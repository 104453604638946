import styled from 'styled-components';

import { BADGE_PILL_VARIANTS } from 'constants/constants';

const getBackgroundColorByVariant = (variant, theme, isFilled) =>
  ({
    [BADGE_PILL_VARIANTS.PRIMARY]: isFilled ? theme.new.colors.P500 : theme.new.colors.white,
    [BADGE_PILL_VARIANTS.SUCCESS]: isFilled ? theme.new.colors.FS500 : theme.new.colors.white,
    [BADGE_PILL_VARIANTS.ERROR]: isFilled ? theme.new.colors.FE900 : theme.new.colors.white,
    [BADGE_PILL_VARIANTS.WARNING]: isFilled ? theme.new.colors.FW300 : theme.new.colors.white,
    [BADGE_PILL_VARIANTS.NEUTRAL]: isFilled ? theme.new.colors.N300 : theme.new.colors.white
  }[variant] || theme.new.colors.white);

const getFontColorByVariant = (variant, theme, isFilled) =>
  ({
    [BADGE_PILL_VARIANTS.PRIMARY]: isFilled ? theme.new.colors.white : theme.new.colors.P500,
    [BADGE_PILL_VARIANTS.SUCCESS]: isFilled ? theme.new.colors.white : theme.new.colors.FS500,
    [BADGE_PILL_VARIANTS.ERROR]: isFilled ? theme.new.colors.white : theme.new.colors.FE900,
    [BADGE_PILL_VARIANTS.WARNING]: theme.new.colors.FW900,
    [BADGE_PILL_VARIANTS.NEUTRAL]: isFilled ? theme.new.colors.N800 : theme.new.colors.N700
  }[variant] || theme.new.colors.black);

const getBorderColorByVariant = (variant, theme, isFilled) =>
  ({
    [BADGE_PILL_VARIANTS.PRIMARY]: theme.new.colors.P500,
    [BADGE_PILL_VARIANTS.SUCCESS]: theme.new.colors.FS500,
    [BADGE_PILL_VARIANTS.ERROR]: theme.new.colors.FE900,
    [BADGE_PILL_VARIANTS.WARNING]: isFilled ? theme.new.colors.FW300 : theme.new.colors.FW500,
    [BADGE_PILL_VARIANTS.NEUTRAL]: isFilled ? theme.new.colors.N300 : theme.new.colors.N400
  }[variant] || theme.new.colors.white);

export const Badge = styled.section`
  ${({ theme }) => theme.new.typography.p5};

  align-items: center;
  background-color: ${({ variant, theme, color, isFilled }) =>
    color || getBackgroundColorByVariant(variant, theme, isFilled)};
  border: 1px solid
    ${({ variant, theme, borderColor, isFilled }) =>
      borderColor || getBorderColorByVariant(variant, theme, isFilled)};
  border-radius: ${({ isRounded }) => (isRounded ? '4px' : '16px')};
  color: ${({ variant, theme, fontColor, isFilled }) =>
    fontColor || getFontColorByVariant(variant, theme, isFilled)};
  display: flex;
  gap: 4px;
  justify-content: center;
  line-height: 1.4rem;
  padding: ${({ isOnlyIcon }) => (isOnlyIcon ? '2px' : '4px 8px')};
  position: relative;
  width: fit-content;
`;
