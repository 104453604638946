import styled, { css } from 'styled-components';

import { PROJECT_STATUS_TYPES } from 'constants/constants';
import { fragmentFlex } from 'utils/timelineUtilities';

const getColorByStatus = ({ theme, statusName, property }) => {
  const { colors } = theme.new;

  return (
    {
      [PROJECT_STATUS_TYPES.ACTIVE]: {
        background: colors.FI100,
        border: colors.FI300,
        dot: colors.FI500,
        hover: colors.FI200
      },
      [PROJECT_STATUS_TYPES.PLANNED]: {
        background: colors.S100,
        border: colors.S300,
        dot: colors.S500,
        hover: colors.S200
      },
      [PROJECT_STATUS_TYPES.WARRANTY]: {
        background: colors.FW100,
        border: colors.FW300,
        dot: colors.FW500,
        hover: colors.FW200
      },
      [PROJECT_STATUS_TYPES.FINISHED]: {
        background: colors.N200,
        border: colors.N400,
        dot: colors.N500,
        hover: colors.N300
      },
      [PROJECT_STATUS_TYPES.ON_HOLD]: {
        background: colors.N100,
        border: colors.N300,
        dot: colors.N300,
        hover: colors.N200
      },
      [PROJECT_STATUS_TYPES.MAINTENANCE]: {
        background: colors.T100,
        border: colors.T300,
        dot: colors.T500,
        hover: colors.T200
      },
      [PROJECT_STATUS_TYPES.NEW]: {
        background: colors.FS100,
        border: colors.FS300,
        dot: colors.FS500,
        hover: colors.FS200
      },
      [PROJECT_STATUS_TYPES.CONFIRMED]: {
        background: colors.FE100,
        border: colors.FE300,
        dot: colors.FE900,
        hover: colors.FE200
      }
    }[statusName]?.[property] || colors.black
  );
};

export const ProjectStatusContainer = styled.div`
  background-color: ${({ statusName, theme }) =>
    getColorByStatus({ statusName, theme, property: 'background' })};
  border-radius: 5px;
  border: 1px solid
    ${({ statusName, theme }) => getColorByStatus({ statusName, theme, property: 'border' })};
  cursor: ${({ onClick }) => onClick && 'pointer'};
  display: flex;
  flex: ${({ startDate, endDate }) => fragmentFlex(startDate, endDate)};
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ isStarted }) =>
    isStarted &&
    css`
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}

  ${({ isFinished }) =>
    isFinished &&
    css`
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}

    &:hover {
    background-color: ${({ statusName, theme }) =>
      getColorByStatus({ statusName, theme, property: 'hover' })};
  }
`;

export const NewProjectStatusContainer = styled.div`
  border-radius: 5px;
  display: flex;
  flex: ${({ startDate, endDate }) => fragmentFlex(startDate, endDate)};
  transition: background-color 0.1s ease-in-out;
`;

export const TextContainer = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.N700};
  text-transform: capitalize;
  margin: 0.8rem;
`;

export const PlannedText = styled.span`
  margin-left: 1rem;
`;

export const StatusText = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;

  &::before {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 20px;
    background-color: ${({ statusName, theme }) =>
      getColorByStatus({ statusName, theme, property: 'dot' })};
  }
`;
