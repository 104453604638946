import { arrayOf, bool, number } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as BudgeIcon } from 'assets/icons/budge.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-icon.svg';
import EmptyState from 'components/common/EmptyState';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { forecastingProjectShape } from 'constants/shapes';
import { getNextMonths, dateToString } from 'utils/date';

import ProjectItem from './ProjectItem';
import { Container, Header, Column } from './styles';

const width = '30rem';

function ProjectList({ forecastingProjects, isLoading, countMonths }) {
  const intl = useIntl();
  const listMonth = getNextMonths(countMonths);

  return (
    <Container>
      <Header>
        <Column minWidth={width} textAlign="start">
          <FolderIcon />
          {intl.formatMessage({ id: 'common.project' })}
        </Column>
        <Column maxWidth={width} textAlign="end">
          <CalendarIcon />
          {intl.formatMessage({ id: 'common.period' })}
        </Column>
        <Column maxWidth={width} textAlign="start">
          {intl.formatMessage({ id: 'forecasting.mainPage.projectList.currentYear' })}
          <BudgeIcon />
        </Column>
        {listMonth.map((item, index) => (
          <Column key={index} maxWidth={width}>
            {dateToString(item.date, 'MMM')}
          </Column>
        ))}
      </Header>
      {isLoading && <CenteredLoading />}
      {forecastingProjects?.length > 0 &&
        forecastingProjects?.map(project => (
          <ProjectItem key={project.id} project={project} width={width} listMonth={listMonth} />
        ))}
      {!forecastingProjects?.length && !isLoading && (
        <EmptyState textIntlId="financial.closePeriod.emptyState" />
      )}
    </Container>
  );
}

ProjectList.propTypes = {
  forecastingProjects: arrayOf(forecastingProjectShape),
  isLoading: bool,
  countMonths: number
};

export default ProjectList;
