import { bool, node, string } from 'prop-types';

import CenteredLoading from '../Loading/CenteredLoading';
import { Wrapper, Overlay } from './styles';

const LoadingWrapper = ({ isLoading = false, children, className }) => (
  <Wrapper className={className}>
    {children}
    {isLoading && (
      <Overlay>
        <CenteredLoading />
      </Overlay>
    )}
  </Wrapper>
);

LoadingWrapper.propTypes = {
  isLoading: bool,
  children: node,
  className: string
};

export default LoadingWrapper;
