import { func, string } from 'prop-types';
import { useIntl } from 'react-intl';

import NewInput from 'components/common/NewInput';
import { EXPERIENCE_INPUTS } from 'constants/constants';
import { errorShape, experienceValidationShape } from 'constants/shapes';

const ExperienceInputs = ({ register, prefix, validations, errors }) => {
  const intl = useIntl();

  return Object.values(EXPERIENCE_INPUTS).map(({ name, min, max }) => {
    const fieldName = prefix ? `${prefix}${name}` : name;

    return (
      <NewInput
        {...register(fieldName, validations?.[name])}
        key={fieldName}
        name={fieldName}
        label={name === 'years' ? `${intl.formatMessage({ id: 'common.experience' })} :` : ''}
        withoutHighlight
        fullWidth
        activePlaceholder={intl.formatMessage({ id: `common.lower.${name}` })}
        type="number"
        min={min}
        max={max}
        errors={errors?.[name]?.message}
      />
    );
  });
};

ExperienceInputs.propTypes = {
  register: func.isRequired,
  prefix: string,
  validations: experienceValidationShape,
  errors: errorShape
};

export default ExperienceInputs;
