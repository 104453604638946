import { string } from 'prop-types';

import { Division } from './styles';

const Divider = ({ height, margin }) => <Division height={height} margin={margin} />;

Divider.propTypes = {
  height: string,
  margin: string
};

export default Divider;
