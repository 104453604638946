import { useEffect, useRef, useState } from 'react';

import { FULFILLED } from 'constants/actionStatusConstants';

import useStatus from './selectors/useStatus';
import useDispatch from './useDispatch';

const useRequest = (
  action,
  { fulfilledCallback, initialData = {}, params = {} } = {},
  execute = true
) => {
  const actionRequest = useDispatch(action);
  const { isPending, error } = useStatus(action);
  const [response, setResponse] = useState(initialData);
  const [config, setConfig] = useState(params);
  const exec = useRef(execute);
  const mountedRef = useRef(false);
  const refCallback = useRef(fulfilledCallback);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      const { payload, type } = await actionRequest(config);

      if (type.endsWith(`/${FULFILLED}`) && mountedRef.current) {
        setResponse(payload);
        refCallback.current && refCallback.current(payload);
      }
    };

    if (exec.current) {
      fetchData();
    }
  }, [actionRequest, config]);

  const doFetch = newParams => {
    setConfig(prevState => ({
      ...prevState,
      ...newParams
    }));
    exec.current = true;
  };

  return [{ response, isPending, error }, doFetch];
};

export default useRequest;
