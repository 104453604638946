import styled from 'styled-components';

import DropdownIcon from 'components/icons/DropdownIcon';

export const FilterDropdownButton = styled.button`
  align-items: center;
  border: 0.5px solid
    ${({ theme, isFiltered }) => (isFiltered ? theme.colors.seaBlue : theme.colors.N400)};
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  height: 100%;
  min-height: 3.4rem;
  justify-content: space-between;
  width: ${({ narrow }) => (narrow ? '60%' : '100%')};

  span {
    ${({ theme }) => theme.new.typography.p4};

    margin-left: 0.97rem;
    margin-right: ${({ narrow }) => (narrow ? '0' : '2.2rem')};
  }
`;

export const Arrow = styled(DropdownIcon)`
  margin-right: 0.95rem;
  stroke-width: 0.6px;
`;

export const FilterDropdownOptions = styled.div`
  position: absolute;
  padding-left: 0.5rem;
  z-index: ${({ theme }) => theme.zIndex.dropdownOptions};
  width: 32rem;

  right: ${({ alignRight }) => (alignRight ? '-0.5rem' : undefined)};
`;

export const FilterDropdownContainer = styled.div`
  position: relative;
  margin: ${({ narrow }) => (narrow ? '0' : '0 0.4rem')};

  ${FilterDropdownButton} {
    color: ${({ theme }) => theme.colors.gray100};

    ${({ theme, isFiltered }) =>
      isFiltered &&
      `
        background-color: ${theme.colors.selectedBackground};
        border-color: ${theme.colors.seaBlue};
        color: ${theme.colors.seaBlue};
      `};

    ${({ theme, isOpen }) =>
      isOpen &&
      `
        background-color: theme.colors.white;
        border-color: ${theme.colors.seaBlue};
        color: ${theme.colors.seaBlue};
      `};

    ${({ theme: { fontWeights }, isOpen, disabled }) =>
      !(isOpen || disabled) &&
      `
        &:hover {
          span {
            font-weight: ${fontWeights.bold};
          }
        }
      `};
  }

  ${Arrow} {
    path {
      stroke: ${({ theme, isFiltered, isOpen }) => (isFiltered || isOpen) && theme.colors.seaBlue};
    }
  }
`;
