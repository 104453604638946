import { GoogleLogin } from '@react-oauth/google';
import { func } from 'prop-types';
import React from 'react';

import { Container } from './styles';

function LoginGoogle({ onSubmit, onError }) {
  const handleSuccess = credentialResponse => {
    const adminUser = {
      googleIdToken: credentialResponse?.credential
    };

    onSubmit(adminUser);
  };

  return (
    <Container>
      <GoogleLogin onSuccess={handleSuccess} onError={onError} width="350" />
    </Container>
  );
}

LoginGoogle.propTypes = {
  onSubmit: func.isRequired,
  onError: func.isRequired
};

export default LoginGoogle;
