import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  overflow-x: auto;
  width: 100%;
`;

export const Header = styled.div`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  align-items: center;
  background: ${({ theme }) => theme.new.colors.neutral100};
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: flex;
  gap: 5rem;
  height: 5.5rem;
  min-width: 126rem;
  padding: 0 3.8rem;
  white-space: nowrap;
  width: 100%;
`;

export const Column = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
  justify-content: ${({ textAlign }) => textAlign || 'center'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  flex-basis: 100%;
  display: flex;
  gap: ${({ gap }) => gap || '1.5rem'};
  position: relative;
`;
