import { arrayOf } from 'prop-types';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useTheme } from 'styled-components';

import { REVENUE_GRAPH_COLORS } from 'constants/forecastingConstants';
import { revenueGraphShape } from 'constants/shapes';
import { getForecastingGraphData } from 'utils/forecastingUtilities';

import BarWithBorder from './BarWithBorder';
import InfoTooltip from './InfoTooltip';
import XAxisTick from './XAxisTick';
import YAxisTick from './YAxisTick';

function RevenueGraph({ data }) {
  const theme = useTheme();

  const handleColor = name =>
    ({
      actualRevenue: REVENUE_GRAPH_COLORS.actualRevenue.fill,
      underContract: REVENUE_GRAPH_COLORS.underContract.fill,
      awaitingStart: REVENUE_GRAPH_COLORS.awaitingStart.fill
    }[name]);

  const handleBorderColor = name =>
    ({
      actualRevenue: REVENUE_GRAPH_COLORS.actualRevenue.stroke,
      underContract: REVENUE_GRAPH_COLORS.underContract.stroke,
      awaitingStart: REVENUE_GRAPH_COLORS.awaitingStart.stroke
    }[name]);

  if (!data) return null;

  const graphData = getForecastingGraphData(data);

  return (
    <ResponsiveContainer width="100%" height={340}>
      <ComposedChart
        data={graphData}
        margin={{
          top: 20,
          left: 68,
          bottom: 100
        }}
      >
        <CartesianGrid vertical={false} stroke={theme.new.colors.neutral200} />
        <XAxis
          tick={<XAxisTick />}
          dataKey="name"
          tickLine={false}
          stroke={theme.new.colors.neutral400}
        />
        <YAxis yAxisId="right" orientation="right" stroke={theme.new.colors.neutral400} width={1} />
        <YAxis
          yAxisId="left"
          tick={<YAxisTick />}
          tickLine={false}
          stroke={theme.new.colors.neutral400}
        />
        <Tooltip content={InfoTooltip} />
        <Line
          key={Math.random()}
          type="monotone"
          yAxisId="left"
          dataKey={REVENUE_GRAPH_COLORS.revenueGoal.key}
          stroke={REVENUE_GRAPH_COLORS.revenueGoal.stroke}
          strokeDasharray="6 6"
          dot={{
            stroke: theme.new.colors.neutral700,
            strokeWidth: 1,
            r: 5,
            strokeDasharray: 'none'
          }}
        />
        <Bar
          dataKey={REVENUE_GRAPH_COLORS.actualRevenue.key}
          yAxisId="left"
          stackId="a"
          barSize={56}
          shape={props => <BarWithBorder size={2} {...props} />}
        >
          {data?.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              cursor="pointer"
              fill={handleColor('actualRevenue')}
              stroke={handleBorderColor('actualRevenue')}
            />
          ))}
        </Bar>
        <Bar
          dataKey={REVENUE_GRAPH_COLORS.underContract.key}
          yAxisId="left"
          stackId="a"
          barSize={56}
          shape={props => <BarWithBorder size={2} {...props} />}
        >
          {data?.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              cursor="pointer"
              fill={handleColor('underContract')}
              stroke={handleBorderColor('underContract')}
            />
          ))}
        </Bar>
        <Bar
          dataKey={REVENUE_GRAPH_COLORS.awaitingStart.key}
          yAxisId="left"
          stackId="a"
          barSize={56}
          shape={props => <BarWithBorder size={2} {...props} />}
        >
          {data?.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              cursor="pointer"
              fill={handleColor('awaitingStart')}
              stroke={handleBorderColor('awaitingStart')}
            />
          ))}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
}

RevenueGraph.propTypes = {
  data: arrayOf(revenueGraphShape)
};

export default RevenueGraph;
