import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'assets/icons/info-filled.svg';

export const Percentage = styled.div`
  ${({ theme }) => theme.new.typography.p2SemiBold};
`;

export const Label = styled.div`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  display: flex;
  align-items: center;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const MarginInformation = styled.div`
  display: flex;
`;
