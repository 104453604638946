import { number, shape } from 'prop-types';
import { useTheme } from 'styled-components';

const XAxisTick = ({ x = 0, y = 0, lastValue, payload }) => {
  const theme = useTheme();
  const { value } = payload;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={value === lastValue ? 9 : 0}
        y={0}
        fill={theme.new.colors.N600}
        dx={0}
        dy={19}
        fontSize={14}
        textAnchor={value !== 0 ? 'end' : 'start'}
        fontWeight={theme.fontWeights.normal}
        fontFamily={theme.new.fontFamily}
        strokeWidth={0}
      >
        {`${value}`}
      </text>
    </g>
  );
};

XAxisTick.propTypes = {
  payload: shape({ value: number }),
  x: number,
  y: number,
  lastValue: number
};

export default XAxisTick;
