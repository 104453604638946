import styled from 'styled-components';

export const BasicInfo = styled.section`
  display: flex;
  gap: 1.6rem;

  & img {
    margin: 0;
  }
`;

export const BasicInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  & h3 {
    color: ${({ theme }) => theme.new.colors.brandDark};
    ${({ theme }) => theme.new.typography.p2SemiBold};
    font-size: 3.2rem;
  }

  & p {
    color: ${({ theme }) => theme.new.colors.neutral800};
    ${({ theme }) => theme.new.typography.p4};
  }
`;

export const LocationContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.6rem;

  & span {
    color: ${({ theme }) => theme.new.colors.neutral800};
    ${({ theme }) => theme.new.typography.p4};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.7rem;
  margin-bottom: ${({ margin }) => margin || '0'};
`;

export const AssignmentStatus = styled.div`
  display: flex;
  ${({ theme }) => theme.new.typography.p5SemiBold};
  gap: 0.8rem;
  align-items: center;
`;

export const Label = styled.div`
  display: flex;
  color: ${({ theme }) => theme.new.colors.neutral800};
`;
