import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import { getRequestFormatDate } from 'utils/date';

class PeopleService {
  static async getPeopleAvailability(startDate, endDate, filterQuery) {
    const start = getRequestFormatDate(startDate);
    const end = getRequestFormatDate(endDate);

    return httpClient.get(ENDPOINTS.availabilities, {
      params: {
        start_date: start,
        end_date: end,
        ...filterQuery
      }
    });
  }

  static async createResource(person) {
    return httpClient.post(ENDPOINTS.people, { person });
  }

  static async resourceExperiences({ personId, highlighted }) {
    return httpClient.get(ENDPOINTS.getPeopleExperience(personId, highlighted));
  }

  static saveExperience(userId, data) {
    return httpClient.post(ENDPOINTS.getPeopleExperience(userId), data);
  }

  static async getMinimalResources(userGroup) {
    return httpClient.get(ENDPOINTS.people, {
      params: {
        group: userGroup
      }
    });
  }

  static async getResource(resourceId) {
    return httpClient.get(ENDPOINTS.getPerson(resourceId));
  }

  static async getResourceSoftSkills({ personId }) {
    return httpClient.get(ENDPOINTS.getPeopleSoftSkills(personId));
  }

  static async updateResourceSoftSkills({ personId, payload }) {
    return httpClient.put(ENDPOINTS.getPeopleSoftSkills(personId), payload);
  }

  static async addResourceSoftSkills({ personId, payload }) {
    return httpClient.post(ENDPOINTS.getPeopleSoftSkills(personId), payload);
  }

  static async updateResourceTechnicalSkills({ personId, payload }) {
    return httpClient.put(ENDPOINTS.gerPersonTechnicalSkill(personId), payload);
  }

  static async addResourceTechnicalSkills({ personId, payload }) {
    return httpClient.post(ENDPOINTS.gerPersonTechnicalSkill(personId), payload);
  }

  static async addSpecialEvents(id, payload) {
    return httpClient.post(ENDPOINTS.getPersonSpecialEvents(id), payload);
  }

  static async updateSpecialEvents(personId, specialEventId, payload) {
    return httpClient.put(ENDPOINTS.updatePersonSpecialEvents(personId, specialEventId), payload);
  }

  static async getPersonSpecialEvents(id) {
    return httpClient.get(ENDPOINTS.getPersonSpecialEvents(id));
  }

  static async deleteSpecialEvents(personId, specialEventId) {
    return httpClient.delete(ENDPOINTS.updatePersonSpecialEvents(personId, specialEventId));
  }

  static async getAssigmentPeople(assignmentId) {
    return httpClient.get(ENDPOINTS.getPeopleByAssignment(assignmentId));
  }
}

export default PeopleService;
