import styled from 'styled-components';

import FilterDropdown from 'components/common/FilterDropdown';
import SearchInput from 'components/common/SearchInput';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Section = styled.section`
  display: flex;
  gap: 1.2rem;
`;

export const StyledFilterDropdown = styled(FilterDropdown)`
  height: 4.8rem;
  margin: 0;
`;

export const StyledSearch = styled(SearchInput)`
  height: 4.8rem;
`;
