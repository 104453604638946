import isEmpty from 'lodash/isEmpty';
import { string, arrayOf, object, oneOf, bool, oneOfType, func } from 'prop-types';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { ReactComponent as TickSvg } from 'components/icons/tick.svg';
import { optionShape } from 'constants/shapes';
import { parseInputErrors } from 'utils/helpers';

import ClearIndicator from './ClearIndicator';
import { CustomStyles } from './customStyles';
import DropdownIndicator from './DropdownIndicator';
import OptionWithIcon from './OptionWithIcon';
import {
  SelectContainer,
  StyledCreatableSelect,
  StyledSelect,
  Label,
  ErrorSpan,
  StyledMenuList,
  SecondaryLabel
} from './styles';
import ValueContainer from './ValueContainer';

const ARROW_SIZES = ['md', 'sm'];

const Select = ({
  name,
  label,
  value,
  clearId,
  errors,
  options,
  arrowSize = ARROW_SIZES[0],
  withoutHighlight = false,
  hidden = false,
  enablePortal = false,
  className = '',
  isUseFilledArrow = false,
  withIntlOptions = false,
  variant = 'old',
  placeholder = '',
  customRef,
  optional = false,
  icon,
  selectedIcon,
  optionIcon,
  defaultValue,
  isCreateable = false,
  ...rest
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const intl = useIntl();

  const theme = useTheme();

  const SelectComponent = isCreateable ? StyledCreatableSelect : StyledSelect;

  return (
    <SelectContainer errors={errors} hidden={hidden} className={className} variant={variant}>
      {label && (
        <Label
          htmlFor={name}
          errors={errors}
          isAbove={isMenuOpen || !isEmpty(value)}
          variant={variant}
        >
          {label} {optional && <SecondaryLabel>(optional)</SecondaryLabel>}
        </Label>
      )}

      <SelectComponent
        arrowSize={arrowSize}
        components={{
          DropdownIndicator,
          // eslint-disable-next-line react/display-name, react/no-multi-comp
          ClearIndicator: props => <ClearIndicator id={clearId} variant={variant} {...props} />,
          // eslint-disable-next-line react/display-name, react/no-multi-comp
          ValueContainer: props => (
            <ValueContainer Icon={isEmpty(value) ? icon : selectedIcon} {...props} />
          ),
          // eslint-disable-next-line react/display-name, react/no-multi-comp
          Option: props => (
            <OptionWithIcon
              {...props}
              selectValue={value?.value || defaultValue}
              variant={variant}
              Icon={TickSvg}
              OptionIcon={optionIcon}
            />
          ),
          // eslint-disable-next-line react/display-name, react/no-multi-comp
          MenuList: props => <StyledMenuList {...props} customTheme={theme} variant={variant} />
        }}
        classNamePrefix="react-select"
        name={name}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        placeholder={placeholder}
        value={value}
        options={
          withIntlOptions
            ? options.map(option => ({
                ...option,
                label: intl.formatMessage({ id: option.label })
              }))
            : options
        }
        hasValue={!isEmpty(value)}
        withoutHighlight={withoutHighlight}
        errors={errors}
        menuPortalTarget={enablePortal && document.querySelector('body')}
        styles={CustomStyles(enablePortal)}
        isUseFilledArrow={isUseFilledArrow}
        variant={variant}
        ref={customRef}
        {...rest}
      />
      {errors && (
        <ErrorSpan variant={variant}>
          <FormattedMessage id={parseInputErrors(errors)} />
        </ErrorSpan>
      )}
    </SelectContainer>
  );
};

Select.propTypes = {
  arrowSize: oneOf(ARROW_SIZES),
  name: string,
  label: string,
  value: oneOfType([optionShape, arrayOf(optionShape)]),
  options: arrayOf(object).isRequired,
  withoutHighlight: bool,
  hidden: bool,
  errors: oneOfType([string, arrayOf(string)]),
  enablePortal: bool,
  className: string,
  isUseFilledArrow: bool,
  withIntlOptions: bool,
  clearId: string,
  variant: string,
  placeholder: string,
  customRef: func,
  optional: bool,
  icon: func,
  selectedIcon: func,
  optionIcon: func,
  defaultValue: string,
  isCreateable: bool
};

export default Select;
