import styled from 'styled-components';

export const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: stretch;
  padding-top: 4rem;
  row-gap: 6rem;
  width: 100%;
`;
