import styled from 'styled-components';

export const Collapsible = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 2px ${({ theme }) => theme.colors.boxShadowColor};
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  margin: 0 3rem;
  padding: 2rem;
  z-index: ${({ theme }) => theme.zIndex.gridTimeline};
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.gray80};
  font-size: 1.7rem;
  line-height: 1.2;
  margin-left: 1.5rem;
`;
