import styled from 'styled-components';

import { TimelineRowHeader, TimelineRow } from 'components/common/Layout/Timeline';

export const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  width: 20rem;

  a {
    text-decoration: none;
  }
`;

export const ListQuickData = styled.li`
  list-style: none;
`;

export const PersonName = styled.p`
  color: ${props => props.theme.colors.gray100};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
`;

export const PersonRole = styled.p`
  color: ${props => props.theme.colors.gray80};
  font-size: 1.3rem;
  font-weight: ${props => props.theme.fontWeights.normal};
  margin: 0;
`;

export const PersonSeniority = styled.span`
  color: ${props => props.theme.colors.gray80};
  font-size: 1.3rem;
  font-weight: ${props => props.theme.fontWeights.bold};
  margin: 0;
`;

export const QuickActionContainer = styled(TimelineRowHeader)`
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray120};
  border-top: none;
  border-right: none;
  height: auto;
  padding: 0.7rem 0.5rem 0.7rem 0;
  width: 100%;
`;

export const QuickActionRow = styled(TimelineRow)`
  &:focus-within {
    z-index: ${({ theme }) => theme.zIndex.generalAvailability};
  }
`;
