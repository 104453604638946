import { func, string } from 'prop-types';

import avatarPlaceholder from 'components/common/images/avatar-placeholder.png';
import RoundAvatar from 'components/common/RoundAvatar';

import { Container, Info } from './styles';

const BasicInfoColumn = ({ fullName, role, avatarUrl, viewProfile }) => (
  <Container>
    <RoundAvatar src={avatarUrl || avatarPlaceholder} squareSize={4} />
    <Info>
      {viewProfile ? (
        <button type="button" onClick={() => viewProfile()}>
          <p>{fullName}</p>
        </button>
      ) : (
        <p>{fullName}</p>
      )}
      {role && <p>{role}</p>}
    </Info>
  </Container>
);

BasicInfoColumn.propTypes = {
  fullName: string,
  role: string,
  avatarUrl: string,
  viewProfile: func
};

export default BasicInfoColumn;
