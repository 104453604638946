import { bool, func, string } from 'prop-types';

import { PENDING } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS } from 'constants/constants';

import { StyledButton, ButtonsRow } from './styles';

const AssignmentFormButtons = ({
  hide,
  isDeleteLoading,
  onSubmit,
  shouldHideOnSuccess,
  showCopy,
  status
}) => {
  const showLoading = status === PENDING || isDeleteLoading;

  const handleSaveAndAddMore = () => {
    onSubmit();
  };

  return (
    <ButtonsRow gap="2.1rem">
      {showCopy ? (
        <StyledButton
          type="button"
          id="saveAddBtn"
          textIntlId="assignment.form.copy"
          variant={BUTTON_VARIANTS.NEW_SECONDARY}
          onClick={handleSaveAndAddMore}
          isLoading={!shouldHideOnSuccess && showLoading}
          outlined
          fullWidth
        />
      ) : (
        <StyledButton
          type="button"
          id="finishBtn"
          variant={BUTTON_VARIANTS.NEW_SECONDARY}
          textIntlId="common.cancel"
          isLoading={shouldHideOnSuccess && showLoading}
          onClick={hide}
          outlined
          fullWidth
        />
      )}
      <StyledButton
        type="submit"
        id="cancelBtn"
        textIntlId="common.save"
        variant={BUTTON_VARIANTS.NEW_PRIMARY}
        isLoading={shouldHideOnSuccess && showLoading}
        fullWidth
      />
    </ButtonsRow>
  );
};

AssignmentFormButtons.propTypes = {
  hide: func,
  isDeleteLoading: bool,
  onSubmit: func,
  shouldHideOnSuccess: bool,
  showCopy: bool,
  status: string
};

export default AssignmentFormButtons;
