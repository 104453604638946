import { arrayOf, node, oneOf } from 'prop-types';

import { SCOPES } from 'constants/permissions';
import { useRole } from 'hooks';
import hasPermission from 'utils/hasPermission';

const PermissionsGate = ({ children, scopes = [] }) => {
  const { permissions } = useRole();

  const permissionGranted = hasPermission(permissions, scopes);

  if (!permissionGranted) return null;

  return children;
};

PermissionsGate.propTypes = {
  children: node.isRequired,
  scopes: arrayOf(oneOf(Object.values(SCOPES)))
};

export default PermissionsGate;
