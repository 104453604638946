import { string } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import BadgePill from 'components/common/BadgePill';
import RoundAvatar from 'components/common/RoundAvatar';
import { SENIORITY_MAP } from 'constants/constants';
import { reportsProfileShape } from 'constants/shapes';
import theme from 'constants/theme';

import {
  Container,
  AssignmentStatus,
  Label,
  BasicInfo,
  BasicInfoContent,
  LocationContainer
} from './styles';

function BasicInfoPanel({ data, margin }) {
  const intl = useIntl();

  const { avatar, fullName, seniority, role, country, assignmentStatus } = data || {};

  const intlSeniority = SENIORITY_MAP.get(seniority)?.label
    ? `${intl.formatMessage({ id: SENIORITY_MAP.get(seniority).label })} `
    : '';

  return (
    <Container margin={margin}>
      <BasicInfo>
        <RoundAvatar src={avatar} squareSize={6.4} />
        <BasicInfoContent>
          <h3>{fullName}</h3>
          <p>{`${intlSeniority}${role || '--'}`}</p>
          <LocationContainer>
            <LocationIcon />
            <span>{country || '--'}</span>
          </LocationContainer>
        </BasicInfoContent>
      </BasicInfo>
      <AssignmentStatus>
        <Label>{intl.formatMessage({ id: 'common.assignmentStatus' })}:</Label>
        <BadgePill
          color={theme.new.colors.neutral700}
          borderColor={theme.new.colors.neutral700}
          fontColor={theme.new.colors.white}
          isFilled
        >
          {assignmentStatus}
        </BadgePill>
      </AssignmentStatus>
    </Container>
  );
}

BasicInfoPanel.propTypes = {
  data: reportsProfileShape,
  margin: string
};

export default BasicInfoPanel;
