import { css } from 'styled-components';

import { setSvgColors } from 'utils/cssUtilities';

import { PrimaryButton } from '.';

const fontColor = css`
  ${({ theme, isCancel }) => (isCancel ? theme.colors.darkRed : theme.colors.seaBlue)}
`;

const backgroundColor = css`
  ${({ theme }) => theme.colors.white}
`;

export const SecondaryButton = css`
  ${PrimaryButton};
  background-color: ${backgroundColor};
  border: ${({ theme, isCancel }) =>
    `1px solid ${isCancel ? theme.colors.darkRed : theme.colors.seaBlue}`};
  color: ${fontColor};
  box-shadow: none;
  ${setSvgColors({ strokeColor: fontColor, fillColor: backgroundColor })};
  &:hover {
    opacity: 0.7;
    background-color: ${({ theme }) => theme.colors.white};
    ${({ theme }) => setSvgColors({ fillColor: theme.colors.white })}
  }
  &:disabled {
    ${({ theme }) => setSvgColors({ fillColor: theme.colors.white })}
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 1;
  }
`;
