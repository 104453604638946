import { string } from 'prop-types';
import { useState } from 'react';

import ConfirmationModal from 'components/common/ConfirmationModal';
import { REJECTED } from 'constants/actionStatusConstants';

import useMountedRef from './utils/useMountedRef';

const useConfirm = ({
  title,
  message,
  warning,
  confirmLabelIntlId,
  cancelLabelIntlId,
  onConfirm,
  onCancel,
  onSuccess
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const mounted = useMountedRef();

  const closeModal = () => setShow(false);

  const confirm = () => {
    setShow(true);
  };

  const handleClose = () => {
    closeModal();
    setIsLoading(false);
    setError(null);
  };

  const handleCancel = () => {
    handleClose();
    onCancel && onCancel();
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    setError(null);
    const result = await onConfirm();
    if (!mounted.current) return;
    if (result?.type?.endsWith(`/${REJECTED}`)) {
      setError(result?.error?.message);
    } else {
      handleClose();
      onSuccess?.();
    }
    setIsLoading(false);
  };

  const ConfirmationDialog = ({ textAlign }) => (
    <ConfirmationModal
      title={title}
      message={message}
      isLoading={isLoading}
      error={error}
      warning={warning}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      hide={handleClose}
      isShowing={show}
      confirmButtonIntlId={confirmLabelIntlId}
      cancelButtonIntlId={cancelLabelIntlId}
      textAlign={textAlign}
    />
  );
  ConfirmationDialog.propTypes = {
    textAlign: string
  };
  return [ConfirmationDialog, confirm];
};

export default useConfirm;
