import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { UTILIZATION } from 'constants/dashboardConstants';
import { DISPLAY_WEEK_DAY_FULL_DATE } from 'constants/dateFormats';
import { assignmentGraphShape } from 'constants/shapes';
import { dateToString } from 'utils/date';

import {
  ContainerGraphTooltip,
  DataContainer,
  Header,
  ItemKey,
  ItemValue,
  SubGrid,
  SubHeader
} from './styles';

function GraphTooltip({ active, payload, label }) {
  const intl = useIntl();

  if (!active) return null;

  const data = payload[0]?.payload?.fullTimeEquivalent;

  return (
    <ContainerGraphTooltip>
      <Header>{dateToString(label, DISPLAY_WEEK_DAY_FULL_DATE)}</Header>
      <DataContainer>
        <SubHeader>{intl.formatMessage({ id: 'common.FTE' })}</SubHeader>
        <ItemKey color={UTILIZATION.billable.color}>
          {intl.formatMessage({ id: UTILIZATION.billable.intl })}
        </ItemKey>
        <ItemValue>{data?.billable || 0}</ItemValue>
        <ItemKey color={UTILIZATION.available.color}>
          {intl.formatMessage({ id: UTILIZATION.available.intl })}
        </ItemKey>
        <ItemValue>{data?.available || 0}</ItemValue>
        <ItemKey color={UTILIZATION.scheduled.color}>
          {intl.formatMessage({ id: UTILIZATION.scheduled.intl })}
        </ItemKey>
        <ItemValue>{data?.scheduled || 0}</ItemValue>
        <ItemKey color={UTILIZATION.demand.color}>
          {intl.formatMessage({ id: UTILIZATION.demand.intl })}
        </ItemKey>
        <SubGrid>
          <ItemKey subItem color={UTILIZATION.open.color}>
            {intl.formatMessage({ id: UTILIZATION.open.intl })}
          </ItemKey>
          <ItemKey subItem color={UTILIZATION.pending.color}>
            {intl.formatMessage({ id: UTILIZATION.pending.intl })}
          </ItemKey>
        </SubGrid>
        <ItemValue>
          <ItemValue>{data?.open || 0}</ItemValue>
          <ItemValue>{data?.pending || 0}</ItemValue>
        </ItemValue>
      </DataContainer>
    </ContainerGraphTooltip>
  );
}

GraphTooltip.propTypes = {
  active: bool,
  label: oneOfType([string, number]),
  payload: arrayOf(shape({ payload: assignmentGraphShape }))
};

export default GraphTooltip;
