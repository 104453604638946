import { string, number, arrayOf, shape } from 'prop-types';

import DetailsBoxTitle from 'components/common/DetailsBoxTitle/index';
import ProjectItems from 'components/common/ProjectItems';
import { capitalizeFirstLetter } from 'utils/searchPeopleUtilities';

import { EmptyBar, DurationDisplay, RequirementInformation, RequirementContainer } from './styles';

const Requirements = ({ role, seniority, allocation, skills, startDate, endDate }) => {
  const projectItems = [
    {
      title: 'common.role',
      item: capitalizeFirstLetter(role)
    },
    {
      title: 'common.seniority',
      item: capitalizeFirstLetter(seniority)
    },
    {
      title: 'common.cap.allocation',
      item: `${allocation}%`
    },
    {
      title: 'common.skills',
      item: skills
    }
  ];

  return (
    <>
      <DetailsBoxTitle title="common.requirements" variant="secondary" />
      <RequirementContainer>
        <RequirementInformation overflowY="auto" overflowX="hidden">
          {projectItems.map(({ title, item }, index) => (
            <ProjectItems
              key={`${index}-project-item`}
              title={title}
              item={item}
              variant="secondary"
            />
          ))}
        </RequirementInformation>
        <RequirementInformation overflowY="hidden" overflowX="hidden">
          <EmptyBar />
          <DurationDisplay>
            <ProjectItems title="common.cap.startDate" item={startDate} variant="secondary" />
            <ProjectItems title="common.cap.endDate" item={endDate} variant="secondary" />
          </DurationDisplay>
        </RequirementInformation>
      </RequirementContainer>
    </>
  );
};

Requirements.propTypes = {
  role: string,
  seniority: string,
  allocation: number,
  skills: arrayOf(
    shape({
      id: number,
      name: string
    })
  ),
  startDate: string,
  endDate: string
};

export default Requirements;
