import { number, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { Span } from './styles';

const EmptyValidator = ({ children }) => {
  const intl = useIntl();

  return <>{children || <Span>{intl.formatMessage({ id: 'financial.empty.value' })}</Span>}</>;
};

EmptyValidator.propTypes = {
  children: oneOfType([string, number])
};

export default EmptyValidator;
