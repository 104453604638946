import breadcrumbs from 'constants/breadcrumbs';

const getBreadcrumbs = (path, current) => {
  const crumbs = breadcrumbs[path] ? [...breadcrumbs[path]] : [];
  if (current) {
    crumbs.push({ name: current });
  }
  return crumbs;
};

export default getBreadcrumbs;
