import { func, bool } from 'prop-types';
import { useIntl } from 'react-intl';

import Modal from 'components/common/Modal';
import theme from 'constants/theme';

import { Container, Box, Circle, Title, Description } from './styles';

function HelperModal({ hide, isShowing }) {
  const intl = useIntl();

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      title={intl.formatMessage({ id: 'dashboard.helper.title' })}
      onClick={e => e.stopPropagation()}
    >
      <Container>
        <Box>
          <Title>
            <Circle color={theme.colors.crusta} />
            {intl.formatMessage({ id: 'dashboard.helper.box.billable.title' })}
          </Title>
          <Description>
            {intl.formatMessage({ id: 'dashboard.helper.box.billable.description' })}
          </Description>
        </Box>
      </Container>
    </Modal>
  );
}

HelperModal.propTypes = {
  hide: func.isRequired,
  isShowing: bool.isRequired
};

export default HelperModal;
