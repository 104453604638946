import { node, string } from 'prop-types';
import { useIntl } from 'react-intl';

import Tooltip from 'components/common/Tooltip';

import { Container, Info } from './styles';

const InformationWrapper = ({ children, infoIntlId }) => {
  const intl = useIntl();
  return infoIntlId ? (
    <Container>
      <Info data-tip={intl.formatMessage({ id: infoIntlId })} />
      <Tooltip />
      {children}
    </Container>
  ) : (
    children
  );
};

InformationWrapper.propTypes = {
  children: node.isRequired,
  infoIntlId: string
};

export default InformationWrapper;
