import styled, { css } from 'styled-components';

export const GlossaryItemContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ active }) => (active ? 'pointer' : 'auto')};
  width: 100%;
  padding: 0.8rem 3.4rem;
  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.colors.softSeaBlue};
      border: 1px solid ${theme.colors.seaBlue};
      border-radius: 6px;
    `};
`;

export const Dot = styled.div`
  display: flex;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background: ${({ theme, color }) => color || theme.colors.black};
  margin-right: 1.3rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const Description = styled.div`
  display: flex;
  font-weight: ${props => props.theme.fontWeights.normal};
  font-size: 1.3rem;
  line-height: 1.8rem;
`;

export const Counter = styled.div`
  display: flex;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${({ theme, color }) => color || theme.colors.black};
  margin-right: 0.3rem;
`;
