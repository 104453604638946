import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { projectToAssignmentOption } from 'utils/assignmentFormsUtilities';

export default projectId => {
  const [projectsOptions, setProjectsOptions] = useState([]);

  const { projects, assignments } = useSelector(({ project: { projects, assignments } }) => ({
    projects,
    assignments
  }));

  useEffect(() => {
    setProjectsOptions(projects?.map(projectToAssignmentOption));
  }, [projects]);

  const assignmentsByProject = assignments?.[projectId] || null;

  return {
    projects,
    projectsOptions,
    assignmentsByProject
  };
};
