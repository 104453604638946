import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  line-height: 2rem;
  margin-bottom: 1rem;
  padding: 2.4rem 1.6rem 1.6rem 1.6rem;
  min-height: 6.5rem;
  flex-direction: column;
  box-shadow: 0px 2px 4px 0px rgba(79, 94, 113, 0.11);
`;
