import styled from 'styled-components';

import { getDiffDays } from 'utils/date';

export const CalendarContainer = styled.div`
  display: flex;
  flex: 3.7;
  flex-direction: column;
`;

export const MonthsContainer = styled.div`
  display: flex;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

export const MonthName = styled.p`
  ${({ theme }) => theme.typography.medium};
  color: ${props => props.theme.colors.gray100};
  flex: ${props => props.weeks.length};
  font-weight: 500;
`;

export const WeeksContainer = styled.div`
  display: flex;

  * {
    flex: 1;
  }
`;

export const DatesContainer = styled.div`
  color: ${props => props.theme.colors.gray80};
  ${({ theme }) => theme.typography.medium};
  flex: ${({ week: { start, end } }) => getDiffDays(start, end) + 1};
  text-align: center;
  word-break: break-all;
`;
