import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import ResourcesTable from 'components/Reports/ResourcesTable';
import { useRequest } from 'hooks';
import { getDisciples } from 'state/actions/resourceProfileActions';

import { Container, Content, Header, Title } from './styles';

const MyReports = () => {
  const intl = useIntl();
  const [resources, setResources] = useState([]);

  const [{ isPending: isLoadingDisciples }] = useRequest(getDisciples, {
    fulfilledCallback: ({ disciples }) => {
      setResources(disciples);
    }
  });

  return (
    <Container>
      <Header>
        <Title>{intl.formatMessage({ id: 'common.myReports' })}</Title>
      </Header>
      <Content>
        <ResourcesTable resources={resources} isLoading={isLoadingDisciples} />
      </Content>
    </Container>
  );
};

export default MyReports;
