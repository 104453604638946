import styled from 'styled-components';

import { getStatusColor, getStatusColorBackground } from 'utils/financialColors';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 61.2rem;
  width: 100%;
`;

export const Totals = styled.div`
  ${({ theme }) => theme.new.typography.p4};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
  display: flex;
  justify-content: space-between;
  max-width: 61.2rem;
  width: 100%;
`;

export const TotalItem = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
`;

export const TotalValue = styled.div`
  ${({ theme }) => theme.new.typography.p4SemiBold}
  align-items: center;
  background: ${getStatusColorBackground};
  border-radius: 65px;
  color: ${getStatusColor};
  display: flex;
  flex-direction: column;
  height: 3.4rem;
  justify-content: center;
  line-height: 1.9rem;
  padding: 0.75rem 1.6rem;
`;
