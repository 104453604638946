import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 18px;
  height: 3.8rem;
  padding: 6px 0 9px;
  width: 100%;
  background: ${({ theme }) => theme.colors.brightGray};
`;

export const Row = styled.div`
  align-items: center;
  border-bottom: 1px ${({ theme }) => theme.colors.lightGray} solid;
  display: flex;
  gap: 18px;
  height: 3.8rem;
  padding: 6px 0 9px;
  width: 100%;
`;

export const Column = styled.div`
  align-items: center;
  color: ${({ theme, header }) => (header ? theme.colors.independence : theme.colors.grey100)};
  flex-basis: 100%;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: ${({ strong }) => (strong ? '500' : '400')};
  line-height: 1.9rem;
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  padding: 0 1.5rem;
`;

export const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;

  a {
    text-decoration: none;
  }
`;

export const Person = styled.div`
  display: flex;
  height: 3.8rem;
`;

export const PersonName = styled.p`
  color: ${props => props.theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  margin: 0;
  line-height: 1.9rem;
`;

export const TruncateText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const ContainerScroll = styled.div`
  max-height: 18rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    height: 5rem;
    background-color: ${({ theme }) => theme.new.colors.neutral300};
  }
`;
