import { arrayOf, number } from 'prop-types';
import { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, XAxis, YAxis } from 'recharts';
import { useTheme } from 'styled-components';

import { skillUtilizationShape } from 'constants/shapes';

import BarLabel from './BarLabel';
import LabelFormatter from './LabelFormatter';
import { StyledResponsiveContainer } from './styles';
import XAxisTick from './XAxisTick';

const SkillsBarGraph = ({ data, totalFTE, step = 50 }) => {
  const theme = useTheme();
  const graphData = data?.map((bar, index) => ({ ...bar, index }));
  const minAxisGap = step >= 50 ? 30 : 10;

  const ticks = useMemo(() => {
    const ticks = [];
    for (let i = 0; i + minAxisGap <= totalFTE; i += step) {
      ticks.push(i);
    }
    ticks.push(totalFTE);
    return ticks;
  }, [step, totalFTE, minAxisGap]);

  return (
    <StyledResponsiveContainer width="100%" height="100%" minHeight={250}>
      <BarChart
        width={50}
        data={graphData}
        layout="vertical"
        barSize={16}
        margin={{
          top: 40,
          left: 4,
          right: 4
        }}
      >
        <CartesianGrid
          horizontal={false}
          stroke={theme.new.colors.N200}
          strokeDasharray="5 5"
          strokeOpacity={1}
        />
        <YAxis hide dataKey="index" type="number" domain={[0, 4]} />
        <XAxis
          type="number"
          tick={<XAxisTick lastValue={totalFTE} />}
          axisLine={false}
          tickLine={false}
          domain={[0, totalFTE]}
          ticks={ticks}
        />
        <defs>
          <linearGradient id="barGradient" x1="0" y1="0" x2="100%" y2="0">
            <stop offset="-0.05%" stopColor="#0090D7" />
            <stop offset="105.58%" stopColor="#58C0F3" />
          </linearGradient>
        </defs>
        <Bar
          dataKey="assigned"
          radius={4}
          label={BarLabel}
          background={{ fill: theme.new.colors.P100 }}
          isAnimationActive={false}
        >
          <LabelList dataKey="assigned" content={LabelFormatter} />
          {data?.map((_, index) => (
            <Cell key={`cell-${index}`} fill="url(#barGradient)" />
          ))}
        </Bar>
      </BarChart>
    </StyledResponsiveContainer>
  );
};

SkillsBarGraph.propTypes = {
  data: arrayOf(skillUtilizationShape),
  totalFTE: number,
  step: number
};

export default SkillsBarGraph;
