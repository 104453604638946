import { bool, number, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as Projection } from 'assets/icons/projection.svg';
import { ReactComponent as TrendingDown } from 'assets/icons/trending-down.svg';
import { ReactComponent as TrendingUp } from 'assets/icons/trending-up.svg';
import { QUARTER_TYPES } from 'constants/constants';

import { Container } from './styles';

const GROWTH_POSITIVE = 1;
const GROWTH_NEGATIVE = -1;

function GrowInfo({ utilizationDifference, quarterType, className, isQuarter }) {
  const intl = useIntl();
  const growthSign = Math.sign(utilizationDifference);

  if (quarterType === QUARTER_TYPES.FUTURE) {
    return (
      <Container isFuture>
        <Projection />
        <span>{intl.formatMessage({ id: 'common.cap.projected' })}</span>
      </Container>
    );
  }

  if (utilizationDifference === null) return '--';

  return (
    <Container growthSign={growthSign} className={className}>
      <span>{`${Math.round(utilizationDifference)}%`} </span>
      {growthSign === GROWTH_NEGATIVE && <TrendingDown />}
      {growthSign === GROWTH_POSITIVE && <TrendingUp />}
      <span>
        {' '}
        {intl.formatMessage({
          id: isQuarter ? 'insights.growthCompare.byQuarter' : 'insights.growthCompare.byYear'
        })}
      </span>
    </Container>
  );
}

GrowInfo.propTypes = {
  utilizationDifference: number,
  quarterType: string,
  className: string,
  isQuarter: bool
};

export default GrowInfo;
