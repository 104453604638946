import { bool, node, oneOf, string } from 'prop-types';

import { ReactComponent as WarningSvg } from 'components/icons/warning.svg';
import { SEVERITY } from 'constants/constants';

import { Container, MessageContainer } from './styles';

const AlertMessage = ({ children, severity = SEVERITY.error, className, isCenter = false }) => (
  <Container severity={severity} className={className} isCenter={isCenter}>
    {severity === SEVERITY.error && <WarningSvg />}
    <MessageContainer severity={severity}>{children}</MessageContainer>
  </Container>
);

AlertMessage.propTypes = {
  children: node.isRequired,
  severity: oneOf(Object.values(SEVERITY)),
  className: string,
  isCenter: bool
};

export default AlertMessage;
