import { createReducer } from '@reduxjs/toolkit';

import { EditQuickAssignment } from 'state/actions/assignmentActions';
import {
  getProjectAssignments,
  getProjectsAvailability,
  resetProjectId
} from 'state/actions/projectActions';
import { sortByDateAsc } from 'utils/date';

const initialState = {
  projects: [],
  assignments: {},
  projectId: ''
};

const projectReducer = createReducer(initialState, builder =>
  builder
    .addCase(getProjectsAvailability.fulfilled, (state, { payload: { projects } }) => {
      projects = projects.map(project => ({
        ...project,
        allStatuses: sortByDateAsc(project.allStatuses, x => x.startDate)
      }));
      state.projects = projects;
    })
    .addCase(
      getProjectAssignments.fulfilled,
      (
        state,
        {
          payload: {
            data: { resources, openAssignments },
            projectId
          }
        }
      ) => {
        const newAssignments = {
          ...state.assignments,
          [projectId]: {
            resources: resources?.sort((a, b) => (a.fullName > b.fullName ? 1 : -1)),
            openAssignments: openAssignments?.sort((a, b) => (a.role > b.role ? 1 : -1))
          }
        };
        state.assignments = newAssignments;
      }
    )
    .addCase(EditQuickAssignment.fulfilled, (state, { payload: { projectId } }) => {
      state.projectId = projectId;
    })
    .addCase(resetProjectId, state => {
      state.projectId = initialState?.projectId;
    })
);

export default projectReducer;
