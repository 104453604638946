import { func, bool, string } from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import DateRange from 'components/common/DatePicker/DateRange';
import { RowInputWrapper } from 'components/common/Layout/Row';
import { VerticalSeparator } from 'components/common/Layout/Separator';
import Modal from 'components/common/Modal';
import { FULFILLED } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS } from 'constants/constants';
import { assignmentsListShape } from 'constants/shapes';
import {
  useForm,
  useTextInputProps,
  useDateRangeProps,
  useHeights,
  useDispatch,
  useStatus,
  useDispatchWithTimelineDates,
  useActionOnSuccess
} from 'hooks';
import { confirmAssignment } from 'state/actions/assignmentActions';
import { composeConfirmAssignmentRequest } from 'utils/assignmentFormsUtilities';
import { isBefore, stringToDate } from 'utils/date';

import { SubTitle, Title, Form, StyledButton, StyledRow } from './styles';

const fields = {
  startDate: 'startDate',
  endDate: 'endDate'
};

const ConfirmAssignmentModal = ({
  assignment,
  isShowing,
  hide,
  name,
  confirmAssignmentAction = confirmAssignment,
  handleRefreshData,
  dispatchFunction = useDispatchWithTimelineDates
}) => {
  const intl = useIntl();

  const { separatorHeight } = useHeights();

  const confirmAssignmentRequest = dispatchFunction(confirmAssignmentAction);

  const resetConfirmStatusRequest = useDispatch(
    confirmAssignmentAction?.reset || confirmAssignment.reset
  );
  const {
    status: confirmAssignmentStatus,
    error,
    isPending
  } = useStatus(confirmAssignmentAction || confirmAssignment);

  const { assignmentId } = assignment;
  const today = new Date();
  const suggestDate = isBefore(assignment?.startDate, today);

  const onSubmitOverride = async values => {
    const { assignment } = composeConfirmAssignmentRequest({ values });
    const { type } = await confirmAssignmentRequest({ assignment, assignmentId });

    if (type.endsWith(`/${FULFILLED}`)) {
      handleRefreshData?.();
    }
  };

  const { values, setValues, errors, setErrors, handleValueChange, handleSubmit, handleBlur } =
    useForm(
      {
        onSubmit: onSubmitOverride,
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: {
          startDate: stringToDate(suggestDate ? today : assignment?.startDate),
          endDate: stringToDate(assignment?.endDate),
          pending: false
        }
      },
      [onSubmitOverride]
    );

  const inputProps = useTextInputProps({
    handleValueChange,
    handleBlur,
    values,
    errors
  });

  const dateRangeProps = useDateRangeProps(
    {
      inputProps,
      values,
      setValues,
      errors,
      setErrors
    },
    [fields.startDate, fields.endDate]
  );

  useActionOnSuccess([confirmAssignmentStatus], () => {
    hide();
  });

  useEffect(
    () => () => {
      resetConfirmStatusRequest();
    },
    [resetConfirmStatusRequest]
  );

  return (
    <Modal isShowing={isShowing} hide={hide} withoutOverflow>
      <Title>
        <FormattedMessage id="template.confirmName" values={{ name }} />
      </Title>
      <SubTitle>{intl.formatMessage({ id: 'modal.confirmAssignment.subtitle' })}</SubTitle>
      <Form onSubmit={handleSubmit}>
        <StyledRow>
          <RowInputWrapper fullWidth>
            <DateRange
              names={{
                startDate: 'assignment.startDate',
                endDate: 'assignment.endDate'
              }}
              disabledKeyboardNavigation
              showWeekCounter
              suggestDate={suggestDate}
              {...dateRangeProps}
            />
          </RowInputWrapper>
        </StyledRow>
        {error && (
          <>
            <AlertMessage>{error}</AlertMessage>
            <VerticalSeparator height={separatorHeight} />
          </>
        )}
        <StyledRow gap="1rem">
          <StyledButton
            type="button"
            variant={BUTTON_VARIANTS.SECONDARY}
            isLoading={isPending}
            textIntlId="common.cancel"
            onClick={hide}
            outlined
            isCancel
            fullWidth
          />
          <StyledButton
            type="submit"
            variant={BUTTON_VARIANTS.PRIMARY}
            isLoading={isPending}
            textIntlId="common.cap.confirmResource"
            fullWidth
          />
        </StyledRow>
      </Form>
    </Modal>
  );
};

ConfirmAssignmentModal.propTypes = {
  assignment: assignmentsListShape,
  isShowing: bool.isRequired,
  hide: func.isRequired,
  name: string.isRequired,
  confirmAssignmentAction: func,
  handleRefreshData: func,
  dispatchFunction: func
};

export default ConfirmAssignmentModal;
