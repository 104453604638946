import { camelize } from 'humps';
import { oneOf, string } from 'prop-types';

import { humanize } from 'utils/helpers';

import { ServiceTypeLabelStyle } from './styles';

const ServiceTypeLabel = ({ serviceType, mode = 'normal' }) => (
  <ServiceTypeLabelStyle mode={mode} serviceType={camelize(serviceType)}>
    {serviceType && humanize(serviceType)}
  </ServiceTypeLabelStyle>
);

ServiceTypeLabel.propTypes = {
  serviceType: string.isRequired,
  mode: oneOf(['normal', 'light'])
};

export default ServiceTypeLabel;
