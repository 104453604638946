import { createReducer } from '@reduxjs/toolkit';

import { getAssignmentById, resetResources } from 'state/actions/assignmentActions';
import { getPeopleByAssignment } from 'state/actions/peopleActions';
import { convertResourcesDataToTableFormat } from 'utils/dashboardUtilities';
import { compareResources } from 'utils/filtersUtilities';

const initialState = {
  assignment: {},
  peopleByAssignment: {
    allResources: [],
    loader: true
  }
};

const assignmentReducer = createReducer(initialState, builder =>
  builder
    .addCase(resetResources, state => {
      state.peopleByAssignment = initialState.peopleByAssignment;
      state.assignment = initialState.assignment;
    })

    .addCase(getAssignmentById.fulfilled, (state, { payload }) => {
      state.assignment = payload;
    })
    .addMatcher(
      action =>
        action.type === getPeopleByAssignment.fulfilled.type ||
        action.type === getPeopleByAssignment.rejected.type,
      (state, action) => {
        if (action.type === getPeopleByAssignment.fulfilled.type) {
          const { resources } = action.payload;
          const formattedResources = convertResourcesDataToTableFormat(
            resources,
            state.assignment.startDate,
            state.assignment.endDate
          );
          const sortedResourcesByMatch = formattedResources.sort(compareResources);
          state.peopleByAssignment.allResources = sortedResourcesByMatch;
        }
        state.peopleByAssignment.loader = false;
      }
    )
);

export default assignmentReducer;
