import { node, string } from 'prop-types';
import { useIntl } from 'react-intl';

import MuiTooltip from 'components/common/MuiTooltip';
import { TOOLTIP_VARIANTS } from 'constants/constants';

import { Container } from './styles';

const InfoTooltip = ({ text, textIntlId, children, width, ...props }) => {
  const intl = useIntl();

  return (
    <Container $width={width}>
      <MuiTooltip variant={TOOLTIP_VARIANTS.BLACK} {...props}>
        {textIntlId ? intl.formatMessage({ id: textIntlId }) : text}
      </MuiTooltip>
      {children}
    </Container>
  );
};

InfoTooltip.propTypes = {
  children: node,
  text: string,
  textIntlId: string,
  width: string
};

export default InfoTooltip;
