import styled from 'styled-components';

export const MatchSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0;
  }

  span {
    ${({ theme }) => theme.new.typography.p5SemiBold};
    text-align: right;
  }
`;

export const MatchOverall = styled.div`
  ${({ theme }) => theme.new.typography.h4SemiBold};
`;
