import styled, { css } from 'styled-components';

import { FORECASTING_GRAPH_REFERENCES } from 'constants/constants';

const setDotColorsByType = type =>
  ({
    [FORECASTING_GRAPH_REFERENCES.REVENUE_TO_DATE]: css`
      border: ${({ theme }) => `1.5px solid ${theme.new.colors.brand}`};
      background: rgba(6, 105, 253, 0.28);
    `,
    [FORECASTING_GRAPH_REFERENCES.YEAR_REVENUE_GOAL]: css`
      border: ${({ theme }) => `1.5px solid ${theme.new.colors.neutral700}`};
      background: ${({ theme }) => `1.5px solid ${theme.new.colors.white}`};
    `
  }[type]);

export const Container = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
`;

export const Dot = styled.div`
  ${({ type }) => setDotColorsByType(type)}
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
`;

export const Title = styled.span`
  ${({ theme }) => theme.new.typography.p3SemiBold};
`;

export const Total = styled.span`
  ${({ theme }) => theme.new.typography.p3};
`;
