import styled from 'styled-components';

export const Container = styled.div`
  padding: 2.4rem 2.4rem 3.2rem 2.4rem;
  width: 100%;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
  width: 100%;
`;
