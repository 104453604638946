import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  min-width: 21rem;
  height: 10.8rem;
  padding: 1.6rem 2.4rem;
  border-radius: 0.6rem;
  gap: 0.8rem;
  background-color: ${({ theme }) => theme.new.colors.white};

  box-shadow: 0px 2px 6px 0px #00000014;
  flex-grow: 1;
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 2.8rem;
  height: 2.8rem;
  padding: 0.8rem;
  border-radius: 36rem;
  gap: 0.8rem;
  justify-items: center;
  align-items: center;
  background-color: ${({ theme, color }) => color || theme.new.colors.white};
`;

export const AssignmentStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const Status = styled.span`
  ${({ theme }) => theme.new.typography.p5SemiBold};
`;

export const NumbersRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const Total = styled.span`
  ${({ theme }) => theme.new.typography.p1SemiBold};
  color: ${({ theme }) => theme.new.colors.N900};
`;

export const Count = styled.span`
  ${({ theme }) => theme.new.typography.p6};
  color: ${({ theme }) => theme.new.colors.N600};
`;
