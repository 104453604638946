import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled, { css } from 'styled-components';

import { TOOLTIP_VARIANTS } from 'constants/constants';

const tooltipVariant = variant =>
  ({
    [TOOLTIP_VARIANTS.WHITE]: css`
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 5px;
      box-shadow: 0 3.51464px 16px rgba(0, 8, 65, 0.16);
    `,
    [TOOLTIP_VARIANTS.BLACK]: css`
      background-color: ${({ theme }) => theme.new.colors.neutral900};
      border-radius: 11px;
      max-width: 40rem;
      min-height: 3.2rem;
      opacity: 0.93;
      padding: 0;
      width: fit-content;
    `
  }[variant]);

const tooltipContentVariant = variant =>
  ({
    [TOOLTIP_VARIANTS.WHITE]: css`
      color: ${({ theme }) => theme.fontColor};
      font-family: ${({ theme }) => theme.fontFamily};
      padding: 1rem 1.4rem;
      white-space: pre;
    `,
    [TOOLTIP_VARIANTS.BLACK]: css`
      ${({ theme }) => theme.new.typography.p5};
      color: ${({ theme }) => theme.new.colors.white};
      overflow: hidden;
      padding: 0.9rem;
      white-space: initial;
    `
  }[variant]);

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className, arrow: className }} />
))`
  & .${tooltipClasses.tooltip} {
    ${({ variant }) => tooltipVariant(variant)}
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }

  & .${tooltipClasses.arrow} {
    color: ${({ theme }) => theme.colors.white};
    font-size: 2.5rem;
    &::before,
    &::after {
      border-bottom-right-radius: 4.39331px;
    }
  }
`;

export const Content = styled.div`
  ${({ variant }) => tooltipContentVariant(variant)}
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  justify-content: flex-start;
  row-gap: 0;
  text-align: center;
  width: 100%;
  overflow: auto;
  white-space: pre-wrap;
`;

export const TooltipContainer = styled.div`
  height: ${({ height }) => height ?? '100%'};
  position: absolute;
  top: ${({ top }) => top};
  width: ${({ width }) => width ?? '100%'};
`;
