import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11),
    0px 4px 8px 0px rgba(79, 94, 113, 0.1);
  padding: 1.6rem;
  flex-grow: 1;
`;

export const TopContainer = styled.div`
  align-items: start;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  padding-bottom: 2rem;
`;

export const Title = styled.div`
  display: flex;
  column-gap: 8px;
  flex-direction: column;

  & h3 {
    ${({ theme }) => theme.new.typography.p3SemiBold};
    color: ${({ theme }) => theme.new.colors.N900};
    line-height: 2.4rem;
  }

  & h4 {
    color: ${({ theme }) => theme.new.colors.P800};
    font-size: 2.2rem;
    line-height: 2.4rem;
    font-weight: 700;
    font-family: Inter;
  }
`;

export const DatePickerContainer = styled.div`
  width: 16rem;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: minmax(248px, 40%) 1fr;
`;
