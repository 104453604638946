import styled, { css } from 'styled-components';

import { ReactComponent as SearchSvg } from 'components/icons/search.svg';

export const SearchInputContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  ${({ variant }) => variant === 'old' && 'margin-left: 1.6rem;'}
  ${({ className }) =>
    ({
      accessRoles: css`
        height: 4.8rem;
      `
    }[className] || '')}
`;

export const Input = styled.input`
  border: none;
  outline: none;

  ${({ variant }) =>
    ({
      old: css`
        font-size: 1.2rem;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors.backgroundColor};
        color: ${({ theme }) => theme.colors.gray80};
        height: 3.4rem;
        width: 18.5rem;
        padding: 0 2.4rem 0 1.5rem;
      `,
      new: css`
        ${({ theme }) => theme.new.typography.p5};
        background-color: ${({ theme }) => theme.new.colors.white};
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.new.colors.neutral400};
        color: ${({ theme }) => theme.new.colors.N900};
        height: 4.4rem;
        width: ${({ $width }) => $width};
        padding: 1.15rem 1.4rem 1.15rem 4.4rem;
      `
    }[variant] || '')}

  ${({ className }) =>
    ({
      accessRoles: css`
        ${({ theme }) => theme.new.typography.p4};
        height: 4.8rem;
        padding: 1.2rem;
        padding-left: 4.4rem;
        width: 100%;
      `
    }[className] || '')}

  &::placeholder {
    ${({ variant }) =>
      ({
        old: css`
          ${({ theme }) => theme.typography.italicPlaceholder};
          color: ${({ theme }) => theme.colors.gray80};
        `,
        new: css`
          ${({ theme }) => theme.new.typography.p5};
          color: ${({ theme }) => theme.new.colors.N600};
        `
      }[variant] || '')}
    ${({ className }) =>
      ({
        accessRoles: css`
          ${({ theme }) => theme.new.typography.p4};
        `
      }[className] || '')}
  }
`;

export const SearchIcon = styled(SearchSvg)`
  position: absolute;
  ${({ variant }) =>
    ({
      old: css`
        height: 1.4rem;
        right: 10px;
      `,
      new: css`
        left: 1.2rem;
        path {
          stroke: ${({ theme }) => theme.new.colors.N600};
          stroke-width: 1.5px;
        }
        width: 2rem;
        height: 2rem;
      `
    }[variant] || '')}

  ${({ className }) =>
    ({
      accessRoles: css`
        path {
          stroke: ${({ theme }) => theme.new.colors.neutral800};
          stroke-width: 1.67px;
        }
        width: 2.4rem;
        height: 2.4rem;
      `
    }[className] || '')}
`;
