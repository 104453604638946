export const toCurrency = (
  number,
  options = {
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency'
  }
) => (Number(number) || 0).toLocaleString('en-US', options);

export const precisionFormat = number =>
  number && typeof number === 'string' ? parseFloat(number.replaceAll(',', '')) : number;

export const formatAmount = (amount, round = false) => {
  if (Number(amount)) {
    const value = Number(amount);
    return round ? Math.round(value) : parseFloat(value.toFixed(2));
  }
  return 0;
};

export const formatFractionDigits = (number, maxFractionDigits) =>
  number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits
  });
