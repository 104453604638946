import { bool, number, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { ReactComponent as AlertCircleIcon } from 'assets/icons/alert-circle.svg';
import BadgePill from 'components/common/BadgePill';
import { SENIORITY_MAP } from 'constants/constants';
import { DISPLAY_MONTH_DAY_YEAR } from 'constants/dateFormats';
import { dateToString } from 'utils/date';
import { humanize } from 'utils/helpers';
import { getColorByAssignmentStatus } from 'utils/projectsOverviewUtilities';

import BasicInfoColumn from '../BasicInfoColumn';
import { Column, AllocationContent } from './styles';

export const Cell = ({ value, columnKey, overassigned }) => {
  const intl = useIntl();
  const theme = useTheme();

  const getSeniorityLabel = seniority => {
    const seniorityMessageId = SENIORITY_MAP.get(seniority)?.label;
    return seniorityMessageId ? intl.formatMessage({ id: seniorityMessageId }) : '-';
  };

  return (
    <Column>
      {{
        assignment: <BasicInfoColumn maxWidth="60%" {...value} />,
        allocation:
          value !== null ? (
            <AllocationContent>
              <p>{`${value} %`}</p>
              {overassigned && (
                <BadgePill
                  color={theme.new.colors.BOA500}
                  borderColor={theme.new.colors.BOA500}
                  fontColor={theme.new.colors.white}
                  isRounded
                  isFilled
                  isIconOnly
                >
                  <AlertCircleIcon />
                </BadgePill>
              )}
            </AllocationContent>
          ) : (
            '-'
          ),
        role: value,

        startDate: dateToString(value, DISPLAY_MONTH_DAY_YEAR),
        endDate: dateToString(value, DISPLAY_MONTH_DAY_YEAR),
        status: (
          <BadgePill
            color={getColorByAssignmentStatus(theme, value?.label).color}
            fontColor={getColorByAssignmentStatus(theme, value?.label).fontColor}
            isRounded
          >
            {humanize(value?.label)}
          </BadgePill>
        ),
        seniority: getSeniorityLabel(value)
      }[columnKey] || value}
    </Column>
  );
};

Cell.propTypes = {
  value: oneOfType([string, number]),
  columnKey: string,
  overassigned: bool
};

export default Cell;
