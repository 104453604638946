import { bool, string } from 'prop-types';
import { useIntl } from 'react-intl';

import emptyStateDefault from '../images/empty-state-icon.png';
import { Container, Title, Subtitle } from './styles';

function EmptyState({
  title,
  subtitle,
  srcImage = emptyStateDefault,
  titleIntlId,
  subtitleIntlId,
  className = '',
  small = false
}) {
  const intl = useIntl();

  return (
    <Container className={className} small={small}>
      <img src={srcImage} alt={intl.formatMessage({ id: 'emptyState.icon' })} />
      <Title>{titleIntlId ? intl.formatMessage({ id: titleIntlId }) : title}</Title>
      <Subtitle>{subtitleIntlId ? intl.formatMessage({ id: subtitleIntlId }) : subtitle}</Subtitle>
    </Container>
  );
}

EmptyState.propTypes = {
  title: string,
  subtitle: string,
  srcImage: string,
  titleIntlId: string,
  subtitleIntlId: string,
  className: string,
  small: bool
};

export default EmptyState;
