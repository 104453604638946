import dayjs from 'dayjs';

import { QUARTER_TYPES } from 'constants/constants';
import { areasInformation } from 'constants/utilizationConstants';

const getAreaInfoByUtilizationId = id =>
  Object.values(areasInformation).find(({ utilizationId }) => id === utilizationId);

export const mergeAreasUtilizationInfo = utilizations => {
  const utilizationsList = Object.entries(utilizations);

  const mergedInfo = {};
  utilizationsList.map(([utilizationId, value]) => {
    mergedInfo[utilizationId] = {
      ...value,
      info: getAreaInfoByUtilizationId(utilizationId)
    };
  });

  return mergedInfo;
};

export const getMaxUtilizationPercentage = utilizations => {
  const utilizationsList = Object.values(mergeAreasUtilizationInfo(utilizations));
  const maxUtilizationPercentage = Math.max(
    ...utilizationsList.map(({ utilizationPercentage }) => utilizationPercentage)
  );
  return Math.max(maxUtilizationPercentage, 100);
};

export const getQuarter = quarter => {
  const currentQuarter = dayjs().quarter();
  let quarterType = QUARTER_TYPES.PAST;
  if (quarter === currentQuarter) quarterType = QUARTER_TYPES.CURRENT;
  if (quarter > currentQuarter) quarterType = QUARTER_TYPES.FUTURE;

  return quarterType;
};

export const getDepartmentsGraphData = ({ departmentData, totalFteInMonth } = {}) =>
  departmentData?.map(item => ({
    ...item,
    value: item.benchInFte / totalFteInMonth
  }));
