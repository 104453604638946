import styled from 'styled-components';

import { newInputBorder } from 'utils/inputColors';

export const StyledInput = styled.input`
  ${({ theme }) => theme.new.typography.p5};
  outline: transparent;
  border-radius: 0.5rem;
  border: ${({ theme, value, errors }) => newInputBorder({ value, errors, theme: theme.new })};
  color: ${({ theme }) => theme.new.colors.neutral900};
  font-size: 1.5rem;
  height: 4.4rem;
  line-height: 2;
  padding: 1.15rem 1.4rem;
  padding-left: ${({ $isWithIcon }) => $isWithIcon && '4.2rem'};
  padding-right: ${({ $isWithActivePlaceholder }) => $isWithActivePlaceholder && '6.3rem'};
  transition: all 0.2s ease-in-out;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  &::placeholder {
    color: ${({ theme }) => theme.new.colors.neutral600};
  }
  &:focus {
    border-color: ${({ errors, theme: { new: colors } }) =>
      errors ? colors.feedbackError : colors.seaBlue};
  }
`;

export const Label = styled.label`
  ${({ theme }) => theme.new.typography.p4};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.new.colors.neutral800};
  padding: 0 0 4px;
`;

export const ErrorSpan = styled.span`
  ${({ theme }) => theme.new.typography.p6};
  color: ${({ theme }) => theme.new.colors.feedbackError};
`;

export const InputContainer = styled.div`
  background: none;
  font-size: 1.6rem;
  overflow: hidden;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

export const IconContainer = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1.4rem;
  svg {
    width: 2rem;
    height: 2rem;
    stroke: ${({ theme }) => theme.new.colors.neutral600};
    stroke-width: 2px;
  }
`;

export const InputContent = styled.div`
  position: relative;
  height: 4.4rem;
`;

export const ActivePlaceholder = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.neutral600};
  position: absolute;
  right: 14px;
  display: flex;
  height: 100%;
  align-items: center;
`;
