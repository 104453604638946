import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
  padding-top: 4rem;
`;

export const Title = styled.h3`
  ${({ theme }) => theme.new.typography.h3};
  color: ${({ theme }) => theme.new.colors.neutral900};
`;

export const Horizontal = styled.hr`
  border-width: 0;
  background-color: ${({ theme }) => theme.new.colors.neutral300};
  height: 1px;
  margin: 1rem 0 3rem 0;
`;

export const ReferenceWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  justify-content: flex-end;
  margin-top: 2.4rem;
  max-width: 49.5rem;
  width: 100%;

  & > div {
    margin-right: 1.2rem;
  }
  & > div:last-child,
  & > div:nth-child(4) {
    margin-right: 0;
  }
`;

export const TimelineWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const TimelineTitle = styled.h4`
  ${({ theme }) => theme.new.typography.p4Bold};
  align-self: flex-start;
  color: ${({ theme }) => theme.new.colors.N600};
`;
