import assignmentTypes from 'constants/assignmentTypes';

import { humanize } from './helpers';

export const groupsToOptions = ({ id, name }) => ({
  label: humanize(name),
  value: { id, type: assignmentTypes.roles.value },
  key: name,
  id: `role-${id}`
});

export const roleGroupsToOptions = ({ id, group }) => ({
  label: humanize(group),
  value: { id, type: assignmentTypes.roles.value },
  key: group,
  id: `role-${id}`
});
