import { css } from 'styled-components';

import { setSvgColors } from 'utils/cssUtilities';

import { NewPrimaryButton } from '.';

const fontColor = css`
  ${({ theme }) => theme.new.colors.neutral800}
`;

const backgroundColor = css`
  ${({ theme }) => theme.new.colors.white}
`;

export const NewSecondaryButton = css`
  ${NewPrimaryButton};
  ${({ theme }) =>
    setSvgColors({ strokeColor: theme.new.colors.brand, fillColor: backgroundColor })}
  background-color: ${backgroundColor};
  border: ${({ theme }) => `1px solid ${theme.new.colors.neutral400}`};
  color: ${fontColor};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  &:focus {
    ${({ theme }) => setSvgColors({ fillColor: theme.new.colors.neutral100 })}
    background-color: ${({ theme }) => theme.new.colors.neutral100};
    box-shadow: 0 1px 2px rgba(228, 229, 235, 0.05), 0 0 0 4px rgba(228, 229, 235, 0.4);
  }
  &:hover {
    ${({ theme }) => setSvgColors({ fillColor: theme.new.colors.neutral100 })}
    background-color: ${({ theme }) => theme.new.colors.neutral100};
  }
  &:disabled {
    ${({ theme }) => setSvgColors({ strokeColor: theme.new.colors.neutral300 })}
    background-color: ${({ theme }) => theme.new.colors.neutral100};
    border: ${({ theme }) => `1px solid ${theme.new.colors.neutral200}`};
    color: ${({ theme }) => theme.new.colors.neutral300};
    cursor: not-allowed;
  }
`;
