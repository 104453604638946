import { bool, func, number, shape } from 'prop-types';
import { useIntl } from 'react-intl';

import InfoTooltip from 'components/Financial/InfoTooltip';
import { additionalChargesShape, openPeriodShape } from 'constants/shapes';
import { useStatus } from 'hooks';
import { editFinancialProjectDetails } from 'state/actions/financialActions';
import { formatAmount, toCurrency } from 'utils/number';
import { getPeriodTotal } from 'utils/periodUtilities';

import {
  Billed,
  Container,
  GeneralContainer,
  Margin,
  MarginLabel,
  PeriodTotalInput,
  TotalRow,
  StyledInfoIcon,
  FieldInformation
} from './styles';

const PeriodTotal = ({
  period,
  editingPeriod,
  additionalCharges,
  globalDiscountEdit,
  globalDiscountAmount,
  handleAdditionalCharges
}) => {
  const intl = useIntl();
  const { isPending: isGlobalDiscountPending } = useStatus(editFinancialProjectDetails);

  const totalBilled = getPeriodTotal(period, globalDiscountAmount, additionalCharges);

  return (
    <Container>
      <GeneralContainer>
        <TotalRow>
          <FieldInformation>
            {intl.formatMessage({ id: 'financial.table.total.additional' })}
            <InfoTooltip textIntlId="financial.table.total.additional.info">
              <StyledInfoIcon />
            </InfoTooltip>
          </FieldInformation>

          {editingPeriod === period.id ? (
            <PeriodTotalInput
              value={additionalCharges.additional}
              field="additional"
              onChange={handleAdditionalCharges}
            />
          ) : (
            <Billed editingPeriod={editingPeriod}>
              {toCurrency(additionalCharges.additional)}
            </Billed>
          )}
        </TotalRow>
        <TotalRow>
          <FieldInformation>
            {intl.formatMessage({ id: 'financial.table.total.fixedPrice' })}
            <InfoTooltip textIntlId="financial.table.total.fixedPrice.info">
              <StyledInfoIcon />
            </InfoTooltip>
          </FieldInformation>
          {editingPeriod === period.id ? (
            <PeriodTotalInput
              value={additionalCharges.fixedPrice}
              field="fixedPrice"
              onChange={handleAdditionalCharges}
            />
          ) : (
            <Billed editingPeriod={editingPeriod}>
              {toCurrency(additionalCharges.fixedPrice)}
            </Billed>
          )}
        </TotalRow>
      </GeneralContainer>
      <TotalRow>
        {intl.formatMessage({ id: 'financial.table.total.periodBilled' })}
        <Billed>{`${toCurrency(totalBilled)}`}</Billed>
      </TotalRow>
      {!editingPeriod && !globalDiscountEdit && !isGlobalDiscountPending && (
        <TotalRow>
          <MarginLabel>
            {intl.formatMessage({ id: 'financial.table.total.periodMargin' })}
          </MarginLabel>
          <Margin margin={period.margin}>
            {period?.margin ? `${formatAmount(period.margin)}%` : 0}
          </Margin>
        </TotalRow>
      )}
    </Container>
  );
};

PeriodTotal.propTypes = {
  period: openPeriodShape,
  editingPeriod: number,
  additionalCharges: shape(additionalChargesShape),
  globalDiscountEdit: bool,
  globalDiscountAmount: number,
  handleAdditionalCharges: func
};

export default PeriodTotal;
