import { SORTING_PROJECT_OPTIONS } from 'constants/constants';
import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import { getRequestFormatDate } from 'utils/date';

class ProjectService {
  static async getProjectsAvailability(startDate, endDate, filterQuery, minimal = false) {
    const start = getRequestFormatDate(startDate);
    const end = endDate && getRequestFormatDate(endDate);

    const endpoint = minimal ? ENDPOINTS.minimalProjects : ENDPOINTS.projects;

    // TODO: Remove sort_by when implement sorting options
    return httpClient.get(endpoint, {
      params: {
        start_date: start,
        ...(end && { end_date: end }),
        [SORTING_PROJECT_OPTIONS.status]: 'asc',
        [SORTING_PROJECT_OPTIONS.name]: 'asc',
        ...filterQuery
      }
    });
  }

  static async archiveProject(projectId) {
    return httpClient.put(ENDPOINTS.getProject(projectId), {
      archived: true
    });
  }

  static async newProjectStatus(projectStatus) {
    return httpClient.post(ENDPOINTS.projectStatuses, { projectStatus });
  }

  static async editProjectStatus(statusId, projectStatus) {
    return httpClient.put(ENDPOINTS.getProjectStatus(statusId), { projectStatus });
  }

  static async deleteProjectStatus(statusId) {
    return httpClient.delete(ENDPOINTS.getProjectStatus(statusId));
  }

  static async createProject(project) {
    return httpClient.post(ENDPOINTS.projects, { project });
  }

  static async editProject(project) {
    return httpClient.put(ENDPOINTS.getProject(project.id), project);
  }

  static async deleteProject(projectId) {
    return httpClient.delete(ENDPOINTS.getProject(projectId));
  }

  static async getProjectById(projectId) {
    return httpClient.get(ENDPOINTS.getProject(projectId));
  }

  static async getProjectsOverviewTotals() {
    return httpClient.get(ENDPOINTS.getProjectsOverviewTotals);
  }

  static async getProjectsOverview(params) {
    return httpClient.get(ENDPOINTS.getProjectsOverview, { params });
  }

  static async getProjectOverviewDetail(projectId) {
    return httpClient.get(ENDPOINTS.getProjectOverviewDetail(projectId));
  }

  static async getFinishedAssignments(projectId) {
    return httpClient.get(ENDPOINTS.getFinishedAssignments(projectId));
  }

  static async getProjectAssignments({ projectId, startDate, endDate }) {
    return httpClient.get(ENDPOINTS.getProjectAssignments(projectId), {
      params: {
        startDate,
        endDate
      }
    });
  }
}

export default ProjectService;
