import { bool, func, number } from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import Button from 'components/common/Button';
import DateRange from 'components/common/DatePicker/DateRange';
import NewModal from 'components/common/NewModal';
import Select from 'components/common/Select';
import Switch from 'components/common/Switch';
import { BUTTON_VARIANTS, SPECIAL_EVENTS_OPTIONS, SPECIAL_EVENT_TYPES } from 'constants/constants';
import { trainingConversionInitialValuesShape } from 'constants/shapes';
import { trainingSkillValidation } from 'constants/validators';
import { useDispatch, useSkills, useStatus, useTeams } from 'hooks';
import { addSpecialEvent, updateSpecialEvent } from 'state/actions/peopleActions';
import { filterSkillsByDepartment, getSkillOptionDepartmentId } from 'utils/helpers';
import { composeTrainingConversionSkill } from 'utils/resourceProfileUtilities';

import { Form, ButtonsContainer, SwitchContainer } from './styles';

const fields = {
  dates: 'dates',
  department: 'department',
  skill: 'skill',
  specialEvent: 'specialEvent'
};

const TrainingConversionModal = ({
  isShowing,
  handleToggle,
  resourceDepartmentId,
  handleAdd,
  handleUpdate,
  initialValues
}) => {
  const intl = useIntl();
  const { skillOptions } = useSkills();
  const { formattedTeams } = useTeams(true);

  const [departmentId, setDepartmentId] = useState();

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm({
    defaultValues: {
      [fields.dates]: {
        startDate: new Date(),
        endDate: new Date()
      },
      [fields.specialEvent]: SPECIAL_EVENT_TYPES.training,
      ...initialValues
    }
  });

  const [departmentField, typeField] = watch([fields.department, fields.specialEvent]);

  useEffect(() => {
    const skillDepartmentId = getSkillOptionDepartmentId(
      skillOptions,
      initialValues?.skill?.value.id
    );

    setDepartmentId(skillDepartmentId || resourceDepartmentId);
  }, [skillOptions, initialValues, resourceDepartmentId]);

  useEffect(() => {
    setValue(
      fields.department,
      formattedTeams.find(team => team.id === departmentId)
    );
  }, [formattedTeams, departmentId, setValue]);

  const resetAddSpecialEvent = useDispatch(addSpecialEvent.reset);
  const { error: errorAddSpecialEvent, isPending: isLoadingAddEspecialEvent } =
    useStatus(addSpecialEvent);
  const resetUpdateSpecialEvent = useDispatch(updateSpecialEvent.reset);
  const { error: errorUpdateSpecialEvent, isPending: isLoadingUpdateEspecialEvent } =
    useStatus(updateSpecialEvent);

  const error = errorAddSpecialEvent || errorUpdateSpecialEvent;
  const isLoading = isLoadingAddEspecialEvent || isLoadingUpdateEspecialEvent;

  const departmentSkillsOptions = filterSkillsByDepartment(
    skillOptions,
    departmentField?.id || departmentId
  );

  const onSubmit = async data => {
    const payload = composeTrainingConversionSkill(data);
    const func = initialValues ? handleUpdate : handleAdd;
    const result = await func(payload);
    if (result) {
      reset();
      handleToggle();
    }
  };

  useEffect(() => {
    reset();
    return () => {
      resetAddSpecialEvent();
      resetUpdateSpecialEvent();
    };
  }, [reset, resetAddSpecialEvent, resetUpdateSpecialEvent]);

  return (
    <NewModal
      isShowing={isShowing}
      hide={handleToggle}
      title={intl.formatMessage({
        id: initialValues ? `modal.editTrainingSkill.title` : `modal.addTrainingSkill.title`
      })}
      maxWidth="65rem"
      withoutOverflow
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SwitchContainer>
          <p>{intl.formatMessage({ id: 'common.type' })}:</p>
          <Controller
            name={fields.specialEvent}
            control={control}
            render={({ field }) => (
              <Switch
                options={SPECIAL_EVENTS_OPTIONS.map(option => ({
                  value: option.value,
                  label: intl.formatMessage({ id: option.label })
                }))}
                value={field.value}
                onChange={e => field.onChange(e.target.value)}
              />
            )}
          />
        </SwitchContainer>
        <Controller
          name={fields.dates}
          control={control}
          render={({ field: { onChange, value } }) => (
            <DateRange
              selected={value}
              onChange={onChange}
              names={{ startDate: 'assignment.startDate', endDate: 'assignment.endDate' }}
              disabledKeyboardNavigation
              showWeekCounter
              keepRangeDuration
              variant="new"
              labels={{
                startDate: `${intl.formatMessage({ id: 'common.cap.startingDate' })}:`,
                endDate: `${intl.formatMessage({ id: 'common.cap.endDate' })}:`
              }}
            />
          )}
        />
        {typeField === 'conversion' && (
          <Controller
            name={fields.department}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={value => {
                  field.onChange(value);
                  setValue(fields.skill, null);
                }}
                ref={undefined}
                customRef={field.ref}
                isSearchable
                options={formattedTeams}
                label={`${intl.formatMessage({ id: 'common.department' })}:`}
                fullWidth
                withoutHighlight
                enablePortal
                variant="new"
              />
            )}
          />
        )}
        <Controller
          name={fields.skill}
          control={control}
          rules={trainingSkillValidation[fields.skill]}
          render={({ field }) => (
            <Select
              {...field}
              ref={undefined}
              customRef={field.ref}
              isSearchable
              options={departmentSkillsOptions}
              label={`${intl.formatMessage({ id: 'common.skill' })}:`}
              fullWidth
              withoutHighlight
              enablePortal
              variant="new"
              errors={errors?.[fields.skill]?.message}
            />
          )}
        />
        {error && <AlertMessage>{error}</AlertMessage>}
        <ButtonsContainer>
          <Button
            id="cancelBtn"
            type="button"
            textIntlId="common.cancel"
            onClick={handleToggle}
            variant={BUTTON_VARIANTS.NEW_SECONDARY}
            isLoading={isLoading}
          />
          <Button
            id="saveBtn"
            type="submit"
            textIntlId="common.save"
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
            isLoading={isLoading}
          />
        </ButtonsContainer>
      </Form>
    </NewModal>
  );
};

TrainingConversionModal.propTypes = {
  resourceDepartmentId: number,
  handleAdd: func,
  handleToggle: func,
  handleUpdate: func,
  isShowing: bool,
  initialValues: trainingConversionInitialValuesShape
};

export default TrainingConversionModal;
