import { number } from 'prop-types';
import { useMemo } from 'react';

import ProgressBar from 'components/common/ProgressBar';
import theme from 'constants/theme';
import { getPercentageProgress } from 'utils/dashboard/people';
import { formatFractionDigits } from 'utils/number';

import { Container, Info, Range, Percentage } from './styles';

const DashboardProgressBar = ({ height, hours, totalHours, maxFractionDigits }) => {
  const [percentage, realDiff] = useMemo(
    () => getPercentageProgress(hours, totalHours),
    [hours, totalHours]
  );

  const getFormattedNumber = number =>
    maxFractionDigits ? formatFractionDigits(number, maxFractionDigits) : number;

  const barContent = [
    {
      percentage,
      color: percentage > 100 ? theme.colors.red : theme.colors.progressbar,
      roundedLeft: true,
      roundedRight: true
    },
    {
      percentage: realDiff,
      color: theme.colors.gray30,
      roundedLeft: true,
      roundedRight: true
    }
  ];
  return (
    <Container>
      <ProgressBar height={height} content={barContent} />
      <Info>
        <Range>{`${getFormattedNumber(hours)} of ${getFormattedNumber(totalHours)}`}</Range>
        <Percentage>{percentage}%</Percentage>
      </Info>
    </Container>
  );
};

DashboardProgressBar.propTypes = {
  height: number,
  hours: number.isRequired,
  totalHours: number.isRequired,
  maxFractionDigits: number
};

export default DashboardProgressBar;
