import styled, { css } from 'styled-components';

import { POSITIONS_TYPES, THREE_DOTS_VARIANTS } from 'constants/constants';

const getPosition = position =>
  ({
    [POSITIONS_TYPES.LEFT]: css`
      top: 2rem;
      right: 3rem;
    `,
    [POSITIONS_TYPES.RIGHT]: css`
      top: 2rem;
      left: 3rem;
    `,
    [POSITIONS_TYPES.TOP_LEFT]: css`
      bottom: 2.5rem;
      right: 3rem;
    `,
    [POSITIONS_TYPES.TOP_RIGHT]: css`
      bottom: 2.5rem;
      left: 3rem;
    `,
    [POSITIONS_TYPES.BOTTOM_LEFT]: css`
      top: 2.5rem;
      right: 3rem;
    `,
    [POSITIONS_TYPES.BOTTOM_RIGHT]: css`
      top: 2.5rem;
      left: 2rem;
    `
  }[position]);

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto 0.5rem auto auto;
  position: relative;
`;

export const RoundButton = styled.button`
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  circle {
    fill: ${({ theme }) => theme.new.colors.N600};
  }
  ${({ threeDotVariant }) =>
    threeDotVariant === THREE_DOTS_VARIANTS.SMALL &&
    css`
      height: 2.8rem;
      width: 2.8rem;
      path {
        stroke: white;
      }
    `};

  &:hover {
    background-color: ${({ theme, threeDotVariant }) =>
      threeDotVariant === THREE_DOTS_VARIANTS.BIG
        ? theme.new.colors.N100
        : theme.new.colors.persianBlue};
    circle {
      fill: ${({ theme }) => theme.new.colors.N900};
    }
  }
  &:focus {
    circle {
      fill: ${({ theme }) => theme.new.colors.N900};
    }
    background-color: ${({ theme, threeDotVariant }) =>
      threeDotVariant === THREE_DOTS_VARIANTS.BIG
        ? theme.new.colors.N100
        : theme.new.colors.persianBlue};
    outline: 1px solid ${({ theme }) => theme.new.colors.N300};
    box-shadow: 0px 0px 0px 4px rgba(228, 229, 235, 0.4), 0px 1px 2px 0px rgba(228, 229, 235, 0.05);
  }
`;

export const OptionsContainer = styled.div`
  ${({ $position }) => getPosition($position)};
  ${({ visible }) => !visible && `opacity: 0;`};

  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.new.colors.N300};
  box-shadow: ${({ theme }) => theme.new.shadows.small};
  min-width: 18.8rem;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

export const Button = styled.button`
  ${({ theme }) => theme.new.typography.p5};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.new.colors.N800};
  height: 5rem;
  text-align: left;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: max-content;
  gap: 0.8rem;

  svg path {
    stroke: ${({ theme }) => theme.new.colors.N600};
  }

  &:hover {
    background-color: ${({ theme }) => theme.new.colors.N100};
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  ${({ isLastOption }) =>
    isLastOption &&
    css`
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      color: ${({ theme }) => theme.new.colors.FE500};
      svg path {
        stroke: ${({ theme }) => theme.new.colors.FE500};
      }
    `}
`;
