import styled, { css } from 'styled-components';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { SORT_DIR } from 'constants/constants';

export const TableWrapper = styled.div`
  display: grid;
  gap: 2.8rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  min-height: 38rem;
  overflow-x: auto;
  transition: all 150ms ease-in-out;
  table {
    width: 100%;
  }
  th,
  td {
    padding: 0 1rem;
    width: fit-content;
  }
`;

export const ArrowColumn = styled.td`
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: 0.8rem;
  width: 100%;
  & > svg {
    min-width: 3.6rem;
  }
`;

export const ArrowIcon = styled(ArrowUp)`
  display: block;
  height: 1rem;
  opacity: ${({ $isSorting }) => ($isSorting ? '1' : '0.3')};
  path {
    stroke: ${({ theme, $isSorting }) =>
      $isSorting ? theme.new.colors.P500 : theme.new.colors.N900};
    stroke-width: 1.8px;
  }
  transition: all 150ms ease-in-out;
  transform: ${({ direction }) =>
    direction === SORT_DIR.desc ? 'rotate(-180deg) translate(0, 10%)' : 'none'};
  width: 1.2rem;
`;

export const Table = styled.table`
  background: ${({ theme }) => theme.new.colors.white};
  border-collapse: collapse;
  height: fit-content;
`;

export const ArrowButton = styled(DownArrow)`
  cursor: pointer;
  height: 1.8rem;
  margin-left: 0.8rem;
  stroke: ${({ theme }) => theme.new.colors.N900};
  transform: ${({ $isExpanded }) =>
    $isExpanded ? `scale(${20 / 24}) rotate(-180deg) translate(0, -25%)` : `scale(${20 / 24})`};
  transition: all 150ms ease-in-out;
  width: 2rem;
`;

export const ExpandedRow = styled.tr`
  background-color: ${({ theme }) => theme.new.colors.N100};
  height: ${({ $isExpanded }) => ($isExpanded ? 'auto' : '0')};
  overflow: hidden;
  transition: all 1300ms ease-in-out;
  vertical-align: top;
  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      border-left: 3px solid ${({ theme }) => theme.new.colors.P500};
      border-bottom: 1px solid ${({ theme }) => theme.new.colors.N400};
    `};
`;

export const Column = styled.td`
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding && `${padding} !important`};
  width: ${({ width }) => width};
`;

export const HeaderColumnContainer = styled.button`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.N900};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 1.4rem;
  justify-content: start;
  width: fit-content;
`;

export const OptionsColumn = styled.th`
  cursor: auto;
  opacity: ${({ $isExpanded }) => ($isExpanded ? '1' : '0')};
`;

export const Row = styled.tr`
  background: ${({ $isExpanded, theme }) => ($isExpanded ? theme.new.colors.P100 : 'none')};
  border-bottom: 1px solid
    ${({ theme, isHeader }) => (isHeader ? theme.new.colors.N500 : theme.new.colors.N400)};
  height: ${({ height }) => height || '7.2rem'};
  text-align: left;
  width: 100%;
  &:hover {
    ${({ isHeader, $isExpanded, theme }) =>
      !isHeader &&
      css`
        background: ${$isExpanded ? theme.new.colors.P100 : theme.new.colors.N100};
        ${OptionsColumn} {
          opacity: 1;
        }
      `}
  }
  td:nth-child(2) {
    max-width: 30rem;
  }
  & > td:nth-child(3),
  & > th:nth-child(3) {
    min-width: 19.6rem;
  }
`;
