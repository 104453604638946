import { useIntl } from 'react-intl';

import { ReactComponent as RefreshIcon } from 'assets/icons/refresh-icon.svg';

import { Container, Title, Subtitle } from './styles';

function EmptyState() {
  const intl = useIntl();

  return (
    <Container>
      <RefreshIcon />
      <Title>{intl.formatMessage({ id: 'financial.updateResources.emptyState.title' })}</Title>
      <Subtitle>
        {intl.formatMessage({ id: 'financial.updateResources.emptyState.subtitle' })}
      </Subtitle>
    </Container>
  );
}

export default EmptyState;
