import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import GrowInfo from 'components/common/GrowInfo';
import { QUARTER_TYPES } from 'constants/constants';
import { useRequest } from 'hooks';
import { getDashboardUtilizationQuarters } from 'state/actions/dashboardActions';
import { getQuarter } from 'utils/utilizationUtilities';

import {
  Block,
  Container,
  Header,
  QuarterInfo,
  QuarterNumber,
  Row,
  StyledLoadingWrapper,
  Utilization
} from './styles';

const initialQuarters = {
  quarterBlocks: [...Array(4)].map((_, index) => ({ quarter: index + 1 }))
};

const QuarterBlocks = () => {
  const intl = useIntl();
  const [{ isPending, error, response: quarters }] = useRequest(getDashboardUtilizationQuarters, {
    initialData: initialQuarters
  });

  return (
    <Container>
      {quarters.quarterBlocks?.map(({ quarter, utilizationPercentage, utilizationDifference }) => {
        const quarterType = getQuarter(quarter);

        return (
          <StyledLoadingWrapper isLoading={isPending} key={quarter}>
            <Block>
              <Header isCurrent={quarterType === QUARTER_TYPES.CURRENT}>
                <span>
                  {intl.formatMessage({ id: `common.${quarterType}` })}{' '}
                  {intl.formatMessage({ id: 'common.cap.quarter' })}
                </span>
              </Header>
              <Row>
                <QuarterNumber>
                  <span>{`Q${quarter}`}</span>
                </QuarterNumber>
                <QuarterInfo>
                  <Utilization>
                    <span>{`${intl.formatMessage({ id: 'common.cap.utilization' })} : `} </span>
                    <span>
                      {utilizationPercentage !== null
                        ? `${Math.round(utilizationPercentage)}%`
                        : '--'}
                    </span>
                  </Utilization>
                  <GrowInfo
                    utilizationDifference={utilizationDifference}
                    quarterType={quarterType}
                  />
                </QuarterInfo>
                {error && <AlertMessage>{error}</AlertMessage>}
              </Row>
            </Block>
          </StyledLoadingWrapper>
        );
      })}
    </Container>
  );
};

export default QuarterBlocks;
