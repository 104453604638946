import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  width: 60rem;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export const Circle = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 1rem;
`;

export const Title = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.grey100};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: 1.9rem;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.grey80};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 1.8rem;
`;
