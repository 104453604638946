import isFinite from 'lodash/isFinite';
import { number } from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { HARVEST_BILLABLE_HOURS } from 'constants/constants';
import { isPastDate } from 'utils/date';

import { TextContainer, TextWrapper } from '../styles';

const PercentageCell = ({ total, value }) => {
  const percentage = Math.round((value * 100) / total);
  const calculated = isFinite(percentage) ? percentage : 0;
  const [isRed, setisRed] = useState(false);
  const { endDate } = useSelector(({ dashboard: { endDate } }) => ({
    endDate
  }));

  useEffect(() => {
    if (endDate) {
      setisRed(
        isPastDate(endDate) &&
          (calculated >= HARVEST_BILLABLE_HOURS.maxExpectedValue ||
            calculated <= HARVEST_BILLABLE_HOURS.minExpectedValue)
      );
    }
  }, [endDate, calculated]);

  return (
    <TextWrapper>
      <TextContainer isRed={isRed} bold>
        {calculated}%
      </TextContainer>
      <TextContainer>
        <FormattedMessage id="template.connectors.of" values={{ valA: value, valB: total }} />
      </TextContainer>
    </TextWrapper>
  );
};

PercentageCell.propTypes = {
  total: number.isRequired,
  value: number.isRequired
};

export default PercentageCell;
