import styled from 'styled-components';

export const ContainerGraphTooltip = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  filter: drop-shadow(0 3.5px 16px rgba(0, 8, 65, 0.16));
  padding: 2rem;
`;

export const DataContainer = styled.div`
  color: ${({ theme }) => theme.fontColor};
  column-gap: 3.7rem;
  display: grid;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  grid-template-columns: auto auto;
`;

export const Header = styled.h3`
  color: ${({ theme }) => theme.fontColor};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: 1.9rem;
  margin-bottom: 0.2rem;
`;

export const SubHeader = styled.p`
  color: ${({ theme }) => theme.fontColor};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin: 0 auto 1rem auto;
  grid-column-start: 2;
`;

export const ItemKey = styled.p`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
    ${({ subItem }) =>
      subItem &&
      `
    margin-bottom: 0;
    `}
  }

  &::before {
    background-color: ${({ color }) => color || 'black'};
    border-radius: 7.5px;
    content: '';
    display: inline-block;
    height: 0.9rem;
    margin-right: 0.5rem;
    width: 0.9rem;

    ${({ subItem }) =>
      subItem &&
      `
    background-color: ${({ theme }) => theme.colors.gray80};
    height: 0.2rem;
    width: 0.2rem;
    `}
  }
`;

export const SubGrid = styled.div`
  grid-column-start: 1;
  margin-left: 1.5rem;
`;

export const ItemValue = styled.div`
  margin: 0 auto;
`;
