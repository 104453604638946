import { FormattedMessage } from 'react-intl';

import routesPaths from 'constants/routesPaths';

const breadcrumbs = {
  [routesPaths.projectDetails]: [
    { name: <FormattedMessage id="common.projectsOverview" />, path: routesPaths.projectsOverview }
  ],
  [routesPaths.resources]: [{ name: <FormattedMessage id="common.resources" /> }],
  [routesPaths.projectsOverview]: [{ name: <FormattedMessage id="common.projectsOverview" /> }],
  [routesPaths.projectsTimeline]: [{ name: <FormattedMessage id="common.projectsTimeline" /> }],
  [routesPaths.assignmentDetails]: [
    {
      name: <FormattedMessage id="common.projectsTimeline" />,
      path: routesPaths.projectsTimeline
    },
    {
      name: <FormattedMessage id="common.projectsOverview" />,
      path: routesPaths.projectsOverview
    }
  ],
  [routesPaths.financialProjectsDetails]: [
    {
      name: <FormattedMessage id="breadcrumbs.financialProjectDetails" />,
      path: `${routesPaths.financialProjects}`
    }
  ],
  [routesPaths.myReportsProfile]: [
    { name: <FormattedMessage id="common.myReports" />, path: routesPaths.myReports }
  ]
};

export default breadcrumbs;
