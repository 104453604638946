import styled from 'styled-components';

import Button from 'components/common/Button';

export const EmployeeInteractions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const Title = styled.h4`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-top: 1.2rem;

  > button {
    ${({ theme }) => theme.new.typography.p6SemiBold};
    height: 2.9rem;
    min-width: auto;
    padding: 0;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
