import { number } from 'prop-types';

import LoadingWrapper from 'components/common/LoadingWrapper';
import Section from 'components/Reports/Section';
import useSkillSet from 'hooks/useSkillSet';

import TrainingsSection from '../../TrainingsSection';
import SoftSkillsModal from '../SoftSkillsModal';
import TechnicalSkillModal from '../TechnicalSkillModal';
import TrainingConversionModal from '../TrainingConversionModal';
import { Container } from './styles';

const SkillSetTab = ({ personId, departmentId }) => {
  const {
    ConfirmationDelete,
    editSection,
    editSkill,
    handleAddSoftSkills,
    handleAddTechnicalSkills,
    handleAddTrainingSkill,
    handleDeleteTrainingSkill,
    handleEditTrainingSkill,
    handleToggleSoftSkills,
    handleToggleTechnicalSkills,
    handleToggleTrainingSkill,
    handleUpdateSoftSkills,
    handleUpdateTechnicalSkills,
    handleUpdateTrainingSkill,
    isEditingTrainings,
    isLoading,
    isOpenSoftSkillModal,
    isOpenTechnicalSkillModal,
    isOpenTrainingSkill,
    repeatedSkill,
    setEditSection,
    setIsEditingTraining,
    softSkills,
    specialEvents,
    technicalSkills
  } = useSkillSet(personId);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Container>
        <Section
          titleIntlId="common.cap.technicalSkills"
          name="technicalSkills"
          editSection={editSection}
          setEditSection={setEditSection}
          data={technicalSkills?.experiences}
          onSubmit={handleUpdateTechnicalSkills}
          handleAction={handleToggleTechnicalSkills}
        />
        <Section
          titleIntlId="common.cap.softSkills"
          name="softSkills"
          editSection={editSection}
          setEditSection={setEditSection}
          data={softSkills?.softSkills}
          onSubmit={handleUpdateSoftSkills}
          handleAction={handleToggleSoftSkills}
        />
        <TrainingsSection
          personId={personId}
          handleAction={handleToggleTrainingSkill}
          onEdit={handleEditTrainingSkill}
          onDelete={handleDeleteTrainingSkill}
          specialEvents={specialEvents?.specialEvents}
          isEditing={isEditingTrainings}
          setIsEditing={setIsEditingTraining}
        />
      </Container>
      {isOpenSoftSkillModal && (
        <SoftSkillsModal
          isShowing={isOpenSoftSkillModal}
          handleToggle={handleToggleSoftSkills}
          handleAdd={handleAddSoftSkills}
          repeatedSkill={repeatedSkill}
        />
      )}
      {isOpenTechnicalSkillModal && (
        <TechnicalSkillModal
          isShowing={isOpenTechnicalSkillModal}
          handleToggle={handleToggleTechnicalSkills}
          handleAdd={handleAddTechnicalSkills}
          departmentId={departmentId}
        />
      )}
      {isOpenTrainingSkill && (
        <TrainingConversionModal
          resourceDepartmentId={departmentId}
          isShowing={isOpenTrainingSkill}
          handleToggle={handleToggleTrainingSkill}
          handleAdd={handleAddTrainingSkill}
          handleUpdate={handleUpdateTrainingSkill}
          initialValues={editSkill}
        />
      )}
      <ConfirmationDelete />
    </LoadingWrapper>
  );
};

SkillSetTab.propTypes = {
  personId: number,
  departmentId: number
};

export default SkillSetTab;
