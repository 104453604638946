import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
`;

export const Title = styled.div`
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.gray100};
  font-size: 1.6rem;
  align-items: center;
  margin-right: 2rem;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.8rem 1rem;
  gap: 1rem;
  width: 4.5rem;
  height: 3.8rem;
  background: ${({ theme }) => theme.colors.avgGreen};
  border-radius: 6px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 2.3rem;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 1rem;
`;

export const Label = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.4rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray80};
`;
