import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';

import Button from 'components/common/Button';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import LoadingWrapper from 'components/common/LoadingWrapper';
import SearchInput from 'components/common/SearchInput';
import Tabs from 'components/common/Tabs';
import { FULFILLED } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS } from 'constants/constants';
import routesPaths from 'constants/routesPaths';
import { useDispatch, useOnClickOutside, useRequest, useStatus } from 'hooks';
import { getExportData, getFinancialProjects } from 'state/actions/financialActions';
import { getExportDefaultDates, getRequestFormatDate } from 'utils/date';
import { downloadCsv } from 'utils/downloadCsv';
import { filterList } from 'utils/reducersUtilities';

import FinancialProjects from './Projects';
import FinancialReporting from './Reporting';
import {
  FinancialContainer,
  FinancialNavbarContainer,
  SearchAndExport,
  StyledDateRange
} from './styles';

const FinancialPage = () => {
  const [search, setSearch] = useState();
  const [projects, setProjects] = useState();
  const [customDateRange, setCustomDateRange] = useState(() => getExportDefaultDates());
  const [exportOpen, setExportOpen] = useState(false);
  const [isLoadingExport, setLoadingExport] = useState(false);

  const exportData = useDispatch(getExportData);
  const { isPending: isExportPending } = useStatus(getExportData);

  const intl = useIntl();

  const tabs = [
    //  TODO enable this line when the new page is ready
    // { route: routesPaths.financialReporting, title: 'financial.tab.reporting' },
    { route: routesPaths.financialProjects, title: 'common.projects' }
  ];

  const [{ isPending }] = useRequest(getFinancialProjects, {
    fulfilledCallback: payload => setProjects(payload.projects)
  });

  const outsideRef = useOnClickOutside(() => setExportOpen(false));

  const filteredProjects = () => filterList(projects, search);

  const handleExport = async () => {
    const { type, payload } = await exportData({
      startDate: getRequestFormatDate(customDateRange.start),
      endDate: getRequestFormatDate(customDateRange.end)
    });
    if (type.endsWith(`/${FULFILLED}`)) {
      downloadCsv(payload);
      setLoadingExport(false);
    }
  };

  const handleDateChange = dates => {
    const [start, end] = dates;
    setCustomDateRange({ start, end });
    setLoadingExport(true);
  };

  const handleClick = () => {
    if (exportOpen && customDateRange.start && customDateRange.end) {
      setExportOpen(false);
      handleExport();
    } else {
      setExportOpen(true);
    }
  };

  useEffect(() => {
    if (isLoadingExport && customDateRange.start && customDateRange.end) {
      handleExport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDateRange]);

  useEffect(() => {
    if (!exportOpen && isLoadingExport) {
      setLoadingExport(false);
    }
  }, [exportOpen]);

  return (
    <FinancialContainer>
      <FinancialNavbarContainer>
        <Tabs tabs={tabs} />
        <SearchAndExport>
          <div ref={outsideRef}>
            <Button variant={BUTTON_VARIANTS.NEW_SECONDARY} onClick={handleClick}>
              {isExportPending ? (
                <CenteredLoading maxHeight="10px" />
              ) : (
                intl.formatMessage({ id: 'common.export' })
              )}
            </Button>
            {exportOpen && (
              <StyledDateRange
                onChange={handleDateChange}
                startDate={customDateRange.start}
                endDate={customDateRange.end}
                form="external-form"
                inline
              />
            )}
          </div>

          <SearchInput
            placeholderId="filter.financial.projects"
            value={search}
            onChange={setSearch}
            variant="new"
          />
        </SearchAndExport>
      </FinancialNavbarContainer>
      <Switch>
        <Redirect exact from={routesPaths.financial} to={routesPaths.financialProjects} />
        <Route exact path={routesPaths.financialReporting}>
          <FinancialReporting />
        </Route>
        <Route exact path={routesPaths.financialProjects}>
          <LoadingWrapper isLoading={isPending}>
            <FinancialProjects projects={filteredProjects()} />
          </LoadingWrapper>
        </Route>
      </Switch>
    </FinancialContainer>
  );
};

export default FinancialPage;
