import styled from 'styled-components';

import Select from 'components/common/Select';

export const OverviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2.4rem 3.2rem;
  padding-bottom: 2rem;
  flex-direction: column;
`;

export const OverviewItemContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 33rem;
  margin: 1rem 0;
  padding: 3rem 3rem 4rem;
  position: relative;
`;

export const SectionsSelect = styled(Select)`
  width: 19.3rem;
  margin: 0;
  .react-select__control {
    ${({ theme }) => theme.typography.semiBold};
    border-color: ${({ theme }) => theme.colors.black};
    border-radius: inherit;
    border-width: 0 0 1.5px 0;
    font-size: 14px;
    height: 3.4rem;
    width: 19.3rem;
  }
  .react-select__menu {
    ${({ theme }) => theme.typography.semiBold};
    font-size: 14px;
  }
  .react-select__single-value {
    ${({ theme }) => theme.typography.semiBold};
    font-size: 14px;
  }
`;

export const TopBar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.6rem 1.4rem 0.1rem 1.4rem;
  width: 100%;
  height: 100%;
`;
