import { string, arrayOf, bool, func, oneOfType, number } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { optionShape } from 'constants/shapes';
import { useOnClickOutside } from 'hooks';

import SearchInput from '../SearchInput';
import {
  Container,
  Button,
  Arrow,
  Options,
  Label,
  PlaceholderContainer,
  StyledDropdownCheckbox
} from './styles';

const MultiDropdown = ({
  labelId,
  options,
  setOptions,
  selectedOptions,
  withIntlOptions = true,
  narrow = false,
  className = '',
  id,
  clearId,
  maxWidth,
  onChange,
  placeholderId,
  isDisabled = false,
  searchIntlId,
  isInsideAccordion,
  isSearch,
  isClearable,
  closeTrigger
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const outsideRef = useOnClickOutside(() => setIsOpen(false));

  const toggle = () => setIsOpen(!isOpen);

  const disabled = isDisabled || !options?.length;

  useEffect(() => {
    setIsOpen(false);
  }, [closeTrigger]);

  return (
    <Container
      isOpen={isOpen}
      ref={outsideRef}
      narrow={narrow}
      disabled={disabled}
      className={className}
      id={id}
    >
      {labelId && <Label>{intl.formatMessage({ id: labelId })}</Label>}
      <Button
        onClick={toggle}
        narrow={narrow}
        disabled={disabled}
        type="button"
        maxWidth={maxWidth}
      >
        <PlaceholderContainer
          maxWidth={maxWidth}
          isEmpty={!selectedOptions?.length}
          onClick={e => {
            setIsOpen(true);
            e.stopPropagation();
          }}
        >
          <SearchInput
            searchIntlId={searchIntlId}
            variant="new"
            width="100%"
            value={search}
            onChange={setSearch}
            disabled={disabled}
            placeholderId={isOpen ? '' : placeholderId}
          />
        </PlaceholderContainer>

        <Arrow upDirection={isOpen} disabled={disabled} />
      </Button>
      {isOpen && (
        <Options maxWidth={maxWidth} isInsideAccordion={isInsideAccordion}>
          <StyledDropdownCheckbox
            options={options}
            setOptions={setOptions}
            withIntlOptions={withIntlOptions}
            variant="new"
            clearId={clearId}
            isUseSelected
            onApply={onChange}
            stroke="current"
            checkboxSize="2.4rem"
            isClearable={isClearable}
            searchIntlId={!isSearch && searchIntlId}
            searchValue={search}
            onSearchChange={setSearch}
          />
        </Options>
      )}
    </Container>
  );
};

MultiDropdown.propTypes = {
  className: string,
  clearId: string,
  id: string,
  options: arrayOf(optionShape),
  setOptions: func,
  selectedOptions: arrayOf(optionShape),
  isDisabled: bool,
  labelId: string.isRequired,
  maxWidth: string,
  narrow: bool,
  onChange: func,
  placeholderId: string,
  withIntlOptions: bool,
  searchIntlId: string,
  isInsideAccordion: bool,
  isSearch: bool,
  isClearable: bool,
  closeTrigger: oneOfType([number])
};

export default MultiDropdown;
