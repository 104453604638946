import styled from 'styled-components';

import TimeNavigation from 'components/common/TimeNavigation';

export const TimelineContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 1rem;
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
`;

export const Content = styled.div`
  margin-top: 2.4rem;
  padding: 2.4rem;
  box-shadow: ${({ theme }) => theme.new.shadows.medium.boxShadow};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.new.colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Horizontal = styled.hr`
  border-width: 0;
  background-color: ${({ theme, color }) => color || theme.new.colors.N300};
  height: ${({ height }) => height || '1px'};
  margin: ${({ margin }) => margin};
`;

export const StyledTimeNavigation = styled(TimeNavigation)`
  background-color: ${({ theme }) => theme.new.colors.white};
  display: flex;
  gap: 1.2;
  justify-content: flex-end;
  padding-bottom: 1.6rem;
  padding-top: 1.8rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.stickyFilterBar};

  & > * {
    max-width: 26.4rem;
  }
`;
