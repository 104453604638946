import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'components/icons/info.svg';

export const Container = styled.div`
  position: relative;
`;

export const Info = styled(InfoIcon)`
  transform: scale(0.6);
  position: absolute;
  bottom: 0;
  right: 0;
`;
