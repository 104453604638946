import { number, func, bool, string, node, oneOf } from 'prop-types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ReactComponent as NonBillable } from 'assets/icons/nonBillable.svg';
import { ReactComponent as Support } from 'assets/icons/support.svg';
import BackfillPositionIndicator from 'components/BackfillPositionIndicator';
import IconWithTooltip from 'components/common/IconWithTooltip';
import MuiTooltip from 'components/common/MuiTooltip';
import NotesTooltip from 'components/common/NotesTooltip';
import { ReactComponent as WarningSvg } from 'components/icons/warning.svg';
import AssignmentTooltip from 'components/timeline/AssignmentTooltip';
import { ASSIGNMENT_STATUS } from 'constants/projectConstants';
import { noteShape } from 'constants/shapes';
import { useDispatchWithTimelineDates } from 'hooks';
import { editNote, updateNote } from 'state/actions/assignmentActions';
import { diffTime, stringToDate } from 'utils/date';
import { formatAmount } from 'utils/number';

import EmptyAvailability from './EmptyAvailability';
import {
  AvailabilityContainer,
  TextContainer,
  PlannedText,
  PercentageContainer,
  Pill,
  NoteSvg
} from './styles';

const Availability = ({
  busyPercentage,
  startDate: start,
  endDate: end,
  assignmentWorkHours,
  isEmpty = false,
  isNonBillable = false,
  isSupport = false,
  isPending = false,
  isBackfillPosition = false,
  note,
  onClick,
  label,
  variant = 'old',
  isFirst,
  isLast,
  status,
  completeAssignmentPeriod,
  showTooltip = true
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const editNoteRequest = useDispatchWithTimelineDates(editNote);

  const startDate = stringToDate(start);
  const endDate = stringToDate(end);

  const handleEditNote = useCallback(
    values => {
      editNoteRequest({
        noteId: note?.id,
        note: { body: values.note }
      });
    },
    [editNoteRequest, note?.id]
  );

  if (isEmpty || busyPercentage === 0) {
    return <EmptyAvailability startDate={startDate} endDate={endDate} />;
  }

  const isAddTooltip = diffTime(start, end, 'day') <= 10;

  const workHours = formatAmount(assignmentWorkHours);

  return (
    <AvailabilityContainer
      percentage={busyPercentage}
      startDate={startDate}
      endDate={endDate}
      onClick={onClick}
      isPending={isPending}
      $isNonBillable={isNonBillable}
      $isSupport={isSupport}
      isBackfillPosition={isBackfillPosition}
      variant={variant}
      isFirst={isFirst}
      isLast={isLast}
      status={status}
    >
      <TextContainer
        $isNonBillable={isNonBillable}
        $isSupport={isSupport}
        percentage={busyPercentage}
        variant={variant}
      >
        {label}
        {isNonBillable && (
          <IconWithTooltip
            icon={<NonBillable />}
            message={intl.formatMessage({ id: 'common.nonBillable' })}
          />
        )}
        {isSupport && (
          <IconWithTooltip
            icon={<Support />}
            message={intl.formatMessage({ id: 'common.support' })}
          />
        )}
        {isBackfillPosition && (
          <IconWithTooltip
            icon={<BackfillPositionIndicator />}
            message={intl.formatMessage({ id: 'common.nonBillable' })}
          />
        )}
        {note?.body && note?.body != '' && variant === 'old' && (
          <div
            onClick={e => {
              e.stopPropagation();
              dispatch(updateNote(note));
            }}
          >
            <NoteSvg
              $isNonBillable={isNonBillable}
              percentage={busyPercentage}
              data-tip
              data-for={`note-tooltip-${note?.id}`}
              data-event="click"
              data-iscapture
            />
            <NotesTooltip
              id={`note-tooltip-${note?.id}`}
              note={note?.body}
              handleEditNote={handleEditNote}
            />
          </div>
        )}
        {assignmentWorkHours && variant === 'old' && (
          <Pill $isNonBillable={isNonBillable} percentage={busyPercentage} variant={variant}>
            {`${workHours}h`}
          </Pill>
        )}
        <Pill $isNonBillable={isNonBillable} percentage={busyPercentage} variant={variant}>
          {`${busyPercentage}%`}
          {assignmentWorkHours && variant === 'new' && `  -  ${workHours}h`}
        </Pill>
        {isPending && variant === 'old' && (
          <Pill $isNonBillable={isNonBillable} percentage={busyPercentage}>
            <PlannedText>
              {intl.formatMessage({ id: 'common.cap.pendingConfirmation' })}
            </PlannedText>
          </Pill>
        )}
      </TextContainer>
      <PercentageContainer>{busyPercentage > 100 && <WarningSvg />}</PercentageContainer>
      {isAddTooltip ? (
        <AssignmentTooltip
          isPending={isPending}
          busyPercentage={busyPercentage}
          workHours={workHours}
          note={note}
          isNonBillable={isNonBillable}
          isSupport={isSupport}
          isBackfillPosition={isBackfillPosition}
        />
      ) : (
        showTooltip && (
          <MuiTooltip width="170%">
            <span>{completeAssignmentPeriod}</span>
          </MuiTooltip>
        )
      )}
    </AvailabilityContainer>
  );
};

Availability.propTypes = {
  busyPercentage: number.isRequired,
  endDate: string.isRequired,
  startDate: string.isRequired,
  assignmentWorkHours: number,
  isEmpty: bool,
  isNonBillable: bool,
  isSupport: bool,
  isPending: bool,
  note: noteShape,
  onClick: func,
  isBackfillPosition: bool,
  label: node,
  variant: string,
  isFirst: bool,
  isLast: bool,
  status: oneOf(Object.values(ASSIGNMENT_STATUS)),
  completeAssignmentPeriod: string,
  showTooltip: bool
};

export default Availability;
