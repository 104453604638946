import { bool, func, shape, string } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import NewModal from 'components/common/NewModal';
import { BUTTON_VARIANTS, ROLE_MODAL_TYPES } from 'constants/constants';
import { useDispatch } from 'hooks';
import { deleteResourceRole } from 'state/actions/settingsActions';
import { isAfter, isBefore } from 'utils/date';
import { getTableColumns } from 'utils/tableUtilities';

import ManageRoleTable from './ManageRolTable';
import {
  AccordionContainer,
  ButtonRow,
  Collapsible,
  CollapsibleBody,
  StyledButton,
  Title
} from './styles';

const tableColumnsKeys = {
  group: 'group',
  startDate: 'startDate',
  endDate: 'endDate'
};

const tableColumns = getTableColumns(tableColumnsKeys, 'settings.table');

const ManageRoleModal = ({ isShowing, hide, resource, setModalOpen, setTemporalGroupId }) => {
  const intl = useIntl();
  const [isCollapsed, setIsCollapsed] = useState(1);
  const [rolesToDelete, setRolesToDelete] = useState([]);
  const deleteRequest = useDispatch(deleteResourceRole);

  const isEdit = rolesToDelete.length > 0;

  const currentRoles = resource.temporalGroups.filter(
    group => !group.expires || isAfter(group?.endDate, new Date())
  );
  const historyRoles = resource.temporalGroups.filter(
    group => group.expires || isBefore(group?.endDate, new Date())
  );

  const handleEdit = id => {
    setModalOpen(ROLE_MODAL_TYPES.EDIT);
    setTemporalGroupId(id);
  };

  const accordions = [
    {
      accordion: 1,
      titleId: 'settings.manageRole.current',
      content: (
        <ManageRoleTable
          columns={tableColumns}
          groups={currentRoles}
          setRolesToDelete={setRolesToDelete}
          handleEdit={handleEdit}
        />
      )
    },
    {
      accordion: 2,
      titleId: 'settings.manageRole.history',
      content: <ManageRoleTable columns={tableColumns} groups={historyRoles} showActions={false} />
    }
  ];

  const resetStatusAndHide = () => {
    setIsCollapsed(1);
    hide();
    setRolesToDelete([]);
  };

  const onSave = () => {
    rolesToDelete.forEach(async id => {
      await deleteRequest({ id });
    });
  };

  const saveAndClose = async () => {
    onSave();
    resetStatusAndHide();
  };

  const onSaveAndMore = () => {
    onSave();
    setRolesToDelete([]);
    setModalOpen(ROLE_MODAL_TYPES.ADD);
  };

  return (
    <NewModal
      isShowing={isShowing}
      hide={resetStatusAndHide}
      contentPadding="2.4rem"
      title={intl.formatMessage(
        { id: resource?.name ? 'settings.manageRole.title' : 'settings.table.actions.manageRoles' },
        { fullName: resource?.name }
      )}
      maxWidth="64rem"
    >
      {accordions.map(({ accordion, titleId, content }) => (
        <AccordionContainer collapsed={isCollapsed === accordion} key={accordion}>
          <Collapsible
            onClick={() => setIsCollapsed(isCollapsed === accordion ? '' : accordion)}
            collapsed={isCollapsed}
          >
            <Title>{intl.formatMessage({ id: titleId })}</Title>
            {isCollapsed ? <ChevronUp /> : <ChevronDown />}
          </Collapsible>
          <CollapsibleBody collapsed={isCollapsed}>{content}</CollapsibleBody>
        </AccordionContainer>
      ))}
      <ButtonRow gap="1.2rem">
        <StyledButton
          id="saveAndMore"
          type="button"
          variant={BUTTON_VARIANTS.NEW_SECONDARY}
          textIntlId="assignment.form.copy"
          onClick={onSaveAndMore}
          disabled={!isEdit}
          outlined
          fullWidth
        />
        <StyledButton
          id="saveCompleteBtn"
          type="button"
          onClick={saveAndClose}
          disabled={!isEdit}
          variant={BUTTON_VARIANTS.NEW_PRIMARY}
          textIntlId="common.save"
          fullWidth
        />
      </ButtonRow>
    </NewModal>
  );
};

ManageRoleModal.propTypes = {
  hide: func.isRequired,
  isShowing: bool,
  resource: shape({ name: string, id: string }),
  setModalOpen: func,
  setTemporalGroupId: func
};

export default ManageRoleModal;
