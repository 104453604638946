import styled from 'styled-components';

import DateRange from 'components/common/DatePicker/DateRange';
import { StyledDatePicker } from 'components/common/DatePicker/styles';
import { HorizontalSeparator } from 'components/common/Layout/Separator';
import Select from 'components/common/Select';
import { ReactComponent as LeftChevron } from 'components/icons/chevron-left.svg';
import { ReactComponent as RightChevron } from 'components/icons/chevron-right.svg';
import { DASHBOARD_DATE_TYPES } from 'constants/dashboardConstants';

export const DashboardTimelineContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  z-index: ${({ theme }) => theme.zIndex.stickyTimeline};
`;

export const InputWrapper = styled.div`
  width: 12rem;

  & .dashboard-datepicker {
    height: 3.7rem;
  }
`;

export const DateRangeWrapper = styled(InputWrapper)`
  width: 24rem;
`;

export const TimelineSelect = styled(Select)`
  width: 9.2rem;
  padding: 0;
  margin-left: 1rem;

  .react-select__value-container {
    height: 3.9rem;
  }

  .react-select__control {
    border-radius: 5px;
  }

  .react-select__placeholder {
    color: ${({ theme: { colors } }) => colors.gray100};
    font-weight: 600;
  }
`;

export const TimelineMonth = styled.div`
  margin: 0 4rem;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  user-select: none;
`;

export const TimelineSwitch = styled.div`
  border: ${({ theme }) => `1px ${theme.colors.gray30} solid`};
  border-radius: 5px;
  display: flex;
  height: 3.5rem;
`;

export const TimelineBack = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0.5px solid ${({ theme }) => theme.colors.gray10};
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 3.2rem;
  width: 3.7rem;
`;

export const TimelineNext = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 3.2rem;
  width: 3.7rem;
`;

export const LeftChevronIcon = styled(LeftChevron)``;

export const RightChevronIcon = styled(RightChevron)``;

export const LookAheadWrapper = styled(InputWrapper)`
  width: 11.4rem;
`;

export const RangeTypeWrapper = styled(InputWrapper)`
  width: 10.8rem;
`;

export const DatePickerWrapper = styled(InputWrapper)`
  width: ${({ rangeType }) =>
    rangeType.value === DASHBOARD_DATE_TYPES.week.value ? '13rem' : '10.8rem'};
`;

export const StyledDateRange = styled(DateRange)`
  align-items: center;
  gap: 1rem;
  justify-content: center;
  ${StyledDatePicker} {
    height: 3.7rem;
  }
  ${HorizontalSeparator} {
    display: none;
  }
`;
