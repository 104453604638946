import styled from 'styled-components';

export const BreadcrumbContainer = styled.ul`
  list-style: none;
`;

export const BreadcrumbItem = styled.li`
  color: ${({ theme }) => theme.new.colors.neutral700};
  display: inline;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.new.fontFamily};
  line-height: 1.4rem;

  & + li::before {
    padding: 10px;
    content: '/';
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  &:last-child {
    color: ${({ theme }) => theme.new.colors.neutral500};
  }

  & a {
    color: ${({ theme }) => theme.new.colors.neutral700};
    text-decoration: underline;
    text-underline-offset: 3px;

    &:hover {
      color: ${({ theme }) => theme.new.colors.neutral600};
    }
  }
`;
