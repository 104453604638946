import { bool, func } from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import BadgePill from 'components/common/BadgePill';
import Button from 'components/common/Button';
import MuiTooltip from 'components/common/MuiTooltip';
import RoundAvatar from 'components/common/RoundAvatar';
import MiniTimelineAllocation from 'components/timeline/MiniTimelineAllocation';
import { BUTTON_VARIANTS, EMPLOYEE_MODAL_TYPES } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { peopleSearchShape } from 'constants/shapes';
import theme from 'constants/theme';
import { useAssignment, useRole } from 'hooks';
import { getMarginColor, getMarginColorBorder } from 'utils/financialColors';
import hasPermission from 'utils/hasPermission';
import { capitalizeFirstLetter } from 'utils/searchPeopleUtilities';

import { TableRow, Skills, MatchOverall, Profile, Margin, Ellipsis } from './styles';

const EmployeeColumn = ({
  employee,
  openModal,
  setPersonId,
  setAssignment,
  displayMargin = true
}) => {
  const {
    id,
    matchOverall,
    mainInfo: { role, fullName, avatarUrl },
    margin,
    mainSkills,
    seniority,
    availability: { assignments, timeOffs },
    availableWorkHours,
    specialEvents,
    hireDate,
    exitDate,
    holidays
  } = employee;

  const intl = useIntl();
  const { permissions } = useRole();

  const { assignment } = useAssignment();

  const { startDate, endDate } = assignment;

  const handleOpenModal = useCallback(() => {
    openModal(EMPLOYEE_MODAL_TYPES.PROFILE);
    setPersonId(id);
  }, [id, openModal, setPersonId]);

  const handleAssignmentModal = useCallback(() => {
    openModal(EMPLOYEE_MODAL_TYPES.ASSIGNMENT);
    setPersonId(id);
    setAssignment({ fullName, assignment });
  }, [id, openModal, setPersonId, setAssignment, fullName, assignment]);

  const allSkills = mainSkills?.map(skill => skill.skillName);
  return (
    <TableRow>
      <Profile onClick={handleOpenModal}>
        <RoundAvatar src={avatarUrl} />
        <h2>{fullName}</h2>
      </Profile>

      <div>{capitalizeFirstLetter(role.name)}</div>
      <div>{capitalizeFirstLetter(seniority)}</div>
      <Skills>
        {mainSkills?.length ? (
          <>
            <Ellipsis>{allSkills.slice(0, 3).join(', ')}</Ellipsis>
            <MuiTooltip>{allSkills.join(', ')}</MuiTooltip>
          </>
        ) : (
          '-'
        )}
      </Skills>
      <MatchOverall>{matchOverall}%</MatchOverall>
      <Margin>
        {displayMargin ? (
          <BadgePill
            borderColor={getMarginColorBorder({ theme, margin })}
            fontColor={getMarginColor({ theme, margin })}
          >
            {margin}%
          </BadgePill>
        ) : (
          '--'
        )}
      </Margin>

      <MiniTimelineAllocation
        startDate={startDate}
        endDate={endDate}
        timeOffs={timeOffs}
        assignments={assignments}
        capacity={availableWorkHours}
        specialEvents={specialEvents}
        hireDate={hireDate}
        exitDate={exitDate}
        holidays={holidays}
      />
      {hasPermission(permissions, [SCOPES.selectAssignment]) && (
        <Button variant={BUTTON_VARIANTS.NEW_PRIMARY} onClick={handleAssignmentModal}>
          {intl.formatMessage({ id: 'searchPeople.bestMatches.button.select' })}
        </Button>
      )}
    </TableRow>
  );
};

EmployeeColumn.propTypes = {
  employee: peopleSearchShape,
  openModal: func,
  setPersonId: func,
  setAssignment: func,
  displayMargin: bool
};

export default React.memo(EmployeeColumn);
