import { useState } from 'react';
import { useIntl } from 'react-intl';

import { projectSummaryShape } from 'constants/shapes';
import { useRequest } from 'hooks';
import { getFinishedAssignments, getProjectOverviewDetail } from 'state/actions/projectActions';
import {
  convertAssignmentsDataToTableFormat,
  sortAssignmentsByDefault
} from 'utils/projectsOverviewUtilities';

import Accordion from './Accordion';
import AssignmentDetailsTable from './AssignmentDetailsTable';
import { SectionHeader } from './styles';

const AssignmentsSection = ({ project }) => {
  const intl = useIntl();
  const [assignments, setAssignments] = useState();
  const [oldAssignments, setOldAssignments] = useState();

  const [{ isPending: isLoadingAssignments }] = useRequest(getProjectOverviewDetail, {
    fulfilledCallback: payload =>
      setAssignments(convertAssignmentsDataToTableFormat(payload?.assignments)),
    params: { projectId: project?.id }
  });

  const [{ isPending: isLoadingOldAssignments }] = useRequest(getFinishedAssignments, {
    fulfilledCallback: payload =>
      setOldAssignments(
        payload?.finishedAssignments?.length &&
          convertAssignmentsDataToTableFormat(payload?.finishedAssignments)
      ),
    params: { projectId: project?.id }
  });

  const assignmentSections = [
    {
      section: 2,
      title: 'common.cap.teamMembers',
      isLoadingAssignments,
      originalAssignments: assignments
    },
    {
      section: 3,
      title: 'common.cap.oldAssignments',
      isLoadingAssignments: isLoadingOldAssignments,
      originalAssignments: oldAssignments,
      old: true
    }
  ];

  return (
    <>
      <SectionHeader>
        <h2>{intl.formatMessage({ id: 'common.cap.assignments' })}</h2>
      </SectionHeader>
      {assignmentSections.map(section => (
        <Accordion data={section} key={section.section} shrink={section?.old}>
          <AssignmentDetailsTable
            old={section.old}
            isLoadingAssignments={section.isLoadingAssignments}
            originalAssignments={section.originalAssignments}
          />
        </Accordion>
      ))}
    </>
  );
};

AssignmentsSection.propTypes = {
  project: projectSummaryShape
};

export default AssignmentsSection;
