import { func, number, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import Input from 'components/common/Input';
import EditButtons from 'components/Financial/OpenPeriodsEditButtons';

import { GeneralDiscountWrapper, InputContainer, Percentage } from './styles';

function GeneralDiscountEdition({
  globalDiscountAmount,
  setGlobalDiscountEdit,
  setGlobalDiscountAmount,
  handleSave,
  initialDiscountAmount
}) {
  const intl = useIntl();

  const handleCancel = () => {
    setGlobalDiscountEdit(false);
    setGlobalDiscountAmount(initialDiscountAmount);
  };

  return (
    <GeneralDiscountWrapper>
      <p>{intl.formatMessage({ id: 'financial.splitCosts.updateResources.globalDiscount' })}</p>
      <InputContainer>
        <Percentage>%</Percentage>
        <Input
          onChange={e => setGlobalDiscountAmount(e.target.value)}
          value={globalDiscountAmount}
          variant="new"
          containerProps={{ padding: '0' }}
          borderRadius="4px"
          minHeight="3.6rem"
          paddingLeft="3.3rem"
          fullWidth
        />
      </InputContainer>
      <EditButtons
        onSave={() => handleSave(globalDiscountAmount)}
        onCancel={handleCancel}
        withText
      />
    </GeneralDiscountWrapper>
  );
}

GeneralDiscountEdition.propTypes = {
  globalDiscountAmount: oneOfType([string, number]),
  setGlobalDiscountEdit: func,
  setGlobalDiscountAmount: func,
  handleSave: func,
  initialDiscountAmount: oneOfType([string, number])
};

export default GeneralDiscountEdition;
