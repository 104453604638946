import styled from 'styled-components';

export const Container = styled.div`
  & h3 {
    ${({ theme }) => theme.new.typography.p4SemiBold};
    color: ${({ theme }) => theme.new.colors.N900};
    margin-bottom: 1.6rem;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  height: min-content;
`;

export const THead = styled.thead`
  & tr {
    ${({ theme }) => theme.new.typography.p6};
    color: ${({ theme }) => theme.new.colors.N600};
    border-bottom: 1px solid ${({ theme }) => theme.new.colors.N500};
  }

  & th {
    text-align: start;
    padding-bottom: 0.4rem;
  }
`;

export const TRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N200};
  height: 2.4rem;

  & td {
    ${({ theme }) => theme.new.typography.p4SemiBold};
    color: ${({ theme }) => theme.new.colors.N800};
    padding: 2rem 0 0.8rem 0;
  }

  & td:first-of-type {
    ${({ theme }) => theme.new.typography.p4};
    color: ${({ theme }) => theme.new.colors.N600};

    &::before {
      background-color: ${({ color }) => color || 'black'};
      border-radius: 7.5px;
      content: '';
      display: inline-block;
      height: 0.9rem;
      margin-right: 0.5rem;
      width: 0.9rem;
    }
  }
`;
