import { useState } from 'react';
import { useSelector } from 'react-redux';

import ActiveFilters from 'components/common/ActiveFilters';
import Filters from 'components/common/Filters';
import ProjectHeader from 'components/ProjectHeader';
import ProjectsTable from 'components/ProjectsOverview/ProjectsTable';
import { useRequest } from 'hooks';
import { getProjectsOverview } from 'state/actions/projectActions';
import { filterItemsByProperties } from 'utils/filtersUtilities';
import { initializeOptionsWithFieldName } from 'utils/helpers';
import {
  assignmentsGreaterFunc,
  assignmentsLowerFunc,
  convertProjectsDataToTableFormat
} from 'utils/projectsOverviewUtilities';
import { filterList } from 'utils/reducersUtilities';

import { Container, Content, FiltersContainer, Horizontal } from './styles';

const defaultGreaterFunc = (a, b) => !b || a > b;
const defaultLowerFunc = (a, b) => !a || a < b;

const tableColumnsKeys = {
  name: 'name',
  status: 'status',
  margin: 'margin',
  projectType: 'projectType',
  startDate: 'startDate',
  endDate: 'endDate',
  assignments: 'assignments'
};

const tableColumns = Object.values(tableColumnsKeys).map(columnKey => ({
  key: columnKey,
  header: `projectsOverview.headers.table.columns.${columnKey}`,
  greaterFunc:
    columnKey === tableColumnsKeys.assignments ? assignmentsGreaterFunc : defaultGreaterFunc,
  lowerFunc: columnKey === tableColumnsKeys.assignments ? assignmentsLowerFunc : defaultLowerFunc
}));

const ProjectsOverview = () => {
  const { projectStatus, assignmentStatus, serviceType } = useSelector(state => state.ui.options);
  const initialValuesDropdowns = {
    projectStatus: initializeOptionsWithFieldName(projectStatus, 'projectStatus'),
    assignmentStatus: initializeOptionsWithFieldName(assignmentStatus, 'assignmentStatus'),
    serviceType: initializeOptionsWithFieldName(serviceType, 'serviceType')
  };

  const [projects, setProjects] = useState([]);
  const [filters, setFilters] = useState({
    ...initialValuesDropdowns,
    search: '',
    hideFinished: true
  });
  const [addAssignmentTo, setAddAssignmentTo] = useState();

  const [{ isPending: isLoading }, getProjectsOverviewRequest] = useRequest(getProjectsOverview, {
    fulfilledCallback: payload => setProjects(convertProjectsDataToTableFormat(payload?.projects))
  });

  const handleClear = () => {
    setFilters(prevState => ({ ...prevState, ...initialValuesDropdowns }));
  };

  const filteredProjects = () => {
    const newProjects = filterItemsByProperties(projects, filters);
    return filterList(newProjects, filters.search, ({ name }) => name);
  };

  const activeFilters = [filters?.projectStatus, filters?.assignmentStatus, filters?.serviceType]
    ?.map(filterItem => filterItem?.filter(option => option.value))
    .flat();

  return (
    <Container>
      <ProjectHeader
        titleId="projectsOverview.title"
        handleRefresh={getProjectsOverviewRequest}
        setAddAssignmentTo={setAddAssignmentTo}
        addAssignmentTo={addAssignmentTo}
      />
      <Content>
        <FiltersContainer>
          <Filters filters={filters} setFilters={setFilters} />
          <Horizontal margin="1.6rem 0" />
          <ActiveFilters
            filters={filters}
            setFilters={setFilters}
            handleClear={handleClear}
            selectedOptions={activeFilters}
          />
        </FiltersContainer>
        <ProjectsTable
          projects={filteredProjects()}
          columns={tableColumns}
          isLoading={isLoading}
          setAddAssignmentTo={setAddAssignmentTo}
          getProjectsRequest={getProjectsOverviewRequest}
        />
      </Content>
    </Container>
  );
};

export default ProjectsOverview;
