import styled, { css } from 'styled-components';

import { ReactComponent as UnstyledCancelIcon } from 'assets/icons/close.svg';
import { ReactComponent as UnstyledSaveIcon } from 'assets/icons/save.svg';
import Button from 'components/common/Button';

const iconStyles = css`
  & path {
    stroke: ${({ theme }) => theme.new.colors.N600};
  }
`;

export const CancelIcon = styled(UnstyledCancelIcon)`
  ${iconStyles}
`;

export const SaveIcon = styled(UnstyledSaveIcon)`
  ${iconStyles}
`;

export const StyledButton = styled(Button)`
  height: 3.6rem;
  min-width: auto;
  padding: ${({ withText }) => (withText ? '1.2rem' : 0)};
  width: ${({ withText }) => (withText ? 'auto' : '4.4rem')};
`;
