import styled from 'styled-components';

import LoadingWrapper from 'components/common/LoadingWrapper';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 1.6rem;
  width: 100%;
`;

export const Block = styled.div`
  border-radius: 6px;
  background: ${({ theme }) => theme.new.colors.white};
  box-shadow: 0 0 2px 0 rgba(79, 94, 113, 0.12), 0 2px 4px 0 rgba(79, 94, 113, 0.11),
    0 4px 8px 0 rgba(79, 94, 113, 0.1);
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  row-gap: 1.6rem;
  align-self: stretch;
  padding-bottom: 1.6rem;
  flex-grow: 1;
`;

export const QuarterNumber = styled.div`
  display: flex;
  height: 6.1rem;
  flex-direction: column;
  align-items: flex-start;

  span {
    ${({ theme }) => theme.new.typography.h3}
    color: ${({ theme }) => theme.new.colors.P800};
    font-size: 3.2rem;
    letter-spacing: -0.048rem;
    line-height: 3.6rem;
  }
`;

export const QuarterInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.4rem;
  flex: 1 0 0;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex-wrap: wrap;
  span {
    ${({ theme }) => theme.new.typography.p3Bold}
    color: ${({ theme }) => theme.new.colors.N800};
    white-space: nowrap;
    font-size: ${({ isFTE }) => isFTE && '1.6rem'};
    &:first-of-type {
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      color: ${({ theme, isFTE }) => (isFTE ? theme.new.colors.N600 : theme.new.colors.N800)};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  padding: 0 1.6rem;
  align-items: flex-start;
  gap: 1.2rem;
  align-self: stretch;
`;

export const Header = styled.div`
  display: flex;
  padding: 0.4rem 1.6rem;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  background: ${({ theme, isCurrent }) =>
    isCurrent ? theme.new.colors.P100 : theme.new.colors.N100_05};

  span {
    ${({ theme }) => theme.new.typography.p6Medium}
    color: ${({ theme, isCurrent }) => (isCurrent ? theme.new.colors.P900 : theme.new.colors.N900)};
    opacity: 1;
  }
`;

export const StyledLoadingWrapper = styled(LoadingWrapper)`
  width: 100%;
`;
