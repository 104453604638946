import { bool, func, number, string } from 'prop-types';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-option.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as FolderPlusIcon } from 'assets/icons/folder-plus.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import { ReactComponent as DeleteIcon } from 'components/icons/delete.svg';
import ProjectModal from 'components/timeline/ProjectModal';
import EditProjectStatusModal from 'components/timeline/ProjectStatus/EditProjectStatusModal';
import { FULFILLED } from 'constants/actionStatusConstants';
import { POSITIONS_TYPES } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import routesPaths from 'constants/routesPaths';
import { projectShape } from 'constants/shapes';
import { useConfirm, useDispatch, useRole } from 'hooks';
import { archiveProject, deleteProject } from 'state/actions/projectActions';
import { stringToDate } from 'utils/date';
import hasPermission from 'utils/hasPermission';
import { routeWithProps } from 'utils/helpers';

import OptionsButton from '../OptionsButton';

const ProjectOptionsButton = ({
  project,
  projectId,
  firstOptionCallback,
  isTimelineView = true,
  handleRefreshData,
  className
}) => {
  const history = useHistory();
  const intl = useIntl();
  const [editProject, setEditProject] = useState();
  const [isShowEditProject, setIsShowEditProject] = useState(false);
  const [editProjectStatus, setEditProjectStatus] = useState(null);

  const archiveProjectRequest = useDispatch(archiveProject);
  const deleteProjectRequest = useDispatch(deleteProject);
  const { permissions } = useRole();

  const id = project?.id || projectId;
  const currentProjectStatus = project?.allStatuses.at(-1);

  const requestWithRefresh = async request => {
    const { type } = await request(id);
    if (type.endsWith(`/${FULFILLED}`) && handleRefreshData) {
      handleRefreshData();
    }
  };

  const [ConfirmationDelete, confirmDelete] = useConfirm({
    title: intl.formatMessage({ id: 'modal.confirm.title' }),
    message: intl.formatMessage({ id: 'template.deleteEntity' }, { entity: 'project' }),
    onConfirm: async () => requestWithRefresh(deleteProjectRequest),
    confirmLabelIntlId: 'common.delete',
    cancelLabelIntlId: 'common.cancel'
  });

  const [ConfirmationArchive, confirmArchive] = useConfirm({
    title: intl.formatMessage({ id: 'modal.confirm.title' }),
    message: intl.formatMessage({ id: 'modal.confirm.archive.message' }),
    onConfirm: async () => requestWithRefresh(archiveProjectRequest),
    confirmLabelIntlId: 'modal.confirm.archive.accept',
    cancelLabelIntlId: 'common.cancel'
  });

  const projectOptions = useMemo(
    () => [
      ...(hasPermission(permissions, [SCOPES.seeProjectDetails])
        ? [
            {
              id: 'viewDetail',
              label: intl.formatMessage({ id: 'common.viewDetail' }),
              onClick: () => history.push(routeWithProps(routesPaths.projectDetails, { id })),
              icon: <FileIcon />
            }
          ]
        : []),
      ...(hasPermission(permissions, [SCOPES.editProjects, SCOPES.editProjectsNotes])
        ? [
            {
              id: 'editOpt',
              label: intl.formatMessage({ id: 'projects.edit' }),
              onClick: () => {
                setEditProject(projectId);
                setIsShowEditProject(true);
              },
              icon: <EditIcon />
            }
          ]
        : []),
      ...(currentProjectStatus && hasPermission(permissions, [SCOPES.editProjectStatus])
        ? [
            {
              id: 'editDatesOpt',
              label: intl.formatMessage({ id: 'projects.editStatus' }),
              onClick: () => {
                setEditProjectStatus({
                  project,
                  statusId: currentProjectStatus.id,
                  initialValues: {
                    id: currentProjectStatus.id,
                    name: currentProjectStatus.status,
                    startDate: stringToDate(currentProjectStatus.startDate),
                    endDate: stringToDate(currentProjectStatus.endDate)
                  }
                });
              },
              icon: <CalendarIcon />
            }
          ]
        : []),
      ...(!isTimelineView && hasPermission(permissions, [SCOPES.createAssignment])
        ? [
            {
              id: 'addAssignmentOpt',
              label: intl.formatMessage({
                id: 'projects.newAssignment'
              }),
              onClick: () => firstOptionCallback(),
              icon: <PlusIcon />
            }
          ]
        : []),
      ...(isTimelineView && hasPermission(permissions, [SCOPES.addProjectsStatus])
        ? [
            {
              id: 'statusOpt',
              label: intl.formatMessage({
                id: 'projects.addNewStatus'
              }),
              onClick: () => firstOptionCallback(),
              icon: <PlusIcon />
            }
          ]
        : []),
      ...(hasPermission(permissions, [SCOPES.archiveProjects])
        ? [
            {
              id: 'archiveOpt',
              label: intl.formatMessage({ id: 'projects.archive' }),
              onClick: () => confirmArchive(),
              icon: <FolderPlusIcon />
            }
          ]
        : []),
      ...(hasPermission(permissions, [SCOPES.deleteProjects])
        ? [
            {
              id: 'deleteOpt',
              label: intl.formatMessage({ id: 'projects.delete' }),
              onClick: () => confirmDelete(),
              icon: <DeleteIcon />
            }
          ]
        : [])
    ],
    [
      permissions,
      intl,
      isTimelineView,
      history,
      projectId,
      firstOptionCallback,
      confirmArchive,
      confirmDelete,
      id,
      project,
      currentProjectStatus
    ]
  );

  return (
    <>
      <OptionsButton
        options={projectOptions}
        position={
          isTimelineView
            ? [POSITIONS_TYPES.RIGHT, POSITIONS_TYPES.TOP_RIGHT]
            : [POSITIONS_TYPES.BOTTOM_LEFT, POSITIONS_TYPES.TOP_LEFT]
        }
        isTimelineView={isTimelineView}
        className={className}
      />
      <ProjectModal
        handleRefresh={handleRefreshData}
        isShowing={!!editProject || isShowEditProject}
        hide={() => {
          setEditProject(null);
          setIsShowEditProject(false);
        }}
        edit
        projectId={projectId}
      />
      <ConfirmationDelete />
      <ConfirmationArchive />
      {editProjectStatus && (
        <EditProjectStatusModal
          isShowing={!!editProjectStatus}
          hide={() => setEditProjectStatus(null)}
          handleRefreshData={handleRefreshData}
          {...editProjectStatus}
        />
      )}
    </>
  );
};

ProjectOptionsButton.propTypes = {
  project: projectShape,
  firstOptionCallback: func,
  isTimelineView: bool,
  className: string,
  handleRefreshData: func,
  projectId: number
};

export default ProjectOptionsButton;
