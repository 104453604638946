import { number } from 'prop-types';
import { useIntl } from 'react-intl';

import BadgePill from 'components/common/BadgePill';
import theme from 'constants/theme';

import { StyledPeopleIcon, Container, StyledTooltip, PeopleContainer } from './styles';

const AssignmentColumn = ({ confirmed, pending, open, overAssigned }) => {
  const intl = useIntl();

  return (
    <Container>
      <PeopleContainer>
        <StyledPeopleIcon />
        {confirmed}
        <StyledTooltip>{intl.formatMessage({ id: 'status.confirmed' })}</StyledTooltip>
      </PeopleContainer>
      {open > 0 && (
        <BadgePill
          color={theme.new.colors.BO500}
          borderColor={theme.new.colors.BO500}
          fontColor={theme.new.colors.white}
          isFilled
          isRounded
        >
          <StyledTooltip>{intl.formatMessage({ id: 'common.open' })}</StyledTooltip>
          {open}
        </BadgePill>
      )}
      {pending > 0 && (
        <BadgePill
          color={theme.new.colors.BP500}
          borderColor={theme.new.colors.BP500}
          isFilled
          isRounded
        >
          <StyledTooltip>{intl.formatMessage({ id: 'common.pending' })}</StyledTooltip> {pending}
        </BadgePill>
      )}
      {overAssigned > 0 && (
        <BadgePill
          color={theme.new.colors.BOA500}
          borderColor={theme.new.colors.BOA500}
          fontColor={theme.new.colors.white}
          isFilled
          isRounded
        >
          <StyledTooltip>{intl.formatMessage({ id: 'common.overassigned' })}</StyledTooltip>
          {overAssigned}
        </BadgePill>
      )}
    </Container>
  );
};

AssignmentColumn.propTypes = {
  confirmed: number,
  pending: number,
  open: number,
  overAssigned: number
};

export default AssignmentColumn;
