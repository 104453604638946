import { arrayOf, number } from 'prop-types';
import { useIntl } from 'react-intl';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { utilizationBenchOverview } from 'constants/shapes';

import { Container, Text, Total } from './styles';

const COLORS = [
  'url(#color1)',
  'url(#color2)',
  'url(#color3)',
  'url(#color4)',
  'url(#color5)',
  'url(#color6)'
];

function CustomPieChart({ data, total }) {
  const intl = useIntl();

  return (
    <Container>
      <Text>{intl.formatMessage({ id: 'common.benchComposition' })}</Text>
      <ResponsiveContainer width="100%" height="100%" minHeight={350} minWidth={248}>
        <PieChart>
          <defs>
            <linearGradient id="color1" x1="0" y1="0" x2="1" y2="1">
              <stop stopColor="#7A1BF3" stopOpacity={1} />
              <stop offset="85%" stopColor="#A284F9" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="color2" x1="0" y1="0" x2="1" y2="1">
              <stop stopColor="#0090D7" stopOpacity={1} />
              <stop offset="85%" stopColor="#58C0F3" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="color3" x1="0" y1="0" x2="1" y2="1">
              <stop stopColor="#67BB0B" stopOpacity={1} />
              <stop offset="85%" stopColor="#A4E064" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="color4" x1="0" y1="0" x2="1" y2="1">
              <stop stopColor="#FF8396" stopOpacity={1} />
              <stop offset="85%" stopColor="#FF5674" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="color5" x1="0" y1="0" x2="1" y2="1">
              <stop stopColor="#E96800" stopOpacity={1} />
              <stop offset="85%" stopColor="#FFA94C" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="color6" x1="0" y1="0" x2="1" y2="1">
              <stop stopColor="#E8AC00" stopOpacity={1} />
              <stop offset="85%" stopColor="#FFDA4D" stopOpacity={0.6} />
            </linearGradient>
          </defs>
          <Pie
            cy={120}
            data={data}
            startAngle={300}
            endAngle={600}
            innerRadius={90}
            outerRadius={110}
            paddingAngle={5}
            dataKey="value"
            nameKey="departmentName"
            cornerRadius={40}
          >
            {data?.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Total>
        <p>{`${intl.formatMessage({ id: 'common.totalBench' })}: `}</p>
        <span>{total?.toFixed(1)}%</span>
      </Total>
    </Container>
  );
}

CustomPieChart.propTypes = {
  data: arrayOf(utilizationBenchOverview),
  total: number
};

export default CustomPieChart;
