import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 2rem;
  height: 2rem;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.gridTimeline};
  & > svg {
    path {
      stroke: ${({ theme }) => theme.new.colors.neutral600};
      fill: transparent;
      transform: scale(${16 / 19});
    }
  }
`;
