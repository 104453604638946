import { arrayOf, bool, func, object } from 'prop-types';

import {
  TimelineRow,
  TimelineRowHeaderContent,
  TimelineRowHeaderSubtitle,
  TimelineRowContent,
  TimelineRowHeader
} from 'components/common/Layout/Timeline';
import PersonProjectsAssignments from 'components/timeline/PersonProjectsAssignments';
import { peopleShape } from 'constants/shapes';
import { useHeights, useTimelineDates, useWidths } from 'hooks';

const PersonProjects = ({
  projectsPerson,
  person,
  setAddAssignment,
  setEditAssignment,
  isReadOnly = false
}) => {
  const { timelineHeader } = useWidths();
  const { timelineFragmentMedium } = useHeights();
  const { startDate: startDateTimeline, endDate: endDateTimeline } = useTimelineDates();
  const hasActiveProject = project =>
    project.availability.find(availability => availability.value.busyPercentage > 0);

  const projectsWithActive = projectsPerson.filter(project => hasActiveProject(project));

  return (
    <>
      {projectsWithActive.map(project => (
        <TimelineRow isNested key={`${person.id}-${project.name}`}>
          <TimelineRowHeader isNested isAddBorders>
            <TimelineRowHeaderContent width={timelineHeader} isNested onClick={null}>
              <TimelineRowHeaderSubtitle>{project.name}</TimelineRowHeaderSubtitle>
            </TimelineRowHeaderContent>
          </TimelineRowHeader>
          <TimelineRowContent height={timelineFragmentMedium}>
            <PersonProjectsAssignments
              project={project}
              person={person}
              availabilities={project.availability}
              startDateTimeline={startDateTimeline}
              endDateTimeline={endDateTimeline}
              setAddAssignment={setAddAssignment}
              setEditAssignment={setEditAssignment}
              isReadOnly={isReadOnly}
            />
          </TimelineRowContent>
        </TimelineRow>
      ))}
    </>
  );
};

PersonProjects.propTypes = {
  projectsPerson: arrayOf(object).isRequired,
  person: peopleShape.isRequired,
  setAddAssignment: func.isRequired,
  setEditAssignment: func.isRequired,
  isReadOnly: bool
};
export default PersonProjects;
