import { snakeCase } from 'lodash';
import { arrayOf, bool, func } from 'prop-types';
import { useEffect, useState } from 'react';

import FilterDropdown from 'components/common/FilterDropdown';
import SearchInput from 'components/common/SearchInput';
import { SETTINGS_FILTERS } from 'constants/constants';
import { reportsResourceShape } from 'constants/shapes';
import useGroups from 'hooks/useGroups';
import useRoleGroups from 'hooks/useRoleGroups';
import { getActiveData } from 'utils/filtersUtilities';
import { initializeOptionsWithFieldName } from 'utils/helpers';
import { filterList } from 'utils/reducersUtilities';
import { isActive } from 'utils/searchPeopleUtilities';
import { getTableColumns } from 'utils/tableUtilities';

import MappingTable from '../MappingTable';
import { Grid, Header } from './styles';

const tableColumnsKeys = {
  group: 'group',
  name: 'roleName'
};

const tableColumns = getTableColumns(tableColumnsKeys, 'settings.groupTable');

const MappingTableAndFilters = ({ roleGroups, isLoading, setModalOpen, setRelation }) => {
  const [search, setSearch] = useState('');
  const [tableResources, setTableResources] = useState({
    initialResources: [],
    resourcesToDisplay: []
  });

  const [roleGroupOptions, setRoleGroupOptions] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { groupOptions } = useRoleGroups();
  const { groupOptions: groups } = useGroups();
  const { initialResources, resourcesToDisplay } = tableResources;

  const filterResources = (resourceGroup, selectedRoles) => {
    const roles = selectedRoles.length ? selectedRoles.map(role => snakeCase(role)) : [];
    const roleMatches = resourceGroup?.filter(({ group }) => isActive(roles, group));
    return roleMatches;
  };

  const handleRoleFilter = selection => {
    const selectedRoles = getActiveData(selection.options);
    setSelectedOptions(selectedRoles);
  };

  const filterBySearchAndRole = () => {
    const filteredBySearch = filterList(initialResources, search, ({ role }) => role?.name);
    const filteredData = filterResources(filteredBySearch, selectedOptions);
    setTableResources({
      ...tableResources,
      resourcesToDisplay: filteredData?.sort(
        ({ fullName: a }, { fullName: b }) => a && b && a.localeCompare(b)
      )
    });
  };

  useEffect(() => {
    filterBySearchAndRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleGroups, search, roleGroupOptions, selectedOptions]);

  useEffect(() => {
    setRoleGroupOptions(initializeOptionsWithFieldName(groupOptions, SETTINGS_FILTERS.groupRoles));
    setGroupFilter(initializeOptionsWithFieldName(groups, SETTINGS_FILTERS.groupRoles));
  }, [groupOptions, groups]);

  useEffect(() => {
    setTableResources({ ...tableResources, initialResources: roleGroups });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleGroups]);

  return (
    <>
      <Grid>
        <Header>
          <SearchInput
            className="accessRoles"
            placeholderId="settings.accessRoles.searchRole"
            value={search}
            onChange={setSearch}
            variant="new"
          />
          <FilterDropdown
            labelId="common.group"
            options={groupFilter}
            onFilter={handleRoleFilter}
            queryKey={SETTINGS_FILTERS.groupRoles}
            withIntlOptions={false}
            searchIntlId="common.cap.searchGroupRole"
            alignRight
          />
        </Header>
        <MappingTable
          resources={resourcesToDisplay}
          columns={tableColumns}
          isLoading={isLoading}
          setModalOpen={setModalOpen}
          setRelation={setRelation}
        />
      </Grid>
    </>
  );
};

MappingTableAndFilters.propTypes = {
  roleGroups: arrayOf(reportsResourceShape),
  isLoading: bool,
  setModalOpen: func,
  setRelation: func
};

export default MappingTableAndFilters;
