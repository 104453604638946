import { GoogleOAuthProvider } from '@react-oauth/google';
import { setAutoFreeze } from 'immer';
import includes from 'lodash/includes';
import { render } from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from 'components/App';
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from 'constants/constants';
import httpClient from 'httpClient';
import applyDefaultInterceptors from 'httpClient/applyDefaultInterceptors';
import locales from 'locales';
import configureStore from 'state/store/configureStore';
import 'styles/styles.scss';

require('./favicon.ico'); // Tell webpack to load favicon.ico

setAutoFreeze(false);

// Load service worker
if (process.env.ENABLE_PWA) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/main-sw.js');
  });
}

// Fix for browsers that don't implement Intl by default e.g.: Safari)
if (!window.Intl) {
  require.ensure(
    [
      '@formatjs/intl-relativetimeformat',
      '@formatjs/intl-relativetimeformat/locale-data/en.js',
      '@formatjs/intl-relativetimeformat/locale-data/es.js'
    ],
    require => {
      require('@formatjs/intl-relativetimeformat/polyfill');
      require('@formatjs/intl-relativetimeformat/locale-data/en.js');
      require('@formatjs/intl-relativetimeformat/locale-data/es.js');
    }
  );
}

const usersLocale = navigator.language.split('-')[0];
const supportedUserLocale = includes(SUPPORTED_LANGUAGES, usersLocale);
const locale = supportedUserLocale ? usersLocale : DEFAULT_LANGUAGE;
const messages = locales[locale];

const { persistor, store } = configureStore();

// TODO: remove hardcode before deploy to production
const GOOGLE_CLIENT_ID =
  process.env.GOOGLE_CLIENT_ID ||
  '657071218115-af6an37q4o3t2kvjfmkqh8af95nmcjn7.apps.googleusercontent.com';

const renderApp = Component => {
  render(
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <IntlProvider locale={locale} messages={messages} defaultLocale="en">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Component />
          </PersistGate>
        </Provider>
      </IntlProvider>
    </GoogleOAuthProvider>,
    document.getElementById('app')
  );
};

applyDefaultInterceptors(store, httpClient);
renderApp(App);
