import { string } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as BackfillIcon } from 'assets/icons/backfill.svg';
import MuiTooltip from 'components/common/MuiTooltip';

import { BackfillContainer } from './styles';

const BackfillPositionIndicator = ({ width = '1.7rem', height = '1.8rem', className = '' }) => {
  const intl = useIntl();
  return (
    <BackfillContainer width={width} height={height} className={className}>
      <MuiTooltip>{intl.formatMessage({ id: 'assignment.backfillPosition.tooltip' })}</MuiTooltip>
      <BackfillIcon width={width} height={height} />
    </BackfillContainer>
  );
};

BackfillPositionIndicator.propTypes = {
  width: string,
  height: string,
  className: string
};

export default BackfillPositionIndicator;
