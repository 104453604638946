import { arrayOf, bool, func, instanceOf, number, oneOf, oneOfType, shape } from 'prop-types';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import InfoTooltip from 'components/Financial/InfoTooltip';
import { AllocationBillInput } from 'components/Financial/Inputs';
import OpenPeriodsEditButtons from 'components/Financial/OpenPeriodsEditButtons';
import PeriodInfoModal from 'components/Financial/PeriodInfoModal';
import { BILLING_UNITS } from 'constants/constants';
import { DISPLAY_MONTH_DAY_DATE } from 'constants/dateFormats';
import { openPeriodShape } from 'constants/shapes';
import { useDispatch, useOnClickOutside, useStatus } from 'hooks';
import { updateOpenPeriodHeader, updatePeriodInfo } from 'state/actions/financialActions';
import { dateToString } from 'utils/date';
import { precisionFormat } from 'utils/number';

import OptionsPeriodButton from '../OptionsPeriodButton';
import PeriodTotal from '../PeriodTotal';
import {
  Container,
  Header,
  HeaderInfo,
  Name,
  Range,
  Info,
  Actions,
  Titles,
  NoteSvg,
  OpenPeriodContainerScroll,
  StyledColumn,
  StyledRow,
  TotalContainer,
  OpenPeriodInput
} from './styles';

function PeriodItem({
  period,
  innerRef,
  handleScroll,
  index,
  onChangeEditingPeriod,
  editingPeriod,
  onPeriodChange,
  billingUnit,
  setEditingPeriod,
  handleSaveEditingPeriod,
  globalDiscountEdit,
  globalDiscountAmount
}) {
  const intl = useIntl();
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [additionalCharges, setAdditionalCharges] = useState({
    fixedPrice: period.fixedPrice,
    additional: period.additional
  });
  const resetUpdateRequest = useDispatch(updatePeriodInfo.reset);
  const updateOpenPeriodRequest = useDispatch(updateOpenPeriodHeader);
  const { isLoading: isLoadingUpdatePeriod, error: updateError } =
    useStatus(updateOpenPeriodHeader);
  const { isPending } = useStatus(updatePeriodInfo);

  const isEditing = editingPeriod === period.id;

  const outsideRef = useOnClickOutside(
    () => isEditing && onChangeEditingPeriod(null),
    event => document.querySelector('#savePeriodBtn')?.contains(event.target)
  );

  const handleOnClickOption = useCallback(
    callback => {
      if (isEditing) {
        onChangeEditingPeriod(null);
      } else callback();
    },
    [isEditing, onChangeEditingPeriod]
  );

  const handleEditPeriodHeader = values => {
    updateOpenPeriodRequest({
      id: period?.id,
      projectId: period.projectId,
      ...values
    });
  };

  const handleAdditionalCharges = (field, value) => {
    const formattedValue = typeof value === 'string' ? Number(precisionFormat(value)) : value;
    setAdditionalCharges(prev => ({ ...prev, [field]: formattedValue }));
  };

  return (
    <>
      <Container isEditing={isEditing} minWidth="24.8rem" ref={outsideRef}>
        <Header>
          <HeaderInfo>
            <Info>
              <Name>{period.name}</Name>
              {period?.note && (
                <InfoTooltip text={period.note} width="3.5rem">
                  <NoteSvg $isEditing={isEditing} />
                </InfoTooltip>
              )}
            </Info>
            <Range>
              {intl.formatMessage(
                { id: 'financial.table.period.dateRange' },
                {
                  startDate: dateToString(period.startDate, DISPLAY_MONTH_DAY_DATE),
                  endDate: dateToString(period.endDate, DISPLAY_MONTH_DAY_DATE)
                }
              )}
            </Range>
          </HeaderInfo>
          <Actions>
            {editingPeriod === period?.id ? (
              <OpenPeriodsEditButtons
                saveId="savePeriodBtn"
                cancelId="cancelPeriodBtn"
                onSave={() => handleSaveEditingPeriod(additionalCharges)}
                onCancel={() => setEditingPeriod(null)}
                isLoading={isPending}
              />
            ) : (
              <OptionsPeriodButton
                period={period}
                editPeriodInfo={() => setIsShowEditModal(true)}
                handleOnClickOption={handleOnClickOption}
                onChangeEditingPeriod={onChangeEditingPeriod}
                isEditing={isEditing}
              />
            )}
          </Actions>
        </Header>
        <Titles isEditing={isEditing}>
          <StyledColumn padding="0 1rem">
            {intl.formatMessage({ id: 'financial.table.period.alloc' })}
          </StyledColumn>
          <StyledColumn padding="0 1rem">
            {intl.formatMessage({ id: 'financial.table.period.bill' })}
          </StyledColumn>
        </Titles>
        <OpenPeriodContainerScroll
          hiddenScroll
          ref={el => {
            innerRef.current[index] = el;
          }}
          onScroll={handleScroll}
          maxHeight="24rem"
        >
          {period.info.map(
            (
              {
                allocation,
                setupId,
                bill,
                isOut,
                isGettingAssigned,
                capacity,
                allocationPercentage
              },
              index
            ) => {
              const disabled = !isEditing || (allocation === null && bill === null);
              const allocValue = allocation?.[billingUnit];
              const billValue = bill?.[billingUnit];

              return (
                <StyledRow key={index} isEditing={isEditing} gray={isOut || isGettingAssigned}>
                  <StyledColumn separator isEditing={isEditing} disabled={disabled}>
                    <AllocationBillInput
                      key={`${billingUnit}${isEditing}`}
                      value={allocValue}
                      billingUnit={billingUnit}
                      id={setupId}
                      capacity={capacity}
                      allocationPercentage={allocationPercentage}
                      field="allocation"
                      onChange={onPeriodChange}
                      onBlur={onPeriodChange}
                      disabled={!isEditing}
                      showInput={isEditing}
                      autofocus={false}
                      CustomInput={OpenPeriodInput}
                    />
                  </StyledColumn>
                  <StyledColumn isEditing={isEditing} disabled={disabled}>
                    <AllocationBillInput
                      key={`${billingUnit}${isEditing}`}
                      value={billValue}
                      billingUnit={billingUnit}
                      id={setupId}
                      capacity={capacity}
                      allocationPercentage={allocationPercentage}
                      field="bill"
                      onChange={onPeriodChange}
                      onBlur={onPeriodChange}
                      disabled={!isEditing}
                      showInput={isEditing}
                      autofocus={false}
                      CustomInput={OpenPeriodInput}
                    />
                  </StyledColumn>
                </StyledRow>
              );
            }
          )}
        </OpenPeriodContainerScroll>
        <TotalContainer>
          <PeriodTotal
            key={period.id}
            period={period}
            editingPeriod={editingPeriod}
            additionalCharges={additionalCharges}
            globalDiscountEdit={globalDiscountEdit}
            globalDiscountAmount={globalDiscountAmount}
            handleAdditionalCharges={handleAdditionalCharges}
          />
        </TotalContainer>
      </Container>
      {isShowEditModal && (
        <PeriodInfoModal
          hide={() => setIsShowEditModal(false)}
          open={isShowEditModal}
          openPeriod={period}
          isEdit
          error={updateError}
          isLoading={isLoadingUpdatePeriod}
          onSubmit={handleEditPeriodHeader}
          resetRequest={resetUpdateRequest}
        />
      )}
    </>
  );
}

PeriodItem.propTypes = {
  editingPeriod: number,
  handleScroll: func,
  index: number,
  innerRef: oneOfType([func, shape({ current: arrayOf(instanceOf(Element)) })]),
  onChangeEditingPeriod: func,
  onPeriodChange: func,
  period: openPeriodShape,
  billingUnit: oneOf(Object.values(BILLING_UNITS)),
  setEditingPeriod: func,
  handleSaveEditingPeriod: func,
  additionalExpanded: bool,
  setAdditionalExpanded: func,
  globalDiscountEdit: bool,
  globalDiscountAmount: number
};

export default PeriodItem;
