import { number } from 'prop-types';
import { useTheme } from 'styled-components';

const LabelFormatter = ({ x, y, width, value }) => {
  const theme = useTheme();

  return (
    <text
      x={width + x}
      y={y}
      dy={14}
      dx={10}
      fill={theme.colors.black}
      fontSize={16}
      fontWeight={theme.fontWeights.semiBold}
      fontFamily={theme.fontFamily}
    >
      {`${value}%`}
    </text>
  );
};

LabelFormatter.propTypes = {
  value: number,
  width: number,
  x: number,
  y: number
};

export default LabelFormatter;
