import dayjs from 'dayjs';
import {
  shape,
  bool,
  number,
  string,
  oneOfType,
  arrayOf,
  object,
  func,
  instanceOf,
  any,
  oneOf
} from 'prop-types';

import {
  ALLOCATION_TIMELINE_TYPES,
  BILLING_UNITS,
  PROJECT_USERS_TYPES,
  SORT_DIR
} from './constants';

export const optionShape = shape({
  value: oneOfType([string, bool, number, object]),
  label: string.isRequired,
  key: string,
  selected: bool
});

export const amountWithUnitShape = shape({
  [BILLING_UNITS.HOURLY]: number,
  [BILLING_UNITS.DAILY]: number
});

export const newProjectStatusShape = shape({
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  endDate: oneOfType([string, instanceOf(Date)]).isRequired,
  onClick: func
});

export const statusShape = shape({
  status: string
});

export const utilizationTooltipShape = shape({
  info: shape({ nameId: string.isRequired }).isRequired,
  utilizationPercentage: number.isRequired,
  scheduled: number.isRequired,
  billablePercentage: number.isRequired,
  billable: number.isRequired,
  nonBillablePercentage: number.isRequired,
  nonBillable: number.isRequired
});

export const defaultFormShape = {
  initialValues: object,
  onSubmit: func.isRequired,
  resetStatus: func.isRequired,
  error: oneOfType([string, object]),
  hide: func.isRequired,
  status: string
};

export const defaultModalShape = {
  isShowing: bool.isRequired,
  hide: func.isRequired,
  edit: bool,
  initialValues: object
};

export const resourceShape = shape({
  id: number,
  seniority: string,
  role: string,
  area: string,
  availability: number,
  benchHours: number,
  skills: arrayOf(object)
});

export const personalInfoShape = shape({
  name: string,
  email: string,
  avatarURL: string,
  hourRate: number,
  location: string,
  role: shape({ name: string, seniority: string }),
  experiences: arrayOf(object),
  projects: arrayOf(object),
  actualAssignmentsStatus: arrayOf(string),
  industries: arrayOf(shape({ id: number, name: string }))
});

export const columnShape = shape({
  Header: string.isRequired,
  accessor: oneOfType([string, func]).isRequired,
  type: string.isRequired
});

export const skillShape = shape({
  skill: string.isRequired,
  experienceTime: string.isRequired
});

export const utilizationShape = shape({
  billable: number,
  billablePercentage: number,
  nonBillable: number,
  nonBillablePercentage: number,
  scheduledTime: number,
  totalAvailability: number,
  unscheduledTime: number,
  utilizationPercentage: number
});

export const projectStatusFormShape = {
  onSubmit: func.isRequired,
  project: object.isRequired,
  initialValues: object,
  status: string,
  error: string,
  onDelete: func,
  isDeleteLoading: bool,
  submitTitleId: string.isRequired,
  disableStartDateSelector: bool
};

export const dateRangeShape = {
  selected: shape({
    startDate: instanceOf(Date),
    endDate: instanceOf(Date)
  }).isRequired,
  minDate: instanceOf(Date),
  maxDate: instanceOf(Date),
  onChange: func.isRequired,
  onSelectionEnd: func,
  names: shape({ startDate: string.isRequired, endDate: string.isRequired }).isRequired,
  labels: shape({ startDate: string.isRequired, endDate: string.isRequired }).isRequired,
  errors: string,
  separation: number,
  startDateProps: object,
  endDateProps: object,
  showWeekCounter: bool,
  keepRangeDuration: bool,
  disabled: bool,
  className: string,
  gap: string,
  variant: string
};

export const availabilitiesShape = shape({
  assignmentIds: arrayOf(number),
  assignmentsTypes: arrayOf(string),
  assignmentsWorkHours: arrayOf(number),
  busyPercentage: number,
  endDate: oneOfType([string, instanceOf(Date)]),
  pending: arrayOf(bool),
  projectIds: arrayOf(number),
  startDate: oneOfType([string, instanceOf(Date)]),
  totalWorkHours: number
});

export const noteShape = shape({
  id: number,
  title: string,
  body: string
});

export const notificationShape = shape({
  body: string.isRequired,
  createdAt: oneOfType([string, instanceOf(Date)]),
  notificationId: number.isRequired,
  personId: number,
  title: string.isRequired,
  type: string.isRequired,
  avatarUrl: string
});

export const projectListShape = shape({
  id: number.isRequired,
  name: string.isRequired,
  serviceType: string,
  currentStatus: string,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)])
});

export const financialSummaryShape = shape({
  billed: number,
  costs: number,
  profits: number,
  margin: number
});

export const projectOverviewFinancialShape = shape({
  id: number,
  name: string,
  status: string,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  totalConfirmed: number,
  clientBudget: number,
  financial: financialSummaryShape
});

export const resourcesSetupShape = shape({
  setupId: number,
  resourceName: string.isRequired,
  seniority: string,
  role: string,
  billable: bool,
  allocationPercentage: number,
  rate: oneOfType([amountWithUnitShape, number])
});

export const periodDetailShape = shape({
  id: number.isRequired,
  resource: string.isRequired,
  seniority: string,
  role: string,
  billable: bool,
  allocationPercentage: number,
  rate: oneOfType([string, number]),
  allocationHours: oneOfType([string, number]),
  bill: oneOfType([string, number])
});

export const technologiesShape = shape({
  experienceId: number,
  skillId: number,
  skillName: string
});

export const roleShape = shape({
  id: number,
  name: string
});

export const assignmentsListShape = shape({
  projectId: number.isRequired,
  assignmentId: number.isRequired,
  resourceName: string,
  role: roleShape,
  seniority: string,
  skillNames: string,
  percentage: number,
  workHours: number,
  pending: bool,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  createdAt: oneOfType([string, instanceOf(Date)]),
  ongoing: bool,
  personId: number
});

export const personExperienceShape = shape({
  experienceTime: string,
  skill: string,
  highlighted: bool
});

export const locationShape = shape({
  id: number,
  city: string,
  country: string
});

export const timeOffShape = shape({
  bambooTimeOffId: string,
  endDate: string,
  id: number,
  personId: number,
  startDate: string,
  status: string,
  timeType: string
});

export const specialEventShape = shape({
  endDate: string,
  id: number,
  startDate: string,
  type: string
});

export const peopleShape = shape({
  availability: arrayOf(availabilitiesShape),
  avatarURL: string,
  department: string,
  exitDate: string,
  experience: arrayOf(personExperienceShape),
  id: number,
  leftCompany: bool,
  location: locationShape,
  name: string,
  projects: arrayOf(object),
  role: string,
  seniority: string,
  status: string,
  timeOffs: arrayOf(timeOffShape),
  workHours: number,
  workKind: string
});

export const departmentShape = shape({
  id: number,
  name: string
});

export const highlightedSkillsShape = shape({
  id: number,
  name: string
});

export const assignmentSummaryShape = shape({
  role: roleShape,
  skillNames: string,
  seniority: string,
  percentage: number,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  note: noteShape,
  createdAt: oneOfType([string, instanceOf(Date)]).isRequired
});

export const projectSummaryShape = shape({
  name: string.isRequired,
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  endDate: oneOfType([string, instanceOf(Date)]).isRequired,
  status: string.isRequired,
  serviceType: string.isRequired,
  code: string,
  projectType: string.isRequired,
  confirmedAssignmentsCount: number,
  totalAssignmentsCount: number
});

export const projectShape = shape({
  allStatuses: arrayOf(
    shape({
      startDate: oneOfType([string, instanceOf(Date)]),
      endDate: oneOfType([string, instanceOf(Date)]),
      name: string,
      id: number
    })
  ),
  archived: bool,
  businessType: string,
  clientId: number,
  code: string,
  currentStatus: string,
  endDate: oneOfType([string, instanceOf(Date)]),
  id: number,
  name: string,
  notes: string,
  resources: arrayOf(peopleShape),
  serviceType: string,
  startDate: oneOfType([string, instanceOf(Date)]),
  projectType: string
});

export const calendarShape = shape({
  month: string,
  weeks: arrayOf(
    shape({
      starDate: string,
      endDate: string,
      start: oneOfType([string, instanceOf(Date)]),
      end: oneOfType([string, instanceOf(Date)])
    })
  ),
  year: number
});

export const glossaryShape = shape({
  subContractor: bool,
  training: bool,
  pending: bool,
  overassigned: bool,
  lowAllocation: bool
});

export const minProjectInfo = shape({
  id: number,
  name: string
});

export const dashboardResourceShape = shape({
  fullName: string.isRequired,
  avatarUrl: string,
  role: roleShape,
  department: departmentShape,
  seniority: string,
  highlightedSkills: arrayOf(highlightedSkillsShape),
  projects: arrayOf(minProjectInfo),
  harvestHours: number,
  capacityHours: number,
  assignmentHours: number,
  glossaryCategories: glossaryShape
});

export const dashboardAvailabilitiesShape = shape({
  id: number,
  available: bool
});

export const assignmentGraphShape = shape({
  name: string,
  billable: number,
  scheduled: number,
  demand: number,
  FTE: shape({
    available: number,
    billable: number,
    open: number,
    pending: number
  })
});

export const dateTypeShape = shape({
  label: object,
  value: number,
  unit: string
});

export const globalIndicatorsShape = shape({
  actualUtilization: number,
  scheduledUtilization: number,
  scheduledPendingUtilization: number,
  availableCapacity: number
});

export const resourceBenchBreakdownShape = shape({
  id: number,
  fullName: string,
  department: departmentShape,
  highlightedSkills: arrayOf(highlightedSkillsShape),
  availableFor: number
});

export const AvailableCapacityGraphShape = shape({
  name: string,
  percentage: number,
  availablePeople: number,
  peopleTotal: number
});

export const AssignmentShape = shape({
  id: number,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  assignationRole: string,
  assignationType: string,
  personId: number,
  skillIds: arrayOf(number),
  skillNames: string,
  clientId: number,
  pending: bool,
  seniority: string,
  ongoing: bool,
  backfillPosition: bool,
  workHours: number,
  percentage: number,
  projectId: number,
  harvestId: number,
  updatedAt: oneOfType([string, instanceOf(Date)]),
  createdAt: oneOfType([string, instanceOf(Date)]),
  note: noteShape,
  role: roleShape,
  creatorId: number,
  skills: arrayOf(highlightedSkillsShape)
});

export const personExperiencesShape = shape({
  id: number,
  time: number,
  skillName: string,
  skillId: number
});

export const periodShape = shape({
  id: number.isRequired,
  name: string.isRequired,
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  endDate: oneOfType([string, instanceOf(Date)]).isRequired,
  notes: string,
  cost: number,
  billed: number,
  margin: number
});

export const projectFinancialFormShape = shape({
  billedAdjustment: string,
  costsAdjustment: string,
  clientBudget: string
});

export const openPeriodShape = shape({
  id: number.isRequired,
  name: string.isRequired,
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  endDate: oneOfType([string, instanceOf(Date)]).isRequired,
  note: string,
  margin: number,
  info: arrayOf(
    shape({
      id: number,
      bill: amountWithUnitShape,
      rate: amountWithUnitShape,
      allocation: amountWithUnitShape
    })
  )
});

export const openPeriodResourcesShape = shape({
  personId: number,
  setupId: number,
  name: string,
  cost: oneOfType([amountWithUnitShape, number]),
  rate: oneOfType([amountWithUnitShape, number]),
  role: string,
  seniority: string,
  periodsInfo: arrayOf(
    shape({
      periodId: number,
      periodDetailId: number,
      allocation: oneOfType([amountWithUnitShape, number]),
      bill: oneOfType([amountWithUnitShape, number])
    })
  )
});

export const openPeriodPeriodsShape = shape({
  id: number,
  name: string,
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  endDate: oneOfType([string, instanceOf(Date)]).isRequired,
  note: string
});

export const vacationShape = shape({
  duration: number,
  endDate: oneOfType([string, instanceOf(Date)]),
  returnDate: oneOfType([string, instanceOf(Date)]),
  startDate: oneOfType([string, instanceOf(Date)])
});

export const forecastedSummariesShape = shape({
  id: number.isRequired,
  month: number.isRequired,
  year: number.isRequired,
  revenue: number.isRequired,
  cost: number.isRequired
});

export const forecastingProjectShape = shape({
  id: number.isRequired,
  name: string.isRequired,
  serviceType: string.isRequired,
  currentRevenue: number.isRequired,
  currentCosts: number.isRequired,
  forecastedSummaries: arrayOf(forecastedSummariesShape)
});

export const forecastingPeriodShape = shape({
  year: number.isRequired,
  month: number.isRequired,
  date: instanceOf(dayjs)
});

export const revenueGraphShape = shape({
  name: string,
  actualRevenue: number,
  underContract: number,
  awaitingStart: number,
  revenueGoal: number
});

export const commonPropsReactSelect = {
  clearValue: func,
  getStyles: func,
  getValue: func,
  hasValue: bool,
  isMulti: bool,
  options: arrayOf(optionShape),
  selectOption: func,
  selectProps: any,
  setValue: func,
  emotion: any
};

export const userShape = shape({
  type: oneOf(Object.values(PROJECT_USERS_TYPES)),
  fullName: string,
  avatarUrl: string
});

export const mainSkillShape = shape({
  name: string,
  time: string
});

export const reportsProfileShape = shape({
  fullName: string,
  seniority: string,
  role: string,
  country: string,
  mainSkills: arrayOf(mainSkillShape),
  englishLevel: string,
  weekHours: number,
  department: departmentShape,
  leadersFullName: string
});

export const editProfileDataShape = shape({
  mainSkills: arrayOf(mainSkillShape),
  englishLevel: string,
  figmaBio: string
});

export const reportsResourceShape = shape({
  fullName: string,
  seniority: string,
  role: string,
  country: string,
  department: string
});

export const timeOffsShape = shape({
  id: number,
  endDate: oneOfType([string, instanceOf(Date)]),
  startDate: oneOfType([string, instanceOf(Date)]),
  amountOfDays: number
});

export const tabShape = shape({
  id: string.isRequired,
  intlId: string.isRequired,
  intlVar: object
});

export const projectsReportsProfile = shape({
  assignmentId: number,
  role: string,
  totalDuration: string,
  allocationPercentage: number,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  projectName: string,
  projectServiceType: string,
  projectType: string
});

export const softSkillShape = shape({
  id: number,
  name: string
});

export const skillSetShape = shape({
  experiences: arrayOf(personExperiencesShape),
  softSkills: arrayOf(softSkillShape)
});

export const assignmentTopOverviewShape = shape({
  totalOpen: number,
  totalOpenProjects: number,
  totalPending: number,
  totalPendingProjects: number,
  totalOverassigned: number,
  totalOverassignedProjects: number
});

export const projectTopOverviewShape = shape({
  total: number,
  active: number,
  planned: number,
  finished: number,
  onHold: number,
  warranty: number,
  maintenance: number
});

export const subRoute = shape({
  title: string,
  route: string
});

export const projectOverviewHeaderShape = shape({
  id: number,
  name: string,
  assignments: {
    pending: number,
    open: number,
    confirmed: number,
    overAssigned: number
  },
  projectType: string,
  serviceType: string,
  margin: string,
  status: string,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)])
});

export const closePeriodHeaderShape = shape({
  name: string,
  periodLength: object,
  note: string,
  // generalCosts: oneOfType([string,number]), TO DO add general costs once it's supported by backend
  billed: oneOfType([string, number]),
  margin: oneOfType([string, number])
});

export const sortableTableColumnShape = shape({
  key: string,
  header: string,
  greaterFunc: func,
  lowerFunc: func
});

export const projectsOverviewFilters = shape({
  search: string,
  projectStatus: arrayOf(optionShape),
  assignmentStatus: arrayOf(optionShape),
  serviceType: arrayOf(optionShape),
  hideFinished: bool
});

export const searchPeopleFilters = shape({
  search: string,
  role: arrayOf(optionShape),
  seniority: arrayOf(optionShape),
  skills: arrayOf(optionShape),
  availability: arrayOf(optionShape),
  englishLevel: arrayOf(optionShape)
});

export const specialEventsShape = shape({
  id: number,
  personId: number,
  specialEventType: string,
  skillId: number,
  skillName: string,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)])
});

export const trainingConversionInitialValuesShape = shape({
  dates: shape({
    startDate: oneOfType([string, instanceOf(Date)]),
    endDate: oneOfType([string, instanceOf(Date)])
  }),
  skill: shape({
    value: shape({
      id: number
    }),
    label: string
  }),
  specialEvent: string,
  specialEventId: number
});

export const newCalendarShape = shape({
  dayOfMonth: number,
  month: string,
  day: string,
  isWeekend: bool,
  isToday: bool
});

export const timelineAssignmentShape = shape({
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  empty: bool,
  assignmentWorkHours: number,
  assignmentType: string,
  assignmentId: number,
  note: noteShape,
  pending: bool,
  ongoing: bool,
  backfillPosition: bool,
  creator: shape({
    id: number,
    name: string
  }),
  busyPercentage: number,
  totalWorkHours: number,
  role: shape({
    id: number,
    name: string,
    createdAt: oneOfType([string, instanceOf(Date)]),
    updatedAt: oneOfType([string, instanceOf(Date)]),
    category: string,
    area: string,
    availableForAssignment: bool,
    departmentId: number,
    expectedPercentage: number
  }),
  seniority: string,
  createdAt: oneOfType([string, instanceOf(Date)])
});

export const projectResourceShape = shape({
  id: number,
  avatar: string,
  leftCompany: bool,
  status: string,
  workKind: string,
  timeOffs: arrayOf(timeOffShape),
  exitDate: oneOfType([string, instanceOf(Date)]),
  role: string,
  assignments: arrayOf(timelineAssignmentShape)
});

export const projectOpenAssignmentShape = shape({
  id: number,
  role: string,
  fragments: arrayOf(timelineAssignmentShape)
});

export const utilizationGraphShape = shape({
  year: number.isRequired,
  month: number.isRequired,
  quarter: number.isRequired,
  billingCapacity: number.isRequired,
  scheduled: number.isRequired,
  demand: number.isRequired
});

export const utilizationFiltersShape = shape({
  year: string,
  group: string,
  departmentId: number
});

export const utilizationBenchOverview = shape({
  departmentId: number,
  departmentName: string,
  utilizationPercentage: number,
  benchPercentage: number,
  benchInFte: number,
  value: number
});

export const dashboardFinancialOverview = shape({
  month: number,
  revenue: number,
  costs: number,
  margin: number
});

export const allocationTimelineBarShape = shape({
  allocationType: oneOf(Object.values(ALLOCATION_TIMELINE_TYPES)),
  start: number.isRequired,
  end: number.isRequired
});

export const financialInsightsFiltersShape = shape({
  year: string,
  departmentId: number
});

export const skillUtilizationShape = shape({
  assigned: number,
  id: number,
  name: string,
  total: number
});

export const labelValueFormat = shape({
  percentage: number,
  revenue: number
});

export const insightRevenueGraphShape = shape({
  id: number,
  name: string,
  percentage: number,
  revenue: number,
  labelValue: labelValueFormat
});

export const peopleSearchShape = shape({
  id: number.isRequired,
  fullName: string.isRequired,
  avatarUrl: string,
  status: string.isRequired,
  workKind: string.isRequired,
  role: roleShape,
  seniority: string,
  margin: number.isRequired,
  matchOverall: number.isRequired,
  mainSkills: arrayOf(mainSkillShape),
  timeOffs: arrayOf(timeOffShape),
  specialEvents: arrayOf(specialEventsShape),
  assignments: arrayOf(timelineAssignmentShape)
});

export const indicatorShape = shape({
  color: string,
  label: string,
  value: number
});

export const benchFiltersShape = shape({
  year: string,
  group: string,
  departmentId: number,
  skillId: number
});

export const benchGraphShape = shape({
  year: number.isRequired,
  month: number.isRequired,
  quarter: number.isRequired,
  totalBenchPercentage: number.isRequired,
  totalBenchFte: number.isRequired
});

export const assignmentShape = shape({
  startDate: string,
  endDate: string,
  workHours: number,
  assignmentType: string
});

export const benchListResource = shape({
  id: number,
  avatarUrl: string,
  firstName: string,
  lastName: string,
  role: string,
  department: string,
  seniority: string,
  skills: arrayOf(string),
  timeOffs: timeOffsShape,
  assignments: arrayOf(assignmentShape),
  projects: arrayOf(string),
  daysOnBench: number,
  capacity: number
});

export const customerOptionShape = shape({
  label: string,
  value: { id: string, type: string },
  key: string,
  id: string
});

export const projectOptionShape = shape({
  label: string,
  value: { id: string, type: string },
  key: string
});

export const additionalChargesShape = shape({
  fixedPrice: number,
  additional: number
});

const numberValidationShape = shape({ value: number, message: string });

export const experienceValidationShape = shape({
  min: numberValidationShape,
  max: numberValidationShape
});

export const errorShape = shape({ [string]: { message: string } });

export const sortConfigShape = shape({
  key: string,
  direction: oneOfType([SORT_DIR.desc, SORT_DIR.asc])
});
