import { FINANCIAL_EXPORT_FILENAME, FINANCIAL_EXPORT_TYPE } from 'constants/constants';

export const downloadCsv = (data, filename = FINANCIAL_EXPORT_FILENAME) => {
  const blob = new Blob([data], { type: FINANCIAL_EXPORT_TYPE });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
