import { dayJSToDate } from './date';

export const composeEditDetails = ({ englishLevel, bioLink, skills }) => ({
  englishLevel: englishLevel?.value,
  figmaBio: bioLink,
  experiences: skills?.map(({ skillValue, years, months, experienceId }) => ({
    years: +years,
    months: +months,
    skillId: skillValue?.value?.id,
    ...(experienceId && { experienceId })
  }))
});

export const composeUpdateSoftSkills = skills => ({
  softSkills: skills.map(skill => ({ name: skill?.name || skill?.skillName }))
});

export const composeAddSoftSkill = ({ skill }) => ({ softSkills: [{ name: skill }] });

export const composeUpdateTechnicalSkills = skills => ({
  experiences: skills.map(skill => ({ ...skill, experienceId: skill.id }))
});

export const composeAddTechnicalSkill = ({ months, years, value }) => ({
  experiences: [{ months: +months, years: +years, skillId: value?.value?.id }]
});

export const composeTrainingConversionSkill = ({ dates, skill, specialEvent }) => ({
  specialEventType: specialEvent,
  skillId: skill.value.id,
  startDate: dates.startDate,
  endDate: dates.endDate
});

export const composeEditTrainingSkill = ({
  startDate,
  endDate,
  skillName,
  specialEventType,
  skillId,
  id
}) => ({
  dates: { startDate: dayJSToDate(startDate), endDate: dayJSToDate(endDate) },
  skill: { value: { id: skillId }, label: skillName },
  specialEvent: specialEventType,
  specialEventId: id
});
