import styled from 'styled-components';

import EmptyState from '../../EmptyState';

export const StyledEmptyState = styled(EmptyState)`
  height: 100%;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  padding: 2rem;
  text-align: center;
`;
