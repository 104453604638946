import styled from 'styled-components';

export const VerticalSeparator = styled.div`
  width: 100%;
  height: ${({ height }) => `${height || 1}rem`};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
`;

export const HorizontalSeparator = styled.div`
  height: 100%;
  width: ${({ width }) => `${width || 1}rem`};
`;
