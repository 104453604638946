import { createAction } from '@reduxjs/toolkit';

import projectService from 'services/projectService';
import createAsyncThunk from 'utils/createAsyncThunk';
import { getRequestFormatDate } from 'utils/date';
import parseError from 'utils/parseError';

export const resetProjectId = createAction('RESET_PROJECT_ID');

export const getProjectsAvailability = createAsyncThunk(
  'GET_PROJECTS_AVAILABILITY',
  async ({ startDate, endDate }) => {
    try {
      const { data } = await projectService.getProjectsAvailability(startDate, endDate);

      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getMinimalProjects = createAsyncThunk(
  'GET_MINIMAL_PROJECTS',
  async ({ startDate, endDate }) => {
    try {
      const { data } = await projectService.getProjectsAvailability(startDate, endDate, {}, true);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const createProjectStatus = createAsyncThunk(
  'CREATE_PROJECT_STATUS',
  async ({ projectStatus }) => {
    try {
      const { data } = await projectService.newProjectStatus(projectStatus);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const editProjectStatus = createAsyncThunk(
  'EDIT_PROJECT_STATUS',
  async ({ statusId, projectStatus }) => {
    try {
      const { data } = await projectService.editProjectStatus(statusId, projectStatus);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const deleteProjectStatus = createAsyncThunk(
  'DELETE_PROJECT_STATUS',
  async ({ statusId }) => {
    try {
      const { data } = await projectService.deleteProjectStatus(statusId);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const createProject = createAsyncThunk('CREATE_PROJECT', async project => {
  try {
    const { data } = await projectService.createProject(project);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const editProject = createAsyncThunk('EDIT_PROJECT', async project => {
  try {
    const { data } = await projectService.editProject(project);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const deleteProject = createAsyncThunk('DELETE_PROJECT', async projectId => {
  try {
    await projectService.deleteProject(projectId);
    return projectId;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const archiveProject = createAsyncThunk('ARCHIVE_PROJECT', async projectId => {
  try {
    await projectService.archiveProject(projectId);
    return projectId;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getProjectById = createAsyncThunk('GET_PROJECT_BY_ID', async ({ projectId }) => {
  try {
    const { data } = await projectService.getProjectById(projectId);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getProjectsOverviewTotals = createAsyncThunk(
  'GET_PROJECTS_OVERVIEW_TOTALS',
  async () => {
    try {
      const { data } = await projectService.getProjectsOverviewTotals();
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getProjectsOverview = createAsyncThunk(
  'GET_PROJECTS_OVERVIEW`',
  async ({ startDate, endDate }) => {
    try {
      const { data } = await projectService.getProjectsOverview({
        startDate,
        endDate
      });
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getProjectOverviewDetail = createAsyncThunk(
  'GET_PROJECT_OVERVIEW_DETAIL`',
  async ({ projectId }) => {
    try {
      const { data } = await projectService.getProjectOverviewDetail(projectId);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);
export const getFinishedAssignments = createAsyncThunk(
  'GET_PROJECT_OVERVIEW_DETAIL`',
  async ({ projectId }) => {
    try {
      const { data } = await projectService.getFinishedAssignments(projectId);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getProjectAssignments = createAsyncThunk(
  'GET_PROJECT_ASSIGNMENTS',
  async ({ projectId, startDate, endDate }) => {
    try {
      const { data } = await projectService.getProjectAssignments({
        projectId,
        startDate: getRequestFormatDate(startDate),
        endDate: getRequestFormatDate(endDate)
      });
      return { data, projectId };
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);
