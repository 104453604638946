import dayjs from 'dayjs';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import DatePicker from 'components/common/DatePicker';
import LoadingWrapper from 'components/common/LoadingWrapper';
import { DISPLAY_MONTH_DATE } from 'constants/dateFormats';
import { useRequest } from 'hooks';
import { getDashboardUtilizationBench } from 'state/actions/dashboardActions';
import { getDepartmentsGraphData } from 'utils/utilizationUtilities';

import CustomPieChart from './CustomPieChart';
import DepartmentTable from './DepartmentTable';
import { Container, TopContainer, Title, Content, DatePickerContainer } from './styles';

const DepartmentBlock = () => {
  const intl = useIntl();
  const [date, setDate] = useState(new Date());

  const [{ isPending: isLoadingGraphData, response }, getDashboardRequest] = useRequest(
    getDashboardUtilizationBench,
    {
      params: {
        year: dayjs(new Date()).year(),
        month: dayjs(new Date()).month() + 1
      }
    }
  );

  const handleChangeDate = value => {
    setDate(value);
    getDashboardRequest({ year: dayjs(value).year(), month: dayjs(value).month() + 1 });
  };

  return (
    <Container>
      <TopContainer>
        <Title>
          <h3>{intl.formatMessage({ id: 'common.overview' })}</h3>
          <h4>{intl.formatMessage({ id: 'insights.utilization.department.subtitle' })}</h4>
        </Title>
        <DatePickerContainer>
          <DatePicker
            name="date-picker"
            onChange={handleChangeDate}
            selected={date}
            variant="new"
            showTodayButton={false}
            showMonthYearPicker
            dateFormat={DISPLAY_MONTH_DATE}
          />
        </DatePickerContainer>
      </TopContainer>
      <LoadingWrapper loading={isLoadingGraphData}>
        <Content>
          <CustomPieChart
            data={getDepartmentsGraphData(response.benchOverview)}
            total={response?.benchOverview?.totalBenchPercentageInMonth}
          />
          <DepartmentTable data={response?.benchOverview?.departmentData} />
        </Content>
      </LoadingWrapper>
    </Container>
  );
};

export default DepartmentBlock;
