import { arrayOf } from 'prop-types';

import { calendarShape } from 'constants/shapes';
import { useSticky } from 'hooks';

import Calendar from './Calendar';
import { TimelineHeaderContainer, Corner } from './styles';

const TimelineHeader = ({ calendar }) => {
  const [timeLineHeaderRef, isSticky] = useSticky();
  // TODO - Hiding checkbox until backend supports unassigned skills
  // const { checkboxWidth } = useWidths();
  // const { unassignedSkillsShown, toggleUnassignedSkillsShownRequest } = useUnassignedSkills();

  return (
    <TimelineHeaderContainer ref={timeLineHeaderRef} isSticky={isSticky}>
      <Corner>
        {/* TODO - Hiding checkbox until backend supports unassigned skills  */}
        {/* <Checkbox
          width={checkboxWidth}
          label={intl.formatMessage({ id: 'timeline.toggleUnassignedSkillsShown' })}
          value={unassignedSkillsShown}
          onChange={() => toggleUnassignedSkillsShownRequest()}
        /> */}
      </Corner>
      <Calendar calendar={calendar} />
    </TimelineHeaderContainer>
  );
};

TimelineHeader.propTypes = {
  calendar: arrayOf(calendarShape)
};

export default TimelineHeader;
