import { arrayOf, number, objectOf } from 'prop-types';

import { newCalendarShape } from 'constants/shapes';

import { Container, Days, DaysContainer, Month, Section } from './styles';

const Calendar = ({ calendar, totalDays }) => (
  <Container>
    {Object.entries(calendar).map(([key, value], index) => (
      <Section key={key} size={value.length} totalDays={totalDays} firstLine={index > 0}>
        <Month>{value.length > 5 && key}</Month>
        <DaysContainer>
          {value.map(({ dayOfMonth, day, isWeekend, isToday }) => (
            <Days key={dayOfMonth} isWeekend={isWeekend} isToday={isToday}>
              <span>{day}</span>
              <span>{dayOfMonth}</span>
            </Days>
          ))}
        </DaysContainer>
      </Section>
    ))}
  </Container>
);

Calendar.propTypes = {
  calendar: objectOf(arrayOf(newCalendarShape)),
  totalDays: number
};

export default Calendar;
