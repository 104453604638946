import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import DateRange from 'components/common/DatePicker/DateRange';
import DeleteButton from 'components/common/DeleteButton';
import { Row, RowInputWrapper, CheckboxWrapper } from 'components/common/Layout/Row';
import { VerticalSeparator } from 'components/common/Layout/Separator';
import Select from 'components/common/Select';
import { PENDING } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS } from 'constants/constants';
import {
  projectStatusArray,
  projectStatus as projectStatusConstant
} from 'constants/projectConstants';
import { projectStatusFormShape } from 'constants/shapes';
import {
  useTextInputProps,
  useSelectorProps,
  useForm,
  useDateRangeProps,
  useCheckboxProps,
  useHeights
} from 'hooks';
import { getProjectMinMaxDates, getRequestFormatDate } from 'utils/date';
import { initialStatus } from 'utils/projectUtilities';

import { StyledForm } from './styles';

const fields = {
  status: 'status',
  startDate: 'startDate',
  endDate: 'endDate',
  updateAssignments: 'updateAssignments'
};

const ProjectStatusForm = ({
  onSubmit,
  project,
  initialValues,
  status,
  error,
  onDelete,
  isDeleteLoading,
  submitTitleId,
  disableStartDateSelector
}) => {
  const intl = useIntl();
  const isLoading = status === PENDING || isDeleteLoading;

  const [, maxDate] = useMemo(() => getProjectMinMaxDates(project), [project]);

  const { inputHeight, separatorHeight } = useHeights();

  const onSubmitOverride = ({ status, type, startDate, endDate, updateAssignments = false }) => {
    const projectStatus = {
      projectId: project.id,
      status: status.value,
      type,
      updateAssignments,
      startDate: getRequestFormatDate(startDate),
      endDate: endDate && getRequestFormatDate(endDate)
    };

    onSubmit({ projectStatus });
  };

  const { values, setValues, errors, setErrors, handleValueChange, handleSubmit, handleBlur } =
    useForm(
      {
        onSubmit: onSubmitOverride,
        validateOnBlur: true
      },
      [onSubmitOverride]
    );

  useEffect(() => {
    if (initialValues) {
      setValues({
        status: {
          ...initialStatus(initialValues),
          label: intl.formatMessage({ id: initialStatus(initialValues).label })
        },
        ...initialValues
      });
    } else {
      setValues(prevState => ({ ...prevState, startDate: new Date(), endDate: maxDate }));
    }
  }, [project, initialValues, maxDate, setValues, intl]);

  const inputProps = useTextInputProps({ handleValueChange, handleBlur, values, errors });
  const selectorProps = useSelectorProps({ inputProps, values, setValues });
  const checkboxProps = useCheckboxProps({ inputProps, values, setValues });
  const dateRangeProps = useDateRangeProps(
    {
      inputProps,
      values,
      setValues,
      errors,
      setErrors
    },
    [fields.startDate, fields.endDate]
  );

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Row>
        <RowInputWrapper fullWidth>
          <DateRange
            names={{ startDate: 'assignment.startDate', endDate: 'assignment.endDate' }}
            showMonthYearDropdown={!!project.endDate}
            disabledKeyboardNavigation
            showWeekCounter
            keepRangeDuration
            startDateProps={{ disabled: disableStartDateSelector }}
            {...dateRangeProps}
            variant="new"
          />
        </RowInputWrapper>
      </Row>
      <Row>
        <Select
          name="status"
          height={inputHeight}
          options={projectStatusArray.map(project => ({
            ...project,
            label: intl.formatMessage({ id: project.label })
          }))}
          isSearchable
          isClearable
          {...selectorProps(fields.status)}
          enablePortal
          variant="new"
          withoutHighlight
        />
      </Row>
      <Row
        displayMode={
          values?.status?.value === projectStatusConstant.confirmed.value ? 'block' : 'none'
        }
      >
        <CheckboxWrapper fullWidth paddingTop="1rem" paddingBottom="0">
          <Checkbox
            name="updateAssignments"
            label={intl.formatMessage({ id: 'projectStatus.updateAssignments' })}
            variant="new"
            {...checkboxProps(fields.updateAssignments)}
          />
        </CheckboxWrapper>
      </Row>
      <VerticalSeparator height={separatorHeight} />
      {onDelete && (
        <Row>
          <DeleteButton
            onClick={onDelete}
            isLoading={isLoading}
            textIntlId="common.cap.deleteStatus"
            type="button"
          />
        </Row>
      )}
      {error && <AlertMessage>{error}</AlertMessage>}
      <Row content="center">
        <Button
          type="submit"
          variant={BUTTON_VARIANTS.NEW_PRIMARY}
          isLoading={isLoading}
          textIntlId={submitTitleId}
          fullWidth
        />
      </Row>
    </StyledForm>
  );
};

ProjectStatusForm.propTypes = projectStatusFormShape;

export default ProjectStatusForm;
