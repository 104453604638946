import { camelize } from 'humps';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import RoundAvatar from 'components/common/RoundAvatar';
import { NOTIFICATIONS_TYPES } from 'constants/constants';
import { notificationShape } from 'constants/shapes';
import { useDispatch } from 'hooks';
import {
  deleteNotification,
  markNotificationAsRead,
  updateNotificationMarkRead
} from 'state/actions/notificationActions';
import { routeWithProps } from 'utils/helpers';

import AcknowledgeSection from '../acknowledgeSection';
import {
  BodyContainer,
  Avatar,
  Message,
  Title,
  Description,
  CircleIndicator,
  TrashCan,
  NotificationIcon,
  NotificationItemContainer,
  Actions
} from './styles';

const NotificationItem = ({ item }) => {
  const history = useHistory();
  const [showTrash, setShowTrash] = useState(false);
  const deleteNotificationRequest = useDispatch(deleteNotification);
  const markNotificationAsReadRequest = useDispatch(markNotificationAsRead);
  const updateNotificationMarkReadRequest = useDispatch(updateNotificationMarkRead);

  const {
    avatarUrl,
    body,
    title,
    personId,
    projectId,
    assignmentId,
    read,
    notificationId,
    acknowledged
  } = item;
  const type = camelize(item.type);
  const { color, icon, redirectPage, getRedirectProps, id } = NOTIFICATIONS_TYPES[type];

  const redirectUrl = () => {
    if (!read) {
      markNotificationAsReadRequest({ notificationId });
      updateNotificationMarkReadRequest({ notificationId });
    }

    const entityId = personId || projectId || assignmentId;
    getRedirectProps &&
      entityId &&
      history.push(routeWithProps(redirectPage, getRedirectProps(entityId)));
  };

  const handleDeleteNotification = e => {
    e.stopPropagation();
    deleteNotificationRequest(notificationId);
  };

  return (
    <NotificationItemContainer>
      <BodyContainer
        onClick={() => redirectUrl()}
        onMouseEnter={() => setShowTrash(true)}
        onMouseLeave={() => setShowTrash(false)}
      >
        {icon && <NotificationIcon src={icon} alt="notification icon" />}
        {avatarUrl ? <Avatar src={avatarUrl} alt="avatar" /> : !icon && <RoundAvatar />}
        <Message>
          <Title color={color}>{title}</Title>
          <Description>{body}</Description>
        </Message>
        <Actions>
          {showTrash ? (
            <TrashCan onClick={handleDeleteNotification} />
          ) : (
            <CircleIndicator read={read} />
          )}
        </Actions>
      </BodyContainer>
      <AcknowledgeSection typeId={id} acknowledged={acknowledged} notificationId={notificationId} />
    </NotificationItemContainer>
  );
};

NotificationItem.propTypes = {
  item: notificationShape
};

export default NotificationItem;
