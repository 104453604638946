import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem 5rem 4rem 5rem;
`;

export const Header = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.9rem;
  padding: 3rem 0 1.8rem 0;
  width: 100%;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.new.typography.h2};
  color: ${({ theme }) => theme.new.colors.black};
  max-width: 25.1rem;
  width: 100%;
`;

export const Content = styled.section`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin-right: 2rem;
`;
