import { bool } from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { StyledNotes } from './styles';

const NotesSection = ({ noGeneralPermission }) => {
  const intl = useIntl();
  const { control } = useFormContext();

  return (
    <Controller
      name="note"
      control={control}
      render={({ field }) => (
        <StyledNotes
          {...field}
          id="notesTxt"
          type="text"
          rows={8}
          placeholder={intl.formatMessage({ id: 'assignment.form.note.placeholder' })}
          disabled={noGeneralPermission}
        />
      )}
    />
  );
};

NotesSection.propTypes = {
  noGeneralPermission: bool
};

export default NotesSection;
