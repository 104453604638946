import styled from 'styled-components';

import { ReactComponent as UserPlusIcon } from 'assets/icons/plusIcon.svg';

export const TitleContainer = styled.div`
  align-items: center;
  align-self: start;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  display: flex;
  width: 100%;
  gap: 1.6rem;
  padding-bottom: 2.5rem;
  h1 {
    ${({ theme }) => theme.new.typography.h3};
    color: ${({ theme }) => theme.new.colors.N900};
    font-size: 3.2rem;
  }
`;
export const VerticalLine = styled.div`
  background: ${({ theme }) => theme.new.colors.N400};
  height: 40px;
  width: 1px;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.4rem;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const StyleUserPlusIcon = styled(UserPlusIcon)`
  & path {
    stroke: ${({ theme }) => theme.new.colors.white};
  }
`;
