import styled from 'styled-components';

export const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: calc(100vh - 6.8rem);
`;

export const Content = styled.div`
  overflow-y: auto;
  grid-column-start: 2;
`;
