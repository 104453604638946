import { func, string, instanceOf, oneOfType, bool } from 'prop-types';

import MuiTooltip from 'components/common/MuiTooltip';
import { TOOLTIP_VARIANTS } from 'constants/constants';
import { statusShape } from 'constants/shapes';
import { diffTime, getTimestamp, stringToDate } from 'utils/date';
import { humanize } from 'utils/helpers';

import { ProjectStatusContainer, StatusText, TextContainer } from './styles';

const ProjectStatus = ({
  status: { status: statusName },
  startDate: renderStartDate,
  endDate: renderEndDate,
  isNonBillable,
  onClick,
  serviceType,
  isStarted,
  isFinished
}) => {
  const startDate = stringToDate(renderStartDate);
  const endDate = stringToDate(renderEndDate);
  if (getTimestamp(startDate) > getTimestamp(endDate)) return null;
  const isAddTooltip = diffTime(startDate, endDate, 'day') <= 14;

  return (
    <ProjectStatusContainer
      statusName={statusName}
      startDate={startDate}
      endDate={endDate}
      onClick={onClick}
      isNonBillable={isNonBillable}
      title={statusName && humanize(statusName)}
      isStarted={isStarted}
      isFinished={isFinished}
    >
      {isAddTooltip && (
        <MuiTooltip variant={TOOLTIP_VARIANTS.BLACK}>
          {statusName && humanize(statusName)}
          {serviceType && ` | ${humanize(serviceType)}`}
        </MuiTooltip>
      )}
      <TextContainer isNonBillable={isNonBillable} statusName={statusName} startDate={startDate}>
        {serviceType && <p>{humanize(serviceType)}</p>}
        {statusName && <StatusText statusName={statusName}>{humanize(statusName)}</StatusText>}
      </TextContainer>
    </ProjectStatusContainer>
  );
};

ProjectStatus.propTypes = {
  endDate: oneOfType([string, instanceOf(Date)]),
  isFinished: bool,
  isNonBillable: bool,
  isStarted: bool,
  onClick: func,
  serviceType: string,
  startDate: oneOfType([string, instanceOf(Date)]),
  status: statusShape
};

export default ProjectStatus;
