import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import { QUARTER_TYPES } from 'constants/constants';
import { useRequest } from 'hooks';
import { getDashboardFinancialQuarters } from 'state/actions/dashboardActions';
import { toCurrency } from 'utils/number';

import { initialQuarters } from './constants';
import {
  Block,
  Container,
  GrowRow,
  Header,
  Info,
  Item,
  QuarterNumber,
  Row,
  StyledGrowInfo,
  StyledLoadingWrapper,
  VerticalLine
} from './styles';

const QuarterBlocks = () => {
  const intl = useIntl();
  const [{ isPending, error, response }] = useRequest(getDashboardFinancialQuarters, {
    initialData: initialQuarters
  });

  return (
    <Container>
      {response.quarterBlocks?.map(
        ({ quarter, revenue, year, margin, vsLastQuarter, vsLastYear }, index, arr) => {
          const quarterType = index === arr.length - 1 ? QUARTER_TYPES.CURRENT : QUARTER_TYPES.PAST;

          return (
            <StyledLoadingWrapper isLoading={isPending} key={quarter}>
              <Block>
                <Header isCurrent={quarterType === QUARTER_TYPES.CURRENT}>
                  <span>
                    {intl.formatMessage({ id: `common.${quarterType}` })}{' '}
                    {intl.formatMessage({ id: 'common.cap.quarter' })}
                  </span>
                </Header>
                <Row>
                  <QuarterNumber>
                    <p>Q{quarter}</p>
                    {year || 2022}
                  </QuarterNumber>
                  <Info>
                    <Item>
                      <span>{`${intl.formatMessage({ id: 'common.revenue' })} : `} </span>
                      <span>
                        {revenue !== null
                          ? toCurrency(revenue, {
                              style: 'currency',
                              currency: 'USD',
                              notation: 'compact',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })
                          : '--'}
                      </span>
                    </Item>
                    <Item>
                      <span>{`${intl.formatMessage({ id: 'common.margin' })} : `} </span>
                      <span>{margin !== null ? `${Math.round(margin)}%` : '--'}</span>
                    </Item>
                  </Info>
                </Row>
                <GrowRow>
                  <StyledGrowInfo
                    utilizationDifference={vsLastQuarter}
                    quarterType={quarterType}
                    isQuarter
                  />
                  <VerticalLine />
                  <StyledGrowInfo utilizationDifference={vsLastYear} quarterType={quarterType} />
                </GrowRow>
                {error && <AlertMessage>{error}</AlertMessage>}
              </Block>
            </StyledLoadingWrapper>
          );
        }
      )}
    </Container>
  );
};

export default QuarterBlocks;
