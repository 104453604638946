import { string } from 'prop-types';
import { useState } from 'react';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Tooltip from 'components/common/Tooltip';

import { Counter, Text, HelpInfo } from './styles';

const CounterInfo = ({ label, message }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Counter>
      <Text>{label}</Text>
      {message && (
        <>
          <InfoIcon
            data-tip
            data-for="info-tooltip"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
          {showTooltip && (
            <Tooltip id="info-tooltip" place="top" type="light" effect="float">
              <HelpInfo>{message}</HelpInfo>
            </Tooltip>
          )}
        </>
      )}
    </Counter>
  );
};

CounterInfo.propTypes = {
  label: string.isRequired,
  message: string
};

export default CounterInfo;
