import { number, shape, string } from 'prop-types';
import { useTheme } from 'styled-components';

const YAxisTick = ({ x = 0, y = 0, payload, width }) => {
  const theme = useTheme();

  const handleColor = name =>
    ({
      DevOps: theme.colors.puertoRico,
      QA: theme.colors.hollywoodCerise
    }[name] || theme.colors.black);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={width - x}
        y={0}
        dy={5}
        dx={65}
        fill={handleColor(payload.value)}
        fontSize={14}
        fontWeight={theme.fontWeights.normal}
        fontFamily={theme.fontFamily}
        textAnchor="end"
      >
        {payload.value}
      </text>
    </g>
  );
};

YAxisTick.propTypes = {
  payload: shape({ value: string }),
  width: number,
  x: number,
  y: number
};

export default YAxisTick;
