import { arrayOf, bool, func } from 'prop-types';

import ResourcesFilterPanel from 'components/assignmentDetails/ResourcesFilterPanel';
import EmptyState from 'components/common/EmptyState';
import { peopleSearchShape } from 'constants/shapes';

import ExploreResources from './ExploreResources';

const EmployeeTable = ({ resources, setResources, allResources, displayMargin }) => (
  <>
    <ResourcesFilterPanel initialResources={allResources} onFilterChange={setResources} />
    {resources.length ? (
      <ExploreResources resources={resources} displayMargin={displayMargin} />
    ) : (
      <EmptyState
        titleIntlId="emptyState.title.resources"
        subtitleIntlId="emptyState.subtitle.resources"
      />
    )}
  </>
);

EmployeeTable.propTypes = {
  resources: arrayOf(peopleSearchShape),
  allResources: arrayOf(peopleSearchShape),
  setResources: func,
  displayMargin: bool
};

export default EmployeeTable;
