import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { isBefore } from 'utils/date';

export default (
  { values, setValues, errors, setErrors, minDate, maxDate },
  [startDateKey, endDateKey]
) => {
  const intl = useIntl();

  useEffect(() => {
    const endBeforeStart = isBefore(values[endDateKey], values[startDateKey]);
    setErrors(prevState => ({
      ...prevState,
      [startDateKey]: endBeforeStart ? intl.formatMessage({ id: 'startEndDate.order' }) : null,
      [endDateKey]: endBeforeStart ? intl.formatMessage({ id: 'startEndDate.order' }) : null
    }));
  }, [endDateKey, setErrors, startDateKey, values, intl]);

  const dateRangeProps = {
    selected: { startDate: values[startDateKey], endDate: values[endDateKey] },
    onChange: ({ startDate, endDate }) => {
      setValues({
        ...values,
        [startDateKey]: startDate ?? values[startDateKey],
        [endDateKey]: endDate ?? values[endDateKey]
      });
    },
    labels: {
      startDate: intl.formatMessage({ id: 'common.cap.startDate' }),
      endDate: intl.formatMessage({ id: 'common.cap.endDate' })
    },
    names: {
      startDate: startDateKey,
      endDate: endDateKey
    },
    errors: errors[startDateKey] || errors[endDateKey],
    minDate,
    maxDate
  };

  return dateRangeProps;
};
