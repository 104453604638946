import { cloneDeep, isEqual } from 'lodash';
import { bool, func, instanceOf, number, oneOf, oneOfType, shape } from 'prop-types';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as PlusCircleIcon } from 'assets/icons/plus-circle.svg';
import ConfirmationModal from 'components/common/ConfirmationModal';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { Column, Container, Header } from 'components/Financial/Layout/Tables';
import { BILLING_UNITS, BUTTON_VARIANTS } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { useDispatch, useFinancial, useRequest, useRole } from 'hooks';
import { createOpenPeriod, updateOpenPeriodInfo } from 'state/actions/financialActions';
import hasPermission from 'utils/hasPermission';
import { getOpenBillingPeriodsData, getResourcesPeriodsInfo } from 'utils/periodUtilities';

import PeriodInfoModal from '../PeriodInfoModal';
import { Actions, SectionHeader, SectionTitle } from '../SplitCosts/styles';
import PeriodItem from '../SplitCostsGrid/PeriodItem';
import TableEmptyState from '../SplitCostsGrid/TableEmptyState';
import {
  AddPeriodButton,
  DynamicBlock,
  EmptyContainer,
  OpenPeriodsContainer,
  StyledEmptyState
} from './styles';

const OpenedPeriods = ({
  projectId,
  billingUnit,
  editingPeriod,
  globalDiscountEdit,
  globalDiscountAmount,
  setEditingPeriod,
  isLoading,
  staticGridRef,
  periodItemsRef
}) => {
  const intl = useIntl();
  const { permissions } = useRole();
  const [isOpenPeriodModal, setIsOpenPeriodModal] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const periodContainerRef = useRef();

  const resetOpenPeriodRequest = useDispatch(createOpenPeriod.reset);
  const { openPeriodResources, openPeriodPeriods, lastPeriodEndDate } = useFinancial();

  const [openedResourcesCopy, setOpenedResourcesCopy] = useState(() =>
    cloneDeep(openPeriodResources)
  );

  if (!isEqual(openedResourcesCopy, openPeriodResources) && !editingPeriod) {
    setOpenedResourcesCopy(cloneDeep(openPeriodResources));
  }

  const [resources, periods] = getOpenBillingPeriodsData(openedResourcesCopy, openPeriodPeriods);

  const handleOpenPeriodModal = () => {
    setIsOpenPeriodModal(prevState => !prevState);
  };

  const scrollToRight = () => {
    if (periodContainerRef?.current) {
      periodContainerRef.current.scrollLeft = periodContainerRef.current.scrollWidth;
    }
  };

  const [{ isPending: isLoadingCreateOpenPeriod, error }, createOpenPeriodRequest] = useRequest(
    createOpenPeriod,
    {
      fulfilledCallback: () => {
        handleOpenPeriodModal();
        scrollToRight();
      }
    },
    false
  );

  const [, updatePeriodInfoRequest] = useRequest(
    updateOpenPeriodInfo,
    {
      params: { id: editingPeriod },
      fulfilledCallback: () => setEditingPeriod(null)
    },
    false
  );

  const handleCreateOpenPeriod = values => {
    createOpenPeriodRequest({
      projectId,
      ...values
    });
  };

  const handleScrollPeriods = scroll => {
    staticGridRef.current.scrollTop = scroll.target.scrollTop;
  };

  const onChangeEditingPeriod = periodId => {
    if (!periodId && !isEqual(openedResourcesCopy, openPeriodResources)) {
      setIsShowConfirm(true);
    } else setEditingPeriod(periodId);
  };

  const handleSaveEditingPeriod = ({ additional, fixedPrice }) => {
    const periodsInfo = getResourcesPeriodsInfo(openedResourcesCopy, editingPeriod);
    updatePeriodInfoRequest({ id: editingPeriod, periodsInfo, additional, fixedPrice, projectId });
  };

  const handlePeriodChange = (setupId, field, hoursValue) => {
    const resourcePos = openedResourcesCopy.findIndex(resource => resource.setupId === setupId);
    const periodPos = openedResourcesCopy[resourcePos].periodsInfo.findIndex(
      period => period.periodId === editingPeriod
    );

    setOpenedResourcesCopy(prevState => {
      const copy = cloneDeep(prevState);
      if (periodPos >= 0) {
        copy[resourcePos].periodsInfo[periodPos][field] = hoursValue;
      }
      return copy;
    });
  };

  const handleDiscardConfirm = () => {
    setOpenedResourcesCopy(cloneDeep(openPeriodResources));
    setIsShowConfirm(false);
    setEditingPeriod(null);
  };

  if (isLoading) return <CenteredLoading />;

  return (
    <OpenPeriodsContainer>
      <Container>
        <SectionHeader mainColor>
          <SectionTitle>{intl.formatMessage({ id: 'financial.openPeriods.title' })}</SectionTitle>
          <Actions>
            {!editingPeriod && (
              <AddPeriodButton
                disabled={!hasPermission(permissions, [SCOPES.createOpenPeriod])}
                type="button"
                id="addPeriodBtn"
                textIntlId="openPeriod.addPeriod"
                variant={BUTTON_VARIANTS.NEW_PRIMARY}
                onClick={handleOpenPeriodModal}
                icon={<PlusCircleIcon />}
              />
            )}
          </Actions>
        </SectionHeader>
        {!periods.length && !resources.length ? (
          <TableEmptyState />
        ) : (
          <>
            <DynamicBlock ref={periodContainerRef} isEmpty={!periods?.length}>
              {periods?.length ? (
                periods.map((period, index) => (
                  <PeriodItem
                    key={period.id}
                    period={period}
                    innerRef={periodItemsRef}
                    handleScroll={handleScrollPeriods}
                    index={index}
                    onChangeEditingPeriod={onChangeEditingPeriod}
                    editingPeriod={editingPeriod}
                    onPeriodChange={handlePeriodChange}
                    billingUnit={billingUnit}
                    setEditingPeriod={setEditingPeriod}
                    handleSaveEditingPeriod={handleSaveEditingPeriod}
                    globalDiscountEdit={globalDiscountEdit}
                    globalDiscountAmount={globalDiscountAmount}
                  />
                ))
              ) : (
                <EmptyContainer>
                  <Header>
                    <Column>{intl.formatMessage({ id: 'financial.openPeriods' })}</Column>
                  </Header>
                  <StyledEmptyState
                    titleIntlId="financial.noBillableData.title"
                    textIntlId="financial.noBillableData.subTitle"
                    height="calc(100% - 73px)"
                  />
                </EmptyContainer>
              )}
            </DynamicBlock>
          </>
        )}
        {isOpenPeriodModal && (
          <PeriodInfoModal
            hide={handleOpenPeriodModal}
            open={isOpenPeriodModal}
            onSubmit={handleCreateOpenPeriod}
            startDate={lastPeriodEndDate}
            isLoading={isLoadingCreateOpenPeriod}
            periodNumber={openPeriodPeriods.length + 1}
            error={error}
            resetRequest={resetOpenPeriodRequest}
          />
        )}
        <ConfirmationModal
          title={intl.formatMessage({ id: 'modal.openPeriod.unsavedChanges.title' })}
          cancelButtonIntlId="common.keepEditing"
          confirmButtonIntlId="modal.openPeriod.unsavedChanges.discard"
          onConfirm={handleDiscardConfirm}
          onCancel={() => setIsShowConfirm(false)}
          hide={() => setIsShowConfirm(false)}
          isShowing={isShowConfirm}
          textAlign="left"
        />
      </Container>
    </OpenPeriodsContainer>
  );
};

OpenedPeriods.propTypes = {
  projectId: number,
  billingUnit: oneOf(Object.values(BILLING_UNITS)),
  editingPeriod: number,
  globalDiscountEdit: bool,
  globalDiscountAmount: number,
  setEditingPeriod: func,
  isLoading: bool,
  staticGridRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
  periodItemsRef: oneOfType([func, shape({ current: instanceOf(Element) })])
};

export default OpenedPeriods;
