import styled from 'styled-components';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import BadgePill from 'components/common/BadgePill';
import { SORT_DIR } from 'constants/constants';

export const ArrowIcon = styled(ArrowUp)`
  display: block;
  transition: all 150ms ease-in-out;
  transform: ${({ direction }) =>
    direction === SORT_DIR.desc ? 'rotate(-180deg) translate(0, 10%)' : 'none'};
  width: 1.2rem;
  height: 1.2rem;
  opacity: ${({ $isSorting }) => ($isSorting ? '1' : '0.3')};
  path {
    stroke: ${({ theme, $isSorting }) =>
      $isSorting ? theme.new.colors.P500 : theme.new.colors.N900};
    stroke-width: 1.5px;
    transform: scale(0.9);
  }
`;

export const Table = styled.table`
  background: ${({ theme }) => theme.new.colors.white};
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
`;

export const Column = styled.td`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme, $isMonthColumn }) =>
    $isMonthColumn ? theme.new.colors.N900 : theme.new.colors.N800};
  font-weight: ${({ theme, $isMonthColumn }) => $isMonthColumn && theme.fontWeights.semiBold};
  height: ${({ height }) => height};
  min-width: 1rem;
  padding: 1.6rem 0;
  width: ${({ $isMonthColumn }) => ($isMonthColumn ? '19%' : `${(100 - 19) / 3}%`)};
  text-align: center;
  &:not(:last-child) {
    border-right: ${({ theme, $isHeader }) => !$isHeader && `1px solid ${theme.new.colors.N300}`};
  }
`;

export const HeaderColumnContainer = styled.button`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.N900};
  display: flex;
  font-size: 1.4rem;
  gap: 0.8rem;
  justify-content: start;
  margin: ${({ $isMonthColumn }) => !$isMonthColumn && '0 auto'};
  width: fit-content;
`;

export const Row = styled.tr`
  overflow: hidden;
  border-bottom: ${({ theme, isHeader }) => isHeader && `1px solid ${theme.new.colors.N500}`};
  width: 100%;
  background: ${({ theme, isGray }) => isGray && `${theme.new.colors.N100_05}`};
  td,
  th {
    &:first-child {
      padding-left: 2.2rem;
      text-align: left;
    }
  }
`;

export const Container = styled.div`
  max-height: ${({ $isExpanded }) => ($isExpanded ? '200rem' : '0')};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`;

export const StyledBadgePill = styled(BadgePill)`
  margin: 0 auto;
`;
