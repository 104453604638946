import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { getRoleGroups } from 'state/actions/settingsActions';
import { roleGroupsToOptions } from 'utils/settingsUtilities';

export default () => {
  const [groupOptions, setGroupOptions] = useState([]);
  const getGroupRequest = useDispatch(getRoleGroups);
  const {
    status: groupStatus,
    error: groupError,
    isPending: isLoadingRoleGroups
  } = useStatus(getRoleGroups);

  const roleGroups = useSelector(({ settings }) => settings.roleGroups);

  useEffect(() => {
    if (!roleGroups) {
      getGroupRequest();
    } else {
      setGroupOptions(roleGroups.map(roleGroupsToOptions));
    }
  }, [getGroupRequest, roleGroups]);

  return {
    roleGroups,
    getGroupRequest,
    groupStatus,
    groupError,
    groupOptions,
    isLoadingRoleGroups
  };
};
