import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11),
    0px 4px 8px 0px rgba(79, 94, 113, 0.1);
  padding: 1.6rem;
`;

export const TopContainer = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;

  & h3 {
    ${({ theme }) => theme.new.typography.p3SemiBold};
    color: ${({ theme }) => theme.new.colors.N900};
    line-height: 2.4rem;
  }

  & h4 {
    ${({ theme }) => theme.new.typography.p3SemiBold};
    color: ${({ theme }) => theme.new.colors.P800};
  }
`;

export const Info = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;

  & > span {
    ${({ theme }) => theme.new.typography.p6SemiBold};
    color: ${({ theme }) => theme.new.colors.P800};
    width: 100%;
  }
`;

export const VerticalLine = styled.div`
  background: ${({ theme }) => theme.new.colors.N400};
  height: 2rem;
  width: 1px;
`;

export const CurrentDayData = styled.div`
  align-items: center;
  display: flex;
  gap: 1.2rem;

  & > p {
    ${({ theme }) => theme.new.typography.p1Bold};
    color: ${({ theme }) => theme.new.colors.P800};
  }
`;

export const DataWrapper = styled.div`
  border-left: 1px solid;
  border-color: ${({ theme }) => theme.new.colors.N300};
  display: flex;
  gap: 1.6rem;
  height: 2.4rem;
  margin-left: 0.4rem;
  padding-left: 1.6rem;
`;
