import { arrayOf, bool } from 'prop-types';
import { useState } from 'react';

import EmployeeColumn from 'components/common/Card/Employee/EmployeeColumn';
import TableHeaders from 'components/common/TableTitles';
import ResourceProfileModal from 'components/Reports/ResourceProfileModal';
import EditQuickAssignmentModal from 'components/timeline/EditQuickAssignmentModal';
import { EMPLOYEE_MODAL_TYPES } from 'constants/constants';
import { peopleSearchShape } from 'constants/shapes';
import { useLazyLoading, useModal } from 'hooks';

import { tableEmployeeColumnTitles, OFF_SET, CONTAINER_ELEMENT_ID } from './constants';
import { Container, HeaderSection, EmployeesSection } from './styles';

const ExploreResources = ({ resources, displayMargin }) => {
  const { openModal, closeModal, modalType } = useModal();

  const [personId, setPersonId] = useState(false);
  const [assignmentData, setAssignment] = useState({});

  const isProfileModal = modalType === EMPLOYEE_MODAL_TYPES.PROFILE;
  const isAssignmentModal = modalType === EMPLOYEE_MODAL_TYPES.ASSIGNMENT;

  const { items } = useLazyLoading(resources, CONTAINER_ELEMENT_ID, OFF_SET);
  return (
    <Container>
      <HeaderSection>
        <TableHeaders headers={tableEmployeeColumnTitles} />
      </HeaderSection>
      <EmployeesSection>
        {items.map(employee => (
          <EmployeeColumn
            key={employee.id}
            employee={employee}
            openModal={openModal}
            setPersonId={setPersonId}
            setAssignment={setAssignment}
            displayMargin={displayMargin}
          />
        ))}
      </EmployeesSection>
      {isProfileModal && (
        <ResourceProfileModal personId={personId} open={isProfileModal} hide={closeModal} />
      )}
      {isAssignmentModal && (
        <EditQuickAssignmentModal
          personId={personId}
          name={assignmentData?.fullName}
          isShowing={isAssignmentModal}
          hide={closeModal}
          busyPercentage={50}
          assignment={assignmentData?.assignment}
        />
      )}
    </Container>
  );
};

ExploreResources.propTypes = {
  resources: arrayOf(peopleSearchShape),
  displayMargin: bool
};

export default ExploreResources;
