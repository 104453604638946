import dayjs from 'dayjs';
import { object, bool, func, node, number } from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import CollapseButton from 'components/common/CollapseButton';
import EventTooltip from 'components/common/EventTooltip';
import {
  TimelineRowWrapper,
  TimelineRow,
  TimelineRowHeader,
  TimelineRowHeaderContent,
  TimelineRowHeaderSubtitleWarning,
  TimelineRowContent,
  TimelineRowContentAbsolut
} from 'components/common/Layout/Timeline';
import RoundAvatar from 'components/common/RoundAvatar';
import Availability from 'components/timeline/Fragments/Availability';
import Event from 'components/timeline/Fragments/Event';
import EmptyPeriod from 'components/timeline/Fragments/Event/EmptyPeriod';
import { PROJECT_TYPES, EVENT_TYPE, SENIORITY_MAP } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { useTimelineDates, useHeights, useRole } from 'hooks';
import { getRequestFormatDate, getXAxisFormatDate } from 'utils/date';
import hasPermission from 'utils/hasPermission';
import { getResourceEventsTimeLineFragments } from 'utils/resourceEventsUtilities';

import PersonProjects from './PersonProjects';
import {
  PersonInfo,
  PersonName,
  PersonRole,
  PersonSeniority,
  QuickActionRow,
  QuickActionContainer
} from './styles';

const PeopleRow = ({
  person,
  isExpanded,
  setAddAssignment,
  setEditAssignment,
  isReadOnly = false,
  quickActionComponent,
  toggleExpandedRequest,
  index,
  showResourceDetail
}) => {
  const intl = useIntl();
  const { permissions } = useRole();
  const {
    id: personId,
    fullName: personName,
    leftCompany,
    projects,
    availability,
    avatarUrl,
    exitDate,
    timeOffs,
    holidays,
    role,
    seniority
  } = person;

  const toggleExpanded = () => toggleExpandedRequest(personId);
  const { startDate: startDateTimeline, endDate: endDateTimeline } = useTimelineDates();

  const { timelineFragmentBig } = useHeights();

  const personAvailability = useMemo(
    () =>
      availability.map((availability, index) => (
        <Availability
          key={`availability-${index}`}
          busyPercentage={Math.round(availability.value.busyPercentage)}
          startDate={availability.startDate}
          endDate={availability.endDate}
          assignmentWorkHours={availability.value.totalWorkHours}
          personId={personId}
          isPending={availability.value.pending.some(isPending => isPending)}
          isNonBillable={availability.value.assignmentsTypes.every(
            type => type === PROJECT_TYPES.NON_BILLABLE
          )}
          showTooltip={false}
        />
      )),
    [availability, personId]
  );

  const timeOffEvents = useMemo(
    () =>
      timeOffs
        ? timeOffs.map(({ startDate: fromDate, endDate: toDate }) => ({
            startDate: getRequestFormatDate(fromDate),
            endDate: getRequestFormatDate(toDate),
            type: EVENT_TYPE.TIME_OFF,
            children: (
              <EventTooltip
                type={EVENT_TYPE.TIME_OFF}
                textRow1={`${intl.formatMessage({
                  id: 'common.from'
                })}: ${getXAxisFormatDate(fromDate)}`}
                textRow2={`${intl.formatMessage({
                  id: 'common.to'
                })}: ${getXAxisFormatDate(toDate)}`}
              />
            )
          }))
        : [],
    [timeOffs, intl]
  );

  const countryHolidayEvents = useMemo(
    () =>
      holidays
        ? holidays.map(date => ({
            startDate: getRequestFormatDate(date),
            endDate: getRequestFormatDate(date),
            type: EVENT_TYPE.TIME_OFF,
            children: (
              <EventTooltip
                type={EVENT_TYPE.TIME_OFF}
                textRow1={`County Holidays - ${intl.formatMessage({
                  id: 'common.from'
                })}: ${getXAxisFormatDate(date)}`}
                textRow2={`${intl.formatMessage({
                  id: 'common.to'
                })}: ${getXAxisFormatDate(date)}`}
              />
            )
          }))
        : [],
    [holidays, intl]
  );

  const exitDateEvents = useMemo(
    () =>
      exitDate
        ? [
            {
              startDate: getRequestFormatDate(exitDate),
              endDate: getRequestFormatDate(exitDate),
              type: EVENT_TYPE.EXIT_DATE,
              children: (
                <EventTooltip
                  type={EVENT_TYPE.EXIT_DATE}
                  title={`${intl.formatMessage({ id: 'common.exitDate' })}:`}
                  textRow1={getXAxisFormatDate(exitDate)}
                />
              )
            }
          ]
        : [],
    [exitDate, intl]
  );

  const events = useMemo(
    () =>
      [...exitDateEvents, ...timeOffEvents, ...countryHolidayEvents].sort((a, b) =>
        dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1
      ),
    [exitDateEvents, timeOffEvents, countryHolidayEvents]
  );

  const personEventsTimeLine = useMemo(() => {
    const personEventsFragments = events?.length
      ? getResourceEventsTimeLineFragments(events, startDateTimeline, endDateTimeline)
      : [];

    return personEventsFragments?.map(({ startDate, endDate, value }, index) =>
      value ? (
        <Event
          key={`event-${index}`}
          eventStartDate={startDate}
          eventEndDate={endDate}
          {...value}
        />
      ) : (
        <EmptyPeriod key={`emptyPeriod-${index}`} startDate={startDate} endDate={endDate} />
      )
    );
  }, [events, startDateTimeline, endDateTimeline]);

  const seniorityLabel =
    seniority && intl.formatMessage({ id: SENIORITY_MAP.get(seniority)?.shortLabel });

  return (
    <TimelineRowWrapper>
      <TimelineRow isExpanded={isExpanded} isWithoutBorderLeft>
        <TimelineRowHeader onClick={toggleExpanded} id={`resourceRowContainer-${index}`}>
          <CollapseButton collapsed={!isExpanded} id={`resourceRowCollapseButton-${index}`} />
          <RoundAvatar src={avatarUrl} />
          <PersonInfo>
            <PersonName
              onClick={() => showResourceDetail?.(personId)}
              id={`resourceRowName-${index}`}
            >
              {personName}
            </PersonName>
            <PersonRole id={`resourceRowPosition-${index}`}>
              <PersonSeniority>{seniorityLabel}</PersonSeniority>
              {seniorityLabel && role?.name ? ' - ' : ''}
              {role?.name}
            </PersonRole>
            {leftCompany && (
              <TimelineRowHeaderSubtitleWarning>
                {intl.formatMessage({ id: 'filter.resource.leftCompany' })}
              </TimelineRowHeaderSubtitleWarning>
            )}
          </PersonInfo>
        </TimelineRowHeader>
        <TimelineRowContent height={timelineFragmentBig}>
          {personAvailability}
          {!!personEventsTimeLine.length && (
            <TimelineRowContentAbsolut height={timelineFragmentBig}>
              {personEventsTimeLine}
            </TimelineRowContentAbsolut>
          )}
        </TimelineRowContent>
      </TimelineRow>
      {isExpanded && (
        <PersonProjects
          projectsPerson={projects}
          person={person}
          setAddAssignment={setAddAssignment}
          setEditAssignment={setEditAssignment}
          isReadOnly={isReadOnly}
        />
      )}
      {quickActionComponent &&
        isExpanded &&
        hasPermission(permissions, [SCOPES.addProjectsResources]) && (
          <QuickActionRow>
            <TimelineRowHeader isNested paddingLeft="0" isCenter isAddBorders isAddBorderBottom>
              <TimelineRowHeaderContent isNested width="100%">
                <QuickActionContainer>{quickActionComponent}</QuickActionContainer>
              </TimelineRowHeaderContent>
            </TimelineRowHeader>
          </QuickActionRow>
        )}
    </TimelineRowWrapper>
  );
};

PeopleRow.propTypes = {
  person: object.isRequired,
  isExpanded: bool.isRequired,
  setEditAssignment: func.isRequired,
  setAddAssignment: func.isRequired,
  isReadOnly: bool,
  quickActionComponent: node,
  toggleExpandedRequest: func.isRequired,
  index: number,
  showResourceDetail: func
};

export default PeopleRow;
