import styled from 'styled-components';

export const NumericList = styled.ol`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 1.6rem;
  margin-left: 3.7rem;

  & > li:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const List = styled.ul`
  margin-left: 2rem;
`;
