import { number, func, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Container, Button } from './styles';

const RangeLengthSelector = ({ amount, setAmount, disabled }) => (
  <Container>
    <Button type="button" onClick={() => amount > 0 && setAmount(amount - 1)} disabled={disabled}>
      -
    </Button>
    <FormattedMessage id="template.counters.weeks" values={{ value: amount }} />
    <Button type="button" onClick={() => setAmount(amount + 1)} disabled={disabled}>
      +
    </Button>
  </Container>
);

RangeLengthSelector.propTypes = {
  amount: number.isRequired,
  setAmount: func.isRequired,
  disabled: bool
};

export default RangeLengthSelector;
