const assignmentTypes = {
  resources: {
    label: 'Resources',
    value: 'resources'
  },
  skills: { label: 'Skills', value: 'skills' },
  clients: { label: 'Customer', value: 'clients' },
  location: { label: 'City', value: 'city' },
  roles: { label: 'Role', value: 'role' },
  industries: { label: 'Industry', value: 'industry' }
};

export default assignmentTypes;
