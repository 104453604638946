import { string } from 'prop-types';

import FilterDropdown from 'components/common/FilterDropdown';
import SearchInput from 'components/common/SearchInput';
import { ReactComponent as LeftChevron } from 'components/icons/chevron-left.svg';
import { ReactComponent as RightChevron } from 'components/icons/chevron-right.svg';
import { useDispatch, useSelectFilters, useSkills, useLocations, useTeams, useSticky } from 'hooks';
import { filterResources } from 'state/actions/peopleActions';
import { resetTimelineDates, showFutureDates, showPastDates } from 'state/actions/timelineActions';

import {
  FiltersContainer,
  FiltersSection,
  ArrowsContainer,
  ArrowButton,
  FiltersBar,
  StyledButton,
  Title
} from './styles';

const ResourceFilters = ({ title }) => {
  const [filtersBarRef, isSticky] = useSticky();
  const showPastDatesRequest = useDispatch(showPastDates);
  const showFutureDatesRequest = useDispatch(showFutureDates);
  const resetTimelineDatesRequest = useDispatch(resetTimelineDates);
  const filterResourceRequest = useDispatch(filterResources);
  const {
    resourceFilters: { seniority, availability, name, locations, skills, teams }
  } = useSelectFilters();

  const onSearchByName = name => filterResourceRequest({ options: name, key: 'name' });

  useTeams();
  useLocations();
  useSkills();

  return (
    <FiltersContainer ref={filtersBarRef}>
      <FiltersBar isSticky={isSticky}>
        <FiltersSection>
          {title && <Title>{title}: </Title>}
          <FilterDropdown
            id="resourcesLocationFilter"
            labelId="filter.location"
            options={locations}
            onFilter={filterResourceRequest}
            queryKey="locations"
            withIntlOptions={false}
          />
          <FilterDropdown
            id="resourcesTeamFilter"
            labelId="filter.team"
            options={teams}
            onFilter={filterResourceRequest}
            queryKey="teams"
            withIntlOptions={false}
          />
          <FilterDropdown
            id="resourcesSkillsFilter"
            labelId="common.skills"
            options={skills}
            onFilter={filterResourceRequest}
            queryKey="skills"
            searchIntlId="common.cap.searchSkill"
            withIntlOptions={false}
          />
          <FilterDropdown
            id="resourcesSeniorityFilter"
            labelId="common.seniority"
            options={seniority}
            onFilter={filterResourceRequest}
            queryKey="seniority"
          />
          <FilterDropdown
            id="resourcesAvailabilityFilter"
            labelId="common.availability"
            options={availability}
            onFilter={filterResourceRequest}
            queryKey="availability"
          />
          <SearchInput
            id="resourcesSearchFilter"
            placeholderId="common.cap.searchResource"
            value={name || ''}
            onChange={onSearchByName}
          />
        </FiltersSection>
        <ArrowsContainer>
          <StyledButton onClick={resetTimelineDatesRequest} textIntlId="filter.currentWeek" />
          <ArrowButton onClick={() => showPastDatesRequest()}>
            <LeftChevron />
          </ArrowButton>
          <ArrowButton onClick={() => showFutureDatesRequest()}>
            <RightChevron />
          </ArrowButton>
        </ArrowsContainer>
      </FiltersBar>
    </FiltersContainer>
  );
};

ResourceFilters.propTypes = {
  title: string
};

export default ResourceFilters;
