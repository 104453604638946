import { func, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import QuickSelect from 'components/common/Select/QuickSelect';
import { peopleShape, projectOptionShape } from 'constants/shapes';
import { personToAssignmentOption } from 'utils/assignmentFormsUtilities';

const AssignToProjectDropdown = ({ person, setAddAssignment, id, options }) => {
  const intl = useIntl();

  const onChange = ({ value: project }) =>
    setAddAssignment({
      project,
      initialValues: { personId: personToAssignmentOption(person) }
    });

  return (
    <QuickSelect
      id={id}
      value={null}
      showIcon
      label={intl.formatMessage({ id: 'assignment.newToProject' })}
      onChange={onChange}
      options={options}
    />
  );
};

AssignToProjectDropdown.propTypes = {
  person: peopleShape.isRequired,
  setAddAssignment: func.isRequired,
  id: string,
  options: shape(projectOptionShape)
};

export default AssignToProjectDropdown;
