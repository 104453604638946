import { arrayOf, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { Header } from './styles';

const TableHeaders = ({ headers }) => {
  const intl = useIntl();

  return headers.map(title => (
    <Header key={title}>{title ? intl.formatMessage({ id: title }) : ''}</Header>
  ));
};

TableHeaders.propTypes = {
  tableTitles: arrayOf(string)
};

export default TableHeaders;
