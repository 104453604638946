const routesPaths = {
  index: '/',
  login: '/login',
  signUp: '/sign-up',
  dashboard: '/dashboard',
  dashboardReports: '/dashboard/reports',
  dashboardPeople: '/dashboard/people',
  dashboardAssignment: '/dashboard/assignment',
  notificationCenter: '/notification-center',
  resources: '/resources',
  projectsOverview: '/projects/overview',
  projectsTimeline: '/projects/timeline',
  projectDetails: '/projects/:id',
  assignmentDetails: '/projects/:projectId/assignment/:assignmentId',
  financial: '/financial',
  financialReporting: '/financial/reporting',
  financialProjects: '/financial/projects',
  financialProjectsDetails: '/financial/projects/:id',
  forecasting: '/forecasting',
  myReportsProfile: '/my-reports/profile/:id',
  myReports: '/my-reports',
  utilizationInsights: '/insights/utilization',
  financialInsights: '/insights/financial',
  benchInsights: '/insights/bench',
  accessRoles: '/settings/roles',
  accessMatrix: '/settings/access-matrix',
  mappingRoles: '/settings/mapping-roles',
  basicUser: '/basic'
};

export default routesPaths;
