import { createAction } from '@reduxjs/toolkit';

import timelineService from 'services/timelineService';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const filterSkillsByTeam = createAction('FILTER_SKILLS_BY_TEAM');

export const getSkills = createAsyncThunk('GET_SKILLS', async () => {
  try {
    const { data } = await timelineService.getSkills();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getDepartments = createAsyncThunk('GET_DEPARTMENTS', async () => {
  try {
    const { data } = await timelineService.getDepartments();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getLocations = createAsyncThunk('GET_LOCATIONS', async () => {
  try {
    const { data } = await timelineService.getLocations();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getRoles = createAsyncThunk('GET_ROLES', async () => {
  try {
    const { data } = await timelineService.getRoles();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getIndustries = createAsyncThunk('GET_INDUSTRIES', async () => {
  try {
    const { data } = await timelineService.getIndustries();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getCustomer = createAsyncThunk('GET_CUSTOMER', async () => {
  try {
    const { data } = await timelineService.getCustomer();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});
