import { func, number, oneOfType, string } from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { POSITIONS_TYPES } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { useConfirm, useDispatch, useRole } from 'hooks';
import { deleteClosedPeriod } from 'state/actions/financialActions';
import hasPermission from 'utils/hasPermission';

import { StyledOptionsButton } from './styles';

const OptionsClosePeriodButton = ({ periodId, projectId, onEditPeriod, onOpenPeriodDetails }) => {
  const intl = useIntl();
  const deleteRequest = useDispatch(deleteClosedPeriod);
  const { permissions } = useRole();

  const [ConfirmationDelete, confirmDelete] = useConfirm({
    title: intl.formatMessage({ id: 'modal.closedPeriod.delete.title' }),
    message: intl.formatMessage({ id: 'modal.closedPeriod.delete.message' }),
    onConfirm: () => deleteRequest({ periodId, projectId }),
    confirmLabelIntlId: 'common.delete',
    cancelLabelIntlId: 'common.cancel'
  });

  const periodOptions = useMemo(() => {
    const deleteOption = {
      label: intl.formatMessage({ id: 'common.delete' }),
      onClick: () => confirmDelete()
    };

    const editPeriodDetail = {
      label: intl.formatMessage({ id: 'financial.period.editBillingPeriod' }),
      onClick: () => onOpenPeriodDetails(periodId)
    };

    const editOption = {
      label: intl.formatMessage({ id: 'financial.period.changePeriodInfo' }),
      onClick: () => onEditPeriod(periodId)
    };

    const options = [
      ...(hasPermission(permissions, [SCOPES.editClosePeriods]) ? [editOption] : []),
      ...(hasPermission(permissions, [SCOPES.editClosePeriodsInfo]) ? [editPeriodDetail] : []),
      ...(hasPermission(permissions, [SCOPES.deleteClosePeriods]) ? [deleteOption] : [])
    ];

    return options;
  }, [intl, confirmDelete, onEditPeriod, onOpenPeriodDetails, permissions, periodId]);

  return (
    <>
      <StyledOptionsButton options={periodOptions} position={POSITIONS_TYPES.LEFT} />
      <ConfirmationDelete />
    </>
  );
};

OptionsClosePeriodButton.propTypes = {
  periodId: oneOfType([string, number]),
  projectId: oneOfType([string, number]),
  onEditPeriod: func,
  onOpenPeriodDetails: func
};

export default OptionsClosePeriodButton;
