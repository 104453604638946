import styled from 'styled-components';

import DropdownIcon from 'components/icons/DropdownIcon';

export const ContainerLoader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  display: flex;
  height: 12rem;
  justify-content: center;
  width: 100%;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  margin: 0 auto 5rem;
  text-align: center;

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  thead tr {
    height: 5.1rem;
  }

  table tr {
    min-height: 6.5rem;
  }

  table td {
    border-right: ${({ theme: { colors } }) => `1px solid ${colors.lightGray}`};
    border-top: ${({ theme: { colors } }) => `1px solid ${colors.lightGray}`};
    font-size: 1.4rem;
    overflow: hidden;
    padding: 1rem;
    text-overflow: ellipsis;
    width: 1%;
    white-space: nowrap;
  }

  table tr:first-child th {
    border-top: none;
  }

  table tr td:last-child,
  table tr th:last-child {
    border-right: none;
  }
`;

export const Dropdown = styled(DropdownIcon)`
  height: 1.2rem;
  margin-left: 0.5rem;
  width: 1.2rem;
`;
