import styled from 'styled-components';

export const GeneralDiscountWrapper = styled.div`
  ${({ theme }) => theme.new.typography.p4};
  align-items: center;
  display: flex;
  gap: 0.8rem;

  & > button {
    font-weight: ${({ theme }) => theme.new.fontWeights.semiBold};
  }
  & > button > svg > path {
    stroke-width: 1.67px;
  }
`;

export const InputContainer = styled.div`
  max-height: 3.6rem;
  position: relative;
  width: 10.8rem;
  & > div > input {
    ${({ theme }) => theme.new.typography.p4};
  }
`;

export const Percentage = styled.span`
  color: ${({ theme }) => theme.new.colors.N600};
  left: 1.2rem;
  position: absolute;
  top: 0.8rem;
  z-index: 2;
`;
