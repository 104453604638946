import styled, { css } from 'styled-components';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as Briefcase } from 'assets/icons/briefcase.svg';
import { ReactComponent as CoinStack } from 'assets/icons/coin-stack.svg';
import { ReactComponent as People } from 'assets/icons/people.svg';
import { ReactComponent as Rocket } from 'assets/icons/rocket.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';

function iconColor(active) {
  return active
    ? css`
        &,
        & path {
          stroke: ${({ theme }) => theme.new.colors.P500};
        }
      `
    : css`
        &,
        & path {
          stroke: ${({ theme }) => theme.new.colors.N500};
        }
      `;
}

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid ${({ theme }) => theme.new.colors.P100};
  padding-left: 1.2rem;
  position: relative;
  transition: width 0.4s ease;
  display: ${({ authenticated }) => (authenticated ? 'auto' : 'none')};
  width: ${({ isOpen }) => (isOpen ? '18.4rem' : '7.2rem')};
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 2.4rem;
`;

export const Item = styled.li`
  & > a {
    align-items: center;
    display: flex;
    gap: 1.6rem;
    text-decoration: none;
    padding: 1.2rem 0 1.2rem 1.2rem;
    overflow: hidden;

    &:hover {
      background-color: ${({ theme }) => theme.new.colors.N100};
    }

    & span {
      ${({ theme }) => theme.new.typography.p6SemiBold};

      color: ${({ theme, active }) => (active ? theme.new.colors.P500 : theme.new.colors.N500)};
      display: ${({ isOpen }) => (isOpen ? 'inline' : 'none')};
      flex-shrink: 0;
      line-height: 1.4rem;
      transition: width 0.1s ease-out 0.3s;
      width: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
    }
  }

  & a.active {
    background-color: ${({ theme }) => theme.new.colors.P100};
    border-right: 3px solid ${({ theme }) => theme.new.colors.P500};

    span {
      color: ${({ theme }) => theme.new.colors.P500};
    }
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.new.colors.N300};
  bottom: 3rem;
  color: ${({ theme }) => theme.new.colors.black};
  cursor: pointer;
  font-size: 24px;
  height: 3.2rem;
  position: absolute;
  right: -1.6rem;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BriefcaseIcon = styled(Briefcase)`
  ${({ active }) => iconColor(active)}
  flex-shrink: 0;
`;

export const PeopleIcon = styled(People)`
  ${({ active }) => iconColor(active)}
  flex-shrink: 0;
`;

export const CoinStackIcon = styled(CoinStack)`
  ${({ active }) => iconColor(active)}
  flex-shrink: 0;
`;

export const RocketIcon = styled(Rocket)`
  ${({ active }) => iconColor(active)}
  flex-shrink: 0;
`;

export const ArrowUpIcon = styled(ArrowUp)`
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
  transition: transform 0.4s ease;
`;

export const SettingsIcon = styled(Settings)`
  ${({ active }) => iconColor(active)}
  flex-shrink: 0;
`;
