import Badge from '@mui/material/Badge';
import styled from 'styled-components';

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    top: '5px',
    right: '5px',
    display: ({ $notificationsSeen }) => (!$notificationsSeen ? 'block' : 'none'),
    backgroundColor: '#FF6565',
    minWidth: '15px',
    height: '15px',
    borderRadius: '50%'
  }
});

export const MenuContainer = styled.div`
  height: 100%;
  margin-bottom: 1.9rem;
  margin-right: 11.21px;
  max-height: 75.5rem;
  overflow-y: scroll;
  padding: 20px 11px 0 20px;
  width: 43rem;

  ::-webkit-scrollbar {
    width: 4.79px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 50px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
