import { func, instanceOf, oneOfType, string } from 'prop-types';
import { useCallback, useState } from 'react';

import { DiscountIcon } from 'components/common/DiscountIcon';
import { TimelineRowWrapper } from 'components/common/Layout/Timeline';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import ProjectOptionsButton from 'components/common/ProjectOptionsButton';
import AssignmentColumn from 'components/ProjectsOverview/ProjectsTable/AssignmentColumn';
import { projectShape } from 'constants/shapes';
import { useDispatch, useNewProjectStatusProps, useProjects, useStatus } from 'hooks';
import { getProjectAssignments } from 'state/actions/projectActions';
import { addDays, stringToDate } from 'utils/date';

import ProjectAssignments from '../ProjectAssignments';
import ProjectStatuses from '../ProjectStatuses';
import {
  ArrowButton,
  DownArrowIcon,
  ExpandedRow,
  LoadingContainer,
  OptionsContainer,
  ProjectHeader,
  ProjectInfo,
  ProjectTitle,
  TimelineRow
} from './styles';

const ProjectRow = ({
  project,
  setNewProjectStatus,
  setEditProjectStatus,
  startDateTimeline,
  endDateTimeline,
  setAddAssignmentTo,
  setEditAssignment,
  setPersonId,
  getProjectsRequest,
  handleExpandedProjects
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { name, assignments, allStatuses, projectType, billableType, id: projectId } = project;
  const getAssignmentsRequest = useDispatch(getProjectAssignments);
  const { isPending } = useStatus(getProjectAssignments);
  const { assignmentsByProject } = useProjects(projectId);

  const addNewStatus = useCallback(
    (startDate, endDate, options) =>
      setNewProjectStatus({
        initialValues: {
          startDate: stringToDate(startDate),
          endDate: stringToDate(endDate)
        },
        ...options
      }),
    [setNewProjectStatus]
  );

  const newProjectStatusProps = useNewProjectStatusProps(
    allStatuses,
    addNewStatus,
    startDateTimeline,
    endDateTimeline
  );

  const addNewStatusToProject = useCallback(() => {
    const newStartDate = addDays(project?.endDate, 1);
    addNewStatus(newStartDate, addDays(newStartDate, 7));
  }, [addNewStatus, project.endDate]);

  const editStatus = status =>
    setEditProjectStatus({
      statusId: status.id,
      initialValues: {
        ...status,
        startDate: stringToDate(status.startDate),
        endDate: stringToDate(status.endDate)
      }
    });

  const handleExpand = () => {
    handleExpandedProjects(projectId, isExpanded);
    if (!isExpanded)
      getAssignmentsRequest({ projectId, startDate: startDateTimeline, endDate: endDateTimeline });
    setIsExpanded(expanded => !expanded);
  };

  return (
    <TimelineRowWrapper>
      <TimelineRow>
        <ProjectHeader id={`projectRowContainer-${project.id}`} $isExpanded={isExpanded}>
          <ArrowButton type="button" onClick={() => handleExpand()}>
            <DownArrowIcon $isExpanded={isExpanded} />
          </ArrowButton>
          <ProjectInfo>
            <ProjectTitle id={`projectRowName-${project.id}`}>
              {name}
              {!!project.globalDiscountPercentage && (
                <DiscountIcon discount={project.globalDiscountPercentage} />
              )}
            </ProjectTitle>
            <AssignmentColumn
              open={assignments?.open}
              pending={assignments?.pending}
              confirmed={assignments?.confirmed}
              overAssigned={assignments?.overAssigned}
            />
          </ProjectInfo>
          <OptionsContainer>
            <ProjectOptionsButton
              projectId={project.id}
              firstOptionCallback={addNewStatusToProject}
              handleRefreshData={getProjectsRequest}
            />
          </OptionsContainer>
        </ProjectHeader>
        <ProjectStatuses
          editStatus={editStatus}
          allStatuses={allStatuses}
          newProjectStatusProps={newProjectStatusProps}
          serviceType={projectType}
          billableType={billableType}
          startDateTimeline={startDateTimeline}
          endDateTimeline={endDateTimeline}
        />
      </TimelineRow>
      <ExpandedRow $isExpanded={isExpanded}>
        {isPending && !assignmentsByProject ? (
          <LoadingContainer>
            <CenteredLoading />
          </LoadingContainer>
        ) : (
          <ProjectAssignments
            project={project}
            resources={assignmentsByProject?.resources}
            openAssignments={assignmentsByProject?.openAssignments}
            startDateTimeline={startDateTimeline}
            endDateTimeline={endDateTimeline}
            setAddAssignment={setAddAssignmentTo}
            setEditAssignment={setEditAssignment}
            setPersonId={setPersonId}
          />
        )}
      </ExpandedRow>
    </TimelineRowWrapper>
  );
};

ProjectRow.propTypes = {
  project: projectShape,
  setEditProjectStatus: func,
  setNewProjectStatus: func,
  startDateTimeline: oneOfType([string, instanceOf(Date)]),
  endDateTimeline: oneOfType([string, instanceOf(Date)]),
  setAddAssignmentTo: func,
  setEditAssignment: func,
  setPersonId: func,
  getProjectsRequest: func,
  handleExpandedProjects: func
};

export default ProjectRow;
