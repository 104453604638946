import styled from 'styled-components';

import { inputBorder } from 'utils/inputColors';

export const StyledInput = styled.input`
  ${({ theme }) => theme.typography.regular};
  border-radius: ${({ borderRadius }) => borderRadius || '0.8rem'};
  border: ${inputBorder};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  box-shadow: none;
  color: ${({ theme }) => theme.colors.gray100};
  font-size: 1.5rem;
  line-height: 2;
  min-height: ${({ minHeight }) => minHeight || '5rem'};
  padding-left: ${({ paddingLeft }) => paddingLeft || '1rem'};
  transition: all 0.2s ease-in-out;

  &:focus {
    border-color: ${({ errors, theme: { colors } }) => (errors ? colors.red : colors.seaBlue)};
    box-shadow: none;
  }
`;

export const Label = styled.label`
  ${({ theme }) => theme.typography.regular}
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1.3rem;
  bottom: auto;
  box-shadow: none;
  color: ${({ errors, theme: { colors } }) => (errors ? colors.red : colors.gray80)};
  font-size: ${({ value }) => (value ? '1.2rem' : '1.6rem')};
  left: ${({ value }) => (value ? '1rem' : '0.6rem')};
  padding: 0 0.5rem;
  pointer-events: none;
  position: absolute;
  top: ${({ value }) => (value ? 0 : '2.2rem')};
  transition: all 0.2s ease-in-out;
  user-select: none;
`;

export const ErrorSpan = styled.span`
  ${({ theme }) => theme.typography.italic}
  bottom: -0.5rem;
  color: ${props => props.theme.colors.red};
  font-size: 1.4rem;
  line-height: 3rem;
  left: 0.5rem;
  position: absolute;
`;

export const InputContainer = styled.div`
  background: none;
  font-size: 1.6rem;
  overflow: hidden;
  padding: ${({ padding }) => padding || '1.1rem 0 2.6rem'};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  position: relative;

  &:focus-within ${Label} {
    color: ${({ errors, theme: { colors } }) => (errors ? colors.red : colors.seaBlue)};
    font-size: 1.2rem;
    left: 1rem;
    top: 0;
  }
`;
