import styled from 'styled-components';

import { OptionsButtons } from 'components/Financial/Layout/OptionsButtons';

export const StyledOptionsButton = styled(OptionsButtons)`
  margin: 0;

  circle {
    fill: ${({ theme }) => theme.new.colors.neutral900};
  }
`;
