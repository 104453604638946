import { arrayOf } from 'prop-types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useTheme } from 'styled-components';

import { AvailableCapacityGraphShape } from 'constants/shapes';

import BarTooltip from './BarTooltip';
import CustomizedLabel from './CustomizedLabel';
import LabelFormatter from './LabelFormatter';
import XAxisTick from './XAxisTick';
import YAxisTick from './YAxisTick';

const AvailableCapacityGraph = ({ data }) => {
  const theme = useTheme();

  const handleColor = (index, name) =>
    ({
      DevOps: theme.colors.puertoRico,
      QA: theme.colors.hollywoodCerise
    }[name] || theme.colors.openBlue);

  return (
    <ResponsiveContainer minHeight={data.length * 30}>
      <BarChart
        width={50}
        data={data}
        layout="vertical"
        barSize={16}
        margin={{
          top: 50,
          right: 50,
          left: 80
        }}
      >
        <CartesianGrid horizontal={false} stroke={theme.colors.cornFlowerBlue} />
        <XAxis type="number" tick={<XAxisTick />} />
        <YAxis
          dataKey="name"
          type="category"
          width={100}
          label={CustomizedLabel}
          tick={<YAxisTick />}
          tickLine={false}
        />
        <Tooltip content={BarTooltip} />
        <Bar dataKey="percentage" radius={4}>
          <LabelList dataKey="percentage" position="right" content={LabelFormatter} />
          {data?.map(({ name }, index) => (
            <Cell cursor="pointer" key={`cell-${index}`} fill={handleColor(index, name)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

AvailableCapacityGraph.propTypes = {
  data: arrayOf(AvailableCapacityGraphShape)
};

export default AvailableCapacityGraph;
