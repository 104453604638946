import { useState } from 'react';
import { useIntl } from 'react-intl';

import ActiveFilters from 'components/common/ActiveFilters';
import DetailsBoxTitle from 'components/common/DetailsBoxTitle';
import LoadingWrapper from 'components/common/LoadingWrapper';
import InfoTooltip from 'components/Financial/InfoTooltip';
import ResourceProfileModal from 'components/Reports/ResourceProfileModal';
import { ALLOCATION_TIMELINE_TYPES } from 'constants/constants';
import { useBenchInsights } from 'hooks';

import Filters from './Filters';
import { BenchSummary, Container, FiltersContainer, InfoItem, StyledInfoIcon } from './styles';
import BenchListTable from './Table';

const BenchList = () => {
  const [personId, setPersonId] = useState(0);

  const intl = useIntl();

  const {
    rows,
    filters,
    activeFilters,
    sortConfig,
    availableFTE,
    getDashboardList,
    isLoading,
    exportTable,
    clearFilters,
    setFilters,
    onSort
  } = useBenchInsights();

  return (
    <>
      <Container>
        <DetailsBoxTitle
          title="insights.bench.benchList.title"
          indicators={Object.values(ALLOCATION_TIMELINE_TYPES)}
        />
        <FiltersContainer>
          <Filters
            filters={filters}
            setFilters={setFilters}
            getDashboardList={getDashboardList}
            exportTable={!!rows?.length && exportTable}
          />
          <ActiveFilters
            filters={filters}
            setFilters={setFilters}
            handleClear={clearFilters}
            selectedOptions={activeFilters}
            withIntlLabel={false}
          />
        </FiltersContainer>
        <BenchSummary>
          <InfoItem>
            <span>{`${intl.formatMessage({ id: 'common.FTE' })}: ${availableFTE}`}</span>
            <InfoTooltip textIntlId="dashboard.people.FTE.infoTooltip" width="auto">
              <StyledInfoIcon />
            </InfoTooltip>
          </InfoItem>
        </BenchSummary>
        <LoadingWrapper isLoading={isLoading}>
          <BenchListTable
            rows={rows}
            setResourceModalPersonId={setPersonId}
            dateParams={{ startDate: filters?.startDate, endDate: filters?.endDate }}
            sortConfig={sortConfig}
            onSort={onSort}
          />
        </LoadingWrapper>
      </Container>
      {Boolean(personId) && (
        <ResourceProfileModal
          open={Boolean(personId)}
          personId={personId}
          hide={() => setPersonId(null)}
        />
      )}
    </>
  );
};

export default BenchList;
