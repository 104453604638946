import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Breadcrumb from 'components/common/Breadcrumb';
import PermissionsGate from 'components/PermissionGate';
import AddNewResourcesModal from 'components/timeline/AddNewResourceModal';
import PeopleTable from 'components/timeline/PeopleTable';
import ResourceFilters from 'components/timeline/ResourceFilters';
import TimelineGrid from 'components/timeline/TimelineGrid';
import TimelineHeader from 'components/timeline/TimelineHeader';
import UnassignedSkillsTable from 'components/timeline/UnassignedSkillsTable';
import { BUTTON_VARIANTS } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { useDispatch, useStatus, useTimelineDates, usePeople, useRole } from 'hooks';
import {
  getPeopleAvailability,
  resetResourceFilters,
  toggleResourceExpanded
} from 'state/actions/peopleActions';
import hasPermission from 'utils/hasPermission';

import { ResourcesContainer, StyledButton, TimelineContainer, TopBarResourcePage } from './styles';

const Resource = () => {
  const intl = useIntl();
  const [addResource, setAddResource] = useState(null);
  const getPeopleAvailabilityRequest = useDispatch(getPeopleAvailability);
  const toggleExpandedRequest = useDispatch(toggleResourceExpanded);
  const reset = useDispatch(resetResourceFilters);

  const { startDate, endDate, calendar } = useTimelineDates();
  const { isPending: isPendingPeople } = useStatus(getPeopleAvailability);
  const { filteredPeople, expandedPeople } = usePeople();
  const { permissions } = useRole();

  const handlerAddNewResource = () => setAddResource(true);

  useEffect(() => {
    getPeopleAvailabilityRequest({ startDate, endDate });
  }, [endDate, getPeopleAvailabilityRequest, startDate]);

  const isLoading = isPendingPeople;

  useEffect(() => () => reset(), [reset]);

  return (
    <ResourcesContainer>
      <TopBarResourcePage>
        <Breadcrumb lastBread={intl.formatMessage({ id: 'common.timeline' })} />
        <PermissionsGate scopes={[SCOPES.createResources]}>
          <StyledButton
            id="newResourceButton"
            variant={BUTTON_VARIANTS.PRIMARY}
            textIntlId="dashboard.tab.addNewResource"
            onClick={handlerAddNewResource}
            fullWidth
          />
        </PermissionsGate>
      </TopBarResourcePage>
      <ResourceFilters />
      <TimelineContainer>
        <TimelineHeader calendar={calendar} />
        <TimelineGrid startDate={startDate} endDate={endDate} />
        <UnassignedSkillsTable isLoading={isPendingPeople} />
        <PeopleTable
          people={filteredPeople}
          expandedPeople={expandedPeople}
          isLoading={isLoading}
          toggleExpandedRequest={toggleExpandedRequest}
          isReadOnly={
            !hasPermission(permissions, [SCOPES.editAssignment, SCOPES.editAssignmentBasic])
          }
          getPeopleAvailabilityRequest={getPeopleAvailabilityRequest}
        />
      </TimelineContainer>
      {addResource && (
        <AddNewResourcesModal
          isShowing={!!addResource}
          hide={() => setAddResource(null)}
          initialValues={addResource?.initialValues}
        />
      )}
    </ResourcesContainer>
  );
};

export default Resource;
