import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: ${({ width }) => width};
  height: 1.6rem;
  position: relative;
`;

export const StyleBars = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ totalDays }) => `repeat(${totalDays}, 1fr)`};
  height: 1.6rem;
  position: relative;
`;

export const DivisionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ totalDays }) => `repeat(${totalDays}, 1fr)`};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Division = styled.div`
  ${({ theme }) => theme.new.typography.p6};
  background: ${({ theme }) => theme.new.colors.black};
  grid-column: ${({ start, column }) => `${start}/span ${column}`};
  grid-row: 1;
  position: relative;
  text-transform: uppercase;
  width: 1px;
  z-index: ${({ theme }) => theme.zIndex.gridTimeline};

  &:first-child,
  &:last-child {
    background: transparent;
  }

  &:last-child {
    & > p {
      left: 0;
    }
  }

  & > p {
    bottom: -2.5rem;
    left: -1.5rem;
    position: absolute;
  }
`;
