import { number } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-left.svg';
import BoxContainer from 'components/common/BoxContainer';
import CustomTab from 'components/common/CustomTab';
import { BILLING_UNITS } from 'constants/constants';
import { useFinancial, useStatus } from 'hooks';
import { getProjectOpenedPeriods, getProjectOpenedResources } from 'state/actions/financialActions';

import OpenedPeriods from '../OpenedPeriods';
import ResourceSetup from '../ResourceSetup';
import {
  Container,
  ExpandableSection,
  ExpandButton,
  Header,
  Title,
  Body,
  GridContainer
} from './styles';

function SplitCosts({ projectId }) {
  const intl = useIntl();
  const staticGridRef = useRef();
  const periodItemsRef = useRef([]);

  const [isEditSetup, setIsEditSetup] = useState(false);
  const [editingPeriod, setEditingPeriod] = useState(null);
  const [billingUnit, setBillingUnit] = useState(BILLING_UNITS.DAILY);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(
    intl.formatMessage({ id: 'common.cap.billingUnit.daily' })
  );
  const { projectDetails } = useFinancial();
  const { financial } = projectDetails;
  const [globalDiscountEdit, setGlobalDiscountEdit] = useState(false);
  const [globalDiscountAmount, setGlobalDiscountAmount] = useState(
    financial?.globalDiscountPercentage
  );

  const { isPending: isLoadingOpenedResources } = useStatus(getProjectOpenedResources);
  const { isPending: isLoadingOpenedPeriods } = useStatus(getProjectOpenedPeriods);

  const handleScrollStatic = scroll => {
    periodItemsRef.current.forEach(ref => {
      if (ref) {
        ref.scrollTop = scroll.target.scrollTop;
      }
    });
  };

  const openPeriodsExpanded = !isEditSetup && isExpanded;

  const handleBillingUnitChange = unit => setBillingUnit(unit.toLowerCase());

  useEffect(() => {
    handleBillingUnitChange(activeTab);
  }, [activeTab]);

  return (
    <BoxContainer>
      <Container>
        <Header>
          <Title>{intl.formatMessage({ id: 'financial.splitCosts.title' })}</Title>
          <CustomTab
            activeTab={activeTab}
            tabTextIds={['common.cap.billingUnit.hourly', 'common.cap.billingUnit.daily']}
            setActiveTab={setActiveTab}
            disabled={isEditSetup || !!editingPeriod}
          />
        </Header>
        <Body>
          <GridContainer>
            <ResourceSetup
              projectId={projectId}
              billingUnit={billingUnit}
              isEditSetup={isEditSetup}
              setIsEditSetup={setIsEditSetup}
              handleScroll={handleScrollStatic}
              innerRef={staticGridRef}
              openPeriodsExpanded={openPeriodsExpanded}
              globalDiscountEdit={globalDiscountEdit}
              setGlobalDiscountEdit={setGlobalDiscountEdit}
              globalDiscountAmount={globalDiscountAmount}
              setGlobalDiscountAmount={setGlobalDiscountAmount}
            />
            <ExpandableSection openPeriodsExpanded={openPeriodsExpanded}>
              <ExpandButton
                type="button"
                onClick={() => setIsExpanded(prev => !prev)}
                openPeriodsExpanded={openPeriodsExpanded}
              >
                <ArrowIcon />
              </ExpandButton>
              <OpenedPeriods
                projectId={projectId}
                billingUnit={billingUnit}
                editingPeriod={editingPeriod}
                globalDiscountEdit={globalDiscountEdit}
                globalDiscountAmount={globalDiscountAmount}
                setEditingPeriod={setEditingPeriod}
                isLoading={isLoadingOpenedPeriods || isLoadingOpenedResources}
                staticGridRef={staticGridRef}
                periodItemsRef={periodItemsRef}
              />
            </ExpandableSection>
          </GridContainer>
        </Body>
      </Container>
    </BoxContainer>
  );
}

SplitCosts.propTypes = {
  projectId: number
};

export default SplitCosts;
