import { useIntl } from 'react-intl';

import { ReactComponent as RocketIcon } from 'assets/icons/rocket.svg';

import { Container, Label } from './styles';

function EmptyState() {
  const intl = useIntl();

  return (
    <Container>
      <RocketIcon />
      <Label>{intl.formatMessage({ id: 'common.delivered' })}</Label>
    </Container>
  );
}

export default EmptyState;
