import { arrayOf } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import { useTheme } from 'styled-components';

import { dashboardFinancialOverview } from 'constants/shapes';

import CustomizedLabelY from './CustomizedLabelY';
import LegendItem from './LegendItem';
import XAxisTick from './XAxisTick';
import YAxisTick from './YAxisTick';

const OverviewChart = ({ overviewData }) => {
  const intl = useIntl();
  const theme = useTheme();

  const getFormattedGraphData = () => {
    let maxValue = 0;

    if (overviewData?.length > 0) {
      const formattedOverviewData = overviewData?.map(({ revenue, costs, ...item }) => {
        if (revenue > maxValue) maxValue = revenue;
        if (costs > maxValue) maxValue = costs;
        const isCostsGreater = costs > revenue;
        return {
          ...item,
          top: isCostsGreater ? Math.abs(costs - revenue) : Math.abs(revenue - costs),
          bottom: isCostsGreater ? revenue : costs,
          isCostsGreater
        };
      });
      return formattedOverviewData?.map(item => ({
        ...item,
        divider: 0.01 * 10 ** (Math.round(maxValue).toString().length - 1)
      }));
    }

    return [];
  };

  const getLegendLabel = name => {
    if (name === 'top') return <LegendItem name={intl.formatMessage({ id: 'common.revenue' })} />;
    if (name === 'bottom') return <LegendItem name={intl.formatMessage({ id: 'common.cost' })} />;
  };

  const overviewDataWithDivider = getFormattedGraphData();

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={400} minWidth={500}>
      <BarChart
        width={500}
        height={300}
        barSize={50}
        data={overviewDataWithDivider}
        margin={{
          top: 50,
          right: 2,
          left: 10,
          bottom: 5
        }}
      >
        <CartesianGrid vertical={false} stroke={theme.new.colors.N300} />
        <XAxis dataKey="month" tick={<XAxisTick />} tickLine={false} axisLine={false} />
        <YAxis tick={<YAxisTick />} tickLine={false} axisLine={false} label={CustomizedLabelY} />
        <Legend iconType="circle" iconSize={8} formatter={name => getLegendLabel(name)} />
        <defs>
          <linearGradient id="revenueGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#A284F9" />
            <stop offset="84.7%" stopColor="#7A1BF3" />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="costGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#F86F8D" />
            <stop offset="100%" stopColor="#D20D38" />
          </linearGradient>
        </defs>
        <Bar dataKey="bottom" fill="#D20D38" stackId="a">
          {overviewDataWithDivider.map(({ isCostsGreater }, index) => (
            <Cell
              key={`cell-${index}`}
              fill={`url(#${isCostsGreater ? 'revenueGradient' : 'costGradient'})`}
            />
          ))}
        </Bar>
        <Bar dataKey="divider" stackId="a" fill="transparent" />
        <Bar dataKey="top" stackId="a" fill="#6C3DF7" radius={[4, 4, 0, 0]}>
          {overviewDataWithDivider.map(({ isCostsGreater }, index) => (
            <Cell
              key={`cell-${index}`}
              fill={`url(#${isCostsGreater ? 'costGradient' : 'revenueGradient'})`}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

OverviewChart.propTypes = { overviewData: arrayOf(dashboardFinancialOverview) };

export default OverviewChart;
