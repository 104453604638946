import styled from 'styled-components';

import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import FilterDropdown from 'components/common/FilterDropdown';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Side = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;

  &:nth-child(2) {
    justify-content: flex-end;
    width: 55%;
  }
`;

export const TransitionWrapper = styled.div`
  display: flex;
  gap: 1.2rem;
  width: ${({ activeTab }) => (activeTab === 0 ? '43rem' : '35rem')};
  transition: all 0.6s ease;

  & > * {
    max-height: 4.8rem;
  }

  & > div:nth-child(2) {
    max-width: 25rem;
    & > div > div > input {
      height: 4.8rem;
    }
  }
`;

export const StyledFilterDropdown = styled(FilterDropdown)`
  height: 4.7rem;
  margin: 0;
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  height: 4.7rem;
  width: 100%;
`;

export const ExportButton = styled(Button)`
  padding: 2.3rem 0;
`;

export const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  && > span {
    ${({ theme }) => theme.new.typography.p4};
  }
`;
