import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Label = styled.label`
  ${({ theme }) => theme.new.typography.p5};

  border: 1px solid ${({ theme }) => theme.new.colors.N400};
  border-right-width: 0;
  color: ${({ theme }) => theme.new.colors.N800};
  padding: 1rem 1.6rem;

  &:first-of-type {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-of-type {
    border-radius: 0px 4px 4px 0px;
    border-right-width: 1px;
  }

  & input {
    display: none;
  }

  &:has(:checked) {
    background-color: ${({ theme }) => theme.new.colors.P500};
    color: ${({ theme }) => theme.new.colors.white};
  }
`;
