import { bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import Button from 'components/common/Button';
import { Row } from 'components/common/Layout/Row';
import NewInput from 'components/common/NewInput';
import NewModal from 'components/common/NewModal';
import { BUTTON_VARIANTS } from 'constants/constants';
import { softSkillValidation } from 'constants/validators';
import { useDispatch, useStatus } from 'hooks';
import { addResourceSoftSkills } from 'state/actions/peopleActions';
import { composeAddSoftSkill } from 'utils/resourceProfileUtilities';

import { CheckboxWrapper, InputWrapper, StyledCheckbox } from './styles';

const fields = {
  skill: 'skill'
};

const SoftSkillsModal = ({ isShowing, handleToggle, handleAdd, repeatedSkill }) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [checked, setChecked] = useState(false);
  const resetAddResourceTechnicalSkills = useDispatch(addResourceSoftSkills.reset);
  const { error: errorAddResourceSoftSkills } = useStatus(addResourceSoftSkills);

  const onSubmit = async data => {
    const result = await handleAdd(composeAddSoftSkill(data));
    if (result) {
      reset();
      if (!checked) {
        handleToggle();
      }
    }
  };

  useEffect(() => {
    reset();
    return () => resetAddResourceTechnicalSkills();
  }, [reset, resetAddResourceTechnicalSkills]);

  return (
    <NewModal
      isShowing={isShowing}
      hide={handleToggle}
      title={intl.formatMessage({ id: 'modal.softSkill.title' })}
      maxWidth="65rem"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <NewInput
            type="text"
            fullWidth
            label={intl.formatMessage({ id: 'form.skillTechnology' })}
            errors={errors?.[fields.skill]?.message}
            {...register(fields.skill, softSkillValidation[fields.skill])}
          />
        </InputWrapper>
        <CheckboxWrapper>
          <StyledCheckbox
            value={checked}
            onChange={() => setChecked(!checked)}
            label={intl.formatMessage({ id: 'reports.resource.editDetails.addSkill' })}
            variant="new"
          />
        </CheckboxWrapper>
        {(errorAddResourceSoftSkills || repeatedSkill) && (
          <AlertMessage>{errorAddResourceSoftSkills || repeatedSkill}</AlertMessage>
        )}
        <Row gap="1.2rem" content="start">
          <Button
            id="cancelBtn"
            type="button"
            textIntlId="common.cancel"
            onClick={handleToggle}
            variant={BUTTON_VARIANTS.NEW_SECONDARY}
          />
          <Button
            id="saveBtn"
            type="submit"
            textIntlId="common.addSkill"
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
          />
        </Row>
      </form>
    </NewModal>
  );
};

SoftSkillsModal.propTypes = {
  handleToggle: func,
  isShowing: bool,
  handleAdd: func,
  repeatedSkill: string
};

export default SoftSkillsModal;
