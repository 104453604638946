import PageHeader from 'components/common/PageHeader';
import OverviewBlock from 'components/FinancialInsights/OverviewBlock';
import MarginByProjectType from 'components/FinancialInsights/OverviewBlock/MarginByProjectType';
import QuarterBlocks from 'components/FinancialInsights/QuartersBlocks';
import RevenueBlock from 'components/FinancialInsights/RevenueBlock';

import { Container, Content, Row } from './styles';

const FinancialInsights = () => (
  <Container>
    <PageHeader titleIntlId="common.cap.insights" subtitleIntlId="common.financial" />
    <Content>
      <OverviewBlock />
      <QuarterBlocks />
      <Row>
        <RevenueBlock />
        <MarginByProjectType />
      </Row>
    </Content>
  </Container>
);

export default FinancialInsights;
