import { string, func, bool, oneOfType, number } from 'prop-types';

import { ReactComponent as TickSvg } from 'components/icons/tick.svg';

import { StyledCheckbox, CheckboxContainer, Label } from './styles';

const Checkbox = ({
  label,
  value = false,
  onChange,
  field,
  disabled,
  variant = 'old',
  size,
  ...props
}) => {
  const onChangeValue = () => {
    field !== undefined ? onChange(!value, field) : onChange(!value);
  };

  return (
    <CheckboxContainer
      onClick={disabled ? undefined : onChangeValue}
      disabled={disabled}
      {...props}
    >
      <StyledCheckbox checked={value} disabled={disabled} variant={variant} size={size}>
        <TickSvg />
      </StyledCheckbox>
      {label && <Label variant={variant}>{label}</Label>}
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  disabled: bool,
  field: oneOfType([string, number]),
  label: string,
  onChange: func,
  value: oneOfType([bool, string]),
  variant: string,
  size: string
};

export default Checkbox;
