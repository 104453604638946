import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';

class SettingsService {
  static async getResources() {
    return httpClient.get(ENDPOINTS.settingsResources);
  }

  static async getGroups() {
    return httpClient.get(ENDPOINTS.settingsGroups);
  }

  static async addTemporalRole(payload) {
    return httpClient.post(ENDPOINTS.settingsResources, payload);
  }

  static async editResourceRole(id, payload) {
    return httpClient.put(ENDPOINTS.settingsEditRole(id), payload);
  }

  static async deleteResourceRole(id) {
    return httpClient.delete(ENDPOINTS.settingsEditRole(id));
  }

  static async getRoleGroups() {
    return httpClient.get(ENDPOINTS.roleGroups);
  }

  static async addRoleRelation(payload) {
    return httpClient.post(ENDPOINTS.roleGroups, payload);
  }

  static async editRoleRelation(id, payload) {
    return httpClient.put(ENDPOINTS.editRoleRelation(id), payload);
  }

  static async deleteRoleRelation(id) {
    return httpClient.delete(ENDPOINTS.editRoleRelation(id));
  }
}

export default SettingsService;
