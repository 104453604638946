import { bool, func, oneOf, string } from 'prop-types';
import { useMemo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'assets/icons/edit-option.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-icon.svg';
import { ReactComponent as DeleteIcon } from 'components/icons/delete.svg';
import ResourceProfileModal from 'components/Reports/ResourceProfileModal';
import ConfirmAssignmentModal from 'components/timeline/ConfirmAssignmentModal';
import { POSITIONS_TYPES } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { ASSIGNMENT_STATUS } from 'constants/projectConstants';
import routesPaths from 'constants/routesPaths';
import { assignmentsListShape } from 'constants/shapes';
import { useConfirm, useDispatch, useRole } from 'hooks';
import {
  isolatedConfirmAssignment,
  isolatedDeleteAssignment
} from 'state/actions/assignmentActions';
import hasPermission from 'utils/hasPermission';
import { routeWithProps } from 'utils/helpers';

import { SearchIcon, StyledOptionsButton, TickIcon } from './styles';

const AssignmentOptionsButton = ({
  assignment,
  editAssignment,
  status,
  isTimelineView = true,
  handleRefreshData,
  className
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [confirmAssignment, setConfirmAssignment] = useState();
  const [isShowResourceProfileModal, setIsShowResourceProfileModal] = useState(false);

  const deleteRequest = useDispatch(isolatedDeleteAssignment);
  const { permissions } = useRole();

  const assignmentId = assignment?.assignmentId;
  const { personId, projectId } = assignment;

  const [ConfirmationDelete, confirmDelete] = useConfirm({
    title: intl.formatMessage({ id: 'template.deleteEntity' }, { entity: 'assignment' }),
    message: '',
    onConfirm: () =>
      deleteRequest({
        assignmentId,
        personId,
        projectId
      }),
    onSuccess: () => handleRefreshData?.(),
    confirmLabelIntlId: 'common.delete',
    cancelLabelIntlId: 'common.cancel'
  });

  const handleRedirect = useCallback(() => {
    history.push(
      routeWithProps(routesPaths.assignmentDetails, {
        projectId,
        assignmentId
      })
    );
  }, [assignmentId, projectId, history]);

  const assignmentOptions = useMemo(() => {
    const confirmOption = {
      label: intl.formatMessage({ id: 'common.confirmCandidate' }),
      onClick: () => setConfirmAssignment(assignment),
      icon: <TickIcon />
    };

    const searchPeopleOption = {
      label: intl.formatMessage({ id: 'common.searchPeople' }),
      onClick: handleRedirect,
      icon: <SearchIcon />
    };

    const deleteOption = {
      label: intl.formatMessage({ id: 'common.delete' }),
      onClick: () => confirmDelete(),
      icon: <DeleteIcon />
    };

    const editOption = {
      label: intl.formatMessage({ id: 'common.editAssignment' }),
      onClick: () => editAssignment(assignment),
      icon: <EditIcon />
    };

    const viewProfileOption = {
      label: intl.formatMessage({ id: 'common.viewProfile' }),
      onClick: () => setIsShowResourceProfileModal(true),
      icon: <UserIcon />
    };

    const optionsPermissionGate = (option, scopes) => [
      hasPermission(permissions, scopes) ? option : []
    ];

    const options = {
      [ASSIGNMENT_STATUS.confirmed]: [
        ...optionsPermissionGate(viewProfileOption, [SCOPES.seeProfileDetail]),
        ...optionsPermissionGate(editOption, [SCOPES.editAssignmentBasic, SCOPES.editAssignment]),
        ...optionsPermissionGate(deleteOption, [SCOPES.deleteProjectsResources])
      ],
      [ASSIGNMENT_STATUS.pending]: [
        ...optionsPermissionGate(viewProfileOption, [SCOPES.seeProfileDetail]),
        ...optionsPermissionGate(confirmOption, [SCOPES.confirmAssignment]),
        ...optionsPermissionGate(searchPeopleOption, [SCOPES.searchPeople]),
        ...optionsPermissionGate(editOption, [SCOPES.editAssignmentBasic, SCOPES.editAssignment]),
        ...optionsPermissionGate(deleteOption, [SCOPES.deleteProjectsResources])
      ],
      [ASSIGNMENT_STATUS.open]: [
        ...optionsPermissionGate(searchPeopleOption, [SCOPES.searchPeople]),
        ...optionsPermissionGate(editOption, [SCOPES.editAssignmentBasic, SCOPES.editAssignment]),
        ...optionsPermissionGate(deleteOption, [SCOPES.deleteProjectsSkills])
      ]
    };
    return options[status] || [];
  }, [intl, handleRedirect, permissions, status, assignment, confirmDelete, editAssignment]);

  return (
    <div className={className}>
      <StyledOptionsButton
        options={assignmentOptions}
        position={isTimelineView ? POSITIONS_TYPES.RIGHT : POSITIONS_TYPES.BOTTOM_LEFT}
      />
      <ConfirmationDelete />
      {confirmAssignment && (
        <ConfirmAssignmentModal
          isShowing={!!confirmAssignment}
          hide={() => setConfirmAssignment(null)}
          assignment={assignment}
          name={assignment.resourceName}
          confirmAssignmentAction={isolatedConfirmAssignment}
          handleRefreshData={handleRefreshData}
          dispatchFunction={useDispatch}
        />
      )}
      {isShowResourceProfileModal && (
        <ResourceProfileModal
          open={isShowResourceProfileModal}
          personId={personId}
          hide={() => setIsShowResourceProfileModal(false)}
        />
      )}
    </div>
  );
};

AssignmentOptionsButton.propTypes = {
  assignment: assignmentsListShape,
  editAssignment: func,
  status: oneOf(Object.values(ASSIGNMENT_STATUS)).isRequired,
  isTimelineView: bool,
  handleRefreshData: func,
  className: string
};

export default AssignmentOptionsButton;
