import styled from 'styled-components';

import Select from 'components/common/Select';
import { StyledSelect as CommonStyledSelect } from 'components/common/Select/styles';
import { newInputBorder } from 'utils/inputColors';

export const StyledSelect = styled(Select)`
  ${CommonStyledSelect} {
    .react-select__control {
      border: ${newInputBorder};
      height: 4.4rem;
    }
  }
`;
