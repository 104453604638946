import { object, oneOfType, string, instanceOf, func, bool, arrayOf } from 'prop-types';
import { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import BadgePill from 'components/common/BadgePill';
import Availability from 'components/timeline/Fragments/Availability';
import EmptyAvailability from 'components/timeline/Fragments/Availability/EmptyAvailability';
import NewAvailability from 'components/timeline/Fragments/Availability/NewAvailability';
import { ASSIGNMENTS_STATUS, PROJECT_TYPES } from 'constants/constants';
import { timelineAssignmentShape } from 'constants/shapes';
import { personToAssignmentOption } from 'utils/assignmentFormsUtilities';
import {
  minDate,
  isAfter,
  isBefore,
  getRequestFormatDate,
  getPeriodFormatDateStandard
} from 'utils/date';
import { getColorByAssignmentStatus } from 'utils/projectsOverviewUtilities';
import { getTimelineFragments, splitFragment } from 'utils/timelineUtilities';

const NewPersonProjectsAssignments = ({
  project,
  person,
  assignments,
  startDateTimeline,
  endDateTimeline,
  setAddAssignment,
  setEditAssignment,
  status,
  isReadOnly = false
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const timelineFragments = useMemo(
    () =>
      getTimelineFragments(
        startDateTimeline,
        endDateTimeline
      )(assignments.filter(assignment => !assignment.empty)),
    [startDateTimeline, endDateTimeline, assignments]
  );

  return timelineFragments.map(({ startDate, endDate, ...fragment }, index) => {
    if (fragment.value) {
      const allocation = fragment.value;
      const {
        assignmentId,
        assignmentWorkHours,
        pending,
        note,
        backfillPosition,
        busyPercentage,
        support
      } = allocation;

      const assignmentStatus = pending ? ASSIGNMENTS_STATUS.pending : ASSIGNMENTS_STATUS.confirmed;
      const availabilityStatus = status || assignmentStatus;

      const handleOnClick = () =>
        setEditAssignment({
          projectId: project.id,
          assignmentId
        });

      return (
        <Availability
          projectId={project.id}
          isNonBillable={allocation.assignmentType === PROJECT_TYPES.NON_BILLABLE}
          isSupport={support}
          isFirst={startDate === getRequestFormatDate(startDateTimeline)}
          isLast={endDate === getRequestFormatDate(endDateTimeline)}
          key={`assignment-${assignmentId}`}
          busyPercentage={busyPercentage}
          startDate={startDate}
          endDate={endDate}
          onClick={!isReadOnly ? handleOnClick : null}
          assignmentWorkHours={assignmentWorkHours}
          note={note}
          isBackfillPosition={backfillPosition}
          variant="new"
          status={availabilityStatus}
          isPending={pending}
          label={
            <BadgePill
              color={getColorByAssignmentStatus(theme, availabilityStatus).color}
              fontColor={getColorByAssignmentStatus(theme, availabilityStatus).fontColor}
              isRounded
              borderColor={getColorByAssignmentStatus(theme, availabilityStatus).color}
            >
              {intl.formatMessage({ id: `common.${availabilityStatus}` })}
            </BadgePill>
          }
          completeAssignmentPeriod={getPeriodFormatDateStandard(
            allocation.startDate,
            allocation.endDate
          )}
        />
      );
    }

    const projectId = `${project.id}-${index}`;

    if (isReadOnly)
      return <EmptyAvailability key={projectId} startDate={startDate} endDate={endDate} />;

    const newAssignmentMaxEndDate = minDate(endDateTimeline, project.endDate);

    const onNewAssignmentClick = ({ startDate: selectedStart, endDate: selectedEnd }) =>
      setAddAssignment({
        project,
        initialValues: {
          personId: person && personToAssignmentOption(person),
          startDate: selectedStart,
          endDate: selectedEnd
        }
      });

    if (index === timelineFragments.length - 1) {
      const [assignmentFragment, emptyFragment] = splitFragment(
        { startDate, endDate },
        newAssignmentMaxEndDate
      );

      if (isAfter(startDate, project.endDate))
        return <EmptyAvailability key={projectId} startDate={startDate} endDate={endDate} />;

      if (emptyFragment && isBefore(emptyFragment.endDate < endDateTimeline))
        return (
          <Fragment key={projectId}>
            <NewAvailability
              startDate={assignmentFragment.startDate}
              endDate={assignmentFragment.endDate}
              onSelectionComplete={onNewAssignmentClick}
            />
            <EmptyAvailability
              startDate={emptyFragment.startDate}
              endDate={emptyFragment.endDate}
            />
          </Fragment>
        );
    }

    return (
      <NewAvailability
        key={projectId}
        startDate={startDate}
        endDate={endDate}
        onSelectionComplete={onNewAssignmentClick}
      />
    );
  });
};

NewPersonProjectsAssignments.propTypes = {
  person: object,
  startDateTimeline: oneOfType([string, instanceOf(Date)]),
  endDateTimeline: oneOfType([string, instanceOf(Date)]),
  project: object.isRequired,
  setAddAssignment: func,
  setEditAssignment: func,
  isAvailabilityEmpty: bool,
  assignments: arrayOf(timelineAssignmentShape).isRequired,
  isReadOnly: bool
};

export default NewPersonProjectsAssignments;
