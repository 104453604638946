import styled from 'styled-components';

import Button from 'components/common/Button';

export const ResourcesContainer = styled.div`
  margin: 0 3.2rem;
`;

export const TopBarResourcePage = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1.7rem 0 1.5rem;
`;

export const StyledButton = styled(Button)`
  font-size: 1.5rem;
  line-height: 1;
  height: 3.8rem;
  width: 17.5rem;
  font-size: 15px;
  padding: 0;
`;

export const TimelineContainer = styled.div`
  margin: 1.4rem 0 3.2rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
`;
