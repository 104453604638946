import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import Breadcrumb from 'components/common/BreadcrumbV2';
import Button from 'components/common/Button';
import LoadingWrapper from 'components/common/LoadingWrapper';
import { ResourceProfile } from 'components/Reports';
import NotFound from 'components/common/NotFound';
import { BUTTON_VARIANTS } from 'constants/constants';
import { DISPLAY_MONTH_DAY_YEAR } from 'constants/dateFormats';
import { useRequest, useStatus } from 'hooks';
import { getResourceBio, getResourceProfile } from 'state/actions/resourceProfileActions';
import { dateToString } from 'utils/date';
import { parseUrl } from 'utils/helpers';

import { BioWrapper, Container, Header, RedirectLinkIcon, Title } from './styles';

const MyReportsProfile = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [bioData, setBioData] = useState();
  const [error, setError] = useState(false);

  const [{ isPending: isLoadingResource, response: leftPanelData }, getLeftPanelData] = useRequest(
    getResourceProfile,
    {
      fulfilledCallback: payload => {
        setBioData(payload);
      },
      params: { id }
    }
  );
  const [{ isPending: isLoadingBio }, getBio] = useRequest(getResourceBio, {
    fulfilledCallback: payload => {
      if (!payload) {
        setError(true);
      }
      setBioData(payload);
    }
  });

  const { isRejected: isResourceRejected } = useStatus(getResourceProfile);

  if (isResourceRejected) {
    return <NotFound successRequest={isResourceRejected} />;
  }

  return (
    <Container>
      <Breadcrumb lastBread={leftPanelData?.fullName} />
      <Header>
        <Title>{intl.formatMessage({ id: 'common.resourceProfile' })}</Title>
        <LoadingWrapper isLoading={isLoadingResource || isLoadingBio}>
          <BioWrapper>
            {leftPanelData?.figmaBio ? (
              <a href={parseUrl(leftPanelData.figmaBio)} target="_blank" rel="noreferrer">
                <Button variant={BUTTON_VARIANTS.NEW_SECONDARY} icon={<RedirectLinkIcon />}>
                  {intl.formatMessage({ id: 'common.viewLegacyBio' })}
                </Button>
              </a>
            ) : (
              <Button
                variant={BUTTON_VARIANTS.NEW_SECONDARY}
                icon={<RedirectLinkIcon disabled />}
                disabled
              >
                {intl.formatMessage({ id: 'common.viewLegacyBio' })}
              </Button>
            )}
            <div>
              <Button
                variant={BUTTON_VARIANTS.NEW_SECONDARY}
                icon={<RedirectLinkIcon />}
                onClick={() => getBio({ id })}
              >
                {intl.formatMessage({ id: 'common.requestBio' })}
              </Button>
              {error && (
                <span>{intl.formatMessage({ id: 'resource.experience.genericErrorMessage' })}</span>
              )}
            </div>
            {bioData?.bioUuid && (
              <div>
                <a
                  href={`${process.env.BIO_URL}${bioData.bioUuid}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant={BUTTON_VARIANTS.NEW_SECONDARY} icon={<RedirectLinkIcon />}>
                    {intl.formatMessage({ id: 'common.viewBio' })}
                  </Button>
                </a>
                {bioData?.bioRequestedAt && (
                  <span>
                    {intl.formatMessage(
                      {
                        id: 'profile.bioRequest'
                      },
                      {
                        date: dateToString(bioData?.bioRequestedAt, DISPLAY_MONTH_DAY_YEAR)
                      }
                    )}
                  </span>
                )}
              </div>
            )}
          </BioWrapper>
        </LoadingWrapper>
      </Header>
      <ResourceProfile
        isLoadingResource={isLoadingResource}
        profileData={leftPanelData}
        personId={Number(id)}
        getLeftPanelData={getLeftPanelData}
      />
    </Container>
  );
};

export default MyReportsProfile;
