import { arrayOf, bool } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import RoundAvatar from 'components/common/RoundAvatar';
import ResourceProfileModal from 'components/Reports/ResourceProfileModal';
import { SENIORITY_MAP } from 'constants/constants';
import routesPaths from 'constants/routesPaths';
import { reportsResourceShape } from 'constants/shapes';
import { routeWithProps } from 'utils/helpers';

import {
  BasicInfo,
  BasicInfoContent,
  ArrowIcon,
  Grid,
  Header,
  Location,
  Row,
  Rows,
  StatusColumn,
  Title
} from './styles';

const Table = ({ resources, isLoading }) => {
  const intl = useIntl();

  const [isShowResourceProfileModal, setIsShowResourceProfileModal] = useState(false);
  const [personId, setPersonId] = useState(0);

  const getSeniorityLabel = seniority => {
    const seniorityMessageId = SENIORITY_MAP.get(seniority)?.label;

    return seniorityMessageId ? intl.formatMessage({ id: seniorityMessageId }) : '';
  };

  const showResourceDetail = personId => {
    setPersonId(personId);
    setIsShowResourceProfileModal(true);
  };

  return (
    <>
      <Grid>
        <Title>{`${intl.formatMessage({ id: 'common.resources' })} (${resources?.length})`}</Title>
        <Header>
          <div>{intl.formatMessage({ id: 'common.cap.name' })}</div>
        </Header>
        <Rows>
          {isLoading ? (
            <CenteredLoading />
          ) : (
            resources?.map(
              ({ id, fullName, seniority, country, role, department, avatar }, index) => (
                <Row key={index} onClick={() => showResourceDetail?.(id)}>
                  <BasicInfo>
                    <RoundAvatar squareSize={6.4} src={avatar} />
                    <BasicInfoContent>
                      <h3>{fullName || '--'}</h3>
                      <p>
                        {seniority || (role && department)
                          ? `${getSeniorityLabel(seniority)} ${role || ''} ${
                              department && role && `in ${department}`
                            }`
                          : ''}
                      </p>
                      <Location>{country || ''}</Location>
                    </BasicInfoContent>
                  </BasicInfo>
                  <StatusColumn>
                    <Link to={routeWithProps(routesPaths.myReportsProfile, { id })}>
                      <ArrowIcon />
                    </Link>
                  </StatusColumn>
                </Row>
              )
            )
          )}
        </Rows>
      </Grid>
      {isShowResourceProfileModal && (
        <ResourceProfileModal
          open={isShowResourceProfileModal}
          personId={personId}
          hide={() => setIsShowResourceProfileModal(false)}
        />
      )}
    </>
  );
};

Table.propTypes = {
  resources: arrayOf(reportsResourceShape),
  isLoading: bool
};

export default Table;
