import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.new.fontFamily};
`;

export const TabOption = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 1.8rem;
  font-weight: ${({ selected, theme }) =>
    selected ? theme.fontWeights.bold : theme.fontWeights.normal};
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;
