import styled from 'styled-components';

const getBackgroundColor = props => {
  const { theme, serviceType, mode } = props;
  if (serviceType === 'staffAugmentation')
    return mode == 'light'
      ? theme.new.colors.badgeSecondaryStuffAugmentationFill
      : theme.new.colors.badgePrimaryText;
  if (serviceType === 'fullProduct')
    return mode == 'light'
      ? theme.new.colors.badgeSecondaryFullProductFill
      : theme.new.colors.feedbackAlertLight;
  if (serviceType === 'discovery')
    return mode == 'light'
      ? theme.new.colors.badgeSecondaryDiscoveryFill
      : theme.new.colors.badgePrimaryFill;
  if (serviceType === 'maintenance')
    return mode == 'light' ? theme.new.colors.brandLight : theme.new.colors.softMaintenance;
  return 'transparent';
};

const getColor = props => {
  const { theme, serviceType, mode } = props;
  if (serviceType === 'staffAugmentation')
    return mode == 'light'
      ? theme.new.colors.badgeSecondaryStaffAugmentationText
      : theme.new.colors.badgePrimaryStuffAugmentationText;
  if (serviceType === 'fullProduct')
    return mode == 'light'
      ? theme.new.colors.badgeSecondaryFullProductText
      : theme.new.colors.feedbackAlertBase;
  if (serviceType === 'discovery')
    //  TODO the same color is used twice
    return mode == 'light'
      ? theme.new.colors.badgeSecondaryDiscoveryText
      : theme.new.colors.badgeSecondaryDiscoveryText;
  if (serviceType === 'maintenance')
    return mode == 'light' ? theme.new.colors.brandDark : theme.new.colors.brandDark;
  return 'transparent';
};

export const ServiceTypeLabelStyle = styled.span`
  ${({ theme, mode }) =>
    mode == 'light' ? theme.new.typography.p5 : theme.new.typography.p5SemiBold};
  color: ${getColor};
  background: ${getBackgroundColor};
  border-radius: 1.6rem;
  padding: 2px 8px;
  gap: 10px;
  margin-left: 5px;
`;
