import styled from 'styled-components';

export const Container = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  gap: 1.9rem;
  position: relative;
`;

export const Grid = styled.div`
  ${({ theme }) => theme.new.shadows.medium};
  background: ${({ theme }) => theme.new.colors.neutral100};
  border-radius: 6px;
  border-style: solid solid none solid;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  padding: 2.4rem;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  gap: 3.2rem;
  margin-bottom: 1.6rem;
  & > div:nth-child(1) {
    flex: 1;
  }
  & > * {
    min-width: 9.3rem;
  }
`;
