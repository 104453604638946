import { string } from 'prop-types';

import CloseIcon from 'assets/icons/close.svg';
import { commonPropsReactSelect } from 'constants/shapes';

import { StyledClearIndicator, StyledCloseIcon } from './styles';

const ClearIndicator = ({ innerProps, id, variant, ...rest }) => {
  const customInnerProps = {
    ...innerProps,
    ...(id && { id })
  };

  return (
    <StyledClearIndicator {...rest} innerProps={customInnerProps}>
      {variant === 'new' && <StyledCloseIcon src={CloseIcon} />}
    </StyledClearIndicator>
  );
};

ClearIndicator.propTypes = {
  id: string,
  ...commonPropsReactSelect
};

export default ClearIndicator;
