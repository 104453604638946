import dayjs from 'dayjs';
import humps from 'humps';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import queryString from 'query-string';

import { QUERY_OPTIONS } from 'constants/constants';
import { stringToDate, timeInWeeks } from 'utils/date';

import { ONE_WEEK } from './date';

export const parseInputErrors = error => {
  if (!error) {
    return;
  }
  if (Array.isArray(error)) {
    return error[0];
  }
  return error;
};

export const applyQueryParams = (url, params = {}) => {
  if (isEmpty(params)) {
    return url;
  }
  const queryParams = queryString.stringify(params);
  return `${url}?${queryParams}`;
};

export const duration = (startDate, endDate) => dayjs(endDate).diff(dayjs(startDate), 'day') + 1;

export const weekDays = (start, end) => {
  const startDate = stringToDate(start);
  const endDate = stringToDate(end);

  const startWeek = startDate.getDay();
  const startOffset = startWeek === 0 ? 0 : startWeek - 1;
  startDate.setDate(startDate.getDate() - startWeek);

  const endWeek = endDate.getDay();
  const endOffset = endWeek === 0 || endWeek === 6 ? 0 : 5 - endWeek;
  endWeek !== 0 && endDate.setDate(endDate.getDate() + (7 - endWeek));
  return Math.floor((endDate - startDate) / ONE_WEEK + 0.5) * 5 - startOffset - endOffset;
};

export const humanize = string =>
  string
    ?.split('_')
    .map(str => humps.pascalize(str))
    .join(' ');

export const checkIfIsFiltered = options =>
  options?.reduce((optionValues, option) => [option.value, ...optionValues], []).includes(true);

export const getOptionsKeys = options => options.filter(({ value }) => value).map(({ key }) => key);

export const getFilterQuery = filterObject =>
  Object.keys(filterObject).reduce((filterQuery, filterName) => {
    if (QUERY_OPTIONS[filterName] && filterObject[filterName]) {
      filterQuery[QUERY_OPTIONS[filterName]] = getOptionsKeys(filterObject[filterName]);
    }
    return filterQuery;
  }, {});

export const initializeOptions = options => options.map(option => ({ ...option, value: false }));

export const initializeOptionsWithFieldName = (options, fieldName, hasIntlOptions) =>
  options.map(option => ({ ...option, value: false, field: fieldName, hasIntlOptions }));

export const initializeOptionsWithSelected = (options, defaults = []) =>
  options.map(option => ({
    ...option,
    selected: Boolean(defaults?.find(({ id }) => id === option.id))
  }));

export const initializeOptionsWithDefault = (options, defaults = []) =>
  options.map(option => ({ ...option, value: defaults.includes(option.key) }));

export const initializeOptionsValues = options =>
  options.map(({ value, key }) => ({
    label: key,
    value: { value },
    key: { key }
  }));

export const initializeTeams = ({ teams, withValues }) =>
  teams.reduce((formattedTeams, { name, ...team }) => {
    const formattedTeam = { ...team, label: name, key: name };

    formattedTeam.value = withValues ? { value: team.id } : false;

    formattedTeams.push(formattedTeam);

    return formattedTeams;
  }, []);

/**
 * @param {string} value String value in rem units
 * @returns {float} Number without 'rem' unit
 * @example remToNumber('10.5rem');
 * // returns 10.5
 */
export const remToNumber = value => parseFloat(value.replace(/rem$/, ''));

/**
 * @param {string[]} values Array of string values in rem units
 * @returns {float[]} Array of numbers without 'rem' unit
 * @example remToNumberArray(['10.5rem', '5rem']);
 * // returns [10.5, 5]
 */
export const remToNumberArray = values => values.map(remToNumber);

/**
 * @param {Array or Object} data Array data or Object to get the data Skills and Scheduled Dates;
 * @example getSkillsAndScheduledDates(project);
 * @return (allSkills\Array) ,(allScheduledDates:Array) return a Object with allSkills and AllScheduledDates
 */
export const getSkillsAndScheduledDates = data => {
  const allSkills = [];
  const allScheduledDates = [];
  if (!Array.isArray(data)) {
    data.projects.map(project => {
      if (project.availability.find(availability => availability.busyPercentage > 0)) {
        const { scheduledDates, skills } = project;
        allSkills.push(skills);
        allScheduledDates.push(scheduledDates);
      }
    });
  } else {
    data.forEach(resource => {
      const { scheduledDates, skills } = resource;
      allSkills.push(skills);
      allScheduledDates.push(scheduledDates);
    });
  }

  return { allSkills: flattenDeep(allSkills), allScheduledDates: flatten(allScheduledDates) };
};

export const getFiltersResourcesQuery = data => {
  const { resources } = data;
  const filterSkills = [];
  const filterSeniority = [];

  const locations = flattenDeep(uniq(resources.map(resource => resource.location.city)));
  const departments = flattenDeep(uniq(resources.map(resource => resource?.department?.name)));

  resources.forEach(({ seniority, experiences }) => {
    seniority && filterSeniority.push(seniority);
    experiences.forEach(experience => filterSkills.push(experience.skill));
  });

  return {
    locations,
    departments,
    skills: compact(uniq(filterSkills)),
    seniority: compact(uniq(filterSeniority))
  };
};

export const getResourcesSkills = data => {
  const { skills, resourcesFiltersOptionsQuery } = data;
  const { skills: resourcesFilteredSkills } = resourcesFiltersOptionsQuery;
  const skillsByResources = flattenDeep(
    resourcesFilteredSkills.map(skill => {
      const skillToReturn = compact(
        skills.map(skillFilter => (skillFilter.name === skill ? skillFilter : null))
      );
      return skillToReturn;
    })
  );

  return skillsByResources;
};

export const filterSkillsByDepartment = (skills, departmentId) =>
  skills.filter(skill => skill.departmentId == departmentId);

export const validateSkillsRequest = skillsFields => {
  const subjectValidation = 'resource.experience.skillsErrorMessage';
  const errors = skillsFields
    .map(element => {
      element.error =
        !element.skill || !element.years || !element.months ? subjectValidation : null;
      return element;
    })
    .filter(element => !isEmpty(element.error));
  return errors.length === 0;
};

/**
 * @param {string} value String value in with months
 * @returns {string} X years, Y months
 * @example monthsToYears('14 months');
 * // returns {years: num, months: num}
 */
export const monthsToYears = value => {
  const totalMonths = parseFloat(value.split(' ')?.[0]);
  return {
    years: Math.floor(totalMonths / 12),
    months: totalMonths % 12
  };
};

export const daysToYears = d => {
  let months = 0;
  let years = 0;
  let days = 0;
  let weeks = 0;

  while (d) {
    if (d >= 365) {
      years += 1;
      d -= 365;
    } else if (d >= 30) {
      months += 1;
      d -= 30;
    } else if (d >= 7) {
      weeks += 1;
      d -= 7;
    } else {
      days += 1;
      d -= 1;
    }
  }

  return {
    years,
    months,
    weeks,
    days
  };
};

export const routeWithProps = (route, props) => {
  let newRoute = route;
  Object.keys(props).forEach(prop => {
    newRoute = newRoute.replace(`:${prop}?`, props[prop]);
    newRoute = newRoute.replace(`:${prop}`, props[prop]);
  });
  return newRoute;
};

export const estimatePercentage = (capacity, newHour) => ((100 * newHour) / capacity).toFixed(0);

export const estimateHours = (capacity, newPercentage) =>
  ((capacity * newPercentage) / 100).toFixed(2);

export const getObjectValueFromString = (obj, str) =>
  str.split('.').reduce((o, key) => (o ? o[key] : null), obj);

export const setSelectInitialValue = (options, field, initialValues, setValues) => {
  if (options) {
    initialValues?.[field] &&
      setValues(prevState => ({
        ...prevState,
        [field]: options?.find(({ value: { id } }) => id === initialValues?.[field])
      }));
  }
};

export const getSelectInitialValue = (options, initialValues, field) =>
  options?.find(({ value: { id } }) => id === initialValues?.[field]) || '';

export const filterById = (targetId, group) =>
  group ? group.filter(({ id }) => id === targetId) : [];

export const accumulator = array => (array ? array.reduce((a, b) => a + b, 0) : 0);

export const parseUrl = url =>
  url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

export const getSkillOptionDepartmentId = (skillOptions, id) => {
  const skill = skillOptions?.find(option => option.value.id === id);
  return skill?.departmentId;
};

export const isMatchingRoute = (path, route) => {
  const pathParts = path.split('/').filter(Boolean);
  const routeParts = route.split('/').filter(Boolean);

  if (pathParts.length !== routeParts.length) {
    return false;
  }

  return routeParts.every((routePart, index) => {
    if (routePart.startsWith(':')) {
      return true;
    }
    return routePart === pathParts[index];
  });
};

export const findMatchingRoute = (path, routes) =>
  Object.values(routes).some(route => isMatchingRoute(path, route));
