import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { BUTTON_VARIANTS } from 'constants/constants';
import routesPaths from 'constants/routesPaths';
import { useRequest } from 'hooks';
import { getDashboardFinancialProjectTypesMargin } from 'state/actions/dashboardActions';

import {
  Column,
  Container,
  Header,
  HeaderColumn,
  QuarterInfo,
  RedirectLinkIcon,
  Row,
  StyledButton,
  TableBody,
  Title
} from './styles';

const currentYear = dayjs().year();

const initialData = {
  projectTypesMargins: [...Array(4)].map((_, index) => ({ quarter: index + 1 }))
};

const MarginByProjectType = () => {
  const intl = useIntl();

  const [{ isPending: isLoading, response: data }] = useRequest(
    getDashboardFinancialProjectTypesMargin,
    { initialData }
  );
  return (
    <Container>
      <Header>
        <Title>
          <h3>{intl.formatMessage({ id: 'common.margin' })}</h3>
          <h4>
            {intl.formatMessage(
              { id: 'template.by' },
              { name: intl.formatMessage({ id: 'common.projectType' }) }
            )}
          </h4>
        </Title>
        <Link to={routesPaths.projectsOverview} style={{ textDecoration: 'none' }} target="_blank">
          <StyledButton
            variant={BUTTON_VARIANTS.NEW_SECONDARY}
            icon={<RedirectLinkIcon />}
            padding="1.2rem 2rem"
          >
            <p>{intl.formatMessage({ id: 'common.projects' })}</p>
          </StyledButton>
        </Link>
      </Header>
      <table>
        <thead>
          <tr>
            <HeaderColumn> </HeaderColumn>
            <HeaderColumn>{intl.formatMessage({ id: 'serviceType.fullProduct' })}</HeaderColumn>
            <HeaderColumn>
              {intl.formatMessage({ id: 'serviceType.staffAugmentation' }).substring(0, 9)}.
            </HeaderColumn>
          </tr>
        </thead>
        <TableBody>
          {data.projectTypesMargins.map(({ quarter, fullProduct, staffAugmentation }) => (
            <Row key={quarter} isGray={quarter % 2 !== 0}>
              <Column>
                <QuarterInfo>
                  <span>Q{quarter}</span>
                  <span>{currentYear}</span>
                </QuarterInfo>
              </Column>
              <Column>
                {isLoading ? (
                  <CenteredLoading maxHeight="2rem" />
                ) : (
                  <p>{Math.round(fullProduct)}%</p>
                )}
              </Column>
              <Column isAddBorderRight>
                {isLoading ? (
                  <CenteredLoading maxHeight="2rem" />
                ) : (
                  <p>{Math.round(staffAugmentation)}%</p>
                )}
              </Column>
            </Row>
          ))}
        </TableBody>
      </table>
    </Container>
  );
};

export default MarginByProjectType;
