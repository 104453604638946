import { useTheme } from 'styled-components';

import { remToNumber } from 'utils/helpers';

export default () => {
  const { constantHeights } = useTheme();
  const heightsArray = Object.entries(constantHeights);

  const heights = heightsArray.reduce(
    (previous, [key, value]) => ({ ...previous, [key]: remToNumber(value) }),
    {}
  );

  return heights;
};
