import styled from 'styled-components';

import { SEVERITY } from 'constants/constants';
import { hexColorAlpha, darkenColor, lightenColor } from 'utils/colorUtilities';

const getColors = ({ theme: { colors }, severity }) => {
  let border;
  let bgColor;
  let color;

  switch (severity) {
    case SEVERITY.success:
      {
        border = colors.darkGreen;
        bgColor = colors.green;
        color = darkenColor(colors.darkGreen, 30);
      }
      break;
    case SEVERITY.info:
      {
        border = colors.seaBlue;
        bgColor = lightenColor(colors.seaBlue, 25);
        color = darkenColor(colors.seaBlue, 30);
      }
      break;
    case SEVERITY.warn:
      {
        border = colors.darkAmber;
        bgColor = colors.amber;
        color = darkenColor(colors.darkAmber, 35);
      }
      break;
    default:
    case SEVERITY.error:
      {
        border = colors.red;
        bgColor = colors.peach;
        color = darkenColor(colors.red, 20);
      }
      break;
  }

  return { border, bgColor: hexColorAlpha(bgColor, 50), color };
};

export const Container = styled.div`
  background-color: ${({ theme, severity }) => getColors({ theme, severity }).bgColor};
  border-radius: 8px;
  border: ${({ theme, severity }) => `1px solid ${getColors({ theme, severity }).border}`};
  color: ${({ theme, severity }) => getColors({ theme, severity }).color};
  display: flex;
  padding: 1rem;
  justify-content: ${({ isCenter }) => isCenter && 'center'};
  width: 100%;
`;

export const Header = styled.div`
  align-items: flex-end;
  display: flex;
`;

export const MessageContainer = styled.div`
  margin-left: 1rem;
`;
