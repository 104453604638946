import { bool, func, string } from 'prop-types';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import { SearchInputContainer, SearchIcon, Input } from './styles';

const SearchInput = ({
  onChange,
  placeholderId,
  value = '',
  variant = 'old',
  id,
  width = '32rem',
  disabled = false,
  className
}) => {
  const intl = useIntl();

  const onChangeSearch = ({ target: { value: resourceName } }) => {
    onChange(resourceName);
  };

  return (
    <SearchInputContainer variant={variant} className={className}>
      <Input
        id={id}
        placeholder={placeholderId && intl.formatMessage({ id: placeholderId })}
        onChange={onChangeSearch}
        value={value}
        variant={variant}
        className={className}
        $width={width}
        disabled={disabled}
      />
      <SearchIcon variant={variant} className={className} />
    </SearchInputContainer>
  );
};

SearchInput.propTypes = {
  value: string,
  onChange: func.isRequired,
  placeholderId: string,
  variant: string,
  id: string,
  width: string,
  disabled: bool,
  className: string
};

export default memo(SearchInput);
