import styled from 'styled-components';

import GrowInfo from 'components/common/GrowInfo';
import LoadingWrapper from 'components/common/LoadingWrapper';

export const Container = styled.div`
  display: flex;
  gap: 1.6rem;
  width: 100%;
`;

export const Block = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(79, 94, 113, 0.12), 0 2px 4px 0 rgba(79, 94, 113, 0.11),
    0 4px 8px 0 rgba(79, 94, 113, 0.1);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 1.6rem;
`;

export const Header = styled.div`
  display: flex;
  padding: 0.4rem 1.6rem;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  background-color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.new.colors.P100 : theme.new.colors.N100_05};

  span {
    ${({ theme }) => theme.new.typography.p6Medium}
    color: ${({ theme, isCurrent }) => (isCurrent ? theme.new.colors.P900 : theme.new.colors.N900)};
  }
`;

export const Row = styled.div`
  display: flex;
  padding: 0 1.6rem;
  align-items: flex-start;
  gap: 1.2rem;
`;

export const QuarterNumber = styled.div`
  ${({ theme }) => theme.new.typography.p6};
  color: ${({ theme }) => theme.new.colors.P400};
  display: flex;
  height: 6.1rem;
  flex-direction: column;
  align-items: flex-start;

  & p {
    ${({ theme }) => theme.new.typography.h3}
    color: ${({ theme }) => theme.new.colors.P800};
    font-size: 3.2rem;
    letter-spacing: -0.048rem;
    line-height: 3.6rem;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;

  span {
    ${({ theme }) => theme.new.typography.p3Bold}
    color: ${({ theme }) => theme.new.colors.N900};
    line-height: 2.4rem;

    &:first-of-type {
      font-weight: ${({ theme }) => theme.fontWeights.normal};
    }
  }
`;

export const GrowRow = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: #f4f6f7b2;
  display: flex;
  gap: 8px;
  padding: 1.6rem;
`;

export const StyledLoadingWrapper = styled(LoadingWrapper)`
  width: 100%;
`;

export const StyledGrowInfo = styled(GrowInfo)`
  span {
    ${({ theme }) => theme.new.typography.p6}
    font-size: 1.2rem;
  }

  svg {
    width: 16px;
    path {
      transform: scale(${16 / 21});
    }
  }
`;

export const VerticalLine = styled.div`
  background-color: ${({ theme }) => theme.new.colors.N300};
  height: 2rem;
  width: 1px;
`;
