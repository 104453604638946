import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
  row-gap: 1.6rem;
`;

export const SwitchContainer = styled.div`
  align-items: center;
  display: flex;

  & > p {
    ${({ theme }) => theme.new.typography.p4};
    color: ${({ theme }) => theme.new.colors.N800};
    margin-right: 1.6rem;
  }
`;

export const ButtonsContainer = styled.div`
  align-items: start;
  display: flex;
  gap: 1.6rem;
  margin-top: 1.6rem;
`;
