import { arrayOf, bool } from 'prop-types';
import { useState } from 'react';

import SearchInput from 'components/common/SearchInput';
import { reportsResourceShape } from 'constants/shapes';
import { filterList } from 'utils/reducersUtilities';

import { Container } from './styles';
import Table from './Table';

const ResourcesTable = ({ resources, isLoading }) => {
  const [search, setSearch] = useState('');
  const filteredResources = () => filterList(resources, search, item => item?.fullName);
  return (
    <Container>
      <SearchInput
        placeholderId="common.cap.searchResource"
        value={search}
        onChange={setSearch}
        variant="new"
      />
      <Table
        resources={filteredResources()?.sort(({ fullName: a }, { fullName: b }) =>
          a < b ? -1 : 1
        )}
        isLoading={isLoading}
      />
    </Container>
  );
};

ResourcesTable.propTypes = {
  resources: arrayOf(reportsResourceShape),
  isLoading: bool
};

export default ResourcesTable;
