import { node, string } from 'prop-types';

import { Container, Content } from './styles';

const ItemDetail = ({ icon, title, children }) => (
  <Container>
    {icon}
    <Content>
      <p>{title}</p>
      <div>{children}</div>
    </Content>
  </Container>
);

ItemDetail.propTypes = {
  icon: node,
  children: node,
  title: string
};

export default ItemDetail;
