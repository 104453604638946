import styled from 'styled-components';

export const ProjectInformation = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  justify-content: space-between;
  margin-top: 2.4rem;
  overflow: hidden;
  padding-right: 2rem;
  width: 100%;
`;

export const NotesContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.new.colors.N300};
  margin-top: 0.8rem;
  padding-top: 0.8rem;
  width: 100%;

  & p {
    ${({ theme }) => theme.new.typography.p5Bold};
    line-height: 2.1rem;

    & span {
      ${({ theme }) => theme.new.typography.p4};
      line-height: 2.4rem;
      margin-left: 1.2rem;
    }
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: flex;
  height: 6.4rem;
  justify-content: space-between;
  margin-bottom: 1.9rem;
  width: 100%;

  && > button {
    ${({ theme }) => theme.new.typography.p4SemiBold};

    & > svg > path {
      stroke: ${({ theme }) => theme.new.colors.white};
      stroke-width: 1.67px;
    }
  }
`;

export const Title = styled.h2`
  ${({ theme }) => theme.new.typography.h3};
  padding-top: 1rem;
`;
