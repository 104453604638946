import { bool, number } from 'prop-types';
import { useIntl } from 'react-intl';

import BadgePill from 'components/common/BadgePill';
import theme from 'constants/theme';
import { getMarginColor, getMarginColorBorder } from 'utils/financialColors';

import { MatchSection, InfoItem, MatchOverall } from './styles';

const MatchInfo = ({ matchOverall, margin, displayMargin }) => {
  const intl = useIntl();

  return (
    <MatchSection>
      <InfoItem>
        <span>{intl.formatMessage({ id: 'searchPeople.bestMatches.matchOverall' })}</span>
        <MatchOverall>{matchOverall}%</MatchOverall>
      </InfoItem>
      <InfoItem>
        <span>{intl.formatMessage({ id: 'common.margin' })}</span>

        {displayMargin ? (
          <BadgePill
            borderColor={getMarginColorBorder({ theme, margin })}
            fontColor={getMarginColor({ theme, margin })}
          >
            {margin}%
          </BadgePill>
        ) : (
          '--'
        )}
      </InfoItem>
    </MatchSection>
  );
};

MatchInfo.propTypes = {
  matchOverall: number.isRequired,
  margin: number.isRequired,
  displayMargin: bool
};

export default MatchInfo;
