export const ROLES = {
  operations: 'operations',
  projectManager: 'project_manager',
  sales: 'sales',
  manager: 'manager',
  deliveryOwner: 'delivery_owner',
  leader: 'leader',
  leadership: 'leadership',
  resourceManager: 'resource_manager',
  people: 'people_ta',
  finance: 'finance',
  basic: 'basic'
};

export const SCOPES = {
  // Projects
  createProjects: 'create-projects',
  seeProjectDetails: 'see-project-details',
  editProjects: 'edit-projects',
  editProjectsNotes: 'edit-projects-notes',
  editProjectStatus: 'edit-project-status',
  createAssignment: 'create-assignment',
  archiveProjects: 'archive-projects',
  deleteProjects: 'delete-projects',
  addProjectsStatus: 'add-projects-status',
  confirmAssignment: 'confirm-assignment',
  searchPeople: 'search-people',
  editAssignment: 'edit-assignment',
  editAssignmentBasic: 'edit-assignment-basic',
  deleteProjectsResources: 'delete-projects-resources',
  deleteProjectsSkills: 'delete-projects-skills',
  selectAssignment: 'select-assignment',
  // Resources
  createResources: 'create-resources',
  addProjectsResources: 'add-projects-resources',
  seeProfileDetail: 'see-profile-detail',
  seeProfileModal: 'see-profile-modal-detail',
  // Financial
  editFinancialInfo: 'edit-financial-info',
  updateResourcesSetup: 'update-resources-setup',
  editResourcesSetup: 'edit-resources-setup',
  createOpenPeriod: 'create-open-period',
  editOpenPeriods: 'edit-open-periods',
  editOpenPeriodsInfo: 'edit-open-periods-info',
  closeOpenPeriods: 'close-open-periods',
  deleteOpenPeriods: 'delete-open-periods',
  editClosePeriods: 'edit-close-periods',
  editClosePeriodsInfo: 'edit-close-periods-info',
  deleteClosePeriods: 'delete-close-periods',
  manageGlobalDiscount: 'manage-global-discount',
  // Dashboard
  seeDashboard: 'see-dashboard'
};

export const PERMISSIONS = {
  [ROLES.operations]: [
    SCOPES.createProjects,
    SCOPES.seeProjectDetails,
    SCOPES.editProjects,
    SCOPES.editProjectStatus,
    SCOPES.createAssignment,
    SCOPES.archiveProjects,
    SCOPES.deleteProjects,
    SCOPES.addProjectsStatus,
    SCOPES.confirmAssignment,
    SCOPES.searchPeople,
    SCOPES.editAssignment,
    SCOPES.deleteProjectsResources,
    SCOPES.deleteProjectsSkills,
    SCOPES.selectAssignment,
    SCOPES.createResources,
    SCOPES.addProjectsResources,
    SCOPES.seeProfileDetail,
    SCOPES.editFinancialInfo,
    SCOPES.updateResourcesSetup,
    SCOPES.editResourcesSetup,
    SCOPES.createOpenPeriod,
    SCOPES.editOpenPeriods,
    SCOPES.editOpenPeriodsInfo,
    SCOPES.closeOpenPeriods,
    SCOPES.deleteOpenPeriods,
    SCOPES.editClosePeriods,
    SCOPES.editClosePeriodsInfo,
    SCOPES.deleteClosePeriods,
    SCOPES.manageGlobalDiscount,
    SCOPES.seeDashboard,
    SCOPES.seeProfileModal
  ],
  [ROLES.resourceManager]: [
    SCOPES.createProjects,
    SCOPES.seeProjectDetails,
    SCOPES.editProjects,
    SCOPES.editProjectStatus,
    SCOPES.createAssignment,
    SCOPES.archiveProjects,
    SCOPES.deleteProjects,
    SCOPES.addProjectsStatus,
    SCOPES.confirmAssignment,
    SCOPES.searchPeople,
    SCOPES.editAssignment,
    SCOPES.deleteProjectsResources,
    SCOPES.deleteProjectsSkills,
    SCOPES.selectAssignment,
    SCOPES.createResources,
    SCOPES.addProjectsResources,
    SCOPES.seeProfileDetail,
    SCOPES.seeProfileModal
  ],
  [ROLES.projectManager]: [
    SCOPES.seeProjectDetails,
    SCOPES.editProjectsNotes,
    SCOPES.createAssignment,
    SCOPES.editAssignmentBasic,
    SCOPES.editFinancialInfo,
    SCOPES.updateResourcesSetup,
    SCOPES.editResourcesSetup,
    SCOPES.createOpenPeriod,
    SCOPES.editOpenPeriods,
    SCOPES.editOpenPeriodsInfo,
    SCOPES.closeOpenPeriods,
    SCOPES.deleteOpenPeriods,
    SCOPES.editClosePeriods,
    SCOPES.editClosePeriodsInfo,
    SCOPES.deleteClosePeriods,
    SCOPES.manageGlobalDiscount,
    SCOPES.seeProfileDetail
  ],
  [ROLES.deliveryOwner]: [
    SCOPES.seeProjectDetails,
    SCOPES.editProjectsNotes,
    SCOPES.createAssignment,
    SCOPES.editAssignmentBasic,
    SCOPES.seeProfileDetail,
    SCOPES.editFinancialInfo,
    SCOPES.updateResourcesSetup,
    SCOPES.editResourcesSetup,
    SCOPES.createOpenPeriod,
    SCOPES.editOpenPeriods,
    SCOPES.editOpenPeriodsInfo,
    SCOPES.closeOpenPeriods,
    SCOPES.deleteOpenPeriods,
    SCOPES.editClosePeriods,
    SCOPES.editClosePeriodsInfo,
    SCOPES.deleteClosePeriods,
    SCOPES.manageGlobalDiscount,
    SCOPES.seeProfileModal
  ],
  [ROLES.leadership]: [
    SCOPES.seeProjectDetails,
    SCOPES.searchPeople,
    SCOPES.seeProfileDetail,
    SCOPES.seeProfileModal
  ],
  [ROLES.manager]: [
    SCOPES.seeProjectDetails,
    SCOPES.searchPeople,
    SCOPES.seeProfileDetail,
    SCOPES.seeDashboard,
    SCOPES.seeProfileModal
  ],
  [ROLES.leader]: [SCOPES.seeProfileDetail, SCOPES.seeProfileModal],
  [ROLES.people]: [SCOPES.seeProfileDetail, SCOPES.seeProfileModal],
  [ROLES.sales]: [SCOPES.seeProjectDetails, SCOPES.seeProfileModal]
};
