import { arrayOf, bool, func } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import CloseIcon from 'assets/icons/close.svg';
import Button from 'components/common/Button';
import EmptyState from 'components/common/EmptyState';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import RoundAvatar from 'components/common/RoundAvatar';
import Cell from 'components/ProjectsOverview/ProjectsTable/Cell';
import { BUTTON_VARIANTS, ROLE_MODAL_TYPES, SETTINGS_FILTERS, SORT_DIR } from 'constants/constants';
import { reportsResourceShape, sortableTableColumnShape } from 'constants/shapes';
import { useDispatch, useSession } from 'hooks';
import { deleteResourceRole } from 'state/actions/settingsActions';
import { humanize } from 'utils/helpers';
import { sortTable } from 'utils/projectsOverviewUtilities';

import {
  ActionsWrapper,
  ArrowIcon,
  BasicInfo,
  BasicInfoContent,
  Column,
  HeaderColumnContainer,
  RolePill,
  Row,
  StyledCloseIcon,
  Table,
  TableWrapper,
  TemporalRoleWrapper
} from './styles';

const RoleTable = ({ resources = [], columns, isLoading, setModalOpen, setResource }) => {
  const intl = useIntl();
  const { user } = useSession();
  const deleteRequest = useDispatch(deleteResourceRole);
  const [sortConfig, setSortConfig] = useState({
    key: 'assignments',
    direction: SORT_DIR.desc
  });

  const onSort = ({ key: columnKey }) => {
    let direction = SORT_DIR.desc;
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === SORT_DIR.desc) {
      direction = SORT_DIR.asc;
    }
    setSortConfig({
      key: columnKey,
      direction
    });
  };

  const sortedResources = useMemo(() => {
    if (!sortConfig) return resources;
    const sortedRows = sortTable({
      columns,
      sortConfig,
      rows: resources,
      sortType: SETTINGS_FILTERS.roles
    });
    return sortedRows;
  }, [columns, resources, sortConfig]);

  const handleActions = (resource, modalType) => {
    setResource({
      name: resource?.fullName,
      id: resource.personId,
      temporalGroups: resource.temporalGroups
    });

    setModalOpen(modalType);
  };

  const handleTableContent = () => {
    if (isLoading) {
      return null;
    }
    if (sortedResources.length > 0) {
      return sortedResources?.map(resource => {
        const resourceId = resource?.personId;

        return (
          <Row key={resourceId}>
            <Cell
              value={
                <BasicInfo>
                  <RoundAvatar squareSize={4} src={resource?.avatarUrl} />
                  <BasicInfoContent>
                    <h3>{resource?.fullName || '--'}</h3>
                    <p>{resource?.role?.name}</p>
                  </BasicInfoContent>
                </BasicInfo>
              }
              columnKey="resourceName"
              key={`user-${uuid()}`}
            />

            <Cell
              value={<RolePill>{humanize(resource?.group) || '--'}</RolePill>}
              columnKey="role"
              key={`role-${uuid()}`}
            />
            <Cell
              value={
                <TemporalRoleWrapper>
                  {resource?.temporalGroups?.length
                    ? resource?.temporalGroups.map(temporaryRole => (
                        <RolePill key={`${temporaryRole?.id}-${uuid()}`}>
                          <span>{humanize(temporaryRole?.group) || '--'}</span>
                          <StyledCloseIcon
                            src={CloseIcon}
                            onClick={() =>
                              deleteRequest({
                                id: temporaryRole?.id,
                                userId: user.personId,
                                resourceId
                              })
                            }
                          />
                        </RolePill>
                      ))
                    : '-'}
                </TemporalRoleWrapper>
              }
              columnKey="temporaryRoles"
              key={`temporaryRole-${uuid()}`}
            />

            <Cell
              value={
                <ActionsWrapper>
                  <Button
                    variant={BUTTON_VARIANTS.NEW_SECONDARY}
                    onClick={() => handleActions(resource, ROLE_MODAL_TYPES.ADD)}
                  >
                    {intl.formatMessage({ id: 'settings.table.actions.addRole' })}
                  </Button>
                  {/* Commenting manage role button until dates are incorporated again */}
                  {/* {resource?.temporalGroups.length ? (
                    <Button
                      variant={BUTTON_VARIANTS.NEW_SECONDARY}
                      onClick={() => handleActions(resource, ROLE_MODAL_TYPES.MANAGE)}
                    >
                      {intl.formatMessage({ id: 'settings.table.actions.manageRoles' })}
                    </Button>
                  ) : null} */}
                </ActionsWrapper>
              }
              columnKey="actions"
              key={`actions-${uuid()}`}
            />
          </Row>
        );
      });
    }
    return (
      <tr>
        <td colSpan={7}>
          <EmptyState
            className="accessRoles"
            titleIntlId="emptyState.title.resources"
            subtitleIntlId="emptyState.subtitle.resources"
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      <TableWrapper>
        <Table>
          <thead>
            <Row height="5.7rem" isHeader>
              {columns?.map(column => {
                const isSorting = sortConfig && sortConfig.key === column.key;
                return (
                  <th key={column.key}>
                    <HeaderColumnContainer
                      onClick={() => !isLoading && onSort(column)}
                      $disabled={isLoading}
                    >
                      <span>{column.header && intl.formatMessage({ id: column.header })}</span>
                      <Column width="1.2rem">
                        <ArrowIcon
                          direction={isSorting ? sortConfig.direction : SORT_DIR.desc}
                          $isSorting={!isLoading && isSorting}
                          disabled={isLoading}
                        />
                      </Column>
                    </HeaderColumnContainer>
                  </th>
                );
              })}
              <th>
                <HeaderColumnContainer $disabled={isLoading}>
                  <span>{intl.formatMessage({ id: 'settings.table.actions' })}</span>
                </HeaderColumnContainer>
              </th>
              <td />
            </Row>
          </thead>
          <tbody>{handleTableContent(isLoading)}</tbody>
        </Table>
      </TableWrapper>
      {isLoading && <CenteredLoading />}
    </>
  );
};

RoleTable.propTypes = {
  columns: arrayOf(sortableTableColumnShape),
  isLoading: bool,
  resources: arrayOf(reportsResourceShape),
  setModalOpen: func,
  setResource: func
};
export default RoleTable;
