import styled from 'styled-components';

export const CardContainer = styled.div`
  background: ${({ variant, theme }) =>
    variant === 'primary' ? theme.new.colors.white : theme.new.gradients.purpleHaze};
  border-radius: 0.6rem;
  border: ${({ theme }) => `1px solid ${theme.new.colors.N300}`};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 38.3rem;
  justify-content: ${({ variant }) => (variant === 'primary' ? 'space-around' : 'flex-start')};
  min-width: 22rem;
  padding: 1.5rem;
`;
