import { arrayOf, bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { Container, Tab } from './styles';

const CustomTab = ({ activeTab, setActiveTab, tabTextIds, disabled }) => {
  const intl = useIntl();
  const firstTab = intl.formatMessage({ id: tabTextIds[0] });
  const secondTab = intl.formatMessage({ id: tabTextIds[1] });

  return (
    <Container>
      <Tab
        type="button"
        active={activeTab === firstTab}
        onClick={() => setActiveTab(firstTab)}
        disabled={disabled}
      >
        {firstTab}
      </Tab>
      <Tab
        type="button"
        active={activeTab === secondTab}
        onClick={() => setActiveTab(secondTab)}
        disabled={disabled}
      >
        {secondTab}
      </Tab>
    </Container>
  );
};

CustomTab.propTypes = {
  activeTab: string,
  setActiveTab: func,
  tabTextIds: arrayOf(string),
  disabled: bool
};

export default CustomTab;
