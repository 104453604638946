import { string } from 'prop-types';

import { LegendItemContainer } from './styles';

const LegendItem = ({ name }) => <LegendItemContainer>{name}</LegendItemContainer>;

LegendItem.propTypes = {
  name: string
};

export default LegendItem;
