import { func, object, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from 'components/common/Button';
import PermissionsGate from 'components/PermissionGate';
import ProjectTopOverview from 'components/ProjectTopOverview';
import AddAssignmentModal from 'components/timeline/AddAssignmentModal';
import ProjectModal from 'components/timeline/ProjectModal';
import { BUTTON_VARIANTS } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { useDispatch, useRequest } from 'hooks';
import { addAssignment } from 'state/actions/assignmentActions';
import { getMinimalResources } from 'state/actions/peopleActions';
import { getProjectsOverviewTotals } from 'state/actions/projectActions';

import { TitleContainer, VerticalLine, ButtonsContainer, Title, StyleUserPlusIcon } from './styles';

function ProjectHeader({ titleId, handleRefresh, setAddAssignmentTo, addAssignmentTo }) {
  const intl = useIntl();
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);

  const getMinimalResourcesRequest = useDispatch(getMinimalResources);
  const [
    { isPending: isLoadingProjectOverviewTotals, response: projectOverviewTotals },
    getProjectsOverviewTotalsRequest
  ] = useRequest(getProjectsOverviewTotals, {});

  useEffect(() => {
    getMinimalResourcesRequest({ userGroup: 'sales' });
    getMinimalResourcesRequest({ userGroup: 'manager' });
    getMinimalResourcesRequest({ userGroup: 'delivery_owner' });
  }, [getMinimalResourcesRequest]);

  const refreshInfo = () => {
    getProjectsOverviewTotalsRequest();
    handleRefresh?.();
  };

  return (
    <>
      <TitleContainer>
        <Title>
          <h1>{intl.formatMessage({ id: 'common.projects' })}</h1>
          <VerticalLine />
          <h1>{intl.formatMessage({ id: `${titleId}` })}</h1>
        </Title>
        <ButtonsContainer>
          <PermissionsGate scopes={[SCOPES.createProjects]}>
            <Button
              id="createProjectBtn"
              variant={BUTTON_VARIANTS.NEW_PRIMARY}
              textIntlId="common.newProject"
              icon={<StyleUserPlusIcon />}
              onClick={() => setShowNewProjectModal(true)}
              fullWidth
            />
          </PermissionsGate>
        </ButtonsContainer>
      </TitleContainer>
      <ProjectTopOverview
        projects={projectOverviewTotals?.projects}
        assignments={projectOverviewTotals?.assignments}
        isLoading={isLoadingProjectOverviewTotals}
      />
      {showNewProjectModal && (
        <ProjectModal
          isShowing={showNewProjectModal}
          hide={() => setShowNewProjectModal(false)}
          setAddAssignmentTo={setAddAssignmentTo}
          handleRefresh={refreshInfo}
        />
      )}
      {addAssignmentTo && (
        <AddAssignmentModal
          isShowing={!!addAssignmentTo}
          hide={() => setAddAssignmentTo(null)}
          initialValues={addAssignmentTo.initialValues}
          projectId={addAssignmentTo.project?.id}
          isNewProject
          showCopy
          handleRefresh={refreshInfo}
          addAssignmentAction={addAssignment}
        />
      )}
    </>
  );
}

ProjectHeader.propTypes = {
  titleId: string,
  handleRefresh: func,
  setAddAssignmentTo: func,
  addAssignmentTo: object
};

export default ProjectHeader;
