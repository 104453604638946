import styled, { css } from 'styled-components';

import { remToNumber } from 'utils/helpers';

const nestedLeftMargin = 2.9;

const calcWidth = ({ theme, width: widthOverride, isNested }) => {
  const timelineHeaderWidthNumber = remToNumber(theme.constantWidths.timelineHeader);

  let width = timelineHeaderWidthNumber;

  if (widthOverride) width = widthOverride;
  if (isNested) width -= nestedLeftMargin;

  return width;
};

export const TimelineRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimelineRowHeader = styled.div`
  ${({ isAddBorders, isAddBorderBottom }) =>
    isAddBorders &&
    css`
      border: ${({ theme }) => `0.1px solid ${theme.colors.athensGray}`};
      border-right: none;
      ${!isAddBorderBottom && 'border-bottom: none;'}
    `}
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  display: flex;
  width: ${props => `${calcWidth(props)}rem`};
  margin-left: 0;
  padding-left: 0;
  ${({ isCenter }) => isCenter && 'justify-content:center;'};
  ${({ theme, isNested, paddingLeft }) =>
    isNested &&
    css`
      background-color: ${theme.colors.gray120};
      margin-left: 0;
      padding-left: ${paddingLeft || '3.9rem'};
      width: 28.5rem;
    `};
`;

export const TimelineRow = styled.div`
  border-top: ${({ isExpanded, theme }) =>
    `0.7px solid ${isExpanded ? theme.colors.expandedTimelineRow : theme.colors.athensGray}`};
  ${({ isWithoutBorderLeft }) =>
    !isWithoutBorderLeft &&
    css`
      border-left: ${({ theme }) => `0.7px solid ${theme.colors.athensGray}`};
    `};
  display: flex;
  min-height: 3.5rem;
`;

export const TimelineRowHeaderContent = styled.div`
  cursor: ${({ onClick }) => onClick && 'pointer'};
  width: ${({ width, isNested }) =>
    width ? `${isNested ? width - nestedLeftMargin : width}rem` : 'auto'};
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

export const TimelineRowHeaderTitle = styled.p`
  color: ${props => props.theme.colors.gray100};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin: 0;
  width: 18rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TimelineRowHeaderSubtitle = styled.p`
  width: 18rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.gray80};
  font-size: 1.3rem;
  font-weight: ${props => props.theme.fontWeights.normal};
  margin: 0;
`;

export const TimelineRowHeaderSubtitleWarning = styled(TimelineRowHeaderSubtitle)`
  color: ${props => props.theme.colors.red};
`;

export const TimelineRowContent = styled.div`
  display: flex;
  margin: auto;
  flex: 1;
  height: ${({ height }) => (height ? `${height}rem` : '3.7rem')};
  position: relative;
  padding: ${({ padding }) => padding || '0.5rem 0'};
`;

export const TimelineRowContentAbsolut = styled.div`
  display: flex;
  margin: auto;
  flex: 1;
  height: ${({ height }) => (height ? `${height - 0.5}rem` : '3.7rem')};
  position: absolute;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '0.5rem'};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.generalAvailability + 1};
  pointer-events: none;
`;
