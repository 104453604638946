import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ variant, theme }) =>
    variant === 'primary' ? theme.new.colors.N900 : theme.new.colors.white};
  display: flex;
  flex-direction: column;

  & h4 {
    ${({ theme }) => theme.new.typography.p5SemiBold};
  }

  & div {
    ${({ theme }) => theme.new.typography.p5};
  }
`;
