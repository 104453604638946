import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 1.3rem;
  width: 100%;
`;

export const Section = styled.div`
  align-items: center;
  display: flex;
  gap: 1.2rem;
  overflow: hidden;

  & > div {
    align-items: start;
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${({ billable }) => (!billable ? '16.4rem' : '13rem')};
  }

  & div:nth-child(1) {
    ${({ theme }) => theme.new.typography.p5SemiBold};
    color: ${({ theme, disabled }) =>
      disabled ? theme.new.colors.N500 : theme.new.colors.neutral800};
    cursor: pointer;
  }

  & div:nth-child(2) {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme, disabled }) =>
      disabled ? theme.new.colors.N500 : theme.new.colors.neutral700};
  }
`;
