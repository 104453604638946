import { bool } from 'prop-types';
import { useIntl } from 'react-intl';

import MuiTooltip from 'components/common/MuiTooltip';
import { ALLOCATION_TIMELINE_TYPES } from 'constants/constants';
import { DISPLAY_MONTH_DAY_YEAR } from 'constants/dateFormats';
import { allocationTimelineBarShape } from 'constants/shapes';
import { dateToString } from 'utils/date';

import { StyleBar, Title, Description } from './styles';

function Bar({ item, isFirst, isLast, bigBar, totalDays }) {
  const intl = useIntl();
  const { allocationType, start, end, startEvent, endEvent, isHireDate, isExitDate } = item;
  const { color, label } = allocationType;

  const vacationsOrSpecialEvents = [
    ALLOCATION_TIMELINE_TYPES.VACATIONS,
    ALLOCATION_TIMELINE_TYPES.TRAINING,
    ALLOCATION_TIMELINE_TYPES.CONVERSION,
    ALLOCATION_TIMELINE_TYPES.HIRE_AND_EXIT_DATES
  ];
  return (
    <StyleBar
      color={color}
      start={start}
      column={(end - start) + 1}
      isFirst={isFirst}
      isLast={isLast}
      isVacationOrSpecial={vacationsOrSpecialEvents.includes(allocationType)}
      bigBar={bigBar}
      totalDays={totalDays}
    >
      <MuiTooltip>
        {isHireDate || isExitDate ? (
          <>
            <Title>
              {intl.formatMessage({ id: isHireDate ? 'timeline.hireDate' : 'timeline.exitDate' })}
            </Title>
            <Description>
              {dateToString(isHireDate ? endEvent : startEvent, DISPLAY_MONTH_DAY_YEAR)}
            </Description>
          </>
        ) : (
          <>
            <Title>
              {intl.formatMessage({ id: label })}{' '}
              {item?.allocationPercentage >= 0 && (
                <Description>({item?.allocationPercentage}% of availability)</Description>
              )}
            </Title>
            <Description>
              {`${intl.formatMessage({ id: 'common.from' })} ${dateToString(
                startEvent,
                DISPLAY_MONTH_DAY_YEAR
              )} ${intl.formatMessage({ id: 'common.lower.to' })} ${dateToString(
                endEvent,
                DISPLAY_MONTH_DAY_YEAR
              )}
      `}
            </Description>
          </>
        )}
      </MuiTooltip>
    </StyleBar>
  );
}

Bar.propTypes = {
  item: allocationTimelineBarShape,
  isFirst: bool,
  isLast: bool,
  bigBar: bool
};

export default Bar;
