import dayjs from 'dayjs';
import { arrayOf, bool } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { SORT_DIR } from 'constants/constants';
import { DISPLAY_FULL_MONTH } from 'constants/dateFormats';
import { dashboardFinancialOverview } from 'constants/shapes';
import { getMarginColor, getMarginColorBorder } from 'utils/financialColors';
import { toCurrency } from 'utils/number';
import { sortTable } from 'utils/projectsOverviewUtilities';

import {
  ArrowIcon,
  Column,
  Container,
  HeaderColumnContainer,
  Row,
  StyledBadgePill,
  Table
} from './styles';

const greaterFunc = (a, b) => a > b;

const lowerFunc = (a, b) => a < b;

const columnsKeys = {
  month: 'month',
  revenue: 'revenue',
  costs: 'costs',
  margin: 'margin'
};

const columns = Object.values(columnsKeys).map(columnKey => ({
  key: columnKey,
  header: `insights.financial.monthlyBreakdown.table.columns.${columnKey}`,
  greaterFunc,
  lowerFunc
}));

const formatNumber = number =>
  toCurrency(number, {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
const MonthlyBreakdownTable = ({ rows = [], isExpanded }) => {
  const intl = useIntl();
  const theme = useTheme();
  const [sortConfig, setSortConfig] = useState({
    key: 'month',
    direction: SORT_DIR.asc
  });

  const onSort = ({ key: columnKey }) => {
    let direction = SORT_DIR.desc;
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === SORT_DIR.desc) {
      direction = SORT_DIR.asc;
    }
    setSortConfig({
      key: columnKey,
      direction
    });
  };
  const sortedRows = useMemo(() => {
    if (!sortConfig) return rows;
    return sortTable({ rows, sortConfig, columns });
  }, [rows, sortConfig]);

  return (
    <Container $isExpanded={isExpanded}>
      <Table>
        <thead>
          <Row isHeader>
            {columns?.map(column => {
              const isSorting = sortConfig && sortConfig.key === column.key;
              return (
                <th key={column.key} align="center">
                  <HeaderColumnContainer
                    onClick={() => onSort(column)}
                    $isMonthColumn={column.key === columnsKeys.month}
                  >
                    <span>{column.header && intl.formatMessage({ id: column.header })}</span>
                    <Column width="1.2rem" $isHeader>
                      <ArrowIcon
                        direction={isSorting ? sortConfig.direction : SORT_DIR.desc}
                        $isSorting={isSorting}
                      />
                    </Column>
                  </HeaderColumnContainer>
                </th>
              );
            })}
            <td />
          </Row>
        </thead>
        <tbody>
          {sortedRows?.map((item, index) => (
            <Row key={index} isGray={index % 2 === 0}>
              {Object.values(columns).map(({ key }) => {
                const value = item[key];
                return {
                  [columnsKeys.month]: (
                    <Column key={`${index}-${key}`} $isMonthColumn>
                      {dayjs()
                        .month(value - 1)
                        .format(DISPLAY_FULL_MONTH)}
                    </Column>
                  ),
                  [columnsKeys.revenue]: (
                    <Column key={`${index}-${key}`}>{formatNumber(value)}</Column>
                  ),
                  [columnsKeys.costs]: (
                    <Column key={`${index}-${key}`}>{formatNumber(value)}</Column>
                  ),
                  [columnsKeys.margin]: (
                    <Column key={`${index}-${key}`}>
                      <StyledBadgePill
                        fontColor={getMarginColor({ theme, margin: value })}
                        borderColor={getMarginColorBorder({ theme, margin: value })}
                      >
                        {`${Math.round(value)}%`}
                      </StyledBadgePill>
                    </Column>
                  )
                }[key];
              })}
            </Row>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

MonthlyBreakdownTable.propTypes = {
  rows: arrayOf(dashboardFinancialOverview),
  isExpanded: bool
};
export default MonthlyBreakdownTable;
