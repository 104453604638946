import ForecastingServices from 'services/forecastingServices';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const getProjectsTotals = createAsyncThunk('GET_PROJECTS_TOTALS', async () => {
  try {
    const { data } = await ForecastingServices.getProjectsTotals();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getProjects = createAsyncThunk('GET_PROJECTS', async ({ forecastedMonthsAhead }) => {
  try {
    const { data } = await ForecastingServices.getProjects(forecastedMonthsAhead);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getGraph = createAsyncThunk('GET_GRAPH', async () => {
  try {
    const { data } = await ForecastingServices.getGraph();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});
