import styled from 'styled-components';

import Button from 'components/common/Button';
import NewModal from 'components/common/NewModal';
import { ContentWrapper } from 'components/common/NewModal/styles';

export const ButtonsRow = styled.div`
  display: flex;
  gap: 1.2rem;
  justify-content: ${({ isResourceEqual }) => (isResourceEqual ? 'center' : 'space-between')};
  margin-top: 2rem;
  margin-top: auto;
  padding-top: 1.5rem;
  width: 100%;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

export const Title = styled.div`
  ${({ theme }) => theme.new.typography.p4};
  color: ${({ theme }) => theme.new.colors.neutral800};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'space-between')};
  min-height: 23.7rem;
  padding: ${({ isResourceEqual }) => isResourceEqual && '3rem 0 0.7rem 0'};
  gap: 1.6rem;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  ${({ isResourceEqual }) => isResourceEqual && 'max-width:29.4rem;'}
`;

export const StyledNewModal = styled(NewModal)`
  ${ContentWrapper} {
    display: flex;
    flex-direction: column;
    max-width: 88rem;
  }
`;
