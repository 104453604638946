import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
  width: 25.6rem;
`;

export const Info = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.N600};
  button:hover {
    text-decoration: underline;
  }
  p:first-child {
    ${({ theme }) => theme.new.typography.p5SemiBold};
    color: ${({ theme }) => theme.new.colors.N800};
  }
`;
