import { bool, number, string } from 'prop-types';

import { humanize } from 'utils/helpers';

import { Label, Text } from './styles';

const BarLabel = ({ x, y, name, humanizeName }) => (
  <Label x={x} y={y - 28}>
    <Text>{humanizeName ? humanize(name) : name}</Text>
  </Label>
);

BarLabel.propTypes = {
  name: string,
  humanizeName: bool,
  x: number,
  y: number
};

export default BarLabel;
