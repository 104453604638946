import { useState } from 'react';
import { useIntl } from 'react-intl';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { useRequest } from 'hooks';
import { getDashboardSkills } from 'state/actions/dashboardActions';

import SkillsBarGraph from './SkillsBarGraph';
import { Container, StyledTabButtons, Title } from './styles';

const TABS = [
  { id: 'top', intlId: 'template.topAmount', intlVar: { amount: 5 } },
  { id: 'worst', intlId: 'template.bottomAmount', intlVar: { amount: 5 } }
];

const UtilizationBySkill = () => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState('top');

  const [{ isPending: isSkillsPending, response: skills }] = useRequest(getDashboardSkills);

  const totalFTE = skills?.totalFte;

  return (
    <Container>
      <Title>
        <h3>{intl.formatMessage({ id: 'common.cap.utilization' })}</h3>
        <h4>{intl.formatMessage({ id: 'insights.utilization.bySkill.title' })}</h4>
      </Title>
      <StyledTabButtons
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        tabs={TABS}
        width="100%"
      />
      {isSkillsPending ? (
        <CenteredLoading />
      ) : (
        <SkillsBarGraph
          data={skills?.[activeTab]}
          totalFTE={totalFTE}
          step={totalFTE > 200 ? 50 : 20}
        />
      )}
    </Container>
  );
};

export default UtilizationBySkill;
