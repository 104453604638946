import FormControlLabel from '@mui/material/FormControlLabel';
import { func } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from 'components/common/Button';
import FilterDropdown from 'components/common/FilterDropdown';
import { FULFILLED } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS, SENIORITY_OPTIONS } from 'constants/constants';
import { useSkills, useTeams } from 'hooks';
import { filtersToParamsPeople } from 'utils/dashboardUtilities';
import * as Dates from 'utils/date';
import { initializeOptions, initializeTeams } from 'utils/helpers';

import DateSelector from '../../DateSelector';
import { CustomSwitch, FiltersContainer, Selectors, SwitchLabel } from './styles';

const month = Dates.getFirstAndLastDayOfCurrentMonth(Dates.dayJSToDate);

const initialFilters = {
  startDate: month.startDate,
  endDate: month.endDate,
  skills: [],
  departments: [],
  seniorities: [],
  highlighted: false,
  name: ''
};

const DashboardFilters = ({ onClear, onRequest }) => {
  const intl = useIntl();
  const [filters, setFilters] = useState(initialFilters);
  const [clearDateFilters, setClearDateFilters] = useState(false);

  const { skills, skillsStatus } = useSkills();
  const { teams, teamsStatus } = useTeams();

  const handleFilter = ({ options, key }) => {
    setFilters(prevState => ({ ...prevState, [key]: options }));
  };

  const handleDates = ({ startDate, endDate }) => {
    setFilters(prevState => ({
      ...prevState,
      startDate,
      endDate
    }));
  };

  const handleHighlighted = event => {
    setFilters(prevState => ({ ...prevState, highlighted: event.target.checked }));
  };

  const handleApplyFilters = () => {
    onRequest(filtersToParamsPeople(filters));
  };

  const handleClearFilters = () => {
    setFilters({
      ...initialFilters,
      skills: initializeOptions(skills.map(({ name, id }) => ({ key: name, label: name, id }))),
      departments: initializeTeams({
        teams,
        withValues: false
      }),
      seniorities: initializeOptions(SENIORITY_OPTIONS)
    });
    setClearDateFilters(true);
    onClear();
  };

  useEffect(() => {
    if ([skillsStatus, teamsStatus].every(status => status === FULFILLED)) {
      setFilters(prevState => ({
        ...prevState,
        skills: initializeOptions(skills.map(({ name, id }) => ({ key: name, label: name, id }))),
        departments: initializeTeams({
          teams,
          withValues: false
        }),
        seniorities: initializeOptions(SENIORITY_OPTIONS)
      }));
    }
  }, [skillsStatus, teamsStatus, skills, teams, setFilters]);

  return (
    <FiltersContainer>
      <DateSelector
        startDate={filters.startDate}
        endDate={filters.endDate}
        onChange={handleDates}
        clearDateFilters={clearDateFilters}
        setClearDateFilters={setClearDateFilters}
      />
      <Selectors>
        <FilterDropdown
          labelId="common.department"
          options={filters.departments}
          onFilter={handleFilter}
          queryKey="departments"
          withIntlOptions={false}
        />
        <FilterDropdown
          labelId="common.skills"
          options={filters.skills}
          onFilter={handleFilter}
          queryKey="skills"
          withIntlOptions={false}
        />
        <FilterDropdown
          labelId="common.seniority"
          options={filters.seniorities}
          onFilter={handleFilter}
          queryKey="seniorities"
        />
        <FormControlLabel
          control={
            <CustomSwitch
              checked={filters.highlighted}
              onChange={handleHighlighted}
              name="highlighted"
            />
          }
          label={<SwitchLabel>{intl.formatMessage({ id: 'common.mainSkill' })}</SwitchLabel>}
        />
        <Button
          variant={BUTTON_VARIANTS.APPLY}
          onClick={handleApplyFilters}
          textIntlId="filter.dashboard.apply"
        />
        <Button
          variant={BUTTON_VARIANTS.CLEAR}
          onClick={handleClearFilters}
          textIntlId="filter.dashboard.clear"
        />
      </Selectors>
    </FiltersContainer>
  );
};

DashboardFilters.propTypes = {
  onRequest: func,
  onClear: func
};

export default DashboardFilters;
