import { cloneDeep, isEqual } from 'lodash';
import { arrayOf, func, object, oneOfType, string } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import BoxItem from 'components/common/BoxItem';
import { BUTTON_VARIANTS } from 'constants/constants';
import { personExperiencesShape, softSkillShape } from 'constants/shapes';

import {
  CheckCircleIcon,
  CloseIcon,
  Container,
  Content,
  EditIcon,
  Header,
  IconsGroup,
  PlusIcon,
  StyledButton
} from './styles';

const Section = ({
  data,
  editSection,
  handleAction,
  name,
  onSubmit,
  setEditSection,
  titleIntlId
}) => {
  const intl = useIntl();
  const isEditing = name === editSection;
  const [copy, setCopy] = useState(null);

  if (!isEqual(copy, data) && !isEditing) {
    setCopy(cloneDeep(data));
  }

  const handleEdit = () => {
    setEditSection(name);
  };

  const handleCancel = () => {
    setEditSection(null);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(copy);
    handleCancel();
  };

  const handleDelete = pos => {
    setCopy(copy.toSpliced(pos, 1));
  };

  return (
    <Container>
      <Header>
        <h3>{intl.formatMessage({ id: titleIntlId })}</h3>
        {isEditing ? (
          <IconsGroup>
            <CheckCircleIcon onClick={handleSubmit} />
            <CloseIcon onClick={handleCancel} />
          </IconsGroup>
        ) : (
          <button type="button" onClick={handleEdit}>
            <EditIcon />
          </button>
        )}
      </Header>
      <Content>
        {Array.isArray(copy) &&
          copy.map(({ name, skillName, time }, index) => (
            <BoxItem
              key={skillName || name}
              title={skillName || name}
              info={time}
              onDelete={() => handleDelete(index)}
              isEditing={isEditing}
            />
          ))}
        {isEditing && (
          <StyledButton
            variant={BUTTON_VARIANTS.PRIMARY}
            icon={<PlusIcon />}
            onClick={handleAction}
          >
            {intl.formatMessage({ id: 'common.addSkill' })}
          </StyledButton>
        )}
      </Content>
    </Container>
  );
};

Section.propTypes = {
  data: arrayOf(oneOfType([softSkillShape, personExperiencesShape, object])),
  editSection: string,
  handleAction: func,
  name: string,
  onSubmit: func,
  setEditSection: func,
  titleIntlId: string
};

export default Section;
