import { useMemo } from 'react';

import useDashboardColumns from './useDashboardColumns';

export default () => {
  const columns = useDashboardColumns();

  return useMemo(
    () => ({
      work: [
        columns.resourceData,
        columns.workArea,
        columns.seniority,
        columns.skills,
        columns.projects,
        columns.harvestBillableHours,
        columns.assignments
      ],

      bench: [
        columns.resourceData,
        columns.workArea,
        columns.seniority,
        columns.skills,
        columns.projects,
        columns.benchBar
      ],

      benchWithPending: [
        columns.resourceData,
        columns.workArea,
        columns.seniority,
        columns.skills,
        columns.projects,
        columns.benchForecast
      ],

      overassigned: [
        columns.resourceData,
        columns.workArea,
        columns.seniority,
        columns.skills,
        columns.projects,
        columns.totalUtilizationBar
      ],

      rolesUtilizations: [columns.role, columns.assignments, columns.resources],

      skillsUtilizations: [columns.skill, columns.assignments, columns.resources, columns.requested]
    }),
    [columns]
  );
};
