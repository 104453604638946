import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { getNotificationHeader } from 'state/actions/notificationActions';

export default () => {
  const getNotificationHeaderRequest = useDispatch(getNotificationHeader);
  const notification = useSelector(({ notification }) => notification);
  const { isPending } = useStatus(getNotificationHeader);

  useEffect(() => {
    getNotificationHeaderRequest();
  }, [getNotificationHeaderRequest]);

  return {
    notification,
    isPending
  };
};
