import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-circle.svg';
import Button from 'components/common/Button';
import PageHeader from 'components/common/PageHeader';
import MappingModal from 'components/MappingRoles/MappingModal';
import MappingTableAndFilters from 'components/MappingRoles/TableAndFilters';
import { BUTTON_VARIANTS, GROUP_ROLE_MODAL_TYPES } from 'constants/constants';
import useRoleGroups from 'hooks/useRoleGroups';

import { Container, Content } from './styles';

const showModal = [
  GROUP_ROLE_MODAL_TYPES.ADD,
  GROUP_ROLE_MODAL_TYPES.DELETE,
  GROUP_ROLE_MODAL_TYPES.EDIT
];

const MappingRoles = () => {
  const [relation, setRelation] = useState({ group: '', role: '', groupName: '', roleName: '' });
  const [modalOpen, setModalOpen] = useState('');

  const intl = useIntl();
  const { roleGroups, isLoadingResources } = useRoleGroups();

  return (
    <>
      <Container>
        <PageHeader titleIntlId="common.settings" subtitleIntlId="settings.mappingRoles" />
        <Content>
          <Button
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
            onClick={() => setModalOpen(GROUP_ROLE_MODAL_TYPES.ADD)}
            icon={<PlusIcon />}
          >
            {intl.formatMessage({ id: 'settings.groupTable.addGroup' })}
          </Button>
          <MappingTableAndFilters
            roleGroups={roleGroups}
            isLoading={isLoadingResources}
            setModalOpen={setModalOpen}
            setRelation={setRelation}
          />
        </Content>
      </Container>
      <MappingModal
        setModalOpen={setModalOpen}
        isShowing={showModal.includes(modalOpen)}
        error={false}
        relation={relation}
        isEdit={modalOpen === GROUP_ROLE_MODAL_TYPES.EDIT}
        isDelete={modalOpen === GROUP_ROLE_MODAL_TYPES.DELETE}
      />
    </>
  );
};

export default MappingRoles;
