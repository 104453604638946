import styled from 'styled-components';

import Button from 'components/common/Button';
import { Row } from 'components/common/Layout/Row';

export const Form = styled.form`
  margin-top: 3.6rem;
  width: 45rem;
`;

export const StyledButton = styled(Button)`
  font-size: 1.5rem;
  height: 3.6rem;
  margin-top: 0;
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.fontColors};
  text-align: center;
`;

export const SubTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 1.9rem;
  color: ${({ theme }) => theme.fontColors};
  text-align: center;
`;

export const StyledRow = styled(Row)`
  margin-top: 2rem;
`;
