import { isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import BoxContainer from 'components/common/BoxContainer';
import Breadcrumb from 'components/common/Breadcrumb';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import NotFound from 'components/common/NotFound';
import ProjectDetailsData from 'components/common/ProjectDetailsData';
import Header from 'components/Header';
import ProjectModal from 'components/timeline/ProjectModal';
import { FULFILLED } from 'constants/actionStatusConstants';
import { PROJECT_USERS_TYPES } from 'constants/constants';
import routesPaths from 'constants/routesPaths';
import { useConfirm, useDispatch, useRequest, useStatus } from 'hooks';
import { getMinimalResources } from 'state/actions/peopleActions';
import { deleteProject, archiveProject, getProjectById } from 'state/actions/projectActions';
import { routeWithProps } from 'utils/helpers';

import AssignmentsSection from './AssignmentsSection';
import { DetailsContainer, ProjectDetailsContainer, ProjectInfoContainer } from './styles';

const ProjectDetails = () => {
  const intl = useIntl();
  const match = useRouteMatch();
  const history = useHistory();
  const projectId = match.params?.id;
  const [editProject, setEditProject] = useState();
  const [isShowEditProject, setIsShowEditProject] = useState(false);
  const [project, setProject] = useState(null);
  const [minimalResources, setMinimalResources] = useState();
  const [users, setUsers] = useState([]);
  const deleteProjectRequest = useDispatch(deleteProject);
  const archiveProjectRequest = useDispatch(archiveProject);

  const [{ isPending: isPendingProject }, getProject] = useRequest(getProjectById, {
    params: { projectId },
    fulfilledCallback: payload => setProject(payload)
  });

  const [{ isPending: isPendingMinimalResources }, getResources] = useRequest(getMinimalResources, {
    params: { userGroup: '' },
    fulfilledCallback: payload => setMinimalResources(payload.resources)
  });
  const getMinimalResourcesRequest = useDispatch(getMinimalResources);

  const { isRejected: isRejectedProject } = useStatus(getProjectById);

  const isLoadData = project && !isEmpty(minimalResources);

  useEffect(() => {
    getMinimalResourcesRequest({ userGroup: 'sales' });
    getMinimalResourcesRequest({ userGroup: 'manager' });
    getMinimalResourcesRequest({ userGroup: 'delivery_owner' });
  }, [getMinimalResourcesRequest]);

  useEffect(() => {
    if (isLoadData) {
      const userType = {
        ...(project.salesPersonId && { [project.salesPersonId]: 0 }),
        ...(project.managerPersonId && { [project.managerPersonId]: 1 }),
        ...(project.deliveryOwnerPersonId && { [project.deliveryOwnerPersonId]: 2 })
      };

      if (Object.keys(userType).length) {
        const updatedUsers = Object.values(PROJECT_USERS_TYPES).map(type => ({ type }));
        minimalResources.forEach(({ id, fullName, avatarUrl }) => {
          if (Object.keys(userType).includes(id.toString())) {
            const pos = userType[id];
            updatedUsers[pos] = { ...updatedUsers[pos], fullName, avatarUrl };
          }
        });

        setUsers(updatedUsers);
      }
    }
  }, [isLoadData, minimalResources, project]);

  const handleRefreshData = useCallback(async () => {
    await getProject();
    await getResources();
  }, [getProject, getResources]);

  const handleModalClose = () => {
    setEditProject(null);
    setIsShowEditProject(false);
  };

  const requestWithRefresh = async request => {
    const { type } = await request(project.id);
    if (type.endsWith(`/${FULFILLED}`)) {
      handleRefreshData();
      history.push(routeWithProps(routesPaths.projectsOverview, {}));
    }
  };

  const [ConfirmationDelete, confirmDelete] = useConfirm({
    title: intl.formatMessage({ id: 'modal.confirm.title' }),
    message: intl.formatMessage({ id: 'template.deleteEntity' }, { entity: 'project' }),
    onConfirm: async () => requestWithRefresh(deleteProjectRequest),
    confirmLabelIntlId: 'common.delete',
    cancelLabelIntlId: 'common.cancel'
  });

  const [ConfirmationArchive, confirmArchive] = useConfirm({
    title: intl.formatMessage({ id: 'modal.confirm.title' }),
    message: intl.formatMessage({ id: 'modal.confirm.archive.message' }),
    onConfirm: async () => requestWithRefresh(archiveProjectRequest),
    confirmLabelIntlId: 'modal.confirm.archive.accept',
    cancelLabelIntlId: 'common.cancel'
  });

  if (isRejectedProject) {
    return <NotFound successRequest={isRejectedProject} />;
  }

  return (
    <ProjectDetailsContainer>
      {isPendingProject ? (
        <CenteredLoading />
      ) : (
        <>
          {project && (
            <>
              <Breadcrumb lastBread={project?.name} />
              <Header headerString={project?.name} />
              <DetailsContainer>
                <BoxContainer>
                  <ProjectDetailsData
                    project={project}
                    users={users}
                    isLoading={isPendingMinimalResources}
                    setEditProject={setEditProject}
                    setIsShowEditProject={setIsShowEditProject}
                    confirmDelete={confirmDelete}
                    confirmArchive={confirmArchive}
                  />
                </BoxContainer>
                <ProjectInfoContainer>
                  <BoxContainer>
                    <AssignmentsSection project={project} />
                  </BoxContainer>
                </ProjectInfoContainer>
              </DetailsContainer>
            </>
          )}
        </>
      )}
      <ProjectModal
        handleRefresh={handleRefreshData}
        isShowing={!!editProject || isShowEditProject}
        hide={handleModalClose}
        edit
        projectId={projectId}
      />
      <ConfirmationArchive />
      <ConfirmationDelete />
    </ProjectDetailsContainer>
  );
};

export default ProjectDetails;
