import styled from 'styled-components';

import { ReactComponent as Star } from 'components/icons/star.svg';

export const Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SkillToolTipList = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  justify-content: space-between;
  min-width: 20rem;
  line-height: 2rem;
`;

export const SkillListName = styled.div`
  color: ${({ theme }) => theme.colors.gray80};
`;

export const SkillListExperience = styled.div`
  margin-left: 5px;
`;

export const HighlightedSkill = styled.span`
  display: flex;
`;

export const StyledStar = styled(Star)`
  align-self: center;
`;
