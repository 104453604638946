import React from 'react';

import { StyledEmptyState } from './styles';

const TableEmptyState = () => (
  <StyledEmptyState
    titleIntlId="financial.noBillableData.title"
    textIntlId="financial.noBillableData.subTitle"
    height="29.2rem"
  />
);

export default TableEmptyState;
