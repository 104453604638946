import styled from 'styled-components';

export const ContainerBarTooltip = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.gray100};
  font-family: ${({ theme }) => theme.fontFamily};
  line-height: 1.2rem;
  padding: 1rem 2rem;

  span:nth-child(1) {
    font-size: 1.3rem;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.8rem;
  }

  span:nth-child(2) {
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.6rem;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  p,
  p > span {
    align-items: center;
    color: ${({ theme }) => theme.colors.gray100};
    display: inline-flex;
    line-height: 1.2rem;
    font-size: 1.4rem;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  p > span {
    color: ${({ theme }) => theme.colors.black};
  }
`;
