import { isEmpty } from 'lodash';
import { func, bool, object, number } from 'prop-types';
import { useIntl } from 'react-intl';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import NewModal from 'components/common/NewModal';
import { FULFILLED } from 'constants/actionStatusConstants';
import { useDispatch, useRequest, useStatus } from 'hooks';
import { createAssignment } from 'state/actions/assignmentActions';
import { getProjectById } from 'state/actions/projectActions';

import AssignmentForm from '../AssignmentForm';

const AddAssignmentModal = ({
  addAssignmentAction,
  handleRefresh,
  hide,
  initialValues,
  isNewProject = false,
  isShowing,
  projectId,
  showCopy = false
}) => {
  const intl = useIntl();

  const createAssignmentRequest = useDispatch(createAssignment);
  const addAssignmentRequest = useDispatch(addAssignmentAction);
  const resetStatusRequest = useDispatch(
    addAssignmentAction ? addAssignmentAction.reset : createAssignment.reset
  );
  const { status, error } = useStatus(addAssignmentAction || createAssignment);

  const [{ response: project }] = useRequest(getProjectById, {
    params: { projectId }
  });

  const resetStatusAndHide = () => {
    resetStatusRequest();
    hide();
  };

  const handleSubmit = async param => {
    const assignmentRequest = addAssignmentAction ? addAssignmentRequest : createAssignmentRequest;
    const { type } = await assignmentRequest(param);

    if (type.endsWith(`/${FULFILLED}`)) {
      handleRefresh?.();
    }
  };

  return (
    <NewModal
      isShowing={isShowing}
      hide={resetStatusAndHide}
      title={intl.formatMessage({ id: 'template.newAssignment' }, { projectName: project?.name })}
      maxWidth="64rem"
    >
      {!isEmpty(project) ? (
        <AssignmentForm
          project={project}
          onSubmit={handleSubmit}
          resetStatus={resetStatusRequest}
          status={status}
          initialValues={initialValues}
          hide={resetStatusAndHide}
          error={error}
          showCopy={showCopy}
          isNewProject={isNewProject}
        />
      ) : (
        <CenteredLoading />
      )}
    </NewModal>
  );
};

AddAssignmentModal.propTypes = {
  addAssignmentAction: func,
  handleRefresh: func,
  hide: func.isRequired,
  initialValues: object,
  isNewProject: bool,
  isShowing: bool.isRequired,
  projectId: number,
  showCopy: bool
};

export default AddAssignmentModal;
