import styled from 'styled-components';

import Button from 'components/common/Button';

export const AcknowledgeSectionContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1.2rem;
  margin: 1.3rem 0 0 6.2rem;

  & p {
    color: ${({ theme }) => theme.fontColor};
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 1.4rem;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 1.9rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 6.9rem;
`;
