import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { globalIndicatorsShape } from 'constants/shapes';

import ItemTitle from '../ItemTitle';
import { BillableCapacityContainer, DataSection, InfoLabel, PercentageValue } from './styles';

const BillableCapacity = ({ globalIndicators }) => {
  const intl = useIntl();
  const theme = useTheme();

  const { availableCapacity, scheduledUtilization, scheduledPendingUtilization } = globalIndicators;

  return (
    <BillableCapacityContainer>
      <ItemTitle
        title={intl.formatMessage({ id: 'dashboard.capacity' })}
        subTitle={intl.formatMessage({ id: 'dashboard.percentageBillableCapacity' })}
      />
      <DataSection>
        <InfoLabel color={theme.colors.java}>
          {intl.formatMessage({ id: 'common.scheduledUtilization' })}
        </InfoLabel>
        <PercentageValue>{`${scheduledUtilization}%`}</PercentageValue>
        <InfoLabel>{intl.formatMessage({ id: 'dashboard.scheduledPending' })}</InfoLabel>
        <PercentageValue>{`${scheduledPendingUtilization}%`}</PercentageValue>
        <InfoLabel color={theme.colors.dodgerBlue}>
          {intl.formatMessage({ id: 'dashboard.availableCapacity' })}
        </InfoLabel>
        <PercentageValue>{`${availableCapacity}%`}</PercentageValue>
      </DataSection>
    </BillableCapacityContainer>
  );
};

BillableCapacity.propTypes = {
  globalIndicators: globalIndicatorsShape
};

export default BillableCapacity;
