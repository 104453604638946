import { arrayOf, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { indicatorShape } from 'constants/shapes';

import StatusIndicator from '../StatusIndicator';
import { Title, StatusIndicatorContainer } from './styles';

const DetailsBoxTitle = ({ title, indicators, variant = 'primary' }) => {
  const intl = useIntl();
  return (
    <Title variant={variant}>
      <h2>{intl.formatMessage({ id: title })}</h2>
      <StatusIndicatorContainer>
        {indicators?.map(({ color, label, value }) => (
          <StatusIndicator
            key={`${label}-status`}
            color={color}
            label={label}
            value={value ? `${value}%` : ''}
          />
        ))}
      </StatusIndicatorContainer>
    </Title>
  );
};

DetailsBoxTitle.propTypes = {
  title: string.isRequired,
  variant: string,
  indicators: arrayOf(indicatorShape)
};

export default DetailsBoxTitle;
