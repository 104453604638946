import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import NewModal from 'components/common/NewModal';
import { useStatus } from 'hooks';
import { createProject } from 'state/actions/projectActions';

import RoleForm from './RoleForm';

const AddRoleModal = ({ isShowing, hide, resource, isEdit, temporalGroupId }) => {
  const intl = useIntl();
  const action = createProject;
  const { status, error } = useStatus(action);

  return (
    <NewModal
      isShowing={isShowing}
      hide={hide}
      contentPadding="2.4rem"
      title={intl.formatMessage(
        { id: isEdit ? 'settings.editRole.title' : 'settings.addRole.title' },
        { fullName: resource?.name }
      )}
      maxWidth="64rem"
      height="30rem"
    >
      <RoleForm
        status={status}
        hide={hide}
        error={error}
        resource={resource}
        isEdit={isEdit}
        temporalGroupId={temporalGroupId}
      />
    </NewModal>
  );
};

AddRoleModal.propTypes = {
  hide: func.isRequired,
  isShowing: bool,
  resource: shape({ name: string, id: string }),
  isEdit: bool,
  temporalGroupId: oneOfType([number, string])
};

export default AddRoleModal;
