import { node, oneOf, bool, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Loading from 'components/common/Loading';
import { BUTTON_VARIANTS } from 'constants/constants';

import { StyledButton, LoadingContainer } from './styles';

const Button = ({
  children,
  variant,
  textIntlId,
  disabled = false,
  isLoading = false,
  fullWidth = false,
  icon,
  padding,
  value,
  ...props
}) => (
  <StyledButton
    disabled={disabled || isLoading}
    variant={variant}
    fullWidth={fullWidth}
    padding={padding}
    {...props}
  >
    {icon}
    {children}
    {value || (textIntlId && <FormattedMessage id={textIntlId} />)}
    <LoadingContainer>{isLoading && <Loading />}</LoadingContainer>
  </StyledButton>
);

Button.propTypes = {
  children: node,
  variant: oneOf(Object.values(BUTTON_VARIANTS)),
  isLoading: bool,
  disabled: bool,
  textIntlId: string,
  fullWidth: bool,
  icon: node,
  padding: string,
  value: string
};

export default Button;
