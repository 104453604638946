import PropTypes, { node } from 'prop-types';
import { useIntl } from 'react-intl';

import { Header, Title, VerticalLine } from './styles';

const PageHeader = ({ titleIntlId, subtitleIntlId, children }) => {
  const intl = useIntl();
  return (
    <Header>
      <Title>
        <h1>{intl.formatMessage({ id: titleIntlId })}</h1>
        {subtitleIntlId && (
          <>
            <VerticalLine />
            <h2>{intl.formatMessage({ id: subtitleIntlId })}</h2>
          </>
        )}
      </Title>
      {children}
    </Header>
  );
};

PageHeader.propTypes = {
  titleIntlId: PropTypes.string.isRequired,
  subtitleIntlId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([undefined])]),
  children: node
};

export default PageHeader;
