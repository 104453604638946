import { number } from 'prop-types';
import { useIntl } from 'react-intl';
import { PieChart, Pie, Cell } from 'recharts';
import { useTheme } from 'styled-components';

import ItemTitle from 'components/dashboard/ItemTitle';

import { DataSection, Info, InfoLabel, UtilizationChartContainer } from './styles';

const UtilizationChart = ({ percentage, size = 80 }) => {
  const theme = useTheme();
  const intl = useIntl();

  const isOver100 = percentage > 100;

  const data = [{ value: percentage }, { value: isOver100 ? 0 : 100 - percentage }];
  const colors = [theme.colors.crusta, theme.colors.newOrleans];

  return (
    <UtilizationChartContainer>
      <ItemTitle
        title={intl.formatMessage({ id: 'dashboard.actualUtilization' })}
        subTitle={intl.formatMessage({ id: 'dashboard.actualAmount' })}
      />
      <DataSection>
        <Info>
          <InfoLabel color={theme.colors.crusta}>
            {intl.formatMessage({ id: 'dashboard.billedHours' })}
          </InfoLabel>
          <InfoLabel color={theme.colors.newOrleans}>
            {intl.formatMessage({ id: 'dashboard.nonBilledHours' })}
          </InfoLabel>
        </Info>
        <PieChart width={2 * size} height={2 * size}>
          <Pie
            data={data}
            dataKey="value"
            startAngle={180}
            endAngle={540}
            innerRadius={50}
            outerRadius={80}
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Pie>
          <text
            x="50%"
            y="50%"
            dy={10}
            textAnchor="middle"
            fontSize="2.4rem"
            fontFamily={theme.fontFamily}
            fontWeight="600"
            color={theme.fontColor}
          >
            {percentage}%
          </text>
        </PieChart>
      </DataSection>
    </UtilizationChartContainer>
  );
};

UtilizationChart.propTypes = {
  percentage: number,
  size: number
};

export default UtilizationChart;
