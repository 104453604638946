import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled, { css } from 'styled-components';

import { ReactComponent as Caret } from 'components/icons/caret.svg';
import DropdownIcon from 'components/icons/DropdownIcon';
import { ReactComponent as ArrowDown } from 'components/icons/filled-down-chevron.svg';
import { inputBorder, newInputBorder } from 'utils/inputColors';

const fontVariant = variant =>
  ({
    old: css`
      ${({ theme }) => theme.typography.regular};
      color: ${({ theme }) => theme.colors.gray100};
      font-size: 1.5rem;
    `,
    new: css`
      ${({ theme }) => theme.new.typography.p5};
      color: ${({ theme }) => theme.new.colors.neutral900};
    `
  }[variant] || '');

const labelVariant = variant =>
  ({
    old: css`
      border-radius: 1.3rem;
      bottom: auto;
      color: ${({ errors, theme }) =>
        errors ? theme.new.colors.feedbackError : theme.new.colors.neutral600};
      font-size: 1.6rem;
      left: 0.6rem;
      padding: 0 0.5rem;
      position: absolute;
      pointer-events: none;
      top: 1.9rem;
      transition: all 0.2s ease-in-out;
      z-index: 2;
      user-select: none;
      ${({ isAbove }) =>
        isAbove &&
        `
    font-size: 1.2rem;
    left: 1rem;
    top: 0;

  `};
    `,
    new: css`
      ${({ theme }) => theme.new.typography.p6};
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.new.colors.neutral800};
      font-size: 1.6rem;
      padding: 0 0 4px;
    `
  }[variant] || '');

export const Label = styled.label`
  ${({ variant }) => labelVariant(variant)}
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: none;
`;

export const SecondaryLabel = styled.span`
  color: ${({ theme }) => theme.new.colors.N500};
`;

const selectVariant = variant =>
  ({
    old: css`
      border: ${({ errors, theme }) =>
        inputBorder({ baseColor: theme.colors.gray60, errors, theme })};
      border-radius: 8px;
      &:focus + ${Label} {
        border-color: ${({ errors, theme: { colors } }) => (errors ? colors.red : colors.seaBlue)};
        box-shadow: none;
      }
    `,
    new: css`
      border: ${({ errors, theme }) =>
        newInputBorder({ baseColor: theme.new.colors.neutral400, errors, theme })};
      border-radius: 4px;
      height: 4.4rem;
    `
  }[variant] || '');

export const FilledArrow = styled(ArrowDown)`
  transform: ${({ $upDirection }) => ($upDirection ? 'rotate(180deg)' : 'none')};
  transition: all 0.2s ease-in-out;
`;

export const Arrow = styled(Caret)`
  height: 2rem !important;
  transform: ${({ $upDirection }) => ($upDirection ? 'rotate(180deg)' : 'none')};
  transition: all 0.2s ease-in-out;
  width: 2rem !important;
`;

export const StyledDropDownIcon = styled(DropdownIcon)`
  background-color: ${({ theme }) => theme.new.colors.white};
  height: 17px;
  stroke-width: 0.4px;
  stroke: ${({ theme }) => theme.new.colors.neutral600};
  width: 32px;
  transform: ${({ $upDirection }) =>
    $upDirection ? 'rotate(-180deg) translate(0, -20%) scale(0.9)' : 'scale(0.9)'};
`;

export const ErrorSpan = styled.span`
  ${({ variant }) =>
    ({
      old: css`
        color: ${({ theme: { colors } }) => colors.red};
        font-size: 1.4rem;
        font-style: italic;
      `,
      new: css`
        ${({ theme }) => theme.new.typography.p6};
        color: ${({ theme }) => theme.new.colors.feedbackError};
      `
    }[variant])}

  line-height: 3rem;
  padding-left: 0.5rem;
`;

export const StyledMenuList = styled(components.MenuList)`
  ${({ variant }) =>
    variant === 'new' &&
    css`
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 1.1rem;
      }

      &::-webkit-scrollbar:horizontal {
        height: 1.1rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ customTheme }) => customTheme.new.colors.neutral300};
        border-radius: 8px;
        border: 2px solid ${({ customTheme }) => customTheme.new.colors.white};
        width: 0.1rem;
      }
    `}
`;

const SelectStyles = css`
  ${({ theme }) => theme.typography.p5};
  color: ${({ theme }) => theme.colors.gray100};
  padding: ${({ errors, variant }) => variant === 'old' && `1.1rem 0 ${errors ? '0' : '1.3rem'}`};
  transition: all 0.2s ease-in-out;

  ${Arrow} {
    ${({ arrowSize }) => {
      if (arrowSize === 'sm') {
        return `
          height: 1rem;
          width: 1rem;
        `;
      }
      // md
      return `
          height: 1.5rem;
          width: 1.5rem;
        `;
    }}
  }

  .react-select__control {
    background-color: ${({ withoutHighlight, hasValue, theme: { colors } }) =>
      hasValue && !withoutHighlight && colors.selectedBackground};
    cursor: pointer;
    height: ${({ height }) => height && `${height}rem`};
    ${({ variant }) => selectVariant(variant)};
  }

  .react-select__control--is-focused {
    border-color: ${({ errors, theme: { colors } }) => (errors ? colors.red : colors.seaBlue)};
    box-shadow: none;
    &:hover {
      border-color: ${({ errors, theme: { colors } }) => (errors ? colors.red : colors.seaBlue)};
      box-shadow: none;
    }
  }

  .react-select__container {
    border-radius: 1.3rem;
    border: ${({ hasValue, errors, theme: { colors } }) => {
      let color = colors.gray30;
      if (errors) color = colors.red;
      else if (hasValue) color = colors.seaBlue;

      return `1px ${color} solid`;
    }};
    ${({ variant }) => selectVariant(variant)}
  }

  .react-select__single-value {
    ${({ variant }) => fontVariant(variant)}
    ${({ hasValue, withoutHighlight, theme: { colors, fontWeights } }) =>
      hasValue &&
      !withoutHighlight &&
      `
      color: ${colors.seaBlue};
      font-weight: ${fontWeights.bold};
    `}
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme }) => theme.new.colors.N600};
  }

  .react-select__value-container {
    border: none;
    flex-wrap: nowrap;
    height: 100%;
    overflow-x: auto;
  }

  .react-select__multi-value {
    background-color: ${({ theme }) => theme.colors.seaBlue};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.4rem;
    min-width: fit-content;
  }

  .react-select__multi-value__label {
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  .react-select__group-heading {
    ${({ theme }) => theme.typography.semiBold};
    color: ${({ theme }) => theme.colors.gray100};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray60};
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
  }

  .react-select--is-disabled,
  .react-select__control--is-disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    border-color: ${({ theme }) => theme.colors.gray60};

    .react-select__single-value {
      color: ${({ theme }) => theme.fontColor};
      font-weight: 300;
    }
  }

  .react-select__menu {
    z-index: 3;
  }
`;

export const StyledSelect = styled(Select)`
  ${SelectStyles}
`;

export const StyledCreatableSelect = styled(CreatableSelect)`
  ${SelectStyles}
`;

export const SelectContainer = styled.div`
  background: none;
  display: ${({ hidden }) => hidden && 'none'};
  font-size: 1.6rem;
  position: relative;
  width: 100%;
  height: 100%;
  ${({ variant }) =>
    variant === 'old' &&
    css`
      &:focus-within ${Label} {
        color: ${({ errors, theme: { colors } }) => (errors ? colors.red : colors.seaBlue)};
      }
    `}
`;

export const OptionContent = styled.div`
  ${({ variant }) =>
    variant === 'new' &&
    css`
      ${({ theme }) => theme.new.typography.p5}
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-between;
      width: 100%;
    `}
`;

export const StyledOption = styled(components.Option)`
  ${({ variant }) =>
    variant === 'new' &&
    css`
      background-color: ${({ customTheme }) => `${customTheme.new.colors.white} !important`};
      color: ${({ customTheme }) => customTheme.new.colors.neutral800} !important;
      height: 4.4rem;
      width: 100%;
      &:hover,
      &:focus-within,
      &:visited {
        color: ${({ customTheme }) => customTheme.new.colors.brand} !important;
        background-color: ${({ customTheme }) => `${customTheme.new.colors.neutral100} !important`};
      }
    `}
`;

export const StyledDropdownIndicator = styled(components.DropdownIndicator)`
  ${({ variant }) =>
    variant === 'new' &&
    css`
      padding-left: 0 !important;
      padding-bottom: 10px !important;
    `}
  svg {
    background: transparent;
  }
`;

export const SelectIconWrapper = styled.div`
  display: flex;

  svg {
    margin-left: 0.25rem;
    margin-right: 0.5rem;
    height: 2.4rem;
    width: 2.4rem;
  }
`;

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  width: 2.4rem;

  svg {
    stroke: ${({ theme }) => theme.new.colors.brand};
  }

  path {
    stroke-width: 0.8px;
  }
`;

export const OptionIconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  margin-right: 0.7rem;
  width: 2.4rem;
`;

export const OptionContainer = styled.div`
  display: flex;
`;

export const StyledClearIndicator = styled(components.ClearIndicator)`
  ${({ variant }) => variant === 'new' && 'padding: 0 !important;'}
`;

export const StyledCloseIcon = styled.img`
  height: 2.2rem;
  width: 2.2rem;
`;
