import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Info = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.N600};
  display: flex;
  align-items: center;
  gap: 1.2rem;
  overflow: hidden;
  width: 100%;

  button:hover {
    text-decoration: underline;
  }
  p:first-child {
    ${({ theme }) => theme.new.typography.p5SemiBold};
    color: ${({ theme }) => theme.new.colors.N800};
  }
  p {
    text-align: left;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const TooltipContent = styled.div`
  width: 20rem;
  text-overflow: wrap;
  white-space: initial;
`;
