import AccessMatrixImage from 'components/AccessMatrix';
import PageHeader from 'components/common/PageHeader';

import { Container, Content } from './styles';

const AccessMatrix = () => (
  <Container>
    <PageHeader titleIntlId="common.settings" subtitleIntlId="settings.accessMatrix" />
    <Content>
      <AccessMatrixImage />
    </Content>
  </Container>
);

export default AccessMatrix;
