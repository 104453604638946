import { node, func, bool, string, oneOfType } from 'prop-types';
import { memo } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import ReactPortal from 'components/HOC/ReactPortal';

import { VerticalSeparator } from '../Layout/Separator';
import { ModalButton, ModalContainer, ContentWrapper, ModalTitle, ModalSubTitle } from './styles';

const NewModal = ({
  children,
  title,
  subTitle,
  isShowing,
  hide,
  closeHidden = false,
  withoutOverflow = false,
  onClick,
  contentPadding,
  isOverlapped = false,
  isAddBorder = true,
  className,
  maxWidth,
  ...props
}) =>
  isShowing && (
    <ReactPortal>
      <ModalContainer
        isOverlapped={isOverlapped}
        onClick={e => {
          e.stopPropagation();
          onClick && onClick();
        }}
        className={className}
      >
        <ContentWrapper
          withoutOverflow={withoutOverflow}
          padding={contentPadding}
          maxWidth={maxWidth}
          {...props}
        >
          {!closeHidden && (
            <ModalButton onClick={hide}>
              <CloseIcon />
            </ModalButton>
          )}
          <ModalTitle $hasSubtitle={Boolean(subTitle)}>{title}</ModalTitle>
          {subTitle && <ModalSubTitle>{subTitle}</ModalSubTitle>}
          {isAddBorder && <VerticalSeparator marginBottom="1rem" height={2} />}
          {children}
        </ContentWrapper>
      </ModalContainer>
    </ReactPortal>
  );

NewModal.propTypes = {
  children: node.isRequired,
  className: string,
  closeHidden: bool,
  contentPadding: string,
  hide: func.isRequired,
  isAddBorder: bool,
  isOverlapped: bool,
  isShowing: bool.isRequired,
  maxWidth: string,
  onClick: func,
  subTitle: string,
  title: oneOfType([string, node]),
  withoutOverflow: bool
};

export default memo(NewModal);
