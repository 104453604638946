import { sortBy } from 'lodash';
import { bool, func, number } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { BUTTON_VARIANTS } from 'constants/constants';
import { useRequest, useFinancial, useConfirm, useDispatch } from 'hooks';
import {
  getFinancialResourceSetup,
  getProjectOpenPeriodsData,
  getRefreshedResourceSetup,
  refreshResourceSetup
} from 'state/actions/financialActions';
import { getResourcesUpdatedLists } from 'utils/financialUtilities';

import EmptyState from './EmptyState';
import ResourcesTable from './ResourcesTable';
import { BottomSection, ButtonsRow, Content, StyledButton, StyledNewModal, Title } from './styles';

const UpdateSetupModal = ({ hide, open, projectId }) => {
  const intl = useIntl();
  const refreshSetupRequest = useDispatch(refreshResourceSetup);
  const getFinancialResourceSetupRequest = useDispatch(getFinancialResourceSetup);
  const getProjectOpenPeriodsDataRequest = useDispatch(getProjectOpenPeriodsData);
  const [refreshedSetup, setRefreshedSetup] = useState([]);
  const [{ isPending: isLoadingRefreshedSetup, error: getError }] = useRequest(
    getRefreshedResourceSetup,
    {
      fulfilledCallback: payload => {
        setRefreshedSetup(sortBy(payload?.periodSetup, 'resourceName'));
      },
      params: { projectId }
    }
  );

  const [ConfirmationRefreshSetup, confirmRefreshSetup] = useConfirm({
    title: intl.formatMessage({ id: 'financial.splitCosts.updateResources.confirm' }),
    warning: intl.formatMessage({ id: 'financial.splitCosts.updateResources.confirm.message' }),
    onConfirm: () => refreshSetupRequest({ projectId }),
    onSuccess: () => {
      getFinancialResourceSetupRequest({ id: projectId });
      getProjectOpenPeriodsDataRequest({ id: projectId });
      hide();
    },
    confirmLabelIntlId: 'common.accept',
    cancelLabelIntlId: 'common.cancel'
  });

  const { resourceSetup } = useFinancial();

  if (isLoadingRefreshedSetup)
    return (
      <StyledNewModal
        title={intl.formatMessage({ id: 'financial.updateResources.modal.title' })}
        isShowing={open}
        hide={hide}
        withoutOverflow
      >
        <Content isLoading={isLoadingRefreshedSetup}>
          <CenteredLoading />
        </Content>
      </StyledNewModal>
    );

  const [updatedList, resourcesUpdatedList] = getResourcesUpdatedLists(
    resourceSetup,
    refreshedSetup
  );

  const hasUpdates = !!updatedList?.length || !!resourcesUpdatedList?.length;

  return (
    <>
      <StyledNewModal
        title={intl.formatMessage({ id: 'financial.updateResources.modal.title' })}
        isShowing={open}
        hide={hide}
        withoutOverflow
      >
        <Content isResourceEqual={!hasUpdates}>
          {!hasUpdates ? (
            <EmptyState />
          ) : (
            <>
              {updatedList?.length > 0 && (
                <>
                  <Title>
                    {intl.formatMessage({ id: 'financial.updateResources.modal.section1' })}
                  </Title>
                  <ResourcesTable resources={updatedList} />
                </>
              )}
              {resourcesUpdatedList?.length > 0 && (
                <>
                  <Title>
                    {intl.formatMessage({ id: 'financial.updateResources.modal.section2' })}
                  </Title>
                  <ResourcesTable resources={resourcesUpdatedList} />
                </>
              )}
            </>
          )}
          <BottomSection>
            {getError && <AlertMessage>{getError}</AlertMessage>}
            <ButtonsRow isResourceEqual={!hasUpdates}>
              {hasUpdates && (
                <StyledButton
                  id="cancelBtn"
                  textIntlId="financial.updateResources.modal.cancel"
                  variant={BUTTON_VARIANTS.NEW_SECONDARY}
                  onClick={() => hide()}
                />
              )}
              <StyledButton
                type="button"
                id="acceptChanges"
                variant={BUTTON_VARIANTS.NEW_PRIMARY}
                onClick={hasUpdates ? confirmRefreshSetup : () => hide()}
                isResourceEqual={!hasUpdates}
                textIntlId="common.accept"
              />
            </ButtonsRow>
          </BottomSection>
        </Content>
      </StyledNewModal>
      <ConfirmationRefreshSetup textAlign="left" />
    </>
  );
};

UpdateSetupModal.propTypes = {
  hide: func,
  open: bool,
  projectId: number
};

export default UpdateSetupModal;
