import { PROJECT_STATUS_TYPES } from 'constants/constants';

export const getMarginColor = props => {
  const { theme, margin } = props;
  let color = theme.new.colors.neutral800;
  if (margin >= 50) color = theme.new.colors.FS900;
  if (margin >= 20 && margin < 50) color = theme.new.colors.FW900;
  if (margin > 0 && margin < 20) color = theme.new.colors.FE500;
  if (margin < 0) color = theme.new.colors.FE900;
  return color;
};

export const getMarginColorBorder = props => {
  const { theme, margin } = props;
  let color = theme.new.colors.neutral800;
  if (margin >= 50) color = theme.new.colors.FS500;
  if (margin >= 20 && margin < 50) color = theme.new.colors.feedbackWarningBase;
  if (margin > 0 && margin < 20) color = theme.new.colors.FE500;
  if (margin < 0) color = theme.new.colors.FE500;
  return color;
};

export const getMarginColorBackground = props => {
  const { theme, margin } = props;
  let color = theme.new.colors.neutral200;
  if (margin >= 50) color = theme.new.colors.feedbackSuccessLight;
  if (margin >= 20 && margin < 50) color = theme.new.colors.feedbackWarningLight;
  if (margin > 0 && margin < 20) color = theme.new.colors.feedbackErrorLight;
  return color;
};

export const getStatusColor = props => {
  const { theme, status } = props;
  return (
    {
      [PROJECT_STATUS_TYPES.ACTIVE]: theme.new.colors.brandDark,
      [PROJECT_STATUS_TYPES.MAINTENANCE]: theme.new.colors.strongMaintenance,
      [PROJECT_STATUS_TYPES.FINISHED]: theme.new.colors.fernGreen,
      [PROJECT_STATUS_TYPES.PLANNED]: theme.new.colors.flamingo,
      [PROJECT_STATUS_TYPES.WARRANTY]: theme.new.colors.strongWarranty,
      [PROJECT_STATUS_TYPES.CONFIRMED]: theme.new.colors.feedbackSuccess,
      [PROJECT_STATUS_TYPES.ON_HOLD]: theme.new.colors.strongPlanned
    }[status] || theme.new.colors.white
  );
};

export const getStatusColorBackground = props => {
  const { theme, status } = props;
  return (
    {
      [PROJECT_STATUS_TYPES.ACTIVE]: theme.new.colors.brandLight,
      [PROJECT_STATUS_TYPES.MAINTENANCE]: theme.new.colors.softMaintenance,
      [PROJECT_STATUS_TYPES.FINISHED]: theme.new.colors.feta,
      [PROJECT_STATUS_TYPES.PLANNED]: theme.new.colors.pippin,
      [PROJECT_STATUS_TYPES.WARRANTY]: theme.new.colors.softWarranty,
      [PROJECT_STATUS_TYPES.CONFIRMED]: theme.new.colors.hintOfGreen,
      [PROJECT_STATUS_TYPES.ON_HOLD]: theme.new.colors.softPlanned
    }[status] || theme.new.colors.black
  );
};
