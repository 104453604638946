import { css } from 'styled-components';

import { setSvgColors } from 'utils/cssUtilities';

const fontColor = css`
  ${({ theme, outlined, disabled }) => {
    if (outlined) {
      return disabled ? theme.colors.gray60 : theme.colors.seaBlue;
    }
    return theme.colors.white;
  }}}
`;

const backgroundColor = css`
  ${({ theme, outlined, disabled }) => {
    if (outlined) {
      return disabled ? theme.colors.gray30 : theme.colors.white;
    }
    return disabled ? theme.colors.gray60 : theme.colors.seaBlue;
  }}
`;

export const SubmitButton = css`
  ${({ theme }) => theme.typography.semiBold};
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  font-size: 1.8rem;
  border-radius: 3rem;
  height: 4.8rem;
  margin-top: 1.5rem;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  border: ${({ theme, disabled }) =>
    `1px solid ${disabled ? theme.colors.gray60 : theme.colors.seaBlue}`};
  background-color: ${backgroundColor};
  color: ${fontColor};
  ${setSvgColors({ fillColor: backgroundColor, strokeColor: fontColor })}
`;
