import { arrayOf, bool } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { notificationShape } from 'constants/shapes';
import { getRequestFormatDate, addDays } from 'utils/date';

import NotificationItem from '../NotificationItem';
import { ContainerGroups, NotificationContainer, Title } from './styles';

const NotificationMain = ({ notifications, isLoading }) => {
  const intl = useIntl();
  const today = getRequestFormatDate(new Date());
  const yesterday = getRequestFormatDate(addDays(today, -1));
  const [todayList, setTodayList] = useState([]);
  const [yesterdayList, setYesterdayList] = useState([]);
  const [otherList, setOtherList] = useState([]);

  useEffect(() => {
    if (notifications) {
      setTodayList(notifications.filter(item => getRequestFormatDate(item.createdAt) === today));
      setYesterdayList(
        notifications.filter(item => getRequestFormatDate(item.createdAt) === yesterday)
      );
      setOtherList(notifications.filter(item => getRequestFormatDate(item.createdAt) < yesterday));
    }
  }, [notifications, today, yesterday]);

  const hiddenList = list => !list?.length > 0;

  if (isLoading) return <CenteredLoading />;
  return (
    <>
      <ContainerGroups>
        <NotificationContainer hidden={hiddenList(todayList)}>
          <Title>{intl.formatMessage({ id: 'common.today' })}</Title>
          {todayList.map(item => (
            <NotificationItem item={item} key={item.notificationId} />
          ))}
        </NotificationContainer>
        <NotificationContainer hidden={hiddenList(yesterdayList)}>
          <Title>{intl.formatMessage({ id: 'common.yesterday' })}</Title>
          {yesterdayList.map(item => (
            <NotificationItem item={item} key={item.notificationId} />
          ))}
        </NotificationContainer>
        <NotificationContainer hidden={hiddenList(otherList)}>
          <Title>{intl.formatMessage({ id: 'common.others' })}</Title>
          {otherList.map(item => (
            <NotificationItem item={item} key={item.notificationId} />
          ))}
        </NotificationContainer>
      </ContainerGroups>
    </>
  );
};

NotificationMain.propTypes = {
  notifications: arrayOf(notificationShape),
  isLoading: bool
};

export default NotificationMain;
