import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import Input from 'components/common/Input';
import { RowInputWrapper, Row } from 'components/common/Layout/Row';
import Select from 'components/common/Select';
import { useSkills, useHeights } from 'hooks';

import { StyledDeleteButton } from './styles';

const SkillsRow = ({
  skillsFields,
  handleSkillSelectChange,
  handleSkillInputChange,
  handleRemoveSkill
}) => {
  const intl = useIntl();
  const { skillOptions } = useSkills();
  const { inputHeight } = useHeights();

  const skillsRows =
    !isEmpty(skillsFields) &&
    skillsFields.map((field, index) => (
      <Row key={`${index}-${field.skillId}`} gap="1rem">
        <RowInputWrapper>
          <Select
            id="resourceSkills"
            name="skillId"
            label={intl.formatMessage({ id: 'common.skill' })}
            options={skillOptions}
            isSearchable
            height={inputHeight}
            isClearable
            withoutHighlight
            onChange={event => handleSkillSelectChange(index, event)}
            value={field.skill}
          />
        </RowInputWrapper>
        <RowInputWrapper>
          <Input
            name="weeks"
            type="number"
            min="1"
            label={intl.formatMessage({ id: 'resource.weeks' })}
            fullWidth
            required
            onChange={event => handleSkillInputChange(index, event)}
            value={field.weeks}
          />
        </RowInputWrapper>
        <StyledDeleteButton onClick={() => handleRemoveSkill(index)} />
      </Row>
    ));

  return skillsRows;
};

export default SkillsRow;
