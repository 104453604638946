import { useCallback } from 'react';
import validate from 'validate.js';

validate.validators.presence.options = { allowEmpty: false };

export default (constraints, options = { fullMessages: false }, customValidators = null) =>
  useCallback(
    values => {
      if (customValidators) {
        validate.validators = { ...validate.validators, ...customValidators };
      }
      return validate(values, constraints, options);
    },
    [constraints, options, customValidators]
  );
