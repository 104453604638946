import { number, shape, string } from 'prop-types';
import { useTheme } from 'styled-components';

const XAxisTick = ({ x = 0, y = 0, payload }) => {
  const theme = useTheme();

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-35}
        dy={16}
        fill={theme.new.colors.neutral800}
        fontSize={14}
        fontWeight={theme.fontWeights.normal}
        fontFamily={theme.new.fontFamily}
      >
        {payload.value}
      </text>
    </g>
  );
};

XAxisTick.propTypes = {
  payload: shape({ value: string }),
  x: number,
  y: number
};

export default XAxisTick;
