import styled from 'styled-components';

import {
  getMarginColor,
  getMarginColorBackground,
  getMarginColorBorder
} from 'utils/financialColors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  gap: 1.1rem;
`;

export const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral400};
  border-radius: 6px;
  padding: 0 1.4rem;
`;

export const Header = styled.div`
  ${({ theme }) => theme.new.typography.p6SemiBold};
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
  background: ${({ theme }) => theme.new.colors.white};
  padding: 0 1.4rem;
`;

export const Row = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  display: flex;
  align-items: center;
  width: 100%;
  gap: 18px;
  height: 5.8rem;
`;

export const Column = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
  justify-content: ${({ textAlign }) => textAlign || 'center'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  flex-basis: 100%;
  display: flex;
  gap: ${({ gap }) => gap || '1.5rem'};
  position: relative;
`;

export const TruncateText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 1 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const NotesContainer = styled.div`
  max-width: 25rem;
`;

export const Name = styled.div`
  ${({ theme }) => theme.new.typography.p4SemiBold};
  display: flex;
  color: ${({ theme }) => theme.new.colors.black};
`;

export const Box = styled.div`
  ${({ theme }) => theme.new.typography.p3SemiBold};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 1.2rem;
  gap: 1rem;
  background: ${({ theme }) => theme.new.colors.brandLight};
  color: ${({ theme }) => theme.new.colors.brandDark};
  border-radius: 4rem;
`;

export const Margin = styled.div`
  ${({ theme }) => theme.new.typography.p3SemiBold};
  background: ${getMarginColorBackground};
  color: ${getMarginColor};
  border: 1px solid ${getMarginColorBorder};
  border-radius: 4rem;
  align-items: center;
  padding: 0.4rem 1.4rem;
  gap: 1rem;
`;
