import styled from 'styled-components';

export const TagsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  width: 100%;
`;

export const RemainingTechnologies = styled.div`
  position: relative;
  height: 2.4rem;
  width: 3.6rem;
`;
