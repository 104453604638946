import { defineMessages } from 'react-intl';

export const assignmentMessages = defineMessages({
  assignment: {
    personId: {
      label: { id: 'common.resource' },
      placeholder: { id: 'assignment.form.resource.placeholder' }
    },
    role: {
      label: { id: 'common.role' },
      placeholder: { id: 'assignment.form.role.placeholder' }
    },
    seniority: {
      label: { id: 'common.seniority' },
      placeholder: { id: 'assignment.form.seniority.placeholder' }
    },
    skillIds: {
      label: { id: 'common.skills' },
      placeholder: { id: 'assignment.form.skills.placeholder' }
    },
    rate: {
      label: { id: 'assignment.form.rate' },
      placeholder: { id: 'common.cap.rateValue' }
    },
    workHours: {
      label: { id: 'assignment.form.workHours' },
      placeholder: { id: 'assignment.form.workHours.placeholder' }
    },
    percentage: {
      label: { id: 'assignment.form.percentage' },
      placeholder: { id: 'assignment.form.percentage.placeholder' }
    },
    quantity: {
      label: { id: 'common.cap.assignments' },
      placeholder: '1'
    },
    startDate: { id: 'common.cap.startDate' },
    endDate: { id: 'common.cap.endDate' },
    isBillable: { id: 'form.isBillable' },
    pending: { id: 'form.pending' }
  },
  note: { id: 'common.notes' }
});
