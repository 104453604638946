import styled from 'styled-components';

import { BUTTON_VARIANTS } from 'constants/constants';

import {
  SubmitButton,
  PrimaryButton,
  SecondaryButton,
  ApplyButton,
  ClearButton,
  NewPrimaryButton,
  NewSecondaryButton
} from './variants';

export const LoadingContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: block;
  height: fit-content;
  left: auto;
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0.8);
`;

const buttonVariant = variant =>
  ({
    [BUTTON_VARIANTS.SUBMIT]: SubmitButton,
    [BUTTON_VARIANTS.PRIMARY]: PrimaryButton,
    [BUTTON_VARIANTS.SECONDARY]: SecondaryButton,
    [BUTTON_VARIANTS.APPLY]: ApplyButton,
    [BUTTON_VARIANTS.CLEAR]: ClearButton,
    [BUTTON_VARIANTS.NEW_PRIMARY]: NewPrimaryButton,
    [BUTTON_VARIANTS.NEW_SECONDARY]: NewSecondaryButton
  }[variant]);

export const StyledButton = styled.button`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  gap: 0.8rem;
  justify-content: center;
  ${({ variant }) => variant && buttonVariant(variant)};
  padding: ${({ padding }) => padding};
`;
