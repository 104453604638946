import times from 'lodash/times';
import { instanceOf, oneOfType, string } from 'prop-types';
import { useMemo } from 'react';

import { fragmentFlex } from 'utils/timelineUtilities';

import { TimelineGridContainer, Grid, GridTrackSpace } from './styles';

const TimelineGrid = ({ startDate, endDate }) => {
  const Grids = useMemo(
    () =>
      times(fragmentFlex(startDate, endDate), key => (
        <Grid key={`grid-${key}`} isUnderline={!((key + 1) % 7)} />
      )),
    [startDate, endDate]
  );

  return (
    <TimelineGridContainer>
      <GridTrackSpace />
      {Grids}
    </TimelineGridContainer>
  );
};

TimelineGrid.propTypes = {
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)])
};

export default TimelineGrid;
