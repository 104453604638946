import dayjs from 'dayjs';

export const getForecastingGraphData = yearMonths =>
  yearMonths?.map((month, index) => {
    const underContractDiff = month.underContract - month.actualRevenue;
    const awaitingStartDiff = month.awaitingStart - underContractDiff;

    return {
      ...month,
      underContractDiff,
      awaitingStartDiff,
      name: dayjs().set('month', index).format('MMM, YYYY')
    };
  });
