import { node, func } from 'prop-types';
import { components } from 'react-select';

import { SelectIconWrapper } from './styles';

const ValueContainer = ({ Icon, children, ...props }) => (
  <components.ValueContainer {...props}>
    {Icon && (
      <SelectIconWrapper>
        <Icon />
      </SelectIconWrapper>
    )}
    <div>{children}</div>
  </components.ValueContainer>
);

ValueContainer.propTypes = {
  Icon: func,
  children: node
};

export default ValueContainer;
