import { func, number } from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { ReactComponent as CircleClock } from 'assets/icons/circleClock.svg';
import { ReactComponent as CircleDialogue } from 'assets/icons/circleDialogue.svg';
import { ReactComponent as CircleStar } from 'assets/icons/circleStar.svg';
import { ENGLISH_OPTIONS } from 'constants/constants';
import { reportsProfileShape } from 'constants/shapes';
import { useDispatch, useStatus } from 'hooks';
import { editResourceProfile } from 'state/actions/resourceProfileActions';

import BasicInfoPanel from '../BasicInfoPanel';
import EditDetailsModal from '../EditDetailsModal';
import ItemDetail from '../ItemDetail';
import { Container, DetailsHeader, EditIcon, DetailsContent, Horizontal, InfoItem } from './styles';

const LeftPanel = ({ data, personId, getLeftPanelData }) => {
  const intl = useIntl();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const {
    new: { colors }
  } = useTheme();
  const { mainSkills, englishLevel, weekHours, department, leadersFullName, figmaBio } = data || {};

  const englishLevelLabelId = useMemo(
    () => englishLevel && ENGLISH_OPTIONS.find(({ key }) => key === englishLevel)?.label,
    [englishLevel]
  );

  const editResourceProfileRequest = useDispatch(editResourceProfile);
  const { isLoading: isLoadingEdit, isFulfilled, error } = useStatus(editResourceProfile);

  useEffect(() => {
    if (isFulfilled) {
      getLeftPanelData();
      setIsEditModalOpen(false);
    }
  }, [isFulfilled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isEditModalOpen && (
        <EditDetailsModal
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          initialData={{ mainSkills, englishLevel, figmaBio }}
          editDetailRequest={payload => editResourceProfileRequest({ id: personId, payload })}
          isLoading={isLoadingEdit}
          error={error}
          departmentId={department?.id}
        />
      )}
      <Container>
        <BasicInfoPanel data={data} margin="2.7rem" />
        <section>
          <DetailsHeader>
            <h3>{intl.formatMessage({ id: 'common.resourceDetails' })}</h3>
            <button type="button" onClick={() => setIsEditModalOpen(true)}>
              <EditIcon />
            </button>
          </DetailsHeader>
          <Horizontal color={colors.brand} height="2px" margin="1.6rem 0" />
          <DetailsContent>
            <ItemDetail
              icon={<CircleStar />}
              title={intl.formatMessage({ id: 'common.cap.mainSkill' })}
            >
              {mainSkills?.length
                ? mainSkills.map(({ name, time }) => (
                    <div key={name}>
                      {name} <span>{`| ${time}`}</span>
                    </div>
                  ))
                : '--'}
            </ItemDetail>
            <ItemDetail
              icon={<CircleDialogue />}
              title={intl.formatMessage({ id: 'common.cap.englishLevel' })}
            >
              {englishLevelLabelId ? intl.formatMessage({ id: englishLevelLabelId }) : '--'}
            </ItemDetail>
            <ItemDetail
              icon={<CircleClock />}
              title={intl.formatMessage({ id: 'reports.hoursWeek' })}
            >
              {weekHours
                ? `${weekHours} ${intl.formatMessage({ id: 'common.lower.hours' })}`
                : '--'}
            </ItemDetail>
          </DetailsContent>
        </section>
        <Horizontal color={colors.neutral300} margin="2.5rem 0" />
        <section>
          <InfoItem>
            <p>{`${intl.formatMessage({ id: 'common.department' })}: `}</p>
            <p>{department?.name || '--'}</p>
          </InfoItem>
          <InfoItem>
            <p>{`${intl.formatMessage({ id: 'common.cap.reportsTo' })}: `}</p>
            <p>{leadersFullName || '--'}</p>
          </InfoItem>
        </section>
        <Horizontal color={colors.neutral300} margin="2.5rem 0" />
      </Container>
    </>
  );
};

LeftPanel.propTypes = {
  data: reportsProfileShape,
  personId: number,
  getLeftPanelData: func
};

export default LeftPanel;
