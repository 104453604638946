import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Accordion from 'components/common/Accordion';
import LoadingWrapper from 'components/common/LoadingWrapper';
import Table from 'components/common/Table';
import Tooltip from 'components/common/Tooltip';
import Filters from 'components/dashboard/Filters/Assignment';
import UtilizationTooltip from 'components/dashboard/UtilizationTooltip';
import { areasInformation } from 'constants/utilizationConstants';
import { useDashboardColumnsPresets, useRequest } from 'hooks';
import { getDashboardResources } from 'state/actions/dashboardActions';
import { getFirstAndLastDayOfCurrentMonth } from 'utils/date';
import { mergeAreasUtilizationInfo, getMaxUtilizationPercentage } from 'utils/utilizationUtilities';

import {
  BarsContainer,
  BarLegend,
  Bar,
  AreaTitle,
  AreaUtilizationPercentage,
  BarWrapper,
  BarArea,
  TableContainer,
  DashboardAssignmentContainer
} from './styles';

const month = getFirstAndLastDayOfCurrentMonth();

const initialParams = {
  startDate: month.startDate,
  endDate: month.endDate
};

const DashboardAssignment = () => {
  const [utilizations, setUtilizations] = useState({});
  const [selectedAreaId] = useState(areasInformation.engineering.utilizationId);
  const [tooltipUtilization, setTooltipUtilization] = useState();
  const [{ isPending: isUtilizationPending }, resourceRequest] = useRequest(getDashboardResources, {
    fulfilledCallback: payload => {
      setUtilizations(payload.utilizations);
    },
    params: initialParams
  });

  const maxUtilizationPercentage = getMaxUtilizationPercentage(utilizations);
  const utilizationsWithInfo = Object.entries(mergeAreasUtilizationInfo(utilizations));
  const columns = useDashboardColumnsPresets();

  const handleClearFilters = () => {
    resourceRequest(initialParams);
  };

  if (isEmpty(utilizationsWithInfo)) return null;

  const [, selectedUtilizations] = utilizationsWithInfo.find(
    ([, utilization]) => utilization?.info?.utilizationId === selectedAreaId
  );

  const selectedRolesUtilizations = Object.entries(selectedUtilizations?.rolesUtilizations).map(
    ([, role]) => ({ role: role.name, ...role })
  );

  const selectedSkillsUtilizations = Object.entries(selectedUtilizations?.skillsUtilizations).map(
    ([, skill]) => ({ skill: skill.name, ...skill })
  );

  return (
    <DashboardAssignmentContainer>
      <LoadingWrapper isLoading={isUtilizationPending}>
        <Filters onRequest={resourceRequest} onClear={handleClearFilters} />
        <BarsContainer>
          {utilizationsWithInfo.map(
            ([key, utilization]) =>
              utilization?.info && (
                <BarWrapper current={utilization?.info?.utilizationId === selectedAreaId} key={key}>
                  <BarLegend>
                    <AreaTitle>
                      <FormattedMessage id={utilization?.info?.nameId || ''} />
                      {` (${utilization.totalResources})`}
                    </AreaTitle>
                    <AreaUtilizationPercentage>
                      {utilization.utilizationPercentage}%
                    </AreaUtilizationPercentage>
                  </BarLegend>
                  <BarArea>
                    <Bar
                      data={utilization}
                      maxUtilizationPercentage={maxUtilizationPercentage}
                      data-tip=""
                      onMouseEnter={() => setTooltipUtilization(utilization)}
                      onMouseLeave={() => setTooltipUtilization()}
                    />
                  </BarArea>
                </BarWrapper>
              )
          )}
        </BarsContainer>
        {tooltipUtilization && (
          <Tooltip place="top" type="light" effect="float">
            <UtilizationTooltip utilization={tooltipUtilization} />
          </Tooltip>
        )}
        <Accordion titleId="dashboard.roleUtilization">
          <TableContainer>
            <Table columns={columns.rolesUtilizations} data={selectedRolesUtilizations} />
          </TableContainer>
        </Accordion>
        <Accordion titleId="dashboard.skillsUtilization">
          <TableContainer>
            <Table columns={columns.skillsUtilizations} data={selectedSkillsUtilizations} />
          </TableContainer>
        </Accordion>
      </LoadingWrapper>
    </DashboardAssignmentContainer>
  );
};

export default DashboardAssignment;
