import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 3rem;
  height: auto;
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
  overflow-y: auto;
`;

export const StyledResponsiveContainer = styled(ResponsiveContainer)`
  overflow: hidden;

  .recharts-wrapper .recharts-cartesian-grid-vertical line:first-child,
  .recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
    stroke-opacity: 0;
  }
`;
