import { string, arrayOf, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { parseInputErrors } from 'utils/helpers';

import { TextAreaContainer, ErrorSpan, StyledTextArea, Label } from './styles';

const NewTextArea = ({ label, name, errors, value, rows = 1, className = '', ...props }) => (
  <TextAreaContainer errors={errors} className={className}>
    {label && (
      <Label errors={errors} value={value} htmlFor={name}>
        {label}
      </Label>
    )}
    <StyledTextArea id={name} name={name} value={value} rows={rows} errors={errors} {...props} />
    {errors && (
      <ErrorSpan>
        <FormattedMessage id={parseInputErrors(errors)} defaultMessage={parseInputErrors(errors)} />
      </ErrorSpan>
    )}
  </TextAreaContainer>
);

NewTextArea.propTypes = {
  name: string.isRequired,
  rows: number,
  label: string,
  errors: arrayOf(string),
  value: string,
  className: string
};

export default NewTextArea;
