import { number } from 'prop-types';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

const CustomizedLabelY = ({ x = 0, y = 0 }) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        dx={0}
        fill={theme.new.colors.N600}
        fontSize={13}
        fontWeight={theme.fontWeights.semiBold}
        fontFamily={theme.new.fontFamily}
      >
        {intl.formatMessage({ id: 'common.revenue' })}
      </text>
    </g>
  );
};

CustomizedLabelY.propTypes = {
  x: number,
  y: number
};

export default CustomizedLabelY;
