import { bool } from 'prop-types';
import { useState } from 'react';

import LoadingWrapper from 'components/common/LoadingWrapper';
import EditAssignmentModal from 'components/timeline/EditAssignmentModal';
import useUnassignedSkills from 'hooks/people/useUnassignedSkills';
import useSkillsAssignments from 'hooks/selectors/useSkillsAssignments';

import SkillRow from './SkillRow';
import { Container } from './styles';

const UnassignedSkillsTable = ({ isLoading }) => {
  const [editAssignment, setEditAssignment] = useState();
  const skillsAssignments = useSkillsAssignments();
  const { unassignedSkillsShown, expandedUnassignedSkills } = useUnassignedSkills();

  if (!unassignedSkillsShown) return null;

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Container>
        <div>
          {skillsAssignments?.map(skillAssignment => (
            <SkillRow
              key={skillAssignment.id}
              skillAssignment={skillAssignment}
              isExpanded={expandedUnassignedSkills.includes(skillAssignment.id)}
              setEditAssignment={setEditAssignment}
            />
          ))}
        </div>
        {editAssignment && (
          <EditAssignmentModal
            isShowing={!!editAssignment}
            hide={() => setEditAssignment(null)}
            projectId={editAssignment.projectId}
            assignmentId={editAssignment.assignmentId}
          />
        )}
      </Container>
    </LoadingWrapper>
  );
};

UnassignedSkillsTable.propTypes = {
  isLoading: bool
};

export default UnassignedSkillsTable;
