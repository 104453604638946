import { Helmet } from 'react-helmet';
import { Redirect, Switch, BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import Navbar from 'components/common/Navbar';
import RouteFromPath from 'components/routes/RouteFromPath';
import { ROLES } from 'constants/permissions';
import routesPaths from 'constants/routesPaths';
import { useRole, useSession } from 'hooks';
import NotFound from 'components/common/NotFound';

import theme from '../../constants/theme';
import { publicRoutes, privateRoutes } from '../../routes';
import Sidebar from '../common/SideBar';
import PrivateRoute from '../routes/PrivateRoute';
import { Content, Layout } from './styles';

const App = () => {
  const { authenticated } = useSession();
  const { role, temporalRoles } = useRole();

  const basicUser = role === ROLES.basic && !temporalRoles.length && authenticated;

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>Forecast</title>
        </Helmet>
        <Router>
          <Navbar />
          <Layout>
            <Sidebar />
            <Content>
              <Switch>
                {basicUser && (
                  <Redirect exact from={routesPaths.index} to={privateRoutes[role][0]?.path} />
                )}
                {role && role !== ROLES.basic && privateRoutes[role] && (
                  <Redirect exact from={routesPaths.index} to={privateRoutes[role][0]?.path} />
                )}
                {!!temporalRoles?.length &&
                  temporalRoles?.map(
                    ({ group }) =>
                      privateRoutes[group] && (
                        <Redirect
                          exact
                          from={routesPaths.index}
                          to={privateRoutes[group][0]?.path}
                        />
                      )
                  )}
                {publicRoutes.map((route, index) => (
                  <RouteFromPath key={`route${index}`} {...route} />
                ))}
                {privateRoutes[role]?.map((route, index) => (
                  <RouteFromPath
                    key={`route${index}`}
                    {...route}
                    privateRoute
                    authenticated={authenticated}
                  />
                ))}
                {!!temporalRoles?.length &&
                  temporalRoles?.map(({ group }) =>
                    privateRoutes[group]?.map((route, index) => (
                      <RouteFromPath
                        key={`route${index}`}
                        {...route}
                        privateRoute
                        authenticated={authenticated}
                      />
                    ))
                  )}

                <PrivateRoute exact path={routesPaths.index} authenticated={authenticated}>
                  <CenteredLoading />
                </PrivateRoute>
                <PrivateRoute authenticated={authenticated}>
                  <NotFound />
                </PrivateRoute>
              </Switch>
            </Content>
          </Layout>
        </Router>
      </>
    </ThemeProvider>
  );
};

export default App;
