import { bool, func, number } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import Button from 'components/common/Button';
import NewModal from 'components/common/NewModal';
import Select from 'components/common/Select';
import ExperienceInputs from 'components/Reports/ResourceProfile/ExperienceInputs';
import { BUTTON_VARIANTS } from 'constants/constants';
import { technicalSkillsValidations } from 'constants/validators';
import { useDispatch, useSkills, useStatus } from 'hooks';
import { addResourceTechnicalSkills } from 'state/actions/peopleActions';
import { filterSkillsByDepartment } from 'utils/helpers';
import { composeAddTechnicalSkill } from 'utils/resourceProfileUtilities';

import { CheckboxWrapper, ExperienceContainer, StyledCheckbox, Row } from './styles';

const fields = {
  value: 'value',
  years: 'years',
  months: 'months'
};

const TechnicalSkillModal = ({ handleAdd, handleToggle, isShowing, departmentId }) => {
  const intl = useIntl();
  const { skillOptions } = useSkills();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control
  } = useForm();
  const [checked, setChecked] = useState(false);
  const resetAddResourceTechnicalSkills = useDispatch(addResourceTechnicalSkills.reset);
  const { error: errorAddResourceTechnicalSkills } = useStatus(addResourceTechnicalSkills);

  const departmentSkillsOptions = filterSkillsByDepartment(skillOptions, departmentId);

  const onSubmit = async data => {
    const result = await handleAdd(composeAddTechnicalSkill(data));
    if (result) {
      reset();
      if (!checked) {
        handleToggle();
      }
    }
  };

  useEffect(() => {
    reset();
    return () => resetAddResourceTechnicalSkills();
  }, [reset, resetAddResourceTechnicalSkills]);

  return (
    <NewModal
      isShowing={isShowing}
      hide={handleToggle}
      title={intl.formatMessage({ id: 'modal.technicalSkill.title' })}
      maxWidth="65rem"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Controller
            name={fields.value}
            control={control}
            rules={technicalSkillsValidations[fields.value]}
            render={({ field }) => (
              <Select
                {...field}
                ref={undefined}
                customRef={field.ref}
                isSearchable
                options={departmentSkillsOptions}
                label={intl.formatMessage({ id: 'form.skillTechnology' })}
                fullWidth
                withoutHighlight
                enablePortal
                variant="new"
                errors={errors?.[fields.value]?.message}
              />
            )}
          />
          <ExperienceContainer>
            <ExperienceInputs
              register={register}
              validations={technicalSkillsValidations}
              errors={errors}
            />
          </ExperienceContainer>
        </Row>
        <CheckboxWrapper>
          <StyledCheckbox
            value={checked}
            onChange={() => setChecked(!checked)}
            label={intl.formatMessage({ id: 'reports.resource.editDetails.addSkill' })}
            variant="new"
          />
        </CheckboxWrapper>
        {errorAddResourceTechnicalSkills && (
          <AlertMessage>{errorAddResourceTechnicalSkills}</AlertMessage>
        )}
        <Row>
          <Button
            id="cancelBtn"
            type="button"
            textIntlId="common.cancel"
            onClick={handleToggle}
            variant={BUTTON_VARIANTS.NEW_SECONDARY}
          />
          <Button
            id="saveBtn"
            type="submit"
            textIntlId="common.addSkill"
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
          />
        </Row>
      </form>
    </NewModal>
  );
};

TechnicalSkillModal.propTypes = {
  handleAdd: func,
  handleToggle: func,
  isShowing: bool,
  departmentId: number
};

export default TechnicalSkillModal;
