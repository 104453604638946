import { func, arrayOf, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { tabShape } from 'constants/shapes';

import { Container, TabButton } from './styles';

function TabButtons({ setActiveTab, activeTab, tabs, className }) {
  const intl = useIntl();

  return (
    <Container className={className}>
      {tabs?.map(({ id, intlId, intlVar }) => (
        <TabButton key={id} active={activeTab === id} onClick={() => setActiveTab(id)}>
          {intl.formatMessage({ id: intlId }, { ...intlVar })}
        </TabButton>
      ))}
    </Container>
  );
}

TabButtons.propTypes = {
  setActiveTab: func,
  activeTab: string,
  tabs: arrayOf(tabShape),
  className: string
};

export default TabButtons;
