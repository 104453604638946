import { object, oneOfType, string } from 'prop-types';
import { useFormContext } from 'react-hook-form';

import AlertMessage from 'components/common/AlertMessage';
import { VerticalSeparator } from 'components/common/Layout/Separator';
import WarningList from 'components/common/WarningList';
import { REJECTED } from 'constants/actionStatusConstants';
import { useHeights, usePersonExperiences } from 'hooks';

import { WarningWrapper } from './styles';

const AssignmentFormAlerts = ({ error, status }) => {
  const { separatorHeight } = useHeights();
  const { watch } = useFormContext();
  const { personId, skillIds } = watch();
  const { personExperiences, personExperiencesLoading } = usePersonExperiences(personId?.value.id);

  return (
    <>
      <WarningWrapper>
        <WarningList
          personExperiencesLoading={personExperiencesLoading}
          personExperiences={personExperiences}
          selectedSkills={skillIds}
        />
      </WarningWrapper>
      {status === REJECTED && (
        <>
          <AlertMessage>{error}</AlertMessage>
          <VerticalSeparator height={separatorHeight} />
        </>
      )}
    </>
  );
};

AssignmentFormAlerts.propTypes = {
  error: oneOfType([string, object]),
  status: string
};

export default AssignmentFormAlerts;
