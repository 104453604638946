import { number, shape } from 'prop-types';
import { useTheme } from 'styled-components';

const YAxisTick = ({ x = 0, y = 0, payload }) => {
  const theme = useTheme();

  return (
    <g transform={`translate(${x},${y})`}>
      <circle cx={-30} cy={-10} r="20" fill={theme.new.colors.P100} />
      <text
        x={0}
        y={0}
        dy={-4}
        dx={-29}
        fill={theme.new.colors.P800}
        fontSize={16}
        fontWeight={theme.fontWeights.normal}
        fontFamily={theme.new.fontFamily}
        textAnchor="middle"
      >
        {payload.index + 1}
      </text>
    </g>
  );
};

YAxisTick.propTypes = {
  payload: shape({ index: number }),
  x: number,
  y: number
};

export default YAxisTick;
