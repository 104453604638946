import { node, func, bool, string } from 'prop-types';
import { memo } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import ReactPortal from 'components/HOC/ReactPortal';

import {
  ModalButton,
  ModalContainer,
  ModalContent,
  ContentWrapper,
  ModalTitle,
  ModalSubTitle
} from './styles';

const Modal = ({
  children,
  title,
  titleId,
  subTitle,
  isShowing,
  hide,
  closeHidden = false,
  withoutOverflow = false,
  onClick,
  contentPadding,
  isOverlapped = false,
  ...props
}) =>
  isShowing && (
    <ReactPortal>
      <ModalContainer
        isOverlapped={isOverlapped}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContent onClick={onClick}>
          <ContentWrapper withoutOverflow={withoutOverflow} padding={contentPadding} {...props}>
            {!closeHidden && (
              <ModalButton onClick={hide}>
                <CloseIcon id="closeBtn" />
              </ModalButton>
            )}
            <ModalTitle id={titleId}>{title}</ModalTitle>
            {subTitle && <ModalSubTitle>{subTitle}</ModalSubTitle>}
            {children}
          </ContentWrapper>
        </ModalContent>
      </ModalContainer>
    </ReactPortal>
  );

Modal.propTypes = {
  children: node.isRequired,
  isShowing: bool.isRequired,
  hide: func.isRequired,
  closeHidden: bool,
  title: string,
  titleId: string,
  subTitle: string,
  onClick: func,
  contentPadding: string,
  isOverlapped: bool,
  withoutOverflow: bool
};

export default memo(Modal);
