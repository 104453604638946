import { number, shape } from 'prop-types';
import { useTheme } from 'styled-components';

const XAxisTick = ({ x = 0, y = 0, payload }) => {
  const theme = useTheme();

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        fill={theme.fontColor}
        dx={-9}
        dy={16}
        fontSize={14}
        fontWeight={theme.fontWeights.medium}
        fontFamily={theme.fontFamily}
      >
        {`${payload.value}%`}
      </text>
    </g>
  );
};

XAxisTick.propTypes = {
  payload: shape({ value: number }),
  x: number,
  y: number
};

export default XAxisTick;
