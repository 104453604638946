import { shape, string, arrayOf } from 'prop-types';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { TabOption, TabsContainer } from './styles';

const Tabs = ({ tabs }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const selectedTabIndex = tabs.findIndex(tab => tab.route === pathname);

  const disabled = tabs.length < 2;

  return (
    <TabsContainer>
      {tabs.map(({ route, title }, index) => (
        <Fragment key={title}>
          <TabOption selected={index === selectedTabIndex} to={route} disabled={disabled}>
            {intl.formatMessage({ id: title })}
          </TabOption>
        </Fragment>
      ))}
    </TabsContainer>
  );
};

Tabs.propTypes = {
  tabs: arrayOf(
    shape({
      route: string.isRequired,
      title: string.isRequired
    })
  )
};

export default Tabs;
