import styled, { css } from 'styled-components';

const headerWidth = css`
  ${({ theme }) => `calc(${theme.constantWidths.timelineHeader} - 0.3rem )`}
`;

export const AssignmentHeader = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 1.2rem 0.8rem 1.2rem 5.2rem;
  max-width: ${headerWidth};
  width: 100%;
  padding-top: ${({ isFirst }) => isFirst && '2.8rem'};
  background: ${({ theme }) => theme.new.colors.N100};
`;

export const ArrowButton = styled.button`
  visibility: ${({ $isHidden }) => ($isHidden ? 'hidden' : 'visible')};
`;

export const AssignmentRow = styled.div`
  width: 100%;
  display: flex;
`;

export const NewAssignmentRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  padding: 1.2rem 5.2rem 2.8rem 5.2rem;
  max-width: ${headerWidth};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  background: ${({ theme }) => theme.new.colors.N100};
`;
