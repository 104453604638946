import { object } from 'prop-types';
import Loader from 'react-loaders';
import { withTheme } from 'styled-components';

const Loading = ({ theme }) => (
  <div className="loading-wrapper">
    <Loader type="line-scale-pulse-out" active color={theme.colors.loader} />
  </div>
);

Loading.propTypes = {
  theme: object
};

export default withTheme(Loading);
