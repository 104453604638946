import { bool, func, number } from 'prop-types';
import { useIntl } from 'react-intl';

import NewModal from 'components/common/NewModal';
import ProjectForm from 'components/timeline/ProjectForm';
import { useDispatch, useStatus } from 'hooks';
import { createProject, editProject } from 'state/actions/projectActions';

const ProjectModal = ({
  isShowing,
  hide,
  edit = false,
  setAddAssignmentTo,
  handleRefresh,
  projectId
}) => {
  const intl = useIntl();
  const action = edit ? editProject : createProject;
  const projectRequest = useDispatch(action);
  const resetStatusRequest = useDispatch(action.reset);
  const { status, error } = useStatus(action);

  const resetStatusAndHide = () => {
    resetStatusRequest();
    hide();
  };

  return (
    <NewModal
      isShowing={isShowing}
      hide={resetStatusAndHide}
      contentPadding="2rem 3.4rem 3rem 3.4rem"
      title={
        edit
          ? intl.formatMessage({ id: 'projects.editProject' })
          : intl.formatMessage({ id: 'common.newProject' })
      }
      titleId={!edit ? 'newProjectLbl' : undefined}
      maxWidth="64rem"
    >
      <ProjectForm
        onSubmit={projectRequest}
        status={status}
        hide={resetStatusAndHide}
        error={error}
        edit={edit}
        setAddAssignmentTo={setAddAssignmentTo}
        handleRefresh={handleRefresh}
        projectId={projectId}
      />
    </NewModal>
  );
};

ProjectModal.propTypes = {
  edit: bool,
  hide: func.isRequired,
  isShowing: bool,
  setAddAssignmentTo: func,
  handleRefresh: func,
  projectId: number
};

export default ProjectModal;
