import { bool, func, string } from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { POSITIONS_TYPES, THREE_DOTS_VARIANTS } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { periodShape } from 'constants/shapes';
import { useConfirm, useDispatch, useRole } from 'hooks';
import { closeOpenPeriod, deleteOpenPeriod } from 'state/actions/financialActions';
import hasPermission from 'utils/hasPermission';

import { OptionButtonStyled } from './styles';

const OptionsPeriodButton = ({
  period,
  editPeriodInfo,
  onChangeEditingPeriod,
  handleOnClickOption,
  isEditing,
  className = ''
}) => {
  const intl = useIntl();
  const deleteRequest = useDispatch(deleteOpenPeriod);
  const closeRequest = useDispatch(closeOpenPeriod);

  const { permissions } = useRole();

  const [ConfirmationDelete, confirmDelete] = useConfirm({
    title: intl.formatMessage({ id: 'modal.openPeriod.delete.title' }),
    onConfirm: () =>
      deleteRequest({
        periodId: period?.id
      }),
    confirmLabelIntlId: 'common.delete',
    cancelLabelIntlId: 'common.cancel'
  });

  const [ConfirmationClose, confirmClose] = useConfirm({
    title: intl.formatMessage({ id: 'modal.openPeriod.close.title' }),
    message: intl.formatMessage({ id: 'modal.openPeriod.close.message' }),
    onConfirm: () =>
      closeRequest({
        periodId: period?.id,
        projectId: period.projectId
      }),
    confirmLabelIntlId: 'modal.openPeriod.close.accept',
    cancelLabelIntlId: 'common.cancel'
  });

  const periodOptions = useMemo(() => {
    const deleteOption = {
      label: intl.formatMessage({ id: 'common.delete' }),
      onClick: () => handleOnClickOption(confirmDelete)
    };

    const editOption = {
      label: intl.formatMessage({ id: 'financial.period.editBillingPeriod' }),
      onClick: () => handleOnClickOption(() => onChangeEditingPeriod(period.id))
    };

    const editInfoOption = {
      label: intl.formatMessage({ id: 'financial.period.changePeriodInfo' }),
      onClick: () => handleOnClickOption(() => editPeriodInfo(period))
    };

    const closeOption = {
      label: intl.formatMessage({ id: 'financial.closePeriod.options.close' }),
      onClick: () => handleOnClickOption(confirmClose)
    };

    const options = [
      ...(hasPermission(permissions, [SCOPES.editOpenPeriodsInfo]) ? [editInfoOption] : []),
      ...(hasPermission(permissions, [SCOPES.editOpenPeriods]) ? [editOption] : []),
      ...(hasPermission(permissions, [SCOPES.closeOpenPeriods]) ? [closeOption] : []),
      ...(hasPermission(permissions, [SCOPES.deleteOpenPeriods]) ? [deleteOption] : [])
    ];

    return options;
  }, [
    intl,
    permissions,
    period,
    confirmDelete,
    confirmClose,
    editPeriodInfo,
    onChangeEditingPeriod,
    handleOnClickOption
  ]);

  return (
    <>
      <OptionButtonStyled
        options={periodOptions}
        position={POSITIONS_TYPES.BOTTOM_LEFT}
        threeDotVariant={THREE_DOTS_VARIANTS.SMALL}
        className={className}
        $isEditing={isEditing}
      />
      <ConfirmationDelete textAlign="left" />
      <ConfirmationClose textAlign="left" />
    </>
  );
};

OptionsPeriodButton.propTypes = {
  className: string,
  editPeriodInfo: func,
  handleOnClickOption: func,
  isEditing: bool,
  onChangeEditingPeriod: func,
  period: periodShape
};

export default OptionsPeriodButton;
