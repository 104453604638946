import { createAction } from '@reduxjs/toolkit';

import AssignmentService from 'services/assignmentService';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

import { getPeopleAvailability, getPeopleByAssignment } from './peopleActions';

export const resetResources = createAction('RESET_ASSIGNMENT_RESOURCES');
export const updateNote = createAction('UPDATE_NOTE');

export const getAssignmentById = createAsyncThunk(
  'GET_ASSIGNMENT_BY_ID',
  async ({ assignmentId }, { dispatch }) => {
    try {
      const { data } = await AssignmentService.getAssignmentById(assignmentId);

      dispatch(getPeopleByAssignment(assignmentId));
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const editAssignment = createAsyncThunk(
  'EDIT_ASSIGNMENT',
  async ({ assignmentId, assignment, note }, { getState, dispatch }) => {
    try {
      const {
        timeline: { startDate, endDate }
      } = getState();
      const { data } = await AssignmentService.editAssignment({
        assignmentId,
        assignment,
        note
      });
      dispatch(getPeopleAvailability({ startDate, endDate }));
      return { data, starDateTimeline: startDate, endDateTimeline: endDate };
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const EditQuickAssignment = createAsyncThunk(
  'EDIT_QUICK_ASSIGNMENT',
  async ({ assignment, assignmentId }) => {
    try {
      const { startDate, endDate } = assignment;

      const { data } = await AssignmentService.editAssignment({
        assignmentId,
        assignment,
        startDate,
        endDate
      });

      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const confirmAssignment = createAsyncThunk(
  'CONFIRM_ASSIGNMENT',
  async ({ payload: { assignment, assignmentId }, startDate, endDate }) => {
    try {
      const { data } = await AssignmentService.editAssignment({
        assignmentId,
        assignment,
        startDate,
        endDate
      });

      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const deleteAssignment = createAsyncThunk(
  'DELETE_ASSIGNMENT',
  async ({ assignmentId, personId, projectId }) => {
    try {
      await AssignmentService.deleteAssignment({
        assignmentId
      });
      return { assignmentId, personId, projectId };
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const createAssignment = createAsyncThunk(
  'CREATE_ASSIGNMENT',
  async ({ assignment, quantity, note }, { getState }) => {
    try {
      const {
        project: { projects },
        timeline: { startDate, endDate }
      } = getState();
      const { data } = await AssignmentService.createAssignment({
        assignment,
        quantity,
        note
      });
      const project = projects.find(project => project.id === data.projectId);
      return { data, project, starDateTimeline: startDate, endDateTimeline: endDate };
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const editNote = createAsyncThunk(
  'EDIT_NOTE',
  async ({ payload: { noteId, note } }, { dispatch }) => {
    try {
      const { data } = await AssignmentService.editNotes({ noteId, note });
      dispatch(updateNote(data));
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const addAssignment = createAsyncThunk('ADD_ASSIGNMENT', async payload => {
  try {
    const { data } = await AssignmentService.createAssignment(payload);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const isolatedEditAssignment = createAsyncThunk(
  'EDIT_ASSIGNMENT_ISOLATED',
  async payload => {
    try {
      const { data } = await AssignmentService.editAssignment(payload);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const isolatedDeleteAssignment = createAsyncThunk(
  'DELETE_ASSIGNMENT_ISOLATED',
  async payload => {
    try {
      const { data } = await AssignmentService.deleteAssignment(payload);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const isolatedConfirmAssignment = createAsyncThunk(
  'CONFIRM_ASSIGNMENT_ISOLATED',
  async payload => {
    try {
      const { data } = await AssignmentService.editAssignment(payload);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);
