import { useIntl } from 'react-intl';

import EmptyState from 'components/common/EmptyState';

const FinancialReporting = () => {
  const intl = useIntl();

  return (
    <EmptyState
      title={intl.formatMessage({ id: 'common.workingOn' })}
      subtitle={intl.formatMessage({ id: 'emptyState.underConstruction.subtitle' })}
    />
  );
};

export default FinancialReporting;
