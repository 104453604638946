import { arrayOf, func, instanceOf, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as ProjectionIcon } from 'assets/icons/projection.svg';
import EmptyState from 'components/common/EmptyState';
import MiniTimelineAllocation from 'components/timeline/MiniTimelineAllocation';
import { COLUMNS, COLUMNS_KEYS } from 'constants/benchInsights';
import { SORT_DIR } from 'constants/constants';
import { benchListResource, sortConfigShape } from 'constants/shapes';
import { showMultipleData } from 'utils/projectsOverviewUtilities';

import BasicInfoColumn from './BasicInfoColumn';
import {
  ArrowIcon,
  AvailabilityContainer,
  Column,
  Container,
  HeaderColumnContainer,
  IconContainer,
  Row,
  Table
} from './styles';

const BenchListTable = ({
  rows = [],
  setResourceModalPersonId,
  dateParams,
  sortConfig,
  onSort
}) => {
  const intl = useIntl();

  const { startDate, endDate } = dateParams;

  return (
    <Container>
      <Table>
        <thead>
          <Row isHeader>
            {COLUMNS.map(column => {
              const isSorting = sortConfig && sortConfig.key === column.key;
              return (
                <th key={column.key} align="left">
                  <HeaderColumnContainer
                    onClick={column.key !== COLUMNS_KEYS.availability ? () => onSort(column) : null}
                    $isMonthColumn={column.key === COLUMNS_KEYS.month}
                    disabled={column.key === COLUMNS_KEYS.availability || !rows.length}
                  >
                    <span>
                      {column.header && intl.formatMessage({ id: column.header })}
                      {column.key === COLUMNS_KEYS.mainInfo && ` (${rows.length})`}
                    </span>
                    {column.key !== COLUMNS_KEYS.availability && (
                      <ArrowIcon
                        direction={isSorting ? sortConfig.direction : SORT_DIR.desc}
                        $isSorting={isSorting}
                      />
                    )}
                  </HeaderColumnContainer>
                </th>
              );
            })}
            <td />
          </Row>
        </thead>
        <tbody>
          {rows?.length > 0 ? (
            rows?.map((item, index) => (
              <Row key={index} isGray={index % 2 === 0}>
                {Object.values(COLUMNS).map(({ key }) => {
                  const value = item[key];
                  return (
                    {
                      [COLUMNS_KEYS.mainInfo]: (
                        <Column key={`${index}-${key}`} isMainInfo>
                          <BasicInfoColumn
                            {...value}
                            viewProfile={() => setResourceModalPersonId(item.id)}
                          />
                        </Column>
                      ),
                      [COLUMNS_KEYS.mainSkills]: (
                        <Column key={`${index}-${key}`}>{showMultipleData(value)}</Column>
                      ),
                      [COLUMNS_KEYS.currentProjects]: (
                        <Column key={`${index}-${key}`}>{showMultipleData(value)}</Column>
                      ),
                      [COLUMNS_KEYS.availability]: (
                        <Column key={`${index}-${key}`}>
                          <AvailabilityContainer>
                            <MiniTimelineAllocation
                              timeOffs={value?.timeOffs}
                              assignments={value?.assignments}
                              startDate={startDate}
                              endDate={endDate}
                              capacity={value?.availableWorkHours}
                              specialEvents={value?.specialEvents}
                              width="14rem"
                              hireDate={value?.hireDate}
                              exitDate={value?.exitDate}
                              holidays={item?.holidays}
                            />
                            <IconContainer>
                              {item?.hasPendingAssignments && <ProjectionIcon />}
                            </IconContainer>
                          </AvailabilityContainer>
                        </Column>
                      )
                    }[key] || <Column key={`${index}-${key}`}>{value || '-'}</Column>
                  );
                })}
              </Row>
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <EmptyState
                  titleIntlId="emptyState.title.resources"
                  subtitleIntlId="emptyState.subtitle.resources"
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

BenchListTable.propTypes = {
  rows: arrayOf(benchListResource),
  setResourceModalPersonId: func,
  dateParams: shape({
    startDate: oneOfType([string, instanceOf(Date)]),
    endDate: oneOfType([string, instanceOf(Date)])
  }),
  sortConfig: sortConfigShape,
  onSort: func
};
export default BenchListTable;
