import { string, arrayOf, any, bool, node, oneOfType } from 'prop-types';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { parseInputErrors } from 'utils/helpers';

import {
  InputContainer,
  ErrorSpan,
  StyledInput,
  Label,
  InputContent,
  IconContainer,
  ActivePlaceholder
} from './styles';

const NewInput = forwardRef(
  /* eslint-disable prefer-arrow-callback */
  function NewInput(
    {
      label,
      name,
      errors,
      value,
      className = '',
      Icon,
      padding,
      fullWidth,
      activePlaceholder,
      type,
      ...props
    },
    ref
  ) {
    const validateKey = e => {
      if (type === 'number' && e.key.toLowerCase() === 'e') {
        e.preventDefault();
      }
    };

    return (
      <InputContainer errors={errors} className={className} padding={padding} fullWidth={fullWidth}>
        {label && (
          <Label errors={errors} htmlFor={name} value={value}>
            {label}
          </Label>
        )}
        <InputContent>
          {Icon && <IconContainer>{Icon}</IconContainer>}
          {activePlaceholder && (
            <ActivePlaceholder>
              <p>{activePlaceholder}</p>
            </ActivePlaceholder>
          )}
          <StyledInput
            errors={errors}
            name={name}
            value={value}
            fullWidth={fullWidth}
            $isWithIcon={Boolean(Icon)}
            $isWithActivePlaceholder={Boolean(activePlaceholder)}
            ref={ref}
            type={type}
            onKeyDown={validateKey}
            {...props}
          />
        </InputContent>
        {errors && (
          <ErrorSpan>
            <FormattedMessage
              id={parseInputErrors(errors)}
              defaultMessage={parseInputErrors(errors)}
            />
          </ErrorSpan>
        )}
      </InputContainer>
    );
  }
);

NewInput.propTypes = {
  name: string.isRequired,
  label: string,
  errors: oneOfType([arrayOf(string), string]),
  value: any,
  className: string,
  padding: string,
  fullWidth: bool,
  Icon: node,
  activePlaceholder: string,
  type: string
};

export default NewInput;
