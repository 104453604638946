import styled from 'styled-components';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import Button from 'components/common/Button';
import { SORT_DIR } from 'constants/constants';

export const TableWrapper = styled.div`
  display: grid;
  gap: 2.8rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  overflow-x: auto;
  transition: all 150ms ease-in-out;
  padding: 1.6rem 0;
  align-items: start;
  overflow: visible;
  th,
  td {
    max-width: 20rem;
    width: fit-content;
    position: relative;
  }
`;

export const ArrowIcon = styled(ArrowUp)`
  display: block;
  height: 1rem;
  transition: all 150ms ease-in-out;
  transform: ${({ direction }) =>
    direction === SORT_DIR.desc ? 'rotate(-180deg) translate(0, 10%)' : 'none'};
  width: 1.2rem;
  height: 1.2rem;
  opacity: ${({ $isSorting }) => ($isSorting ? '1' : '0.3')};
  path {
    stroke: ${({ theme, $isSorting }) =>
      $isSorting ? theme.new.colors.P500 : theme.new.colors.N900};
    stroke-width: 1.8px;
  }
  margin-left: 1.5rem;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const Column = styled.td`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.N700};
`;

export const HeaderColumnContainer = styled.button`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.N600};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 1.4rem;
  justify-content: start;
  width: fit-content;
`;

export const NewAssignmentButton = styled(Button)`
  border: none;
  color: ${({ theme }) => theme.new.colors.P500};
  ${({ theme }) => theme.new.typography.p4SemiBold};
  background: none;
  width: 20.5rem;
  height: 4.4rem;
  padding: 0;
  border-radius: 4px;

  svg {
    transform: ${`scale(${20 / 22})`};
    path {
      stroke-width: 2px;

      stroke: ${({ theme }) => theme.new.colors.P500};
    }
  }
`;

export const OptionsColumn = styled.th`
  opacity: 0;
  min-width: 5rem;
`;

export const NewAssignmentColumn = styled.tr`
  margin-top: 1.6rem;
  display: block;
`;
export const Row = styled.tr`
  height: ${({ height }) => height || '6.6rem'};
  text-align: left;
  width: 100%;
`;
