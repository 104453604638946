import { arrayOf, number, objectOf } from 'prop-types';

import { newCalendarShape } from 'constants/shapes';

import Calendar from './Calendar';
import { TimelineHeaderContainer, Corner } from './styles';

const NewTimelineHeader = ({ calendar, totalDays }) => (
  <TimelineHeaderContainer>
    <Corner />
    <Calendar calendar={calendar} totalDays={totalDays} />
  </TimelineHeaderContainer>
);

NewTimelineHeader.propTypes = {
  calendar: objectOf(arrayOf(newCalendarShape)),
  totalDays: number
};

export default NewTimelineHeader;
