import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';

class ResourceProfilesService {
  static async getDisciples() {
    return httpClient.get(ENDPOINTS.getDisciples);
  }

  static async getResourceProfile(id) {
    return httpClient.get(ENDPOINTS.getResourceProfile(id));
  }

  static async getTimeOffs(id) {
    return httpClient.get(ENDPOINTS.getResourceProfileTimeOffs(id));
  }

  static async getProjects(id) {
    return httpClient.get(ENDPOINTS.getResourceProfileProjects(id));
  }

  static async editResourceProfile(id, payload) {
    return httpClient.put(ENDPOINTS.getResourceProfile(id), payload);
  }

  static async getMiniTimeline(id, startDate, endDate) {
    return httpClient.get(ENDPOINTS.getResourceProfileTimeline(id, startDate, endDate));
  }

  static async getResourceBio(id) {
    return httpClient.post(ENDPOINTS.getResourceProfileBio(id));
  }
}

export default ResourceProfilesService;
