import { maxDate, minDate } from './date';

export const parseAssignmentToProject = (
  data,
  initialValue = { availability: [], scheduledDates: [] },
  starDateTimeline,
  endDateTimeline
) => {
  const {
    personId,
    projectId,
    startDate,
    id,
    skills,
    createdAt,
    creator,
    endDate,
    ongoing,
    percentage,
    seniority,
    skillNames,
    workHours,
    role,
    note,
    pending,
    person,
    assignationType,
    backfillPosition
  } = data;

  const roundPercentage = Math.round(percentage);

  if (personId) {
    return {
      availability: [
        ...initialValue.availability,
        {
          startDate: maxDate(startDate, starDateTimeline),
          endDate: minDate(endDate, endDateTimeline),
          createdAt: [createdAt],
          ongoing: [ongoing],
          busyPercentage: roundPercentage,
          seniority: [seniority],
          skillNames: [skillNames],
          role: [role],
          notes: note ? [{ ...note, assignmentId: id }] : null,
          assignmentIds: [id],
          assignmentSkills: skills,
          assignmentsWorkHours: [workHours],
          totalWorkHours: workHours,
          pending: [pending],
          projectIds: [projectId],
          resourceName: [person.fullName],
          assignmentsTypes: [assignationType],
          backfillPosition: [backfillPosition],
          creator
        }
      ],
      id: personId,
      name: person.fullName,
      scheduledDates: [...initialValue.scheduledDates, [startDate, endDate]],
      skills: skills.map(skill => ({ skill: skill.name, highlighted: false }))
    };
  }

  return {
    startDate,
    skills,
    createdAt,
    endDate,
    ongoing,
    percentage: roundPercentage,
    seniority,
    skillNames,
    workHours,
    role,
    assignmentId: id,
    assignmentSkills: skills,
    creator,
    note
  };
};

export const parseAssignmentToResource = (
  data,
  project,
  initialValue = { availability: [], scheduledDates: [] },
  starDateTimeline,
  endDateTimeline
) => {
  const {
    id,
    projectId,
    skills,
    startDate,
    endDate,
    assignationType,
    workHours,
    pending,
    percentage
  } = data;

  return {
    id: projectId,
    name: project.name,
    skills,
    startDate: project.startDate,
    endDate: project.endDate,
    scheduledDates: [...initialValue.scheduledDates, [startDate, endDate]],
    availability: [
      ...initialValue.availability,
      {
        assignmentIds: [id],
        assignmentsTypes: [assignationType],
        assignmentsWorkHours: [workHours],
        projectIds: [projectId],
        startDate: maxDate(startDate, starDateTimeline),
        endDate: minDate(endDate, endDateTimeline),
        pending: [pending],
        busyPercentage: Math.round(percentage),
        totalWorkHours: workHours
      }
    ]
  };
};
