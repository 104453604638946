import { bool, node, string } from 'prop-types';

import MuiTooltip from 'components/common/MuiTooltip';

import { Wrapper } from './styles';

const IconWithTooltip = ({ message, icon, disablePortal = false }) => (
  <Wrapper onMouseEnter={e => e.stopPropagation()}>
    <MuiTooltip width="2rem" disablePortal={disablePortal}>
      <span>{message}</span>
    </MuiTooltip>
    {icon}
  </Wrapper>
);

IconWithTooltip.propTypes = {
  icon: node,
  message: string,
  disablePortal: bool
};
export default IconWithTooltip;
