import { camelize } from 'humps';
import { bool, func, number, string } from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import { Row } from 'components/common/Layout/Row';
import { AccordionWrapper } from 'components/timeline/AssignmentForm/styles';
import { FULFILLED, PENDING, REJECTED } from 'constants/actionStatusConstants';
import { BUSINESS_TYPES, BUTTON_VARIANTS, PROJECT_TYPES } from 'constants/constants';
import { projectServiceTypes } from 'constants/projectConstants';
import { projectShape } from 'constants/shapes';
import { editProjectFormValidations, newProjectFormValidations } from 'constants/validators';
import {
  useCustomer,
  useCustomerOptions,
  useFormAccordion,
  useIndustries,
  usePeople,
  useRequest
} from 'hooks';
import { getProjectById } from 'state/actions/projectActions';
import { composeProjectRequest } from 'utils/assignmentFormsUtilities';
import { getSelectInitialValue } from 'utils/helpers';

import BasicInfo from './components/BasicInfo';
import NotesSection from './components/Notes';
import ProjectLeaders from './components/ProjectLeaders';
import fields from './fields.json';
import { Form, StyledButton, StyledPlusIcon } from './styles';

const ProjectForm = ({
  onSubmit,
  status,
  error,
  hide,
  edit = false,
  setAddAssignmentTo,
  handleRefresh,
  projectId
}) => {
  const intl = useIntl();
  const [{ options: customerOptions, isCustomerPending }] = useCustomerOptions();
  const { getCustomerRequest } = useCustomer();
  const { salesPeopleOptions, deliveryOwnerOptions, managerOptions } = usePeople();
  const { industriesOptions } = useIndustries();

  const newAssignment = useRef(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      [fields.dates]: {
        [fields.startDate]: new Date(),
        [fields.endDate]: new Date()
      }
    }
  });

  const {
    handleSubmit,
    reset,
    formState: { errors }
  } = methods;

  const [{ response: project, isPending: isPendingProject }] = useRequest(
    getProjectById,
    { params: { projectId } },
    Boolean(projectId)
  );

  const sectionErrors = Object.keys(edit ? editProjectFormValidations : newProjectFormValidations);

  const formSections = [
    {
      section: 1,
      title: 'projectForm.section.basicInfo',
      sectionErrors,
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      component: () => (
        <BasicInfo
          edit={edit}
          isPendingProject={isPendingProject}
          customerOptions={customerOptions}
          isCustomerPending={isCustomerPending}
        />
      )
    },
    {
      section: 2,
      title: 'projectForm.section.projectLeaders',
      optional: true,
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      component: () => <ProjectLeaders />
    },
    {
      section: 3,
      title: 'projectForm.section.notes',
      optional: true,
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      component: () => <NotesSection />
    }
  ];

  const { accordionWrapperRef, openErrorSection, renderFormAccordion } = useFormAccordion(
    formSections,
    errors
  );

  const setInitialValues = useCallback(
    initialValues => {
      const serviceType = initialValues?.serviceType
        ? projectServiceTypes[camelize(initialValues.serviceType)]
        : '';

      const clientId = getSelectInitialValue(customerOptions, initialValues, fields.clientId);
      const industryId = getSelectInitialValue(industriesOptions, initialValues, fields.industryId);
      const salesPersonId = getSelectInitialValue(
        salesPeopleOptions,
        initialValues,
        fields.salesPersonId
      );
      const deliveryOwnerPersonId = getSelectInitialValue(
        deliveryOwnerOptions,
        initialValues,
        fields.deliveryOwnerPersonId
      );

      const managerPersonId = getSelectInitialValue(
        managerOptions,
        initialValues,
        fields.managerPersonId
      );

      reset({
        name: initialValues?.name || '',
        clientId,
        clientName: '',
        industryId,
        code: initialValues?.code || '',
        startDate: initialValues?.startDate || new Date(),
        endDate: initialValues?.endDate || new Date(),
        projectType: initialValues?.projectType === PROJECT_TYPES.NON_BILLABLE,
        serviceType: serviceType && {
          ...serviceType,
          label: intl.formatMessage({
            id: serviceType.label
          })
        },
        notes: initialValues?.notes || '',
        businessType: BUSINESS_TYPES.CLIENT,
        id: initialValues?.id || '',
        salesPersonId,
        deliveryOwnerPersonId,
        managerPersonId
      });
    },
    [
      customerOptions,
      industriesOptions,
      salesPeopleOptions,
      deliveryOwnerOptions,
      managerOptions,
      intl,
      reset
    ]
  );

  useEffect(() => {
    if (!isPendingProject) {
      setInitialValues(project);
    }
  }, [isPendingProject, project, setInitialValues]);

  const onSubmitOverride = async values => {
    const { payload, type } = await onSubmit(composeProjectRequest({ values }));

    if (type.endsWith(`/${FULFILLED}`)) {
      if (newAssignment.current) {
        setAddAssignmentTo({ project: payload, initialValues: null });
      }
      getCustomerRequest();
      handleRefresh?.();
      hide();
    }
  };

  const onCreateNewAssignment = () => {
    newAssignment.current = true;
    handleSubmit(onSubmitOverride)();
  };

  const handleSubmitErrors = async event => {
    await handleSubmit(onSubmitOverride)(event);
    openErrorSection();
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmitErrors}>
        <AccordionWrapper ref={accordionWrapperRef}>
          {renderFormAccordion()}
          {status === REJECTED && <AlertMessage>{error || ''}</AlertMessage>}
        </AccordionWrapper>
        <Row gap="1.2rem">
          {!edit && (
            <StyledButton
              id="addAssignmentsBtn"
              type="button"
              variant={BUTTON_VARIANTS.NEW_SECONDARY}
              isLoading={status === PENDING || isPendingProject}
              textIntlId="common.addAssignments"
              onClick={onCreateNewAssignment}
              outlined
              fullWidth
            >
              <StyledPlusIcon />
            </StyledButton>
          )}
          <StyledButton
            id="saveCompleteBtn"
            type="submit"
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
            isLoading={status === PENDING || isPendingProject}
            textIntlId={edit ? 'projects.editProject' : 'assignment.newProjectCompleteLater'}
            fullWidth
          />
        </Row>
      </Form>
    </FormProvider>
  );
};

ProjectForm.propTypes = {
  edit: bool,
  error: string,
  hide: func.isRequired,
  initialValues: projectShape,
  onSubmit: func.isRequired,
  setAddAssignmentTo: func,
  status: string,
  handleRefresh: func,
  projectId: number
};

export default ProjectForm;
