import { css } from 'styled-components';

import { setSvgColors } from 'utils/cssUtilities';

const fontColor = css`
  ${({ theme }) => theme.new.colors.white}
`;

const backgroundColor = css`
  ${({ theme }) => theme.new.colors.brand}
`;

export const NewPrimaryButton = css`
  ${({ theme }) => theme.new.typography.p6Medium};
  background-color: ${backgroundColor};
  border-radius: 4px;
  color: ${fontColor};
  height: 4rem;
  min-width: 10.2rem;
  padding: 9px 33px;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  ${setSvgColors({ strokeColor: fontColor, fillColor: backgroundColor })};
  &:hover {
    ${({ theme }) => setSvgColors({ fillColor: theme.new.colors.brandDark })}
    background-color: ${({ theme }) => theme.new.colors.brandDark};
  }
  &:focus {
    ${({ theme }) => setSvgColors({ fillColor: theme.new.colors.brandDark })}
    background-color: ${({ theme }) => theme.new.colors.brandDark};
    box-shadow: 0 1px 2px rgba(71, 111, 242, 0.05), 0 0 0 4px rgba(71, 111, 242, 0.05);
  }
  &:disabled {
    ${({ theme }) =>
      setSvgColors({
        strokeColor: theme.new.colors.neutral500,
        fillColor: theme.new.colors.neutral100
      })}
    background-color: ${({ theme }) => theme.new.colors.neutral100};
    border: ${({ theme }) => `1px solid ${theme.new.colors.neutral100}`};
    color: ${({ theme }) => theme.new.colors.neutral500};
    cursor: not-allowed;
  }
`;
