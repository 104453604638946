import styled from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
`;

export const Row = styled.div`
  display: ${({ displayMode }) => displayMode || 'flex'};
  justify-content: ${({ content }) => content || 'space-between'};
  gap: ${({ gap }) => gap};
  width: 100%;
`;

export const RowInputWrapper = styled.div`
  transition: width 0.1s ease-in-out;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '48%')};
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '1rem'};
  padding-top: ${({ paddingTop }) => paddingTop};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '48%')};
  width: ${({ width }) => width};
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  column-gap: ${({ columnGap }) => columnGap || '2rem'};
  flex-wrap: wrap;
  margin-bottom: 1.2rem;
  width: -webkit-fill-available;
`;
