import { useIntl } from 'react-intl';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import StatusIndicator from 'components/common/StatusIndicator';
import { projectTopOverviewShape } from 'constants/shapes';
import theme from 'constants/theme';

import { Container, References, Total, TotalColumn, Label, GraphContainer } from './styles';

function GraphicCard({ projects }) {
  const intl = useIntl();

  const { total, active, planned, finished, onHold, warranty, maintenance } = projects || {};

  const COLORS = theme.new.colors;

  const data = [
    { name: 'Active', value: active, color: COLORS.FI500, label: 'status.active' },
    { name: 'On Hold', value: onHold, color: COLORS.N300, label: 'status.onHold' },
    { name: 'Planned', value: planned, color: COLORS.S500, label: 'status.planned' },
    { name: 'Warranty', value: warranty, color: COLORS.FW500, label: 'status.warranty' },
    { name: 'Finished', value: finished, color: COLORS.N500, label: 'status.finished' },
    { name: 'Maintenance', value: maintenance, color: COLORS.T500, label: 'status.maintenance' }
  ];

  return (
    <Container>
      <GraphContainer>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
              innerRadius={15}
              stroke=""
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </GraphContainer>
      <TotalColumn>
        <Label>{intl.formatMessage({ id: `common.totalProjects` })}</Label>
        <Total>{total}</Total>
      </TotalColumn>
      <References>
        {data.map(({ color, label, value }) => (
          <StatusIndicator key={label} color={color} label={label} value={value} />
        ))}
      </References>
    </Container>
  );
}

GraphicCard.propTypes = {
  projects: projectTopOverviewShape
};

export default GraphicCard;
