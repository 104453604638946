import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Status from 'components/routes/Status';
import routesPaths from 'constants/routesPaths';
import { findMatchingRoute } from 'utils/helpers';
import { HTTP_STATUS_CODES } from 'constants/constants';

import {
  Container,
  Message,
  StyledWarningSvg,
  StyledStatusNumber,
  Title,
  Description
} from './styles';

const NotFound = ({ successRequest }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const routeExists = useMemo(
    () => findMatchingRoute(window.location.pathname, routesPaths),
    [window.location.pathname]
  );

  const hasNoAccess = routeExists && !successRequest;

  const getStatusCode = hasNoAccess ? HTTP_STATUS_CODES.UNAUTHORIZED : HTTP_STATUS_CODES.NOT_FOUND;

  const statusText = formatMessage({ id: 'notFound.status' });
  const titleText = hasNoAccess
    ? formatMessage({ id: 'notFound.title.notAccess' })
    : formatMessage({ id: 'notFound.title.notFound' });
  const descriptionText = hasNoAccess
    ? formatMessage({ id: 'notFound.description.notAccess' })
    : formatMessage({ id: 'notFound.description.notFound' });

  const memoMessage = useMemo(
    () => (
      <Message>
        <StyledWarningSvg />
        <StyledStatusNumber>{getStatusCode}</StyledStatusNumber>
        <Title>{titleText}</Title>
        <Description>{descriptionText}</Description>
      </Message>
    ),
    [statusText, titleText, descriptionText, getStatusCode]
  );

  return (
    <Status code={getStatusCode}>
      <Container>{memoMessage}</Container>
    </Status>
  );
};

export default NotFound;
