import { Redirect, Route, Switch } from 'react-router-dom';

import Tabs from 'components/common/Tabs';
import PermissionsGate from 'components/PermissionGate';
import { SCOPES } from 'constants/permissions';
import routesPaths from 'constants/routesPaths';
import { useRole } from 'hooks';
import DashboardAssignment from 'pages/Dashboard/Assignment';
import DashboardPeople from 'pages/Dashboard/People';
import DashboardReports from 'pages/Dashboard/Reports';
import hasPermission from 'utils/hasPermission';

import { DashboardNavbarContainer } from './styles';

const Dashboard = () => {
  const { permissions } = useRole();

  // Keeping only people tab as it was the only one requested to be brought back
  const tabs = [
    // ...(hasPermission(permissions, [SCOPES.seeDashboardReports, SCOPES.seeDashboard])
    //   ? [{ route: routesPaths.dashboardReports, title: 'dashboard.tab.reports' }]
    //   : []),
    ...(hasPermission(permissions, [SCOPES.seeDashboardPeople, SCOPES.seeDashboard])
      ? [{ route: routesPaths.dashboardPeople, title: 'common.people' }]
      : [])
    // ...(hasPermission(permissions, [SCOPES.seeDashboardAssignments, SCOPES.seeDashboard])
    //   ? [{ route: routesPaths.dashboardAssignment, title: 'common.availability' }]
    //   : [])
  ];

  return (
    <>
      <DashboardNavbarContainer>
        <Tabs tabs={tabs} />
      </DashboardNavbarContainer>
      {!!tabs?.length && (
        <Switch>
          <Redirect exact from={routesPaths.dashboard} to={tabs[0]?.route} />
          <Route exact path={routesPaths.dashboardReports}>
            <PermissionsGate scopes={[SCOPES.seeDashboardReports, SCOPES.seeDashboard]}>
              <DashboardReports />
            </PermissionsGate>
          </Route>
          <Route exact path={routesPaths.dashboardPeople}>
            <PermissionsGate scopes={[SCOPES.seeDashboardPeople, SCOPES.seeDashboard]}>
              <DashboardPeople />
            </PermissionsGate>
          </Route>
          <Route exact path={routesPaths.dashboardAssignment}>
            <PermissionsGate scopes={[SCOPES.seeDashboardAssignments, SCOPES.seeDashboard]}>
              <DashboardAssignment />
            </PermissionsGate>
          </Route>
        </Switch>
      )}
    </>
  );
};

export default Dashboard;
