import { string, arrayOf, any, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { parseInputErrors } from 'utils/helpers';

import { InputContainer, ErrorSpan, StyledInput, Label } from './styles';

const Input = ({ label, name, errors, value, containerProps, className = '', ...props }) => (
  <InputContainer errors={errors} className={className} {...containerProps}>
    <StyledInput errors={errors} name={name} value={value} {...props} />
    {label && (
      <Label errors={errors} value={value} htmlFor={name}>
        {label}
      </Label>
    )}
    {errors && (
      <ErrorSpan>
        <FormattedMessage id={parseInputErrors(errors)} defaultMessage={parseInputErrors(errors)} />
      </ErrorSpan>
    )}
  </InputContainer>
);

Input.propTypes = {
  name: string.isRequired,
  label: string,
  errors: arrayOf(string),
  value: any,
  containerProps: object,
  className: string
};

export default Input;
