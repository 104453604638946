import styled from 'styled-components';

export const SectionHeader = styled.div`
  align-items: flex-start;
  display: flex;
  height: 6.4rem;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;

  && > h2 {
    ${({ theme }) => theme.new.typography.h3};
    padding: 1rem 0.4rem;
  }

  && > button {
    ${({ theme }) => theme.new.typography.p4SemiBold};
    width: 20.7rem;
  }
`;

export const MainCardsContainer = styled.div``;
