import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Row } from 'components/common/Layout/Row';
import { SCOPES } from 'constants/permissions';
import { useRole, useRoles } from 'hooks';
import useGroups from 'hooks/useGroups';
import hasPermission from 'utils/hasPermission';

import { StyledSelect } from './styles';

const MappingFields = () => {
  const intl = useIntl();
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const { permissions } = useRole();
  const { rolesOptions } = useRoles();
  const { groupOptions } = useGroups();

  return (
    <>
      <Row gap="1.2rem">
        <Controller
          name="group"
          control={control}
          rules={{
            validate: (value, formValues) =>
              !!value || !!formValues.group || intl.formatMessage({ id: 'role.presence' })
          }}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="group"
              label={intl.formatMessage({ id: 'settings.addRole.form.group' })}
              options={groupOptions}
              isSearchable
              enablePortal
              isDisabled={!hasPermission(permissions, [SCOPES.createProjects])}
              variant="new"
              withoutHighlight
              placeholder={intl.formatMessage({ id: 'assignment.form.role.placeholder' })}
              errors={errors.role?.message}
            />
          )}
        />
      </Row>
      <Row gap="1.2rem">
        <Controller
          name="role"
          control={control}
          rules={{
            validate: (value, formValues) =>
              !!value || !!formValues.role || intl.formatMessage({ id: 'role.presence' })
          }}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="role"
              label={intl.formatMessage({ id: 'settings.addRole.form.role' })}
              options={rolesOptions}
              isSearchable
              enablePortal
              isDisabled={!hasPermission(permissions, [SCOPES.createProjects])}
              variant="new"
              withoutHighlight
              placeholder={intl.formatMessage({ id: 'assignment.form.role.placeholder' })}
              errors={errors.role?.message}
            />
          )}
        />
      </Row>
    </>
  );
};

export default MappingFields;
