import { useState, useEffect, useCallback } from 'react';

const useLazyLoading = (fullData, containerId, offSet) => {
  const initialItemsToShow = 10;
  const itemsToLoad = 10;

  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setItems(fullData.slice(0, initialItemsToShow));
    setHasMore(fullData.length > initialItemsToShow);
  }, [fullData, initialItemsToShow]);

  const loadMore = useCallback(() => {
    const newItemsCount = items.length + itemsToLoad;
    const newItems = fullData.slice(0, newItemsCount);
    setItems(newItems);
    if (newItemsCount >= fullData.length) {
      setHasMore(false);
    }
  }, [items.length, fullData, itemsToLoad]);

  const handleScroll = useCallback(() => {
    const container = document.getElementById(containerId);
    if (!container) return;

    const { scrollTop, clientHeight, scrollHeight } = container;

    if (scrollHeight - scrollTop <= clientHeight + offSet) {
      if (hasMore) {
        loadMore();
      }
    }
  }, [hasMore, loadMore, containerId, offSet]);

  useEffect(() => {
    const container = document.getElementById(containerId);
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll, containerId]);

  return { items, hasMore };
};

export default useLazyLoading;
