import styled from 'styled-components';

export const Wrapper = styled.div`
  width: auto;
  height: auto;
  position: relative;
`;

export const Overlay = styled.div`
  background-color: ${({ theme }) => theme.colors.loadingOverlay};
  cursor: wait;
  z-index: ${({ theme }) => theme.zIndex.loadingOverlay};
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;

  & * {
    z-index: 0;
  }
`;
