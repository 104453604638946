import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  cursor: auto;
  display: flex;
  height: auto;
  justify-content: center;
  position: relative;

  width: ${({ $width }) => $width || '2.8rem'};
`;
