import styled from 'styled-components';

import Select from 'components/common/Select';
import { StyledSelect as CommonStyledSelect } from 'components/common/Select/styles';
import { newInputBorder } from 'utils/inputColors';

export const Container = styled.div`
  display: flex;
  gap: 0.8rem;
`;

export const StyledSelect = styled(Select)`
  width: 25rem;

  & label {
    padding: 0;
  }

  & .react-select__value-container {
    padding-left: 4px;
  }

  & .react-select__placeholder,
  .react-select__single-value,
  .react-select__option {
    ${({ theme }) => theme.new.typography.p4};
    color: ${({ theme }) => theme.new.colors.N900};
  }

  ${CommonStyledSelect} {
    .react-select__control {
      border: ${newInputBorder};
      height: 4.8rem;
    }
  }
`;
