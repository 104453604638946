import { arrayOf, string } from 'prop-types';
import { useIntl } from 'react-intl';

import MuiTooltip from 'components/common/MuiTooltip';
import ServiceTypeLabel from 'components/timeline/ServiceTypeLabel';
import routesPaths from 'constants/routesPaths';
import { forecastingPeriodShape, forecastingProjectShape } from 'constants/shapes';
import { routeWithProps } from 'utils/helpers';
import { formatFractionDigits, toCurrency } from 'utils/number';
import { getMarginPercentage } from 'utils/periodUtilities';

import EmptyState from './EmptyState';
import { Column, Row, Name, Label, Title, ServiceType, Margin } from './styles';

function ProjectItem({ project, width = 'auto', listMonth }) {
  const intl = useIntl();
  const { id, name, serviceType, currentRevenue, currentCosts, forecastedSummaries } = project;
  const currentMargin = getMarginPercentage(currentRevenue, currentCosts);

  const summariesList = [];

  listMonth.map(item => {
    const itemPeriod = forecastedSummaries.find(
      element => element.year == item.year && element.month == item.month
    );

    summariesList.push(itemPeriod);
  });

  return (
    <Row
      aria-label={name}
      id={`project-${id}`}
      key={id}
      to={routeWithProps(routesPaths.financialProjectsDetails, {
        id
      })}
    >
      <Column gap="0.6rem" flexDirection="column" minWidth={width} alignItems="start">
        {name.length > 22 ? (
          <>
            <MuiTooltip>{name}</MuiTooltip>
            <Name
              to={routeWithProps(routesPaths.projectDetails, {
                id
              })}
            >
              {name}
            </Name>
          </>
        ) : (
          <Name
            to={routeWithProps(routesPaths.projectDetails, {
              id
            })}
          >
            {name}
          </Name>
        )}
        <ServiceType>
          <Title>{intl.formatMessage({ id: 'common.serviceType' })}</Title>
          <ServiceTypeLabel mode="light" serviceType={serviceType} />
        </ServiceType>
      </Column>
      <Column gap="0.4rem" maxWidth={width} flexDirection="column" alignItems="end">
        <Label strong>{intl.formatMessage({ id: 'common.revenue' })}:</Label>
        <Label strong>{intl.formatMessage({ id: 'common.cost' })}:</Label>
        <Label strong>{intl.formatMessage({ id: 'common.margin' })}:</Label>
      </Column>
      <Column gap="0.5rem" maxWidth={width} flexDirection="column" alignItems="start">
        <Label>{currentRevenue ? toCurrency(currentRevenue) : '-'}</Label>
        <Label>{currentCosts ? toCurrency(currentCosts) : '-'}</Label>
        <Margin margin={currentMargin}>
          {currentMargin ? `${formatFractionDigits(currentMargin, 2)}%` : '--'}
        </Margin>
      </Column>
      {summariesList.map((period, index) => {
        if (!period) {
          return (
            <Column key={index} gap="0.5rem" flexDirection="column" maxWidth={width}>
              <EmptyState />
            </Column>
          );
        }

        const { revenue, cost, id } = period;
        const margin = getMarginPercentage(revenue, cost);

        return (
          <Column key={id} gap="0.5rem" flexDirection="column" maxWidth={width}>
            <Label>{revenue ? toCurrency(revenue) : '-'}</Label>
            <Label>{cost ? toCurrency(cost) : '-'}</Label>
            <Margin margin={margin}>{margin ? `${formatFractionDigits(margin, 2)}%` : '--'}</Margin>
          </Column>
        );
      })}
    </Row>
  );
}

ProjectItem.propTypes = {
  project: forecastingProjectShape.isRequired,
  width: string,
  listMonth: arrayOf(forecastingPeriodShape).isRequired
};

export default ProjectItem;
