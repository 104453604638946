import { useIntl } from 'react-intl';

import Modal from 'components/common/Modal';
import NewResourceForm from 'components/timeline/NewResourceForm';
import { defaultModalShape } from 'constants/shapes';
import { useDispatch, useDispatchWithTimelineDates, useStatus } from 'hooks';
import { createResource } from 'state/actions/peopleActions';

const AddNewResourcesModal = ({ initialValues, isShowing, hide }) => {
  const intl = useIntl();

  const action = createResource;
  const resourceRequest = useDispatchWithTimelineDates(action);
  const resetStatusRequest = useDispatch(action.reset);
  const { status, error } = useStatus(action);

  const resetStatusAndHide = () => {
    resetStatusRequest();
    hide();
  };

  return (
    <Modal
      isShowing={isShowing}
      hide={resetStatusAndHide}
      title={intl.formatMessage({ id: 'resources.newResource' })}
    >
      <NewResourceForm
        onSubmit={resourceRequest}
        resetStatus={resetStatusRequest}
        status={status}
        initialValues={initialValues}
        hide={resetStatusAndHide}
        error={error}
      />
    </Modal>
  );
};

AddNewResourcesModal.propTypes = defaultModalShape;

export default AddNewResourcesModal;
