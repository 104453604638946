import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  margin: 20px 27px 0 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray30}`};
  padding-bottom: 15px;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.fontColor};
  display: inline-block;
`;
