import { node } from 'prop-types';

import { Container } from './styles';

const BoxContainer = ({ children }) => <Container>{children}</Container>;

BoxContainer.propTypes = {
  children: node.isRequired
};

export default BoxContainer;
