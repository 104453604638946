import styled from 'styled-components';

export const Container = styled.div`
  padding: 3.2rem 2.4rem;
  font-family: ${({ theme }) => theme.new.fontFamily};
  height: 100vh;
  overflow-y: auto;
  width: 100%;
`;

export const MainCardsContainer = styled.div``;
