import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const Content = styled.div`
  align-items: start;
  background-color: ${({ theme }) => theme.new.colors.feedbackWarningLight};
  border-radius: 0.8rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  padding: 1.1rem 0;
  white-space: pre;
  width: 100%;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;

  ${({ theme }) => theme.new.typography.p5SemiBold};
  color: ${({ theme }) => theme.new.colors.feedbackWarningDark};
`;

export const Text = styled.div`
  padding-top: 0.2rem;
  ${({ theme }) => theme.new.typography.p5};
`;
