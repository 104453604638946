import styled from 'styled-components';

export const Label = styled.foreignObject`
  ${({ theme }) => theme.new.typography.h3};
  height: 100%;
  width: 60%;
`;

export const Text = styled.p`
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
