import dayjs from 'dayjs';
import { arrayOf, bool, shape } from 'prop-types';
import { useIntl } from 'react-intl';

import { REVENUE_GRAPH_COLORS } from 'constants/forecastingConstants';
import { revenueGraphShape } from 'constants/shapes';
import { toCurrency } from 'utils/number';

import { Container, DataContainer, Header, ItemKey, ItemValue } from './styles';

function InfoTooltip({ active, payload }) {
  const intl = useIntl();

  if (!active || !payload?.length) return null;

  const data = payload[0].payload;

  return (
    <Container>
      <Header>{data?.name ? dayjs(data.name, 'MMM, YYYY').format('MMMM YYYY') : ''}</Header>
      <DataContainer>
        {Boolean(data?.actualRevenue) && (
          <>
            <ItemKey
              fill={REVENUE_GRAPH_COLORS.actualRevenue.fill}
              stroke={REVENUE_GRAPH_COLORS.actualRevenue.stroke}
            >
              {intl.formatMessage({ id: 'common.actualRevenue' })}
            </ItemKey>
            <ItemValue>{toCurrency(data.actualRevenue)}</ItemValue>
          </>
        )}
        {Boolean(data?.underContract) && (
          <>
            <ItemKey
              fill={REVENUE_GRAPH_COLORS.underContract.fill}
              stroke={REVENUE_GRAPH_COLORS.underContract.stroke}
            >
              {intl.formatMessage({ id: 'common.underContract' })}
            </ItemKey>
            <ItemValue>{toCurrency(data.underContract)}</ItemValue>
          </>
        )}
        {Boolean(data?.awaitingStart) && (
          <>
            <ItemKey
              fill={REVENUE_GRAPH_COLORS.awaitingStart.fill}
              stroke={REVENUE_GRAPH_COLORS.awaitingStart.stroke}
            >
              {intl.formatMessage({ id: 'common.awaitingStart' })}
            </ItemKey>
            <ItemValue>{toCurrency(data.awaitingStart)}</ItemValue>
          </>
        )}
        {Boolean(data?.revenueGoal) && (
          <>
            <ItemKey
              fill={REVENUE_GRAPH_COLORS.revenueGoal.fill}
              stroke={REVENUE_GRAPH_COLORS.revenueGoal.stroke}
            >
              {intl.formatMessage({ id: 'common.revenueGoal' })}
            </ItemKey>
            <ItemValue>{toCurrency(data.revenueGoal)}</ItemValue>
          </>
        )}
      </DataContainer>
    </Container>
  );
}

InfoTooltip.propTypes = {
  active: bool,
  payload: arrayOf(shape({ payload: revenueGraphShape }))
};

export default InfoTooltip;
