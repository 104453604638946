import { createReducer } from '@reduxjs/toolkit';

import { updateTemporalRoles } from 'state/actions/settingsActions';
import { login, signUp, logout, updateSession, loginGoogle } from 'state/actions/userActions';

const initialState = {
  authenticated: false,
  user: null,
  info: {},
  resource: null
};

export default createReducer(initialState, builder =>
  builder
    .addCase(login.fulfilled, (state, { payload: { user, resource } }) => {
      state.user = user;
      state.resource = resource;
    })
    .addCase(loginGoogle.fulfilled, (state, { payload: { user, resource } }) => {
      state.user = user;
      state.resource = resource;
    })
    .addCase(signUp.fulfilled, (state, { payload }) => {
      state.user = payload;
    })
    .addCase(updateSession, (state, { payload }) => {
      state.info = payload;
      state.authenticated = true;
    })
    .addCase(updateTemporalRoles, (state, { payload }) => {
      state.user = {
        ...state.user,
        temporalGroups: state.user.temporalGroups.filter(({ id }) => id !== payload.temporalRoleId)
      };
    })
    .addCase(logout.fulfilled, () => initialState)
    .addCase(logout.rejected, () => initialState)
);
