import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
`;

export const Horizontal = styled.hr`
  border-width: 0;
  background-color: ${({ theme, color }) => color || theme.new.colors.N300};
  height: ${({ height }) => height || '1px'};
  margin: ${({ margin }) => margin};
`;

export const Content = styled.div`
  margin-top: 2.4rem;
  padding: 2.4rem;
  box-shadow: ${({ theme }) => theme.new.shadows.medium.boxShadow};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.new.colors.white};
  width: 100%;
`;

export const FiltersContainer = styled.div`
  padding-bottom: 1.8rem;
`;
