import { number } from 'prop-types';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

const CustomizedLabel = ({ x = 0, y = 0 }) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={20}
        dx={80}
        fill={theme.colors.gray80}
        fontSize={16}
        fontWeight={theme.fontWeights.normal}
        fontFamily={theme.fontFamily}
      >
        {intl.formatMessage({ id: 'dashboard.breakdown.section.availableChart' })}
      </text>
    </g>
  );
};

CustomizedLabel.propTypes = {
  x: number,
  y: number
};

export default CustomizedLabel;
