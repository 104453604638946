import Zoom from '@mui/material/Zoom';
import { string, oneOf, node } from 'prop-types';

import { EVENT_TYPE } from 'constants/constants';

import { StyledTooltip, TooltipContainer, Content, Title, Text } from './styles';

const EventTooltip = ({ type, title = '', textRow1 = '', textRow2 = '', children }) => (
  <StyledTooltip
    placement="top"
    TransitionComponent={Zoom}
    arrow
    title={
      <Content type={type}>
        <Title type={type}>{title}</Title>
        <Text>{textRow1}</Text>
        <Text>{textRow2}</Text>
        {children}
      </Content>
    }
  >
    <TooltipContainer />
  </StyledTooltip>
);

EventTooltip.propTypes = {
  type: oneOf(Object.values(EVENT_TYPE)),
  title: string,
  textRow1: string,
  textRow2: string,
  children: node
};

export default EventTooltip;
