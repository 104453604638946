import { func, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { Container, Tab } from './styles';

const CustomTab = ({ activeTab, setActiveTab }) => {
  const intl = useIntl();

  return (
    <Container>
      <Tab
        type="button"
        active={activeTab === 'quarter'}
        onClick={() => setActiveTab('group', 'quarter')}
      >
        {intl.formatMessage({ id: 'common.cap.quarter' })}
      </Tab>
      <Tab
        type="button"
        active={activeTab === 'month'}
        onClick={() => setActiveTab('group', 'month')}
      >
        {intl.formatMessage({ id: 'common.month' })}
      </Tab>
    </Container>
  );
};

CustomTab.propTypes = {
  setActiveTab: func,
  activeTab: string
};

export default CustomTab;
