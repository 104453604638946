import styled from 'styled-components';

import FilterDropdown from 'components/common/FilterDropdown';

export const Selectors = styled.div`
  align-items: center;
  display: flex;
`;

export const FilterSkill = styled(FilterDropdown)`
  margin-right: 2.4rem;
`;
