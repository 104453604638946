import { func, number, oneOfType } from 'prop-types';

import InlineTextEditor from 'components/common/InlineTextEditor';
import { BILLING_UNITS } from 'constants/constants';
import { convertCostsToBillingUnit } from 'utils/financialUtilities';
import { precisionFormat } from 'utils/number';

export const RateInput = ({
  rate,
  billingUnit,
  id,
  capacity,
  priceWithDiscount,
  onChange,
  ...props
}) => {
  const handleChange = value => {
    const hoursValue = convertCostsToBillingUnit(
      { from: billingUnit, to: BILLING_UNITS.HOURLY, capacity },
      precisionFormat(value)
    );

    onChange(id, 'rate', hoursValue);
  };

  return (
    <InlineTextEditor
      symbol="$"
      value={billingUnit === BILLING_UNITS.DAILY ? Math.round(rate) : rate}
      priceWithDiscount={priceWithDiscount}
      onChange={handleChange}
      {...props}
    />
  );
};

RateInput.propTypes = {
  rate: number,
  billingUnit: oneOfType([BILLING_UNITS.DAILY, BILLING_UNITS.HOURLY]),
  id: number,
  capacity: number,
  priceWithDiscount: number,
  onChange: func
};

export default RateInput;
