import { arrayOf } from 'prop-types';
import { useIntl } from 'react-intl';

import { utilizationBenchOverview } from 'constants/shapes';

import { Container, THead, TRow, Table } from './styles';

const COLORS = ['#A284F9', '#58C0F3', '#A4E064', '#FF5674', '#FFA94C', '#FFDA4D'];

const DepartmentTable = ({ data }) => {
  const intl = useIntl();

  return (
    <Container>
      <h3>{intl.formatMessage({ id: 'insights.utilization.department.table' })}</h3>
      <Table border="1">
        <THead>
          <tr>
            <th>{intl.formatMessage({ id: 'common.department' })}</th>
            <th>{intl.formatMessage({ id: 'common.cap.utilization' })}</th>
            <th>{intl.formatMessage({ id: 'common.bench' })} %</th>
            <th>{intl.formatMessage({ id: 'common.benchFTE' })}</th>
          </tr>
        </THead>
        <tbody>
          {data?.map(
            (
              { departmentId, departmentName, utilizationPercentage, benchPercentage, benchInFte },
              index
            ) => (
              <TRow key={departmentId} color={COLORS[index % COLORS.length]}>
                <td>{departmentName}</td>
                <td>{utilizationPercentage?.toFixed(1)}%</td>
                <td>{benchPercentage?.toFixed(1)}%</td>
                <td>{Math.round(benchInFte)}</td>
              </TRow>
            )
          )}
        </tbody>
      </Table>
    </Container>
  );
};

DepartmentTable.propTypes = {
  data: arrayOf(utilizationBenchOverview)
};

export default DepartmentTable;
