import styled from 'styled-components';

import Button from 'components/common/Button';
import DeleteButton from 'components/common/DeleteButton';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding-top: 3.2rem;
  width: 100%;
`;

export const Title = styled.p`
  ${({ theme }) => theme.new.typography.p4Bold}
`;

export const StyledDeleteButton = styled(DeleteButton)`
  height: 4.4rem;
  margin-left: 0.4rem;
`;

export const ExperienceContainer = styled.div`
  align-items: end;
  display: flex;
  gap: 0.8rem;
  max-width: 29.3rem;
  width: 100%;
`;

export const AddSkillButton = styled(Button)`
  border: none;
  color: ${({ theme }) => theme.new.colors.brand};
  height: 2rem;
  padding: 0;
  svg {
    transform: ${`scale(${20 / 22})`};
    path {
      stroke-width: 1.5px;
      stroke: ${({ theme }) => theme.new.colors.brand};
    }
  }
`;

export const MainSkills = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
