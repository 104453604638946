import { createReducer } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

import {
  AVAILABILITY_OPTIONS,
  HIGHLIGHT_SKILL_VALUE,
  SENIORITY_OPTIONS,
  TEAMS_OPTIONS
} from 'constants/constants';
import {
  createAssignment,
  deleteAssignment,
  editAssignment
} from 'state/actions/assignmentActions';
import {
  filterResourcesByName,
  getPeopleAvailability,
  toggleResourceExpanded,
  resetExpandedResources,
  toggleUnassignedSkillsShown,
  toggleUnassignedSkillExpanded,
  resetResourceFilters,
  filterResources,
  getMinimalResources
} from 'state/actions/peopleActions';
import { getSkills, getDepartments, getLocations } from 'state/actions/uiActions';
import { filtersResources, resetFilters, sortOptions } from 'utils/filtersUtilities';
import { initializeOptions, initializeTeams } from 'utils/helpers';
import { localCreateAssignment, localDeleteAssignment } from 'utils/localAssignmentActions';
import { parseAssignmentToResource } from 'utils/parseState';
import { filterList } from 'utils/reducersUtilities';

const initialState = {
  people: [],
  minimalSalesPeople: [],
  minimalManagers: [],
  minimalDeliveryOwners: [],
  resourcesTimeline: {
    filters: {
      seniority: initializeOptions(SENIORITY_OPTIONS),
      availability: initializeOptions(AVAILABILITY_OPTIONS),
      name: null,
      skills: null,
      teams: initializeOptions(TEAMS_OPTIONS),
      locations: null,
      roles: null
    },
    filteredPeople: [],
    expandedPeople: [],
    filtersOptions: [],
    expandedUnassignedSkills: [],
    unassignedSkillsShown: false
  }
};

const peopleReducer = createReducer(initialState, builder =>
  builder
    .addCase(getPeopleAvailability.fulfilled, (state, { payload }) => {
      state.people = payload.resources;
      state.resourcesTimeline.filteredPeople = filtersResources(state, payload).resourcesResult;
    })
    .addCase(getSkills.fulfilled, (state, { payload: { skills } }) => {
      state.resourcesTimeline.filters.skills = sortOptions(
        initializeOptions(skills.map(({ name }) => ({ key: name, label: name })))
      );
      state.resourcesTimeline.filters.skills.unshift({
        key: HIGHLIGHT_SKILL_VALUE,
        label: HIGHLIGHT_SKILL_VALUE,
        value: false
      });
    })
    .addCase(getLocations.fulfilled, (state, { payload: { locations } }) => {
      const locationsShort = uniqBy(locations, 'country');
      state.resourcesTimeline.filters.locations = sortOptions(
        initializeOptions(locationsShort.map(({ country }) => ({ key: country, label: country })))
      );
    })
    .addCase(getDepartments.fulfilled, (state, { payload: { departments } }) => {
      state.resourcesTimeline.filters.teams = sortOptions(
        initializeTeams({
          teams: departments,
          withValues: false
        })
      );
    })
    .addCase(filterResourcesByName, (state, { payload: resourceName }) => {
      state.resourcesTimeline.filters.name = resourceName;
      state.resourcesTimeline.filteredPeople = filterList(
        state.people,
        resourceName,
        item => item?.fullName
      );
    })
    .addCase(filterResources, (state, { payload }) => {
      const { resourcesResult, newResourceFilters, resourcesFiltersOptionsQuery } =
        filtersResources(state, payload);
      state.resourcesTimeline.filters = newResourceFilters;
      state.resourcesTimeline.filteredPeople = resourcesResult;
      state.resourcesTimeline.filtersOptions = resourcesFiltersOptionsQuery;
    })
    .addCase(toggleResourceExpanded, (state, { payload: personId }) => {
      state.resourcesTimeline.expandedPeople.includes(personId)
        ? (state.resourcesTimeline.expandedPeople = state.resourcesTimeline.expandedPeople.filter(
            x => x !== personId
          ))
        : state.resourcesTimeline.expandedPeople.push(personId);
    })
    .addCase(toggleUnassignedSkillsShown, (state, { payload }) => {
      state.resourcesTimeline.unassignedSkillsShown =
        payload === undefined ? !state.resourcesTimeline.unassignedSkillsShown : payload;
    })
    .addCase(toggleUnassignedSkillExpanded, (state, { payload: { unassignedSkillId } }) => {
      state.resourcesTimeline.expandedUnassignedSkills.includes(unassignedSkillId)
        ? (state.resourcesTimeline.expandedUnassignedSkills =
            state.resourcesTimeline.expandedUnassignedSkills.filter(x => x !== unassignedSkillId))
        : state.resourcesTimeline.expandedUnassignedSkills.push(unassignedSkillId);
    })
    .addCase(resetExpandedResources, state => {
      state.resourcesTimeline.expandedPeople = initialState.resourcesTimeline.expandedPeople;
    })
    .addCase(resetResourceFilters, state => {
      state.resourcesTimeline.filters.seniority = resetFilters(
        state.resourcesTimeline.filters.seniority
      );
      state.resourcesTimeline.filters.availability = resetFilters(
        state.resourcesTimeline.filters.availability
      );
      state.resourcesTimeline.filters.locations = resetFilters(
        state.resourcesTimeline.filters.locations
      );
      state.resourcesTimeline.filters.skills = resetFilters(state.resourcesTimeline.filters.skills);
      state.resourcesTimeline.filters.teams = resetFilters(state.resourcesTimeline.filters.teams);
      state.resourcesTimeline.filters.name = null;
    })
    .addCase(
      createAssignment.fulfilled,
      (state, { payload: { data, project, starDateTimeline, endDateTimeline } }) => {
        const { personId, projectId } = data;

        if (!personId) return;

        const personPos = state.people.findIndex(person => person.id === personId);
        const filterPersonPos = state.resourcesTimeline.filteredPeople.findIndex(
          person => person.id === personId
        );
        const projectPos = state.people[personPos].projects.findIndex(
          project => project.id === projectId
        );

        const parseData = parseAssignmentToResource(
          data,
          project,
          state.people[personPos].projects?.[projectPos],
          starDateTimeline,
          endDateTimeline
        );

        localCreateAssignment(state, personPos, filterPersonPos, projectPos, null, parseData);
      }
    )
    .addCase(deleteAssignment.fulfilled, (state, { payload: data }) => {
      const { personId, projectId, assignmentId } = data;

      if (!personId) return;

      const personPos = state.people.findIndex(person => person.id === personId);
      const filterPersonPos = state.resourcesTimeline.filteredPeople.findIndex(
        person => person.id === personId
      );
      const projectPos = state.people[personPos].projects.findIndex(
        project => project.id === projectId
      );

      localDeleteAssignment(state, personPos, filterPersonPos, projectPos, assignmentId);
    })
    .addCase(
      editAssignment.fulfilled,
      (state, { payload: { data, starDateTimeline, endDateTimeline } }) => {
        const { personId, projectId, id } = data;

        if (!personId) return;

        const personPos = state.people.findIndex(person => person.id === personId);
        const filterPersonPos = state.resourcesTimeline.filteredPeople.findIndex(
          person => person.id === personId
        );

        const prevProjectPos = state.people[personPos].projects.findIndex(project =>
          project.availability.some(availability => availability?.assignmentIds?.includes(id))
        );
        const prevProject = state.people[personPos].projects[prevProjectPos];

        if (!prevProject) return;

        localDeleteAssignment(state, personPos, filterPersonPos, prevProjectPos, id);

        const projectPos = state.people[personPos].projects.findIndex(
          project => project.id === projectId
        );

        const parseData = parseAssignmentToResource(
          data,
          prevProject,
          state.people[personPos].projects?.[projectPos],
          starDateTimeline,
          endDateTimeline
        );

        localCreateAssignment(state, personPos, filterPersonPos, projectPos, null, parseData);
      }
    )
    .addCase(getMinimalResources.fulfilled, (state, { payload: { resources, userGroup } }) => {
      if (userGroup === 'sales') {
        state.minimalSalesPeople = resources;
      } else if (userGroup === 'manager') {
        state.minimalManagers = resources;
      } else if (userGroup === 'delivery_owner') {
        state.minimalDeliveryOwners = resources;
      }
    })
);

export default peopleReducer;
