import { func, bool, object } from 'prop-types';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import NewModal from 'components/common/NewModal';
import { FULFILLED } from 'constants/actionStatusConstants';
import { useDispatch, useActionOnSuccess, useStatus } from 'hooks';
import { createProjectStatus } from 'state/actions/projectActions';

import ProjectStatusForm from '../ProjectStatusForm';

const NewProjectStatusModal = ({ project, initialValues, isShowing, hide, handleRefreshData }) => {
  const intl = useIntl();
  const createStatusRequest = useDispatch(createProjectStatus);
  const resetCreateStatusRequest = useDispatch(createProjectStatus.reset);
  const { status, error } = useStatus(createProjectStatus);

  useActionOnSuccess([status], hide);
  useEffect(() => resetCreateStatusRequest, [resetCreateStatusRequest]);

  const onSubmit = async values => {
    const { type } = await createStatusRequest({ ...values });
    if (type.endsWith(`/${FULFILLED}`)) {
      handleRefreshData?.();
    }
  };

  return (
    <NewModal
      isShowing={isShowing}
      hide={hide}
      title={intl.formatMessage(
        { id: 'template.newProjectStatus.title' },
        { projectName: project.name }
      )}
      withoutOverflow
    >
      <ProjectStatusForm
        project={project}
        initialValues={initialValues}
        onSubmit={onSubmit}
        status={status}
        error={error}
        submitTitleId="common.cap.createStatus"
        disableStartDateSelector={project.allStatuses.length > 0}
      />
    </NewModal>
  );
};

NewProjectStatusModal.propTypes = {
  project: object.isRequired,
  isShowing: bool.isRequired,
  hide: func.isRequired,
  initialValues: object,
  handleRefreshData: func
};

export default NewProjectStatusModal;
