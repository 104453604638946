import { arrayOf, bool, func, number, object, oneOfType, string } from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import ActiveFilters from 'components/common/ActiveFilters';
import Checkbox from 'components/common/Checkbox';
import { CheckboxWrapper } from 'components/common/Layout/Row';
import MultiDropdown from 'components/common/MultiDropdown';
import { ReactComponent as SearchSvg } from 'components/icons/search.svg';
import { ReactComponent as UserSvg } from 'components/icons/user.svg';
import assignmentTypes from 'constants/assignmentTypes';
import { assignmentMessages } from 'constants/messages';
import { SCOPES } from 'constants/permissions';
import { useAssignmentOptions, useHeights, useRequest, useRole } from 'hooks';
import { getMinimalResources } from 'state/actions/peopleActions';
import { personToAssignmentOption } from 'utils/assignmentFormsUtilities';
import hasPermission from 'utils/hasPermission';
import { initializeOptionsWithSelected } from 'utils/helpers';

import fields from './fields.json';
import { StyledResourceSelect, SkillsRow, StyledRow } from './styles';

const ResourcesAndSkills = ({
  noGeneralPermission,
  resourceId,
  resources,
  setResourceId,
  setResources,
  expandedSection
}) => {
  const [personsOptions, setPersonsOptions] = useState();
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [, skillsOptions] = useAssignmentOptions();

  const intl = useIntl();

  const { permissions } = useRole();
  const { inputHeight } = useHeights();

  const { control, getValues, reset, setValue, trigger } = useFormContext();
  const { personId, role, skillIds } = useWatch();

  const initialSkillOptions = useMemo(
    () => initializeOptionsWithSelected(skillsOptions.options, skillIds),
    [skillsOptions.options, skillIds]
  );

  const filterSkillsByDepartment = useCallback(
    (skills, resourceId, resources) => {
      let filteredSkills = skills;
      const resource = resources?.find(({ id }) => id === resourceId);
      if (resource?.departmentId) {
        filteredSkills = skills?.filter(skill => skill.departmentId === resource?.departmentId);
      }
      const filteredIds = filteredSkills.map(({ id }) => id);

      const selectedSkills = skillIds.reduce((skills, skill) => {
        if (!filteredIds.includes(skill.id)) skills.push({ ...skill, selected: true });
        return skills;
      }, []);

      return skillIds ? [...filteredSkills, ...selectedSkills] : filteredSkills;
    },
    [skillIds]
  );

  const handleSelectedOptions = useCallback(newOptions => {
    const checkedOptions = newOptions?.filter(option => option.selected);
    setSelectedOptions(checkedOptions);
  }, []);

  const deselectOption = deletedOption => {
    const newValues = skillIds?.filter(({ key }) => key !== deletedOption);
    setValue(fields.assignment.skillIds, newValues);
  };

  const onClear = useCallback(() => {
    setValue(fields.assignment.skillIds, []);
  }, [setValue]);

  const onResourceChange = target => {
    let newValues = getValues();

    if (target?.value.type === assignmentTypes.skills.value) {
      newValues = {
        ...getValues(),
        [fields.assignment.skillIds]: [...skillIds, target]
      };
    }

    const personId = target?.value?.id;

    if (!target || target?.value.type === assignmentTypes.resources.value) {
      newValues = {
        ...getValues(),
        [fields.assignment.personId]: target,
        [fields.assignment.pending]: true,
        [fields.assignment.skillIds]: personId
          ? filterSkillsByDepartment(skillIds, personId, resources)
          : skillIds,
        [fields.assignment.quantity]: 1
      };
    }
    reset(newValues, { keepDefaultValues: true });
    setResourceId(personId);
    trigger();
  };

  const [{ isPending: isPendingPersons }] = useRequest(getMinimalResources, {
    fulfilledCallback: ({ resources }) => {
      const resourcesAvailableForAssignment = resources?.filter(
        resource => resource?.role?.availableForAssignment
      );

      const resourcesOptions = resourcesAvailableForAssignment?.map(personToAssignmentOption);
      setPersonsOptions(resourcesOptions);
      setResources(resourcesAvailableForAssignment);

      if (resourcesOptions) {
        setValue(
          fields.assignment.personId,
          resourcesOptions?.find(({ value: { id } }) => id === resourceId)
        );
      }

      setValue(
        fields.assignment.skillIds,
        filterSkillsByDepartment(skillIds, resourceId, resources)
      );
    }
  });

  useEffect(() => {
    !role && onClear();
  }, [role, onClear]);

  useEffect(() => {
    handleSelectedOptions(options);
  }, [options, handleSelectedOptions]);

  useEffect(() => {
    setOptions(filterSkillsByDepartment(initialSkillOptions, resourceId, resources));
  }, [initialSkillOptions, resourceId, resources, filterSkillsByDepartment]);

  return (
    <>
      <StyledRow>
        <Controller
          name={fields.assignment.personId}
          control={control}
          render={({ field }) => (
            <StyledResourceSelect
              {...field}
              id="resourcesDrp"
              label={intl.formatMessage(assignmentMessages.assignment.personId.label)}
              placeholder={intl.formatMessage(assignmentMessages.assignment.personId.placeholder)}
              options={personsOptions || []}
              height={inputHeight}
              isSearchable
              withoutHighlight
              enablePortal
              isClearable
              isDisabled={
                !hasPermission(permissions, [SCOPES.createAssignment, SCOPES.editAssignment])
              }
              onChange={onResourceChange}
              isLoading={isPendingPersons}
              variant="new"
              icon={SearchSvg}
              selectedIcon={UserSvg}
              optionIcon={UserSvg}
            />
          )}
        />

        <CheckboxWrapper paddingBottom="1.4rem">
          <Controller
            name={fields.assignment.pending}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={intl.formatMessage({ id: 'common.cap.confirmPosition' })}
                disabled={
                  !hasPermission(permissions, [SCOPES.confirmAssignment]) || !personId?.value?.id
                }
                value={!value && personId?.value?.id}
                variant="new"
                onChange={value => onChange(!value)}
              />
            )}
          />
        </CheckboxWrapper>
      </StyledRow>
      <SkillsRow>
        <Controller
          name={fields.assignment.skillIds}
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiDropdown
              id="skillsDrp"
              labelId={assignmentMessages.assignment.skillIds.label.id}
              placeholderId={assignmentMessages.assignment.skillIds.placeholder.id}
              clearId="deleteSkill"
              options={options}
              setOptions={setOptions}
              selectedOptions={selectedOptions}
              withIntlOptions={false}
              isDisabled={noGeneralPermission}
              maxWidth="100%"
              searchIntlId="common.cap.searchSkill"
              isClearable={false}
              isSearch
              isInsideAccordion
              narrow
              closeTrigger={expandedSection}
              onChange={skills => onChange(skills.filter(skill => skill.selected))}
              value={value}
            />
          )}
        />
        <ActiveFilters
          filters={options}
          setFilters={deselectOption}
          handleClear={onClear}
          selectedOptions={selectedOptions}
          withIntlLabel={false}
          handleOwnDelete
        />
      </SkillsRow>
    </>
  );
};

ResourcesAndSkills.propTypes = {
  noGeneralPermission: bool,
  resourceId: oneOfType([string, number]),
  resources: arrayOf(object),
  setResourceId: func,
  setResources: func,
  setValues: func,
  values: object,
  expandedSection: number
};

export default ResourcesAndSkills;
