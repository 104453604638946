import { useSelector, shallowEqual } from 'react-redux';

const useSession = () =>
  useSelector(
    ({ session: { authenticated, user, resource } }) => ({
      authenticated,
      user,
      resource
    }),
    shallowEqual
  );

export default useSession;
