import { object, bool, func } from 'prop-types';
import { useMemo } from 'react';

import CollapseButton from 'components/common/CollapseButton';
import {
  TimelineRowWrapper,
  TimelineRow,
  TimelineRowHeaderContent,
  TimelineRowHeaderTitle,
  TimelineRowHeaderSubtitle,
  TimelineRowContent,
  TimelineRowHeader
} from 'components/common/Layout/Timeline';
import EmptyAvailability from 'components/timeline/Fragments/Availability/EmptyAvailability';
import SkillAvailability from 'components/timeline/Fragments/Availability/SkillAvailability';
import { useTimelineDates, useDispatch, useHeights } from 'hooks';
import { toggleUnassignedSkillExpanded } from 'state/actions/peopleActions';
import { getTimelineFragments } from 'utils/timelineUtilities';

import { StyledUnknownResource } from './styles';

const SkillRow = ({
  skillAssignment: { id, skills, assignments, projectNames, timePeriods },
  setEditAssignment,
  isExpanded
}) => {
  const { startDate: startDateTimeLine, endDate: endDateTimeline } = useTimelineDates();
  const timelineFragments = getTimelineFragments(startDateTimeLine, endDateTimeline);

  const timePeriodsFragments = timelineFragments(timePeriods.filter(x => x.value !== 0));

  const toggleUnassignedSkillExpandedRequest = useDispatch(toggleUnassignedSkillExpanded);
  const toggleExpandedRequest = () =>
    toggleUnassignedSkillExpandedRequest({ unassignedSkillId: id });

  const { timelineFragmentMedium, timelineFragmentBig } = useHeights();

  const skillAssignments = useMemo(
    () =>
      assignments.map(assignment => {
        const fragments = timelineFragments([assignment]);

        return (
          <TimelineRow isNested key={assignment.assignmentId}>
            <TimelineRowHeader isNested onClick={null}>
              <TimelineRowHeaderContent>
                <TimelineRowHeaderSubtitle>{assignment.projectName}</TimelineRowHeaderSubtitle>
              </TimelineRowHeaderContent>
            </TimelineRowHeader>
            <TimelineRowContent height={timelineFragmentMedium}>
              {fragments.map(({ startDate, endDate, ...fragment }) => {
                if (!fragment.value)
                  return <EmptyAvailability startDate={startDate} endDate={endDate} />;

                const { workHours, assignmentId, projectId, pending: isPending } = fragment.value;
                return (
                  <SkillAvailability
                    key={assignmentId}
                    startDate={startDate}
                    endDate={endDate}
                    workHours={workHours}
                    onClick={() => setEditAssignment({ projectId, assignmentId })}
                    isPending={isPending}
                  />
                );
              })}
            </TimelineRowContent>
          </TimelineRow>
        );
      }),
    [assignments, setEditAssignment, timelineFragmentMedium, timelineFragments]
  );

  return (
    <TimelineRowWrapper>
      <TimelineRow isExpanded={isExpanded}>
        <TimelineRowHeader onClick={toggleExpandedRequest}>
          <CollapseButton collapsed={!isExpanded} />
          <StyledUnknownResource squareSize={4} />
          <TimelineRowHeaderContent>
            <TimelineRowHeaderTitle>
              {skills.map(({ name }) => name).join(', ')}
            </TimelineRowHeaderTitle>
            <TimelineRowHeaderSubtitle>{projectNames.join(', ')}</TimelineRowHeaderSubtitle>
          </TimelineRowHeaderContent>
        </TimelineRowHeader>
        <TimelineRowContent height={timelineFragmentBig}>
          {timePeriodsFragments.map(({ startDate, endDate, ...fragment }) => {
            if (!fragment.value)
              return <EmptyAvailability startDate={startDate} endDate={endDate} />;

            const { assignmentId, value: workHours, pending: isPending } = fragment.value;
            return (
              <SkillAvailability
                key={assignmentId}
                startDate={startDate}
                endDate={endDate}
                workHours={workHours}
                isPending={isPending}
              />
            );
          })}
        </TimelineRowContent>
      </TimelineRow>
      {isExpanded && skillAssignments}
    </TimelineRowWrapper>
  );
};

SkillRow.propTypes = {
  skillAssignment: object.isRequired,
  setEditAssignment: func.isRequired,
  isExpanded: bool.isRequired
};

export default SkillRow;
