import { number, func, bool, string, instanceOf, oneOfType } from 'prop-types';
import { useIntl } from 'react-intl';

import { stringToDate } from 'utils/date';
import { fragmentFlex } from 'utils/timelineUtilities';

import EmptyAvailability from './EmptyAvailability';
import { SkillTextContainer, Pill, SkillAssignmentElement } from './styles';

const SkillAvailability = ({
  startDate: start,
  endDate: end,
  isPending,
  onClick,
  isEmpty = false,
  workHours
}) => {
  const intl = useIntl();
  const startDate = stringToDate(start);
  const endDate = stringToDate(end);

  const assignmentHoursText = workHours ? `${workHours}h` : '';
  const title = `${assignmentHoursText} ${
    isPending ? `  |  ${intl.formatMessage({ id: 'common.cap.pendingConfirmation' })}` : ''
  }`;

  if (isEmpty) {
    return Array(fragmentFlex(startDate, endDate)).fill(
      <EmptyAvailability startDate={startDate} endDate={endDate} />
    );
  }

  return (
    <SkillAssignmentElement
      startDate={startDate}
      endDate={endDate}
      onClick={onClick}
      isPending
      title={title}
    >
      <SkillTextContainer>
        {workHours && <Pill>{`${workHours}h`}</Pill>}
        {isPending && <Pill>{intl.formatMessage({ id: 'common.cap.pendingConfirmation' })}</Pill>}
      </SkillTextContainer>
    </SkillAssignmentElement>
  );
};

SkillAvailability.propTypes = {
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  isPending: bool,
  onClick: func,
  isEmpty: bool,
  workHours: number
};

export default SkillAvailability;
