import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as AccessMatrix } from 'assets/icons/access.svg';
import AccessMatrixModal from 'components/AccessRoles/AccessMatrixModal';
import AddRoleModal from 'components/AccessRoles/AddRoleModal';
import ManageRoleModal from 'components/AccessRoles/ManageRoleModal';
import RolesTable from 'components/AccessRoles/RolesTable';
import IconWithTooltip from 'components/common/IconWithTooltip';
import PageHeader from 'components/common/PageHeader';
import { ROLE_MODAL_TYPES } from 'constants/constants';
import useGroups from 'hooks/useGroups';

import { Container, Content, IconContainer } from './styles';

const AccessRoles = () => {
  const [resource, setResource] = useState({ name: '', id: '', temporalGroups: [] });
  const [modalOpen, setModalOpen] = useState('');
  const [temporalGroupId, setTemporalGroupId] = useState('');

  const { groupResources, isLoadingResources } = useGroups();

  const intl = useIntl();

  const handleAccessMatrix = () => {
    setModalOpen(ROLE_MODAL_TYPES.MATRIX);
  };

  const hide = () => {
    setModalOpen('');
    if (modalOpen !== ROLE_MODAL_TYPES.MATRIX) {
      setTemporalGroupId('');
    }
  };

  return (
    <>
      <Container>
        <PageHeader titleIntlId="common.settings" subtitleIntlId="settings.accessRoles">
          <IconContainer onClick={handleAccessMatrix}>
            <IconWithTooltip
              icon={<AccessMatrix />}
              message={intl.formatMessage({ id: 'settings.accessMatrix' })}
            />
          </IconContainer>
        </PageHeader>
        <Content>
          <RolesTable
            resources={groupResources}
            isLoading={isLoadingResources}
            setModalOpen={setModalOpen}
            setResource={setResource}
          />
        </Content>
      </Container>
      <AddRoleModal
        hide={hide}
        isShowing={modalOpen === ROLE_MODAL_TYPES.ADD || modalOpen === ROLE_MODAL_TYPES.EDIT}
        error={false}
        resource={resource}
        isEdit={modalOpen === ROLE_MODAL_TYPES.EDIT}
        temporalGroupId={temporalGroupId}
      />
      <ManageRoleModal
        hide={hide}
        isShowing={modalOpen === ROLE_MODAL_TYPES.MANAGE}
        error={false}
        resource={resource}
        setModalOpen={setModalOpen}
        setTemporalGroupId={setTemporalGroupId}
      />
      <AccessMatrixModal hide={hide} isShowing={modalOpen === ROLE_MODAL_TYPES.MATRIX} />
    </>
  );
};

export default AccessRoles;
