import dayjs from 'dayjs';
import { cloneDeep, isEqual } from 'lodash';
import { bool, func, instanceOf, number, oneOf, oneOfType, shape } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as DiscountIcon } from 'assets/icons/discount.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-resource.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh-icon.svg';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { PercentageInput, RateInput } from 'components/Financial/Inputs';
import {
  Container,
  ContainerScroll,
  Count,
  Label,
  TextField
} from 'components/Financial/Layout/Tables';
import PermissionsGate from 'components/PermissionGate';
import ResourceProfileModal from 'components/Reports/ResourceProfileModal';
import { BILLING_UNITS, BUTTON_VARIANTS } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { useDispatch, useFinancial, useRole, useStatus } from 'hooks';
import {
  editFinancialProjectDetails,
  editResourceSetup,
  getFinancialResourceSetup,
  getProjectOpenPeriodsData
} from 'state/actions/financialActions';
import { formatResourceDate } from 'utils/date';
import {
  addBillingUnitToResourceSetup,
  composedResourceSetup,
  getResourceStateVariables,
  handlePriceWithDiscount
} from 'utils/financialUtilities';
import hasPermission from 'utils/hasPermission';
import { formatAmount } from 'utils/number';

import EditButtons from '../EditButtons';
import EmptyValidator from '../EmptyValidator';
import ResourceState from '../SplitCosts/ResourceState';
import { Actions, SectionHeader } from '../SplitCosts/styles';
import GeneralDiscountEdition from './GeneralDiscountEdition';
import {
  EditResourcesButton,
  ResourceSetupContainer,
  ResourceSetupHeader,
  ResourceTableContainer,
  StyledColumn,
  StyledEmptyState,
  StyledPeopleIcon,
  StyledRow,
  UpdateResourcesButton,
  GeneralDiscountButton,
  ResourceSectionTitle,
  StyledPlusIcon
} from './styles';
import UpdateSetupModal from './UpdateSetupModal';

const minWidth = '23rem';
const dateNow = dayjs();

function ResourceSetup({
  projectId,
  billingUnit,
  isEditSetup,
  setIsEditSetup,
  handleScroll,
  openPeriodsExpanded,
  innerRef,
  globalDiscountEdit,
  setGlobalDiscountEdit,
  globalDiscountAmount,
  setGlobalDiscountAmount
}) {
  const intl = useIntl();
  const { isPending: isLoadingResources } = useStatus(getFinancialResourceSetup);
  const [isShowUpdateSetupModal, setIsShowUpdateSetupModal] = useState(false);
  const { permissions } = useRole();
  const editResourceSetupRequest = useDispatch(editResourceSetup);
  const { resourceSetup, projectDetails } = useFinancial();
  const { clientBudget, costsAdjustment, billedAdjustment, financial } = projectDetails;
  const [isShowResourceProfileModal, setIsShowResourceProfileModal] = useState(false);
  const [personId, setPersonId] = useState(0);
  const [resourceSetupCopy, setResourceSetupCopy] = useState(() => cloneDeep(resourceSetup));

  const resources = addBillingUnitToResourceSetup(resourceSetupCopy);
  const editFinancialProjectDetailsRequest = useDispatch(editFinancialProjectDetails);
  const { isFulfilled: isGlobalDiscountFulfilled } = useStatus(editFinancialProjectDetails);
  const openedPeriodsRequest = useDispatch(getProjectOpenPeriodsData);

  const showResourceDetail = personId => {
    setPersonId(personId);
    setIsShowResourceProfileModal(true);
  };

  const handleEditSetup = () => {
    setIsEditSetup(prevState => !prevState);
  };

  const handleSubmitEditSetup = async () => {
    await editResourceSetupRequest({ projectId, ...composedResourceSetup(resourceSetupCopy) });
    handleEditSetup();
  };

  const handleResources = (id, field, value) => {
    const resourcePos = resourceSetupCopy.findIndex(resource => resource.setupId === id);

    setResourceSetupCopy(prevState => {
      const copy = cloneDeep(prevState);
      copy[resourcePos][field] = value;
      return copy;
    });
  };

  const handleGeneralDiscountEdit = state => {
    setGlobalDiscountEdit(state);
  };

  const handleGlobalDiscountAmount = value => {
    const numberValue = Number(value);
    const isNumber = !Number.isNaN(numberValue);

    if (!isNumber || numberValue < 0) {
      setGlobalDiscountAmount('');
      return;
    }
    if (numberValue >= 100) {
      setGlobalDiscountAmount(100);
      return;
    }
    setGlobalDiscountAmount(value);
  };

  const handleSave = state => {
    editFinancialProjectDetailsRequest({
      id: projectId,
      globalDiscountPercentage: state !== '' ? state : 0,
      clientBudget,
      costsAdjustment,
      billedAdjustment
    });
    setGlobalDiscountEdit(false);
  };

  useEffect(() => {
    if (isGlobalDiscountFulfilled) {
      openedPeriodsRequest({ id: projectId });
    }
  }, [isGlobalDiscountFulfilled, projectId, openedPeriodsRequest]);

  useEffect(() => {
    setGlobalDiscountAmount(financial?.globalDiscountPercentage);
  }, [financial, setGlobalDiscountAmount]);

  if (!isEqual(resourceSetupCopy, resourceSetup) && !isEditSetup) {
    setResourceSetupCopy(cloneDeep(resourceSetup));
  }

  const globalDiscount = globalDiscountEdit ? (
    <GeneralDiscountEdition
      globalDiscountAmount={globalDiscountAmount}
      setGlobalDiscountEdit={handleGeneralDiscountEdit}
      setGlobalDiscountAmount={handleGlobalDiscountAmount}
      handleSave={handleSave}
      initialDiscountAmount={financial?.globalDiscountPercentage}
    />
  ) : (
    <GeneralDiscountButton
      icon={globalDiscountAmount > 0 ? <DiscountIcon /> : <StyledPlusIcon />}
      value={
        globalDiscountAmount > 0 &&
        intl.formatMessage(
          { id: 'financial.splitCosts.updateResources.globalDiscountAmount' },
          { discount: globalDiscountAmount }
        )
      }
      textIntlId="financial.splitCosts.updateResources.addGlobalDiscount"
      variant={BUTTON_VARIANTS.NEW_SECONDARY}
      onClick={() => handleGeneralDiscountEdit(true)}
    />
  );

  if (isLoadingResources)
    return (
      <ResourceSetupContainer>
        <CenteredLoading />
      </ResourceSetupContainer>
    );

  return (
    <ResourceSetupContainer>
      <Container>
        <SectionHeader>
          <ResourceSectionTitle>
            {intl.formatMessage({ id: 'financial.updateResources.title' })}
            {hasPermission(permissions, [SCOPES.manageGlobalDiscount]) && globalDiscount}
          </ResourceSectionTitle>
        </SectionHeader>
        <Actions>
          <PermissionsGate scopes={[SCOPES.updateResourcesSetup]}>
            <UpdateResourcesButton
              type="button"
              icon={<RefreshIcon />}
              id="updateResourceBtn"
              textIntlId="financial.splitCosts.updateResources"
              variant={BUTTON_VARIANTS.NEW_SECONDARY}
              onClick={() => setIsShowUpdateSetupModal(true)}
            />
          </PermissionsGate>
          {!isEditSetup && (
            <EditResourcesButton
              disabled={!hasPermission(permissions, [SCOPES.editResourcesSetup])}
              type="button"
              id="editSetupBtn"
              textIntlId="financial.splitCosts.editSetup"
              variant={BUTTON_VARIANTS.NEW_SECONDARY}
              onClick={handleEditSetup}
              icon={<EditIcon />}
            />
          )}
          {isEditSetup && (
            <EditButtons
              saveId="saveSetupBtn"
              cancelId="cancelSetupBtn"
              onSave={handleSubmitEditSetup}
              onCancel={handleEditSetup}
            />
          )}
        </Actions>
        <ResourceTableContainer>
          <ResourceSetupHeader height="5.3rem">
            <StyledColumn paddingLeft="1rem" minWidth={minWidth} firstColumn>
              <Label>
                {intl.formatMessage({ id: 'common.resources' })}
                <Count>{`(${resources?.length})`}</Count>
              </Label>
            </StyledColumn>
            {!openPeriodsExpanded && (
              <>
                <StyledColumn paddingLeft="1rem">
                  {intl.formatMessage({ id: 'common.role' })}
                </StyledColumn>
                <StyledColumn paddingLeft="1rem">
                  {intl.formatMessage({ id: 'common.allocation' })}
                </StyledColumn>
                <StyledColumn paddingLeft="1rem">
                  {intl.formatMessage({ id: 'common.rate' })}
                </StyledColumn>
                <StyledColumn>{intl.formatMessage({ id: 'common.discount' })}</StyledColumn>
              </>
            )}
          </ResourceSetupHeader>
          <ContainerScroll
            ref={innerRef}
            onScroll={handleScroll}
            maxHeight="24rem"
            minHeight="15rem"
          >
            {resources?.length ? (
              resources.map((resource, index) => {
                const {
                  setupId,
                  resourceName,
                  roleName,
                  billable,
                  allocationPercentage,
                  rate,
                  discountPercentage: discount,
                  endDate,
                  startDate,
                  personId,
                  capacity
                } = resource;

                const { grayRow, resourceStates } = getResourceStateVariables(dateNow, resources, {
                  ...resource,
                  index
                });

                return (
                  <StyledRow key={setupId} gray={grayRow}>
                    <StyledColumn strong minWidth={minWidth} firstColumn paddingLeft="1rem">
                      <ResourceState
                        name={resourceName}
                        billable={!billable}
                        startDate={formatResourceDate(startDate)}
                        endDate={formatResourceDate(endDate)}
                        personId={personId}
                        showResourceDetail={showResourceDetail}
                        {...resourceStates}
                      />
                    </StyledColumn>
                    {!openPeriodsExpanded && (
                      <>
                        <StyledColumn separator={isEditSetup}>
                          <TextField paddingLeft="1.2rem">
                            <EmptyValidator>{roleName}</EmptyValidator>
                          </TextField>
                        </StyledColumn>
                        <StyledColumn isEditing={isEditSetup}>
                          <PercentageInput
                            key={`${billingUnit}${isEditSetup}`}
                            percentageValue={allocationPercentage}
                            id={setupId}
                            field="allocationPercentage"
                            onChange={handleResources}
                            disabled={!isEditSetup}
                          />
                        </StyledColumn>
                        <StyledColumn isEditing={isEditSetup}>
                          <RateInput
                            key={`${billingUnit}${isEditSetup}`}
                            rate={rate?.[billingUnit]}
                            billingUnit={billingUnit}
                            id={setupId}
                            capacity={capacity}
                            priceWithDiscount={handlePriceWithDiscount(
                              formatAmount(
                                rate?.[billingUnit],
                                billingUnit === BILLING_UNITS.DAILY
                              ),
                              discount >= 100 ? 100 : discount
                            )}
                            onChange={handleResources}
                            disabled={!isEditSetup}
                          />
                        </StyledColumn>
                        <StyledColumn isEditing={isEditSetup}>
                          <PercentageInput
                            key={`${billingUnit}${isEditSetup}`}
                            percentageValue={discount >= 100 ? 100 : discount}
                            id={setupId}
                            field="discountPercentage"
                            onChange={handleResources}
                            disabled={!isEditSetup}
                            isDiscount
                          />
                        </StyledColumn>
                      </>
                    )}
                  </StyledRow>
                );
              })
            ) : (
              <StyledEmptyState
                titleIntlId="emptyState.resourcesSetup.title"
                textIntlId="emptyState.resourcesSetup.subtitle"
                height="28.7rem"
                icon={<StyledPeopleIcon />}
              />
            )}
          </ContainerScroll>
        </ResourceTableContainer>
      </Container>
      {isShowUpdateSetupModal && (
        <UpdateSetupModal
          open={isShowUpdateSetupModal}
          projectId={projectId}
          hide={() => setIsShowUpdateSetupModal(false)}
        />
      )}
      {isShowResourceProfileModal && (
        <ResourceProfileModal
          open={isShowResourceProfileModal}
          personId={personId}
          hide={() => setIsShowResourceProfileModal(false)}
        />
      )}
    </ResourceSetupContainer>
  );
}

ResourceSetup.propTypes = {
  projectId: number,
  billingUnit: oneOf(Object.values(BILLING_UNITS)),
  isEditSetup: bool,
  setIsEditSetup: func,
  handleScroll: func,
  openPeriodsExpanded: bool,
  innerRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
  globalDiscountEdit: bool,
  setGlobalDiscountEdit: func,
  globalDiscountAmount: number,
  setGlobalDiscountAmount: func
};

export default ResourceSetup;
