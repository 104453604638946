import { func, instanceOf, oneOfType, string } from 'prop-types';
import React from 'react';

import DatePicker from '../DatePicker';

const SingleDateRange = ({ onChange, startDate, endDate, ...props }) => (
  <DatePicker
    selected={startDate}
    onChange={onChange}
    startDate={startDate}
    endDate={endDate}
    selectsRange
    variant="new"
    {...props}
  />
);

SingleDateRange.propTypes = {
  onChange: func,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)])
};

export default SingleDateRange;
