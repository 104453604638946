import styled, { css } from 'styled-components';

import Button from '../Button';

export const StyledButton = styled(Button)`
  ${({ theme }) => theme.typography.regular};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 1.4rem;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  ${({ $variant }) =>
    $variant === 'new'
      ? css`
          color: ${({ theme }) => theme.new.colors.FE500};
          padding: 0;
          margin-top: 0.82rem;
          :hover {
            filter: brightness(70%);
          }
          svg {
            stroke: ${({ theme }) => theme.new.colors.FE500};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.gray80};
          padding: 1rem 4rem 1rem 0;
        `}
`;

export const LoadingContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: block;
  height: fit-content;
  left: auto;
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0.8);
`;
