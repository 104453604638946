import { arrayOf, bool, func, node, number, oneOfType, string } from 'prop-types';

import NewModal from 'components/common/NewModal';
import { insightRevenueGraphShape } from 'constants/shapes';

import RevenueGraph from '../RevenueGraph';

const RevenueModal = ({ name, data, limitX, handleToggle, isShowing, tabName }) => (
  <NewModal isShowing={isShowing} hide={handleToggle} title={name} maxWidth="65rem">
    <RevenueGraph data={data} limitX={limitX} name={tabName} />
  </NewModal>
);

RevenueModal.propTypes = {
  data: arrayOf(insightRevenueGraphShape),
  handleToggle: func,
  tabName: string,
  isShowing: bool,
  limitX: number,
  name: oneOfType([string, node])
};

export default RevenueModal;
