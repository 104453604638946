import { useSelector } from 'react-redux';

import { weekCalendar } from 'utils/date';

export default () =>
  useSelector(({ timeline: { startDate, endDate } }) => {
    const calendar = startDate && weekCalendar(startDate);

    return {
      startDate,
      endDate,
      calendar
    };
  });
