import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Section = styled.div`
  border-left: ${({ theme, firstLine }) => firstLine && `1px solid ${theme.new.colors.N500}`};
  flex-basis: ${({ size, totalDays }) => (size * 100) / totalDays}%;

  display: flex;
  flex-direction: column;
  justify-content: end;
  row-gap: 4px;
  flex-grow: 1;
`;

export const Month = styled.p`
  ${({ theme }) => theme.new.typography.p6Bold}

  color: ${({ theme }) => theme.new.colors.N800};
  margin-left: 8px;
`;

export const DaysContainer = styled.div`
  display: flex;
`;

export const Days = styled.div`
  align-items: center;
  color: ${({ theme, isWeekend }) => (isWeekend ? theme.new.colors.N500 : theme.new.colors.N700)};
  display: flex;
  flex-basis: 1rem;
  flex-direction: column;
  flex-grow: 1;

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.new.colors.N300};
  }

  ${({ theme, isToday }) =>
    isToday &&
    css`
      background-color: ${theme.new.colors.P500};
      color: ${theme.new.colors.white};
    `}

  & span:nth-child(1) {
    ${({ theme }) => theme.new.typography.p6Bold};
  }

  & span:nth-child(2) {
    ${({ theme }) => theme.new.typography.p6};
  }
`;
