import styled, { css } from 'styled-components';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { getMarginColor, getMarginColorBackground } from 'utils/financialColors';

const commonTextStyles = css`
  ${({ theme }) => theme.new.typography.p4};
`;

const commonSemiBoldTextStyles = css`
  ${({ theme }) => theme.new.typography.p4SemiBold};
`;

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.new.colors.neutral100};
  display: flex;
  gap: 1rem;
  height: 4.8rem;
  padding: 1.2rem 1.6rem;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderInfo = styled.p`
  display: flex;
  gap: 4rem;
  flex-grow: 1;
  width: 100%;

  & span {
    ${commonSemiBoldTextStyles};
    color: ${({ theme }) => theme.new.colors.neutral700};
    flex-basis: 1.48rem;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15rem;

    &:first-of-type {
      color: ${({ theme }) => theme.new.colors.neutral700};
      flex-grow: 2;
      cursor: not-allowed;

      ${({ enableRedirectStyles, theme }) =>
        enableRedirectStyles &&
        css`
          cursor: pointer;
          text-decoration-line: underline;
          color: ${theme.new.colors.brand};
        `}
    }

    &:nth-of-type(2) {
      flex-grow: 0;
    }

    &:last-of-type {
      color: ${({ theme }) => theme.new.colors.feedbackSuccess};
    }
  }
`;

export const ArrowIcon = styled(DownArrow)`
  transform: ${({ active }) => (active ? 'rotate(180deg)' : 'rotate(0)')};
  stroke: ${({ theme }) => theme.new.colors.brand};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1.6rem 2rem;
  row-gap: 2.4rem;
`;

export const Item = styled.div`
  display: flex;
  white-space: pre-wrap;
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
  & {
    p {
      ${commonTextStyles};
    }

    span {
      ${commonSemiBoldTextStyles};
    }
  }
`;

export const MarginContainer = styled.div`
  ${commonSemiBoldTextStyles};
  background: ${getMarginColorBackground};
  border-radius: 16px;
  color: ${getMarginColor};
  height: 2.7rem;
  padding: 0.4rem 1rem;
`;
