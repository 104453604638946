import { func, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import { PENDING, REJECTED } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS } from 'constants/constants';
import { useDispatch } from 'hooks';
import { deleteRoleRelation } from 'state/actions/settingsActions';

import { ButtonRow, StyledButton } from '../MappingForm/styles';
import { ContentWrapper } from './styles';

const DeleteConfirmation = ({ status, error, relation, hide }) => {
  const intl = useIntl();
  const deleteRequest = useDispatch(deleteRoleRelation);

  const onDelete = () => {
    deleteRequest({ id: relation?.group });
    hide();
  };

  return (
    <ContentWrapper>
      <p>
        {intl.formatMessage(
          { id: 'settings.deleteGroupRole.confirmation' },
          { group: relation?.groupName, role: relation?.roleName }
        )}
      </p>
      {status === REJECTED && <AlertMessage>{error || ''}</AlertMessage>}
      <ButtonRow gap="1.2rem">
        <StyledButton
          id="addAssignmentsBtn"
          type="button"
          variant={BUTTON_VARIANTS.NEW_SECONDARY}
          isLoading={status === PENDING}
          textIntlId="common.cancel"
          onClick={hide}
          outlined
          fullWidth
        />
        <StyledButton
          id="saveCompleteBtn"
          type="button"
          variant={BUTTON_VARIANTS.NEW_PRIMARY}
          isLoading={status === PENDING}
          onClick={onDelete}
          textIntlId="settings.groupTable.deleteRole"
          fullWidth
        />
      </ButtonRow>
    </ContentWrapper>
  );
};

DeleteConfirmation.propTypes = {
  relation: shape({ group: string, role: string, groupName: string, roleName: string }),
  error: string,
  status: string,
  hide: func
};

export default DeleteConfirmation;
