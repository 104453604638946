import styled, { css } from 'styled-components';

export const StyleBar = styled.div`
  grid-row: 1;
  background: ${({ color }) => color || 'transparent'};
  height: 1.6rem;
  grid-column: ${({ start, column, bigBar, totalDays }) =>
    `${start} / span ${column == 1 ? (bigBar || totalDays < 30) ? 1 : 2 : column}`};
  position: relative;
  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    `};

  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    `};
  border-left: ${({ theme }) => `1px solid ${theme.new.colors.white}`};
  border-right: ${({ theme, isVacationOrSpecial }) =>
    isVacationOrSpecial && `1px solid ${theme.new.colors.white}`};
`;

export const Title = styled.p`
  ${({ theme }) => theme.new.typography.p6SemiBold};
  margin-bottom: 1.2rem;
  text-align: center;
  width: 100%;
  & > p {
    color: ${({ theme }) => theme.new.colors.N500};
    margin-top: 0.2rem;
  }
`;

export const Description = styled.p`
  ${({ theme }) => theme.new.typography.p6};
  text-align: center;
  width: 100%;
`;
