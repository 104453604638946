import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { getDepartments } from 'state/actions/uiActions';
import { initializeTeams } from 'utils/helpers';

export default (withValues = false) => {
  const [formattedTeams, setFormattedTeams] = useState([]);
  const getDepartmentsRequest = useDispatch(getDepartments);
  const { status: teamsStatus, error: teamsError } = useStatus(getDepartments);
  const departments = useSelector(({ ui }) => ui.options.departments);

  useEffect(() => {
    if (isEmpty(departments)) {
      getDepartmentsRequest();
    }
  }, [departments, getDepartmentsRequest]);

  useEffect(() => {
    const formattedTeams = initializeTeams({ teams: departments, withValues });
    setFormattedTeams(formattedTeams);
  }, [setFormattedTeams, withValues, departments]);

  return { teams: departments, getDepartmentsRequest, teamsStatus, teamsError, formattedTeams };
};
