import isEmpty from 'lodash/isEmpty';
import { string, arrayOf, object, oneOf, bool, number } from 'prop-types';
import { useState } from 'react';

import DropdownIndicator from '../DropdownIndicator';
import { SelectContainer } from '../styles';
import { Select, StyledLabel, SearchIcon } from './styles';

const ARROW_SIZES = ['md', 'sm'];

const QuickSelect = ({
  arrowSize = ARROW_SIZES[0],
  name,
  label,
  showIcon = false,
  value,
  variant = 'old',
  ...props
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <SelectContainer variant={variant}>
      <StyledLabel htmlFor={name} isAbove={isMenuOpen || !isEmpty(value)} variant={variant}>
        {showIcon && <SearchIcon height="1.8" />}
        {label}
      </StyledLabel>
      <Select
        arrowSize={arrowSize}
        components={{ DropdownIndicator }}
        classNamePrefix="react-select"
        name={name}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        placeholder=""
        value={value}
        hasValue={!isEmpty(value)}
        variant={variant}
        {...props}
      />
    </SelectContainer>
  );
};

QuickSelect.propTypes = {
  arrowSize: oneOf(ARROW_SIZES),
  name: string,
  label: string,
  value: oneOf([number, string]),
  options: arrayOf(object).isRequired,
  showIcon: bool,
  variant: string
};
export default QuickSelect;
