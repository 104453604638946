import styled from 'styled-components';

import MuiTooltip from 'components/common/MuiTooltip';
import { Content } from 'components/common/MuiTooltip/styles';

export const StyledMuiTooltip = styled(MuiTooltip)`
  padding: 0;
  ${Content} {
    padding: 1.2rem 1.6rem;
  }
  & .MuiTooltip-tooltip {
    top: 12px;
    padding: 0;
    filter: drop-shadow(0px 3.5px 16px rgba(0, 8, 65, 0.16));
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  max-width: 20rem;
  overflow-wrap: break-word;
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
  white-space: normal;
  width: 100%;
`;

export const TooltipTitle = styled.p`
  ${({ theme }) => theme.typography.semiBold};
  color: ${({ theme }) => theme.fontColor};
`;

export const TooltipText = styled.p`
  ${({ theme }) => theme.typography.regular};
  color: ${({ theme }) => theme.fontColor};
  font-size: 1.2rem;
  line-height: 17px;
`;

export const IconsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
`;
