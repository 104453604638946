import { func, bool } from 'prop-types';
import { memo } from 'react';
import { useIntl, defineMessages } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { BUTTON_VARIANTS } from 'constants/constants';
import { useForm, useValidation, useTextInputProps, useStatus } from 'hooks';
import { login } from 'state/actions/userActions';
import { login as loginValidations } from 'utils/constraints';

const messages = defineMessages({
  email: { id: 'common.email' },
  password: { id: 'common.password' }
});

const fields = {
  email: 'email',
  password: 'password'
};

export const LoginForm = ({ onSubmit, suppressErrorMessage = false }) => {
  const intl = useIntl();

  const { isPending, isRejected, error } = useStatus(login);

  const validator = useValidation(loginValidations);
  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps({ handleValueChange, handleBlur, values, errors });

  return (
    <form onSubmit={handleSubmit}>
      {isRejected && !suppressErrorMessage && <AlertMessage>{error}</AlertMessage>}
      <div>
        <Input
          name="email"
          type="email"
          label={intl.formatMessage(messages.email)}
          required
          fullWidth
          {...inputProps(fields.email)}
        />
      </div>
      <div>
        <Input
          name="password"
          type="password"
          required
          fullWidth
          label={intl.formatMessage(messages.password)}
          {...inputProps(fields.password)}
        />
      </div>
      <Button
        type="submit"
        variant={BUTTON_VARIANTS.SUBMIT}
        isLoading={isPending}
        textIntlId="common.cap.signIn"
        fullWidth
      />
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: func.isRequired,
  suppressErrorMessage: bool
};

export default memo(LoginForm);
