import styled from 'styled-components';

import { ReactComponent as Logo } from 'assets/icons/logo.svg';

export const NavbarContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.new.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.P100};
  display: flex;
  height: 6.8rem;
  justify-content: space-between;
  width: 100%;
`;

export const RightControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 3.2rem;
`;

export const LeftControls = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0 2.7rem;
`;

export const StyledLogo = styled(Logo)`
  margin-right: 2.2rem;
`;
