import { func, number, oneOfType, string } from 'prop-types';

import InlineTextEditor from 'components/common/InlineTextEditor';
import { BILLING_UNITS } from 'constants/constants';
import {
  allocationValidator,
  billValidator,
  convertHoursToBillingUnit
} from 'utils/financialUtilities';
import { precisionFormat } from 'utils/number';

export const AllocationBillInput = ({
  value,
  billingUnit,
  id,
  capacity,
  allocationPercentage,
  field,
  onChange,
  onBlur,
  ...props
}) => {
  const getHoursValue = (value, billingUnit, capacity, allocationPercentage, round) => {
    const newValue = round ? Math.round(precisionFormat(value)) : precisionFormat(value);

    return convertHoursToBillingUnit(
      { from: billingUnit, to: BILLING_UNITS.HOURLY, capacity, allocationPercentage },
      newValue
    );
  };

  const handleChange = value => {
    const hoursValue = getHoursValue(value, billingUnit, capacity, allocationPercentage);
    onChange(id, field, hoursValue);
  };

  const handleBlur = value => {
    const hoursValue = getHoursValue(value, billingUnit, capacity, allocationPercentage, true);
    return onBlur && billingUnit === BILLING_UNITS.DAILY && onBlur(id, field, hoursValue);
  };

  return (
    <InlineTextEditor
      value={billingUnit === BILLING_UNITS.DAILY ? Math.round(value) : value}
      isAllowed={values =>
        field === 'bill'
          ? billValidator(values, billingUnit)
          : allocationValidator(values, billingUnit)
      }
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

AllocationBillInput.propTypes = {
  value: string,
  billingUnit: oneOfType([BILLING_UNITS.DAILY, BILLING_UNITS.HOURLY]),
  id: number,
  capacity: number,
  allocationPercentage: number,
  field: string,
  onChange: func,
  onBlur: func
};

export default AllocationBillInput;
