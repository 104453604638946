import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';

class TimelineService {
  static async getSkills(allSkills = true) {
    return httpClient.get(ENDPOINTS.skills(allSkills));
  }

  static async getCustomer() {
    return httpClient.get(ENDPOINTS.clients);
  }

  static async getDepartments() {
    return httpClient.get(ENDPOINTS.departments);
  }

  static async getLocations() {
    return httpClient.get(ENDPOINTS.locations);
  }

  static async getRoles() {
    return httpClient.get(ENDPOINTS.roles);
  }

  static async getIndustries() {
    return httpClient.get(ENDPOINTS.industries);
  }
}

export default TimelineService;
