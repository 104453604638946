import styled from 'styled-components';

export const LegendItemContainer = styled.span`
  ${({ theme }) => theme.new.typography.p5};

  color: ${({ theme }) => theme.new.colors.N800};
  display: inline-block;
  letter-spacing: 0.35px;
  line-height: 1.4rem;
  padding-top: 2.4rem;
`;
