import { bool, number, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { ProjectStatus, Circle, Count, Status } from './styles';

const StatusIndicator = ({ color, label, unit, unitInBold = true, value }) => {
  const intl = useIntl();

  return (
    <ProjectStatus>
      <Circle color={color} />
      <Status>{intl.formatMessage({ id: `${label}` })}</Status>
      {(value || value === 0) && (
        <Count unitInBold={unitInBold}>
          : {value} {unit || ''}
        </Count>
      )}
    </ProjectStatus>
  );
};

StatusIndicator.propTypes = {
  unitInBold: bool,
  color: string.isRequired,
  label: string.isRequired,
  unit: string,
  value: oneOfType([string, number])
};

export default StatusIndicator;
