import styled from 'styled-components';

import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import Button from 'components/common/Button';

export const CheckIcon = styled(CheckCircle)`
  & path {
    stroke: ${({ theme }) => theme.new.colors.white};
  }
`;

export const StyledButton = styled(Button)`
  padding: 1rem 1.6rem;
`;
