import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import Divider from 'components/common/Divider';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import LoadingWrapper from 'components/common/LoadingWrapper';
import { DISPLAY_YEAR } from 'constants/dateFormats';
import { useRequest } from 'hooks';
import { getDashboardFinancialOverviews } from 'state/actions/dashboardActions';
import { dateToString } from 'utils/date';
import { toCurrency } from 'utils/number';

import Filters from './Filters';
import MonthlyBreakdownTable from './MonthlyBreakdownTable';
import OverviewChart from './OverviewChart';
import {
  CardInfo,
  Container,
  ExpandButton,
  Info,
  InfoBlock,
  Title,
  TopContainer,
  VerticalLine
} from './styles';

const OverviewBlock = () => {
  const intl = useIntl();
  const [showBreakdown, setShowBreakdown] = useState(false);

  const [filters, setFilters] = useState({
    departmentId: null,
    year: dateToString(new Date(), DISPLAY_YEAR)
  });

  const [{ isPending: isLoading, response: data }, getFinancialOverviewRequest] = useRequest(
    getDashboardFinancialOverviews,
    {
      params: filters
    }
  );

  const financialData = data?.financialOverview;

  return (
    <Container>
      <TopContainer>
        <Title>
          <h3>{intl.formatMessage({ id: 'common.overview' })}</h3>
          <VerticalLine height="1.6rem" />
          <h4>{intl.formatMessage({ id: 'common.general' })}</h4>
        </Title>
        <Filters
          filters={filters}
          setFilters={setFilters}
          getFinancialOverviewRequest={getFinancialOverviewRequest}
        />
      </TopContainer>
      <Info>
        {['totalRevenue', 'totalCosts'].map(key => (
          <Fragment key={key}>
            <InfoBlock>
              <CardInfo>
                <h5>
                  {intl.formatMessage({
                    id: `common.${key === 'totalRevenue' ? 'revenue' : 'cost'}`
                  })}
                </h5>
                <p>{intl.formatMessage({ id: 'common.cap.yearlyValue' })}</p>
              </CardInfo>
              {isLoading ? (
                <CenteredLoading />
              ) : (
                <p>
                  {toCurrency(financialData?.[key], {
                    style: 'currency',
                    currency: 'USD',
                    notation: 'compact',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}
                </p>
              )}
            </InfoBlock>
            <VerticalLine />
          </Fragment>
        ))}
        <InfoBlock>
          <CardInfo>
            <h5>{intl.formatMessage({ id: 'common.margin' })}</h5>
            <p>{intl.formatMessage({ id: 'common.cap.yearlyValue' })}</p>
          </CardInfo>
          {isLoading ? (
            <CenteredLoading />
          ) : (
            <p>{`${Math.round(financialData?.totalMargin) || 0}%`}</p>
          )}
        </InfoBlock>
      </Info>
      <LoadingWrapper isLoading={isLoading}>
        <OverviewChart overviewData={financialData?.overview} />
        <Divider />
        <ExpandButton
          icon={<DownArrow />}
          onClick={() => setShowBreakdown(prev => !prev)}
          $isExpanded={showBreakdown}
        >
          {intl.formatMessage({ id: 'insights.financial.monthlyBreakdown.title' })}
        </ExpandButton>
        <MonthlyBreakdownTable rows={financialData?.overview} isExpanded={showBreakdown} />
      </LoadingWrapper>
    </Container>
  );
};

export default OverviewBlock;
