import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const HeaderSection = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: 2.5fr 2.5fr 1.5fr 2fr 2fr 1fr 4fr 0.5fr 0.5fr;
  width: 100%;
  padding: 0 1rem;
`;

export const EmployeesSection = styled.div`
  background-color: ${({ theme }) => theme.new.colors.N300};
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
  width: 100%;
`;
