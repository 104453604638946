import { arrayOf } from 'prop-types';

import { assignmentGraphShape, globalIndicatorsShape } from 'constants/shapes';

import { AssignmentGraph, UtilizationChart, BillableCapacity } from '..';
import { SubSection } from './styles';

const SectionInsights = ({ summaries, globalIndicators }) => (
  <>
    <AssignmentGraph data={summaries} />
    <SubSection>
      <UtilizationChart
        titleId="dashboard.actualUtilization"
        percentage={globalIndicators.actualUtilization}
      />
      <BillableCapacity globalIndicators={globalIndicators} />
    </SubSection>
  </>
);

SectionInsights.propTypes = {
  summaries: arrayOf(assignmentGraphShape).isRequired,
  globalIndicators: globalIndicatorsShape
};

export default SectionInsights;
