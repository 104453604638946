import styled from 'styled-components';

import { ReactComponent as xCircle } from 'assets/icons/x-circle.svg';

export const Box = styled.div`
  background: ${({ theme }) => theme.new.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral400};
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0.8rem;
  width: fit-content;

  p {
    ${({ theme }) => theme.new.typography.p6};
    color: ${({ theme }) => theme.new.colors.neutral700};
    line-height: 2rem;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 0.4rem;
  justify-content: space-between;

  h3 {
    ${({ theme }) => theme.new.typography.p5SemiBold};
    color: ${({ theme }) => theme.new.colors.brandDark};
    line-height: 2.1rem;
  }
`;

export const XCircleIcon = styled(xCircle)`
  &:hover {
    cursor: pointer;
  }
`;
