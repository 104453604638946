import { bool, func, node, number, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import {
  Accordion,
  AccordionDetails,
  AccordionOptional,
  AccordionSummary,
  AccordionTitle,
  ExpandIcon,
  Section
} from './styles';

const FormAccordion = ({ data: { section, title, optional }, expanded, setExpanded, children }) => {
  const intl = useIntl();

  return (
    <Accordion
      expanded={expanded === section}
      onChange={(_, isExpanded) => setExpanded(isExpanded ? section : null)}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        aria-controls={`section-${section}-content`}
        id={`section-${section}-header`}
      >
        <Section>{section}</Section>
        <AccordionTitle>
          {intl.formatMessage({ id: title })}{' '}
          {optional && <AccordionOptional>(optional)</AccordionOptional>}
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

FormAccordion.propTypes = {
  data: shape({ section: number.isRequired, title: string.isRequired, optional: bool }),
  expanded: number,
  setExpanded: func.isRequired,
  children: node.isRequired
};

export default FormAccordion;
