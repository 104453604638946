import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';

import RangeDates from 'components/common/RangeDates';
import { ReactComponent as ExternalLink } from 'components/icons/external-link.svg';
import PermissionsGate from 'components/PermissionGate';
import { SCOPES } from 'constants/permissions';
import routesPaths from 'constants/routesPaths';
import { projectOverviewFinancialShape } from 'constants/shapes';
import { useRole } from 'hooks';
import hasPermission from 'utils/hasPermission';
import { humanize, routeWithProps } from 'utils/helpers';
import { toCurrency } from 'utils/number';

import InfoTooltip from '../InfoTooltip';
import {
  Header,
  Name,
  StatusLabel,
  Status,
  Duration,
  Total,
  Count,
  Body,
  Box,
  Description,
  Title,
  Percentage,
  MarginBox,
  Label,
  Info,
  Budget,
  ClientBudget,
  Remaining,
  BoxBudget,
  StyledInfoIcon,
  Container,
  RedirectLink
} from './styles';

function ProjectOverview({ project = {}, displayClientBudget = false, onBoxClick }) {
  const intl = useIntl();
  const { permissions } = useRole();

  const disabled = !(hasPermission(permissions, [SCOPES.editFinancialInfo]) && onBoxClick);
  const { name, status, startDate, endDate, totalConfirmed, financial, clientBudget } = project;
  const { billed, costs, profits, margin } = financial || {};

  return (
    <Container>
      <Header>
        <Name>{name}</Name>
        <Info>
          <StatusLabel>
            {intl.formatMessage({ id: 'common.status' })}
            {': '}
            <Status status={status}>{status && humanize(status)}</Status>
          </StatusLabel>
          <Duration>
            {intl.formatMessage({ id: 'financial.overview.projectDuration' })}{' '}
            <RangeDates startDate={startDate} endDate={endDate} bold />
          </Duration>
          <Total>
            {intl.formatMessage({ id: 'financial.overview.totalPeople' })}{' '}
            <Count>{totalConfirmed}</Count>
          </Total>
          <PermissionsGate scopes={[SCOPES.seeProjectDetails]}>
            <RedirectLink to={routeWithProps(routesPaths.projectDetails, { id: project.id })}>
              <ExternalLink />
            </RedirectLink>
          </PermissionsGate>
        </Info>
      </Header>
      <Body>
        {displayClientBudget && (
          <BoxBudget id="clientBudget" disabled={disabled} onClick={onBoxClick}>
            <Budget>
              <ClientBudget>
                {intl.formatMessage({ id: 'financial.overview.clientBudget' })}
              </ClientBudget>
              <Title>{clientBudget ? toCurrency(clientBudget) : '--'}</Title>
            </Budget>
            <Remaining>
              {intl.formatMessage({ id: 'financial.overview.remaining' })}
              <strong>{clientBudget || billed ? toCurrency(clientBudget - billed) : '--'}</strong>
            </Remaining>
          </BoxBudget>
        )}
        <Box id="projectBilled" disabled={disabled} onClick={onBoxClick}>
          <Title>{billed ? toCurrency(billed) : '--'}</Title>
          <Description>
            {intl.formatMessage({ id: 'financial.overview.billed' })}{' '}
            <InfoTooltip textIntlId="financial.overview.billed.info">
              <StyledInfoIcon />
            </InfoTooltip>
          </Description>
        </Box>
        <Box id="projectCosts" disabled={disabled} onClick={onBoxClick}>
          <Title>{costs ? toCurrency(costs) : '--'}</Title>
          <Description>{intl.formatMessage({ id: 'financial.overview.cost' })}</Description>
        </Box>
        <Box disabled>
          <Title>{profits ? toCurrency(profits) : '--'}</Title>
          <Description>{intl.formatMessage({ id: 'financial.overview.profits' })}</Description>
        </Box>
        <MarginBox margin={financial?.margin}>
          <Percentage>{margin ? `${margin}%` : '--'}</Percentage>
          <Label>{intl.formatMessage({ id: 'common.margin' })}</Label>
        </MarginBox>
      </Body>
    </Container>
  );
}

ProjectOverview.propTypes = {
  displayClientBudget: bool,
  onBoxClick: func,
  project: projectOverviewFinancialShape
};

export default ProjectOverview;
