import { func, number, string } from 'prop-types';

import InlineTextEditor from 'components/common/InlineTextEditor';
import { percentageValidator } from 'utils/financialUtilities';
import { precisionFormat } from 'utils/number';

export const PercentageInput = ({ percentageValue, id, field, onChange, ...props }) => {
  const handleChange = value => onChange(id, field, precisionFormat(value));

  return (
    <InlineTextEditor
      symbol="%"
      value={percentageValue}
      isAllowed={percentageValidator}
      onChange={handleChange}
      {...props}
    />
  );
};

PercentageInput.propTypes = {
  percentageValue: string,
  id: number,
  field: string,
  onChange: func
};

export default PercentageInput;
