import { bool, node, number, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  AccordionWrapper,
  AccordionDetails,
  AccordionSummary,
  AccordionTitle,
  ExpandIcon
} from './styles';

const Accordion = ({ data: { section, title }, children, shrink }) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (shrink) setExpanded(false);
  }, [shrink]);

  return (
    <AccordionWrapper expanded={expanded} onChange={() => setExpanded(expanded => !expanded)}>
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        aria-controls={`section-${section}-content`}
        id={`section-${section}-header`}
      >
        <AccordionTitle>{intl.formatMessage({ id: title })} </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  data: shape({ section: number.isRequired, title: string.isRequired, optional: bool }),
  children: node.isRequired,
  shrink: bool
};

export default Accordion;
