import Switch from '@mui/material/Switch';
import styled from 'styled-components';

export const FiltersContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Selectors = styled.div`
  align-items: center;
  display: flex;

  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

export const CustomSwitch = styled(Switch)`
  margin-left: 1rem;
`;

export const SwitchLabel = styled.p`
  color: ${({ theme }) => theme.colors.gray100};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  line-height: 1.9rem;
  margin-right: 3.5rem;
`;
