import styled from 'styled-components';

export const ItemTitleContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  padding-bottom: 0.5rem;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 1.6rem;
  line-height: 2.2rem;
`;

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.gray100};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.3rem;
  line-height: 1.8rem;
`;
