import { node, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import RoundAvatar from 'components/common/RoundAvatar';

import { BasicInfo, BasicInfoContent } from './styles';

const Details = ({ title, item, avatar, subTitle, titleText }) => {
  const intl = useIntl();

  const renderItems = () => {
    if (Array.isArray(item)) {
      const names = item.map(itm => itm.name).join(', ');
      return names;
    }

    return item;
  };

  return (
    <BasicInfo>
      <RoundAvatar squareSize={4} src={avatar} />
      <BasicInfoContent titleText={titleText}>
        <h3>{titleText || intl.formatMessage({ id: title })}</h3>
        <p>{item ? renderItems() : intl.formatMessage({ id: subTitle })}</p>
      </BasicInfoContent>
    </BasicInfo>
  );
};

Details.propTypes = {
  item: oneOfType([string, node]),
  title: string,
  avatar: string,
  subTitle: string,
  titleText: string
};

export default Details;
