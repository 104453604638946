import { func } from 'prop-types';
import { useIntl } from 'react-intl';

import QuickDateSelector from 'components/common/TimeNavigation/QuickDateSelector';
import { DISPLAY_YEAR } from 'constants/dateFormats';
import { utilizationFiltersShape } from 'constants/shapes';
import { useTeams } from 'hooks';
import { addYears, dateToString } from 'utils/date';

import CustomTab from '../CustomTab';
import { Container, StyledSelect } from './styles';

const Filters = ({ filters, setFilters, getDashboardRequest }) => {
  const intl = useIntl();
  const { formattedTeams } = useTeams(true);
  const { year, group, departmentId } = filters;

  const handleOptions = (key, value) => {
    const params = { ...filters, [key]: value };
    setFilters(params);
    getDashboardRequest(params);
  };

  const handleQuickSelectorChange = (value, forward = true) => {
    const newDate = value || addYears(forward ? 1 : -1, year);
    handleOptions('year', dateToString(newDate, DISPLAY_YEAR));
  };

  return (
    <Container>
      <StyledSelect
        id="departmentIdTxt"
        name="departmentId"
        options={formattedTeams}
        isSearchable
        isClearable
        variant="new"
        withoutHighlight
        placeholder={intl.formatMessage({ id: 'common.cap.allDepartments' })}
        onChange={data => {
          handleOptions('departmentId', data?.id);
        }}
        value={formattedTeams.find(({ id }) => id === departmentId)}
      />
      <CustomTab activeTab={group} setActiveTab={handleOptions} />
      <QuickDateSelector onChange={handleQuickSelectorChange}>
        <span>{year}</span>
      </QuickDateSelector>
    </Container>
  );
};

Filters.propTypes = {
  filters: utilizationFiltersShape,
  getDashboardRequest: func,
  setFilters: func
};

export default Filters;
