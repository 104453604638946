import React from 'react';

import PageHeader from 'components/common/PageHeader';
import DepartmentBlock from 'components/Utilization/DepartmentBlock';
import OverviewBlock from 'components/Utilization/OverviewBlock';
import QuarterBlocks from 'components/Utilization/QuartersBlocks';
import UtilizationBySkill from 'components/Utilization/UtilizationBySkill';

import { Container, Content, Row } from './styles';

const Utilization = () => (
  <Container>
    <PageHeader titleIntlId="common.cap.insights" subtitleIntlId="common.capacityAndUtilization" />
    <Content>
      <OverviewBlock />
      <QuarterBlocks />
      <Row>
        <DepartmentBlock />
        <UtilizationBySkill />
      </Row>
    </Content>
  </Container>
);

export default Utilization;
