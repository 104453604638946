import styled from 'styled-components';

import { ReactComponent as Trash } from 'components/icons/delete.svg';

export const NotificationItemContainer = styled.div`
  border-bottom: 1px solid #e4e5eb;
  padding-bottom: 1.8rem;

  &:hover {
    background-color: ${props => props.theme.colors.lightGray};
    cursor: pointer;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.8rem;
  line-height: 19.07px;
`;

export const Avatar = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  margin-right: 15px;
  margin-left: 15px;
`;

export const NotificationIcon = styled.img`
  height: 3.2rem;
  margin-left: 15px;
  margin-right: 15px;
  width: 3.2rem;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: ${props => (props.color ? props.color : props.theme.colors.camouflageGreen)};
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.4rem;
  line-height: 1.9rem;
`;

export const CircleIndicator = styled.div`
  background-color: #2470e0;
  border-radius: 50%;
  display: ${props => (props.read ? 'none' : 'block')};
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
`;

export const TrashCan = styled(Trash)`
  flex-shrink: 0;

  &:hover {
    & path {
      fill: black;
    }
  }
`;

export const Actions = styled.div`
  min-width: 4rem;
  display: flex;
  justify-content: center;
`;
