import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 1.6rem;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2.4rem;
`;
