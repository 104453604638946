import styled from 'styled-components';

export const ProjectStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const Status = styled.div`
  display: flex;
  ${({ theme }) => theme.new.typography.p6};
  color: ${({ theme }) => theme.new.colors.N900};
`;

export const Circle = styled.div`
  display: flex;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: ${({ theme, color }) => color || theme.new.colors.black};
  margin-right: 0.4rem;
`;

export const Count = styled.div`
  display: flex;
  ${({ theme, unitInBold }) =>
    unitInBold ? theme.new.typography.p6Bold : theme.new.typography.p6};
  color: ${({ theme }) => theme.new.colors.N900};
`;
