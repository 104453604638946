import styled from 'styled-components';

import DropdownIcon from 'components/icons/DropdownIcon';
import { newInputBorder } from 'utils/inputColors';

import DropdownCheckbox from '../DropdownCheckbox';
import { OptionLabel } from '../DropdownCheckbox/styles';

export const PlaceholderContainer = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme, isEmpty }) =>
    isEmpty ? theme.new.colors.neutral600 : theme.new.colors.neutral800};
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  & > * {
    width: 100%;
  }
  & > div > * {
    ${({ theme }) => theme.new.typography.p5}
    max-height: 4rem;
    border: none;
    background: transparent;
    padding-left: 3rem;
  }
  & > div > svg {
    left: 0;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0;
    path {
      stroke-width: 0.8px;
    }
  }
`;

export const Button = styled.button`
  align-items: center;
  border-radius: 4px;
  border: ${({ errors, theme }) =>
    newInputBorder({ baseColor: theme.new.colors.neutral400, errors, theme })};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: inline-flex;
  height: 4.4rem;
  justify-content: space-between;
  max-width: ${({ maxWidth }) => maxWidth || '24.95rem '};
  padding: 1rem;
  position: relative;
  white-space: nowrap;
  width: 100%;
  &:hover {
    border: ${({ theme }) => `1px ${theme.new.colors.neutral500} solid`};
  }

  ${({ disabled, theme }) =>
    disabled &&
    `background-color: ${theme.colors.disabled};
border-color: ${theme.colors.gray60};`}
`;

export const Arrow = styled(DropdownIcon)`
  background-color: white;
  height: 17px;
  padding-bottom: 2px;
  position: absolute;
  right: 0.5rem;
  stroke-width: 0.4px;
  stroke: ${({ theme }) => theme.new.colors.neutral600};
  width: 34px;

  ${({ disabled, theme }) => disabled && `background-color: ${theme.colors.disabled}`}
`;

export const Label = styled.label`
  ${({ theme }) => theme.new.typography.p4};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.new.colors.neutral800};
  padding: 0 0 4px;
`;

export const Options = styled.div`
  max-width: ${({ maxWidth, isInsideAccordion }) =>
    isInsideAccordion ? `calc(${maxWidth} - 5.4rem)` : maxWidth || '23.5rem '};
  padding-left: 0.5rem;
  position: absolute;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.stickyFilterBar};
`;

export const Container = styled.div`
  margin: ${({ narrow }) => (narrow ? '0' : '0 0.4rem')};
  height: 100%;
  width: 100%;
  ${Button} {
    color: ${({ theme }) => theme.colors.gray100};
  }
`;

export const StyledDropdownCheckbox = styled(DropdownCheckbox)`
  ${OptionLabel} {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme }) => theme.new.colors.neutral800};
  }
`;
