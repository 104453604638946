import { arrayOf, func, oneOf } from 'prop-types';
import { useIntl } from 'react-intl';

import RoundAvatar from 'components/common/RoundAvatar';
import { Dots, ProgressBar } from 'components/dashboard';
import { SENIORITY_MAP, tabPanelIndexes } from 'constants/constants';
import { dashboardResourceShape } from 'constants/shapes';

import {
  Container,
  Header,
  Row,
  Column,
  Person,
  PersonInfo,
  PersonName,
  PersonRole,
  Info,
  Range,
  TruncateText,
  EmptyRange
} from './styles';

const minWidth = '23rem';

const DashboardPeopleTable = ({ resources, tabIndex, showResourceDetail }) => {
  const intl = useIntl();

  return (
    <Container>
      <Header>
        <Column minWidth={minWidth} strong>
          {intl.formatMessage({ id: 'dashboard.people.table.name' })}
        </Column>
        <Column strong>{intl.formatMessage({ id: 'common.department' })}</Column>
        <Column strong>{intl.formatMessage({ id: 'common.seniority' })}</Column>
        <Column strong>{intl.formatMessage({ id: 'common.skill' })}</Column>
        <Column strong>{intl.formatMessage({ id: 'common.projects' })}</Column>
        {tabIndex === tabPanelIndexes.availableTab ? (
          <Column strong>
            {intl.formatMessage({
              id: 'dashboard.people.table.availableFor'
            })}
          </Column>
        ) : null}
        <Column strong>{intl.formatMessage({ id: 'dashboard.people.table.assignments' })}</Column>
      </Header>
      {resources.map(resource => {
        const { id, fullName, avatarUrl, role, seniority, department, capacityHours } = resource;
        const { highlightedSkills, projects, assignmentHours, glossaryCategories } = resource;
        return (
          <Row key={id}>
            <Column minWidth={minWidth} textAlign="left" borderRight>
              <Person>
                <Dots glossary={glossaryCategories} />
                <RoundAvatar src={avatarUrl} squareSize={3} />
                <PersonInfo>
                  <PersonName onClick={() => showResourceDetail(id)}>{fullName}</PersonName>
                  <PersonRole>{role.name}</PersonRole>
                </PersonInfo>
              </Person>
            </Column>
            <Column>{department.name}</Column>
            <Column>
              {(seniority && intl.formatMessage({ id: SENIORITY_MAP.get(seniority).label })) || '-'}
            </Column>
            <Column>
              <TruncateText>
                {highlightedSkills.length > 0
                  ? highlightedSkills.map(item => item.name).join(', ')
                  : '-'}
              </TruncateText>
            </Column>
            <Column>
              <TruncateText>
                {projects.length > 0 ? projects.map(item => item.name).join(', ') : '-'}
              </TruncateText>
            </Column>
            {tabIndex === tabPanelIndexes.availableTab ? (
              <Column>
                <Info>
                  {resource?.availableFor >= 0 ? (
                    <Range>
                      {intl.formatMessage(
                        {
                          id: 'template.availableFor'
                        },
                        { days: resource.availableFor }
                      )}
                    </Range>
                  ) : (
                    <EmptyRange>-</EmptyRange>
                  )}
                </Info>
              </Column>
            ) : null}

            <Column>
              <ProgressBar
                height={0.7}
                hours={assignmentHours}
                totalHours={capacityHours}
                maxFractionDigits={1}
              />
            </Column>
          </Row>
        );
      })}
    </Container>
  );
};

DashboardPeopleTable.propTypes = {
  resources: arrayOf(dashboardResourceShape),
  tabIndex: oneOf(Object.values(tabPanelIndexes)).isRequired,
  showResourceDetail: func
};

export default DashboardPeopleTable;
