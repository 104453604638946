import { useIntl } from 'react-intl';

import { projectSummaryShape } from 'constants/shapes';

import DetailsBoxTitle from '../DetailsBoxTitle';
import ProjectItems from '../ProjectItems';
import { NotesContainer, ProjectInformation } from './styles';
import { getProjectInfo } from './utils';

const ProjectSummary = ({ project }) => {
  const intl = useIntl();
  const projectItems = getProjectInfo(project, intl);

  return (
    <>
      <DetailsBoxTitle title="searchPeople.peopleSummary.title" />
      <ProjectInformation>
        {projectItems.map(({ item, title }) => (
          <ProjectItems key={`${title}-item`} item={item} title={title} />
        ))}
      </ProjectInformation>
      <NotesContainer>
        <p>
          {intl.formatMessage({ id: 'common.notes' })}:
          <span>
            {project.notes || intl.formatMessage({ id: 'searchPeople.peopleSummary.noNotes' })}
          </span>
        </p>
      </NotesContainer>
    </>
  );
};

ProjectSummary.propTypes = {
  project: projectSummaryShape
};

export default ProjectSummary;
