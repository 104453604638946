import styled from 'styled-components';

import Button from '../Button';

export const ProjectInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: flex-start;
  margin: 2.4rem 0;
  overflow: hidden;
  width: 100%;
`;

export const DetailSection = styled.div`
  ${({ theme }) => theme.new.typography.p3SemiBold};
  border-top: 1px solid ${({ theme }) => theme.new.colors.N300};
  color: ${({ theme }) => theme.new.colors.N600};
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.4rem 0;
  width: 100%;
`;

export const NotesContainer = styled(DetailSection)`
  & span {
    ${({ theme }) => theme.new.typography.p4};
    color: ${({ theme }) => theme.new.colors.N900};
  }
`;

export const LeadersContainer = styled(DetailSection)`
  height: 26.6rem;
`;

export const CreationDates = styled(DetailSection)`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.N600};
  gap: 0.8rem;

  && > span {
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: flex;
  height: 6.4rem;
  justify-content: space-between;
  margin-bottom: 1.9rem;
  width: 100%;

  && > button {
    ${({ theme }) => theme.new.typography.p4SemiBold};

    & > svg > path {
      stroke: ${({ theme }) => theme.new.colors.white};
      stroke-width: 1.67px;
    }
  }
`;

export const Title = styled.h2`
  ${({ theme }) => theme.new.typography.h3};
  padding-top: 1rem;
`;

export const ProjectCode = styled.div`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  && > span:nth-child(2) {
    ${({ theme }) => theme.new.typography.p4};
    text-transform: capitalize;
  }
`;

export const BillablePill = styled.div`
  ${({ theme }) => theme.new.typography.p4};
  align-items: center;
  border: 1px solid
    ${({ nonBillable, theme }) => (nonBillable ? theme.new.colors.N600 : theme.new.colors.BOA500)};
  border-radius: 4px;
  color: ${({ nonBillable, theme }) =>
    nonBillable ? theme.new.colors.N600 : theme.new.colors.BOA500};
  display: flex;
  gap: 0.5rem;
  padding: 0.4rem 1.4rem;
  width: fit-content;

  && > svg > path {
    stroke: ${({ theme }) => theme.new.colors.BOA500};
  }
`;

export const DeleteProjectButton = styled(Button)`
  ${({ theme }) => theme.new.typography.p4SemiBold};
  align-self: flex-start;
  color: ${({ theme }) => theme.new.colors.FE500};
  height: 4.4rem;
  width: 18.3rem;
`;

export const ArchiveProjectButton = styled(Button)`
  ${({ theme }) => theme.new.typography.p4SemiBold};
  align-self: flex-start;
  height: 4.4rem;
  width: 18.3rem;
`;
