import styled from 'styled-components';

import Button from 'components/common/Button';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11),
    0px 4px 8px 0px rgba(79, 94, 113, 0.1);
  padding: 2rem 2.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.8rem 0;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;

  & h3 {
    ${({ theme }) => theme.new.typography.p3SemiBold};
    color: ${({ theme }) => theme.new.colors.N900};
    line-height: 2.4rem;
  }

  & h4 {
    ${({ theme }) => theme.new.typography.p3SemiBold};
    color: ${({ theme }) => theme.new.colors.P800};
  }
`;

export const VerticalLine = styled.div`
  background-color: ${({ theme }) => theme.new.colors.P300};
  height: ${({ height }) => height || '4rem'};
  width: 1px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  align-self: stretch;
`;

export const InfoBlock = styled.div`
  display: flex;
  padding: 1.6rem 1rem 1.6rem 2.4rem;
  justify-content: start;
  align-items: center;
  gap: 2.4rem;
  flex: 1 0 0;
  & p {
    ${({ theme }) => theme.new.typography.p1Bold};
    color: ${({ theme }) => theme.new.colors.P800};
  }
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-wrap: no-wrap;
  & > h5 {
    ${({ theme }) => theme.new.typography.p3SemiBold};
    color: ${({ theme }) => theme.new.colors.P800};
  }
  & > p {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme }) => theme.new.colors.N600};
  }
`;

export const ExpandButton = styled(Button)`
  border: none;
  color: ${({ theme }) => theme.new.colors.P500};
  ${({ theme }) => theme.new.typography.p6Medium};
  font-size: 1.6rem;
  line-height: 1.6rem;
  background: none;
  height: 4.4rem;
  padding: 0;
  text-decoration: underline;

  svg {
    transition: all 0.2s ease-in-out;
    transform: ${({ $isExpanded }) =>
      $isExpanded ? `rotate(180deg) scale(${20 / 22})` : ` scale(${20 / 22})`};
    order: 2;
    path {
      stroke-width: 2px;
      stroke: ${({ theme }) => theme.new.colors.P500};
    }
  }
`;
