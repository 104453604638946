import styled from 'styled-components';

export const TimelineGridContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
`;

export const Grid = styled.div`
  border-right: ${({ theme, isUnderline }) =>
    `0.5px solid ${isUnderline ? theme.colors.modalBackground : theme.colors.grayOpacity07}`};
  flex: 1;

  &:last-child {
    border: none;
  }
`;

export const GridTrackSpace = styled.div`
  width: calc(${({ theme }) => theme.constantWidths.timelineHeader});
`;
