import styled from 'styled-components';

const getHeight = ({ big, height: heightOverride }) => {
  let height = 1.4;

  if (big) height = 3.4;
  if (heightOverride) height = heightOverride;

  return height;
};

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 0.5rem;
  display: flex;
  height: ${props => `${getHeight(props)}rem`};
  width: 100%;
`;

export const Filler = styled.div`
  background-color: ${({ color }) => color};
  border-top-left-radius: ${({ roundedLeft }) => (roundedLeft ? '0.5rem' : '0')};
  border-bottom-left-radius: ${({ roundedLeft }) => (roundedLeft ? '0.5rem' : '0')};
  border-bottom-right-radius: ${({ roundedRight }) => (roundedRight ? '0.5rem' : '0')};
  border-top-right-radius: ${({ roundedRight }) => (roundedRight ? '0.5rem' : '0')};
  display: ${({ percentage }) => (percentage === 0 ? 'none' : 'flex')};
  height: 100%;
  width: ${({ percentage }) => (percentage ? `${percentage}%` : '0')};
`;
