import { node, bool } from 'prop-types';

import { Collapsible } from './styles';

const AccordionBody = ({ children, collapsed = false }) => (
  <Collapsible collapsed={collapsed}>{children}</Collapsible>
);

AccordionBody.propTypes = {
  children: node.isRequired,
  collapsed: bool
};

export default AccordionBody;
