import { bool, func, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import NewModal from 'components/common/NewModal';
import { useStatus } from 'hooks';
import { addRoleRelation } from 'state/actions/settingsActions';

import DeleteConfirmation from './DeleteConfirmation';
import MappingForm from './MappingForm';

const MappingModal = ({ isShowing, setModalOpen, relation, isDelete }) => {
  const [closed, setClosed] = useState(false);
  const intl = useIntl();

  const action = addRoleRelation;
  const { status, error, isFulfilled } = useStatus(action);

  const hide = () => {
    if (!error) {
      setModalOpen('');
      setClosed(false);
    }
  };

  useEffect(() => {
    isFulfilled && closed && hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFulfilled]);

  return (
    <NewModal
      isShowing={isShowing}
      hide={() => setModalOpen('')}
      contentPadding="2.4rem"
      title={intl.formatMessage({
        id: isDelete ? 'settings.groupTable.deleteRole' : 'settings.addGroupRole.title'
      })}
      maxWidth="64rem"
      height={isDelete ? '30rem' : '50rem'}
    >
      {isDelete ? (
        <DeleteConfirmation status={status} error={error} relation={relation} hide={hide} />
      ) : (
        <MappingForm
          status={status}
          error={error}
          setModalOpen={setModalOpen}
          setClosed={setClosed}
        />
      )}
    </NewModal>
  );
};

MappingModal.propTypes = {
  setModalOpen: func.isRequired,
  isShowing: bool,
  relation: shape({ group: string, role: string, groupName: string, roleName: string }),
  isDelete: bool
};

export default MappingModal;
