import isEmpty from 'lodash/isEmpty';
import { object, oneOfType, string, instanceOf, func, bool, arrayOf } from 'prop-types';
import { Fragment, useMemo } from 'react';

import Availability from 'components/timeline/Fragments/Availability';
import EmptyAvailability from 'components/timeline/Fragments/Availability/EmptyAvailability';
import NewAvailability from 'components/timeline/Fragments/Availability/NewAvailability';
import { PROJECT_TYPES } from 'constants/constants';
import { availabilitiesShape } from 'constants/shapes';
import { personToAssignmentOption } from 'utils/assignmentFormsUtilities';
import { minDate, isAfter, isBefore } from 'utils/date';
import { getTimelineFragments, splitFragment } from 'utils/timelineUtilities';

const PersonProjectsAssignments = ({
  project,
  person,
  availabilities,
  startDateTimeline,
  endDateTimeline,
  setAddAssignment,
  setEditAssignment,
  isAvailabilityEmpty = false,
  isReadOnly = false
}) => {
  const timelineFragments = useMemo(
    () =>
      getTimelineFragments(
        startDateTimeline,
        endDateTimeline
      )(availabilities.filter(availability => !isEmpty(availability.value.assignmentIds))),
    [startDateTimeline, endDateTimeline, availabilities]
  );

  return timelineFragments.map(({ startDate, endDate, ...fragment }, index) => {
    if (fragment.value) {
      const availability = fragment.value;
      const currentProjectIndex = availability.value.projectIds.indexOf(project.id);
      const assignmentId = availability.value.assignmentIds[currentProjectIndex];
      const availabilityId = `assignment-${assignmentId}`;
      const handleOnClick = () =>
        setEditAssignment({
          projectId: availability.value.projectIds[currentProjectIndex],
          assignmentId
        });

      return (
        <Availability
          projectId={project.id}
          isNonBillable={
            availability.value.assignmentsTypes[currentProjectIndex] === PROJECT_TYPES.NON_BILLABLE
          }
          key={availabilityId}
          busyPercentage={availability.value.busyPercentage}
          startDate={availability.startDate}
          endDate={availability.endDate}
          onClick={!isReadOnly ? handleOnClick : null}
          isEmpty={isAvailabilityEmpty}
          assignmentWorkHours={availability.value.assignmentsWorkHours[currentProjectIndex]}
          isPending={availability.value.pending[currentProjectIndex]}
          note={availability.value.notes?.find(
            note => !!note && note.assignmentId === assignmentId
          )}
          isBackfillPosition={
            availability?.value?.backfillPosition && availability?.value?.backfillPosition[0]
          }
        />
      );
    }

    const projectId = `${project.id}-${index}`;

    if (isReadOnly)
      return <EmptyAvailability key={projectId} startDate={startDate} endDate={endDate} />;

    const newAssignmentMaxEndDate = minDate(endDateTimeline, project.endDate);

    const onNewAssignmentClick = ({ startDate: selectedStart, endDate: selectedEnd }) =>
      setAddAssignment({
        project,
        initialValues: {
          personId: personToAssignmentOption(person),
          startDate: selectedStart,
          endDate: selectedEnd
        }
      });

    if (index === timelineFragments.length - 1) {
      const [assignmentFragment, emptyFragment] = splitFragment(
        { startDate, endDate },
        newAssignmentMaxEndDate
      );

      if (isAfter(startDate, project.endDate))
        return <EmptyAvailability key={projectId} startDate={startDate} endDate={endDate} />;

      if (emptyFragment && isBefore(emptyFragment.endDate < endDateTimeline))
        return (
          <Fragment key={projectId}>
            <NewAvailability
              startDate={assignmentFragment.startDate}
              endDate={assignmentFragment.endDate}
              onSelectionComplete={onNewAssignmentClick}
            />
            <EmptyAvailability
              startDate={emptyFragment.startDate}
              endDate={emptyFragment.endDate}
            />
          </Fragment>
        );
    }

    return (
      <NewAvailability
        key={projectId}
        startDate={startDate}
        endDate={endDate}
        onSelectionComplete={onNewAssignmentClick}
      />
    );
  });
};

PersonProjectsAssignments.propTypes = {
  person: object.isRequired,
  startDateTimeline: oneOfType([string, instanceOf(Date)]),
  endDateTimeline: oneOfType([string, instanceOf(Date)]),
  project: object.isRequired,
  setAddAssignment: func,
  setEditAssignment: func,
  isAvailabilityEmpty: bool,
  availabilities: arrayOf(availabilitiesShape).isRequired,
  isReadOnly: bool
};

export default PersonProjectsAssignments;
