import { PERMISSIONS } from 'constants/permissions';

import useSession from './useSession';

const useRole = () => {
  const { user } = useSession();
  const role = user?.group;
  const mainPermissions = PERMISSIONS[role] || [];

  const temporalRoles = user?.temporalGroups || [];

  const getTemporalPermissions = group => PERMISSIONS[group] || [];

  const permissions = temporalRoles?.reduce(
    (roles, { group }) => {
      const temporalPermissions = getTemporalPermissions(group);
      const newPermissions = temporalPermissions.filter(permission => !roles.includes(permission));
      return [...roles, ...newPermissions];
    },
    [...mainPermissions]
  );

  return { role, temporalRoles, permissions };
};

export default useRole;
