import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ small }) => (small ? '5rem' : '50rem')};
  flex-direction: column;
  background: ${({ theme, className }) =>
    className === 'accessRoles' ? 'transparent' : theme.colors.white};

  & > img {
    max-height: ${({ small }) => small && '3rem'};
    visibility: ${({ small }) => small && 'hidden'};
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.8rem;
  line-height: 2.4rem;
  display: flex;
  color: ${({ theme }) => theme.colors.grey100};
  margin-bottom: 0.5rem;
  ${({ small, theme }) => small && theme.typography.p4bold};
`;

export const Subtitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.4rem;
  line-height: 1.9rem;
  display: flex;
  color: ${({ theme }) => theme.colors.grey100};
`;
