import { func, number } from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import AssignmentOptionsButton from 'components/common/AssignmentOptionsButton';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import PermissionsGate from 'components/PermissionGate';
import EditAssignmentModal from 'components/timeline/EditAssignmentModal';
import { PROJECT_TYPES, SORT_DIR } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { useRequest } from 'hooks';
import { isolatedDeleteAssignment, isolatedEditAssignment } from 'state/actions/assignmentActions';
import { getProjectOverviewDetail } from 'state/actions/projectActions';
import { convertAssignmentsDataToTableFormat, sortTable } from 'utils/projectsOverviewUtilities';

import Cell from './Cell';
import {
  ArrowIcon,
  Column,
  HeaderColumnContainer,
  NewAssignmentButton,
  NewAssignmentColumn,
  OptionsColumn,
  Row,
  Table,
  TableWrapper
} from './styles';

const greaterFunc = (a, b) => {
  if (!b) return true;
  if (a?.fullName && b?.fullName) return a?.fullName > b?.fullName;
  if (a?.order && b?.order) return a.order > b.order;
  return a > b;
};

const lowerFunc = (a, b) => {
  if (!a) return true;
  if (a?.fullName && b?.fullName) return a?.fullName < b?.fullName;
  if (a?.order && b?.order) return a.order < b.order;
  return a < b;
};

const columnsKeys = {
  assignment: 'assignment',
  status: 'status',
  role: 'role',
  seniority: 'seniority',
  allocations: 'allocation',
  startDate: 'startDate',
  endDate: 'endDate'
};

const columns = Object.values(columnsKeys).map(columnKey => ({
  key: columnKey,
  header: `projectsOverview.detail.table.columns.${columnKey}`,
  greaterFunc,
  lowerFunc
}));

const tieBreaker = (a, b) => {
  const fullNameA = a?.assignment?.fullName;
  const fullNameB = b?.assignment?.fullName;
  return fullNameA && fullNameB ? fullNameA.localeCompare(fullNameB) : a;
};

const getValue = (assignmentItem, key) => {
  if (key === 'role') {
    return assignmentItem.assignment.role;
  }
  if (key === 'assignment') {
    return {
      ...assignmentItem.assignment,
      mainSkills: assignmentItem.mainSkills,
      nonBillable: assignmentItem.assignationType === PROJECT_TYPES.NON_BILLABLE,
      isSupport: assignmentItem?.support,
      discount: assignmentItem.discountPercentage,
      isBackfillPosition: assignmentItem.backfillPosition
    };
  }
  return assignmentItem[key];
};

const AssignmentsTable = ({ projectId, getProjectsRequest, setAddAssignmentTo }) => {
  const intl = useIntl();

  const [assignments, setAssignments] = useState();
  const [editAssignment, setEditAssignment] = useState(null);

  const [sortConfig, setSortConfig] = useState({
    key: 'status',
    direction: SORT_DIR.desc
  });
  const onSort = ({ key: columnKey }) => {
    let direction = SORT_DIR.desc;
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === SORT_DIR.desc) {
      direction = SORT_DIR.asc;
    }
    setSortConfig({
      key: columnKey,
      direction
    });
  };

  const sortedAssignments = useMemo(() => {
    if (!sortConfig) return assignments;
    const sortedRows = sortTable({ columns, sortConfig, rows: assignments || [], tieBreaker });
    return sortedRows;
  }, [assignments, sortConfig]);

  const [{ isPending: isLoadingAssignments }, getAssignmentsRequest] = useRequest(
    getProjectOverviewDetail,
    {
      fulfilledCallback: payload =>
        setAssignments(convertAssignmentsDataToTableFormat(payload?.assignments)),
      params: { projectId }
    }
  );

  const isLoading = isLoadingAssignments && !assignments;

  const handleRefreshData = () => {
    getAssignmentsRequest();
    getProjectsRequest && getProjectsRequest();
    setAssignments(null);
  };

  return (
    <>
      <TableWrapper>
        <Table>
          <thead>
            <Row height="2.1rem" isHeader>
              {columns?.map(column => {
                const isSorting = sortConfig && sortConfig.key === column.key;
                return (
                  <th key={column.key}>
                    <HeaderColumnContainer
                      onClick={() => !isLoading && onSort(column)}
                      $disabled={isLoading}
                    >
                      <span>{column.header && intl.formatMessage({ id: column.header })}</span>
                      <Column width="1.2rem">
                        <ArrowIcon
                          direction={isSorting ? sortConfig.direction : SORT_DIR.desc}
                          $isSorting={!isLoading && isSorting}
                          disabled={isLoading}
                        />
                      </Column>
                    </HeaderColumnContainer>
                  </th>
                );
              })}
              <td />
            </Row>
          </thead>

          <tbody>
            {!isLoading &&
              sortedAssignments?.map(assignmentItem => {
                const { id: assignmentId, overassigned } = assignmentItem;

                return (
                  <Fragment key={assignmentId}>
                    <Row key={assignmentId}>
                      {Object.values(columns).map(({ key }) => (
                        <Cell
                          value={getValue(assignmentItem, key)}
                          columnKey={key}
                          key={`${assignmentId}-${key}`}
                          overassigned={overassigned}
                        />
                      ))}
                      <OptionsColumn>
                        <AssignmentOptionsButton
                          status={assignmentItem?.status?.label}
                          assignment={{ ...assignmentItem, assignmentId, projectId }}
                          editAssignment={setEditAssignment}
                          isTimelineView={false}
                          handleRefreshData={handleRefreshData}
                        />
                      </OptionsColumn>
                    </Row>
                  </Fragment>
                );
              })}
            {!isLoading && (
              <NewAssignmentColumn>
                <PermissionsGate scopes={[SCOPES.createAssignment]}>
                  <NewAssignmentButton
                    id="newAssignment"
                    type="button"
                    textIntlId="common.newAssignment"
                    icon={<PlusIcon />}
                    onClick={() => setAddAssignmentTo()}
                  />
                </PermissionsGate>
              </NewAssignmentColumn>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      {isLoading && <CenteredLoading />}
      {editAssignment && (
        <EditAssignmentModal
          isShowing={!!editAssignment}
          hide={() => setEditAssignment(null)}
          projectId={editAssignment.projectId}
          assignmentId={editAssignment.assignmentId}
          editAssignmentAction={isolatedEditAssignment}
          handleRefreshData={handleRefreshData}
          deleteAssignmentAction={isolatedDeleteAssignment}
          overview
        />
      )}
    </>
  );
};

AssignmentsTable.propTypes = {
  projectId: number,
  getProjectsRequest: func,
  setAddAssignmentTo: func
};
export default AssignmentsTable;
