import { string, bool, func } from 'prop-types';
import { memo } from 'react';

import chevronDown from 'assets/icons/chevron-down.svg';
import chevronUp from 'assets/icons/chevron-up.svg';

import { Collapsible, Title } from './styles';

const AccordionHeader = ({ title, toggleCollapse, collapsed = false }) => (
  <Collapsible onClick={toggleCollapse}>
    <img className="chevron-img" src={collapsed ? chevronDown : chevronUp} alt="chevron" />
    <Title>{title}</Title>
  </Collapsible>
);

AccordionHeader.propTypes = {
  title: string.isRequired,
  collapsed: bool,
  toggleCollapse: func.isRequired
};

export default memo(AccordionHeader);
