import { bool, node, oneOf, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { BADGE_PILL_VARIANTS } from 'constants/constants';

import { Badge } from './styles';

const BadgePill = ({
  children,
  className,
  color,
  fontColor,
  borderColor,
  isFilled,
  isRounded,
  textIntlId,
  variant,
  isIconOnly
}) => (
  <Badge
    variant={variant}
    color={color}
    borderColor={borderColor}
    fontColor={fontColor}
    isFilled={isFilled}
    className={className}
    isRounded={isRounded}
    isIconOnly={isIconOnly}
  >
    {children}
    {textIntlId && <FormattedMessage id={textIntlId} />}
  </Badge>
);

BadgePill.propTypes = {
  children: node,
  className: string,
  color: string,
  fontColor: string,
  borderColor: string,
  isFilled: bool,
  isRounded: bool,
  textIntlId: string,
  variant: oneOf(Object.values(BADGE_PILL_VARIANTS)),
  isIconOnly: bool
};

export default BadgePill;
