import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import LoginForm from 'components/user/LoginForm';
import LoginGoogle from 'components/user/LoginGoogle';
import routesPaths from 'constants/routesPaths';
import { useSession, useStatus } from 'hooks';
import { login, loginGoogle } from 'state/actions/userActions';

import {
  LoginContainer,
  LoginFormContainer,
  LoginTitle,
  ErrorContainer,
  StyledWarningSvg
} from './styles';

const Login = () => {
  const [googleAuthError, setGoogleAuthError] = useState(null);
  const { authenticated } = useSession();
  const dispatch = useDispatch();
  const { error: loginError } = useStatus(login);
  const { error: googleLoginError } = useStatus(loginGoogle);
  const loginRequest = values => dispatch(login(values));
  const loginGoogleRequest = values => dispatch(loginGoogle(values));

  if (authenticated) {
    return <Redirect to={routesPaths.index} />;
  }

  const resetErrors = () => {
    dispatch(login.reset());
    dispatch(loginGoogle.reset());
    setGoogleAuthError(null);
  };

  const handleLogin = data => {
    resetErrors();
    loginRequest(data);
  };

  const handleGoogleLogin = data => {
    resetErrors();
    loginGoogleRequest(data);
  };

  const handleGoogleAuthError = errorResponse => {
    setGoogleAuthError(errorResponse.errorDescription);
  };

  return (
    <LoginContainer>
      {(loginError || googleLoginError || googleAuthError) && (
        <ErrorContainer>
          <StyledWarningSvg />
          {loginError ? (
            <FormattedMessage id="login.form.genericError" />
          ) : (
            googleLoginError || googleAuthError
          )}
        </ErrorContainer>
      )}
      <LoginFormContainer>
        <LoginTitle>
          <FormattedMessage id="login.title" />
        </LoginTitle>
        <LoginForm onSubmit={handleLogin} suppressErrorMessage />
        <LoginGoogle onSubmit={handleGoogleLogin} onError={handleGoogleAuthError} />
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default Login;
