import { string } from 'prop-types';
import { isValidElement } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import getBreadcrumbs from 'utils/getBreadcrumbs';

import { BreadcrumbContainer, BreadcrumbItem } from './styles';

const Breadcrumb = ({ lastBread }) => {
  const match = useRouteMatch();
  const breadcrumbs = getBreadcrumbs(match.path, lastBread);

  return (
    <BreadcrumbContainer>
      {breadcrumbs.map(({ name, path }) => (
        <BreadcrumbItem key={isValidElement(name) ? name.props.id : name}>
          {path ? <Link to={path}>{name}</Link> : name}
        </BreadcrumbItem>
      ))}
    </BreadcrumbContainer>
  );
};

Breadcrumb.propTypes = {
  lastBread: string
};

export default Breadcrumb;
