import { arrayOf } from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';

import { technologiesShape } from 'constants/shapes';
import theme from 'constants/theme';
import {
  calculateDisplayedItemsCount,
  getTagColors,
  orderItemsByRequirement
} from 'utils/searchPeopleUtilities';

import BadgePill from '../BadgePill';
import MuiTooltip from '../MuiTooltip';
import { RemainingTechnologies, TagsContainer } from './styles';

const TechnologiesInfo = ({ items, skillsPerAssignment }) => {
  const [displayedItemsCount, setDisplayedItemsCount] = useState(items.length);
  const tagsRef = useRef(null);
  useLayoutEffect(() => {
    const orderedByRequirements = orderItemsByRequirement(items, skillsPerAssignment);
    setDisplayedItemsCount(calculateDisplayedItemsCount(tagsRef, orderedByRequirements));
  }, [tagsRef, items, skillsPerAssignment]);

  const displayedItems = items.slice(0, displayedItemsCount);
  const remainingItemCount = items.slice(displayedItemsCount);
  const displayRemainingItems = items.map(skill => skill.skillName);

  return (
    <TagsContainer ref={tagsRef}>
      {displayedItems.map(({ skillId, skillName }) => {
        const { backgroundColor, borderColor, fontColor } = getTagColors(
          skillsPerAssignment,
          skillName
        );
        return (
          <BadgePill
            key={skillId}
            isRounded
            color={backgroundColor}
            borderColor={borderColor}
            fontColor={fontColor}
          >
            {skillName}
          </BadgePill>
        );
      })}
      {remainingItemCount.length > 0 && (
        <RemainingTechnologies>
          <BadgePill
            isRounded
            color={theme.new.colors.N300}
            borderColor={theme.new.colors.N300}
            fontColor={theme.new.colors.black}
          >
            +{remainingItemCount.length}
            <MuiTooltip>{displayRemainingItems.join(', ')}</MuiTooltip>
          </BadgePill>
        </RemainingTechnologies>
      )}
    </TagsContainer>
  );
};

TechnologiesInfo.propTypes = {
  items: arrayOf(technologiesShape),
  skillsPerAssignment: arrayOf(technologiesShape).isRequired
};

export default TechnologiesInfo;
