import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';

import BoxContainer from 'components/common/BoxContainer';
import Divider from 'components/common/Divider';
import RangeDates from 'components/common/RangeDates';
import { SCOPES } from 'constants/permissions';
import { projectOverviewFinancialShape } from 'constants/shapes';
import { useRole } from 'hooks';
import hasPermission from 'utils/hasPermission';
import { humanize } from 'utils/helpers';
import { toCurrency } from 'utils/number';
import InfoTooltip from 'components/Financial/InfoTooltip';

import {
  Header,
  StatusLabel,
  Status,
  Body,
  MarginBox,
  Info,
  Container,
  Duration,
  Total,
  Count
} from '../styles';
import OverviewBox from './OverviewBox';
import { Percentage, Label, StyledInfoIcon, MarginInformation } from './styles';

function OverviewHeader({ project = {}, displayClientBudget = false, onBoxClick }) {
  const intl = useIntl();
  const { permissions } = useRole();

  const disabled = !(hasPermission(permissions, [SCOPES.editFinancialInfo]) && onBoxClick);
  const { status, startDate, endDate, totalConfirmed, financial, clientBudget } = project;
  const { billed, costs, profits, margin } = financial || {};

  const boxes = [
    {
      id: 'remainingRevenue',
      descriptionId: 'financial.overview.remainingRevenue',
      value: clientBudget ? toCurrency(clientBudget - billed) : toCurrency(0),
      tooltipTextId: 'financial.overview.remainingRevenue.info',
      disabled
    },
    {
      id: 'projectBilled',
      descriptionId: 'financial.overview.billed',
      value: billed ? toCurrency(billed) : '--',
      tooltipTextId: 'financial.overview.billed.info',
      disabled
    },
    {
      id: 'projectCosts',
      descriptionId: 'financial.overview.cost',
      value: costs ? toCurrency(costs) : '--',
      tooltipTextId: 'financial.overview.cost.info',
      disabled
    },
    {
      id: 'profits',
      descriptionId: 'financial.overview.profits',
      value: profits ? toCurrency(profits) : '--',
      tooltipTextId: 'financial.overview.profits.info',
      disabled: true
    }
  ];

  return (
    <BoxContainer>
      <Container>
        <Header>
          <Info>
            <StatusLabel>
              {intl.formatMessage({ id: 'common.status' })}
              {': '}
              <Status status={status}>{status && humanize(status)}</Status>
            </StatusLabel>
            <Duration>
              {intl.formatMessage({ id: 'financial.overview.projectDuration' })}{' '}
              <RangeDates startDate={startDate} endDate={endDate} bold />
            </Duration>
            <Total>
              {intl.formatMessage({ id: 'financial.overview.totalPeople' })}{' '}
              <Count>{totalConfirmed}</Count>
            </Total>
          </Info>
        </Header>
        <Divider margin="0.2rem 0 2.4rem 0" />
        <Body>
          {displayClientBudget && (
            <OverviewBox
              id="clientBudget"
              disabled={disabled}
              onBoxClick={onBoxClick}
              description={intl.formatMessage({ id: 'financial.overview.clientBudget' })}
              value={clientBudget ? toCurrency(clientBudget) : '--'}
              tooltipTextId="financial.overview.clientBudget.info"
            />
          )}
          {boxes.map(({ id, descriptionId, value, disabled, tooltipTextId }) => (
            <OverviewBox
              key={id}
              id={id}
              disabled={disabled}
              onBoxClick={onBoxClick}
              description={intl.formatMessage({ id: descriptionId })}
              value={value}
              tooltipTextId={tooltipTextId}
            />
          ))}
          <MarginBox margin={financial?.margin}>
            <MarginInformation>
              <Label>{intl.formatMessage({ id: 'common.margin' })}</Label>
              <InfoTooltip textIntlId="financial.overview.margin.info">
                <StyledInfoIcon />
              </InfoTooltip>
            </MarginInformation>
            <Percentage>{margin ? `${margin}%` : '--'}</Percentage>
          </MarginBox>
        </Body>
      </Container>
    </BoxContainer>
  );
}

OverviewHeader.propTypes = {
  displayClientBudget: bool,
  onBoxClick: func,
  project: projectOverviewFinancialShape
};

export default OverviewHeader;
