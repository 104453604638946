import { bool, func, number, oneOf } from 'prop-types';
import { useIntl } from 'react-intl';

import { GLOSSARY } from 'constants/constants';

import { Dot, Info, Title, Description, Counter, GlossaryItemContainer } from './styles';

const GlossaryItem = ({ item, value, selected, setCategories }) => {
  const intl = useIntl();

  const handleAction = key => {
    setCategories(prevState => ({ ...prevState, [key]: !selected }));
  };

  const { label, key, color } = GLOSSARY[item];

  const getDescription = () => {
    if (value > 0) {
      return (
        <>
          <Counter color={color}>{value}</Counter>
          {intl.formatMessage({ id: 'common.resources' })}
        </>
      );
    }
    return key ? intl.formatMessage({ id: 'dashboard.people.summary.noResources' }) : '';
  };

  return (
    <GlossaryItemContainer
      key={item}
      onClick={() => (key ? handleAction(key) : null)}
      active={!!key}
      selected={selected}
    >
      <Dot color={color} />
      <Info>
        <Title>{label}</Title>
        <Description>{getDescription()}</Description>
      </Info>
    </GlossaryItemContainer>
  );
};

GlossaryItem.propTypes = {
  item: oneOf(Object.keys(GLOSSARY)).isRequired,
  setCategories: func.isRequired,
  value: number,
  selected: bool
};

export default GlossaryItem;
