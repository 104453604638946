import { arrayOf, bool, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import BadgePill from 'components/common/BadgePill';
import { SPECIAL_EVENT_TYPES } from 'constants/constants';
import { DISPLAY_MONTH_DAY_YEAR } from 'constants/dateFormats';
import { specialEventsShape } from 'constants/shapes';
import { dateToString } from 'utils/date';
import { composeEditTrainingSkill } from 'utils/resourceProfileUtilities';

import {
  ActionsContainer,
  CloseIcon,
  Container,
  Content,
  EditIcon,
  Header,
  IconButton,
  IconsGroup,
  Item,
  ItemContent,
  ItemValue,
  PlusIcon,
  Row,
  StyledDeleteButton
} from './styles';

const TrainingsSection = ({
  handleAction,
  onEdit,
  onDelete,
  specialEvents,
  isEditing,
  setIsEditing
}) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Container>
      <Header>
        <h3>{intl.formatMessage({ id: 'reportsProfile.trainingSkill' })}</h3>
        <IconsGroup>
          <IconButton type="button" onClick={handleAction}>
            <PlusIcon />
          </IconButton>
          {specialEvents?.length > 0 && (
            <IconButton type="button" onClick={() => setIsEditing(value => !value)}>
              <CloseIcon isEditing={isEditing} /> <EditIcon isEditing={isEditing} />
            </IconButton>
          )}
        </IconsGroup>
      </Header>
      <Content>
        {specialEvents?.map((specialEvent, index) => {
          const { startDate, endDate, skillName, specialEventType } = specialEvent;
          return (
            <Item key={index}>
              {
                {
                  [SPECIAL_EVENT_TYPES.training]: (
                    <ItemContent>
                      <Row>
                        <span>{`${intl.formatMessage({
                          id: 'reports.resource.section.trainingIn'
                        })}: `}</span>
                        <BadgePill
                          fontColor={theme.new.colors.strongActive}
                          color={theme.new.colors.ghostWhite}
                          borderColor={theme.new.colors.softActive}
                        >
                          {skillName}
                        </BadgePill>
                      </Row>
                      <Row gap="4rem">
                        <Row>
                          <span>{`${intl.formatMessage({ id: 'common.cap.startDate' })}: `}</span>
                          <ItemValue>{dateToString(startDate, DISPLAY_MONTH_DAY_YEAR)}</ItemValue>
                        </Row>
                        <Row>
                          <span> {`${intl.formatMessage({ id: 'common.cap.endDate' })}: `}</span>
                          <ItemValue>{dateToString(endDate, DISPLAY_MONTH_DAY_YEAR)}</ItemValue>
                        </Row>
                      </Row>
                    </ItemContent>
                  ),
                  [SPECIAL_EVENT_TYPES.conversion]: (
                    <ItemContent>
                      <Row gap="4rem">
                        <Row>
                          <span>{`${intl.formatMessage({ id: 'common.cap.startDate' })}: `}</span>
                          <ItemValue>{dateToString(startDate, DISPLAY_MONTH_DAY_YEAR)}</ItemValue>
                        </Row>
                        <Row>
                          <span> {`${intl.formatMessage({ id: 'common.cap.endDate' })}: `}</span>
                          <ItemValue>{dateToString(endDate, DISPLAY_MONTH_DAY_YEAR)}</ItemValue>
                        </Row>
                      </Row>
                      <Row gap="1rem">
                        <span>{`${intl.formatMessage({
                          id: 'reports.resource.section.skillConversion'
                        })}: `}</span>
                        <BadgePill
                          fontColor={theme.new.colors.strongActive}
                          color={theme.new.colors.ghostWhite}
                          borderColor={theme.new.colors.softActive}
                        >
                          {skillName}
                        </BadgePill>
                      </Row>
                    </ItemContent>
                  )
                }[specialEventType]
              }
              <ActionsContainer isEditing={isEditing}>
                <IconsGroup>
                  <IconButton
                    type="button"
                    onClick={() => onEdit(composeEditTrainingSkill(specialEvent))}
                  >
                    <EditIcon />
                  </IconButton>
                  <StyledDeleteButton onClick={() => onDelete(specialEvent)} />
                </IconsGroup>
              </ActionsContainer>
            </Item>
          );
        })}
      </Content>
    </Container>
  );
};

TrainingsSection.propTypes = {
  handleAction: func,
  onEdit: func,
  specialEvents: arrayOf(specialEventsShape),
  onDelete: func,
  isEditing: bool,
  setIsEditing: func
};

export default TrainingsSection;
