import { Tab } from '@mui/material';
import { arrayOf, bool, number } from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import CounterInfo from 'components/common/CounterInfo';
import ResourceProfileModal from 'components/Reports/ResourceProfileModal';
import { tabPanelIndexes } from 'constants/constants';
import { dashboardResourceShape, dashboardAvailabilitiesShape } from 'constants/shapes';
import { formatFractionDigits } from 'utils/number';

import DashboardPeopleTable from '../PeopleTable';
import { Container, StyledTabs, TabPanelContainer } from './styles';

const DashboardPeopleTabs = ({
  resourcesAll,
  availabilities,
  isResourcesAllPending,
  isAvailabilitiesPending,
  workingDays
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(0);
  const [availableResources, setAvailableResources] = useState([]);
  const [personId, setPersonId] = useState(0);
  const [isShowResourceProfileModal, setIsShowResourceProfileModal] = useState(false);
  const loadingMessage = intl.formatMessage({ id: 'common.loading' });
  const getTabLabel = tabName =>
    `${intl.formatMessage({
      id: `dashboard.people.tab.${tabName}`
    })} (${tabName === 'all' ? resourcesAll.length : availableResources.length})`;

  const handleChange = newValue => {
    setValue(newValue);
  };

  const [availableHoursPercentage, FTE] = useMemo(() => {
    let availableHoursPercentage = 0;
    let FTE = 0;

    if (resourcesAll.length) {
      const totalCapacityHours = resourcesAll.reduce(
        (acc, { capacityHours }) => acc + capacityHours,
        0
      );
      const availableHours = resourcesAll.reduce((acc, { capacityHours, assignmentHours }) => {
        const result = capacityHours - assignmentHours;
        return result > 0 ? result + acc : acc;
      }, 0);
      const workingDaysHours = workingDays * 7.5;

      if (totalCapacityHours > 0)
        availableHoursPercentage = Math.round((availableHours * 100) / totalCapacityHours);
      if (workingDaysHours) FTE = formatFractionDigits(availableHours / workingDaysHours, 1);
    }

    return [availableHoursPercentage, FTE];
  }, [resourcesAll, workingDays]);

  const showResourceDetail = personId => {
    setPersonId(personId);
    setIsShowResourceProfileModal(true);
  };

  useEffect(() => {
    const availabilitiesIds = availabilities
      .filter(availability => availability.available)
      .map(({ id }) => id);

    const availableResourcesArray = resourcesAll.filter(resource =>
      availabilitiesIds.includes(resource.id)
    );

    setAvailableResources(availableResourcesArray);
  }, [resourcesAll, availabilities]);

  return (
    <>
      <Container>
        <StyledTabs
          value={value}
          onChange={(_event, newValue) => handleChange(newValue)}
          variant="fullWidth"
        >
          <Tab label={isResourcesAllPending ? loadingMessage : getTabLabel('all')} />
          <Tab
            icon={
              isAvailabilitiesPending ? (
                ''
              ) : (
                <>
                  <CounterInfo
                    label={`${availableHoursPercentage}%`}
                    message={intl.formatMessage({ id: 'dashboard.people.available.infoTooltip' })}
                  />
                  <CounterInfo
                    label={`FTE: ${FTE}`}
                    message={intl.formatMessage({ id: 'dashboard.people.FTE.infoTooltip' })}
                  />
                </>
              )
            }
            iconPosition="end"
            label={isAvailabilitiesPending ? loadingMessage : getTabLabel('available')}
          />
        </StyledTabs>
        <TabPanelContainer
          role="tabpanel"
          hidden={value !== tabPanelIndexes.allTab}
          id={`tabpanel-${tabPanelIndexes.allTab}`}
        >
          <DashboardPeopleTable
            resources={resourcesAll}
            tabIndex={tabPanelIndexes.allTab}
            showResourceDetail={showResourceDetail}
          />
        </TabPanelContainer>
        <TabPanelContainer
          role="tabpanel"
          hidden={value !== tabPanelIndexes.availableTab}
          id={`tabpanel-${tabPanelIndexes.availableTab}`}
        >
          <DashboardPeopleTable
            resources={availableResources}
            tabIndex={tabPanelIndexes.availableTab}
            showResourceDetail={showResourceDetail}
          />
        </TabPanelContainer>
      </Container>

      {isShowResourceProfileModal && (
        <ResourceProfileModal
          open={isShowResourceProfileModal}
          personId={personId}
          hide={() => setIsShowResourceProfileModal(false)}
        />
      )}
    </>
  );
};

DashboardPeopleTabs.propTypes = {
  availabilities: arrayOf(dashboardAvailabilitiesShape),
  isAvailabilitiesPending: bool,
  isResourcesAllPending: bool,
  resourcesAll: arrayOf(dashboardResourceShape),
  workingDays: number
};

export default DashboardPeopleTabs;
