import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.new.colors.N400};
  display: flex;
  padding: 4px;
`;

export const Tab = styled.button`
  ${({ theme }) => theme.new.typography.p5Bold};

  color: ${({ theme }) => theme.new.colors.N600};
  padding: 8px 12px;

  ${({ theme, active }) =>
    active &&
    css`
      color: ${theme.new.colors.white};
      background-color: ${theme.new.colors.P800};
      border-radius: 4px;
    `}
`;
