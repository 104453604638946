import styled from 'styled-components';

import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';

export const DownArrowIcon = styled(DownArrow)`
  cursor: pointer;
  margin: auto 16px auto 0;
  stroke: ${({ theme }) => theme.new.colors.N900};
  transform: ${({ $isExpanded }) => `scale(${20 / 24}) ${$isExpanded ? 'rotate(180deg)' : ''}`};
  transition: all 150ms ease-in-out;
`;

export const OptionsContainer = styled.div`
  opacity: 0;
  margin: auto 0.5rem auto auto;
`;

export const TimelineRow = styled.div`
  display: flex;
  min-height: 3.5rem;
`;

export const ProjectHeader = styled.div`
  display: flex;
  padding: 12px 8px;
  width: ${({ theme }) => theme.constantWidths.timelineHeader};
  background-color: ${({ $isExpanded, theme }) =>
    $isExpanded ? theme.new.colors.P100 : theme.new.colors.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.new.colors.N300}`};

  &:hover {
    background-color: ${({ theme }) => theme.new.colors.N100};

    ${OptionsContainer} {
      opacity: 1;
    }
  }
`;

export const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ProjectTitle = styled.h4`
  ${({ theme }) => theme.new.typography.p4SemiBold};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.N900};
  display: flex;
  gap: 1rem;
`;

export const ExpandedRow = styled.div`
  align-items: start;
  border-left: 3px solid ${({ theme }) => theme.new.colors.P500};
  display: flex;
  height: ${({ $isExpanded }) => ($isExpanded ? 'auto' : '0')};
  overflow: ${({ $isExpanded }) => ($isExpanded ? 'visible' : 'hidden')};
  flex-direction: column;
`;

export const ArrowButton = styled.button`
  visibility: ${({ $isHidden }) => ($isHidden ? 'hidden' : 'visible')};
`;

export const LoadingContainer = styled.div`
  width: ${({ theme }) => `calc(${theme.constantWidths.timelineHeader} - 0.3rem )`};
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  background: ${({ theme }) => theme.new.colors.N100};
`;
