import { arrayOf, func } from 'prop-types';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';

import ActiveFilters from 'components/common/ActiveFilters';
import FilterDropdown from 'components/common/FilterDropdown';
import {
  AVAILABILITY_OPTIONS,
  ENGLISH_OPTIONS,
  RESOURCE_FILTERS,
  SENIORITY_OPTIONS
} from 'constants/constants';
import { peopleSearchShape } from 'constants/shapes';
import { useAssignment, useDebounce, useRoles, useSkills } from 'hooks';
import { applyFilters, getActiveFilters } from 'utils/filtersUtilities';
import { initializeOptionsWithDefault, initializeOptionsWithFieldName } from 'utils/helpers';

import {
  FiltersContainer,
  FiltersSection,
  StyledSearch,
  Table,
  ActiveFiltersContainer
} from './styles';

const ResourcesFilterPanel = ({ initialResources, onFilterChange }) => {
  const { rolesOptions } = useRoles();
  const { skillOptions } = useSkills();

  const onInit = useRef(false);

  const {
    assignment: { role: assignmentRole }
  } = useAssignment();

  const initialValuesDropdowns = useMemo(
    () => ({
      searchName: '',
      roles: initializeOptionsWithFieldName(rolesOptions, RESOURCE_FILTERS.roles),
      skills: initializeOptionsWithFieldName(skillOptions, RESOURCE_FILTERS.skills),
      seniority: initializeOptionsWithFieldName(
        SENIORITY_OPTIONS,
        RESOURCE_FILTERS.seniority,
        true
      ),
      availability: initializeOptionsWithFieldName(
        AVAILABILITY_OPTIONS,
        RESOURCE_FILTERS.availability,
        true
      ),
      englishLevel: initializeOptionsWithFieldName(
        ENGLISH_OPTIONS,
        RESOURCE_FILTERS.englishLevel,
        true
      )
    }),
    [rolesOptions, skillOptions]
  );

  const [debouncedValue, setDebouncedValue] = useState('');

  const [selectedFilters, setSelectedFilters] = useState(() => ({
    ...initialValuesDropdowns
  }));

  const initializeFilters = useCallback(
    () => ({
      roles: assignmentRole
        ? initializeOptionsWithDefault(initialValuesDropdowns.roles, [assignmentRole.name])
        : []
    }),
    [assignmentRole, initialValuesDropdowns]
  );

  const handleDebouncedName = useDebounce(debouncedName => {
    setDebouncedValue(debouncedName);
  }, 300);

  const handleSearchByName = name => {
    setSelectedFilters(prev => ({ ...prev, searchName: name }));
    handleDebouncedName(name);
  };

  const handleFilterBySelect = ({ options, key }) => {
    setSelectedFilters(prev => ({ ...prev, [key]: options }));
  };

  const handleClearFilters = () => {
    setSelectedFilters({
      ...initialValuesDropdowns
    });
  };

  const activeFilters = getActiveFilters(selectedFilters);

  useEffect(() => {
    if (rolesOptions.length && skillOptions.length && !onInit.current) {
      if (initialResources.length > 0) {
        setSelectedFilters(prev => ({
          ...prev,
          ...initializeFilters()
        }));
        onInit.current = true;
      }
    }
  }, [rolesOptions, skillOptions, initializeFilters, initialResources]);

  useEffect(() => {
    if (onInit.current) {
      const filteredResources = applyFilters(initialResources, {
        ...selectedFilters,
        name: debouncedValue
      });
      onFilterChange(filteredResources);
    }
  }, [selectedFilters, onFilterChange, initialResources, debouncedValue]);

  return (
    <Table>
      <FiltersContainer>
        <FiltersSection>
          <FilterDropdown
            labelId="common.role"
            options={selectedFilters.roles}
            onFilter={handleFilterBySelect}
            queryKey={RESOURCE_FILTERS.roles}
            withIntlOptions={false}
            searchIntlId="common.cap.searchRole"
          />
          <FilterDropdown
            labelId="common.seniority"
            options={selectedFilters.seniority}
            onFilter={handleFilterBySelect}
            queryKey={RESOURCE_FILTERS.seniority}
          />
          <FilterDropdown
            labelId="common.skills"
            options={selectedFilters.skills}
            onFilter={handleFilterBySelect}
            queryKey={RESOURCE_FILTERS.skills}
            withIntlOptions={false}
            searchIntlId="common.cap.searchSkill"
          />
          <FilterDropdown
            labelId="common.availability"
            options={selectedFilters.availability}
            onFilter={handleFilterBySelect}
            queryKey={RESOURCE_FILTERS.availability}
          />
          <FilterDropdown
            labelId="common.cap.englishLevel"
            options={selectedFilters.englishLevel}
            onFilter={handleFilterBySelect}
            queryKey={RESOURCE_FILTERS.englishLevel}
          />
        </FiltersSection>
        <StyledSearch
          placeholderId="common.cap.searchPeople"
          value={selectedFilters.searchName}
          onChange={handleSearchByName}
          variant="new"
        />
      </FiltersContainer>
      <ActiveFiltersContainer>
        <ActiveFilters
          filters={selectedFilters}
          setFilters={setSelectedFilters}
          handleClear={handleClearFilters}
          selectedOptions={activeFilters}
          withIntlLabel={false}
        />
      </ActiveFiltersContainer>
    </Table>
  );
};

ResourcesFilterPanel.propTypes = {
  initialResources: arrayOf(peopleSearchShape),
  onFilterChange: func.isRequired
};

export default ResourcesFilterPanel;
