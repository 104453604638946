import styled, { css } from 'styled-components';

export const RangeDatesContainer = styled.p`
  ${({ $bold }) =>
    $bold &&
    css`
      & > span:nth-child(odd) {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
      }};
    `}
`;
