import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { RowInputWrapper } from 'components/common/Layout/Row';
import { SCOPES } from 'constants/permissions';
import { useRole } from 'hooks';
import hasPermission from 'utils/hasPermission';

import fields from '../fields.json';
import { StyledNotes } from './styles';

const Notes = () => {
  const intl = useIntl();
  const { control } = useFormContext();
  const { permissions } = useRole();

  return (
    <RowInputWrapper fullWidth>
      <Controller
        name={fields.notes}
        control={control}
        render={({ field }) => (
          <StyledNotes
            {...field}
            id="notesTxt"
            fullWidth
            type="text"
            rows={8}
            placeholder={intl.formatMessage({ id: 'projectForm.notes.placeholder' })}
            disabled={!hasPermission(permissions, [SCOPES.editProjects, SCOPES.editProjectsNotes])}
          />
        )}
      />
    </RowInputWrapper>
  );
};

export default Notes;
