import { func } from 'prop-types';
import { useState } from 'react';

import Button from 'components/common/Button';
import { BUTTON_VARIANTS } from 'constants/constants';
import { filtersToParamsAssignment } from 'utils/dashboardUtilities';
import * as Dates from 'utils/date';

import DateSelector from '../../DateSelector';
import { FiltersContainer, Selectors } from './styles';

const month = Dates.getFirstAndLastDayOfCurrentMonth(Dates.dayJSToDate);

const initialFilters = {
  startDate: month.startDate,
  endDate: month.endDate
};

const DashboardFilters = ({ onClear, onRequest }) => {
  const [filters, setFilters] = useState(initialFilters);
  const [clearDateFilters, setClearDateFilters] = useState(false);

  const handleDates = ({ startDate, endDate }) => {
    setFilters(prevState => ({
      ...prevState,
      startDate,
      endDate
    }));
  };

  const handleApplyFilters = () => {
    onRequest(filtersToParamsAssignment(filters));
  };

  const handleClearFilters = () => {
    setFilters({
      ...initialFilters
    });
    setClearDateFilters(true);
    onClear();
  };

  return (
    <FiltersContainer>
      <DateSelector
        startDate={filters.startDate}
        endDate={filters.endDate}
        onChange={handleDates}
        clearDateFilters={clearDateFilters}
        setClearDateFilters={setClearDateFilters}
      />
      <Selectors>
        <Button
          variant={BUTTON_VARIANTS.APPLY}
          onClick={handleApplyFilters}
          textIntlId="filter.dashboard.apply"
        />
        <Button
          variant={BUTTON_VARIANTS.CLEAR}
          onClick={handleClearFilters}
          textIntlId="filter.dashboard.clear"
        />
      </Selectors>
    </FiltersContainer>
  );
};

DashboardFilters.propTypes = {
  onRequest: func,
  onClear: func
};

export default DashboardFilters;
