import { useCallback } from 'react';

export default ({ values, setValues, runValidations, handleBlur, errors }) =>
  useCallback(
    fieldKey => ({
      onChange: target => {
        const newValues = {
          ...values,
          [fieldKey]: target
        };
        runValidations && runValidations(newValues);
        setValues(newValues);
      },
      value: values[fieldKey] || '',
      onBlur: () => handleBlur && handleBlur(fieldKey),
      errors: errors && errors[fieldKey]
    }),
    [values, errors, runValidations, setValues, handleBlur]
  );
