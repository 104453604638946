import styled from 'styled-components';

import Button from 'components/common/Button';

export const Form = styled.form`
  width: 51.8rem;
`;

export const StyledButton = styled(Button)`
  width: 22.4rem;
`;
