import { func, string } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import NotesModal from './NotesModal';
import { NoteBody, NoteTitle, StyledTooltip, EditNoteSvg } from './styles';

const NotesTooltip = ({ id, note, handleEditNote }) => {
  const [openModal, setOpenModal] = useState(false);
  const intl = useIntl();

  const handleModal = () => {
    setOpenModal(prevState => !prevState);
  };
  return (
    <>
      {note && (
        <StyledTooltip
          id={id}
          place="top"
          type="light"
          effect="float"
          offset={{ top: '10%' }}
          globalEventOff="click"
          isCapture
          clickable
        >
          <div onClick={e => e.stopPropagation()}>
            <NoteTitle>
              <h4>{intl.formatMessage({ id: 'common.note' })}</h4>
              <EditNoteSvg onClick={handleModal} />
            </NoteTitle>
            <NoteBody>{note}</NoteBody>
          </div>
        </StyledTooltip>
      )}
      <NotesModal
        isShowing={openModal}
        hide={() => setOpenModal(false)}
        note={note}
        handleEditNote={handleEditNote}
      />
    </>
  );
};

NotesTooltip.propTypes = {
  handleEditNote: func.isRequired,
  id: string,
  note: string
};

export default NotesTooltip;
