import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import {
  getMarginColor,
  getMarginColorBackground,
  getMarginColorBorder,
  getStatusColor,
  getStatusColorBackground
} from 'utils/financialColors';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.8rem;
  width: 100%;
`;

export const Name = styled.div`
  ${({ theme }) => theme.new.typography.h2};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
`;

export const StatusLabel = styled.div`
  font-weight: ${({ theme }) => theme.new.fontWeights.normal};
  font-size: 1.4rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
  gap: 0.7rem;
`;

export const RedirectLink = styled(Link)`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 2.8rem;
  justify-content: center;
  width: 2.8rem;
  &:hover {
    background-color: ${({ theme }) => theme.new.colors.neutral300};
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0.8rem;
  height: 2.5rem;
  background: ${getStatusColorBackground};
  border-radius: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.4rem;
  line-height: 2.1rem;
  text-align: center;
  color: ${getStatusColor};
`;

export const Duration = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.4rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral800};
  gap: 0.7rem;
`;

export const Total = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.4rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral800};
  gap: 0.7rem;
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0.8rem;
  min-width: 2.8rem;
  height: 2.5rem;
  background: ${({ theme }) => theme.new.colors.brandLight};
  border-radius: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.4rem;
  line-height: 2.1rem;
  justify-content: center;
  color: ${({ theme }) => theme.new.colors.brandDark};
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

export const Info = styled.div`
  display: flex;
  gap: 8.3rem;
`;

export const Box = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  border-radius: 0.7rem;
  width: 100%;
  gap: 0.5rem;
  background: ${({ theme }) => theme.new.colors.white};
  cursor: text;
  user-select: text;
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
      &:focus {
        border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
        box-shadow: 0 0 0 4px rgba(71, 111, 242, 0.12);
      }
      &:hover {
        ${({ theme }) => theme.new.shadows.large}
        border: 1px solid ${({ theme }) => theme.new.colors.neutral600};
      }
      &:active {
        ${({ theme }) => theme.new.shadows.large}
        background-color: ${({ theme }) => theme.new.colors.neutral100};
        border: 1px solid ${({ theme }) => theme.new.colors.neutral400};
      }
    `}
`;

export const Description = styled.div`
  ${({ theme }) => theme.new.typography.p4};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral700};
  display: flex;
  gap: 1.9rem;
  justify-content: center;
`;

export const Title = styled.span`
  ${({ theme }) => theme.new.typography.p2SemiBold};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
`;

export const Percentage = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 2.4rem;
  line-height: 3.6rem;
`;

export const MarginBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.6rem;
  gap: 0.3rem;
  background: ${getMarginColorBackground};
  color: ${getMarginColor};
  border: 1px solid ${getMarginColorBorder};
  border-radius: 0.7rem;
  font-family: ${({ theme }) => theme.new.fontFamily};
`;

export const Label = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.8rem;
  line-height: 2.6rem;
  display: flex;
  align-items: center;
`;

export const Budget = styled.div`
  align-items: center;
  border-radius: 7px;
  display: flex;
  padding: 1rem 2rem;
  width: 100%;
  height: 100%;
`;

export const ClientBudget = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.3rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral600};
  max-width: 6rem;
  text-align: left;
`;

export const Remaining = styled.div`
  display: flex;
  border-radius: 0 0 7px 7px;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2rem;
  gap: 1rem;
  width: 100%;
  height: 3.5rem;
  background: #edf1fd;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: ${({ theme }) => theme.new.colors.neutral900};

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;

export const BoxBudget = styled.button`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  border-radius: 0.7rem;
  width: 100%;
  &:focus {
    border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
    box-shadow: 0 0 0 4px rgba(71, 111, 242, 0.12);
  }
  &:hover {
    ${({ theme }) => theme.new.shadows.large}
    border: 1px solid ${({ theme }) => theme.new.colors.neutral600};
  }
  &:active {
    ${({ theme }) => theme.new.shadows.large}
    background-color: ${({ theme }) => theme.new.colors.neutral100};
    border: 1px solid ${({ theme }) => theme.new.colors.neutral400};
  }
`;

export const StyledInfoIcon = styled(InfoIcon)`
  stroke: ${({ theme }) => theme.new.colors.neutral500};
  fill: ${({ theme }) => theme.new.colors.white};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.new.fontFamily};
  width: 100%;
`;
