export const REQUEST_DATE = 'YYYY-MM-DD';

export const DISPLAY_DATE = 'MMM d yyyy';

export const ONLY_DATE = 'D';
export const DISPLAY_MONTH = 'MMM';
export const DISPLAY_FULL_MONTH = 'MMMM';
export const DISPLAY_MONTH_DAY_DATE = 'MMM DD';
export const DISPLAY_MONTH_DAY_YEAR_DATE = 'MMM DD YYYY';
export const DISPLAY_WEEK_DATE = "'W' w '('MMM yyyy')'";
export const DISPLAY_MONTH_DATE = 'MMM yyyy';
export const DISPLAY_MONTH_DATE_DAYJS = 'MMM YYYY';
export const DISPLAY_QUARTER_DATE = 'yyyy, QQQ';
export const DISPLAY_YEAR_DATE = 'yyyy';
export const DISPLAY_DAY_MONTH_YEAR = 'DD MMM YYYY';
export const DISPLAY_MONTH_DAY_YEAR_STANDARD = 'MM/DD/YYYY';
export const DISPLAY_WEEK_DAY_FULL_DATE = 'ddd MMMM D YYYY';
export const DISPLAY_DAY_MONTH = 'DD MMM';
export const DISPLAY_DAY_MONTH_YEAR_NEW = 'DD MMM, YYYY';
export const DISPLAY_MONTH_DAY_YEAR = 'MMM DD, YYYY';
export const DISPLAY_MONTH_DAY_YEAR_DATEPICKER = 'MMM dd, yyyy';
export const DISPLAY_YEAR = 'YYYY';
export const DISPLAY_YEAR_MONTH = 'YYYY-MM';

export const MONDAY = 'M';
export const FRIDAY = 'F';
