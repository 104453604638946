import { arrayOf, bool, node, string } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useHistory } from 'react-router-dom';

import { subRoute } from 'constants/shapes';

import { Container, Menu } from './styles';

const SubItem = ({ children, icon, isOpen, title }) => {
  const intl = useIntl();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleAction = () => {
    children.length && history.push(children[0].route);
  };

  const handleMenuOpen = () => {
    !isOpen && setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleClick = e => {
    e.stopPropagation();
  };

  return (
    <Container isOpen={isOpen} onMouseEnter={handleMenuOpen} onMouseLeave={handleMenuClose}>
      <button type="button" onClick={handleAction}>
        {icon}
        <span>{intl.formatMessage({ id: title })}</span>
        {!isOpen && (
          <Menu isOpen={isMenuOpen}>
            {children?.map(({ title, route }) => (
              <NavLink key={route} to={route} activeClassName="active" onClick={handleClick}>
                <span>{intl.formatMessage({ id: title })}</span>
              </NavLink>
            ))}
          </Menu>
        )}
      </button>
      {children?.map(({ title, route }) => (
        <NavLink key={route} to={route} activeClassName="active">
          <span>{intl.formatMessage({ id: title })}</span>
        </NavLink>
      ))}
    </Container>
  );
};

SubItem.propTypes = {
  children: arrayOf(subRoute),
  icon: node,
  isOpen: bool,
  title: string
};

export default SubItem;
