import { number, shape } from 'prop-types';
import { useTheme } from 'styled-components';

const YAxisTick = ({ x = 0, y = 0, payload }) => {
  const theme = useTheme();

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={5}
        dx={0}
        fill={theme.new.colors.N600}
        fontSize={16}
        fontWeight={theme.fontWeights.normal}
        fontFamily={theme.new.fontFamily}
        textAnchor="end"
      >
        {payload.value}%
      </text>
    </g>
  );
};

YAxisTick.propTypes = {
  payload: shape({ value: number }),
  x: number,
  y: number
};

export default YAxisTick;
