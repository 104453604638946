import styled, { css } from 'styled-components';

import { ReactComponent as NoteIcon } from 'assets/icons/note.svg';
import { Row, Column, ContainerScroll } from 'components/Financial/Layout/Tables';

export const Container = styled.div`
  border-radius: 1rem 1rem 0.8rem 0.8rem;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.new.colors.P500};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  min-width: ${({ minWidth }) => minWidth || 'auto'};
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.new.colors.brand};
  border-radius: 0.8rem 0.8rem 0 0;
  color: ${({ theme }) => theme.new.colors.white};
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  display: flex;
  height: 2rem;
  justify-content: end;
  align-items: start;
  width: 100%;
  position: relative;
`;

export const Name = styled.div`
  display: flex;
  ${({ theme }) => theme.new.typography.p5SemiBold};
  margin-right: auto;
`;

export const Range = styled.div`
  display: flex;
  ${({ theme }) => theme.new.typography.p6};
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  gap: 0.4rem;
`;

export const Titles = styled.div`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N500};
  display: flex;
  height: 5.3rem;
  justify-content: space-between;
  margin: 0 1.2rem;
`;

export const NoteSvg = styled(NoteIcon)`
  margin: 0.2rem 2rem 0 0;

  stroke: ${({ theme, $isEditing }) =>
    $isEditing ? theme.new.colors.brandDark : theme.new.colors.white};
`;

export const StyledColumn = styled(Column)`
  color: ${({ theme }) => theme.new.colors.N600};
  justify-content: flex-start;
  width: 50%;
  max-width: 10.5rem;
  ${({ theme, isEditing, disabled }) =>
    isEditing &&
    !disabled &&
    css`
      background-color: ${theme.new.colors.white};
      color: ${theme.new.colors.black};
    `};
  ${({ disabled }) => disabled && 'cursor: not-allowed;'};
  padding: ${({ padding }) => padding};
`;

export const StyledRow = styled(Row)`
  border-color: ${({ theme }) => theme.new.colors.N300};
  justify-content: space-between;
  padding: 0;

  &:last-of-type {
    border: none;
  }

  ${({ theme, isEditing }) =>
    isEditing &&
    css`
      background: ${theme.new.colors.white};
    `};
`;

export const TotalContainer = styled.div`
  background: ${({ theme }) => theme.colors.whiteOpacity07};
  border-radius: 0 0 0.8rem 0.8rem;
  margin-top: ${({ negativeMargin, length }) => negativeMargin && `-${10 - 6 * (length - 1)}rem`};
  display: flex;
  flex-grow: 1;
  gap: 1.2rem;
  overflow-x: auto;
  padding: 1.2rem;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 1.1rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.new.colors.white};
    width: 5rem;
    background-color: ${({ theme }) => theme.new.colors.neutral300};
  }
`;

export const OpenPeriodContainerScroll = styled(ContainerScroll)`
  border: none;
  padding: 0 1.2rem;
`;

export const OpenPeriodInput = styled.input`
  border: 1px solid ${({ theme }) => theme.new.colors.N400};
  border-radius: 0.4rem;
  margin: 0 auto;
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.neutral900};
  padding: 2rem 1.2rem;
  width: 9.2rem;
`;
