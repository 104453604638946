import { number } from 'prop-types';
import { useTheme } from 'styled-components';

const LabelFormatter = ({ y, value }) => {
  const theme = useTheme();

  return (
    <text
      x={330}
      y={y}
      dy={-7}
      dx={15 - value?.toString()?.length * 6}
      fill={theme.new.colors.N600}
      fontSize={14}
      fontWeight={theme.fontWeights.normal}
      fontFamily={theme.new.fontFamily}
    >
      {`${value}`}
    </text>
  );
};

LabelFormatter.propTypes = {
  value: number,
  y: number
};

export default LabelFormatter;
