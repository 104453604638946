import { useCallback } from 'react';

export default ({ inputProps, values, setValues }) =>
  useCallback(
    fieldKey => ({
      ...inputProps(fieldKey),
      onChange: target => {
        setValues({
          ...values,
          [fieldKey]: Boolean(target)
        });
      }
    }),
    [inputProps, setValues, values]
  );
