import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 2rem 2rem 14rem;
  gap: 1.5rem;
  isolation: isolate;
  background: ${({ theme }) => theme.new.colors.white};
  border-radius: 0.6rem;
`;

export const ListItem = styled.li`
  width: 100%;
  list-style-type: none;
`;

export const ContainerItem = styled(Link)`
  font-family: ${({ theme }) => theme.new.fontFamily};
  font-style: normal;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 1.6rem 2rem 2rem;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.03), 0px 8px 24px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  outline: none;
  ${({ theme }) => theme.new.shadows.extraLarge};
  border-radius: 0.6rem;
  &:focus {
    box-shadow: 0 0 0 4px rgba(71, 111, 242, 0.12);
  }
  &:hover {
    ${({ theme }) => theme.new.shadows.extraLarge}
    border: 1px solid ${({ theme }) => theme.new.colors.neutral600};
  }
  &:active {
    ${({ theme }) => theme.new.shadows.large}
    background: ${({ theme }) => theme.new.colors.neutral100};
    border: 1px solid ${({ theme }) => theme.new.colors.neutral400};
  }
`;
