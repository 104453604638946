const parseErrors = errors => {
  if (errors) {
    const formattedErrors = Object.entries(errors).map(([, value]) => `Error: ${value}`);

    const lastError = formattedErrors.pop();

    const errorMessage = formattedErrors.length
      ? `${formattedErrors.join(', ')} and ${lastError}`
      : lastError;

    return errorMessage;
  }

  return 'Something went wrong';
};

export default data => {
  if (!data) return 'Something went wrong';

  const { error, errors } = data;

  if (error) return error;

  if (typeof errors === 'string') return errors;

  return parseErrors(errors);
};
