import { func, string } from 'prop-types';
import { components } from 'react-select';
import { useTheme } from 'styled-components';

import { commonPropsReactSelect } from 'constants/shapes';

import {
  IconWrapper,
  OptionContainer,
  OptionIconWrapper,
  OptionContent,
  StyledOption
} from './styles';

function OptionWithIcon({ Icon, OptionIcon, selectValue, variant, ...props }) {
  const isSelected =
    props?.data?.value === selectValue ||
    (selectValue?.id && props?.data?.value?.id === selectValue?.id);

  const theme = useTheme();

  if (variant === 'old') return <components.Option {...props} />;
  return (
    <StyledOption {...props} customTheme={theme} isSelected={isSelected} variant={variant}>
      <OptionContent variant={variant}>
        <OptionContainer>
          {OptionIcon && (
            <OptionIconWrapper>
              <OptionIcon />
            </OptionIconWrapper>
          )}
          {props.data.label}
        </OptionContainer>
        {isSelected && Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
      </OptionContent>
    </StyledOption>
  );
}

OptionWithIcon.propTypes = {
  ...commonPropsReactSelect,
  id: string,
  Icon: func,
  OptionIcon: func
};

export default OptionWithIcon;
