import { string, arrayOf, number, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { parseInputErrors } from 'utils/helpers';

import { TextAreaContainer, ErrorSpan, StyledTextArea, Label } from './styles';

const TextArea = ({
  label,
  name,
  errors,
  value,
  rows = 1,
  isResizeable = false,
  className = '',
  ...props
}) => (
  <TextAreaContainer errors={errors} className={className}>
    <StyledTextArea
      errors={errors}
      name={name}
      value={value}
      rows={rows}
      isResizeable={isResizeable}
      {...props}
    />
    {label && (
      <Label errors={errors} value={value} htmlFor={name}>
        {label}
      </Label>
    )}
    {errors && (
      <ErrorSpan>
        <FormattedMessage id={parseInputErrors(errors)} defaultMessage={parseInputErrors(errors)} />
      </ErrorSpan>
    )}
  </TextAreaContainer>
);

TextArea.propTypes = {
  name: string.isRequired,
  isResizeable: bool,
  rows: number,
  label: string,
  errors: arrayOf(string),
  value: string,
  className: string
};

export default TextArea;
