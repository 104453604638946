import { number, string } from 'prop-types';
import { useTheme } from 'styled-components';

const BarLabel = ({ x, y, name }) => {
  const theme = useTheme();
  return (
    <text
      x={x}
      y={y - 1}
      dy={-6}
      fill={theme.new.colors.N600}
      fontSize={14}
      fontWeight={theme.fontWeights.normal}
      fontFamily={theme.new.fontFamily}
    >
      {name}
    </text>
  );
};

BarLabel.propTypes = {
  name: string,
  x: number,
  y: number
};

export default BarLabel;
