import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.new.colors.P900};
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;
  margin-top: 20rem;
  width: 100%;

  && > * {
    max-width: 50rem;
    text-align: center;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => theme.new.typography.h3};
`;

export const SubTitle = styled.h2`
  ${({ theme }) => theme.new.typography.p5};
  padding-top: 1.5rem;
`;
