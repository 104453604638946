const tableEmployeeColumnTitles = [
  'common.resource',
  'common.role',
  'common.seniority',
  'common.skills',
  'searchPeople.bestMatches.matchOverall',
  'common.margin',
  'searchPeople.bestMatches.interactions.title',
  ''
];

const OFF_SET = 150;

const CONTAINER_ELEMENT_ID = 'scrollContainer';

export { OFF_SET, CONTAINER_ELEMENT_ID, tableEmployeeColumnTitles };
