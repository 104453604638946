import cloneDeep from 'lodash/cloneDeep';
import { func, arrayOf, bool, string, node } from 'prop-types';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Checkbox from 'components/common/Checkbox';
import { optionShape } from 'constants/shapes';
import { filterList } from 'utils/reducersUtilities';

import {
  DropdownCheckboxContainer,
  Option,
  OptionLabel,
  ClearButton,
  DropdownActions,
  Body,
  StyledSearch,
  TopActions
} from './styles';

const DropdownCheckbox = ({
  checkboxSize,
  children,
  className,
  clearId,
  isUseSelected,
  onApply,
  options: initialOptions,
  searchIntlId,
  setOptions,
  isClearable = true,
  multi = true,
  variant = 'new',
  withIntlOptions = true,
  searchValue = '',
  onSearchChange
}) => {
  const intl = useIntl();
  const [search, setSearch] = useState(searchValue);
  const options = useMemo(
    () => initialOptions.map((option, index) => ({ ...option, position: index })),
    [initialOptions]
  );

  const selectedAtt = isUseSelected ? 'selected' : 'value';

  const onChange = (selectedValue, position) => {
    const newOptions = multi
      ? cloneDeep(options)
      : options.map(option => ({
          ...option,
          [selectedAtt]: false
        }));
    newOptions[position][selectedAtt] = !selectedValue;
    onApply && onApply(newOptions);
    setOptions(newOptions);
  };

  const filteredOptions = () => filterList(options, searchValue || search, item => item?.label);

  const onClear = () => {
    const newOptions = options.map(option => ({
      ...option,
      [selectedAtt]: false
    }));
    onApply && onApply(newOptions);
    setOptions(newOptions);
  };

  return (
    <DropdownCheckboxContainer className={className}>
      {Boolean(searchIntlId) && (
        <StyledSearch
          placeholderId={searchIntlId}
          variant="new"
          width="100%"
          value={searchValue || search}
          onChange={onSearchChange || setSearch}
        />
      )}
      {children && <TopActions>{children}</TopActions>}
      <Body variant={variant}>
        {filteredOptions()?.map(({ label, [selectedAtt]: selectedValue, key, position }) => (
          <Option key={key} onClick={() => onChange(selectedValue, position)} type="button">
            <Checkbox
              value={selectedValue}
              onChange={onChange}
              field={position}
              variant={variant}
              size={checkboxSize}
            />
            <OptionLabel>{withIntlOptions ? intl.formatMessage({ id: label }) : label}</OptionLabel>
          </Option>
        ))}
      </Body>
      {isClearable && (
        <DropdownActions>
          <ClearButton id={clearId} onClick={onClear} type="button" variant={variant}>
            {intl.formatMessage({ id: 'filter.clear' })}
          </ClearButton>
        </DropdownActions>
      )}
    </DropdownCheckboxContainer>
  );
};

DropdownCheckbox.propTypes = {
  checkboxSize: string,
  children: node,
  className: string,
  clearId: string,
  isClearable: bool,
  isUseSelected: bool,
  multi: bool,
  onApply: func,
  options: arrayOf(optionShape),
  searchIntlId: string,
  setOptions: func,
  variant: string,
  withIntlOptions: bool,
  searchValue: string,
  onSearchChange: func
};

export default DropdownCheckbox;
