import styled from 'styled-components';

import { ReactComponent as XCircle } from 'assets/icons/x-circle.svg';
import { ReactComponent as Trash } from 'components/icons/delete.svg';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 2.4rem;
`;

export const Active = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  ${({ theme }) => theme.new.typography.p4};

  align-items: center;
  background-color: ${({ theme }) => theme.new.colors.N300};
  border-radius: 4px;
  color: ${({ theme }) => theme.new.colors.N800};
  display: flex;
  gap: 4px;
  justify-content: center;
  line-height: 2.4rem;
  padding: 0.4rem 1.2rem;
`;

export const ClearContainer = styled.div`
  ${({ theme }) => theme.new.typography.p5SemiBold};

  color: ${({ theme }) => theme.new.colors.P900};
  cursor: pointer;
  display: flex;
  gap: 0.8rem;
  flex-shrink: 0;
`;

export const XCircleIcon = styled(XCircle)`
  width: 16px;

  &:hover {
    cursor: pointer;
  }

  & path {
    stroke: ${({ theme }) => theme.new.colors.N800};
  }
`;

export const TrashIcon = styled(Trash)`
  transform: scale(${20 / 24});

  & path {
    stroke: ${({ theme }) => theme.new.colors.P500};
    stroke-width: 2px;
  }
`;
