import { string, number, bool } from 'prop-types';

import { CustomSvg } from './styles';

const DropdownIcon = ({
  className,
  width = 9,
  height = 9,
  upDirection = false,
  stroke = '#515152'
}) => (
  <CustomSvg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    $upDirection={upDirection}
  >
    <path
      d="M7.48529 4.24261L4.24268 7.48523L1.00006 4.24261"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </CustomSvg>
);

DropdownIcon.propTypes = {
  className: string,
  width: number,
  height: number,
  upDirection: bool,
  stroke: string
};

export default DropdownIcon;
