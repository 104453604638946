import { arrayOf, bool, func } from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { DiscountIcon } from 'components/common/DiscountIcon';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import ProjectOptionsButton from 'components/common/ProjectOptionsButton';
import { SORT_DIR } from 'constants/constants';
import { projectOverviewHeaderShape, sortableTableColumnShape } from 'constants/shapes';
import { useDispatch } from 'hooks';
import { resetProjectId } from 'state/actions/projectActions';
import { sortTable } from 'utils/projectsOverviewUtilities';

import AssignmentsTable from '../AssignmentsTable';
import Cell from './Cell';
import {
  ArrowButton,
  ArrowColumn,
  ArrowIcon,
  Column,
  ExpandedRow,
  HeaderColumnContainer,
  OptionsColumn,
  Row,
  Table,
  TableWrapper
} from './styles';

const ProjectsTable = ({
  projects = [],
  columns,
  isLoading,
  setAddAssignmentTo,
  getProjectsRequest
}) => {
  const projectId = useSelector(({ project }) => project.projectId);
  const intl = useIntl();
  const [expandedRows, setExpandedRows] = useState(projectId ? { [projectId]: true } : null);
  const [sortConfig, setSortConfig] = useState({
    key: 'assignments',
    direction: SORT_DIR.desc
  });

  const resetExpandedProjectId = useDispatch(resetProjectId);

  const onSort = ({ key: columnKey }) => {
    let direction = SORT_DIR.desc;
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === SORT_DIR.desc) {
      direction = SORT_DIR.asc;
    }
    setSortConfig({
      key: columnKey,
      direction
    });
  };

  const sortedProjects = useMemo(() => {
    if (!sortConfig) return projects;
    const sortedRows = sortTable({ columns, sortConfig, rows: projects });
    return sortedRows;
  }, [columns, projects, sortConfig]);

  useEffect(
    () => () => {
      projectId && resetExpandedProjectId();
    },
    [projectId, resetExpandedProjectId]
  );

  return (
    <>
      <TableWrapper>
        <Table>
          <thead>
            <Row height="5.7rem" isHeader>
              <ArrowColumn />
              {columns?.map(column => {
                const isSorting = sortConfig && sortConfig.key === column.key;
                return (
                  <th key={column.key}>
                    <HeaderColumnContainer
                      onClick={() => !isLoading && onSort(column)}
                      $disabled={isLoading}
                    >
                      <span>{column.header && intl.formatMessage({ id: column.header })}</span>
                      <Column width="1.2rem">
                        <ArrowIcon
                          direction={isSorting ? sortConfig.direction : SORT_DIR.desc}
                          $isSorting={!isLoading && isSorting}
                          disabled={isLoading}
                        />
                      </Column>
                    </HeaderColumnContainer>
                  </th>
                );
              })}
              <td />
            </Row>
          </thead>
          <tbody>
            {!isLoading &&
              sortedProjects?.map(project => {
                const projectId = project.id;
                const isExpanded = expandedRows?.[projectId] || false;
                const { open, pending, confirmed } = project.assignments || {};
                const hasAssignments = open > 0 || confirmed > 0 || pending > 0;
                return (
                  <Fragment key={projectId}>
                    <Row key={projectId} $isExpanded={isExpanded}>
                      <ArrowColumn>
                        <ArrowButton
                          $isExpanded={isExpanded}
                          $hasAssignments={hasAssignments}
                          onClick={() =>
                            setExpandedRows(current => ({
                              ...current,
                              [projectId]: !expandedRows?.[projectId]
                            }))
                          }
                        />
                      </ArrowColumn>
                      {Object.values(columns).map(({ key }) => (
                        <Cell
                          value={project[key]}
                          columnKey={key}
                          projectIcon={
                            !!project.globalDiscountPercentage && (
                              <DiscountIcon discount={project.globalDiscountPercentage} />
                            )
                          }
                          key={`${projectId}-${key}`}
                        />
                      ))}
                      <OptionsColumn $isExpanded={isExpanded}>
                        <ProjectOptionsButton
                          isTimelineView={false}
                          project={project}
                          projectId={projectId}
                          handleRefreshData={() => getProjectsRequest()}
                          firstOptionCallback={() =>
                            setAddAssignmentTo({ project, initialValues: null })
                          }
                        />
                      </OptionsColumn>
                    </Row>
                    <ExpandedRow $isExpanded={isExpanded}>
                      {isExpanded && (
                        <Column colSpan={columns.length + 2} height="100%" padding="0">
                          <AssignmentsTable
                            projectId={projectId}
                            getProjectsRequest={getProjectsRequest}
                            setAddAssignmentTo={() => setAddAssignmentTo({ project })}
                          />
                        </Column>
                      )}
                    </ExpandedRow>
                  </Fragment>
                );
              })}
          </tbody>
        </Table>
      </TableWrapper>
      {isLoading && <CenteredLoading />}
    </>
  );
};

ProjectsTable.propTypes = {
  projects: arrayOf(projectOverviewHeaderShape),
  columns: arrayOf(sortableTableColumnShape),
  isLoading: bool,
  setAddAssignmentTo: func,
  getProjectsRequest: func
};
export default ProjectsTable;
