import { number } from 'prop-types';
import { useTheme } from 'styled-components';

import { labelValueFormat } from 'constants/shapes';
import { toCurrency } from 'utils/number';

function getTextWidth(text, fontSize, fontFamily) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `${fontSize}px ${fontFamily}`;
  const textMetrics = context.measureText(text);
  return textMetrics.width;
}

const LabelFormatter = props => {
  const theme = useTheme();
  const { value, y, width } = props;

  if (value.percentage <= 0) return null;

  const fontSize = 16;
  const totalWidth = (width * 100) / value.percentage;
  const revenueWidth = getTextWidth(toCurrency(value.revenue), fontSize, theme.new.fontFamily);
  const revenuePosition = totalWidth - revenueWidth + 50;
  const percentageWidth = getTextWidth(value.percentage, fontSize, theme.new.fontFamily);

  return (
    <g>
      <text
        x={revenuePosition - 30 - percentageWidth}
        y={y}
        dy={-7}
        fill={theme.new.colors.P500}
        fontSize={fontSize}
        fontWeight={theme.fontWeights.normal}
        fontFamily={theme.new.fontFamily}
      >
        {`${value.percentage}%`}
      </text>
      <rect
        x={revenuePosition - 10}
        y={y - 19}
        width="1"
        height="12"
        fill={theme.new.colors.N300}
      />
      <text
        x={revenuePosition}
        y={y}
        dy={-7}
        fill={theme.new.colors.N800}
        fontSize={fontSize}
        fontWeight={theme.fontWeights.normal}
        fontFamily={theme.new.fontFamily}
      >
        {toCurrency(value.revenue)}
      </text>
    </g>
  );
};

LabelFormatter.propTypes = {
  value: labelValueFormat,
  y: number,
  width: number
};

export default LabelFormatter;
