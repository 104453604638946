import { number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';

import ProgressBar from 'components/common/ProgressBar';

import { TextContainer, TextWrapper } from '../styles';

const UtilizationCell = ({ capacity, scheduled }) => {
  const theme = useTheme();
  const utilization = Math.round((scheduled * 100) / capacity);

  const barContent = [
    {
      percentage: utilization,
      color: utilization > 100 ? theme.colors.red : theme.colors.strongMaintenance,
      roundedLeft: true,
      roundedRight: true
    }
  ];

  return (
    <>
      <TextWrapper>
        <TextContainer bold>{utilization}%</TextContainer>
        <TextContainer>
          <FormattedMessage
            id="template.connectors.of"
            values={{ valA: scheduled, valB: capacity }}
          />
        </TextContainer>
      </TextWrapper>
      <ProgressBar height={0.7} content={barContent} />
    </>
  );
};

UtilizationCell.propTypes = {
  capacity: number.isRequired,
  scheduled: number.isRequired
};

export default UtilizationCell;
