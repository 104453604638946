import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.2rem;
`;

export const GridContainer = styled.div`
  display: flex;
  width: 100%;
  row-gap: 2.35rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  padding-bottom: 2rem;
  gap: 1.6rem;
  justify-content: space-between;
  margin-bottom: 2.4rem;
`;

export const Title = styled.div`
  display: flex;
  ${({ theme }) => theme.new.typography.h4};
  color: ${({ theme }) => theme.new.colors.P800};
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const SectionHeader = styled.div`
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  height: 5.5rem;
  justify-content: space-between;

  ${({ theme, mainColor }) => mainColor && `border-color: ${theme.new.colors.P500}`}
`;

export const SectionTitle = styled.h3`
  ${({ theme }) => theme.new.typography.h3};
  color: ${({ theme }) => theme.new.colors.black};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem 0;
  gap: 0.8rem;
`;

export const ExpandButton = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.new.colors.white};
  border: 1px solid ${({ theme }) => theme.new.colors.N300};
  border-radius: 50%;
  display: flex;
  height: 3.2rem;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 3.2rem;
  ${({ openPeriodsExpanded }) => openPeriodsExpanded && 'transform: rotate(180deg)'};
`;

export const ExpandableSection = styled.div`
  overflow: auto;
  position: relative;
  transition: width 0.4s ease;
  width: ${({ openPeriodsExpanded }) => (openPeriodsExpanded ? '120rem' : '58rem')};
`;
