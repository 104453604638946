import { arrayOf, func, string } from 'prop-types';

import { optionShape } from 'constants/shapes';

import { Container, Label } from './styles';

const Switch = ({ options, value, onChange }) => (
  <Container>
    {options.map(option => (
      <Label key={option.value}>
        <input
          type="radio"
          value={option.value}
          checked={value === option.value}
          onChange={onChange}
        />
        {option.label}
      </Label>
    ))}
  </Container>
);

Switch.propTypes = {
  options: arrayOf(optionShape),
  value: string,
  onChange: func
};

export default Switch;
