import { arrayOf, number } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import BoxContainer from 'components/common/BoxContainer';
import CustomTab from 'components/common/CustomTab';
import { periodShape } from 'constants/shapes';
import { useDispatch, useStatus } from 'hooks';
import { getProjectClosedPeriods, updateClosedPeriodInfo } from 'state/actions/financialActions';
import { composedClosedPeriodsInfo } from 'utils/periodUtilities';

import EditButtons from '../EditButtons';
import PeriodTable from './PeriodTable';
import { Container, Header, Title } from './styles';

function ClosePeriods({ closedPeriods, projectId }) {
  const intl = useIntl();
  const [editId, setEditId] = useState(null);
  const [periodDetailCopy, setPeriodDetailCopy] = useState(null);
  const [activeTab, setActiveTab] = useState(
    intl.formatMessage({ id: 'common.cap.billingUnit.daily' })
  );
  const [expandedRows, setExpandedRows] = useState({});
  const updateClosedPeriodInfoRequest = useDispatch(updateClosedPeriodInfo);
  const { isPending } = useStatus(getProjectClosedPeriods);

  const handleCleanEdit = () => {
    setEditId(null);
    setPeriodDetailCopy(null);
  };

  const handleSubmitClosedPeriodInfo = async () => {
    handleCleanEdit();
    await updateClosedPeriodInfoRequest({
      projectId,
      periodId: editId,
      body: composedClosedPeriodsInfo(periodDetailCopy)
    });
  };

  const handleActiveTab = newActiveTab => {
    setActiveTab(newActiveTab);
  };

  return (
    <BoxContainer>
      <Container>
        <Header isSticky={editId}>
          <Title>{intl.formatMessage({ id: 'financial.closePeriod.title' })}</Title>

          {Object.values(expandedRows).length &&
          Object.values(expandedRows).some(status => status) ? (
            <div>
              {editId ? (
                <EditButtons
                  saveId="saveClosedPeriodBtn"
                  cancelId="cancelClosedPeriodBtn"
                  onSave={handleSubmitClosedPeriodInfo}
                  onCancel={handleCleanEdit}
                />
              ) : (
                <CustomTab
                  activeTab={activeTab}
                  setActiveTab={handleActiveTab}
                  tabTextIds={['common.cap.billingUnit.hourly', 'common.cap.billingUnit.daily']}
                />
              )}
            </div>
          ) : null}
        </Header>
        <PeriodTable
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          activeTab={activeTab}
          periods={closedPeriods}
          isLoading={isPending}
          projectId={projectId}
          periodDetailCopy={periodDetailCopy}
          setPeriodDetailCopy={setPeriodDetailCopy}
          editId={editId}
          setEditId={setEditId}
        />
      </Container>
    </BoxContainer>
  );
}

ClosePeriods.propTypes = {
  closedPeriods: arrayOf(periodShape),
  projectId: number
};

export default ClosePeriods;
