import { bool, func, number, oneOfType, string } from 'prop-types';

import InfoTooltip from 'components/Financial/InfoTooltip';

import { Box, Description, StyledInfoIcon, Title } from './styles';

function OverviewBox({ id, disabled, onBoxClick, description, tooltipTextId = '', value }) {
  return (
    <Box id={id} disabled={disabled} onClick={onBoxClick}>
      <Description>
        {description}{' '}
        {tooltipTextId && (
          <InfoTooltip textIntlId={tooltipTextId}>
            <StyledInfoIcon />
          </InfoTooltip>
        )}
      </Description>
      <Title>{value}</Title>
    </Box>
  );
}

OverviewBox.propTypes = {
  id: string,
  disabled: bool,
  onBoxClick: func,
  description: string,
  tooltipTextId: string,
  value: oneOfType([string, number])
};

export default OverviewBox;
