import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
  display: flex;
  flex-direction: row;
  gap: 18px;
  height: 60px;
  padding: 0px 11px;
  width: 100%;
`;

export const Row = styled.div`
  align-items: center;
  border-bottom: 1px ${({ theme }) => theme.colors.lightGray} solid;
  display: flex;
  flex-direction: row;
  gap: 18px;
  height: 60px;
  padding: 0px 11px;
  width: 100%;
`;

export const Column = styled.div`
  align-items: center;
  border-right: ${({ theme, borderRight }) =>
    borderRight ? `1px solid ${theme.colors.lightGray}` : 'none'};
  color: ${({ theme }) => theme.colors.gray100};
  flex-basis: 100%;
  flex-direction: row;
  font-family: 'Noto Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ strong }) => (strong ? '600' : '400')};
  line-height: 19px;
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  text-align: ${({ textAlign }) => textAlign || 'center'};
`;

export const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;

  a {
    text-decoration: none;
  }
`;

export const Person = styled.div`
  display: flex;
  height: 60px;
`;

export const PersonName = styled.p`
  color: ${props => props.theme.colors.gray100};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  line-height: 19px;

  :hover {
    cursor: pointer;
  }
`;

export const PersonRole = styled.p`
  color: ${props => props.theme.colors.gray80};
  font-size: 1.3rem;
  font-weight: ${props => props.theme.fontWeights.normal};
  margin: 0;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const Range = styled.div`
  align-items: center;
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const EmptyRange = styled.div`
  align-items: center;
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  justify-content: center;
  width: 100%;
`;

export const TruncateText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
