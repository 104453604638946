import { StatusCircle, StatusColumn } from 'components/ProjectsOverview/ProjectsTable/Cell/styles';
import { SERVICE_TYPES_OPTIONS } from 'constants/constants';
import { DISPLAY_MONTH_DAY_YEAR } from 'constants/dateFormats';
import { dateToString } from 'utils/date';
import { humanize } from 'utils/helpers';

export const getProjectInfo = (
  {
    name,
    serviceType,
    status,
    projectType,
    startDate,
    endDate,
    confirmedAssignmentsCount,
    totalAssignmentsCount,
    code
  },
  intl
) => [
  {
    title: 'common.project',
    item: name
  },
  {
    title: 'common.projectType',
    item: intl.formatMessage({
      id: SERVICE_TYPES_OPTIONS.find(({ key }) => key.includes(serviceType)).label
    })
  },
  {
    title: 'common.currentAllocation',
    item: `${confirmedAssignmentsCount} / ${totalAssignmentsCount}`
  },
  {
    title: 'common.status',
    item: (
      <StatusColumn>
        <StatusCircle status={status} />
        {humanize(status)}
      </StatusColumn>
    )
  },
  {
    title: 'common.billable',
    item: intl.formatMessage({
      id: projectType.includes('billable') ? 'common.billable.yes' : 'common.billable.no'
    })
  },
  {
    title: 'common.projectCode',
    item: code
  },
  {
    title: 'common.startDate',
    item: dateToString(startDate, DISPLAY_MONTH_DAY_YEAR)
  },
  {
    title: 'common.endDate',
    item: dateToString(endDate, DISPLAY_MONTH_DAY_YEAR)
  }
];
