import { arrayOf, bool, func, number, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as NonBillable } from 'assets/icons/nonBillable.svg';
import { ReactComponent as Support } from 'assets/icons/support.svg';
import BackfillPositionIndicator from 'components/BackfillPositionIndicator';
import { DiscountIcon } from 'components/common/DiscountIcon';
import IconWithTooltip from 'components/common/IconWithTooltip';
import avatarPlaceholder from 'components/common/images/avatar-placeholder.png';
import MuiTooltip from 'components/common/MuiTooltip';
import RoundAvatar from 'components/common/RoundAvatar';
import { roleShape } from 'constants/shapes';

import { Container, Info, TooltipContent, UserInfo } from './styles';

const TOOLTIP_LENGTH = 15;

const BasicInfoColumn = ({
  fullName,
  role,
  avatarUrl,
  viewProfile,
  mainSkills,
  nonBillable,
  isSupport,
  isBackfillPosition,
  discount,
  maxWidth
}) => {
  const intl = useIntl();
  const skillsText = Array.isArray(mainSkills)
    ? mainSkills?.map(({ name }) => name)?.join(', ')
    : '-';

  return (
    <Container>
      <RoundAvatar src={avatarUrl || avatarPlaceholder} squareSize={4} />
      <Info>
        <UserInfo maxWidth={maxWidth}>
          {viewProfile ? (
            <button type="button" onClick={() => viewProfile()}>
              <p>{fullName}</p>
            </button>
          ) : (
            <p>{fullName}</p>
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {mainSkills?.length ? (
            skillsText?.length > TOOLTIP_LENGTH ? (
              <>
                <MuiTooltip width="12rem" height="2rem" top="5rem">
                  <TooltipContent>{skillsText}</TooltipContent>
                </MuiTooltip>
                <span>{skillsText}</span>
              </>
            ) : (
              <span>{skillsText}</span>
            )
          ) : role?.length > TOOLTIP_LENGTH ? (
            <>
              <MuiTooltip width="12rem" height="2rem" top="4rem">
                <TooltipContent>{role}</TooltipContent>
              </MuiTooltip>
              <span>{role}</span>
            </>
          ) : (
            <p>{role || '-'}</p>
          )}
        </UserInfo>
        {nonBillable && (
          <IconWithTooltip
            icon={<NonBillable />}
            message={intl.formatMessage({ id: 'common.nonBillable' })}
          />
        )}
        {isSupport && (
          <IconWithTooltip
            icon={<Support />}
            message={intl.formatMessage({ id: 'common.support' })}
          />
        )}
        {isBackfillPosition && (
          <IconWithTooltip
            icon={<BackfillPositionIndicator />}
            message={intl.formatMessage({ id: 'common.nonBillable' })}
          />
        )}
        {!!discount && <DiscountIcon discount={discount} />}
      </Info>
    </Container>
  );
};

BasicInfoColumn.propTypes = {
  fullName: string,
  role: string,
  avatarUrl: string,
  viewProfile: func,
  mainSkills: oneOfType([arrayOf(roleShape), string]),
  nonBillable: bool,
  isSupport: bool,
  isBackfillPosition: bool,
  discount: number,
  maxWidth: string
};

export default BasicInfoColumn;
