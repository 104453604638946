import { createAction } from '@reduxjs/toolkit';

import peopleService from 'services/peopleService';
import createAsyncThunk from 'utils/createAsyncThunk';
import { getFilterQuery } from 'utils/helpers';
import parseError from 'utils/parseError';

import { getProjectsAvailability } from './projectActions';

export const filterResources = createAction('FILTER_RESOURCES');

export const filterResourcesByName = createAction('FILTER_RESOURCE_BY_NAME');
export const resetResourceFilters = createAction('RESET_RESOURCE_FILTERS');
export const toggleResourceExpanded = createAction('TOGGLE_RESOURCE_EXPANDED');
export const resetExpandedResources = createAction('RESET_EXPANDED_RESOURCES');
export const toggleUnassignedSkillsShown = createAction('TOGGLE_UNASSIGNED_SKILLS_SHOWN');
export const toggleUnassignedSkillExpanded = createAction('TOGGLE_UNASSIGNED_SKILL_EXPANDED');

export const getPeopleAvailability = createAsyncThunk(
  'GET_PEOPLE_AVAILABILITY',
  async ({ startDate, endDate, withFilters }, { getState }) => {
    try {
      const {
        timeline: { resourceFilters }
      } = getState();

      const filterQuery = withFilters ? getFilterQuery(resourceFilters) : '';
      const { data } = await peopleService.getPeopleAvailability(startDate, endDate, filterQuery);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getPeopleByAssignment = createAsyncThunk(
  'GET_PEOPLE_ASSIGNMENT',
  async assignmentId => {
    try {
      const { data } = await peopleService.getAssigmentPeople(assignmentId);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const createResource = createAsyncThunk(
  'CREATE_RESOURCE',
  async ({ payload: { payload: person }, startDate, endDate }, { dispatch }) => {
    try {
      const { data } = await peopleService.createResource(person);
      dispatch(getProjectsAvailability({ startDate, endDate }));
      dispatch(getPeopleAvailability({ startDate, endDate }));
      dispatch(filterResourcesByName(`${person.firstName} ${person.lastName}`));
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getMinimalResources = createAsyncThunk(
  'GET_MINIMAL_RESOURCES',
  async ({ userGroup }) => {
    try {
      const {
        data: { resources }
      } = await peopleService.getMinimalResources(userGroup);
      return { resources, userGroup };
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getResource = createAsyncThunk('GET_RESOURCE', async ({ resourceId }) => {
  try {
    const { data } = await peopleService.getResource({ resourceId });
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getResourceSoftSkills = createAsyncThunk(
  'GET_RESOURCE_SOFT_SKILLS ',
  async ({ personId }) => {
    try {
      const { data } = await peopleService.getResourceSoftSkills({ personId });
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const updateResourceSoftSkills = createAsyncThunk(
  'UPDATE_RESOURCE_SOFT_SKILLS ',
  async ({ personId, payload }) => {
    try {
      const { data } = await peopleService.updateResourceSoftSkills({ personId, payload });
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const addResourceSoftSkills = createAsyncThunk(
  'ADD_RESOURCE_SOFT_SKILLS ',
  async ({ personId, payload }) => {
    try {
      const { data } = await peopleService.addResourceSoftSkills({ personId, payload });
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getResourceTechnicalSkills = createAsyncThunk(
  'GET_RESOURCE_TECHNICAL_SKILLS ',
  async ({ personId, highlighted = false }) => {
    try {
      const { data } = await peopleService.resourceExperiences({ personId, highlighted });
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const updateResourceTechnicalSkills = createAsyncThunk(
  'UPDATE_RESOURCE_TECHNICAL_SKILLS ',
  async ({ personId, payload }) => {
    try {
      const { data } = await peopleService.updateResourceTechnicalSkills({ personId, payload });
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const addResourceTechnicalSkills = createAsyncThunk(
  'ADD_RESOURCE_TECHNICAL_SKILLS ',
  async ({ personId, payload }) => {
    try {
      const { data } = await peopleService.addResourceTechnicalSkills({ personId, payload });
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const addSpecialEvent = createAsyncThunk(
  'ADD_SPECIAL_EVENT',
  async ({ personId, payload }) => {
    try {
      const { data } = await peopleService.addSpecialEvents(personId, payload);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const updateSpecialEvent = createAsyncThunk(
  'UPDATE_SPECIAL_EVENT',
  async ({ personId, specialEventId, payload }) => {
    try {
      const { data } = await peopleService.updateSpecialEvents(personId, specialEventId, payload);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getPersonSpecialEvents = createAsyncThunk(
  'GET_SPECIAL_EVENTS',
  async ({ personId }) => {
    try {
      const { data } = await peopleService.getPersonSpecialEvents(personId);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const deleteSpecialEvent = createAsyncThunk(
  'DELETE_SPECIAL_EVENT',
  async ({ personId, specialEventId }) => {
    try {
      const { data } = await peopleService.deleteSpecialEvents(personId, specialEventId);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);
