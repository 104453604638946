import { string, instanceOf, func, bool } from 'prop-types';
import { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DISPLAY_DATE } from 'constants/dateFormats';
import { parseInputErrors } from 'utils/helpers';

import { DatePickerContainer, StyledDatePicker, Label, ErrorSpan, CalendarIcon } from './styles';

const DatePicker = forwardRef(
  /* eslint-disable prefer-arrow-callback */
  function DatePicker(
    {
      label,
      name,
      errors,
      selected,
      onDoubleClick,
      popperWidth,
      startDate,
      endDate,
      variant = 'old',
      showMonthYearPicker,
      showYearPicker,
      showQuarterYearPicker,
      showPopperArrow = true,
      dateFormat = DISPLAY_DATE,
      className,
      showTodayButton = true,
      ...props
    },
    ref
  ) {
    const intl = useIntl();

    return (
      <DatePickerContainer
        onDoubleClick={onDoubleClick}
        errors={errors}
        popperWidth={popperWidth}
        variant={variant}
        isDayPicker={!showMonthYearPicker && !showYearPicker && !showQuarterYearPicker}
        className={className}
        hasTodayButton={showTodayButton}
      >
        <StyledDatePicker
          showPopperArrow={showPopperArrow}
          errors={errors}
          todayButton={showTodayButton && intl.formatMessage({ id: 'common.today' })}
          selected={selected}
          startDate={startDate}
          endDate={endDate}
          required
          variant={variant}
          showMonthYearPicker={showMonthYearPicker}
          showYearPicker={showYearPicker}
          showQuarterYearPicker={showQuarterYearPicker}
          ref={ref}
          disabledKeyboardNavigation={false}
          onChangeRaw={e => e.preventDefault()}
          dateFormat={dateFormat}
          {...props}
        />
        {variant === 'new' && <CalendarIcon />}
        {label && (
          <Label
            errors={errors}
            selected={selected || selected === null}
            htmlFor={name}
            variant={variant}
            isDayPicker={!showMonthYearPicker && !showYearPicker && !showQuarterYearPicker}
          >
            {label}
          </Label>
        )}
        {errors && (
          <ErrorSpan>
            <FormattedMessage
              id={parseInputErrors(errors)}
              defaultMessage={parseInputErrors(errors)}
            />
          </ErrorSpan>
        )}
      </DatePickerContainer>
    );
  }
);

DatePicker.propTypes = {
  name: string.isRequired,
  selected: instanceOf(Date),
  label: string,
  errors: string,
  onDoubleClick: func,
  popperWidth: string,
  startDate: instanceOf(Date),
  endDate: instanceOf(Date),
  variant: string,
  showMonthYearPicker: bool,
  showYearPicker: bool,
  showQuarterYearPicker: bool,
  showPopperArrow: bool,
  dateFormat: string,
  className: string,
  showTodayButton: bool
};

export default DatePicker;
