import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  & button,
  & > a {
    align-items: center;
    display: flex;
    gap: 1.6rem;
    padding: 1.2rem 0 1.2rem 1.2rem;
    text-decoration: none;
    width: 100%;
    overflow: hidden;

    &:hover {
      background-color: ${({ theme }) => theme.new.colors.N100};
    }

    & > span {
      ${({ theme }) => theme.new.typography.p6SemiBold};
      color: ${({ theme }) => theme.new.colors.N500};
      display: ${({ isOpen }) => (isOpen ? 'inline' : 'none')};
      transition: width 0.1s ease-out 0.3s;
    }
  }

  & > a {
    padding: 0;
    & span {
      display: none;
      width: 0;
    }
  }

  &:has(a.active) {
    & button {
      ${({ theme, isOpen }) =>
        !isOpen &&
        css`
          background-color: ${theme.new.colors.P100};
          border-right: 3px solid ${theme.new.colors.P500};
        `};

      & svg path {
        stroke: ${({ theme, isOpen }) => (isOpen ? theme.new.colors.N800 : theme.new.colors.P500)};
      }

      & > span {
        color: ${({ theme }) => theme.new.colors.N800};
        display: ${({ isOpen }) => (isOpen ? 'inline' : 'none')};
        width: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
      }
    }

    & > a {
      padding: ${({ isOpen }) => (isOpen ? '1.2rem 0 1.2rem 5.2rem' : '0')};

      & span {
        display: ${({ isOpen }) => (isOpen ? 'inline' : 'none')};
        width: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
      }
    }
  }
`;

export const Menu = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  box-shadow: 0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11),
    0px 4px 8px 0px rgba(79, 94, 113, 0.1), 0px 8px 16px 0px rgba(79, 94, 113, 0.09),
    0px 16px 32px 0px rgba(79, 94, 113, 0.08), 0px 32px 64px 0px rgba(79, 94, 113, 0.07);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: hidden;
  position: absolute;
  right: -144px;
  top: 0;
  transition: opacity 0.3s ease;
  width: ${({ isOpen }) => (isOpen ? '144px' : '0px')};
  z-index: ${({ isOpen }) => isOpen && 10000};

  & > a {
    align-items: center;
    display: flex;
    gap: 1.6rem;
    text-decoration: none;
    padding: 1.2rem 0 1.2rem 1.2rem;

    &:hover {
      background-color: ${({ theme }) => theme.new.colors.N100};
    }

    & span {
      ${({ theme }) => theme.new.typography.p6SemiBold};

      color: ${({ theme }) => theme.new.colors.N500};
      display: block;
      text-align: left;
      line-height: 2.4rem;
    }
  }
`;
