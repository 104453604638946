import isEmpty from 'lodash/isEmpty';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { getGroups, getResources } from 'state/actions/settingsActions';
import { groupsToOptions } from 'utils/settingsUtilities';

export default () => {
  const [groupOptions, setGroupOptions] = useState([]);
  const getGroupRequest = useDispatch(getGroups);
  const getGroupResources = useDispatch(getResources);
  const { status: groupStatus, error: groupError } = useStatus(getGroups);
  const { isFulfilled } = useStatus(getResources);

  const groups = useSelector(({ settings }) => settings.groups);
  const groupResources = useSelector(({ settings }) => settings.resources);
  const isLoadingResources = !isFulfilled;

  useEffect(() => {
    if (isEmpty(groups)) {
      getGroupRequest();
    } else {
      setGroupOptions(groups.map(groupsToOptions));
    }
    if (!groupResources || isEmpty(groupResources)) {
      getGroupResources();
    }
  }, [groups, groupResources, getGroupRequest, getGroupResources]);

  return {
    groups,
    groupResources,
    getGroupRequest,
    groupStatus,
    groupError,
    groupOptions,
    isLoadingResources
  };
};
