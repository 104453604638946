import styled from 'styled-components';

import { ErrorSpan } from 'components/common/Input/styles';
import NewInput from 'components/common/NewInput';
import NewTextArea from 'components/common/NewTextArea';
import Select from 'components/common/Select';
import { StyledSelect as CommonStyledSelect } from 'components/common/Select/styles';
import { StyledTextArea } from 'components/common/TextArea/styles';
import { newInputBorder } from 'utils/inputColors';

const getInputBorder = ({ errors, theme }) =>
  newInputBorder({ baseColor: theme.colors.gray60, errors, theme });

export const StyledInput = styled(NewInput)`
  padding-bottom: ${({ errors }) => errors && '0'};
  ${ErrorSpan} {
    bottom: 0;
    position: relative;
  }
`;

export const StyledSelect = styled(Select)`
  ${CommonStyledSelect} {
    .react-select__control {
      border: ${newInputBorder};
      height: 4.4rem;
    }
  }
`;

export const StyledNotes = styled(NewTextArea)`
  padding: 0;

  ${StyledTextArea} {
    border: ${getInputBorder};
    min-height: 3.8rem;
  }
`;
