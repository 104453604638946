import { oneOfType, instanceOf, string, func } from 'prop-types';
import { useRef, useState, useMemo } from 'react';

import { KEYBOARD } from 'constants/keyboardCodes';
import { useKeyboardEvent } from 'hooks';
import { addDays, getDiffDays, minDate, maxDate } from 'utils/date';
import { getTimelineFragments } from 'utils/timelineUtilities';

import {
  NewAvailabilityElement,
  NewAvailabilityInnerElement,
  EmptyAvailabilityElement
} from './styles';

const SELECT_TYPE = {
  0: 'SELECT_START',
  1: 'SELECT_END'
};

const NewAvailability = ({ startDate: parentStart, endDate: parentEnd, onSelectionComplete }) => {
  const daysCount = getDiffDays(parentStart, parentEnd);

  const [selectionType, setSelectionType] = useState(SELECT_TYPE[0]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const selectedDates = useMemo(
    () => ({
      startDate: minDate(startDate, endDate),
      endDate: maxDate(startDate, endDate)
    }),
    [endDate, startDate]
  );

  const timelineFragments = useMemo(
    () => getTimelineFragments(parentStart, parentEnd)([selectedDates]),
    [parentStart, parentEnd, selectedDates]
  );

  const elementRef = useRef();

  useKeyboardEvent(KEYBOARD.ESC)(() => {
    setSelectionType(SELECT_TYPE[0]);
    setStartDate(null);
    setEndDate(null);
  });

  const getSelectedDay = evt => {
    const elementWidth = elementRef.current?.clientWidth;
    const mouseOffsetX = evt.nativeEvent.offsetX;
    const selectedDayOffset = Math.floor((mouseOffsetX * (daysCount + 1)) / elementWidth);
    const selectedDay = addDays(parentStart, selectedDayOffset);

    return selectedDay;
  };

  const onClick = evt => {
    const selectedDay = getSelectedDay(evt);

    if (selectionType === SELECT_TYPE[0]) {
      setStartDate(selectedDay);
      setEndDate(selectedDay);
      setSelectionType(SELECT_TYPE[1]);
    }
    if (selectionType === SELECT_TYPE[1]) {
      setEndDate(selectedDay);
      setSelectionType(SELECT_TYPE[0]);
      onSelectionComplete(selectedDates);
    }
  };

  const onMouseMove = evt => {
    const selectedDay = getSelectedDay(evt);

    if (selectionType === SELECT_TYPE[0]) {
      setStartDate(selectedDay);
      setEndDate(selectedDay);
    }
    if (selectionType === SELECT_TYPE[1]) {
      setEndDate(selectedDay);
    }
  };

  const onMouseOut = () => {
    if (selectionType === SELECT_TYPE[0]) {
      setStartDate(null);
      setEndDate(null);
    }
    if (selectionType === SELECT_TYPE[1]) {
      setStartDate(startDate);
      setEndDate(startDate);
    }
  };

  const innerElements = timelineFragments.map(({ startDate, endDate, value }, i) => {
    if (!value) return <EmptyAvailabilityElement key={i} startDate={startDate} endDate={endDate} />;
    return <NewAvailabilityInnerElement key={i} startDate={startDate} endDate={endDate} />;
  });

  return (
    <NewAvailabilityElement
      ref={elementRef}
      startDate={parentStart}
      endDate={parentEnd}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}
      onBlur={onMouseOut}
    >
      {innerElements}
    </NewAvailabilityElement>
  );
};

NewAvailability.propTypes = {
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  endDate: oneOfType([string, instanceOf(Date)]).isRequired,
  onSelectionComplete: func.isRequired
};

export default NewAvailability;
