import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import { arrayOf, object, bool } from 'prop-types';
import { useMemo } from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { HIGHLIGHT_SKILL_VALUE } from 'constants/constants';
import { columnShape } from 'constants/shapes';

import InformationWrapper from '../InformationWrapper';
import Filter from './Filter';
import { Container, ContainerLoader, Dropdown } from './styles';
import TableItem from './TableItem';

const Table = ({ columns, data, isLoading }) => {
  const defaultColumn = useMemo(
    () => ({
      Filter
    }),
    []
  );

  const skillFilter = (rows, id, filterValue) => {
    const highlightedFilter = filterValue.includes(HIGHLIGHT_SKILL_VALUE);
    if (filterValue.length) {
      return rows.filter(
        ({ values }) =>
          intersection(
            values[id].map(({ skill, highlighted }) => {
              if (skill !== HIGHLIGHT_SKILL_VALUE) {
                if ((highlightedFilter && highlighted) || !highlightedFilter) {
                  return skill;
                }
              }
            }),
            filterValue
          ).length
      );
    }
    return rows;
  };

  const includesFilter = (rows, id, filterValue) => {
    if (filterValue.length) {
      return rows.filter(({ values }) => includes(filterValue, values[id]));
    }
    return rows;
  };

  const filterTypes = useMemo(
    () => ({
      skills: skillFilter,
      includes: includesFilter
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetSortBy: false
    },
    useFilters,
    useSortBy
  );

  return isLoading ? (
    <ContainerLoader>
      <CenteredLoading />
    </ContainerLoader>
  ) : (
    <Container>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, trIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={trIndex}>
              {headerGroup.headers.map((column, thIndex) => (
                <th
                  width={column.width}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={`${trIndex}.${thIndex}`}
                >
                  <InformationWrapper infoIntlId={column.infoIntlId}>
                    {column.canFilter ? (
                      column.render('Filter')
                    ) : (
                      <>
                        {column.render('Header')}
                        {column.showCount && <span> {rows.length}</span>}
                      </>
                    )}
                    {column.isSorted &&
                      (column.isSortedDesc ? <Dropdown /> : <Dropdown upDirection />)}
                  </InformationWrapper>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, cellIndex) => (
                  <TableItem item={cell} key={`${rowIndex}.${cellIndex}`} />
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
};

Table.defaultProps = {
  isLoading: false
};

Table.propTypes = {
  columns: arrayOf(columnShape).isRequired,
  data: arrayOf(object).isRequired,
  isLoading: bool
};

export default Table;
