import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';

import { ROLES } from 'constants/permissions';
import routesPaths from 'constants/routesPaths';
import { useRole, useSession } from 'hooks';

import {
  ArrowUpIcon,
  BriefcaseIcon,
  Button,
  CoinStackIcon,
  Container,
  RocketIcon,
  Item,
  List,
  PeopleIcon,
  SettingsIcon
} from './styles';
import SubItem from './SubItem';

const Sidebar = () => {
  const intl = useIntl();
  const { authenticated } = useSession();
  const [isOpen, setIsOpen] = useState(true);
  const { pathname } = useLocation();
  const routeBase = pathname.split('/')[1];
  const { role, temporalRoles } = useRole();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const linkRoutes = {
    [ROLES.operations]: [
      {
        title: 'common.cap.insights',
        icon: <RocketIcon />,
        children: [
          { title: 'common.cap.legacy', route: routesPaths.dashboard },
          { title: 'common.capacityAndUtilization', route: routesPaths.utilizationInsights },
          { title: 'common.financial', route: routesPaths.financialInsights },
          { title: 'common.bench', route: routesPaths.benchInsights }
        ]
      },
      {
        title: 'common.projects',
        icon: <BriefcaseIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.projectsOverview },
          { title: 'common.timeline', route: routesPaths.projectsTimeline }
        ]
      },
      {
        title: 'common.resources',
        icon: <PeopleIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.resources },
          { title: 'common.myReports', route: routesPaths.myReports }
        ]
      },
      {
        title: 'common.financial',
        icon: <CoinStackIcon />,
        children: [
          { title: 'common.projectFinancial', route: routesPaths.financial },
          { title: 'common.forecasting', route: routesPaths.forecasting }
        ]
      },
      {
        title: 'common.settings',
        icon: <SettingsIcon />,
        children: [
          { title: 'settings.accessRoles', route: routesPaths.accessRoles },
          { title: 'settings.mappingRoles', route: routesPaths.mappingRoles },
          { title: 'settings.accessMatrix', route: routesPaths.accessMatrix }
        ]
      }
    ],
    [ROLES.leadership]: [
      {
        title: 'common.cap.insights',
        icon: <RocketIcon />,
        children: [
          { title: 'common.capacityAndUtilization', route: routesPaths.utilizationInsights },
          { title: 'common.financial', route: routesPaths.financialInsights },
          { title: 'common.bench', route: routesPaths.benchInsights }
        ]
      },
      {
        title: 'common.projects',
        icon: <BriefcaseIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.projectsOverview },
          { title: 'common.timeline', route: routesPaths.projectsTimeline }
        ]
      },
      {
        title: 'common.resources',
        icon: <PeopleIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.resources },
          { title: 'common.myReports', route: routesPaths.myReports }
        ]
      },
      {
        title: 'common.financial',
        icon: <CoinStackIcon />,
        children: [
          { title: 'common.projectFinancial', route: routesPaths.financial },
          { title: 'common.forecasting', route: routesPaths.forecasting }
        ]
      }
    ],
    [ROLES.resourceManager]: [
      {
        title: 'common.cap.insights',
        icon: <RocketIcon />,
        children: [
          { title: 'common.capacityAndUtilization', route: routesPaths.utilizationInsights },
          { title: 'common.bench', route: routesPaths.benchInsights }
        ]
      },
      {
        title: 'common.projects',
        icon: <BriefcaseIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.projectsOverview },
          { title: 'common.timeline', route: routesPaths.projectsTimeline }
        ]
      },
      {
        title: 'common.resources',
        icon: <PeopleIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.resources },
          { title: 'common.myReports', route: routesPaths.myReports }
        ]
      }
    ],
    [ROLES.deliveryOwner]: [
      {
        title: 'common.cap.insights',
        icon: <RocketIcon />,
        children: [
          { title: 'common.capacityAndUtilization', route: routesPaths.utilizationInsights },
          { title: 'common.bench', route: routesPaths.benchInsights }
        ]
      },
      {
        title: 'common.projects',
        icon: <BriefcaseIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.projectsOverview },
          { title: 'common.timeline', route: routesPaths.projectsTimeline }
        ]
      },
      {
        title: 'common.resources',
        icon: <PeopleIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.resources },
          { title: 'common.myReports', route: routesPaths.myReports }
        ]
      },
      {
        title: 'common.financial',
        icon: <CoinStackIcon />,
        children: [{ title: 'common.projectFinancial', route: routesPaths.financial }]
      }
    ],
    [ROLES.manager]: [
      {
        title: 'common.cap.insights',
        icon: <RocketIcon />,
        children: [
          { title: 'common.cap.legacy', route: routesPaths.dashboard },
          { title: 'common.capacityAndUtilization', route: routesPaths.utilizationInsights },
          { title: 'common.bench', route: routesPaths.benchInsights }
        ]
      },
      {
        title: 'common.projects',
        icon: <BriefcaseIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.projectsOverview },
          { title: 'common.timeline', route: routesPaths.projectsTimeline }
        ]
      },
      {
        title: 'common.resources',
        icon: <PeopleIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.resources },
          { title: 'common.myReports', route: routesPaths.myReports }
        ]
      },
      {
        title: 'common.financial',
        icon: <CoinStackIcon />,
        children: [{ title: 'common.projectFinancial', route: routesPaths.financial }]
      }
    ],
    [ROLES.sales]: [
      {
        title: 'common.cap.insights',
        icon: <RocketIcon />,
        children: [{ title: 'common.bench', route: routesPaths.benchInsights }]
      },
      {
        title: 'common.myProjects',
        icon: <BriefcaseIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.projectsOverview },
          { title: 'common.timeline', route: routesPaths.projectsTimeline }
        ]
      }
    ],
    [ROLES.finance]: [
      {
        title: 'common.cap.insights',
        icon: <RocketIcon />,
        children: [
          { title: 'common.capacityAndUtilization', route: routesPaths.utilizationInsights },
          { title: 'common.financial', route: routesPaths.financialInsights }
        ]
      },
      {
        title: 'common.resources',
        icon: <PeopleIcon />,
        children: [{ title: 'common.overview', route: routesPaths.resources }]
      },
      {
        title: 'common.financial',
        icon: <CoinStackIcon />,
        children: [
          { title: 'common.projectFinancial', route: routesPaths.financial },
          { title: 'common.forecasting', route: routesPaths.forecasting }
        ]
      }
    ],
    [ROLES.projectManager]: [
      {
        title: 'common.myProjects',
        icon: <BriefcaseIcon />,
        children: [
          { title: 'common.overview', route: routesPaths.projectsOverview },
          { title: 'common.timeline', route: routesPaths.projectsTimeline }
        ]
      },
      {
        title: 'common.financial',
        icon: <CoinStackIcon />,
        children: [{ title: 'common.projectFinancial', route: routesPaths.financial }]
      }
    ],
    [ROLES.people]: [
      {
        title: 'common.cap.insights',
        icon: <RocketIcon />,
        children: [{ title: 'common.bench', route: routesPaths.benchInsights }]
      },
      {
        title: 'common.projects',
        icon: <BriefcaseIcon />,
        children: [{ title: 'common.timeline', route: routesPaths.projectsTimeline }]
      },
      {
        title: 'common.resources',
        icon: <PeopleIcon />,
        children: [{ title: 'common.overview', route: routesPaths.resources }]
      }
    ],
    [ROLES.leader]: [
      {
        title: 'common.resources',
        icon: <PeopleIcon />,
        children: [{ title: 'common.myReports', route: routesPaths.myReports }]
      }
    ]
  };

  const navItems = useMemo(() => {
    const routes = linkRoutes[role] || [];
    if (temporalRoles?.length) {
      const allRoutes = temporalRoles?.reduce(
        (navItems, { group }) => {
          const temporalRoutes = linkRoutes[group];
          const newRoutes = temporalRoutes.filter(route => !navItems.includes(route));
          return [...navItems, ...newRoutes];
        },
        [...routes]
      );

      const filteredRoutes = allRoutes?.reduce((route, item) => {
        const routeCopy = { ...route };

        if (route[item.title]) {
          const routeChildren = route[item.title].children;
          const newChildren = item.children.filter(itemChild => !routeChildren.includes(itemChild));

          routeCopy[item.title].children = [...routeChildren, ...newChildren];

          return routeCopy;
        }

        routeCopy[item.title] = { ...item };

        return routeCopy;
      }, {});

      const filteredChildren = Object.values(filteredRoutes)?.map(route => {
        const titles = [];
        return {
          ...route,
          children: route.children.filter(child => {
            if (!titles.includes(child.title)) {
              titles.push(child.title);
              return child;
            }
          })
        };
      });

      return filteredChildren;
    }
    return routes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporalRoles, role]);

  return (
    <Container isOpen={isOpen} authenticated={authenticated}>
      <Button type="button" onClick={toggleSidebar}>
        <ArrowUpIcon $isOpen={isOpen} />
      </Button>
      <List>
        {authenticated &&
          navItems?.map(({ title, route, icon, children }) => (
            <Item key={route + title} isOpen={isOpen}>
              {route ? (
                <NavLink
                  to={route}
                  className={route.includes(routeBase) ? 'active' : ''}
                  activeClassName="active"
                >
                  {icon && React.cloneElement(icon, { active: route.includes(routeBase) })}
                  <span>{intl.formatMessage({ id: title })}</span>
                </NavLink>
              ) : (
                <SubItem isOpen={isOpen} icon={icon} title={title}>
                  {children}
                </SubItem>
              )}
            </Item>
          ))}
      </List>
    </Container>
  );
};

export default Sidebar;
