import { bool, func, string } from 'prop-types';

import { BUTTON_VARIANTS } from 'constants/constants';

import { CheckIcon, StyledButton } from './styles';

function EditButtons({ saveId, cancelId, onSave, onCancel, isLoading = false }) {
  return (
    <>
      <StyledButton
        type="button"
        id={saveId}
        textIntlId="common.saveChanges"
        icon={<CheckIcon />}
        variant={BUTTON_VARIANTS.NEW_PRIMARY}
        onClick={onSave}
        isLoading={isLoading}
      />
      <StyledButton
        type="button"
        id={cancelId}
        textIntlId="common.cancel"
        variant={BUTTON_VARIANTS.NEW_SECONDARY}
        onClick={onCancel}
        isLoading={isLoading}
      />
    </>
  );
}

EditButtons.propTypes = {
  cancelId: string,
  isLoading: bool,
  onCancel: func,
  onSave: func,
  saveId: string
};

export default EditButtons;
