import { oneOf, string, node } from 'prop-types';

import { EVENT_TYPE } from 'constants/constants';
import { stringToDate } from 'utils/date';

import { EventContainer, TextContainer, Pill } from './styles';

const Event = ({
  eventStartDate: start,
  eventEndDate: end,
  type,
  text,
  color,
  textColor,
  children
}) => {
  const startDate = stringToDate(start);
  const endDate = stringToDate(end);

  return (
    <EventContainer startDate={startDate} endDate={endDate} color={color} type={type}>
      <TextContainer textColor={textColor}>
        <Pill colo={color} type={type}>
          {text}
        </Pill>
      </TextContainer>
      {children}
    </EventContainer>
  );
};

Event.propTypes = {
  eventStartDate: string.isRequired,
  eventEndDate: string.isRequired,
  type: oneOf(Object.values(EVENT_TYPE)),
  color: string,
  text: string,
  textColor: string,
  children: node
};

export default Event;
