import { bool, object } from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import EmptyState from 'components/common/EmptyState';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import Cell from 'components/ProjectsOverview/AssignmentsTable/Cell';
import { SORT_DIR } from 'constants/constants';
import { sortTable, sortAssignmentsByDefault } from 'utils/projectsOverviewUtilities';

import { ArrowIcon, Column, HeaderColumnContainer, Row, Table, TableWrapper } from './styles';

const greaterFunc = (a, b) => {
  if (!b) return true;
  if (a?.fullName && b?.fullName) return a?.fullName > b?.fullName;
  if (a?.order && b?.order) return a.order > b.order;
  return a > b;
};

const lowerFunc = (a, b) => {
  if (!a) return true;
  if (a?.fullName && b?.fullName) return a?.fullName < b?.fullName;
  if (a?.order && b?.order) return a.order < b.order;
  return a < b;
};

const roleLowerFunc = (a, b) => {
  if (!b) return true;
  if (a && b) {
    if (b.includes('manager')) {
      return true;
    }
    if (b.includes('lead') && (a.includes('manager') || a.includes('lead'))) {
      return false;
    }

    return false;
  }
};

const columnsKeys = {
  assignment: 'assignment',
  status: 'status',
  // margin: 'margin', commenting this line until there is definition about it
  seniority: 'seniority',
  allocations: 'allocation',
  startDate: 'startDate',
  endDate: 'endDate',
  role: 'role'
};

let columns = Object.values(columnsKeys).map(columnKey => ({
  key: columnKey,
  header: `projectsOverview.detail.table.columns.${columnKey}`,
  greaterFunc,
  lowerFunc
}));

const tieBreaker = (a, b) => {
  const fullNameA = a?.assignment?.fullName;
  const fullNameB = b?.assignment?.fullName;
  return fullNameA && fullNameB ? fullNameA.localeCompare(fullNameB) : a;
};

const AssignmentDetailsTable = ({ old = false, originalAssignments, isLoadingAssignments }) => {
  const intl = useIntl();

  const [assignments, setAssignments] = useState(originalAssignments);

  const onFirstLoad = useRef(false);

  const [sortConfig, setSortConfig] = useState({
    key: 'role',
    direction: SORT_DIR.desc
  });
  const onSort = ({ key: columnKey }) => {
    let direction = SORT_DIR.desc;
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === SORT_DIR.desc) {
      direction = SORT_DIR.asc;
    }
    setSortConfig({
      key: columnKey,
      direction
    });
  };

  if (old) {
    columns = columns.filter(column => column.key !== 'margin' && column.key !== 'status');
  } else {
    columns = Object.values(columnsKeys).map(columnKey => ({
      key: columnKey,
      header: `projectsOverview.detail.table.columns.${columnKey}`,
      greaterFunc,
      lowerFunc
    }));
  }

  const sortedAssignments = useMemo(() => {
    if (!onFirstLoad.current && assignments?.length) {
      onFirstLoad.current = true;
      return sortAssignmentsByDefault(assignments);
    }

    if (!sortConfig) return assignments;
    if (sortConfig.key === 'role') {
      columns = columns.map(column => ({
        ...column,
        lowerFunc: roleLowerFunc
      }));
    } else {
      columns = columns.map(column => ({ ...column, greaterFunc, lowerFunc }));
    }
    const sortedRows = sortTable({
      columns,
      sortConfig,
      rows: assignments || [],
      tieBreaker
    });
    return sortedRows;
  }, [assignments, sortConfig, onFirstLoad, assignments]);

  const isLoading = isLoadingAssignments && !assignments;

  useEffect(() => {
    setAssignments(originalAssignments);
  }, [originalAssignments, sortAssignmentsByDefault]);

  return (
    <>
      <TableWrapper>
        <Table>
          <thead>
            <Row height="2.1rem" isHeader>
              {columns?.map(column => {
                const isSorting = sortConfig && sortConfig.key === column.key;
                return (
                  column.key !== 'role' && (
                    <th key={column.key}>
                      <HeaderColumnContainer
                        onClick={() => !isLoading && onSort(column)}
                        $disabled={isLoading}
                      >
                        <span>{column.header && intl.formatMessage({ id: column.header })}</span>
                        <Column width="1.2rem">
                          <ArrowIcon
                            direction={isSorting ? sortConfig.direction : SORT_DIR.desc}
                            $isSorting={!isLoading && isSorting}
                            disabled={isLoading}
                          />
                        </Column>
                      </HeaderColumnContainer>
                    </th>
                  )
                );
              })}
              <td />
            </Row>
          </thead>
          <tbody>
            {!isLoading && sortedAssignments?.length ? (
              sortedAssignments.map(assignmentItem => {
                const { id: assignmentId, overassigned } = assignmentItem;

                return (
                  <Fragment key={assignmentId}>
                    <Row key={assignmentId}>
                      {Object.values(columns).map(({ key }) => (
                        <Cell
                          value={key !== 'role' && assignmentItem[key]}
                          columnKey={key}
                          key={`${assignmentId}-${key}`}
                          overassigned={overassigned}
                        />
                      ))}
                    </Row>
                  </Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <EmptyState
                    className="accessRoles"
                    titleIntlId="emptyState.title.finishedAssignments"
                    subtitleIntlId="emptyState.subtitle.finishedAssignments"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      {isLoading && <CenteredLoading />}
    </>
  );
};

AssignmentDetailsTable.propTypes = {
  originalAssignments: object,
  isLoadingAssignments: bool,
  old: bool
};
export default AssignmentDetailsTable;
