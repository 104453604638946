import isEmpty from 'lodash/isEmpty';
import { arrayOf } from 'prop-types';
import { useState } from 'react';

import Tooltip from 'components/common/Tooltip';
import { skillShape } from 'constants/shapes';

import {
  Container,
  SkillToolTipList,
  SkillListExperience,
  SkillListName,
  HighlightedSkill,
  StyledStar
} from './styles';

const SkillList = ({ skills }) => {
  const [showSkillTooltip, setShowSkillTooltip] = useState(false);

  return (
    <>
      <Container
        data-tip
        data-for="skills-tooltip"
        onMouseEnter={() => setShowSkillTooltip(true)}
        onMouseLeave={() => setShowSkillTooltip(false)}
      >
        {isEmpty(skills) ? '-' : skills.map(({ skill }) => skill).join(', ')}
      </Container>
      {showSkillTooltip && !isEmpty(skills) && (
        <Tooltip id="skills-tooltip" place="top" type="light" effect="float">
          {skills.map(({ skill, experienceTime, highlighted }, index) => {
            const formattedSkill = highlighted ? (
              <HighlightedSkill>
                <StyledStar height="11" width="12" viewBox="0 0 20 20" />
                {skill}
              </HighlightedSkill>
            ) : (
              skill
            );

            return (
              <SkillToolTipList key={`${skill}-${experienceTime}-${index}`}>
                <SkillListName>{formattedSkill}</SkillListName>
                <SkillListExperience>{experienceTime}</SkillListExperience>
              </SkillToolTipList>
            );
          })}
        </Tooltip>
      )}
    </>
  );
};

SkillList.propTypes = {
  skills: arrayOf(skillShape).isRequired
};

export default SkillList;
