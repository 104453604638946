import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getMarginColor, getMarginColorBackground } from 'utils/financialColors';

export const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral400};
  border-radius: 6px;
  padding: 0 1.4rem;
`;

export const ContainerDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled(Link)`
  ${({ theme }) => theme.new.typography.p5};
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: flex;
  gap: 5rem;
  height: 10rem;
  min-width: 126rem;
  padding: 0 3.8rem;
  position: relative;
  text-decoration: none;
  width: 100%;

  :hover {
    box-shadow: ${({ theme }) => theme.new.shadows.large.boxShadow};
    cursor: pointer;
  }
`;

export const Column = styled.div`
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ textAlign }) => textAlign || 'center'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  flex-basis: 100%;
  display: flex;
  gap: ${({ gap }) => gap || '1.5rem'};
  position: relative;
`;

export const Name = styled(Link)`
  ${({ theme }) => theme.new.typography.p2SemiBold};
  color: ${({ theme }) => theme.new.colors.black};
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
`;

export const Margin = styled.div`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  background: ${getMarginColorBackground};
  color: ${getMarginColor};
  border-radius: 4rem;
  align-items: center;
  padding: 0.1rem 0.8rem;
  gap: 1rem;
`;

export const Label = styled.div`
  ${({ theme, strong }) => (strong ? theme.new.typography.p5SemiBold : theme.new.typography.p5)};
  display: flex;
  color: ${({ theme }) => theme.new.colors.neutral800};
`;

export const ServiceType = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
`;

export const Title = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  display: flex;
  color: ${({ theme }) => theme.new.colors.neutral900};
`;
