import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.datepickerGrayBg};
  border-top: ${({ theme }) => `1px solid ${theme.colors.datepickerGrayBorder}`};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`;

export const Button = styled.button`
  font-size: 1.4rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray30 : theme.colors.seaBlue};
  border-radius: 5px;
  padding: 0.6rem 1rem;
  margin: 0 1rem;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
`;
