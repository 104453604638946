import { bool } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as AlertArticleIcon } from 'assets/icons/alert-article-icon.svg';
import { ReactComponent as UserCheckIcon } from 'assets/icons/user-check-icon.svg';
import { ReactComponent as UserPlusIcon } from 'assets/icons/user-plus-icon.svg';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { assignmentTopOverviewShape, projectTopOverviewShape } from 'constants/shapes';
import theme from 'constants/theme';

import Card from './Card';
import GraphicCard from './GraphicCard';
import { Container } from './styles';

function ProjectTopOverview({ assignments, projects, isLoading }) {
  const intl = useIntl();

  const {
    totalOpen,
    totalOpenProjects,
    totalPending,
    totalPendingProjects,
    totalOverassigned,
    totalOverassignedProjects
  } = assignments || {};

  return (
    <Container>
      {isLoading ? (
        <CenteredLoading />
      ) : (
        <>
          <Card
            color={theme.new.colors.BO500}
            title={intl.formatMessage({ id: `projects.openAssignments` })}
            Icon={UserPlusIcon}
            total={totalOpen}
            count={totalOpenProjects}
          />
          <Card
            color={theme.new.colors.BP500}
            title={intl.formatMessage({ id: `projects.pendingAssignments` })}
            Icon={UserCheckIcon}
            total={totalPending}
            count={totalPendingProjects}
          />
          <Card
            color={theme.new.colors.BOA500}
            title={intl.formatMessage({ id: `common.overassigned` })}
            Icon={AlertArticleIcon}
            total={totalOverassigned}
            count={totalOverassignedProjects}
          />
          <GraphicCard projects={projects} />
        </>
      )}
    </Container>
  );
}

ProjectTopOverview.propTypes = {
  projects: projectTopOverviewShape,
  assignments: assignmentTopOverviewShape,
  isLoading: bool
};

export default ProjectTopOverview;
