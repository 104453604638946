import { string } from 'prop-types';

import RoundAvatar from 'components/common/RoundAvatar';

import { ProfileInfo } from './styles';

const Profile = ({ image, name, role, senior }) => (
  <>
    <RoundAvatar src={image} squareSize={6.4} />
    <ProfileInfo>
      <h2>{name}</h2>
      <h4>{role}</h4>
      <p>{senior}</p>
    </ProfileInfo>
  </>
);

Profile.propTypes = {
  image: string,
  name: string.isRequired,
  role: string.isRequired,
  senior: string.isRequired
};

export default Profile;
