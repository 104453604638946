import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 6px;
  margin: 2.2rem 5rem;
  padding: 2.8rem 2rem;
`;

export const Header = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.new.colors.neutral300}`};
  display: flex;
  justify-content: space-between;
  padding-bottom: 3.7rem;
  margin-bottom: 4rem;
`;

export const Title = styled.div`
  ${({ theme }) => theme.new.typography.h2};
  color: ${({ theme }) => theme.new.colors.black};
  max-width: 25.1rem;
  width: 100%;
`;

export const ProjectHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const GraphReferences = styled.div`
  align-items: center;
  display: flex;
  height: 7.8rem;
  justify-content: space-between;
  margin-bottom: 3.7rem;
`;
