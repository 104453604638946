import styled from 'styled-components';

export const FiltersContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 3rem;
  padding: 0 1.4rem;
`;

export const Selectors = styled.div`
  align-items: center;
  display: flex;
`;
