import { number, string } from 'prop-types';

function BarWithBorder({ size, ...props }) {
  const { fill, x, y, width, height, stroke } = props;

  if (width < 0 || height < 0) return null;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="none"
        strokeWidth={size}
        fill={fill}
      />
      <rect x={x} y={y} width={width} height={size} stroke="none" fill={stroke} />
      <rect x={x} y={y} width={size} height={height} stroke="none" fill={stroke} />
      <rect x={x + width} y={y} width={size} height={height} stroke="none" fill={stroke} />
    </g>
  );
}

BarWithBorder.propTypes = {
  fill: string.isRequired,
  height: number.isRequired,
  size: number.isRequired,
  stroke: string.isRequired,
  width: number.isRequired,
  x: number.isRequired,
  y: number.isRequired
};

export default BarWithBorder;
