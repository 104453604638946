import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: 5px;
  padding: 2rem;

  background-color: ${({ theme }) => theme.new.colors.white};
  box-shadow: ${({ theme }) => theme.new.shadows.extraLarge};
`;

export const Header = styled.h3`
  margin-bottom: 2rem;

  ${({ theme }) => theme.new.typography.p5SemiBold}
  color: ${({ theme }) => theme.new.colors.neutral900};
`;

export const DataContainer = styled.div`
  column-gap: 5rem;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 1rem;

  ${({ theme }) => theme.new.typography.p5}
  color: ${({ theme }) => theme.new.colors.neutral800};
`;

export const ItemKey = styled.p`
  display: flex;
  align-items: center;

  ${({ fill, stroke }) => {
    if (fill && stroke)
      return css`
        &::before {
          background-color: ${fill};
          border-radius: 7.5px;
          border: 1px solid ${stroke};
          content: '';
          display: inline-block;
          height: 0.9rem;
          margin-right: 0.5rem;
          width: 0.9rem;
        }
      `;
  }}
`;

export const ItemValue = styled.div`
  margin-left: auto;
`;
