import isEmpty from 'lodash/isEmpty';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { getRoles } from 'state/actions/uiActions';
import { rolesToOption } from 'utils/assignmentFormsUtilities';
import { sortOptions } from 'utils/filtersUtilities';

export default () => {
  const [rolesOptions, setRolesOptions] = useState([]);
  const getRolesRequest = useDispatch(getRoles);
  const { status: rolesStatus, error: rolesError } = useStatus(getRoles);

  const roles = useSelector(({ ui }) => ui.options.roles);

  useEffect(() => {
    if (isEmpty(roles)) {
      getRolesRequest();
    } else {
      setRolesOptions(sortOptions(roles.map(rolesToOption)));
    }
  }, [roles, getRolesRequest]);

  return {
    roles,
    getRolesRequest,
    rolesStatus,
    rolesError,
    rolesOptions
  };
};
