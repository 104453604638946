import { node, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { Container } from './styles';

const ProjectItems = ({ title, item, variant = 'primary' }) => {
  const intl = useIntl();

  const renderItems = () => {
    if (Array.isArray(item)) {
      const names = item.map(itm => itm.name).join(', ');
      return names;
    }

    return item;
  };

  return (
    <Container variant={variant}>
      <h4>{intl.formatMessage({ id: title })}</h4>
      <div>{renderItems()}</div>
    </Container>
  );
};

ProjectItems.propTypes = {
  item: oneOfType([string, node]),
  title: string,
  variant: string
};

export default ProjectItems;
