import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';

import AvatarSvg from 'assets/icons/avatar.svg';
import { AvailableCapacityGraphShape } from 'constants/shapes';

import { ContainerBarTooltip } from './styles';

const BarTooltip = ({ active, payload, label }) => {
  if (active && payload?.length) {
    const { percentage, peopleTotal, availablePeople } = payload[0].payload;

    return (
      <ContainerBarTooltip>
        <span>{`${label}:`}</span>
        <span>{` ${percentage}% `}</span>
        <p>
          (
          <img src={AvatarSvg} width="14px" height="14px" alt="avatar" /> &nbsp;
          <span>{` ${availablePeople}`}</span>
          &nbsp;{`of ${peopleTotal})`}
        </p>
      </ContainerBarTooltip>
    );
  }

  return null;
};

BarTooltip.propTypes = {
  active: bool,
  label: oneOfType([string, number]),
  payload: arrayOf(shape({ payload: AvailableCapacityGraphShape }))
};

export default BarTooltip;
