import { bool, func, number } from 'prop-types';

import LoadingWrapper from 'components/common/LoadingWrapper';
import { reportsProfileShape } from 'constants/shapes';

import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import { Container } from './styles';

const ResourceProfile = ({ profileData, personId, isLoadingResource, getLeftPanelData }) => (
  <Container>
    <LoadingWrapper isLoading={isLoadingResource}>
      <LeftPanel data={profileData} personId={personId} getLeftPanelData={getLeftPanelData} />
    </LoadingWrapper>
    <RightPanel personId={personId} departmentId={profileData?.department?.id} />
  </Container>
);

ResourceProfile.propTypes = {
  profileData: reportsProfileShape,
  personId: number,
  isLoadingResource: bool,
  getLeftPanelData: func
};

export default ResourceProfile;
