import styled from 'styled-components';

import Button from 'components/common/Button';
import { AdditionalChargeInput } from 'components/Financial/Inputs';
import { getMarginColor, getMarginColorBorder } from 'utils/financialColors';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 1.2rem;
  justify-content: start;
  width: 100%;
`;

export const GeneralContainer = styled(Container)`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  padding: 1.2rem 0;
`;

export const Billed = styled.p`
  color: ${({ theme }) => theme.new.colors.N700};
  ${({ theme }) => theme.new.typography.p6SemiBold}
  padding: ${({ editingPeriod }) => (editingPeriod ? '1.1rem 0' : 0)};
`;

export const PeriodTotalInput = styled(AdditionalChargeInput)`
  margin: 0;
  width: 8.8rem;
`;

export const MarginLabel = styled.div`
  ${({ theme }) => theme.new.typography.p6SemiBold}
`;

export const Margin = styled.p`
  ${({ theme }) => theme.new.typography.p5}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 1.2rem;
  background: ${({ theme }) => theme.new.colors.white};
  color: ${getMarginColor};
  border: 1px solid ${getMarginColorBorder};
  border-radius: 65px;
  line-height: 1.4rem;
`;

export const TotalRow = styled.div`
  ${({ theme }) => theme.new.typography.p6}
  color: ${({ theme }) => theme.new.colors.N700};
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FieldInformation = styled.div`
  display: flex;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  stroke: ${({ theme }) => theme.new.colors.white};
  fill: ${({ theme }) => theme.new.colors.neutral700};
`;
