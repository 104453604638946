import { createAction } from '@reduxjs/toolkit';

import SettingsService from 'services/settingsService';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const updateTemporalRoles = createAction('UPDATE_TEMPORAL_ROLES');

export const getResources = createAsyncThunk('GET_RESOURCES', async () => {
  try {
    const { data } = await SettingsService.getResources();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getGroups = createAsyncThunk('GET_GROUPS', async () => {
  try {
    const { data } = await SettingsService.getGroups();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getResourceRoles = createAsyncThunk('GET_RESOURCE_ROLES', async () => {
  try {
    const { data } = await SettingsService.getResourceRoles();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const addTemporalRole = createAsyncThunk(
  'ADD_TEMPORAL_ROLE',
  async ({ body }, { dispatch }) => {
    try {
      const { data } = await SettingsService.addTemporalRole(body);
      dispatch(getResources());
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const editResourceRole = createAsyncThunk(
  'EDIT_RESOURCE_ROLE',
  async ({ id, body }, { dispatch }) => {
    try {
      const { data } = await SettingsService.editResourceRole(id, body);
      dispatch(getResources());
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const deleteResourceRole = createAsyncThunk(
  'DELETE_RESOURCE_ROLE',
  async ({ id, userId, resourceId }, { dispatch }) => {
    try {
      const { data } = await SettingsService.deleteResourceRole(id);
      resourceId === userId && dispatch(updateTemporalRoles({ temporalRoleId: id }));
      dispatch(getResources());
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getRoleGroups = createAsyncThunk('GET_ROLE_GROUPS', async (_, { dispatch }) => {
  try {
    const { data } = await SettingsService.getRoleGroups();
    dispatch(getResources());
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const addRoleRelation = createAsyncThunk(
  'ADD_ROLE_RELATION',
  async ({ body }, { dispatch }) => {
    try {
      const { data } = await SettingsService.addRoleRelation(body);
      dispatch(getRoleGroups());
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const editRoleRelation = createAsyncThunk(
  'EDIT_ROLE_RELATION',
  async ({ id, body }, { dispatch }) => {
    try {
      const { data } = await SettingsService.editRoleRelation(id, body);
      dispatch(getRoleGroups());
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const deleteRoleRelation = createAsyncThunk(
  'DELETE_ROLE_RELATION',
  async ({ id }, { dispatch }) => {
    try {
      const { data } = await SettingsService.deleteRoleRelation(id);
      dispatch(getRoleGroups());
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);
