import { arrayOf, number } from 'prop-types';
import { useState } from 'react';

import TabButtons from 'components/common/TabButtons';
import { PROFILE_TABS, PROFILE_TABS_ARRAY } from 'constants/constants';
import { projectsReportsProfile, timeOffsShape } from 'constants/shapes';

import ProjectsTab from './ProjectsTab';
import SkillSetTab from './SkillSetTab';
import { Container } from './styles';

const RightPanel = ({ assignments, timeOffs, personId, departmentId }) => {
  const [activeTab, setActiveTab] = useState(PROFILE_TABS.skillSet);
  return (
    <Container>
      <TabButtons activeTab={activeTab} setActiveTab={setActiveTab} tabs={PROFILE_TABS_ARRAY} />
      {activeTab === PROFILE_TABS.skillSet && (
        <SkillSetTab personId={personId} departmentId={departmentId} />
      )}
      {activeTab === PROFILE_TABS.projects && (
        <ProjectsTab timeOffs={timeOffs} assignments={assignments} />
      )}
    </Container>
  );
};

RightPanel.propTypes = {
  assignments: arrayOf(projectsReportsProfile),
  timeOffs: arrayOf(timeOffsShape),
  personId: number,
  departmentId: number
};

export default RightPanel;
