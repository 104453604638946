import { useSelector } from 'react-redux';

import { FULFILLED, REJECTED, PENDING } from 'constants/actionStatusConstants';

/**
 * @typedef UseStatusResult
 * @property {string} status
 * @property {string} error
 * @property {boolean} isPending
 * @property {boolean} isFulfilled
 * @property {boolean} isRejected
 */

/**
 * useStatus hook
 *
 * @param {string} action Prefix for the action names
 *
 * @returns {UseStatusResult} Object with status and error keys
 *
 * @example
 * const { status, error } = useStatus(login)
 */

const useStatus = action =>
  useSelector(({ statusReducer }) => {
    const { status, error } = statusReducer[action?.typePrefix] || {};

    return {
      status,
      error,
      isPending: status === PENDING,
      isFulfilled: status === FULFILLED,
      isRejected: status === REJECTED
    };
  });

export default useStatus;
