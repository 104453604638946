import styled from 'styled-components';

export const TablesContainer = styled.div`
  margin: 2.4rem 3.2rem;
  min-height: calc(100vh - (2 * 2.4rem));
`;

export const GlossaryContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

export const FilterContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 1rem 1.4rem;
  margin-bottom: 1.3rem;
`;
