import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { getFormatDateStandard } from 'utils/date';

import { RangeDatesContainer } from './styles';

const RangeDates = ({
  startDate,
  endDate,
  className = '',
  bold = false,
  ongoing = false,
  dateFormatter = getFormatDateStandard
}) => {
  const intl = useIntl();
  const start = startDate && dateFormatter(startDate);
  const end = endDate && dateFormatter(endDate);

  const textContent = () => {
    if (start) {
      if (ongoing) {
        return (
          <>
            <span>{start}</span>
            <span> - </span>
            <span>{intl.formatMessage({ id: 'common.lower.ongoing' })}</span>
          </>
        );
      }
      if (end) {
        return (
          <>
            <span>{start}</span>
            <span> {intl.formatMessage({ id: 'common.lower.to' })} </span>
            <span>{end}</span>
          </>
        );
      }
    }

    return <>{intl.formatMessage({ id: 'common.unknown' })}</>;
  };

  return (
    <RangeDatesContainer className={className} $bold={bold}>
      {textContent()}
    </RangeDatesContainer>
  );
};

RangeDates.propTypes = {
  bold: bool,
  className: string,
  endDate: oneOfType([string, instanceOf(Date)]),
  startDate: oneOfType([string, instanceOf(Date)]),
  ongoing: bool,
  dateFormatter: func
};

export default RangeDates;
