const sortBy = condition => (condition ? -1 : 1);

const totalUtilizationSelector = row => row.capacity / row.scheduled;
const assignmentsSelector = row => row.capacity / row.billable;
const benchUtilizationSelector = row => row.capacity / row.benchHours;
const benchPendingSelector = row => (row.benchHours + row.pending) / row.capacity;

const realHoursSelector = row => row.scheduled / row.harvestHours;

export const TOTAL_UTILIZATION_SORT = ({ original: rowA }, { original: rowB }) =>
  sortBy(totalUtilizationSelector(rowA) < totalUtilizationSelector(rowB));

export const ASSIGNMENTS_SORT = ({ original: rowA }, { original: rowB }) =>
  sortBy(assignmentsSelector(rowA) < assignmentsSelector(rowB));

export const BENCH_UTILIZATION_SORT = ({ original: rowA }, { original: rowB }) =>
  sortBy(benchUtilizationSelector(rowA) < benchUtilizationSelector(rowB));

export const REAL_HOURS_SORT = ({ original: rowA }, { original: rowB }) =>
  sortBy(realHoursSelector(rowA) < realHoursSelector(rowB));

export const BENCH_PENDING_SORT = ({ original: rowA }, { original: rowB }) =>
  sortBy(benchPendingSelector(rowA) < benchPendingSelector(rowB));
