import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';
import { getRequestFormatDate } from 'utils/date';

class AssignmentService {
  static async createAssignment({ assignment, quantity, note, startDate, endDate }) {
    const start = getRequestFormatDate(startDate);
    const end = getRequestFormatDate(endDate);
    return httpClient.post(ENDPOINTS.assignments(start, end), {
      assignment,
      quantity,
      note
    });
  }

  static async editAssignment({ assignmentId, assignment, note, startDate, endDate }) {
    const start = getRequestFormatDate(startDate);
    const end = getRequestFormatDate(endDate);

    return httpClient.put(ENDPOINTS.updateAssignment(start, end, assignmentId), {
      assignment,
      note
    });
  }

  static async deleteAssignment({ assignmentId, startDate, endDate }) {
    const start = getRequestFormatDate(startDate);
    const end = getRequestFormatDate(endDate);
    return httpClient.delete(ENDPOINTS.updateAssignment(start, end, assignmentId), {
      data: {}
    });
  }

  static async getAssignmentById(assignmentId) {
    return httpClient.get(ENDPOINTS.getAssignment(assignmentId));
  }

  static async editNotes({ noteId, note }) {
    return httpClient.put(ENDPOINTS.getAssignmentNote(noteId), note);
  }
}

export default AssignmentService;
