import dayjs from 'dayjs';

import { BILLING_UNITS } from 'constants/constants';

import { isAfter } from './date';
import {
  getResourceStateVariables,
  convertCostsToBillingUnit,
  convertHoursToBillingUnit,
  handlePriceWithDiscount
} from './financialUtilities';

const { HOURLY, DAILY } = BILLING_UNITS;

export const getOpenBillingPeriodsData = (resources, periods) => {
  if (!resources && !periods) return [];
  const dateNow = dayjs();

  const resourcesList = resources.map((resource, index) => {
    const {
      name,
      rate: rateValue,
      startDate,
      endDate,
      timeOffs,
      capacity,
      allocationPercentage
    } = resource;

    const {
      resourceStates: { isLeave, isOut, isGettingAssigned, isUpcomingChanges, vacationData }
    } = getResourceStateVariables(dateNow, resources, {
      startDate,
      endDate,
      resourceName: name,
      index,
      timeOffs
    });

    return {
      ...resource,
      name,
      rate: {
        [HOURLY]: rateValue,
        [DAILY]: convertCostsToBillingUnit({ from: HOURLY, to: DAILY, capacity }, rateValue)
      },
      startDate,
      endDate,
      isLeave,
      isOut,
      isGettingAssigned,
      isUpcomingChanges,
      vacationData,
      capacity,
      allocationPercentage
    };
  });

  const newPeriods = periods.map(period => ({
    ...period,
    info: resourcesList.map(resource => ({
      periodId: period.id,
      setupId: resource.setupId,
      bill: null,
      allocation: null,
      rate: resource.rate,
      isLeave: resource.isLeave,
      isOut: resource.isOut,
      isGettingAssigned: resource.isGettingAssigned,
      isUpcomingChanges: resource.isUpcomingChanges,
      capacity: resource.capacity,
      allocationPercentage: resource.allocationPercentage,
      discountPercentage: resource.discountPercentage
    }))
  }));

  resources.forEach((resource, resourceIndex) =>
    resource.periodsInfo.forEach(info => {
      periods.forEach((period, periodIndex) => {
        if (period.id === info.periodId) {
          newPeriods[periodIndex].info[resourceIndex].bill = {
            [HOURLY]: info.bill,
            [DAILY]: convertHoursToBillingUnit(
              {
                from: HOURLY,
                to: DAILY,
                capacity: resource.capacity,
                allocationPercentage: resource.allocationPercentage
              },
              info.bill
            )
          };
          newPeriods[periodIndex].info[resourceIndex].allocation = {
            [HOURLY]: info.allocation,
            [DAILY]: convertHoursToBillingUnit(
              {
                from: HOURLY,
                to: DAILY,
                capacity: resource.capacity,
                allocationPercentage: resource.allocationPercentage
              },
              info.allocation
            )
          };
        }
      });
    })
  );

  return [resources, newPeriods];
};

export const getMarginPercentage = (billed, cost) => {
  if (billed > 0) {
    return ((billed - cost) / billed) * 100;
  }
  if (billed < 0) {
    return -((billed - cost) / billed) * 100;
  }
  return cost !== 0 ? -100 : 0;
};

export const getPeriodTotal = (period, globalDiscountAmount, additionalCharges) => {
  const subtotal = period.info.reduce(
    (acc, { bill, rate, discountPercentage }) => {
      const rateWithDiscount = discountPercentage
        ? handlePriceWithDiscount(rate[HOURLY], discountPercentage)
        : rate[HOURLY];

      const partialBilled = rate && bill ? rateWithDiscount * bill[HOURLY] : 0;

      return {
        totalBilled: acc.totalBilled + partialBilled
      };
    },
    {
      totalCost: 0,
      totalBilled: 0
    }
  );

  const subtotalWithDiscount = globalDiscountAmount
    ? handlePriceWithDiscount(subtotal.totalBilled, globalDiscountAmount)
    : subtotal.totalBilled;

  const total = subtotalWithDiscount + additionalCharges.fixedPrice + additionalCharges.additional;

  return total;
};

export const getResourcesPeriodsInfo = (resources, editingPeriodId) => {
  const periodInfosList = [];
  resources.forEach(({ periodsInfo }) =>
    periodsInfo.forEach(({ periodDetailId, allocation, bill, periodId }) => {
      if (periodId === editingPeriodId) {
        periodInfosList.push({
          periodDetailId,
          allocation,
          bill
        });
      }
    })
  );
  return periodInfosList;
};

export const composedClosedPeriodsInfo = resources => ({
  periodsInfo: resources.map(
    ({ id, allocationPercentage, billable, rate, allocationHours, bill }) => ({
      periodDetailId: id,
      allocationPercentage,
      billable,
      rate,
      allocation: allocationHours,
      bill
    })
  )
});

export const getLastPeriodEndDate = (openPeriods, closedPeriods) => {
  const openEndDates = openPeriods.map(period => period.endDate);
  const closedEndDates = closedPeriods.map(period => period.endDate);

  const dates = [...new Set([...openEndDates, ...closedEndDates])];

  if (dates.length) {
    return dates?.reduce((acc, current) => {
      if (isAfter(acc, current)) return acc;
      return current;
    });
  }

  return dayjs().toString();
};
