export const REVENUE_GRAPH_COLORS = {
  actualRevenue: {
    key: 'actualRevenue',
    fill: '#EDF1FC',
    stroke: '#476FF2'
  },
  underContract: {
    key: 'underContractDiff',
    fill: '#06fdb314',
    stroke: '#00ACB7'
  },
  awaitingStart: {
    key: 'awaitingStartDiff',
    fill: '#fd066e14',
    stroke: '#F267CB'
  },
  revenueGoal: {
    key: 'revenueGoal',
    fill: '#FFFFFF',
    stroke: '#6D6E73'
  }
};
