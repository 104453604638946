import styled from 'styled-components';

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: inline-block;
  font-weight: ${({ bold, theme }) => bold && theme.fontWeights.semiBold};
  color: ${({ isRed, theme }) => isRed && theme.colors.red};
`;

export const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
`;
