import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { useState } from 'react';

import { useDispatch, useNotifications } from 'hooks';
import {
  markNotificationAsSeen,
  updateNotificationMarkSeen
} from 'state/actions/notificationActions';

import HeaderNotification from './HeaderNotification';
import NotificationMain from './NotificationMain';
import { MenuContainer, StyledBadge } from './styles';

const NavbarNotification = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const markNotificationAsSeenRequest = useDispatch(markNotificationAsSeen);
  const updateNotificationMarkSeenRequest = useDispatch(updateNotificationMarkSeen);
  const { notification, isPending: isNotificationsPending } = useNotifications();

  const handleClick = event => {
    if (!notification?.notificationsSeen) {
      markNotificationAsSeenRequest();
      updateNotificationMarkSeenRequest();
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <StyledBadge
          $notificationsSeen={notification?.notificationsSeen}
          badgeContent=""
          color="error"
        >
          <Avatar sx={{ width: 32, height: 32, bgcolor: '#3E3E3E' }}>
            <NotificationsIcon sx={{ fontSize: '2rem' }} />
          </Avatar>
        </StyledBadge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            height: '74vh',
            mt: -1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '& .MuiMenu-list': {
              height: '95%',
              paddingBottom: '5rem'
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <HeaderNotification />
        <MenuContainer>
          <NotificationMain
            notifications={notification?.notifications}
            isLoading={isNotificationsPending}
          />
        </MenuContainer>
      </Menu>
    </>
  );
};

export default NavbarNotification;
