import { arrayOf, bool, func, instanceOf, oneOfType, string } from 'prop-types';
import { useState, useReducer, useEffect, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import DatePicker from 'components/common/DatePicker';
import Select from 'components/common/Select';
import {
  DASHBOARD_DATE_TYPES,
  dashboardDateTypesArray,
  LOOK_AHEAD_OPTIONS
} from 'constants/dashboardConstants';
import { DISPLAY_MONTH_DATE } from 'constants/dateFormats';
import { dateTypeShape } from 'constants/shapes';
import * as Dates from 'utils/date';

import { datePickerReducer } from './datePickerReducer';
import {
  DashboardTimelineContainer,
  TimelineSwitch,
  TimelineBack,
  TimelineNext,
  DateRangeWrapper,
  LeftChevronIcon,
  RightChevronIcon,
  StyledDateRange,
  RangeTypeWrapper,
  LookAheadWrapper,
  DatePickerWrapper
} from './styles';

const defaultRangeType = DASHBOARD_DATE_TYPES.month;
const defaultLookAheadOption = LOOK_AHEAD_OPTIONS[0];

const DashboardDateSelector = ({
  startDate,
  endDate,
  rangeTypeOptions = dashboardDateTypesArray,
  onChange,
  clearDateFilters,
  setClearDateFilters
}) => {
  const intl = useIntl();

  const [datePickerProps, dispatch] = useReducer(datePickerReducer, {
    showMonthYearPicker: true,
    dateFormat: DISPLAY_MONTH_DATE
  });
  const defaultLookAhead = useMemo(
    () => ({
      ...defaultLookAheadOption,
      label: intl.formatMessage({ id: defaultLookAheadOption.label })
    }),
    [intl]
  );

  const [rangeType, setRangeType] = useState(defaultRangeType);
  const [monthsLookAhead, setMonthsLookAhead] = useState(defaultLookAhead);

  const handleDateChange = useCallback(
    (value, newRangeType, newMonthsLookAhead) => {
      const range = dashboardDateTypesArray.includes(newRangeType) ? newRangeType : rangeType;
      const { startDate, endDate } = Dates.getFirstAndLastDayOfRange(
        value,
        range,
        newMonthsLookAhead?.value ?? monthsLookAhead?.value
      );
      onChange({
        startDate,
        endDate,
        groupingType: range.groupingType
      });
    },
    [monthsLookAhead, onChange, rangeType]
  );

  const handleTypeChange = useCallback(
    ({ value: newValue }) => {
      const newRangeType = dashboardDateTypesArray.find(({ value }) => value === newValue);
      setRangeType(newRangeType);
      if (newRangeType.value !== DASHBOARD_DATE_TYPES.custom.value) {
        dispatch({ type: newRangeType.value, startDate });
        handleDateChange(startDate, newRangeType);
      }
    },
    [startDate, handleDateChange]
  );

  const handleChangeLookAhead = useCallback(
    (newLookAheadValue, newRangeType) => {
      setMonthsLookAhead(newLookAheadValue);
      handleDateChange(startDate, newRangeType, newLookAheadValue);
    },
    [handleDateChange, startDate]
  );

  const handleArrowClick = (rangeType, startDate, value) => {
    const newStartDate = Dates.addTimeByRangeType(startDate, value, rangeType);
    handleDateChange(newStartDate, rangeType);
  };

  useEffect(() => {
    if (clearDateFilters) {
      handleTypeChange(defaultRangeType);
      handleChangeLookAhead(defaultLookAhead, defaultRangeType);
      setClearDateFilters(false);
    }
  }, [
    clearDateFilters,
    setClearDateFilters,
    handleTypeChange,
    handleChangeLookAhead,
    defaultLookAhead
  ]);

  const isRangeTypeCustom = rangeType.value === DASHBOARD_DATE_TYPES.custom.value;
  return (
    <DashboardTimelineContainer>
      <RangeTypeWrapper>
        <Select
          name="rangeType"
          label={intl.formatMessage({ id: 'dashboard.time.rangeType' })}
          options={rangeTypeOptions}
          height={3.4}
          value={rangeType}
          onChange={handleTypeChange}
          withoutHighlight
        />
      </RangeTypeWrapper>
      {isRangeTypeCustom ? (
        <DateRangeWrapper>
          <StyledDateRange
            selected={{ startDate, endDate }}
            names={{ startDate: 'startDate', endDate: 'endDate' }}
            labels={{
              startDate: intl.formatMessage({ id: 'common.cap.startDate' }),
              endDate: intl.formatMessage({ id: 'common.cap.endDate' })
            }}
            onChange={onChange}
            onSelectionEnd={onChange}
          />
        </DateRangeWrapper>
      ) : (
        <>
          <DatePickerWrapper rangeType={rangeType}>
            <DatePicker
              name="date-picker"
              startDate={startDate}
              endDate={endDate}
              label={intl.formatMessage({ id: 'dashboard.time.selectedLabel' })}
              onChange={handleDateChange}
              {...datePickerProps}
              selected={startDate}
              className="dashboard-datepicker"
              popperWidth="17rem"
            />
          </DatePickerWrapper>
          {rangeType.value === DASHBOARD_DATE_TYPES.month.value && (
            <LookAheadWrapper>
              <Select
                name="dashboard.time.lookAhead"
                label={intl.formatMessage({ id: 'dashboard.time.lookAhead' })}
                height={3.4}
                options={LOOK_AHEAD_OPTIONS}
                value={monthsLookAhead}
                onChange={handleChangeLookAhead}
                withIntlOptions
              />
            </LookAheadWrapper>
          )}
        </>
      )}
      {!isRangeTypeCustom && (
        <TimelineSwitch>
          <TimelineBack onClick={() => handleArrowClick(rangeType, startDate, -1)}>
            <LeftChevronIcon />
          </TimelineBack>
          <TimelineNext onClick={() => handleArrowClick(rangeType, startDate, 1)}>
            <RightChevronIcon />
          </TimelineNext>
        </TimelineSwitch>
      )}
    </DashboardTimelineContainer>
  );
};

DashboardDateSelector.propTypes = {
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  rangeTypeOptions: arrayOf(dateTypeShape),
  onChange: func,
  clearDateFilters: bool,
  setClearDateFilters: func
};

export default DashboardDateSelector;
