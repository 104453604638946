import UserNav from 'components/common/UserNav';
import NavbarNotification from 'components/NavbarNotification';
import { ROLES } from 'constants/permissions';
import { useRole, useSession } from 'hooks';

import { NavbarContainer, LeftControls, RightControls, StyledLogo } from './styles';

const Navbar = () => {
  const { authenticated } = useSession();
  const { role, temporalRoles } = useRole();

  const basicUser = role === ROLES.basic && temporalRoles.length === 0;

  return (
    <NavbarContainer>
      <LeftControls>
        <StyledLogo />
      </LeftControls>
      {authenticated && (
        <RightControls>
          {!basicUser ? <NavbarNotification /> : null}
          <UserNav />
        </RightControls>
      )}
    </NavbarContainer>
  );
};

export default Navbar;
