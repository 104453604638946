import { useTheme } from 'styled-components';

import { remToNumber } from 'utils/helpers';

export default () => {
  const { constantWidths } = useTheme();
  const widthsArray = Object.entries(constantWidths);

  const widths = widthsArray.reduce(
    (previous, [key, value]) => ({ ...previous, [key]: remToNumber(value) }),
    {}
  );

  return widths;
};
