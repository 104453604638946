import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import LoadingWrapper from 'components/common/LoadingWrapper';
import StatusIndicator from 'components/common/StatusIndicator';
import ProjectAccordion from 'components/Reports/ProjectAccordion';
import ScheduledVacations from 'components/Reports/ScheduledVacations';
import MiniTimelineAllocation from 'components/timeline/MiniTimelineAllocation';
import { ALLOCATION_TIMELINE_TYPES } from 'constants/constants';
import { useRequest } from 'hooks';
import {
  getResourceProfileProjects,
  getResourceProfileTimeOffs,
  getResourceMiniTimeline
} from 'state/actions/resourceProfileActions';
import {
  isAfter,
  isBefore,
  addMonths,
  getFirstAndLastDayOfMonth,
  getPeriodYearMonth
} from 'utils/date';

import {
  Container,
  Horizontal,
  Title,
  TimelineTitle,
  TimelineWrapper,
  ReferenceWrapper
} from './styles';

const currentDate = dayjs();
const { startDate: timelineStartDate } = getFirstAndLastDayOfMonth(addMonths(-2));
const { endDate: timelineEndDate } = getFirstAndLastDayOfMonth(addMonths(2));

const ProjectsTab = () => {
  const intl = useIntl();
  const { id } = useParams();

  const [{ response: timeOffsData, isPending: isLoadingTimeOffs }] = useRequest(
    getResourceProfileTimeOffs,
    {
      params: { id }
    }
  );
  const [{ response: projectsData, isPending: isLoadingProjects }] = useRequest(
    getResourceProfileProjects,
    {
      params: { id }
    }
  );
  const [{ response: timelineData, isPending: isLoadingTimeline }] = useRequest(
    getResourceMiniTimeline,
    {
      params: { id, startDate: timelineStartDate, endDate: timelineEndDate }
    }
  );

  const assignments = projectsData?.assignments;
  const currentAllocations = assignments
    ?.filter(({ endDate }) => isAfter(endDate, currentDate))
    ?.sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
  const pastProjects = assignments
    ?.filter(({ endDate }) => isBefore(endDate, currentDate))
    ?.sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
  const isLoading = isLoadingTimeOffs || isLoadingProjects || isLoadingTimeline;

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Container>
        <ScheduledVacations timeOffs={timeOffsData?.timeOffs} />
        <section>
          <Title>{intl.formatMessage({ id: 'common.allocationTimeline' })}</Title>
          <Horizontal />
          <TimelineWrapper>
            <TimelineTitle>{getPeriodYearMonth(timelineStartDate, timelineEndDate)}</TimelineTitle>
            <MiniTimelineAllocation
              startDate={timelineStartDate}
              endDate={timelineEndDate}
              addSeparators
              showTimePeriod
              width="calc(100% - 3rem)"
              timeOffs={timelineData?.timeOffs}
              assignments={timelineData?.assignments}
              capacity={timelineData?.availableWorkHours}
              specialEvents={timelineData?.specialEvents}
              hireDate={timelineData?.hireDate}
              exitDate={timelineData?.exitDate}
              holidays={timelineData?.holidays}
              bigBar
            />
            <ReferenceWrapper>
              {Object.values(ALLOCATION_TIMELINE_TYPES).map(({ label, color }) => (
                <StatusIndicator key={label} label={label} color={color} />
              ))}
            </ReferenceWrapper>
          </TimelineWrapper>
        </section>
        <section>
          <Title>{intl.formatMessage({ id: 'common.currentAllocation' })}</Title>
          <Horizontal />
          {currentAllocations?.map((assignment, index) => (
            <ProjectAccordion key={index} assignment={assignment} current />
          ))}
        </section>
        <section>
          <Title>{intl.formatMessage({ id: 'common.cap.pastProjects' })}</Title>
          <Horizontal />
          {pastProjects?.map((assignment, index) => (
            <ProjectAccordion key={index} assignment={assignment} />
          ))}
        </section>
      </Container>
    </LoadingWrapper>
  );
};

export default ProjectsTab;
