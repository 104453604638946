import styled from 'styled-components';

export const Title = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  padding-bottom: 1rem;
  width: 100%;

  h2 {
    ${({ theme }) => theme.new.typography.h4SemiBold};
    color: ${({ variant, theme }) =>
      variant === 'primary' ? theme.new.colors.N900 : theme.new.colors.white};
  }
`;

export const StatusIndicatorContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 2.2rem;
`;
