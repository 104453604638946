import client from 'assets/icons/client.svg';
import projectStatus from 'assets/icons/project-status.svg';
import projectTypeIcon from 'assets/icons/project-type.svg';
import { StatusCircle, StatusColumn } from 'components/ProjectsOverview/ProjectsTable/Cell/styles';
import { SERVICE_TYPES_OPTIONS } from 'constants/constants';
import { humanize } from 'utils/helpers';

export const getProjectData = ({ serviceType, status, clientId }, clients, intl) => [
  {
    title: 'common.cap.projectStatus',
    item: (
      <StatusColumn>
        <StatusCircle status={status} />
        {humanize(status)}
      </StatusColumn>
    ),
    avatar: projectStatus
  },
  {
    title: 'common.client',
    item: intl.formatMessage({
      id: clients.find(({ id }) => id === clientId)?.name || '-'
    }),
    avatar: client
  },
  {
    title: 'common.projectType',
    item: intl.formatMessage({
      id: SERVICE_TYPES_OPTIONS.find(({ key }) => key.includes(serviceType))?.label || '-'
    }),
    avatar: projectTypeIcon
  }
];
