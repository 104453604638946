import { commonPropsReactSelect } from 'constants/shapes';

import { Arrow, FilledArrow, StyledDropDownIcon, StyledDropdownIndicator } from './styles';

const DropdownIndicator = ({
  selectProps: { menuIsOpen, isUseFilledArrow, variant },
  ...props
}) => {
  const arrowProps = { $upDirection: menuIsOpen, variant };
  let ArrowComponent = Arrow;

  if (variant === 'new') {
    arrowProps.upDirection = menuIsOpen;
    ArrowComponent = StyledDropDownIcon;
  } else if (isUseFilledArrow) {
    ArrowComponent = FilledArrow;
  }

  return (
    <StyledDropdownIndicator {...props} variant={variant}>
      <ArrowComponent {...arrowProps} />
    </StyledDropdownIndicator>
  );
};

DropdownIndicator.propTypes = commonPropsReactSelect;

export default DropdownIndicator;
