import { bool, func, number } from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as CircleClock } from 'assets/icons/circleClock.svg';
import { ReactComponent as CircleDialogue } from 'assets/icons/circleDialogue.svg';
import { ReactComponent as CircleStar } from 'assets/icons/circleStar.svg';
import BoxItem from 'components/common/BoxItem';
import Button from 'components/common/Button';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import ItemDetail from 'components/Reports/ResourceProfile/ItemDetail';
import { BUTTON_VARIANTS, ENGLISH_OPTIONS } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import routesPaths from 'constants/routesPaths';
import { useRequest, useRole } from 'hooks';
import { getResourceTechnicalSkills } from 'state/actions/peopleActions';
import {
  getResourceProfile,
  getResourceProfileTimeOffs
} from 'state/actions/resourceProfileActions';
import hasPermission from 'utils/hasPermission';
import { routeWithProps } from 'utils/helpers';

import BasicInfoPanel from '../ResourceProfile/BasicInfoPanel';
import ScheduledVacations from '../ScheduledVacations';
import {
  Content,
  StyledNewModal,
  DetailsContent,
  Horizontal,
  BoxesContainer,
  SkillsContainer,
  Title,
  Header
} from './styles';

function ResourceProfileModal({ hide, open, personId }) {
  const intl = useIntl();
  const { permissions } = useRole();
  const {
    new: { colors }
  } = useTheme();

  const [{ isPending: isLoadingResource, response: personInfo }] = useRequest(getResourceProfile, {
    params: { id: personId }
  });

  const [{ response: technicalSkills, isPending: isLoadingExperiences }] = useRequest(
    getResourceTechnicalSkills,
    {
      params: { personId, highlighted: false }
    }
  );

  const [{ response: timeOffsData, isPending: isLoadingTimeOffs }] = useRequest(
    getResourceProfileTimeOffs,
    {
      params: { id: personId }
    }
  );

  const { mainSkills, englishLevel, weekHours } = personInfo || {};

  const isLoading = isLoadingResource || isLoadingExperiences || isLoadingTimeOffs;

  const englishLevelLabelId = useMemo(
    () => englishLevel && ENGLISH_OPTIONS.find(({ key }) => key === englishLevel)?.label,
    [englishLevel]
  );

  return (
    <StyledNewModal
      title={intl.formatMessage({ id: 'common.resourceProfile' })}
      isShowing={open}
      hide={hide}
      withoutOverflow
    >
      <Content isLoading={isLoading}>
        {isLoading ? (
          <CenteredLoading />
        ) : (
          <>
            <Header>
              <BasicInfoPanel data={personInfo} />
              {hasPermission(permissions, [SCOPES.seeProfileModal]) && (
                <Link
                  id={`resourcelink-${personId}`}
                  target="_blank"
                  to={routeWithProps(routesPaths.myReportsProfile, { id: personId })}
                >
                  <Button variant={BUTTON_VARIANTS.NEW_PRIMARY}>
                    {intl.formatMessage({ id: 'common.viewProfile' })}
                  </Button>
                </Link>
              )}
            </Header>
            <Horizontal color={colors.neutral300} margin="0.5rem 0" />
            <DetailsContent>
              <ItemDetail
                icon={<CircleStar />}
                title={intl.formatMessage({ id: 'common.cap.mainSkill' })}
              >
                {mainSkills?.length
                  ? mainSkills.map(({ name, time }) => (
                      <div key={name}>
                        {name} <span>{`| ${time}`}</span>
                      </div>
                    ))
                  : '--'}
              </ItemDetail>
              <ItemDetail
                icon={<CircleDialogue />}
                title={intl.formatMessage({ id: 'common.cap.englishLevel' })}
              >
                {englishLevelLabelId ? intl.formatMessage({ id: englishLevelLabelId }) : '--'}
              </ItemDetail>
              <ItemDetail
                icon={<CircleClock />}
                title={intl.formatMessage({ id: 'reports.hoursWeek' })}
              >
                {weekHours
                  ? `${weekHours} ${intl.formatMessage({ id: 'common.lower.hours' })}`
                  : '--'}
              </ItemDetail>
            </DetailsContent>
            <SkillsContainer>
              <Title>{intl.formatMessage({ id: 'common.cap.technicalSkills' })}</Title>
              <BoxesContainer>
                {technicalSkills?.experiences?.map(({ skillName, time }) => (
                  <BoxItem key={skillName} title={skillName} info={time} />
                ))}
              </BoxesContainer>
            </SkillsContainer>
            <ScheduledVacations timeOffs={timeOffsData?.timeOffs} />
          </>
        )}
      </Content>
    </StyledNewModal>
  );
}

ResourceProfileModal.propTypes = {
  hide: func,
  open: bool,
  personId: number
};

export default ResourceProfileModal;
