import { bool, func, node, string } from 'prop-types';

import { BUTTON_VARIANTS } from 'constants/constants';

import { Container, StyledRow, StyledModal, StyledAlertMessage, StyledButton } from './styles';

const ConfirmationModal = ({
  title,
  message,
  isShowing,
  hide,
  onConfirm,
  onCancel,
  error,
  warning,
  isLoading,
  cancelButtonIntlId = 'dashboard.cancel',
  confirmButtonIntlId = 'dashboard.confirm',
  children,
  textAlign = 'center'
}) => (
  <StyledModal
    title={title}
    subTitle={message}
    isShowing={isShowing}
    hide={hide}
    isOverlapped
    isAddBorder={false}
  >
    <Container textAlign={textAlign}>
      {children}
      {error && <StyledAlertMessage>{error}</StyledAlertMessage>}
      {warning && <StyledAlertMessage severity="warn">{warning}</StyledAlertMessage>}
      <StyledRow error={error}>
        <StyledButton
          variant={BUTTON_VARIANTS.NEW_SECONDARY}
          isLoading={isLoading}
          textIntlId={cancelButtonIntlId}
          onClick={onCancel}
          outlined
          isCancel
          fullWidth
        />
        <StyledButton
          variant={BUTTON_VARIANTS.NEW_PRIMARY}
          isLoading={isLoading}
          textIntlId={confirmButtonIntlId}
          onClick={onConfirm}
          fullWidth
        />
      </StyledRow>
    </Container>
  </StyledModal>
);

ConfirmationModal.propTypes = {
  title: string.isRequired,
  message: string,
  isShowing: bool.isRequired,
  hide: func.isRequired,
  isLoading: bool,
  error: string,
  warning: string,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  cancelButtonIntlId: string,
  confirmButtonIntlId: string,
  children: node,
  textAlign: string
};

export default ConfirmationModal;
