import { createReducer } from '@reduxjs/toolkit';

import {
  deleteOpenPeriod,
  getClosedPeriodDetail,
  getFinancialProjectDetails,
  getFinancialResourceSetup,
  getProjectClosedPeriods,
  getProjectOpenedPeriods,
  getProjectOpenedResources,
  updateClosedPeriodInfo,
  updateLastPeriodEndDate
} from 'state/actions/financialActions';
import { sortByNameAndStartDate } from 'utils/financialUtilities';
import { getLastPeriodEndDate } from 'utils/periodUtilities';

const initialState = {
  projectDetails: {},
  resourceSetup: [],
  openPeriods: {
    resources: [],
    periods: []
  },
  closedPeriods: [],
  lastPeriodEndDate: null
};

const financialReducer = createReducer(initialState, builder =>
  builder
    .addCase(getFinancialProjectDetails.fulfilled, (state, { payload }) => {
      state.projectDetails = payload;
      state.lastPeriodEndDate = payload.lastPeriodEndDate;
    })
    .addCase(getFinancialResourceSetup.fulfilled, (state, { payload: { periodSetup } }) => {
      state.resourceSetup = sortByNameAndStartDate(periodSetup, 'resourceName');
    })
    .addCase(getProjectOpenedResources.fulfilled, (state, { payload }) => {
      state.openPeriods.resources = sortByNameAndStartDate(payload, 'name');
    })
    .addCase(getProjectOpenedPeriods.fulfilled, (state, { payload }) => {
      state.openPeriods.periods = payload;
    })
    .addCase(deleteOpenPeriod.fulfilled, (state, { payload: { periodId } }) => {
      const periodPos = state.openPeriods.periods.findIndex(period => period.id === periodId);
      state.openPeriods.periods.splice(periodPos, 1);
      if (!state.openPeriods.periods.length) {
        state.openPeriods.resources = [];
      }
      state.lastPeriodEndDate = getLastPeriodEndDate(
        state.openPeriods.periods,
        state.closedPeriods
      );
    })
    .addCase(getProjectClosedPeriods.fulfilled, (state, { payload }) => {
      state.closedPeriods = payload;
    })
    .addCase(getClosedPeriodDetail.fulfilled, (state, { payload: { id, periodDetails } }) => {
      const periodPos = state.closedPeriods.findIndex(period => period.id === id);
      state.closedPeriods[periodPos].periodDetails = periodDetails;
    })
    .addCase(updateClosedPeriodInfo.fulfilled, (state, { payload }) => {
      const periodPos = state.closedPeriods.findIndex(period => period.id === payload.id);
      state.closedPeriods[periodPos] = payload;
    })
    .addCase(updateLastPeriodEndDate, state => {
      state.lastPeriodEndDate = getLastPeriodEndDate(
        state.openPeriods.periods,
        state.closedPeriods
      );
    })
);

export default financialReducer;
