import { func } from 'prop-types';
import { useEffect, useState } from 'react';

import Button from 'components/common/Button';
import FilterDropdown from 'components/common/FilterDropdown';
import { FULFILLED } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS } from 'constants/constants';
import { DASHBOARD_DATE_TYPES, GROUPING_TYPES } from 'constants/dashboardConstants';
import { useRoles, useSkills, useTeams } from 'hooks';
import { filtersToParamsReports } from 'utils/dashboardUtilities';
import * as Dates from 'utils/date';
import { initializeOptions, initializeTeams } from 'utils/helpers';

import DateSelector from '../../DateSelector';
import { FilterSkill, Selectors } from './styles';

const month = Dates.getFirstAndLastDayOfCurrentMonth(Dates.dayJSToDate);
const rangeTypeOptions = [
  DASHBOARD_DATE_TYPES.month,
  DASHBOARD_DATE_TYPES.quarter,
  DASHBOARD_DATE_TYPES.year
];

const initialFilters = {
  startDate: month.startDate,
  endDate: month.endDate,
  skills: [],
  departments: [],
  roles: [],
  groupingType: GROUPING_TYPES.weekly
};

const DashboardFilters = ({ onClear, onRequest }) => {
  const [filters, setFilters] = useState(initialFilters);
  const [clearDateFilters, setClearDateFilters] = useState(false);

  const { skills, skillsStatus } = useSkills();
  const { teams, teamsStatus } = useTeams();
  const { roles, rolesStatus } = useRoles();

  const handleFilter = ({ options, key }) => {
    setFilters(prevState => ({ ...prevState, [key]: options }));
  };

  const handleDates = ({ startDate, endDate, groupingType }) => {
    setFilters(prevState => ({
      ...prevState,
      startDate,
      endDate,
      groupingType
    }));
  };

  const handleApplyFilters = () => {
    onRequest(filtersToParamsReports(filters));
  };

  const handleClearFilters = () => {
    setFilters({
      ...initialFilters,
      skills: initializeOptions(skills.map(({ name, id }) => ({ key: name, label: name, id }))),
      departments: initializeTeams({
        teams,
        withValues: false
      }),
      roles: initializeOptions(roles.map(({ name, id }) => ({ key: name, label: name, id })))
    });
    setClearDateFilters(true);
    onClear();
  };

  useEffect(() => {
    if ([skillsStatus, teamsStatus, rolesStatus].every(status => status === FULFILLED)) {
      setFilters(prevState => ({
        ...prevState,
        skills: initializeOptions(skills.map(({ name, id }) => ({ key: name, label: name, id }))),
        departments: initializeTeams({
          teams,
          withValues: false
        }),
        roles: initializeOptions(roles.map(({ name, id }) => ({ key: name, label: name, id })))
      }));
    }
  }, [skillsStatus, teamsStatus, rolesStatus, skills, teams, roles, setFilters]);

  return (
    <>
      <DateSelector
        startDate={filters.startDate}
        endDate={filters.endDate}
        onChange={handleDates}
        rangeTypeOptions={rangeTypeOptions}
        clearDateFilters={clearDateFilters}
        setClearDateFilters={setClearDateFilters}
      />
      <Selectors>
        <FilterDropdown
          labelId="common.role"
          options={filters.roles}
          onFilter={handleFilter}
          queryKey="roles"
          withIntlOptions={false}
        />
        <FilterDropdown
          labelId="common.department"
          options={filters.departments}
          onFilter={handleFilter}
          queryKey="departments"
          withIntlOptions={false}
        />
        <FilterSkill
          labelId="common.skills"
          options={filters.skills}
          onFilter={handleFilter}
          queryKey="skills"
          withIntlOptions={false}
        />
        <Button
          variant={BUTTON_VARIANTS.APPLY}
          onClick={handleApplyFilters}
          textIntlId="filter.dashboard.apply"
        />
        <Button
          variant={BUTTON_VARIANTS.CLEAR}
          onClick={handleClearFilters}
          textIntlId="filter.dashboard.clear"
        />
      </Selectors>
    </>
  );
};

DashboardFilters.propTypes = {
  onRequest: func,
  onClear: func
};

export default DashboardFilters;
