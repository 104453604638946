import { func, node, oneOfType, string } from 'prop-types';
import React from 'react';

import { BUTTON_VARIANTS } from 'constants/constants';

import { ArrowLeft, ArrowRight, ButtonsGroup, StyledArrowButton, StyledButton } from './styles';

const QuickDateSelector = ({ onChange, children }) => (
  <ButtonsGroup>
    <StyledArrowButton
      position="left"
      icon={<ArrowLeft />}
      variant={BUTTON_VARIANTS.NEW_SECONDARY}
      onClick={() => onChange(null, false)}
    />
    <StyledButton variant={BUTTON_VARIANTS.NEW_SECONDARY} onClick={() => onChange(new Date())}>
      {children}
    </StyledButton>
    <StyledArrowButton
      position="right"
      icon={<ArrowRight />}
      variant={BUTTON_VARIANTS.NEW_SECONDARY}
      onClick={() => onChange()}
    />
  </ButtonsGroup>
);

QuickDateSelector.propTypes = {
  onChange: func,
  children: oneOfType([string, node])
};

export default QuickDateSelector;
