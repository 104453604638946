import { number, bool, arrayOf, shape, string } from 'prop-types';

import { Container, Filler } from './styles';

const ProgressBar = ({ content, height, big = false }) => (
  <Container big={big} height={height}>
    {content.map(({ percentage, color, roundedLeft, roundedRight }, index) => (
      <Filler
        key={index}
        percentage={percentage}
        roundedLeft={roundedLeft}
        roundedRight={roundedRight}
        color={color}
      />
    ))}
  </Container>
);

ProgressBar.propTypes = {
  content: arrayOf(
    shape({
      percentage: number.isRequired,
      color: string.isRequired,
      roundedLeft: bool,
      roundedRight: bool
    })
  ).isRequired,
  big: bool,
  height: number
};

export default ProgressBar;
