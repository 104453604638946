import { func } from 'prop-types';
import { useIntl } from 'react-intl';

import Checkbox from 'components/common/Checkbox';
import { projectsOverviewFilters } from 'constants/shapes';

import { Container, Section, StyledFilterDropdown, StyledSearch } from './styles';

const Filters = ({ filters, setFilters }) => {
  const intl = useIntl();
  const { search, projectStatus, assignmentStatus, serviceType, hideFinished } = filters;

  const handleOptions = ({ options, key }) =>
    setFilters(prevState => ({ ...prevState, [key]: options }));

  return (
    <Container>
      <Section>
        <StyledFilterDropdown
          id="projectsStatusFilter"
          labelId="common.cap.projectStatus"
          options={projectStatus}
          onFilter={handleOptions}
          queryKey="projectStatus"
          searchIntlId="common.search"
          isClearable={false}
        />
        <StyledFilterDropdown
          id="projectsStatusFilter"
          labelId="common.cap.assignmentStatus"
          options={assignmentStatus}
          onFilter={handleOptions}
          queryKey="assignmentStatus"
          isClearable={false}
        />
        <StyledFilterDropdown
          id="projectsServiceTypeFilter"
          labelId="common.cap.projectType"
          options={serviceType}
          onFilter={handleOptions}
          queryKey="serviceType"
          isClearable={false}
        />
        <Checkbox
          label={intl.formatMessage({ id: 'projectOverview.filter.hideFinished' })}
          value={hideFinished}
          onChange={value => handleOptions({ options: value, key: 'hideFinished' })}
          variant="new"
        />
      </Section>
      <StyledSearch
        placeholderId="projectOverview.filters.search"
        value={search}
        onChange={value => setFilters(prevState => ({ ...prevState, search: value }))}
        variant="new"
      />
    </Container>
  );
};

Filters.propTypes = {
  setFilters: func,
  filters: projectsOverviewFilters
};

export default Filters;
