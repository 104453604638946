import { useIntl } from 'react-intl';

import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';

import { Container, Title, SubTitle } from './styles';

const BasicUser = () => {
  const intl = useIntl();

  return (
    <Container>
      <LockIcon />
      <Title>{intl.formatMessage({ id: 'basic.permissions.title' })}</Title>
      <SubTitle>{intl.formatMessage({ id: 'basic.permissions.subTitle' })}</SubTitle>
      <SubTitle>{intl.formatMessage({ id: 'basic.permissions.thanks' })}</SubTitle>
    </Container>
  );
};

export default BasicUser;
