import { bool, string } from 'prop-types';

import { ReactComponent as DeleteSvg } from 'components/icons/delete.svg';

import { StyledButton } from './styles';

const DeleteButton = ({
  textIntlId,
  disabled = false,
  isLoading = false,
  fullWidth = false,
  variant = 'old',
  ...props
}) => (
  <StyledButton
    icon={<DeleteSvg />}
    textIntlId={textIntlId}
    disabled={disabled || isLoading}
    fullWidth={fullWidth}
    isLoading={isLoading}
    $variant={variant}
    {...props}
  />
);

DeleteButton.propTypes = {
  isLoading: bool,
  disabled: bool,
  textIntlId: string,
  fullWidth: bool,
  variant: string
};

export default DeleteButton;
