import { isArray } from 'lodash';

import MuiTooltip from 'components/common/MuiTooltip';
import {
  ASSIGNMENTS_STATUS,
  PROJECT_STATUS_TYPES,
  SETTINGS_FILTERS,
  SORT_DIR
} from 'constants/constants';
import { capitalizeFirstLetter } from 'utils/searchPeopleUtilities';

const { open, pending, confirmed } = ASSIGNMENTS_STATUS;
const assignmentStatusOrder = [open, pending];

export const assignmentsGreaterFunc = (a, b) => {
  let isLower = false;
  return assignmentStatusOrder.some(status => {
    if (a[status] > b[status] && !isLower) {
      return true;
    }
    if (a[status] < b[status]) {
      isLower = true;
    }
  });
};

export const assignmentsLowerFunc = (a, b) => {
  let isGreater = false;
  return assignmentStatusOrder.some(status => {
    if (a[status] < b[status] && !isGreater) {
      return true;
    }
    if (a[status] > b[status]) {
      isGreater = true;
    }
  });
};

export const convertProjectsDataToTableFormat = projects =>
  projects.map(
    ({
      overassignedAssignments,
      openAssignments,
      pendingAssignments,
      confirmedAssignments,
      serviceType,
      marginPercentage,
      projectType,
      ...rest
    }) => ({
      ...rest,
      assignments: {
        pending: pendingAssignments,
        open: openAssignments,
        confirmed: confirmedAssignments,
        overAssigned: overassignedAssignments
      },
      projectType: serviceType,
      billableType: projectType,
      margin: marginPercentage
    })
  );

export const convertAssignmentsDataToTableFormat = assignments =>
  assignments?.map(
    ({
      firstName,
      lastName,
      assignmentSeniority,
      mainSkillsAssignment,
      status,
      allocationPercentage,
      personRole,
      assignmentRole,
      avatarUrl,
      ...rest
    }) => ({
      ...rest,
      assignment: {
        fullName:
          firstName || lastName ? `${firstName ? `${firstName} ` : ''}${lastName || ''}` : 'N/A',
        role: assignmentRole || personRole,
        avatarUrl
      },
      seniority: assignmentSeniority,
      mainSkills: mainSkillsAssignment,
      status: {
        label: status,
        order: { [open]: 3, [pending]: 2, [confirmed]: 1 }[status]
      },
      allocation: allocationPercentage
    })
  );
export const sortAssignmentsByDefault = assignments => {
  const statusOrder = { open: 3, pending: 2, confirmed: 1 };

  return assignments?.sort((a, b) => {
    const statusComparison =
      statusOrder[b.status?.label?.toLowerCase()] - statusOrder[a.status?.label?.toLowerCase()];
    if (statusComparison !== 0) {
      return statusComparison;
    }

    const startDateComparison = new Date(a.startDate) - new Date(b.startDate);
    if (startDateComparison !== 0) {
      return startDateComparison;
    }

    return new Date(a.endDate) - new Date(b.endDate);
  });
};

export const getColorByProjectStatus = (theme, status) =>
  ({
    [PROJECT_STATUS_TYPES.ACTIVE]: theme.new.colors.FI500,
    [PROJECT_STATUS_TYPES.ON_HOLD]: theme.new.colors.N300,
    [PROJECT_STATUS_TYPES.PLANNED]: theme.new.colors.S500,
    [PROJECT_STATUS_TYPES.WARRANTY]: theme.new.colors.FW500,
    [PROJECT_STATUS_TYPES.FINISHED]: theme.new.colors.N500,
    [PROJECT_STATUS_TYPES.MAINTENANCE]: theme.new.colors.T500,
    [PROJECT_STATUS_TYPES.CONFIRMED]: theme.new.colors.FI500
  }[status] || theme.new.colors.black);

export const getColorByAssignmentStatus = (theme, status) =>
  ({
    [ASSIGNMENTS_STATUS.open]: { color: theme.new.colors.BO500, fontColor: theme.new.colors.white },
    [ASSIGNMENTS_STATUS.pending]: {
      color: theme.new.colors.BP500,
      fontColor: theme.new.colors.black
    },
    [ASSIGNMENTS_STATUS.confirmed]: {
      color: theme.new.colors.BC500,
      fontColor: theme.new.colors.white
    }
  }[status] || { color: theme.new.colors.white, fontColor: theme.new.colors.black });

const getSortValues = (key, a, b) => {
  if (key === 'role') {
    return [a.assignment.role, b.assignment.role];
  }
  return [a[key], b[key]];
};

const getRoleSortValues = (key, a, b) => {
  if (key === 'temporalGroups') {
    return [a[0]?.group, b[0]?.group];
  }
  if (key === 'fullName') {
    return [a.person?.fullName, b.person?.fullName];
  }
  return [a[key], b[key]];
};

const getGroupSortValues = (key, a, b) => {
  if (key === 'roleName') {
    return [a.role?.name, b.role?.name];
  }

  return [a[key], b[key]];
};

const getSortingType = (key, a, b, type) =>
  type
    ? {
        [SETTINGS_FILTERS.roles]: getRoleSortValues(key, a, b),
        [SETTINGS_FILTERS.groupRoles]: getGroupSortValues(key, a, b)
      }[type]
    : getSortValues(key, a, b);

export const sortTable = ({
  rows,
  sortConfig,
  columns,
  tieBreaker = (a, b) => a?.name?.localeCompare(b.name),
  sortType = undefined
}) => {
  const { key, direction } = sortConfig;
  const { greaterFunc, lowerFunc } = columns.find(({ key: columnKey }) => columnKey === key) || {};
  const sortedRows = [...rows]?.sort((a, b) => {
    const values = getSortingType(key, a, b, sortType);
    const [firstValue, secondValue] = values.map(value =>
      typeof value === 'string' ? value.toLowerCase() : value
    );

    if (greaterFunc && lowerFunc) {
      if (lowerFunc(firstValue, secondValue)) {
        return direction === SORT_DIR.asc ? -1 : 1;
      }
      if (greaterFunc(firstValue, secondValue)) {
        return direction === SORT_DIR.asc ? 1 : -1;
      }
    }
    return tieBreaker(a, b);
  });
  return sortedRows;
};

export const showMultipleData = value =>
  isArray(value) && value?.length ? (
    <>
      {isArray(value) && value.length >= 2 && (
        <MuiTooltip>{isArray(value) && capitalizeFirstLetter(value?.join(', '))}</MuiTooltip>
      )}
      {isArray(value) && capitalizeFirstLetter(value.slice(0, 2).join(', '))}
    </>
  ) : (
    '-'
  );
