import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { personToAssignmentOption } from 'utils/assignmentFormsUtilities';

export default () => {
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [salesPeopleOptions, setSalesPeopleOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [deliveryOwnerOptions, setDeliveryOwnerOptions] = useState([]);

  const {
    people,
    filteredPeople,
    expandedPeople,
    minimalSalesPeople,
    minimalManagers,
    minimalDeliveryOwners,
    resourcesTimeline
  } = useSelector(
    ({
      people: {
        people,
        resourcesTimeline,
        minimalSalesPeople,
        minimalManagers,
        minimalDeliveryOwners
      }
    }) => ({
      people,
      filteredPeople: resourcesTimeline.filteredPeople,
      expandedPeople: resourcesTimeline.expandedPeople,
      minimalSalesPeople,
      minimalManagers,
      minimalDeliveryOwners,
      resourcesTimelineFilters: resourcesTimeline
    })
  );

  useEffect(() => {
    setPeopleOptions(people?.map(personToAssignmentOption));
  }, [people]);

  useEffect(() => {
    minimalSalesPeople &&
      setSalesPeopleOptions(
        minimalSalesPeople?.map(({ id, fullName }) =>
          personToAssignmentOption({ id, name: fullName })
        )
      );
  }, [minimalSalesPeople]);

  useEffect(() => {
    minimalManagers &&
      setManagerOptions(
        minimalManagers?.map(({ id, fullName }) => personToAssignmentOption({ id, name: fullName }))
      );
  }, [minimalManagers]);

  useEffect(() => {
    minimalDeliveryOwners &&
      setDeliveryOwnerOptions(
        minimalDeliveryOwners?.map(({ id, fullName }) =>
          personToAssignmentOption({ id, name: fullName })
        )
      );
  }, [minimalDeliveryOwners]);

  return {
    people,
    filteredPeople,
    expandedPeople,
    peopleOptions,
    minimalSalesPeople,
    salesPeopleOptions,
    minimalManagers,
    managerOptions,
    minimalDeliveryOwners,
    deliveryOwnerOptions,
    resourcesTimeline
  };
};
