import isEmpty from 'lodash/isEmpty';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { getLocations } from 'state/actions/uiActions';
import { locationToOption } from 'utils/assignmentFormsUtilities';

export default () => {
  const [locationsOptions, setLocationOptions] = useState([]);
  const getLocationsRequest = useDispatch(getLocations);
  const { status: locationsStatus, error: locationsError } = useStatus(getLocations);
  const locations = useSelector(({ ui }) => ui.options.locations);

  useEffect(() => {
    if (isEmpty(locations)) {
      getLocationsRequest();
    } else {
      setLocationOptions(locations.map(locationToOption));
    }
  }, [locations, getLocationsRequest]);

  return {
    locations,
    getLocationsRequest,
    locationsStatus,
    locationsError,
    locationsOptions
  };
};
