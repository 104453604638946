import { func, instanceOf, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { DISPLAY_MONTH_DAY_YEAR_DATEPICKER } from 'constants/dateFormats';
import { addDays } from 'utils/date';

import QuickDateSelector from './QuickDateSelector';
import { Container, StyledDatePicker } from './styles';

const TimeNavigation = ({ date, setDate, className, onDateChange }) => {
  const intl = useIntl();
  const handleDateChange = dateValue => {
    setDate(dateValue);
    onDateChange?.(dateValue);
  };

  const handleQuickSelectorChange = (value, forward = true) => {
    const newDate = value || addDays(date, forward ? 7 : -7);

    handleDateChange(newDate);
  };

  return (
    <Container className={className}>
      <QuickDateSelector onChange={handleQuickSelectorChange}>
        {intl.formatMessage({ id: 'common.today' })}
      </QuickDateSelector>
      <StyledDatePicker
        name="date-picker"
        onChange={handleDateChange}
        selected={date}
        startDate={date}
        endDate={date}
        popperWidth="10rem"
        variant="new"
        showPopperArrow={false}
        showTodayButton={false}
        dateFormat={DISPLAY_MONTH_DAY_YEAR_DATEPICKER}
      />
    </Container>
  );
};

TimeNavigation.propTypes = {
  date: oneOfType([string, instanceOf(Date)]).isRequired,
  setDate: func.isRequired,
  className: string,
  onDateChange: func
};

export default TimeNavigation;
