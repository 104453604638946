import { string } from 'prop-types';

import { ItemTitleContainer, SubTitle, Title } from './styles';

const ItemTitle = ({ title, subTitle }) => (
  <ItemTitleContainer>
    <Title>{title}</Title>
    <SubTitle>{subTitle}</SubTitle>
  </ItemTitleContainer>
);

ItemTitle.propTypes = {
  title: string.isRequired,
  subTitle: string.isRequired
};

export default ItemTitle;
