import { useState } from 'react';
import { useIntl } from 'react-intl';

import LoadingWrapper from 'components/common/LoadingWrapper';
import { DISPLAY_YEAR } from 'constants/dateFormats';
import { useRequest } from 'hooks';
import {
  getDashboardBenchCorner,
  getDashboardBenchOverviews
} from 'state/actions/dashboardActions';
import { dateToString } from 'utils/date';

import BenchChart from './BenchChart';
import Filters from './Filters';
import { Container, CurrentDayData, Info, Title, TopContainer, VerticalLine } from './styles';

const OverviewBlock = () => {
  const intl = useIntl();

  const [filters, setFilters] = useState({
    skillId: null,
    departmentId: null,
    year: dateToString(new Date(), DISPLAY_YEAR),
    group: 'month'
  });

  const [{ isPending: isLoadingGraphData, response: overviewData }, getDashboardRequest] =
    useRequest(getDashboardBenchOverviews, {
      params: filters
    });

  const [{ isPending: isLoadingTopCorner, response: topCornerData }] =
    useRequest(getDashboardBenchCorner);

  return (
    <Container>
      <TopContainer>
        <LoadingWrapper isLoading={isLoadingTopCorner}>
          <Title>
            <h3>{intl.formatMessage({ id: 'common.overview' })}</h3>
            <VerticalLine />
            <h4>{intl.formatMessage({ id: 'common.cap.allDepartments' })}</h4>
          </Title>
        </LoadingWrapper>
        <Filters
          filters={filters}
          setFilters={setFilters}
          getDashboardRequest={getDashboardRequest}
        />
      </TopContainer>
      <LoadingWrapper isLoading={isLoadingGraphData}>
        <Info>
          <span>{intl.formatMessage({ id: 'common.currentDay' })}</span>
          <CurrentDayData>
            {topCornerData?.corner?.totalBenchPercentage
              ? `${Math.round(topCornerData.corner.totalBenchPercentage)}%`
              : 0}
            <span>{intl.formatMessage({ id: 'common.totalBench' })}</span>
            {topCornerData?.corner?.totalBenchFte && (
              <span>
                ({Math.round(topCornerData.corner.totalBenchFte)}{' '}
                {intl.formatMessage({ id: 'common.FTE' })})
              </span>
            )}
          </CurrentDayData>
        </Info>
      </LoadingWrapper>
      <LoadingWrapper isLoading={isLoadingGraphData}>
        <BenchChart data={overviewData.benchOverview} />
      </LoadingWrapper>
    </Container>
  );
};

export default OverviewBlock;
