import { cloneDeep } from 'lodash';
import { func, string, arrayOf, bool, node } from 'prop-types';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import DropdownCheckbox from 'components/common/DropdownCheckbox';
import { optionShape } from 'constants/shapes';
import { useOnClickOutside } from 'hooks';
import { checkIfIsFiltered } from 'utils/helpers';

import {
  FilterDropdownContainer,
  FilterDropdownButton,
  Arrow,
  FilterDropdownOptions
} from './styles';

const FilterDropdown = ({
  children,
  id,
  labelId,
  onFilter,
  queryKey,
  searchIntlId,
  className = '',
  isClearable = true,
  multi = true,
  narrow = false,
  options: initialOptions = [],
  withIntlOptions = true,
  alignRight = false
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(cloneDeep(initialOptions));
  const [isFiltered, setIsFiltered] = useState(checkIfIsFiltered(options));
  const theme = useTheme();

  const outsideRef = useOnClickOutside(() => setIsOpen(false));

  useEffect(() => {
    setIsFiltered(checkIfIsFiltered(options));
  }, [options]);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions, setOptions]);

  const toggle = () => setIsOpen(!isOpen);

  const onApply = newOptions => {
    onFilter({ options: newOptions, key: queryKey });
  };

  return (
    <FilterDropdownContainer
      isFiltered={isFiltered}
      isOpen={isOpen}
      ref={outsideRef}
      narrow={narrow}
      disabled={!options?.length}
      className={className}
      id={id}
    >
      <FilterDropdownButton onClick={toggle} narrow={narrow} disabled={!options?.length}>
        <span>{intl.formatMessage({ id: labelId })}</span>
        <Arrow upDirection={isOpen} width={15} height={15} stroke={theme.new.colors.N600} />
      </FilterDropdownButton>
      {isOpen && (
        <FilterDropdownOptions alignRight={alignRight}>
          <DropdownCheckbox
            options={options}
            setOptions={setOptions}
            onApply={onApply}
            withIntlOptions={withIntlOptions}
            checkboxSize="2rem"
            searchIntlId={searchIntlId}
            isClearable={isClearable}
            multi={multi}
          >
            {children}
          </DropdownCheckbox>
        </FilterDropdownOptions>
      )}
    </FilterDropdownContainer>
  );
};

FilterDropdown.propTypes = {
  children: node,
  className: string,
  id: string,
  isClearable: bool,
  labelId: string.isRequired,
  multi: bool,
  narrow: bool,
  onFilter: func.isRequired,
  options: arrayOf(optionShape),
  queryKey: string,
  searchIntlId: string,
  withIntlOptions: bool,
  alignRight: bool
};

export default FilterDropdown;
