import styled from 'styled-components';

export const EmptyBar = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  height: 1.6rem;
  opacity: 50%;
  width: 100%;
`;

export const DurationDisplay = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RequirementContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const RequirementInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 21rem;
  overflow-y: ${({ overflowY }) => overflowY || 'auto'};
  overflow-x: ${({ overflowX }) => overflowX || 'hidden'};
`;
