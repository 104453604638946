import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 10.8rem;
  padding: 1.6rem 2.4rem;
  align-items: center;
  border-radius: 0.6rem;
  gap: 1.6rem;
  background-color: ${({ theme }) => theme.new.colors.white};
  flex-grow: 2;

  box-shadow: 0px 2px 6px 0px #00000014;
`;

export const References = styled.div`
  display: grid;
  grid-template: repeat(3, auto) / repeat(2, auto);
  column-gap: 1.6rem;
`;

export const ProjectStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const Status = styled.div`
  display: flex;
  ${({ theme }) => theme.new.typography.p6};
  color: ${({ theme }) => theme.new.colors.N900};
`;

export const Circle = styled.div`
  display: flex;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: ${({ theme, color }) => color || theme.new.colors.black};
`;

export const Count = styled.div`
  display: flex;
  ${({ theme }) => theme.new.typography.p6Bold};
  color: ${({ theme }) => theme.new.colors.N900};
`;

export const TotalColumn = styled.div`
  display: flex;
  width: 5.6rem;
  flex-direction: column;
`;

export const Label = styled.span`
  display: flex;
  ${({ theme }) => theme.new.typography.p5SemiBold};
  color: ${({ theme }) => theme.new.colors.N700};
`;

export const Total = styled.span`
  display: flex;
  ${({ theme }) => theme.new.typography.h2};
  color: ${({ theme }) => theme.new.colors.N900};
`;

export const GraphContainer = styled.div`
  width: 10rem;
  height: 10rem;
`;
