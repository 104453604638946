import { number, arrayOf, string } from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, XAxis, YAxis } from 'recharts';
import { useTheme } from 'styled-components';

import EmptyState from 'components/common/EmptyState';
import { insightRevenueGraphShape } from 'constants/shapes';

import BarLabel from './BarLabel';
import LabelFormatter from './LabelFormatter';
import { Container, StyledResponsiveContainer } from './styles';
import XAxisTick from './XAxisTick';
import YAxisTick from './YAxisTick';

const RevenueGraph = ({ data, limitX, step = 50000, name }) => {
  const theme = useTheme();
  const intl = useIntl();

  const ticks = useMemo(() => {
    const ticks = [];
    for (let i = 0; i <= limitX; i += step) {
      ticks.push(i);
    }
    ticks.push(limitX);
    return ticks;
  }, [limitX, step]);

  if (!data) return <EmptyState />;

  return (
    <Container maxHeight={window.innerHeight - 200}>
      <StyledResponsiveContainer width="99%" height={data.length * (data.length < 2 ? 90 : 75)}>
        <BarChart
          key={Math.random()}
          data={data}
          layout="vertical"
          barSize={16}
          margin={{
            left: 0,
            right: 4
          }}
        >
          <CartesianGrid
            horizontal={false}
            stroke={theme.new.colors.N200}
            strokeDasharray="5 5"
            strokeOpacity={1}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tick={<YAxisTick />}
            dataKey="name"
            type="category"
          />
          <XAxis
            type="number"
            tick={<XAxisTick />}
            axisLine={false}
            tickLine={false}
            domain={[0, limitX]}
            ticks={ticks}
          />
          <defs>
            <linearGradient id="barGradient" x1="0" y1="0" x2="100%" y2="0">
              <stop offset="-0.05%" stopColor="#0090D7" />
              <stop offset="105.58%" stopColor="#58C0F3" />
            </linearGradient>
          </defs>
          <Bar
            dataKey="revenue"
            radius={4}
            label={props =>
              BarLabel({
                ...props,
                humanizeName: name === intl.formatMessage({ id: 'common.serviceType' })
              })
            }
            background={{ fill: theme.new.colors.P100 }}
          >
            <LabelList dataKey="labelValue" content={LabelFormatter} />
            {data?.map((_, index) => (
              <Cell key={`cell-${index}`} fill="url(#barGradient)" />
            ))}
          </Bar>
        </BarChart>
      </StyledResponsiveContainer>
    </Container>
  );
};

RevenueGraph.propTypes = {
  data: arrayOf(insightRevenueGraphShape),
  limitX: number,
  name: string,
  step: number
};

export default RevenueGraph;
