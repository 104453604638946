import { instanceOf, oneOfType, string } from 'prop-types';

import { getTimestamp, stringToDate } from 'utils/date';

import { NewProjectStatusContainer } from './styles';

const NewProjectStatusRow = ({ startDate: renderStartDate, endDate: renderEndDate }) => {
  const startDate = stringToDate(renderStartDate);
  const endDate = stringToDate(renderEndDate);

  if (getTimestamp(startDate) == getTimestamp(endDate)) return null;

  return <NewProjectStatusContainer startDate={startDate} endDate={endDate} />;
};

NewProjectStatusRow.propTypes = {
  startDate: oneOfType([string, instanceOf(Date)]).isRequired,
  endDate: oneOfType([string, instanceOf(Date)]).isRequired
};

export default NewProjectStatusRow;
