import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { FULFILLED } from 'constants/actionStatusConstants';
import { useConfirm, useDispatch, useRequest } from 'hooks';
import {
  addResourceSoftSkills,
  addResourceTechnicalSkills,
  getResourceSoftSkills,
  getResourceTechnicalSkills,
  updateResourceSoftSkills,
  updateResourceTechnicalSkills,
  addSpecialEvent,
  updateSpecialEvent,
  getPersonSpecialEvents,
  deleteSpecialEvent
} from 'state/actions/peopleActions';
import {
  composeUpdateSoftSkills,
  composeUpdateTechnicalSkills
} from 'utils/resourceProfileUtilities';

const useSkillSet = personId => {
  const intl = useIntl();
  const [editSection, setEditSection] = useState(null);
  const [isOpenSoftSkillModal, setIsOpenSoftSkillModal] = useState(false);
  const [isOpenTechnicalSkillModal, setIsOpenTechnicalSkillModal] = useState(false);
  const [isOpenTrainingSkill, setIsOpenTrainingSkill] = useState(false);
  const [editSkill, setEditSkill] = useState(null);
  const [isEditingTrainings, setIsEditingTraining] = useState(false);
  const [deleteSpecialEventInfo, setDeleteSpecialEventInfo] = useState(null);
  const [repeatedSkill, setRepeatedSkill] = useState('');

  const updateResourceSoftSkillsRequest = useDispatch(updateResourceSoftSkills);
  const addResourceSoftSkillsRequest = useDispatch(addResourceSoftSkills);
  const updateResourceTechnicalSkillsRequest = useDispatch(updateResourceTechnicalSkills);
  const addResourceTechnicalSkillsRequest = useDispatch(addResourceTechnicalSkills);
  const addSpecialEventRequest = useDispatch(addSpecialEvent);
  const updateSpecialEventRequest = useDispatch(updateSpecialEvent);
  const deleteSpecialEventRequest = useDispatch(deleteSpecialEvent);

  const [{ response: technicalSkills, isPending: isLoadingExperiences }, getTechnicalSkills] =
    useRequest(getResourceTechnicalSkills, {
      params: { personId, highlighted: false }
    });
  const [{ response: softSkills, isPending: isLoadingSoftSkills }, getSoftSkills] = useRequest(
    getResourceSoftSkills,
    {
      params: { personId }
    }
  );
  const [{ isPending: isLoadingSpecialEvents, response: specialEvents }, getSpecialEvents] =
    useRequest(getPersonSpecialEvents, {
      params: { personId },
      fulfilledCallback: specialEvents => {
        if (!specialEvents?.length > 0) {
          setIsEditingTraining(false);
        }
      }
    });

  const { softSkills: skills } = softSkills;
  const softSkillsNames = skills?.length ? skills.map(({ name }) => name.trim().toLowerCase()) : [];

  const [ConfirmationDelete, confirmDelete] = useConfirm({
    title: intl.formatMessage({ id: 'modal.confirm.title' }),
    message: intl.formatMessage(
      { id: 'template.deleteEntity' },
      { entity: deleteSpecialEventInfo?.specialEventType }
    ),
    onConfirm: () =>
      deleteSpecialEventRequest({
        personId,
        specialEventId: deleteSpecialEventInfo?.id
      }),
    onSuccess: () => getSpecialEvents(),
    confirmLabelIntlId: 'common.delete',
    cancelLabelIntlId: 'common.cancel'
  });

  const handleToggleSoftSkills = () => {
    setIsOpenSoftSkillModal(!isOpenSoftSkillModal);
    setEditSection(null);
  };

  const handleUpdateSoftSkills = async data => {
    const payload = composeUpdateSoftSkills(data);
    const { type } = await updateResourceSoftSkillsRequest({ personId, payload });
    if (type.endsWith(`/${FULFILLED}`)) {
      getSoftSkills();
    }
  };

  const handleAddSoftSkills = async payload => {
    const skillName = payload?.softSkills[0]?.name?.trim().toLowerCase();
    if (softSkillsNames.includes(skillName)) {
      setRepeatedSkill(intl.formatMessage({ id: 'profile.softSkills.error' }, { skillName }));
      return;
    }
    const { type } = await addResourceSoftSkillsRequest({ personId, payload });
    if (type.endsWith(`/${FULFILLED}`)) {
      getSoftSkills();
      return true;
    }
  };

  const handleToggleTechnicalSkills = () => {
    setIsOpenTechnicalSkillModal(!isOpenTechnicalSkillModal);
    setEditSection(null);
  };

  const handleUpdateTechnicalSkills = async data => {
    const payload = composeUpdateTechnicalSkills(data);
    const { type } = await updateResourceTechnicalSkillsRequest({ personId, payload });
    if (type.endsWith(`/${FULFILLED}`)) {
      getTechnicalSkills();
    }
  };

  const handleAddTechnicalSkills = async payload => {
    const { type } = await addResourceTechnicalSkillsRequest({ personId, payload });
    if (type.endsWith(`/${FULFILLED}`)) {
      getTechnicalSkills();
      return true;
    }
  };

  const handleToggleTrainingSkill = () => {
    if (isOpenTrainingSkill) {
      setEditSkill(null);
    }
    setIsOpenTrainingSkill(!isOpenTrainingSkill);
  };

  const handleEditTrainingSkill = skill => {
    setEditSkill(skill);
    handleToggleTrainingSkill();
  };

  const handleUpdateTrainingSkill = async payload => {
    const { type } = await updateSpecialEventRequest({
      personId,
      specialEventId: editSkill.specialEventId,
      payload
    });
    if (type.endsWith(`/${FULFILLED}`)) {
      getSpecialEvents();
      return true;
    }
  };

  const handleAddTrainingSkill = async payload => {
    const { type } = await addSpecialEventRequest({ personId, payload });
    if (type.endsWith(`/${FULFILLED}`)) {
      getSpecialEvents();
      return true;
    }
  };

  const handleDeleteTrainingSkill = specialEvent => {
    setDeleteSpecialEventInfo(specialEvent);
    confirmDelete();
  };

  const isLoading = isLoadingExperiences || isLoadingSoftSkills || isLoadingSpecialEvents;

  useEffect(() => {
    if (!isOpenSoftSkillModal) {
      setRepeatedSkill('');
    }
  }, [isOpenSoftSkillModal]);

  return {
    ConfirmationDelete,
    editSection,
    editSkill,
    handleAddSoftSkills,
    handleAddTechnicalSkills,
    handleAddTrainingSkill,
    handleDeleteTrainingSkill,
    handleEditTrainingSkill,
    handleToggleSoftSkills,
    handleToggleTechnicalSkills,
    handleToggleTrainingSkill,
    handleUpdateSoftSkills,
    handleUpdateTechnicalSkills,
    handleUpdateTrainingSkill,
    isEditingTrainings,
    isLoading,
    isOpenSoftSkillModal,
    isOpenTechnicalSkillModal,
    isOpenTrainingSkill,
    repeatedSkill,
    setEditSection,
    setIsEditingTraining,
    softSkills,
    specialEvents,
    technicalSkills
  };
};

export default useSkillSet;
