import styled from 'styled-components';

export const FinancialDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2.4rem;
  min-height: calc(100vh - 11.2rem);
`;

export const FinancialDetailsNavbarContainer = styled.div`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.new.fontFamily};
  justify-content: space-between;
  margin-bottom: 2.6rem;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.new.typography.h2};
  color: ${({ theme }) => theme.new.colors.black};
  display: flex;
`;

export const ContentContainer = styled.div`
  border-radius: 0.6rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;
