import styled, { css } from 'styled-components';

import { RoundButton } from 'components/common/OptionsButton/styles';
import { OptionsButtons } from 'components/Financial/Layout/OptionsButtons';

const setSvgColor = color => css`
  stroke: ${color};
  fill: ${color};
`;

export const OptionButtonStyled = styled(OptionsButtons)`
  margin: 0;

  ${RoundButton} {
    svg {
      ${({ theme, $isEditing }) =>
        setSvgColor($isEditing ? theme.new.colors.brandDark : theme.new.colors.white)};
    }
    &:hover {
      svg {
        ${({ theme }) => setSvgColor(theme.new.colors.white)};
      }
    }
  }
`;
