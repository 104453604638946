import { bool, func, string } from 'prop-types';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import AlertMessage from 'components/common/AlertMessage';
import Modal from 'components/common/Modal';
import TextArea from 'components/common/TextArea';
import { BUTTON_VARIANTS } from 'constants/constants';
import { useForm, useTextInputProps, useStatus } from 'hooks';
import { editNote } from 'state/actions/assignmentActions';

import { StyledButton, Form } from './styles';

const fields = {
  note: 'note'
};

const NotesModal = ({ isShowing, hide, note, handleEditNote }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isPending, isRejected, error, isFulfilled } = useStatus(editNote);

  const { values, setValues, errors, handleSubmit, handleValueChange, handleBlur } = useForm(
    {
      initialValues: { note },
      onSubmit: handleEditNote
    },
    [handleEditNote]
  );

  const inputProps = useTextInputProps({ handleValueChange, handleBlur, values, errors });

  useEffect(() => {
    setValues({ note });
  }, [note, setValues]);

  useEffect(() => {
    if (isFulfilled) {
      hide();
      dispatch(editNote.reset());
    }
  }, [dispatch, hide, isFulfilled]);

  return (
    <Modal
      isShowing={isShowing}
      hide={hide}
      title={intl.formatMessage({ id: 'modal.note.title' })}
      onClick={e => e.stopPropagation()}
    >
      <Form onSubmit={handleSubmit}>
        {isRejected && <AlertMessage>{error}</AlertMessage>}
        <div>
          <TextArea name="note" type="text" {...inputProps(fields.note)} rows={4} />
        </div>
        <StyledButton
          type="submit"
          variant={BUTTON_VARIANTS.SUBMIT}
          isLoading={isPending}
          textIntlId="modal.note.button"
          fullWidth
        />
      </Form>
    </Modal>
  );
};

NotesModal.propTypes = {
  handleEditNote: func,
  hide: func.isRequired,
  isShowing: bool.isRequired,
  note: string
};

export default NotesModal;
