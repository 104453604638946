import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.new.shadows.medium.boxShadow};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
  padding: 2.4rem 3.2rem;
`;
