import { node, number, object, oneOfType, string } from 'prop-types';

import BadgePill from 'components/common/BadgePill';
import { DiscountIcon } from 'components/common/DiscountIcon';
import { BADGE_PILL_VARIANTS } from 'constants/constants';
import { DISPLAY_DAY_MONTH, DISPLAY_MONTH_DAY_YEAR } from 'constants/dateFormats';
import theme from 'constants/theme';
import { dateToString } from 'utils/date';
import { getMarginColor, getMarginColorBorder } from 'utils/financialColors';
import { handlePriceWithDiscount } from 'utils/financialUtilities';
import { humanize } from 'utils/helpers';
import { formatAmount, toCurrency } from 'utils/number';

import AssignmentColumn from '../AssignmentColumn';
import {
  BilledValue,
  Column,
  IconColumn,
  StatusCircle,
  StatusColumn,
  StyledBilledDiscount,
  StyledRangeDates
} from './styles';

export const Cell = ({ value, columnKey, projectIcon }) => (
  <Column isStrong={columnKey === 'name'}>
    {{
      margin: (
        <BadgePill
          variant={BADGE_PILL_VARIANTS.SUCCESS}
          fontColor={getMarginColor({ margin: value, theme })}
          color="transparent"
          borderColor={
            Number(value) > 0 ? getMarginColorBorder({ margin: value, theme }) : 'transparent'
          }
        >
          {value && Number(value) > 0 ? `${parseFloat(value)?.toFixed(1)} %` : '--'}
        </BadgePill>
      ),
      assignments: (
        <AssignmentColumn
          overAssigned={value?.overAssigned}
          open={value?.open}
          pending={value?.pending}
          confirmed={value?.confirmed}
        />
      ),
      startDate: dateToString(value, DISPLAY_MONTH_DAY_YEAR),
      endDate: dateToString(value, DISPLAY_MONTH_DAY_YEAR),
      status: typeof value === 'string' && (
        <StatusColumn>
          <StatusCircle status={value} />
          {humanize(value)}
        </StatusColumn>
      ),
      projectType: typeof value === 'string' && humanize(value),
      periodLength: (
        <StyledRangeDates
          startDate={value?.startDate}
          endDate={value?.endDate}
          dateFormatter={date => dateToString(date, DISPLAY_DAY_MONTH)}
        />
      ),
      note: value || '-',
      billed: (
        <StyledBilledDiscount>
          <BilledValue>
            {toCurrency(
              value?.discount
                ? handlePriceWithDiscount(
                    formatAmount(value?.billed),
                    value?.discount >= 100 ? 100 : value?.discount
                  ) + value?.additionalCharges
                : value?.billed + value?.additionalCharges
            )}
          </BilledValue>
          {value?.discount || value?.discountIcon ? (
            <DiscountIcon discount={value?.discount || value?.discountIcon} />
          ) : null}
        </StyledBilledDiscount>
      ),
      additional: value ? toCurrency(value) : '-',
      fixedPrice: value ? toCurrency(value) : '-',
      name: (
        <IconColumn>
          {value} {projectIcon}
        </IconColumn>
      )
    }[columnKey] || value}
  </Column>
);

Cell.propTypes = {
  value: oneOfType([string, number, object]),
  columnKey: string,
  projectIcon: node
};

export default Cell;
