import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  display: flex;
  justify-content: space-between;
  min-height: 6rem;
  padding-bottom: 2rem;
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  top: 0;
  z-index: ${({ isSticky, theme }) => isSticky && theme.zIndex.stickyClosePeriodHeader};

  & > div {
    align-items: center;
    display: flex;
    gap: 1.6rem;
  }
`;

export const Title = styled.div`
  display: flex;
  ${({ theme }) => theme.new.typography.h4};
  color: ${({ theme }) => theme.new.colors.P800};
`;
