import { useState } from 'react';
import { useIntl } from 'react-intl';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import { PROJECT_STATUS_TYPES } from 'constants/constants';
import { useRequest } from 'hooks';
import { getProjectsTotals } from 'state/actions/forecastingActions';

import { Container, TotalItem, Totals, TotalValue } from './styles';

const ProjectsTotals = () => {
  const intl = useIntl();
  const [projectsTotals, setProjectsTotals] = useState(null);
  const [{ isPending: isTotalsLoading }] = useRequest(getProjectsTotals, {
    fulfilledCallback: payload => {
      setProjectsTotals(payload);
    }
  });

  if (isTotalsLoading)
    return (
      <Container>
        <CenteredLoading />
      </Container>
    );

  return (
    <Totals>
      <TotalItem>
        {`${intl.formatMessage({ id: 'common.projects' })}:`}
        <TotalValue status={PROJECT_STATUS_TYPES.ACTIVE}>{projectsTotals?.projects}</TotalValue>
      </TotalItem>
      <TotalItem>
        {intl.formatMessage({ id: 'forecasting.projectsTotals.status' })}
        <TotalValue status={PROJECT_STATUS_TYPES.ACTIVE}>
          {`${intl.formatMessage({ id: 'status.confirmedOrActive' })} (${projectsTotals?.active}) `}
        </TotalValue>
        <TotalValue status={PROJECT_STATUS_TYPES.MAINTENANCE}>{`${intl.formatMessage({
          id: 'status.maintenance'
        })} (${projectsTotals?.maintenance})`}</TotalValue>
      </TotalItem>
    </Totals>
  );
};

export default ProjectsTotals;
