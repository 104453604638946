import { css } from 'styled-components';

import { setSvgColors } from 'utils/cssUtilities';

const fontColor = css`
  ${({ theme }) => theme.colors.white}
`;

const backgroundColor = css`
  ${({ theme }) => theme.colors.blue}
`;

export const PrimaryButton = css`
  background-color: ${backgroundColor};
  border-radius: 8px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.07);
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  height: 3.8rem;
  padding: 9px 20px;
  width: 21.1rem;
  position: relative;
  ${setSvgColors({ strokeColor: fontColor, fillColor: backgroundColor })};
  &:hover {
    background-color: ${({ theme }) => theme.colors.hoverBlue};
    ${({ theme }) => setSvgColors({ fillColor: theme.colors.hoverBlue })}
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    ${({ theme }) => setSvgColors({ fillColor: theme.colors.darkBlue })}
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledBlue};
    cursor: not-allowed;
    ${({ theme }) => setSvgColors({ fillColor: theme.colors.disabledBlue })}
  }
`;
