import styled from 'styled-components';

export const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.modalBackground};
  background-blend-mode: multiply;
  bottom: 0;
  display: flex;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${({ theme, isOverlapped }) =>
    isOverlapped ? theme.zIndex.modalOverlapped : theme.zIndex.modal};
`;

export const ModalButton = styled.button`
  align-self: flex-start;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  position: absolute;
  right: 2.6rem;
  top: 2.6rem;
`;

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1.3rem;
  max-height: 100vh;
  min-width: 42rem;
  padding: ${({ padding }) => padding || '4rem'};
  position: relative;
  ${({ withoutOverflow }) => {
    if (!withoutOverflow) {
      return `
        overflow-x: hidden;
        overflow-y: auto;
         `;
    }
  }}
`;

export const ModalTitle = styled.h3`
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ theme }) => theme.typography.semiBold};
  font-size: 2.1rem;
`;

export const ModalSubTitle = styled.h4`
  margin-bottom: 1.6rem;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;
