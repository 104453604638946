import { number } from 'prop-types';
import { useIntl } from 'react-intl';

import { Container, Title, Box, Label } from './styles';

function AvgOnBench({ days }) {
  const intl = useIntl();

  return (
    <Container>
      <Title>{intl.formatMessage({ id: 'dashboard.breakdown.avg' })}</Title>
      <Box>{days}</Box>
      <Label>{intl.formatMessage({ id: 'common.lower.days' })}</Label>
    </Container>
  );
}

AvgOnBench.propTypes = {
  days: number
};

export default AvgOnBench;
