import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import { QUARTER_TYPES } from 'constants/constants';
import { useRequest } from 'hooks';
import { getDashboardBenchQuarters } from 'state/actions/dashboardActions';
import { getQuarter } from 'utils/utilizationUtilities';

import {
  Block,
  Container,
  Header,
  InfoItem,
  QuarterInfo,
  QuarterNumber,
  Row,
  StyledLoadingWrapper
} from './styles';

const initialQuarters = {
  quarterBlocks: [...Array(4)].map((_, index) => ({ quarter: index + 1 }))
};

const QuarterBlocks = () => {
  const intl = useIntl();

  const [{ isPending, error, response: benchQuarters }] = useRequest(getDashboardBenchQuarters, {
    initialData: initialQuarters
  });

  return (
    <Container>
      {benchQuarters?.quarterBlocks?.map(
        ({ quarter, totalBenchPercentage: benchPercentage, totalBenchFte: benchFte }) => {
          const quarterType = getQuarter(quarter);

          return (
            <StyledLoadingWrapper isLoading={isPending} key={quarter}>
              <Block>
                <Header isCurrent={quarterType === QUARTER_TYPES.CURRENT}>
                  <span>
                    {intl.formatMessage({ id: `common.${quarterType}` })}{' '}
                    {intl.formatMessage({ id: 'common.cap.quarter' })}
                  </span>
                </Header>
                <Row>
                  <QuarterNumber>
                    <span>{`Q${quarter}`}</span>
                  </QuarterNumber>
                  <QuarterInfo>
                    <InfoItem>
                      <span>{`${intl.formatMessage({ id: 'common.bench' })} : `} </span>
                      <span>{benchPercentage ? `${Math.round(benchPercentage)}%` : '--'}</span>
                    </InfoItem>
                    <InfoItem isFTE>
                      <span>{`${intl.formatMessage({ id: 'common.FTE' })} : `} </span>
                      <span>{benchPercentage ? `${Math.round(benchFte)}` : '--'}</span>
                    </InfoItem>
                  </QuarterInfo>
                  {error && <AlertMessage>{error}</AlertMessage>}
                </Row>
              </Block>
            </StyledLoadingWrapper>
          );
        }
      )}
    </Container>
  );
};

export default QuarterBlocks;
