import { useRef } from 'react';

const useOutsideClick = callback => {
  const timerId = useRef(null);

  const handleBlur = () => {
    timerId.current = setTimeout(() => {
      callback();
    });
  };

  const handleFocus = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
  };

  return { handleBlur, handleFocus };
};

export default useOutsideClick;
