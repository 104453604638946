import DatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';

import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { inputBorder, newInputBorder } from 'utils/inputColors';

export const CalendarIcon = styled(Calendar)`
  position: absolute;
  right: 16px;
  top: 25%;
  path {
    stroke: ${({ theme }) => theme.new.colors.neutral400};
  }
`;

const datePickerVariant = variant =>
  ({
    old: css`
      border: ${({ errors, theme }) =>
        inputBorder({ baseColor: theme.colors.gray60, errors, theme })};
      border-radius: 8px;
    `,
    new: css`
      border: ${({ errors, theme }) =>
        newInputBorder({ baseColor: theme.new.colors.neutral400, errors, theme })};
      border-radius: 4px;
    `
  }[variant] || '');

const labelVariant = variant =>
  ({
    old: css`
      border-radius: 1.3rem;
      bottom: auto;
      color: ${({ errors, theme }) =>
        errors ? theme.new.colors.feedbackError : theme.new.colors.neutral600};
      font-size: ${({ selected }) => (selected ? '1.2rem' : '1.6rem')};
      left: ${({ selected }) => (selected ? '1rem' : '0.6rem')};
      left: ${({ labelPosition }) => labelPosition};
      padding: 0 0.5rem;
      position: absolute;
      top: ${({ selected, isDayPicker }) => (selected ? `${isDayPicker ? '1.1rem' : 0}` : '2rem')};
      top: ${({ labelPosition }) => labelPosition};
      transition: all 0.2s ease-in-out;
      z-index: 2;
    `,
    new: css`
      ${({ theme }) => theme.new.typography.p6};
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.new.colors.neutral800};
      font-size: 1.6rem;
      padding: 0 0 4px;
    `
  }[variant] || '');

export const StyledDatePicker = styled(DatePicker)`
  ${({ theme }) => theme.new.typography.p6};
  box-shadow: none;
  color: ${({ theme }) => theme.new.colors.neutral900};
  cursor: pointer;
  font-size: 1.5rem;
  height: 4.4rem;
  line-height: 2;
  padding-left: 1rem;
  transition: all 0.2s ease-in-out;
  ${({ variant }) => datePickerVariant(variant)};
  &:focus {
    border-color: ${({ errors, theme }) => errors && theme.new.colors.feedbackError};
    box-shadow: none;
  }
`;

export const Label = styled.label`
  ${({ variant }) => labelVariant(variant)}
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: none;
`;

export const ErrorSpan = styled.span`
  ${({ theme }) => theme.typography.italic}
  bottom: 0.5rem;
  color: ${({ theme }) => theme.new.colors.feedbackError};
  font-size: 1.3rem;
  left: 0.5rem;
  line-height: 1;
  position: absolute;
`;

export const DatePickerContainer = styled.div`
  background: none;
  font-size: 1.6rem;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.dropdownOptions};
  ${({ theme }) => theme.new.typography.p6};
  & .react-datepicker__today-button {
    align-items: center;
    background: ${({ theme }) => theme.new.colors.white};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid ${({ theme }) => theme.new.colors.N100};
    color: ${({ theme }) => theme.new.colors.N800};
    display: flex;
    height: 4rem;
    justify-content: center;
    &:hover {
      font-weight: ${({ theme }) => theme.fontWeights.heavyBold};
    }
  }
  input {
    caret-color: transparent;
  }
  ${({ isDayPicker }) =>
    isDayPicker &&
    css`
      & .react-datepicker-popper {
        width: 328px;
      }
      & .react-datepicker {
        background: ${({ theme }) => theme.new.colors.white};
        ${({ theme }) => theme.new.shadows.small};
        ${({ theme }) => theme.new.typography.p6}
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.new.colors.N300};
        &__header {
          background: ${({ theme }) => theme.new.colors.white};
          border-radius: 4px;
          border: none;
          display: flex;
          flex-direction: column;
          gap: 2.8rem;
          padding-bottom: 1rem;
          padding: 2rem 2.4rem;
          &__dropdown {
            display: none;
          }
        }
        &__navigation-icon {
          &--next,
          &--previous {
            &::before {
              border-color: ${({ theme }) => theme.new.colors.N600};
              border-width: 1.67px;
              border-style: solid solid none none;
            }
          }
        }

        &__navigation--next,
        &__navigation--previous {
          height: 3.6rem;
          right: 2.4rem;
          top: 2rem;
          width: 3.6rem;
        }

        &__current-month {
          ${({ theme }) => theme.new.typography.p4SemiBold};
          color: ${({ theme }) => theme.new.colors.N800};
          height: 3.6rem;
          line-height: 3.6rem;
        }

        &__week {
          background: ${({ theme }) => theme.new.colors.P100};
          &-number {
            background: white;
            line-height: 4rem;
            margin: 0;
            padding: 0;
            width: 4rem;
          }
        }

        &__day--in-selecting-range,
        &__day--in-range {
          background: ${({ theme }) => theme.new.colors.P100} !important;
          color: ${({ theme }) => theme.new.colors.P500} !important;
          height: 4rem;
          line-height: 4rem;
          width: 4rem;
          &:hover {
            box-shadow: none;
            background: ${({ theme }) => theme.new.colors.P500} !important;
            border-radius: 50%;
            color: ${({ theme }) => theme.new.colors.P100} !important;
            &::before {
              background: ${({ theme }) => theme.new.colors.P100} !important;
            }
            &::after {
              background: ${({ theme }) => theme.new.colors.P500} !important;
            }
          }
        }

        &__month {
          margin: 0;
          height: 100%;
          padding: 0 2.4rem ${({ hasTodayButton }) => (hasTodayButton ? '0.5rem' : '2.4rem')} 2.4rem;
          &-container {
            background: ${({ theme }) => theme.new.colors.white};
            width: 100%;
            border-radius: 4px;
          }
        }

        &__day {
          height: 4rem;
          width: 4rem;
          line-height: 4rem;
          text-align: center;
          background: ${({ theme }) => theme.new.colors.white};
          color: ${({ theme }) => theme.new.colors.N800};

          &--selected {
            background: ${({ theme }) => theme.new.colors.P500} !important;
            color: ${({ theme }) => theme.new.colors.P100} !important;
            border-radius: 50%;
            &::before {
              background: ${({ theme }) => theme.new.colors.white} !important;
              border-radius: 0;
            }
            &::after {
              background: ${({ theme }) => theme.new.colors.P500} !important;
            }
          }

          &:hover:not(&--in-selecting-range, &--disabled, &--range-start, &--range-end),
          &--keyboard-selected:not(&--in-selecting-range, &--disabled, &--range-start, &--range-end) {
            border-radius: 20px;
            z-index: 10;
            position: relative;
            color: ${({ theme }) => theme.new.colors.P500};
            &::before {
              content: '';
              width: 100%;
              position: absolute;
              height: 100%;
              top: 0;
              left: 0;
              background: ${({ theme }) => theme.new.colors.white};
              z-index: -1;
            }
            &::after {
              content: '';
              width: 100%;
              position: absolute;
              height: 100%;
              left: 0;
              top: 0;
              border-radius: 20px;
              z-index: -1;
              background: ${({ theme }) => theme.new.colors.P100};
            }
          }

          &-names {
            color: ${({ theme }) => theme.new.colors.N800};
            ${({ theme }) => theme.new.typography.p5SemiBold};
            width: 100%;
          }
          &-name {
            width: 4rem;
            padding: 0;
            margin: 0;
            height: 4rem;
            line-height: 4rem;
            text-align: center;
          }
          &--disabled {
            text-decoration: none;
            color: ${({ theme }) => theme.new.colors.N500};
            cursor: not-allowed;
          }
          &--range-start,
          &--range-end {
            line-height: 4rem;
            height: 4rem;
            width: 4rem;
            background-color: ${({ theme }) => theme.new.colors.P500};
            color: ${({ theme }) => theme.new.colors.white} !important;
            border-radius: 20px !important;
            position: relative;
            z-index: 10;
          }

          &--range-start {
            &::after {
              content: '';
              width: 100%;
              position: absolute;
              height: 100%;
              left: 0;
              top: 0;
              border-radius: 20px;
              z-index: -1;
              background: ${({ theme }) => theme.new.colors.P500};
            }
            &::before {
              content: '';
              width: 100%;
              position: absolute;
              height: 100%;
              top: 0;
              left: 0;
              background: ${({ theme }) => theme.new.colors.white};
              z-index: -2;
            }
          }

          &--range-start:not(&--range-end) {
            &::before {
              content: '';
              width: 50%;
              position: absolute;
              height: 100%;
              left: 0;
              top: 0;
              z-index: -1;
              background: ${({ theme }) => theme.new.colors.white};
            }
            &::after {
              content: '';
              width: 100%;
              position: absolute;
              height: 100%;
              left: 0;
              top: 0;
              border-radius: 20px;
              z-index: -1;
              background: ${({ theme }) => theme.new.colors.P500};
            }
          }

          &--range-end:not(&--range-start) {
            &::after {
              content: '' !important;
              width: 100% !important;
              position: absolute !important;
              height: 100% !important;
              left: 0 !important;
              top: 0 !important;
              border-radius: 20px !important;
              z-index: -1 !important;
              background: ${({ theme }) => theme.new.colors.P500} !important;
            }
            &::before {
              content: '';
              width: 50% !important;
              position: absolute;
              height: 100%;
              right: 0 !important;
              top: 0 !important;
              background: ${({ theme }) => theme.new.colors.white} !important;
              border-radius: 50% 0 0 50%;
              z-index: -1;
            }
          }
        }
      }
    `}

  ${({ popperWidth }) =>
    popperWidth &&
    `.react-datepicker-popper {
      width: ${popperWidth};
    }`}

  ${({ variant }) =>
    variant === 'old' &&
    css`
      padding: ${({ errors }) => `1.1rem 0 ${errors ? 2.6 : 1.3}rem`};
    `}
`;

export const DateRangeInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: ${({ gap }) => gap};
  width: 100%;
`;
