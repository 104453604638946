import { bool } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import BoxItem from 'components/common/BoxItem';
import { DISPLAY_DAY_MONTH_YEAR_NEW } from 'constants/dateFormats';
import { SCOPES } from 'constants/permissions';
import routesPaths from 'constants/routesPaths';
import { projectsReportsProfile } from 'constants/shapes';
import { useRole } from 'hooks';
import { dateToString, yearsMonthsDiff } from 'utils/date';
import hasPermission from 'utils/hasPermission';
import { routeWithProps } from 'utils/helpers';

import { ArrowIcon, Container, Content, Header, HeaderInfo, Item, MarginContainer } from './styles';

const ProjectAccordion = ({ assignment, current }) => {
  const intl = useIntl();
  const history = useHistory();

  const { permissions } = useRole();
  const [active, setActive] = useState(false);

  const permissionProjectDetails = hasPermission(permissions, [SCOPES.seeProjectDetails]);

  const {
    projectId,
    projectName,
    role,
    totalDuration,
    allocationPercentage,
    startDate,
    endDate,
    projectServiceType,
    projectType
  } = assignment || {};
  const formatStartDate = dateToString(startDate, DISPLAY_DAY_MONTH_YEAR_NEW);
  const formatEndDate = dateToString(endDate, DISPLAY_DAY_MONTH_YEAR_NEW);

  const handleRedirectProjectDetails = e => {
    if (permissionProjectDetails) {
      e.stopPropagation();
      history.push(routeWithProps(routesPaths.projectDetails, { id: projectId }));
    }
  };

  const handleActive = () => {
    setActive(!active);
  };

  const getCurrentDuration = () => {
    const [years, months] = yearsMonthsDiff(startDate);

    const intlYears = intl.formatMessage({ id: 'date.years' }, { years });
    const intlMonths = intl.formatMessage({ id: 'date.months' }, { months });

    return years > 0 ? `${intlYears}${months > 0 ? `, ${intlMonths}` : ''}` : intlMonths;
  };

  return (
    <Container>
      <Header onClick={handleActive}>
        <HeaderInfo enableRedirectStyles={permissionProjectDetails}>
          <span onClick={handleRedirectProjectDetails}>{projectName}</span>
          <span />
          <span>{role}</span>
          <span>{current ? getCurrentDuration() : totalDuration}</span>
        </HeaderInfo>
        <ArrowIcon active={active ? 1 : 0} />
      </Header>
      {active && (
        <Content>
          <Item>
            <p>{`${intl.formatMessage({ id: 'common.cap.allocation' })}: `}</p>
            <MarginContainer margin={allocationPercentage}>
              {allocationPercentage ? `${allocationPercentage}%` : '--'}
            </MarginContainer>
          </Item>
          <Item>
            <p>{`${intl.formatMessage({ id: 'common.cap.startDate' })}: `}</p>
            <span>{formatStartDate || '--'}</span>
          </Item>
          <Item>
            <p>{`${intl.formatMessage({ id: 'common.serviceType' })}: `}</p>
            <span>{projectServiceType || '--'}</span>
          </Item>
          <Item>
            <p>{`${intl.formatMessage({ id: 'common.cap.endDate' })}: `}</p>
            <span>{formatEndDate || '--'}</span>
          </Item>
          <Item />
          {projectType && <BoxItem title={projectType} />}
        </Content>
      )}
    </Container>
  );
};

ProjectAccordion.propTypes = {
  assignment: projectsReportsProfile,
  current: bool
};

export default ProjectAccordion;
