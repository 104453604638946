import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Plus } from 'assets/icons/plusIcon.svg';
import DeleteButton from 'components/common/DeleteButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  width: 100%;

  h3 {
    ${({ theme }) => theme.new.typography.h3};
    color: ${({ theme }) => theme.new.colors.neutral900};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 2rem;
  padding-top: 2rem;
  row-gap: 2rem;
`;

export const IconsGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 1.2rem;
`;

export const IconButton = styled.button`
  align-items: center;
  border: 0px;
  display: flex;
  flex-direction: column;
  height: 24px;
  justify-content: center;
  padding: 0;
  width: 24px;
`;

export const CloseIcon = styled(Close)`
  height: ${({ isEditing }) => (isEditing ? 'auto' : '0')};
  transition: all 0.2s;
  & path {
    stroke: ${({ theme }) => theme.new.colors.feedbackError};
  }
`;

export const EditIcon = styled(Edit)`
  height: ${({ isEditing }) => (isEditing ? '0' : '2rem')};
  transition: all 0.2s;
  width: 2rem;
`;

export const PlusIcon = styled(Plus)`
  & path {
    stroke: ${({ theme }) => theme.new.colors.P500};
    stroke-width: 1.7px;
  }
`;
export const Row = styled.div`
  align-items: center;
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  gap: ${({ gap }) => gap || '1rem'};
`;

export const Item = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  background-color: ${({ theme }) => theme.new.colors.N100};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 1.9rem 0.9rem;
  width: 100%;
  span {
    color: ${({ theme }) => theme.new.colors.N800};
  }
`;

export const ItemValue = styled.span`
  ${({ theme }) => theme.new.typography.p5};
`;

export const ActionsContainer = styled.div`
  height: 100%;
  opacity: ${({ isEditing }) => (isEditing ? '1' : '0')};
  transition: all 0.2s;
`;

export const ItemContent = styled.div`
  display: flex;
  row-gap: 1.1rem;
  flex-direction: column;
`;

export const StyledDeleteButton = styled(DeleteButton)`
  padding: 0;
  stroke-width: 1.7px;
  svg {
    transform: scale(${20 / 24});
  }
`;
