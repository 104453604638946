import styled from 'styled-components';

export const Column = styled.td`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.N700};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Content = styled.div`
  width: fit-content;
`;

export const AllocationContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
