import { arrayOf, bool } from 'prop-types';

import TechnologiesInfo from 'components/common/TechnologiesInfo';
import ResourceProfileModal from 'components/Reports/ResourceProfileModal';
import EditQuickAssignmentModal from 'components/timeline/EditQuickAssignmentModal';
import { EMPLOYEE_MODAL_TYPES } from 'constants/constants';
import { peopleSearchShape, technologiesShape } from 'constants/shapes';
import { useAssignment, useModal } from 'hooks';
import { capitalizeFirstLetter } from 'utils/searchPeopleUtilities';

import Interactions from './Sections/Interactions';
import MatchInfo from './Sections/MatchInfo';
import Profile from './Sections/Profile';
import { Container, Details } from './styles';

const EmployeeCard = ({ employee, skillsPerAssignment, displayMargin = true }) => {
  const {
    id,
    matchOverall,
    mainInfo: { role, fullName, avatarUrl },
    margin,
    mainSkills,
    seniority,
    availability: { assignments, timeOffs },
    availableWorkHours,
    specialEvents,
    hireDate,
    exitDate,
    holidays
  } = employee;

  const { openModal, closeModal, modalType } = useModal();

  const isProfileModal = modalType === EMPLOYEE_MODAL_TYPES.PROFILE;
  const isAssignmentModal = modalType === EMPLOYEE_MODAL_TYPES.ASSIGNMENT;

  const { assignment } = useAssignment();

  const { startDate, endDate } = assignment;

  return (
    <Container>
      <Details>
        <MatchInfo matchOverall={matchOverall} margin={margin} displayMargin={displayMargin} />
        <Profile
          image={avatarUrl}
          name={fullName}
          role={capitalizeFirstLetter(role.name)}
          senior={capitalizeFirstLetter(seniority)}
        />
        <TechnologiesInfo items={mainSkills} skillsPerAssignment={skillsPerAssignment} />
      </Details>
      {!!assignment && (
        <Interactions
          startDate={startDate}
          endDate={endDate}
          handleInteraction={openModal}
          timeOffs={timeOffs}
          assignments={assignments}
          capacity={availableWorkHours}
          specialEvents={specialEvents}
          hireDate={hireDate}
          exitDate={exitDate}
          holidays={holidays}
        />
      )}
      {isProfileModal && (
        <ResourceProfileModal personId={id} open={isProfileModal} hide={closeModal} />
      )}
      {isAssignmentModal && (
        <EditQuickAssignmentModal
          personId={id}
          name={fullName}
          isShowing={isAssignmentModal}
          hide={closeModal}
          busyPercentage={50}
          assignment={assignment}
        />
      )}
    </Container>
  );
};

EmployeeCard.propTypes = {
  employee: peopleSearchShape,
  skillsPerAssignment: arrayOf(technologiesShape),
  displayMargin: bool
};

export default EmployeeCard;
