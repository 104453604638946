import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 0 1rem;
`;

export const ResourceInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  text-align: left;

  a {
    text-decoration: none;
  }
`;

export const ResourceName = styled.p`
  color: ${props => props.theme.colors.gray100};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  margin: 0;
`;

export const ResourceRole = styled.p`
  color: ${props => props.theme.colors.gray80};
  font-size: 1.3rem;
  font-weight: ${props => props.theme.fontWeights.normal};
  margin: 0;
`;
