import { createAction } from '@reduxjs/toolkit';

import { FULFILLED } from 'constants/actionStatusConstants';
import FinancialServices from 'services/financialServices';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const updateLastPeriodEndDate = createAction('UPDATE_LAST_PERIOD_END_DATE');

export const getFinancialProjects = createAsyncThunk('GET_FINANCIAL_PROJECTS', async () => {
  try {
    const { data } = await FinancialServices.getProjects();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getFinancialProjectDetails = createAsyncThunk(
  'GET_FINANCIAL_PROJECT_DETAILS',
  async ({ id }) => {
    try {
      const { data } = await FinancialServices.getProject(id);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getFinancialResourceSetup = createAsyncThunk(
  'GET_FINANCIAL_RESOURCE_SETUP',
  async ({ id }) => {
    try {
      const { data } = await FinancialServices.getResourceSetup(id);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getProjectOpenedResources = createAsyncThunk(
  'GET_PROJECTS_OPENED_RESOURCES',
  async ({ id }) => {
    try {
      const {
        data: { resources }
      } = await FinancialServices.getProjectOpenedResources(id);
      return resources;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getProjectOpenedPeriods = createAsyncThunk(
  'GET_PROJECTS_OPENED_PERIODS',
  async ({ id }) => {
    try {
      const {
        data: { periods }
      } = await FinancialServices.getProjectOpenedPeriods(id);
      return periods;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getProjectOpenPeriodsData = createAsyncThunk(
  'GET_PROJECTS_OPENED_PERIODS_DATA',
  async ({ id }, { dispatch }) => {
    try {
      await dispatch(getProjectOpenedResources({ id }));
      await dispatch(getProjectOpenedPeriods({ id }));
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getProjectClosedPeriods = createAsyncThunk(
  'GET_PROJECTS_CLOSED_PERIODS',
  async ({ id }) => {
    try {
      const {
        data: { financialPeriods }
      } = await FinancialServices.getProjectClosedPeriods(id);
      return financialPeriods;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getClosedPeriodDetail = createAsyncThunk(
  'GET_CLOSED_PERIOD_DETAIL',
  async ({ periodId }) => {
    try {
      const { data } = await FinancialServices.getClosedPeriod(periodId);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const createOpenPeriod = createAsyncThunk(
  'CREATE_OPEN_PERIOD',
  async (payload, { dispatch }) => {
    try {
      const { data, status } = await FinancialServices.createOpenPeriod(payload);

      if (status === 200) {
        await dispatch(getProjectOpenPeriodsData({ id: payload.projectId }));
        dispatch(updateLastPeriodEndDate());
      }
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const deletePeriod = createAsyncThunk('DELETE_PERIOD', async ({ periodId }) => {
  try {
    await FinancialServices.deletePeriod(periodId);

    return periodId;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const deleteOpenPeriod = createAsyncThunk(
  'DELETE_OPEN_PERIOD',
  async ({ periodId }, { dispatch }) => {
    try {
      await dispatch(deletePeriod({ periodId }));
      return { periodId };
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const deleteClosedPeriod = createAsyncThunk(
  'DELETE_CLOSED_PERIOD',
  async ({ periodId, projectId }, { dispatch }) => {
    try {
      const {
        type,
        payload: { data }
      } = await dispatch(deletePeriod({ periodId }));

      if (type.endsWith(`/${FULFILLED}`)) {
        await dispatch(getFinancialProjectDetails({ id: projectId }));
        await dispatch(getProjectClosedPeriods({ id: projectId }));
      }
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const closeOpenPeriod = createAsyncThunk(
  'CLOSE_OPEN_PERIOD',
  async ({ periodId, projectId }, { dispatch }) => {
    try {
      const { data, status } = await FinancialServices.closeOpenPeriod(periodId);

      if (status === 200) {
        await dispatch(getFinancialProjectDetails({ id: projectId }));
        await dispatch(getProjectOpenPeriodsData({ id: projectId }));
        await dispatch(getProjectClosedPeriods({ id: projectId }));
      }
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const updatePeriodInfo = createAsyncThunk('UPDATE_PERIOD_INFO', async payload => {
  try {
    const { data } = await FinancialServices.updatePeriodInfo(payload);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const updateOpenPeriodHeader = createAsyncThunk(
  'UPDATE_OPEN_PERIOD_HEADER',
  async ({ projectId, ...body }, { dispatch }) => {
    try {
      const {
        type,
        payload: { data }
      } = await dispatch(updatePeriodInfo(body));

      if (type.endsWith(`/${FULFILLED}`)) {
        await dispatch(getProjectOpenPeriodsData({ id: projectId }));
        dispatch(updateLastPeriodEndDate());
      }

      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const updateOpenPeriodInfo = createAsyncThunk(
  'UPDATE_OPEN_PERIOD_INFO',
  async ({ projectId, ...body }, { dispatch }) => {
    try {
      const { type, payload } = await dispatch(updatePeriodInfo(body));

      if (type.endsWith(`/${FULFILLED}`)) {
        await dispatch(getProjectOpenPeriodsData({ id: projectId }));
      }
      return payload;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const updateClosedPeriodHeader = createAsyncThunk(
  'UPDATE_CLOSED_PERIOD_HEADER',
  async ({ id, projectId, ...body }, { dispatch }) => {
    try {
      const {
        type,
        payload: { data }
      } = await dispatch(updatePeriodInfo({ ...body, id }));

      if (type.endsWith(`/${FULFILLED}`)) {
        await dispatch(getFinancialProjectDetails({ id: projectId }));
        await dispatch(getProjectClosedPeriods({ id: projectId }));
      }
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const updateClosedPeriodInfo = createAsyncThunk(
  'UPDATE_CLOSED_PERIOD_INFO',
  async ({ projectId, periodId, body }, { dispatch }) => {
    try {
      const { type, payload } = await dispatch(updatePeriodInfo({ id: periodId, ...body }));

      if (type.endsWith(`/${FULFILLED}`)) {
        await dispatch(getFinancialProjectDetails({ id: projectId }));
      }
      return payload;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const editFinancialProjectDetails = createAsyncThunk(
  'EDIT_FINANCIAL_PROJECT_DETAILS',
  async payload => {
    try {
      const { data } = await FinancialServices.editProject(payload);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const editResourceSetup = createAsyncThunk(
  'EDIT_RESOURCE_SETUP',
  async ({ projectId, ...body }, { dispatch }) => {
    try {
      const { data, status } = await FinancialServices.updateResourceSetup(body);
      if (status === 200) {
        await dispatch(getFinancialResourceSetup({ id: projectId }));
        await dispatch(getProjectOpenPeriodsData({ id: projectId }));
      }
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const refreshResourceSetup = createAsyncThunk(
  'REFRESH_RESOURCE_SETUP',
  async ({ projectId }) => {
    try {
      const { data } = await FinancialServices.refreshResourceSetup(projectId);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getRefreshedResourceSetup = createAsyncThunk(
  'GET_REFRESHED_RESOURCE_SETUP',
  async ({ projectId }) => {
    try {
      const { data } = await FinancialServices.getRefreshResourceSetup(projectId);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getExportData = createAsyncThunk('GET_EXPORT_DATA', async ({ startDate, endDate }) => {
  try {
    const { data } = await FinancialServices.getExportData(startDate, endDate);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});
