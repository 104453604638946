import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';

class UserService {
  static login(adminUser) {
    return httpClient.post(ENDPOINTS.login, adminUser);
  }

  static loginGoogle(adminUser) {
    return httpClient.post(ENDPOINTS.loginGoogle, adminUser);
  }

  static logout() {
    return httpClient.delete(ENDPOINTS.logout, { data: {} });
  }

  static signUp(user) {
    return httpClient.post(ENDPOINTS.signUp, user);
  }
}

export default UserService;
