import { bool, func, object, oneOfType, string } from 'prop-types';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import DeleteButton from 'components/common/DeleteButton';
import IconWithTooltip from 'components/common/IconWithTooltip';
import LoadingWrapper from 'components/common/LoadingWrapper';
import { FULFILLED } from 'constants/actionStatusConstants';
import { BILLING_UNITS, PROJECT_STATUS_TYPES } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { DEFAULT_CAPACITY_HOURS } from 'constants/projectConstants';
import { useSession, useResourceDetail, useRole, useRoles, useFormAccordion } from 'hooks';
import {
  composeAssignmentRequest,
  getExpectedPercentage,
  getResourceWorkHours
} from 'utils/assignmentFormsUtilities';
import { getProjectMinMaxDates, addYears, minDate, maxDate } from 'utils/date';
import { convertCostsToBillingUnit } from 'utils/financialUtilities';
import hasPermission from 'utils/hasPermission';

import AssignmentFormAlerts from './AssignmentFormAlerts';
import AssignmentFormButtons from './AssignmentFormButtons';
import AssignmentsAndDates from './assignmentsAndDates';
import fields from './fields.json';
import NotesSection from './notesSection';
import ResourcesAndSkills from './resourcesAndSkills';
import { Form, AccordionWrapper, TotalAvailability, AdditionalDataWrapper } from './styles';

const today = new Date();

const { DAILY, HOURLY } = BILLING_UNITS;

const AssignmentForm = ({
  error,
  hide,
  initialValues,
  isDeleteLoading,
  onDelete,
  onSubmit,
  project,
  resetStatus,
  showCopy = false,
  status
}) => {
  const intl = useIntl();

  const { user } = useSession();
  const { roles } = useRoles();

  const [resources, setResources] = useState();
  const [shouldHideOnSuccess, setShouldHideOnSuccess] = useState(false);
  const [loadingCapacity, setLoadingCapacity] = useState(true);
  const [capacityHours, setCapacityHours] = useState();

  const [projectStart, projectEnd] = useMemo(() => getProjectMinMaxDates(project), [project]);

  const [resourceId, setResourceId] = useState(
    initialValues?.personId?.value?.id || initialValues?.person?.id
  );

  const personDetail = useResourceDetail(resourceId);
  const { permissions } = useRole();

  const initialPending = ![
    PROJECT_STATUS_TYPES.CONFIRMED,
    PROJECT_STATUS_TYPES.ACTIVE,
    PROJECT_STATUS_TYPES.MAINTENANCE
  ].includes(project.status);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      pending: initialPending,
      quantity: 1,
      dates: {
        startDate: new Date(),
        endDate: new Date()
      },
      ...initialValues,
      workHours: DEFAULT_CAPACITY_HOURS,
      skillIds: initialValues?.skillIds || [],
      note: initialValues?.note?.body,
      rate:
        Math.round(
          convertCostsToBillingUnit(
            {
              from: HOURLY,
              to: DAILY,
              capacity: personDetail?.workHours
            },
            initialValues?.rate
          )
        ) || null
    }
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
    getValues
  } = methods;

  const { personId, role } = getValues();

  const expectedPercentage = useMemo(
    () => getExpectedPercentage(role?.value?.id, roles, resourceId, resources),
    [role?.value?.id, roles, resourceId, resources]
  );

  const resourceWorkHours = useMemo(
    () => getResourceWorkHours(resourceId, resources),
    [resourceId, resources]
  );

  const getCapacityHours = useCallback(() => {
    setCapacityHours((resourceWorkHours * expectedPercentage) / 100);
    setLoadingCapacity(false);
  }, [expectedPercentage, resourceWorkHours]);

  const [initialStartDate, initialEndDate] = [
    maxDate(projectStart, initialValues?.startDate || today),
    minDate(projectEnd, initialValues?.endDate || new Date(addYears(1, today)))
  ];

  const noGeneralPermission = !hasPermission(permissions, [
    SCOPES.createAssignment,
    SCOPES.editAssignment,
    personId?.value?.id > 0 ? '' : SCOPES.editAssignmentBasic
  ]);

  useEffect(() => {
    if (shouldHideOnSuccess && status?.includes(FULFILLED)) hide();
  }, [shouldHideOnSuccess, status, hide]);

  useEffect(() => {
    if (status === FULFILLED) {
      resetStatus();
      reset({
        ...getValues(),
        personId: null,
        role: null,
        seniority: null,
        skillIds: [],
        note: null,
        dates: {
          startDate: initialStartDate,
          endDate: initialEndDate
        },
        quantity: 1
      });
    }
  }, [status, getValues, reset, resetStatus, initialStartDate, initialEndDate]);

  useEffect(() => {
    setLoadingCapacity(true);
    getCapacityHours();
  }, [role, resourceId, getCapacityHours]);

  const formSections = [
    {
      section: 1,
      title: 'assignment.form.section.assignmentsAndDates',
      sectionErrors: [fields.assignment.role, fields.assignment.percentage],
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      component: () => (
        <AssignmentsAndDates
          capacityHours={capacityHours}
          errors={errors}
          initialValuesWithDates={{ initialValues, initialStartDate, initialEndDate }}
          noGeneralPermission={noGeneralPermission}
          projectDates={{ projectStart, projectEnd }}
        />
      )
    },
    {
      section: 2,
      title: 'assignment.form.section.resourceAndSkills',
      optional: true,
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      component: props => (
        <ResourcesAndSkills
          noGeneralPermission={noGeneralPermission}
          resourceId={resourceId}
          resources={resources}
          setResourceId={setResourceId}
          setResources={setResources}
          {...props}
        />
      )
    },
    {
      section: 3,
      title: 'assignment.form.section.notes',
      optional: true,
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      component: () => <NotesSection noGeneralPermission={noGeneralPermission} />
    }
  ];

  const { accordionWrapperRef, openErrorSection, renderFormAccordion } = useFormAccordion(
    formSections,
    errors
  );

  const onSubmitOverride =
    (keepModalOpen = false) =>
    async event => {
      await handleSubmit(values => {
        setShouldHideOnSuccess(!keepModalOpen);
        onSubmit(
          composeAssignmentRequest({
            userId: user.id,
            values,
            project,
            personDetail
          })
        );
      })(event);
      openErrorSection();
    };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmitOverride()}>
        <AccordionWrapper ref={accordionWrapperRef}>
          {renderFormAccordion()}
          <AdditionalDataWrapper>
            <LoadingWrapper isLoading={loadingCapacity}>
              <TotalAvailability>
                {intl.formatMessage(
                  {
                    id: 'template.assignment.resourceCapacity'
                  },
                  { capacity: capacityHours }
                )}
                <IconWithTooltip
                  message={intl.formatMessage({ id: 'assignment.form.capacity.tooltip' })}
                  icon={<InfoIcon />}
                  disablePortal
                />
              </TotalAvailability>
            </LoadingWrapper>

            {onDelete && (
              <DeleteButton
                type="button"
                textIntlId="common.cap.deleteAssignment"
                isLoading={isDeleteLoading}
                onClick={onDelete}
                disabled={noGeneralPermission}
                variant="new"
              />
            )}
          </AdditionalDataWrapper>
          <AssignmentFormAlerts error={error} status={status} />
        </AccordionWrapper>
        <AssignmentFormButtons
          hide={hide}
          isDeleteLoading={isDeleteLoading}
          onSubmit={onSubmitOverride(true)}
          shouldHideOnSuccess={shouldHideOnSuccess}
          showCopy={showCopy}
          status={status}
        />
      </Form>
    </FormProvider>
  );
};

AssignmentForm.propTypes = {
  error: oneOfType([string, object]),
  hide: func.isRequired,
  initialValues: object,
  isDeleteLoading: bool,
  onDelete: func,
  onSubmit: func.isRequired,
  project: object.isRequired,
  resetStatus: func.isRequired,
  showCopy: bool,
  status: string
};

export default AssignmentForm;
