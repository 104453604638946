import { getRequestFormatDate } from 'utils/date';
import { getTimelineFragments } from 'utils/timelineUtilities';

const showEvent = (value, startDateTimeline, endDateTimeline) =>
  value &&
  value.startDate < getRequestFormatDate(endDateTimeline) &&
  value.endDate > getRequestFormatDate(startDateTimeline);

export const getResourceEventsTimeLineFragments = (events, startDateTimeline, endDateTimeline) => {
  const personEventsFragments = events.length
    ? getTimelineFragments(
        startDateTimeline,
        endDateTimeline
      )(events.filter(event => showEvent(event, startDateTimeline, endDateTimeline)))
    : [];

  return personEventsFragments;
};
