import styled from 'styled-components';

import Button from 'components/common/Button';
import { ErrorSpan } from 'components/common/Input/styles';
import { Row } from 'components/common/Layout/Row';
import NewInput from 'components/common/NewInput';
import NewTextArea from 'components/common/NewTextArea';
import Select from 'components/common/Select';
import { StyledTextArea } from 'components/common/TextArea/styles';
import { inputBorder } from 'utils/inputColors';

const getInputBorder = ({ errors, theme }) =>
  inputBorder({ baseColor: theme.colors.gray60, errors, theme });

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 59.2rem;
`;

export const AccordionWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  max-height: calc(100vh - 20rem);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledInput = styled(NewInput)`
  padding: 0;
  ${ErrorSpan} {
    bottom: 0;
    position: relative;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 0;
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const StyledResourceSelect = styled(Select)`
  width: 100%;
`;

export const StyledNotes = styled(NewTextArea)`
  padding: 0;
  width: 100%;
  ${StyledTextArea} {
    border: ${getInputBorder};
    min-height: 3.8rem;
    border-radius: 5px;
  }
`;

export const ButtonsRow = styled(Row)`
  align-items: center;
  display: flex;
  padding-top: 2rem;
`;

export const ResourceRow = styled.div`
  display: flex;
  width: 100%;
`;

export const RateRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
`;

export const SkillsRow = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
`;

export const StyledRow = styled(Row)`
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  gap: 2.2rem;
`;

export const TotalAvailability = styled.div`
  ${({ theme }) => theme.new.typography.p4};
  align-items: center;
  align-self: end;
  color: ${({ theme }) => theme.new.colors.neutral800};
  display: flex;
  font-size: 1.4rem;
  gap: 1.2rem;
  height: 4.4rem;
  justify-content: flex-start;
  line-height: 1.3;
  min-width: 9.9rem;
  width: 100%;
`;

export const AdditionalDataWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding-left: 5.6rem;
  width: 100%;
  & > div:nth-child(1) {
    flex: 1;
  }
`;

export const WarningWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;
