const newFontFamily = 'Inter';
const fontWeights = {
  normal: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  heavyBold: '800'
};

const theme = {
  new: {
    fontFamily: newFontFamily,
    colors: {
      black: '#000000',
      white: '#FFFFFF',
      neutral100: '#FAFBFC',
      neutral200: '#F0F2F7',
      neutral300: '#E3E5EB',
      neutral400: '#D0D1D6',
      neutral500: '#B3B4BA',
      neutral600: '#84858A',
      neutral700: '#6D6E73',
      neutral800: '#2F3033',
      neutral900: '#1C1C1F',
      brand: '#476FF2',
      brandDark: '#203680',
      brandLight: '#EDF1FC',
      feedbackError: '#9E0800',
      feedbackSuccess: '#009E3D',
      feedbackSuccessLight: '#EDFDF3',
      feedbackSuccessDark: '#006627',
      feedbackWarningBase: '#E0BD00',
      feedbackWarningDark: '#998000',
      feedbackWarningLight: '#FDFAEB',
      feedbackErrorDark: '#5C0500',
      feedbackErrorLight: '#FDEEED',
      feedbackAlertLight: '#FDF4EB',
      feedbackAlertBase: '#E07000',
      badgePrimaryFill: '#C5FFEA',
      badgePrimaryText: '#FFE3FC',
      strongWarranty: '#717985',
      strongMaintenance: '#024271',
      strongPlanned: '#548184',
      softWarranty: '#E0E4EB',
      softMaintenance: '#C3E2FF',
      softPlanned: '#CDEAEC',
      fernGreen: '#437D42',
      feta: '#DCF8CF',
      flamingo: '#EF4C4C',
      pippin: '#FFDDDD',
      hintOfGreen: '#E4FFE9',
      persianBlue: '#2146BF',
      gray900: '#101828',
      badgePrimaryStuffAugmentationText: '#A423B9',
      badgeSecondaryFinishedFill: '#EEFFF1',
      badgeSecondaryPlannedFill: '#FFF0F0',
      badgeSecondaryWarrantyFill: '#F4F6F8',
      badgeSecondaryNewFill: '#F5F8FF',
      badgeSecondaryOnHoldFill: '#EEFCFD',
      badgeSecondaryFullProductFill: '#FFFAF4',
      badgeSecondaryFullProductText: '#A86C30',
      badgeSecondaryDiscoveryFill: '#EDFFF8',
      badgeSecondaryDiscoveryText: '#3F6A5D',
      badgeSecondaryStuffAugmentationFill: '#FFF6FE',
      badgeSecondaryStaffAugmentationText: '#76537B',
      N100: '#F4F6F7',
      N100_05: '#F4F6F780',
      N200: '#EBECEE',
      N300: '#DCDCE0',
      N400: '#B9BBC1',
      N500: '#9EA0A9',
      N600: '#63656E',
      N700: '#43454C',
      N800: '#37383E',
      N900: '#222326',
      Black: '#000000',
      P100: '#EDF1FF',
      P200: '#C4D2FF',
      P300: '#9CB3FF',
      P400: '#7394FF',
      P500: '#476FF2',
      P600: '#3358D0',
      P700: '#2243AE',
      P800: '#15318C',
      P900: '#0B216A',
      S100: '#F6EDFF',
      S200: '#EFDEFF',
      S300: '#CD9BFF',
      S500: '#9C46F2',
      S700: '#6821AE',
      S900: '#3A0A6A',
      T100: '#FFEDF9',
      T200: '#FFD8F2',
      T300: '#FF9BDE',
      T500: '#F246B9',
      T700: '#AE217F',
      T900: '#6A0A4A',
      FS100: '#E7F7DF',
      FS200: '#DAF6CB',
      FS300: '#B0E495',
      FS500: '#68BB3E',
      FS900: '#2D6014',
      FE100: '#FFEBEB',
      FE200: '#FFD6D6',
      FE300: '#FFADAD',
      FE500: '#E73030',
      FE900: '#8F0000',
      FW100: '#FFFBE7',
      FW200: '#FFF7CD',
      FW300: '#FFEE94',
      FW500: '#E0BD00',
      FW900: '#8E7800',
      FI100: '#EDF2FF',
      FI200: '#DBE5FF',
      FI300: '#8EACF9',
      FI500: '#336CFF',
      FI900: '#00298F',
      BC100: '#E9F7F3',
      BC200: '#C8FFEF',
      BC300: '#74E2C2',
      BC400: '#FF8C40',
      BC500: '#2EA17F',
      BC900: '#194D3D',
      BO100: '#FFEDF6',
      BO200: '#FFDFEB',
      BO300: '#FAA3C3',
      BO500: '#E52E71',
      BO900: '#68092C',
      BOA100: '#E9F2F7',
      BOA500: '#3D86AD',
      BOA900: '#203946',
      BP100: '#FFFDF0',
      BP200: '#FFF6C8',
      BP300: '#F5E175',
      BP500: '#F3CE08',
      BP800: '#8C7600',
      FA500: '#FF8C40',
      strongActive: '#806DAA',
      softActive: '#E2D6FC',
      ghostWhite: '#F6F7FC',
      shadowBox: '#4F5E71',
      overAssigned: '#6EC3F1'
    },
    fontWeights: {
      ...fontWeights
    },
    shadows: {
      small: {
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.07)'
      },
      medium: {
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)'
      },
      large: {
        boxShadow: '0px 5px 14px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.05)'
      },
      extraLarge: {
        boxShadow: '0px 24px 40px rgba(0, 0, 0, 0.03), 0px 8px 24px rgba(0, 0, 0, 0.05)'
      },
      extraLarge2: {
        boxShadow: '0px 60px 100px rgba(0, 0, 0, 0.07), 0px 20px 40px rgba(0, 0, 0, 0.0417275)'
      }
    },
    // typography
    typography: {
      h1SemiBold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '3.2rem',
        lineHeight: '3.6rem'
      },
      h2: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '2.4rem',
        lineHeight: '2.8rem'
      },
      h3: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '1.8rem',
        lineHeight: '2rem'
      },
      h4: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.bold,
        fontSize: '2.2rem',
        lineHeight: '2.8rem'
      },
      h4SemiBold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '2.4rem',
        lineHeight: '2.8rem'
      },
      p1Bold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.bold,
        fontSize: '3.2rem',
        lineHeight: '4.8rem'
      },
      p1SemiBold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '3.2rem',
        lineHeight: '4.8rem'
      },
      p2SemiBold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '2.4rem',
        lineHeight: '3.6rem'
      },
      p3SemiBold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '1.8rem',
        lineHeight: '2.6rem'
      },
      p3Bold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.bold,
        fontSize: '1.8rem',
        lineHeight: '2.6rem'
      },
      p4: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.normal,
        fontSize: '1.6rem',
        lineHeight: '2rem'
      },
      p4SemiBold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '1.6rem',
        lineHeight: '1.9rem'
      },
      p4Bold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.bold,
        fontSize: '1.6rem',
        lineHeight: '1.9rem'
      },
      p5: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.normal,
        fontSize: '1.4rem',
        lineHeight: '2.1rem'
      },
      p5SemiBold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '1.4rem',
        lineHeight: '2.1rem'
      },
      p5Bold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.bold,
        fontSize: '1.4rem',
        lineHeight: '1.4rem'
      },
      p6: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.normal,
        fontSize: '1.3rem',
        lineHeight: '2rem'
      },
      p6Medium: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.medium,
        fontSize: '1.3rem',
        lineHeight: '2rem'
      },
      p6SemiBold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.semiBold,
        fontSize: '1.3rem',
        lineHeight: '2rem'
      },
      p6Bold: {
        fontFamily: newFontFamily,
        fontStyle: 'normal',
        fontWeight: fontWeights.bold,
        fontSize: '1.3rem',
        lineHeight: '2rem'
      }
    },

    // gradients
    gradients: {
      purpleHaze: 'linear-gradient(211.82deg, #A284F9 17.25%, #7A1BF3 99.01%)'
    }
  },

  // defaults
  fontFamily: 'Noto Sans',
  fontColor: '#515152',
  backgroundColor: '#000',
  nestedLeftMargin: '4.9rem',
  fragmentHighlightAmount: 5,

  // colors
  colors: {
    backgroundColor: '#f6f7fc',
    modalBackground: 'rgba(0,0,0,0.2)',
    loadingOverlay: 'rgba(255,255,255,0.6)',
    boxShadowColor: 'rgba(191, 191, 191, 0.25)',
    loader: '#000000A9',
    selectedBackground: 'rgba(115, 163, 187, 0.15)',
    expandedTimelineRow: 'rgba(81, 81, 82, 0.8)',
    black: '#000',
    bellIcon: '#FF6565',
    camouflageGreen: '#778a68',
    camouflageGreenAlpha: '#778a68A0',
    darkGray: '#19241e',
    grayOpacity04: 'rgba(0, 0, 0, 0.04)',
    grayOpacity07: 'rgba(0, 0, 0, 0.07)',
    whiteOpacity07: 'rgba(244, 246, 247, 0.7)',
    datepickerGrayBg: '#f0f0f0',
    datepickerGrayBorder: '#aeaeae',
    gray05: '#f2f2f2',
    gray10: '#d6d6d6',
    gray20: '#e1e1e1',
    gray30: '#cbcbcb',
    gray60: '#979797',
    gray80: '#747475',
    gray100: '#515152',
    gray120: '#F6F6F6',
    amber: '#ffe082',
    darkAmber: '#d0a629',
    gold: '#ba8d1a',
    goldAlpha: '#ba8d1aA0',
    green: '#d3e3c6',
    lightGreen: '#9abd7e',
    darkGreen: '#77a254',
    lightGray: '#f1f1f1',
    paleBlue: '#c3dbff',
    peach: '#f9ccbe',
    purple: '#79589f',
    red: '#ff0f00',
    fieryOrange: '#bb5d10',
    redAlpha: '#bb5d10A0',
    seaBlue: '#73A3BB',
    softActive: '#e2d6fc',
    softConfirmed: '#e2d6fc',
    softMaintenance: '#c3e2ff',
    softOnHold: '#ffe6f5',
    softPlanned: '#cdeaec',
    softSeaBlue: '#e2ebf0',
    softWarranty: '#e0e4eb',
    softNonBillable: '#e0e4eb',
    strongActive: '#806daa',
    strongConfirmed: '#806daa',
    strongMaintenance: '#426e98',
    strongOnHold: '#af6693',
    strongPlanned: '#548184',
    strongWarranty: '#717985',
    strongNonBillable: '#717985',
    white: '#fff',
    whiteOpacity1: 'rgba(255,255,255,0.1)',
    yellow: '#f6e6a9',
    melon: '#ffaea3',
    pastelRed: '#ff6f66',
    persianBlue: '#2634B9',
    activeGreen: '#008325',
    discovery: '#A0F3E5',
    softAssigned: '#F6E6A9',
    iceCold: '#a0f3e5',
    pendingRed: '#F1736B',
    pendingRedLight: '#FFEDEC',
    openBlue: '#4089F8',
    openBlueLight: '#E8F1FF',
    confirmedGreenLight: '#D4F3D3',
    confirmedGreen: '#008325',
    blue: '#0E6AF6',
    darkRed: '#DA1A1A',
    hoverBlue: '#4089F8',
    darkBlue: '#045ADD',
    disabledBlue: '#759CD6',
    transparentBlueRibbon: '#0e6af626',
    blueRibbon: '#0E6AF6',
    slateGray: '#707A94',
    progressbar: '#3DB049',
    hoverTable: '#EDEFF1',
    avgGreen: '#59b949',
    crusta: '#FF8540',
    newOrleans: '#F4D9A5',
    independence: '#495669',
    java: '#26E7A6',
    dodgerBlue: '#26A0FC',
    brightGray: '#e8eff2',
    puertoRico: '#52CB9F',
    hollywoodCerise: '#FF0099',
    cornFlowerBlue: '#35529780',
    heliotrope: '#DE6AFB',
    backfill: '#9211E1',
    disabled: '#e6e6e6',
    athensGray: '#E4E5EB',
    bombay: '#B3B4BA'
  },

  // gradients

  gradients: {
    purpleHaze: 'linear-gradient(211.82deg, #A284F9 17.25%, #7A1BF3 99.01%)'
  },

  // typography
  typography: {
    regular: {
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: '1.8'
    },
    medium: {
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '1.2rem',
      lineHeight: '1.6'
    },
    semiBold: {
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '1.3rem',
      lineHeight: '1.8'
    },
    italic: {
      fontFamily: 'Noto Sans',
      fontStyle: 'italic',
      fontWeight: 'normal',
      fontSize: '1.55rem',
      lineHeight: '2.4'
    },
    extraBold: {
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '1.7rem',
      lineHeight: '2.3'
    },
    italicPlaceholder: {
      fontFamily: 'Noto Sans',
      fontStyle: 'italic',
      fontWeight: 'normal',
      fontSize: '1.2rem'
    }
  },

  // fonts
  fontWeights: {
    normal: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
    heavyBold: '800'
  },

  // Z-indexes
  zIndex: {
    select: 3100,
    modal: 3000,
    modalOverlapped: 4000,
    loadingOverlay: 2000,
    generalTooltip: 1600,
    stickyFilterBar: 1300,
    stickyTimeline: 1000,
    stickyClosePeriodHeader: 1500,
    stickyClosePeriodDetailHeader: 1000,
    generalAvailability: 500,
    gridTimeline: 100,
    dropdownOptions: 1400
  },

  // Fixed positions
  fixedPositions: {
    filters: '0',
    timeLine: '6.6rem'
  },

  constantWidths: {
    timelineHeader: '30.4rem',
    wideTimelineHeader: '50rem',
    smallAvatar: '2.5rem',
    mediumAvatar: '3rem'
  },

  constantHeights: {
    timelineFragmentBig: '6.2rem',
    timelineFragmentMedium: '4.5rem',
    input: '5rem',
    separator: '4rem'
  }
};

export default theme;
