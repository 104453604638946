import styled, { css } from 'styled-components';

import RangeDates from 'components/common/RangeDates';
import { getColorByProjectStatus } from 'utils/projectsOverviewUtilities';

export const Column = styled.td`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  ${({ isStrong }) =>
    isStrong
      ? css`
          ${({ theme }) => theme.new.typography.p4SemiBold};
          color: ${({ theme }) => theme.new.colors.N900};
        `
      : css`
          ${({ theme }) => theme.new.typography.p5};
          color: ${({ theme }) => theme.new.colors.N700};
        `}
`;

export const StatusCircle = styled.div`
  background-color: ${({ theme, status }) => getColorByProjectStatus(theme, status)};
  border-radius: 50%;
  display: flex;
  height: 0.6rem;
  width: 0.6rem;
`;

export const StatusColumn = styled.div`
  align-items: center;
  display: flex;
  gap: 0.4rem;
`;

export const StyledBilledDiscount = styled.div`
  display: flex;
  gap: 0.9rem;
`;

export const BilledValue = styled.span`
  ${({ theme }) => theme.new.typography.p6SemiBold};
`;

export const IconColumn = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
`;

export const StyledRangeDates = styled(RangeDates)`
  color: ${({ theme }) => theme.new.colors.black};
`;
