import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { getSkills } from 'state/actions/uiActions';
import { skillToAssignmentOption } from 'utils/assignmentFormsUtilities';
import { sortOptions } from 'utils/filtersUtilities';

export default () => {
  const [skillOptions, setSkillOptions] = useState([]);
  const getSkillsRequest = useDispatch(getSkills);
  const { status: skillsStatus, error: skillsError } = useStatus(getSkills);

  const { skills, skillsByTeam } = useSelector(
    ({
      ui: {
        options: { skills, skillsByTeam }
      }
    }) => ({
      skills,
      skillsByTeam
    })
  );

  useEffect(() => {
    if (isEmpty(skills)) {
      getSkillsRequest();
    } else {
      const selectedSkills = !isEmpty(skillsByTeam) ? skillsByTeam : skills;
      setSkillOptions(sortOptions(selectedSkills.map(skillToAssignmentOption)));
    }
  }, [skills, skillsByTeam, getSkillsRequest]);

  return {
    skills,
    getSkillsRequest,
    skillsStatus,
    skillsError,
    skillOptions
  };
};
