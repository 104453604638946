import styled from 'styled-components';

export const ContainerGroups = styled.div`
  max-height: 60rem;
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  ${({ hidden }) => hidden && 'display:none;'}
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 16px;
  color: ${({ theme }) => theme.fontColor};
  margin-bottom: 5px;
`;
