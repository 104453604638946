import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  padding-right: 2rem;
`;

export const TabButton = styled.button`
  ${({ theme, active }) => (active ? theme.new.typography.p5SemiBold : theme.new.typography.p5)};
  ${({ theme, active }) =>
    active
      ? css`
          color: ${theme.new.colors.brand};
          border-bottom: 2px solid ${theme.new.colors.brand};
          padding: 0 0.5rem 1.4rem 0.5rem;
        `
      : css`
          color: ${theme.new.colors.neutral700};
          padding: 0 0.5rem 1.6rem 0.5rem;
        `}
`;
