import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 8px;
  box-shadow: 0 0 2px 0 rgba(79, 94, 113, 0.12), 0 2px 4px 0 rgba(79, 94, 113, 0.11),
    0 4px 8px 0 rgba(79, 94, 113, 0.1);
  padding: 2rem 2.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 1.8rem;
  }
`;

export const BenchSummary = styled.div`
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.new.colors.P100};
  width: 100%;
  display: flex;
  padding: 0.8rem 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  align-self: stretch;
`;

export const InfoItem = styled.div`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  display: flex;
  color: ${({ theme }) => theme.new.colors.N900};
  span {
    margin-right: 0.4rem;
  }
`;

export const StyledInfoIcon = styled(InfoIcon)`
  display: flex;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;

  path {
    stroke: ${({ theme }) => theme.new.colors.FI500};
    fill: transparent;
    transform: scale(${16 / 19});
  }
`;

export const FiltersContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  display: flex;
  flex-direction: column;
  padding-bottom: 2.2rem;
  margin-bottom: 0.8rem;
  gap: 2.2rem;
`;
