import { arrayOf, number, objectOf } from 'prop-types';

import { newCalendarShape } from 'constants/shapes';

import { TimelineGridContainer, Grid, GridTrackSpace, Section, Content } from './styles';

const NewTimelineGrid = ({ calendar, totalDays }) => (
  <TimelineGridContainer>
    <GridTrackSpace />
    <Content>
      {Object.entries(calendar).map(([key, value], index) => (
        <Section key={key} size={value.length} totalDays={totalDays} firstLine={index > 0}>
          {value.map(({ isWeekend, isToday, dayOfMonth }) => (
            <Grid key={dayOfMonth} isWeekend={isWeekend} isToday={isToday} />
          ))}
        </Section>
      ))}
    </Content>
  </TimelineGridContainer>
);

NewTimelineGrid.propTypes = {
  calendar: objectOf(arrayOf(newCalendarShape)),
  totalDays: number
};

export default NewTimelineGrid;
