import { css } from 'styled-components';

import { setSvgColors } from 'utils/cssUtilities';

const fontColor = css`
  ${({ theme }) => theme.colors.gray100}
`;
export const ClearButton = css`
  color: ${fontColor};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  height: 3rem;
  line-height: 1.9rem;
  min-width: 8.1rem;
  ${({ theme }) => setSvgColors({ strokeColor: fontColor, fillColor: theme.colors.white })}
`;
