import { func, string, arrayOf, number, oneOfType, instanceOf } from 'prop-types';
import { useIntl } from 'react-intl';

import MiniTimelineAllocation from 'components/timeline/MiniTimelineAllocation';
import { BUTTON_VARIANTS, EMPLOYEE_MODAL_TYPES } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { specialEventShape, timelineAssignmentShape, timeOffShape } from 'constants/shapes';
import { useRole } from 'hooks';
import hasPermission from 'utils/hasPermission';

import { EmployeeInteractions, ButtonsContainer, Title, StyledButton } from './styles';

const Interactions = ({
  handleInteraction,
  timeOffs,
  assignments,
  startDate,
  endDate,
  capacity,
  specialEvents,
  hireDate,
  exitDate,
  holidays
}) => {
  const intl = useIntl();
  const { permissions } = useRole();

  return (
    <EmployeeInteractions>
      <Title>{intl.formatMessage({ id: 'searchPeople.bestMatches.interactions.title' })}</Title>
      <MiniTimelineAllocation
        startDate={startDate}
        endDate={endDate}
        timeOffs={timeOffs}
        assignments={assignments}
        capacity={capacity}
        specialEvents={specialEvents}
        hireDate={hireDate}
        exitDate={exitDate}
        holidays={holidays}
      />
      <ButtonsContainer>
        <StyledButton
          variant={BUTTON_VARIANTS.NEW_SECONDARY}
          onClick={() => handleInteraction(EMPLOYEE_MODAL_TYPES.PROFILE)}
        >
          {intl.formatMessage({ id: 'searchPeople.bestMatches.button.viewProfile' })}
        </StyledButton>
        {hasPermission(permissions, [SCOPES.selectAssignment]) && (
          <StyledButton
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
            onClick={() => handleInteraction(EMPLOYEE_MODAL_TYPES.ASSIGNMENT)}
          >
            {intl.formatMessage({ id: 'searchPeople.bestMatches.button.select' })}
          </StyledButton>
        )}
      </ButtonsContainer>
    </EmployeeInteractions>
  );
};

Interactions.propTypes = {
  handleInteraction: func.isRequired,
  assignments: arrayOf(timelineAssignmentShape),
  timeOffs: arrayOf(timeOffShape),
  specialEvents: arrayOf(specialEventShape),
  startDate: string.isRequired,
  endDate: string.isRequired,
  capacity: number,
  hireDate: oneOfType([string, instanceOf(Date)]),
  exitDate: oneOfType([string, instanceOf(Date)]),
  holidays: oneOfType([string, instanceOf(Date)])
};

export default Interactions;
