import styled from 'styled-components';

import SearchInput from '../SearchInput';

export const DropdownCheckboxContainer = styled.div`
  padding: 1.2rem;
  padding-bottom: 0;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  box-shadow: ${({ theme }) => theme.new.shadows.small.boxShadow};
  top: 0.2rem;
  background: ${({ theme }) => theme.colors.white};
  left: -0.5rem;
  min-width: 19.1rem;
  position: relative;
`;

export const Body = styled.div`
  max-height: 30rem;
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1.1rem;
  }

  ::-webkit-scrollbar:horizontal {
    height: 1.1rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.new.colors.neutral300};
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.new.colors.white};
    width: 0.1rem;
  }
`;

export const OptionLabel = styled.div`
  ${({ theme }) => theme.new.typography.p6};
  color: ${({ theme }) => theme.new.colors.neutral600};
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Option = styled.button`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 1.6rem;
  height: 4.4rem;
  justify-content: start;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.new.colors.neutral100};
    ${OptionLabel} {
      color: ${({ theme }) => theme.new.colors.brand};
    }
  }
`;

export const ClearButton = styled.button`
  ${({ theme }) => theme.new.typography.p6Medium};
  color: ${({ theme }) => theme.new.colors.brand};
`;

export const ApplyButton = styled.button`
  background: ${({ theme }) => theme.colors.seaBlue};
  border-radius: 21px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.typography.regular};
  height: 2.5rem;
  width: 5.9rem;
`;

export const DropdownActions = styled.div`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: flex;
  height: 5.1rem;
  justify-content: space-between;
  padding: 0 1rem;
`;

export const StyledSearch = styled(SearchInput)`
  path {
    stroke: ${({ theme }) => theme.new.colors.neutral800};
  }
`;

export const ToggleContainer = styled.div`
  ${({ theme }) => theme.new.typography.p6};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral800};
  display: flex;
  gap: 0.6rem;
`;

export const TopActions = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral400};
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
`;
