import { bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import NewInput from 'components/common/NewInput';
import NewModal from 'components/common/NewModal';
import NewTextArea from 'components/common/NewTextArea';
import { BUTTON_VARIANTS } from 'constants/constants';
import { openPeriodShape } from 'constants/shapes';
import { openPeriodValidator } from 'constants/validators';
import { useDateRangeProps, useFinancial, useForm, useTextInputProps, useValidation } from 'hooks';
import { addDays, getPeriodEndDate, stringToDate } from 'utils/date';

import { ButtonsRow, StyledButton, StyledDateRange } from './styles';

const fields = {
  name: 'name',
  startDate: 'startDate',
  endDate: 'endDate',
  note: 'note'
};

const PeriodInfoModal = ({
  openPeriod,
  startDate,
  hide,
  open,
  onSubmit,
  isLoading,
  periodNumber,
  error,
  resetRequest,
  isEdit = false,
  isDatesDisabled = false
}) => {
  const intl = useIntl();

  const { projectDetails } = useFinancial();

  const initialDate =
    openPeriod?.startDate ||
    (startDate && addDays(startDate, 1)) ||
    projectDetails?.startDate ||
    new Date();

  const validator = useValidation(openPeriodValidator);

  const { values, setValues, errors, setErrors, handleSubmit, handleValueChange, handleBlur } =
    useForm(
      {
        initialValues: {
          name:
            openPeriod?.name ||
            intl.formatMessage({ id: 'template.periodNumber' }, { periodNumber }),
          startDate: stringToDate(initialDate),
          endDate: getPeriodEndDate(openPeriod?.endDate, initialDate),
          note: openPeriod?.note
        },
        onSubmit,
        validator,
        validateOnBlur: true
      },
      [onSubmit]
    );

  const inputProps = useTextInputProps({ handleValueChange, handleBlur, values, errors });
  const dateRangeProps = useDateRangeProps(
    {
      inputProps,
      values,
      setValues,
      errors,
      setErrors
    },
    [fields.startDate, fields.endDate]
  );

  useEffect(() => () => resetRequest && resetRequest(), [resetRequest]);

  return (
    <NewModal
      title={intl.formatMessage({ id: `modal.openPeriod.${isEdit ? 'edit' : 'create'}.title` })}
      isShowing={open}
      hide={hide}
      withoutOverflow
    >
      <form onSubmit={handleSubmit}>
        <NewInput
          name="name"
          type="text"
          fullWidth
          label={intl.formatMessage({ id: 'openPeriod.form.name.label' })}
          placeholder={intl.formatMessage({ id: 'openPeriod.form.name.placeholder' })}
          {...inputProps(fields.name)}
        />
        <StyledDateRange
          names={{
            startDate: 'startDate',
            endDate: 'endDate'
          }}
          separation={0}
          gap="0.55rem"
          disabledKeyboardNavigation
          variant="new"
          disabled={isDatesDisabled}
          {...dateRangeProps}
        />
        <NewTextArea
          name="note"
          type="text"
          label={intl.formatMessage({ id: 'openPeriod.form.note.label' })}
          placeholder={intl.formatMessage({ id: 'openPeriod.form.note.placeholder' })}
          {...inputProps(fields.note)}
          rows={4}
        />
        {error && <AlertMessage>{error}</AlertMessage>}
        <ButtonsRow>
          <StyledButton
            id="cancelBtn"
            textIntlId="common.cancel"
            variant={BUTTON_VARIANTS.NEW_SECONDARY}
            isLoading={isLoading}
            onClick={() => hide()}
          />
          <StyledButton
            type="submit"
            id="saveBtn"
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
            isLoading={isLoading}
            textIntlId={`modal.openPeriod.${isEdit ? 'edit' : 'create'}.confirm`}
          />
        </ButtonsRow>
      </form>
    </NewModal>
  );
};

PeriodInfoModal.propTypes = {
  error: string,
  onSubmit: func,
  hide: func,
  isEdit: bool,
  isLoading: bool,
  open: bool,
  openPeriod: openPeriodShape,
  periodNumber: number,
  resetRequest: func,
  startDate: oneOfType([string, instanceOf(Date)]),
  isDatesDisabled: bool
};

export default PeriodInfoModal;
