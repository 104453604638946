import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0;
  font-family: ${({ theme }) => theme.fontFamily};
`;

export const Section = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;
  padding: 0 2.8rem 0.6rem 2.8rem;
`;

export const Section2 = styled(Section)`
  padding: 0.5rem 4rem 2rem 3rem;
`;

export const BenchFilter = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    border-bottom: 1px ${({ theme }) => theme.colors.gray30} solid;
    width: calc(100% + 5.6rem);
    right: -2.8rem;
    bottom: 0;
  }
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.gray100};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: 2.2rem;
  margin: 2rem 0;
`;
