import { arrayOf } from 'prop-types';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';

import Loading from 'components/common/Loading';
import { ReactComponent as MoreInfoSVG } from 'components/icons/more-info.svg';
import { UTILIZATION } from 'constants/dashboardConstants';
import { assignmentGraphShape } from 'constants/shapes';
import { useStatus, useDashboardAssignmentIndicators } from 'hooks';
import { OverviewItemContainer } from 'pages/Dashboard/Reports/styles';
import { getAssignmentSummaries } from 'state/actions/dashboardActions';
import { getXAxisFormatDate } from 'utils/date';

import GraphTooltip from './GraphTooltip';
import HelperModal from './HelperModal';
import LegendItem from './LegendItem';
import { GraphTitle, MoreInfo } from './styles';

const AssignmentGraph = ({ data }) => {
  const intl = useIntl();
  const { indicators } = useDashboardAssignmentIndicators();

  const { isPending: isAssignmentSummariesPending } = useStatus(getAssignmentSummaries);

  const [showHelp, setShowHelp] = useState(false);

  if (isAssignmentSummariesPending)
    return (
      <OverviewItemContainer>
        <Loading />
      </OverviewItemContainer>
    );

  return (
    <OverviewItemContainer style={{ flex: '0 0 100%' }}>
      <MoreInfo onClick={() => setShowHelp(true)}>
        <MoreInfoSVG />
      </MoreInfo>
      <GraphTitle>
        <FormattedMessage id="dashboard.assignmentGraph" />
      </GraphTitle>
      <ResponsiveContainer minHeight={350} minWidth={500}>
        <AreaChart data={data} margin={{ right: 40 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tickFormatter={tickItem => getXAxisFormatDate(tickItem)} />
          <YAxis label={{ value: 'Hours', position: 'top', offset: 20 }} />
          <Legend
            wrapperStyle={{ marginLeft: '4.8rem' }}
            verticalAlign="top"
            iconType="circle"
            iconSize={16}
            formatter={name => (
              <LegendItem name={intl.formatMessage({ id: UTILIZATION[name].intl })} />
            )}
          />
          <Tooltip content={GraphTooltip} />
          {indicators
            .filter(({ enabled }) => enabled)
            .map(({ dataKey, color, stackId, unit, props }) => (
              <Area
                type="monotone"
                strokeWidth={3}
                fillOpacity={0.1}
                key={dataKey}
                dataKey={dataKey}
                stroke={color}
                fill={color}
                stackId={stackId}
                unit={unit}
                {...props}
              />
            ))}
        </AreaChart>
      </ResponsiveContainer>
      <HelperModal hide={() => setShowHelp(false)} isShowing={showHelp} />
    </OverviewItemContainer>
  );
};

AssignmentGraph.propTypes = {
  data: arrayOf(assignmentGraphShape).isRequired
};

export default AssignmentGraph;
