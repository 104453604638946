import { useIntl } from 'react-intl';

import { ITEM_TYPE } from 'constants/constants';
import {
  TOTAL_UTILIZATION_SORT,
  ASSIGNMENTS_SORT,
  BENCH_UTILIZATION_SORT,
  REAL_HOURS_SORT,
  BENCH_PENDING_SORT
} from 'utils/dashboard/column-sorting';

export default () => {
  const intl = useIntl();
  return {
    resourceData: {
      Header: intl.formatMessage({ id: 'common.resources' }),
      accessor: row => ({ name: row.name, role: row.role, avatarUrl: row.avatarUrl, id: row.id }),
      type: ITEM_TYPE.resource,
      disableFilters: true,
      disableSortBy: true,
      showCount: true,
      width: 170
    },

    workArea: {
      Header: intl.formatMessage({ id: 'common.area' }),
      accessor: row => row.area.replace(/ Team$/, ''),
      type: ITEM_TYPE.text,
      filter: 'includes',
      labelId: 'common.area',
      disableSortBy: true,
      width: 70
    },

    seniority: {
      Header: intl.formatMessage({ id: 'common.seniority' }),
      accessor: 'seniority',
      type: ITEM_TYPE.seniority,
      filter: 'includes',
      labelId: 'common.seniority',
      disableSortBy: true,
      width: 70
    },

    skills: {
      Header: intl.formatMessage({ id: 'common.skills' }),
      accessor: 'skills',
      type: ITEM_TYPE.skillsWithTooltip,
      filter: 'skills',
      labelId: 'common.skills',
      isSkill: true,
      disableSortBy: true
    },

    projects: {
      Header: intl.formatMessage({ id: 'common.projects' }),
      accessor: 'projects',
      type: ITEM_TYPE.textWithTooltip,
      labelId: 'common.projects',
      disableFilters: true,
      disableSortBy: true
    },

    totalUtilizationBar: {
      Header: intl.formatMessage({ id: 'dashboard.totalUtilization' }),
      accessor: ({ capacity, scheduled }) => ({
        capacity,
        scheduled
      }),
      type: ITEM_TYPE.utilizationBar,
      disableFilters: true,
      sortType: TOTAL_UTILIZATION_SORT,
      infoIntlId: 'info.totalUtilization',
      width: 120
    },

    assignments: {
      Header: intl.formatMessage({ id: 'dashboard.assignments' }),
      accessor: ({ capacity, billable }) => ({
        capacity,
        scheduled: billable
      }),
      type: ITEM_TYPE.utilizationBar,
      disableFilters: true,
      sortType: ASSIGNMENTS_SORT,
      infoIntlId: 'info.assignments',
      width: 120
    },

    benchBar: {
      Header: intl.formatMessage({ id: 'dashboard.benchHours' }),
      accessor: ({ capacity, benchHours, pending }) => ({
        total: capacity,
        current: benchHours,
        diff: pending
      }),
      type: ITEM_TYPE.diffBar,
      disableFilters: true,
      sortType: BENCH_UTILIZATION_SORT,
      infoIntlId: 'info.bench',
      width: 130
    },

    benchForecast: {
      Header: intl.formatMessage({ id: 'dashboard.benchForecast' }),
      accessor: ({ benchHours, capacity, pending }) => ({
        total: capacity,
        current: benchHours,
        diff: pending
      }),
      type: ITEM_TYPE.diffBar,
      disableFilters: true,
      sortType: BENCH_PENDING_SORT,
      infoIntlId: 'info.benchForecast',
      width: 130
    },

    harvestBillableHours: {
      Header: intl.formatMessage({ id: 'dashboard.harvestBillableHours' }),
      accessor: ({ scheduled, harvestHours }) => ({
        total: scheduled,
        value: harvestHours || 0
      }),
      type: ITEM_TYPE.percentage,
      disableFilters: true,
      sortType: REAL_HOURS_SORT,
      infoIntlId: 'info.realHours',
      width: 100
    },

    billableHours: {
      Header: intl.formatMessage({ id: 'common.billable' }),
      accessor: 'billable',
      type: ITEM_TYPE.hours,
      disableFilters: true,
      width: 50
    },

    nonBillableHours: {
      Header: intl.formatMessage({ id: 'common.nonBillable' }),
      accessor: 'nonBillable',
      type: ITEM_TYPE.hours,
      disableFilters: true,
      width: 50
    },

    benchHours: {
      Header: intl.formatMessage({ id: 'dashboard.benchHours' }),
      accessor: 'benchHours',
      type: ITEM_TYPE.hours,
      disableFilters: true,
      width: 50
    },

    capacity: {
      Header: intl.formatMessage({ id: 'dashboard.capacity' }),
      accessor: 'capacity',
      type: ITEM_TYPE.hours,
      disableFilters: true,
      width: 50
    },

    role: {
      Header: intl.formatMessage({ id: 'dashboard.role' }),
      accessor: 'role',
      type: ITEM_TYPE.label,
      filter: 'includes',
      labelId: 'common.role',
      disableSortBy: true,
      width: 100
    },

    resources: {
      Header: intl.formatMessage({ id: 'common.resources' }),
      accessor: 'totalResources',
      type: ITEM_TYPE.text,
      disableFilters: true,
      width: 100
    },

    skill: {
      Header: intl.formatMessage({ id: 'common.skills' }),
      accessor: 'skill',
      filter: 'includes',
      labelId: 'common.skills',
      type: ITEM_TYPE.label,
      disableSortBy: true
    },

    requested: {
      Header: intl.formatMessage({ id: 'dashboard.requested' }),
      accessor: 'requested',
      disableFilters: true,
      type: ITEM_TYPE.text
    }
  };
};
