import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { getCustomer } from 'state/actions/uiActions';
import { customerToAssignmentOption } from 'utils/assignmentFormsUtilities';

export default () => {
  const [customerOptions, setCustomerOptions] = useState([]);
  const getCustomerRequest = useDispatch(getCustomer);
  const {
    status: customerStatus,
    error: customerError,
    isPending: isCustomerPending
  } = useStatus(getCustomer);
  const clients = useSelector(({ ui }) => ui.options.clients);

  useEffect(() => {
    if (isEmpty(clients)) {
      getCustomerRequest();
    } else {
      setCustomerOptions(clients.map(customerToAssignmentOption));
    }
  }, [clients, getCustomerRequest]);

  return {
    clients,
    getCustomerRequest,
    customerStatus,
    customerError,
    customerOptions,
    isCustomerPending
  };
};
