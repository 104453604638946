import ExcelJS from 'exceljs';
import { isArray } from 'lodash';

import { capitalizeFirstLetter } from 'utils/searchPeopleUtilities';

export const exportToExcel = (headers, filename, rows) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('My Sheet');

  sheet.columns = headers;
  rows.map(row => sheet.addRow(row));

  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

export const formatField = (value, emptyValue = '-') => {
  if (typeof value === 'string') {
    return value || emptyValue;
  }

  if (typeof value === 'number') {
    return Math.round(value);
  }

  if (isArray(value) && value?.length) {
    return capitalizeFirstLetter(value.join(', '));
  }

  return emptyValue;
};
