import capitalize from 'lodash/capitalize';
import { object } from 'prop-types';

import ProjectList from 'components/common/ProjectList';
import ResourceDetail from 'components/common/ResourceDetail';
import SkillList from 'components/common/SkillList';
import { ITEM_TYPE } from 'constants/constants';

import DiffCell from './DiffCell';
import PercentageCell from './PercentageCell';
import { FlexStart } from './styles';
import UtilizationCell from './UtilizationCell';

const renderItem = (value, type) => {
  switch (type) {
    case ITEM_TYPE.text:
      return value || '-';
    case ITEM_TYPE.skillsWithTooltip: {
      return <SkillList skills={value} />;
    }
    case ITEM_TYPE.textWithTooltip: {
      return <ProjectList projects={value} />;
    }
    case ITEM_TYPE.resource: {
      return <ResourceDetail resource={value} />;
    }
    case ITEM_TYPE.utilizationBar: {
      return <UtilizationCell {...value} />;
    }
    case ITEM_TYPE.percentage: {
      return <PercentageCell {...value} />;
    }
    case ITEM_TYPE.diffBar: {
      return <DiffCell {...value} />;
    }
    case ITEM_TYPE.hours: {
      return `${value}hs`;
    }
    case ITEM_TYPE.seniority: {
      return value
        ? value
            .split('-')
            .map(word => capitalize(word))
            .join(' ')
        : '-';
    }
    case ITEM_TYPE.label:
      return <FlexStart>{value}</FlexStart>;
    default:
      return null;
  }
};

const TableItem = ({
  item: {
    value,
    getCellProps,
    column: { type, id }
  }
}) => (
  <td {...getCellProps()} className={id}>
    {renderItem(value, type)}
  </td>
);

TableItem.propTypes = {
  item: object.isRequired
};

export default TableItem;
