import styled, { css } from 'styled-components';

import { ReactComponent as ArrowUpRight } from 'assets/icons/arrow-up-right.svg';

const setPillColorsByStatus = status =>
  ({
    active: css`
      background-color: ${({ theme }) => theme.new.colors.brandPrimaryLight};
      color: ${({ theme }) => theme.new.colors.brandDark};
    `,
    bench: css`
      background-color: ${({ theme }) => theme.new.badgePrimaryFill};
      color: ${({ theme }) => theme.new.colors.badgePrimaryText};
    `
  }[status]);

export const StatusPill = styled.div`
  ${({ status }) => setPillColorsByStatus(status)};
  ${({ theme }) => theme.new.typography.p5SemiBold};
  border-radius: 16px;
  padding: 0.2rem 0.8rem;
`;

export const ArrowIcon = styled(ArrowUpRight)`
  justify-self: end;
`;

export const BasicInfo = styled.section`
  align-items: center;
  display: flex;
  gap: 2.735rem;

  & img {
    margin: 0;
  }
`;

export const BasicInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & h3 {
    ${({ theme }) => theme.new.typography.p3Bold};
    color: ${({ theme }) => theme.new.colors.neutral900};
  }

  & p {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme }) => theme.new.colors.neutral900};
  }
`;

export const Location = styled.span`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.neutral700};
`;

export const Grid = styled.div`
  background: ${({ theme }) => theme.new.colors.neutral100};
  border-radius: 4px;
  border-style: solid solid none solid;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  width: 100%;
`;

export const Row = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  padding: 0.9rem 3.3rem 0.9rem 2.4rem;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.new.colors.white};
    box-shadow: ${({ theme }) => theme.new.shadows.large.boxShadow};
    & h3 {
      text-decoration: underline;
    }
  }
  cursor: pointer;
  text-decoration: none;
  &:last-child {
    border-radius: inherit 4px;
    border-style: none;
  }
`;

export const Header = styled.div`
  ${({ theme }) => theme.new.typography.p6SemiBold};
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  color: ${({ theme }) => theme.new.colors.neutral700};
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  height: 4.4rem;
  padding: 0.9rem 3.3rem 0.9rem 2.4rem;
  width: 100%;
`;

export const StatusColumn = styled.div`
  align-items: center;
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const Title = styled.div`
  ${({ theme }) => theme.new.typography.p3SemiBold};
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  color: ${({ theme }) => theme.new.colors.neutral900};
  display: flex;
  height: 6.5rem;
  padding-left: 2.4rem;
  width: 100%;
`;

export const Rows = styled.div`
  height: 100%;
  width: 100%;
`;
