import PropTypes from 'prop-types';

import { CardContainer } from './styles';

const Card = ({ children, variant = 'primary' }) => (
  <CardContainer variant={variant}>{children}</CardContainer>
);
Card.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string
};

export default Card;
