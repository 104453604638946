import { useIntl } from 'react-intl';

import { Container, Title } from './styles';

const HeaderNotification = () => {
  const intl = useIntl();

  return (
    <Container>
      <Title>{intl.formatMessage({ id: 'common.notifications' })}</Title>
    </Container>
  );
};

export default HeaderNotification;
