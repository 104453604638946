import dayjs from 'dayjs';
import { arrayOf } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as DownArrow } from 'assets/icons/corner-down-right.svg';
import { ReactComponent as LeaveIcon } from 'assets/icons/leave-resource.svg';
import { ReactComponent as NewResourceIcon } from 'assets/icons/new-resource.svg';
import Checkbox from 'components/common/Checkbox';
import EmptyValidator from 'components/Financial/EmptyValidator';
import {
  Container,
  Header,
  Count,
  Label,
  ContainerScroll
} from 'components/Financial/Layout/Tables';
import { resourcesSetupShape } from 'constants/shapes';
import { getFormatDateStandard, isAfter } from 'utils/date';

import { StyledColumn, StyledRow, UpdatedInfo, UpdatedLabel } from './styles';

const minWidth = '25rem';
const dateNow = dayjs();

function ResourcesTable({ resources }) {
  const intl = useIntl();
  return (
    <Container border>
      <Header height="6rem">
        <StyledColumn minWidth={minWidth} firstColumn>
          <Label>
            {intl.formatMessage({ id: 'common.resources' })}
            <Count>{`(${resources?.length})`}</Count>
          </Label>
        </StyledColumn>
        <StyledColumn>{intl.formatMessage({ id: 'common.seniority' })}</StyledColumn>
        <StyledColumn>{intl.formatMessage({ id: 'common.nonBillable' })}</StyledColumn>
        <StyledColumn>{intl.formatMessage({ id: 'common.allocation' })}</StyledColumn>
        <StyledColumn>{intl.formatMessage({ id: 'common.rate' })}</StyledColumn>
        <StyledColumn>{intl.formatMessage({ id: 'common.cap.startDate' })}</StyledColumn>
        <StyledColumn>{intl.formatMessage({ id: 'common.cap.endDate' })}</StyledColumn>
      </Header>
      <ContainerScroll maxHeight="18rem">
        {resources?.map(resource => {
          if (resource?.current) {
            const { current, updated } = resource;
            const isIncomingUpdate = isAfter(updated?.startDate, dateNow);
            const { startDate } = current;
            return (
              <>
                <StyledRow key={current.assignmentId}>
                  <StyledColumn minWidth={minWidth} firstColumn isBlack>
                    {current.resourceName}
                  </StyledColumn>
                  <StyledColumn>
                    <EmptyValidator>{current.seniority}</EmptyValidator>
                  </StyledColumn>
                  <StyledColumn>
                    <Checkbox value={!current.billable} disabled variant="new" />
                  </StyledColumn>
                  <StyledColumn>{current.allocationPercentage}%</StyledColumn>
                  <StyledColumn>{current.rate ? `$${current.rate}` : '--'}</StyledColumn>
                  <StyledColumn>{getFormatDateStandard(current.startDate)}</StyledColumn>
                  <StyledColumn>{getFormatDateStandard(current.endDate)}</StyledColumn>
                </StyledRow>
                <StyledRow key={`${current.assignmentId}-updated`} gray>
                  <StyledColumn minWidth={minWidth} firstColumn>
                    <DownArrow />
                    <UpdatedInfo>
                      <UpdatedLabel strong={isIncomingUpdate} isBlack>
                        {intl.formatMessage({
                          id: `financial.updateResources.modal.${
                            isIncomingUpdate ? 'incomingUpdate' : 'updatingTo'
                          }`
                        })}
                      </UpdatedLabel>
                      <span>
                        {isIncomingUpdate &&
                          intl.formatMessage({ id: 'template.effectiveDate' }, { startDate })}
                      </span>
                    </UpdatedInfo>
                  </StyledColumn>
                  <StyledColumn isBlue={updated.seniority !== current.seniority}>
                    <EmptyValidator>{updated.seniority}</EmptyValidator>
                  </StyledColumn>
                  <StyledColumn>
                    <Checkbox value={!updated.billable} disabled variant="new" />
                  </StyledColumn>
                  <StyledColumn
                    isBlue={updated.allocationPercentage !== current.allocationPercentage}
                  >
                    {updated.allocationPercentage}%
                  </StyledColumn>
                  <StyledColumn isBlue={updated.rate !== current.rate}>
                    {updated.rate ? `$${updated.rate}` : '--'}
                  </StyledColumn>
                  <StyledColumn isBlue={updated.startDate !== current.startDate}>
                    {getFormatDateStandard(updated.startDate)}
                  </StyledColumn>
                  <StyledColumn isBlue={updated.endDate !== current.endDate}>
                    {getFormatDateStandard(updated.endDate)}
                  </StyledColumn>
                </StyledRow>
              </>
            );
          }
          const {
            setupId,
            resourceName,
            seniority,
            billable,
            allocationPercentage,
            rate,
            isAdd,
            isRemoved,
            startDate,
            endDate
          } = resource;

          return (
            <StyledRow key={setupId}>
              <StyledColumn strong minWidth={minWidth} firstColumn>
                {isAdd && <NewResourceIcon />}
                {isRemoved && <LeaveIcon />}
                {resourceName}
              </StyledColumn>
              <StyledColumn>
                <EmptyValidator>{seniority}</EmptyValidator>
              </StyledColumn>
              <StyledColumn>
                <Checkbox value={!billable} disabled variant="new" />
              </StyledColumn>
              <StyledColumn>{allocationPercentage}%</StyledColumn>
              <StyledColumn>{rate ? `$${rate}` : '--'}</StyledColumn>
              <StyledColumn>{getFormatDateStandard(startDate)}</StyledColumn>
              <StyledColumn>{getFormatDateStandard(endDate)}</StyledColumn>
            </StyledRow>
          );
        })}
      </ContainerScroll>
    </Container>
  );
}

ResourcesTable.propTypes = {
  resources: arrayOf(resourcesSetupShape)
};

export default ResourcesTable;
