import { isEmpty } from 'lodash';
import { arrayOf, shape, string, func, oneOf, bool } from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { ReactComponent as SmallThreeDotsSvg } from 'components/icons/small-three-dots.svg';
import { ReactComponent as ThreeDotsSvg } from 'components/icons/three-dots.svg';
import { POSITIONS_TYPES, THREE_DOTS_VARIANTS } from 'constants/constants';
import { useOutsideClick } from 'hooks';

import { RoundButton, Button, OptionsContainer, Container } from './styles';

const threeDotSvg = {
  [THREE_DOTS_VARIANTS.BIG]: <ThreeDotsSvg />,
  [THREE_DOTS_VARIANTS.SMALL]: <SmallThreeDotsSvg />
};

const OptionsButton = ({
  options = [],
  isTimelineView,
  threeDotVariant = THREE_DOTS_VARIANTS.BIG,
  className
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [bottomPosition, setBottomPositon] = useState(0);
  const { handleBlur, handleFocus } = useOutsideClick(() => setShowOptions(false));
  const optionsRef = useRef(null);

  const handleOpen = e => {
    e.stopPropagation();
    setShowOptions(!showOptions);
  };

  const availableOptions = !isEmpty(options.filter(option => !isEmpty(option)));

  const position = isTimelineView
    ? [POSITIONS_TYPES.RIGHT, POSITIONS_TYPES.TOP_RIGHT]
    : [POSITIONS_TYPES.BOTTOM_LEFT, POSITIONS_TYPES.TOP_LEFT];

  useEffect(() => {
    setBottomPositon(optionsRef?.current?.getBoundingClientRect().bottom || 0);
  }, [showOptions]);

  return (
    <Container onFocus={handleFocus} onBlur={handleBlur} className={className}>
      {availableOptions && (
        <RoundButton onClick={handleOpen} threeDotVariant={threeDotVariant}>
          {threeDotSvg[threeDotVariant]}
        </RoundButton>
      )}
      {showOptions && (
        <OptionsContainer
          ref={optionsRef}
          $position={window.innerHeight - bottomPosition > 0 ? position[0] : position[1]}
          visible={optionsRef?.current}
        >
          {options.map(
            ({ id, label, onClick, icon }, index) =>
              label && (
                <Button
                  isLastOption={index === options?.length - 1 && options?.length > 1}
                  id={id}
                  key={label}
                  onClick={e => {
                    e.stopPropagation();
                    setShowOptions(false);
                    onClick();
                  }}
                >
                  {icon}
                  {label}
                </Button>
              )
          )}
        </OptionsContainer>
      )}
    </Container>
  );
};

OptionsButton.propTypes = {
  options: arrayOf(
    shape({
      label: string.isRequired,
      onClick: func.isRequired
    })
  ),
  isTimelineView: bool,
  threeDotVariant: oneOf(Object.values(THREE_DOTS_VARIANTS)),
  className: string
};

export default OptionsButton;
