import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { getFilterQuery, getFiltersResourcesQuery } from './helpers';
import { filterList } from './reducersUtilities';
import { parseAvailabilityRange, isActive, isInRange } from './searchPeopleUtilities';

const getResourceFilter = (filter, resources) => (isEmpty(filter) ? resources : []);

/**
 * @param {{resourceFilters: object, people: object[]}} state resources filters and list of resources
 * @param {{options: object, key: string, onlyMainSkills :bool}} payload filters options
 * @returns {{resourcesResult: object[], newResourceFilters: object[], resourcesFiltersOptionsQuery: object[] }}
 */
export const filtersResources = (state, payload) => {
  const { options, key, onlyMainSkills: onlyMainSkillsParam } = payload;
  const { resourcesTimeline, people } = state;
  const resourceFilters = resourcesTimeline.filters;
  const onlyMainSkills = onlyMainSkillsParam ?? resourceFilters?.onlyMainSkills;

  const newResourceFilters = cloneDeep(resourceFilters);
  newResourceFilters[key] = options;
  const filterQuery = getFilterQuery(newResourceFilters);
  const { locations, departments, skills, seniorities, availabilityRanges, roles, englishLevel } =
    filterQuery;
  let filterLocation = [];
  let filterDepartment = [];
  let filterSkills = [];
  let filterSeniority = [];
  let filterAvailability = [];
  let filterRole = [];
  let filterEnglishLevel = [];

  people.forEach(resource => {
    const { location: locationResource } = resource;
    if (!isEmpty(locations)) {
      locations.forEach(location => {
        const { country } = locationResource;
        country === location && filterLocation.push(resource);
      });
    }
  });

  filterLocation = !isEmpty(filterLocation) ? filterLocation : getResourceFilter(locations, people);

  filterLocation.forEach(resource => {
    const { department: departmentResource } = resource;
    if (!isEmpty(departments)) {
      departments.forEach(department => {
        departmentResource?.name === department && filterDepartment.push(resource);
      });
    }
  });

  filterDepartment = !isEmpty(filterDepartment)
    ? filterDepartment
    : getResourceFilter(departments, filterLocation);

  filterDepartment.forEach(resource => {
    const { experiences } = resource;
    if (!isEmpty(skills)) {
      experiences.map(({ skill, highlighted }) => {
        skills.forEach(skillFilter => {
          if (skillFilter === skill?.name) {
            if (!onlyMainSkills || highlighted) {
              filterSkills.push(resource);
            }
          }
        });
      });
    }
  });

  filterSkills = !isEmpty(filterSkills)
    ? filterSkills
    : getResourceFilter(skills, filterDepartment);

  filterSkills.forEach(resource => {
    const { seniority: seniorityResource } = resource;
    if (!isEmpty(seniorities)) {
      seniorities.forEach(seniorityOption => {
        seniorityResource === seniorityOption && filterSeniority.push(resource);
      });
    }
  });

  filterSeniority = !isEmpty(filterSeniority)
    ? filterSeniority
    : getResourceFilter(seniorities, filterSkills);

  filterSeniority.forEach(resource => {
    const { availability: availabilityResource } = resource;
    if (!isEmpty(availabilityRanges)) {
      availabilityRanges.forEach(availability => {
        const range = availability.split('-');
        const { busyPercentage } = availabilityResource[0]?.value;
        let availabilityPercentage = 100 - busyPercentage;
        availabilityPercentage = availabilityPercentage > 0 ? availabilityPercentage : 0;

        availabilityPercentage >= parseFloat(range[0]) &&
          availabilityPercentage <= parseFloat(range[1]) &&
          filterAvailability.push(resource);
      });
    }
  });

  filterAvailability = !isEmpty(filterAvailability)
    ? filterAvailability
    : getResourceFilter(availabilityRanges, filterSeniority);

  filterAvailability.forEach(resource => {
    const { role: roleResource } = resource;
    if (!isEmpty(roles)) {
      roles.forEach(roleOption => {
        roleResource?.name === roleOption && filterRole.push(resource);
      });
    }
  });

  filterRole = !isEmpty(filterRole) ? filterRole : getResourceFilter(roles, filterAvailability);

  filterAvailability.forEach(resource => {
    const { englishLevel: englishLevelResource } = resource;
    if (!isEmpty(englishLevel)) {
      englishLevel.forEach(englishLevelOption => {
        englishLevelResource === englishLevelOption && filterEnglishLevel.push(resource);
      });
    }
  });

  filterEnglishLevel = !isEmpty(filterEnglishLevel)
    ? filterEnglishLevel
    : getResourceFilter(englishLevel, filterRole);

  const filterName = filterList(filterEnglishLevel, newResourceFilters.name, item => item.fullName);

  const resourcesResult = compact(uniq(flatten(filterName)));
  const resourcesFiltersOptionsQuery = getFiltersResourcesQuery({ resources: resourcesResult });

  return { resourcesResult, newResourceFilters, resourcesFiltersOptionsQuery };
};

export const getActiveData = filterData =>
  filterData.filter(item => item.value).map(item => item.key);

export const applyFilters = (resources, filters) => {
  const { roles, seniority, skills, englishLevel, availability, name } = filters;

  const activeRoles = getActiveData(roles);
  const activeSeniorities = getActiveData(seniority);
  const activeSkills = getActiveData(skills);
  const activeEnglishLevels = getActiveData(englishLevel);
  const activeAvailabilityRanges = availability
    .filter(item => item.value)
    .map(item => parseAvailabilityRange(item.key));

  return resources.filter(resource => {
    const matchesSkills =
      activeSkills.length === 0 ||
      resource.mainSkills.some(skill => activeSkills.includes(skill.skillName));
    const matchesAvailability =
      activeAvailabilityRanges.length === 0 ||
      activeAvailabilityRanges.some(range => isInRange(resource.availablePercentage, range));

    return (
      isActive(activeRoles, resource.mainInfo.role.name) &&
      isActive(activeSeniorities, resource.seniority) &&
      matchesSkills &&
      matchesAvailability &&
      isActive(activeEnglishLevels, resource.english_level) &&
      (!name || resource.mainInfo?.fullName?.toLowerCase().includes(name.toLowerCase()))
    );
  });
};

export const getActiveFilters = selectedFilters => {
  const activeFilters = [];
  Object.keys(selectedFilters).forEach(key => {
    if (Array.isArray(selectedFilters[key])) {
      selectedFilters[key].forEach(filter => {
        if (filter.value) {
          activeFilters.push(filter);
        }
      });
    }
  });

  return activeFilters;
};

export const resetFilters = data => (data ? data.map(option => ({ ...option, value: false })) : []);

export const filterItemsByProperties = (items, filters) => {
  if (!items?.length) return [];

  const filterValues = {
    status: filters.projectStatus.filter(item => item.value).map(item => item.key),
    assignmentsValues: filters.assignmentStatus.filter(item => item.value).map(item => item.key),
    projectType: filters.serviceType.filter(item => item.value).map(item => item.key)
  };

  const newItems = items.map(item => ({
    ...item,
    assignmentsValues: Object.keys(item.assignments).filter(
      property => item.assignments[property] > 0
    )
  }));

  let filterItems = items;

  if (
    filterValues.status.length ||
    filterValues.assignmentsValues.length ||
    filterValues.projectType.length
  ) {
    filterItems = newItems.filter(item =>
      Object.keys(filterValues).every(property => {
        const filterOptions = filterValues[property];

        if (filterOptions.length) {
          if (Array.isArray(item[property])) {
            return filterOptions.some(option => item[property].includes(option));
          }
          return filterOptions.includes(item[property]);
        }
        return true;
      })
    );
  }

  return filters.hideFinished
    ? filterItems.filter(item => item.status !== 'finished')
    : filterItems;
};

export const compareResources = (a, b) =>
  b.matchOverall - a.matchOverall ||
  b.margin - a.margin ||
  a.mainInfo.fullName.localeCompare(b.fullName);

export const sortOptions = (options, key = 'label') =>
  options?.length ? [...options].sort((a, b) => a[key].localeCompare(b[key])) : options;
