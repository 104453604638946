import styled from 'styled-components';

import { ReactComponent as EditNote } from 'assets/icons/edit-note.svg';

import Tooltip from '../Tooltip';

export const StyledTooltip = styled(Tooltip)`
  padding: 1.1rem 1.3rem 1.7rem !important;
  cursor: default;
  min-width: 20rem;
  max-width: 50rem;
`;

export const NoteTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  & h4 {
    ${({ theme }) => theme.typography.medium}
    color: ${({ theme }) => theme.fontColor};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

export const NoteBody = styled.p`
  ${({ theme }) => theme.typography.regular}
  line-height: 1.6rem;
  min-height: 2rem;
  max-height: 15rem;
  overflow-y: auto;
  white-space: pre-wrap;
`;

export const EditNoteSvg = styled(EditNote)`
  cursor: pointer;
`;
