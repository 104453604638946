import { ExpandMore } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import styled from 'styled-components';

export const Accordion = styled(MuiAccordion)`
  box-shadow: none !important;
  font-family: ${({ theme }) => theme.new.fontFamily};
  width: 100%;

  &:before {
    display: none !important;
  }

  &.Mui-expanded {
    margin: 0 !important;
  }
`;

export const Section = styled.div`
  align-items: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.new.colors.P100};
  color: ${({ theme }) => theme.new.colors.P800};
  display: flex;
  font-size: 1.6rem;
  height: 3.2rem;
  justify-content: center;
  margin-right: 2.4rem;
  width: 3.2rem;
`;

export const AccordionTitle = styled.h4`
  font-size: ${({ theme }) => theme.new.typography.h3};
`;

export const AccordionOptional = styled.span`
  color: ${({ theme }) => theme.new.colors.N500};
`;

export const ExpandIcon = styled(ExpandMore)`
  &.MuiSvgIcon-root {
    height: 2.4rem;
    width: 2.4rem;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  &,
  &.Mui-expanded {
    min-height: auto !important;
    padding: 0 !important;
  }

  & .MuiAccordionSummary-content {
    align-items: center;
    display: flex;
    margin: 0.8rem 0 !important;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding-left: 5.8rem !important;
  padding-right: 0 !important;
`;
