import { arrayOf } from 'prop-types';
import { useIntl } from 'react-intl';

import Tooltip from 'components/common/Tooltip';
import { resourceBenchBreakdownShape } from 'constants/shapes';

import {
  Container,
  Header,
  Column,
  Row,
  Person,
  PersonInfo,
  PersonName,
  TruncateText,
  ContainerScroll
} from './styles';

const minWidth = '23rem';
const MAX_SKILLS_LENGTH = 45;

function ResourcesTable({ resources }) {
  const intl = useIntl();

  return (
    <Container>
      <Header>
        <Column minWidth={minWidth} textAlign="left" header strong>
          {intl.formatMessage({ id: 'common.resource' })}
        </Column>
        <Column header strong>
          {intl.formatMessage({ id: 'common.department' })}
        </Column>
        <Column header strong>
          {intl.formatMessage({ id: 'common.mainSkill' })}
        </Column>
        <Column header strong>
          {intl.formatMessage({ id: 'dashboard.breakdown.table.amountDays' })}
        </Column>
      </Header>
      <ContainerScroll>
        {resources.map(resource => {
          const skillNames = resource.highlightedSkills.map(skill => skill.name).join(', ');
          const isShowTooltip = skillNames.length > MAX_SKILLS_LENGTH;

          return (
            <Row key={resource.id}>
              <Column minWidth={minWidth} textAlign="left">
                <Person>
                  <PersonInfo>
                    <PersonName>{resource.fullName}</PersonName>
                  </PersonInfo>
                </Person>
              </Column>
              <Column>{resource.department.name}</Column>
              <Column>
                <TruncateText data-tip data-for={`skill-tooltip-${resource.id}}`}>
                  {resource.highlightedSkills.length > 0 ? skillNames : '-'}
                </TruncateText>
                {isShowTooltip && (
                  <Tooltip
                    id={`skill-tooltip-${resource.id}}`}
                    place="top"
                    type="light"
                    effect="float"
                  >
                    <div>
                      <span>{skillNames || '-'}</span>
                    </div>
                  </Tooltip>
                )}
              </Column>
              <Column>{resource.availableFor}</Column>
            </Row>
          );
        })}
      </ContainerScroll>
    </Container>
  );
}

ResourcesTable.propTypes = {
  resources: arrayOf(resourceBenchBreakdownShape)
};

export default ResourcesTable;
