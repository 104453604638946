import { DASHBOARD_DATE_TYPES } from 'constants/dashboardConstants';
import {
  DISPLAY_MONTH_DATE,
  DISPLAY_QUARTER_DATE,
  DISPLAY_WEEK_DATE,
  DISPLAY_YEAR_DATE
} from 'constants/dateFormats';

export const datePickerReducer = (_state, action) => {
  switch (action.type) {
    case DASHBOARD_DATE_TYPES.week.value: {
      return {
        dateFormat: DISPLAY_WEEK_DATE,
        showWeekNumbers: true,
        openToDate: action.startDate
      };
    }
    case DASHBOARD_DATE_TYPES.month.value: {
      return {
        showMonthYearPicker: true,
        dateFormat: DISPLAY_MONTH_DATE
      };
    }
    case DASHBOARD_DATE_TYPES.quarter.value: {
      return {
        showQuarterYearPicker: true,
        dateFormat: DISPLAY_QUARTER_DATE
      };
    }
    case DASHBOARD_DATE_TYPES.year.value: {
      return {
        showYearPicker: true,
        dateFormat: DISPLAY_YEAR_DATE
      };
    }
    default:
      throw new Error('action not supported');
  }
};
