import { bool, func, node, number, oneOfType, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { NUMERIC_FORMAT_PROPS } from 'constants/constants';
import { toCurrency } from 'utils/number';

import { Input, Span } from './styles';

const InlineTextEditor = ({
  value,
  symbol,
  onChange,
  disabled,
  isAllowed,
  onBlur,
  autofocus = true,
  showInput = false,
  CustomInput,
  priceWithDiscount,
  padding,
  width,
  isDiscount,
  className
}) => {
  const [editing, setEditing] = useState(false);
  const [inputText, setInputText] = useState(value);

  const handleClick = () => {
    !disabled && setEditing(true);
  };

  const handleChange = event => {
    const { value } = event.target;
    setInputText(value);
    onChange && onChange(isDiscount && value > 100 ? 100 : value);
  };

  const handleBlur = event => {
    onBlur && onBlur(event.target.value);
    setInputText(value);
    setEditing(false);
  };

  const onKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  };

  const handleText = () => {
    if (!inputText) return '-';
    const formattedInputText = toCurrency(inputText, {
      style: 'decimal',
      maximumFractionDigits: 2
    });
    if (!symbol) return formattedInputText;
    if (symbol === '$') return `$${formattedInputText}`;
    return `${formattedInputText}${symbol}`;
  };

  useEffect(() => {
    setInputText(value);
  }, [value]);

  return (
    <>
      {editing || showInput ? (
        <NumericFormat
          customInput={CustomInput || Input}
          type="text"
          value={inputText}
          isAllowed={isAllowed}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          autoFocus={autofocus}
          className={className}
          {...NUMERIC_FORMAT_PROPS}
        />
      ) : (
        <Span onClick={handleClick} padding={padding} width={width}>
          {priceWithDiscount >= 0 && priceWithDiscount <= inputText ? (
            <>
              <s>{handleText()}</s> {`$${priceWithDiscount}`}
            </>
          ) : (
            handleText()
          )}
        </Span>
      )}
    </>
  );
};

InlineTextEditor.propTypes = {
  disabled: bool,
  isAllowed: func,
  onBlur: func,
  onChange: func,
  padding: oneOfType([string, number]),
  priceWithDiscount: oneOfType([string, number]),
  symbol: string,
  value: oneOfType([string, number]),
  autofocus: bool,
  showInput: bool,
  CustomInput: node,
  width: oneOfType([string, number]),
  isDiscount: bool,
  className: string
};

export default InlineTextEditor;
