import styled from 'styled-components';

import { ReactComponent as peopleIcon } from 'assets/icons/people.svg';
import { Column, Row } from 'components/Financial/Layout/Tables';

import EmptyState from '../EmptyState';

export const StyledColumn = styled(Column)`
  align-items: center;
  background-color: ${({ theme, isEditing }) => isEditing && theme.new.colors.white};
  border-right: ${({ theme, separator }) => separator && `2px ${theme.new.colors.brand} solid`};
  color: ${({ theme, isBlack }) => isBlack && theme.new.colors.neutral800};
  color: ${({ theme, isBlue }) => isBlue && theme.new.colors.brand};
  gap: 1rem;

  &:hover {
    background-color: ${({ theme, isEditing }) => isEditing && theme.new.colors.neutral100};
  }
`;

export const StyledPeopleIcon = styled(peopleIcon)`
  height: 1.8rem;
  width: 2.2rem;

  path {
    stroke: ${({ theme }) => theme.new.colors.brand};
  }
`;

export const UpdatedLabel = styled.span`
  color: ${({ theme, isBlack }) => isBlack && theme.new.colors.neutral800};
  ${({ theme, strong }) => strong && theme.new.typography.p5SemiBold};
`;

export const UpdatedInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
export const StyledEmptyState = styled(EmptyState)`
  border-radius-bottom-left: 4px;
  border-radius: 0;
`;

export const StyledRow = styled(Row)`
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
  }
`;
