import isEmpty from 'lodash/isEmpty';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useDispatch, useStatus } from 'hooks';
import { getIndustries } from 'state/actions/uiActions';
import { industriesToOption } from 'utils/assignmentFormsUtilities';

export default () => {
  const [industriesOptions, setIndustriesOptions] = useState([]);
  const getIndustriesRequest = useDispatch(getIndustries);
  const { status: industriesStatus, error: industriesError } = useStatus(getIndustries);
  const industries = useSelector(({ ui }) => ui.options.industries);

  useEffect(() => {
    if (isEmpty(industries)) {
      getIndustriesRequest();
    } else {
      setIndustriesOptions(industries.map(industriesToOption));
    }
  }, [industries, getIndustriesRequest]);

  return {
    industries,
    getIndustriesRequest,
    industriesStatus,
    industriesError,
    industriesOptions
  };
};
