import { createAction } from '@reduxjs/toolkit';

import notificationService from 'services/notificationService';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const updateNotificationMarkRead = createAction('UPDATE_NOTIFICATION_MARK_READ');
export const updateNotificationMarkSeen = createAction('UPDATE_NOTIFICATION_MARK_SEEN');

export const getNotificationHeader = createAsyncThunk('GET_NOTIFICATION_HEADER', async () => {
  try {
    const { data } = await notificationService.getNotificationsHeader();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const markNotificationAsRead = createAsyncThunk(
  'MARK_NOTIFICATION_AS_READ',
  async ({ notificationId }) => {
    try {
      const { data } = await notificationService.markNotificationAsRead({ notificationId });
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const markNotificationAsSeen = createAsyncThunk('MARK_NOTIFICATION_AS_SEEN', async () => {
  try {
    const { data } = await notificationService.markNotificationAsSeen();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const deleteNotification = createAsyncThunk('DELETE_NOTIFICATION', async notificationId => {
  try {
    await notificationService.deleteNotification(notificationId);
    return notificationId;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const acknowledgeNotification = createAsyncThunk(
  'ACKNOWLEDGE_NOTIFICATION',
  async notificationId => {
    try {
      await notificationService.acknowledgeNotification(notificationId);
      return notificationId;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);
