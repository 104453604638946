import styled from 'styled-components';

export const ContainerGraphTooltip = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11),
    0px 4px 8px 0px rgba(79, 94, 113, 0.1);
  border-radius: 4px;
  min-width: 20rem;
  padding: 1.6rem;
`;

export const DataContainer = styled.div`
  color: ${({ theme }) => theme.fontColor};
  display: grid;
  grid-template-columns: auto auto;
`;

export const Header = styled.h3`
  ${({ theme }) => theme.new.typography.p5SemiBold};

  color: ${({ theme }) => theme.new.colors.P800};
  margin-bottom: 1.4rem;
`;

export const ItemKey = styled.p`
  ${({ theme }) => theme.new.typography.p6};

  align-items: center;
  display: flex;
  grid-column-start: 1;

  &::before {
    background-color: ${({ color }) => color || 'black'};
    border-radius: 7.5px;
    content: '';
    display: inline-block;
    height: 0.9rem;
    margin-right: 0.5rem;
    width: 0.9rem;
  }
`;
export const ItemValue = styled.div`
  ${({ theme }) => theme.new.typography.p6SemiBold};

  grid-column-start: 2;
  justify-self: end;
`;
