import styled from 'styled-components';

export const Header = styled.div`
  align-items: center;
  align-self: start;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  padding-bottom: 2.5rem;
  width: 100%;

  h1 {
    ${({ theme }) => theme.new.typography.h1SemiBold};
  }

  h1,
  h2 {
    ${({ theme }) => theme.new.typography.h3};
    line-height: 4rem;
    color: ${({ theme }) => theme.new.colors.N900};
    font-size: 3.2rem;
  }
`;
export const VerticalLine = styled.div`
  background: ${({ theme }) => theme.new.colors.N400};
  height: 4rem;
  width: 1px;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  gap: 1.6rem;
`;
