import { string } from 'prop-types';
import { useState } from 'react';

import Tooltip from 'components/common/Tooltip';

import { Container, Projects } from './styles';

const ProjectList = ({ projects }) => {
  const [showProjectTooltip, setShowProjectTooltip] = useState(false);

  return (
    <>
      <Container
        data-tip
        data-for="projects-tooltip"
        onMouseEnter={() => setShowProjectTooltip(true)}
        onMouseLeave={() => setShowProjectTooltip(false)}
      >
        {projects}
      </Container>
      {showProjectTooltip && (
        <Tooltip id="projects-tooltip" place="top" type="light" effect="float">
          <Projects>{projects}</Projects>
        </Tooltip>
      )}
    </>
  );
};

ProjectList.propTypes = {
  projects: string
};

export default ProjectList;
