import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 1.3rem 0 1.3rem;
  align-items: center;
  justify-content: center;
  width: 0.7rem;
`;

export const Dot = styled.div`
  background: ${({ theme, color }) => color || theme.colors.black};
  width: 0.7rem;
  height: 0.7rem;
  margin-bottom: 1px;
  border-radius: 50%;
`;
