import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';

import LoadingWrapper from 'components/common/LoadingWrapper';
import { useRequest } from 'hooks';
import {
  getDashboardBenchBreakdownSkills,
  getDashboardBenchBreakdownTable
} from 'state/actions/dashboardActions';

import AvailableCapacityGraph from './AvailableCapacityGraph';
import AvgOnBench from './AvgOnBench';
import Filter from './Filter';
import ResourcesTable from './ResourcesTable';
import { Container, Section, BenchFilter, Title, Section2 } from './styles';

const initialFilters = {
  departments: []
};

const daysOnBench = 25;

function BenchBreakdown() {
  const intl = useIntl();
  const [resources, setResources] = useState([]);
  const [resourcesFilter, setResourcesFilter] = useState([]);
  const [resourcesFilterTable, setResourcesFilterTable] = useState([]);
  const [skills, setSkills] = useState([]);
  const [days, setDays] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [{ isPending: isResourcesPending }] = useRequest(getDashboardBenchBreakdownTable, {
    fulfilledCallback: payload => setResources(payload.resources)
  });
  const [{ isPending: isSkillsPending }] = useRequest(
    getDashboardBenchBreakdownSkills,
    {
      fulfilledCallback: payload =>
        setSkills(
          payload.skills
            .filter(skill => Boolean(skill.peopleTotal))
            .sort((a, b) => {
              if (a.percentage >= b.percentage) return -1;
              return 1;
            })
        )
    },
    true
  );

  const isLoading = isResourcesPending || isSkillsPending;

  const filterResources = useCallback(() => {
    const departmentsIds = filters.departments
      .filter(department => department.value)
      .map(({ id }) => id);

    const resourcesByDepartments =
      departmentsIds.length > 0
        ? resources.filter(resource => departmentsIds.includes(resource.department.id))
        : resources;

    const filterTable = resourcesByDepartments
      .filter(resource => resource.availableFor > daysOnBench)
      .sort((a, b) => {
        if (a.availableFor < b.availableFor) return 1;
        if (a.availableFor > b.availableFor) return -1;
        return 0;
      });
    setResourcesFilter(resourcesByDepartments);
    setResourcesFilterTable(filterTable);
  }, [filters.departments, resources]);

  const calculateAvg = useCallback(() => {
    if (resourcesFilter.length) {
      const sum = resourcesFilter.reduce(
        (accumulator, actual) => accumulator + actual.availableFor,
        0
      );

      setDays(Math.round(sum / resourcesFilter.length));
    } else {
      setDays(0);
    }
  }, [resourcesFilter]);

  useEffect(() => {
    calculateAvg();
  }, [calculateAvg]);

  useEffect(() => {
    filterResources();
  }, [filterResources]);

  return (
    <Container>
      <LoadingWrapper isLoading={isLoading}>
        <Section>
          <BenchFilter>
            <AvgOnBench days={days} />
            <Filter filters={filters} setFilters={setFilters} />
          </BenchFilter>
          <Title>{intl.formatMessage({ id: 'template.daysOnBench' }, { days: daysOnBench })}</Title>
          <ResourcesTable resources={resourcesFilterTable} />
        </Section>
        <Section2>
          <Title>
            {intl.formatMessage({ id: 'dashboard.breakdown.section.titleAvailableCapacity' })}
          </Title>
          <AvailableCapacityGraph data={skills} />
        </Section2>
      </LoadingWrapper>
    </Container>
  );
}

export default BenchBreakdown;
