import { arrayOf, func, shape } from 'prop-types';
import { useEffect } from 'react';

import FilterDropdown from 'components/common/FilterDropdown';
import { FULFILLED } from 'constants/actionStatusConstants';
import { departmentShape } from 'constants/shapes';
import { useTeams } from 'hooks';
import { initializeTeams } from 'utils/helpers';

import { Selectors } from './styles';

function Filter({ filters, setFilters }) {
  const { teams, teamsStatus } = useTeams();

  const handleFilter = ({ options, key }) => {
    setFilters(prevState => ({ ...prevState, [key]: options }));
  };

  useEffect(() => {
    if (teamsStatus === FULFILLED) {
      setFilters(prevState => ({
        ...prevState,
        departments: initializeTeams({
          teams,
          withValues: false
        })
      }));
    }
  }, [teamsStatus, teams, setFilters]);

  return (
    <Selectors>
      <FilterDropdown
        labelId="common.department"
        options={filters.departments}
        onFilter={handleFilter}
        queryKey="departments"
        withIntlOptions={false}
      />
    </Selectors>
  );
}

Filter.propTypes = {
  setFilters: func,
  filters: shape({
    departments: arrayOf(departmentShape)
  })
};

export default Filter;
