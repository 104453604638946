import styled from 'styled-components';

export const UtilizationChartContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  min-width: 42rem;
  padding: 1.2rem 1.8rem 1.7rem 1.8rem;
  width: 100%;
`;

export const DataSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InfoLabel = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &::before {
    background-color: ${({ color }) => color || 'black'};
    border-radius: 7.5px;
    content: '';
    display: inline-block;
    height: 1.6rem;
    margin-right: 0.8rem;
    width: 1.6rem;
  }
`;
