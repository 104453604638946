import { number, shape } from 'prop-types';
import { useTheme } from 'styled-components';

import { toCurrency } from 'utils/number';

const XAxisTick = ({ x = 0, y = 0, payload }) => {
  const theme = useTheme();
  const { value } = payload;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        fill={theme.new.colors.N800}
        dx={-12}
        dy={19}
        fontSize={14}
        fontWeight={theme.fontWeights.normal}
        fontFamily={theme.new.fontFamily}
        strokeWidth={0}
      >
        {toCurrency(value, {
          notation: 'compact',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })}
      </text>
    </g>
  );
};

XAxisTick.propTypes = {
  payload: shape({ value: number }),
  x: number,
  y: number
};

export default XAxisTick;
