import styled from 'styled-components';

import AlertMessage from 'components/common/AlertMessage';
import { Row } from 'components/common/Layout/Row';
import NewModal from 'components/common/NewModal';

import Button from '../Button';
import { ContentWrapper, ModalSubTitle, ModalTitle } from '../NewModal/styles';

export const Container = styled.div`
  width: 100%;
  text-align: ${({ textAlign }) => textAlign};
`;
export const StyledRow = styled(Row)`
  gap: 2rem;
  margin-top: ${({ error }) => (!error ? '5rem' : '2rem')};
`;

export const StyledModal = styled(NewModal)`
  ${ContentWrapper} {
    max-width: 53.7rem;
  }
  ${ModalSubTitle} {
    white-space: normal;
    width: 100%;
  }
  ${ModalTitle} {
    line-height: 20px;
    border-bottom: 1px solid #e4e5eb;
    padding-bottom: 2.7rem;
  }
`;

export const StyledAlertMessage = styled(AlertMessage)`
  ${({ theme }) => theme.new.typography.p4};
  margin-top: 5rem;
`;

export const StyledButton = styled(Button)`
  max-width: 23.75rem;
  width: 100%;
`;
