import { arrayOf, bool, func, instanceOf, oneOfType, string } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import EmptyState from 'components/common/EmptyState';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import LoadingWrapper from 'components/common/LoadingWrapper';
import ResourceProfileModal from 'components/Reports/ResourceProfileModal';
import { projectListShape } from 'constants/shapes';
import { useDispatch } from 'hooks';
import {
  addAssignment,
  isolatedDeleteAssignment,
  isolatedEditAssignment
} from 'state/actions/assignmentActions';
import { getProjectAssignments } from 'state/actions/projectActions';

import AddAssignmentModal from '../AddAssignmentModal';
import EditAssignmentModal from '../EditAssignmentModal';
import ProjectRow from './ProjectRow';

const ProjectsTable = ({
  filteredProjects,
  isProjectsPending = false,
  setEditProjectStatus,
  setNewProjectStatus,
  startDateTimeline,
  endDateTimeline,
  getProjectsRequest,
  handleExpandedProjects
}) => {
  const intl = useIntl();
  const [addAssignmentTo, setAddAssignmentTo] = useState();
  const [editAssignment, setEditAssignment] = useState();
  const [personId, setPersonId] = useState();
  const getAssignmentsRequest = useDispatch(getProjectAssignments);

  const updateAssignment = (id, callback) => () => {
    getAssignmentsRequest({
      projectId: id,
      startDate: startDateTimeline,
      endDate: endDateTimeline
    });
    callback(null);
  };

  const setEditProjectStatusOverride = project => values =>
    setEditProjectStatus({ project, ...values });

  const setNewProjectStatusOverride = project => values =>
    setNewProjectStatus({ project, ...values });

  if (isProjectsPending && !filteredProjects.length) {
    return <CenteredLoading />;
  }

  return (
    <LoadingWrapper isLoading={isProjectsPending}>
      {filteredProjects?.length > 0 ? (
        filteredProjects.map(project => (
          <ProjectRow
            key={project.id}
            project={project}
            setNewProjectStatus={setNewProjectStatusOverride(project)}
            setEditProjectStatus={setEditProjectStatusOverride(project)}
            startDateTimeline={startDateTimeline}
            endDateTimeline={endDateTimeline}
            setAddAssignmentTo={setAddAssignmentTo}
            setEditAssignment={setEditAssignment}
            setPersonId={setPersonId}
            getProjectsRequest={getProjectsRequest}
            handleExpandedProjects={handleExpandedProjects}
          />
        ))
      ) : (
        <EmptyState
          title={intl.formatMessage({ id: 'emptyState.title.projects' })}
          subtitle={intl.formatMessage({ id: 'emptyState.subtitle.projects' })}
        />
      )}
      {addAssignmentTo && (
        <AddAssignmentModal
          isShowing={!!addAssignmentTo}
          hide={updateAssignment(addAssignmentTo.project?.id, setAddAssignmentTo)}
          projectId={addAssignmentTo.project?.id}
          showCopy
          addAssignmentAction={addAssignment}
          handleRefresh={getProjectsRequest}
          initialValues={addAssignmentTo?.initialValues}
        />
      )}
      {editAssignment && (
        <EditAssignmentModal
          isShowing={!!editAssignment}
          hide={updateAssignment(editAssignment.projectId, setEditAssignment)}
          projectId={editAssignment.projectId}
          assignmentId={editAssignment.assignmentId}
          editAssignmentAction={isolatedEditAssignment}
          handleRefreshData={getProjectsRequest}
          deleteAssignmentAction={isolatedDeleteAssignment}
        />
      )}
      {personId && (
        <ResourceProfileModal
          open={Boolean(personId)}
          personId={personId}
          hide={() => setPersonId(null)}
        />
      )}
    </LoadingWrapper>
  );
};

ProjectsTable.propTypes = {
  filteredProjects: arrayOf(projectListShape),
  isProjectsPending: bool,
  setEditProjectStatus: func,
  setNewProjectStatus: func,
  startDateTimeline: oneOfType([string, instanceOf(Date)]),
  endDateTimeline: oneOfType([string, instanceOf(Date)]),
  getProjectsRequest: func,
  handleExpandedProjects: func
};

export default ProjectsTable;
