import { bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as NonBillable } from 'assets/icons/nonBillable.svg';
import { ReactComponent as LeaveIcon } from 'assets/icons/resource-leave.svg';
import { ReactComponent as NewResourceIcon } from 'assets/icons/resource-new.svg';
import { ReactComponent as OutIcon } from 'assets/icons/resource-out.svg';
import { ReactComponent as VacationsIcon } from 'assets/icons/resource-vacations.svg';
import IconWithTooltip from 'components/common/IconWithTooltip';
import InfoTooltip from 'components/Financial/InfoTooltip';
import { TextField } from 'components/Financial/Layout/Tables';
import { vacationShape } from 'constants/shapes';
import { formatResourceDate } from 'utils/date';

import { Container, Section } from './styles';

function ResourceState({
  name,
  startDate,
  endDate,
  isLeave,
  isOut,
  isGettingAssigned,
  isUpcomingChanges,
  vacationData,
  personId,
  showResourceDetail,
  billable
}) {
  const intl = useIntl();
  const vacationStart = vacationData?.startDate && formatResourceDate(vacationData.startDate);
  const vacationReturn = vacationData?.returnDate && formatResourceDate(vacationData.returnDate);

  const handleMessage = () => {
    if (isGettingAssigned)
      return intl.formatMessage({ id: 'template.startingDate' }, { startDate });
    if (isUpcomingChanges)
      return intl.formatMessage({ id: 'template.effectiveDate' }, { startDate });
    if (isLeave) return intl.formatMessage({ id: 'template.leaveDate' }, { endDate });
    if (vacationData)
      return intl.formatMessage(
        { id: 'template.vacationReturn' },
        {
          startDate: vacationStart,
          returnDate: vacationReturn
        }
      );
    if (isOut) {
      return intl.formatMessage({ id: 'template.notAvailable' });
    }
  };

  return (
    <Container>
      {isLeave && (
        <InfoTooltip
          text={intl.formatMessage({ id: 'template.leaveDate.info' }, { endDate })}
          placement="top"
          width="4rem"
        >
          <LeaveIcon />
        </InfoTooltip>
      )}
      {isOut && <OutIcon />}
      {isGettingAssigned && (
        <InfoTooltip
          text={intl.formatMessage({ id: 'template.startingDate.info' }, { startDate })}
          placement="top"
          width="4rem"
        >
          <NewResourceIcon />
        </InfoTooltip>
      )}
      {vacationData && (
        <InfoTooltip
          text={intl.formatMessage(
            { id: 'template.vacationReturn.info' },
            {
              startDate: vacationStart,
              endDate: vacationReturn,
              duration: vacationData.duration
            }
          )}
          placement="top"
          width="4rem"
        >
          <VacationsIcon />
        </InfoTooltip>
      )}
      <Section disabled={isOut} billable={billable}>
        <div>
          <div onClick={() => showResourceDetail?.(personId)}>{name}</div>
          <TextField>{handleMessage()}</TextField>
        </div>
        {billable && (
          <IconWithTooltip
            icon={<NonBillable />}
            message={intl.formatMessage({ id: 'common.nonBillable' })}
          />
        )}
      </Section>
    </Container>
  );
}

ResourceState.propTypes = {
  name: string,
  startDate: oneOfType([string, instanceOf(Date)]),
  endDate: oneOfType([string, instanceOf(Date)]),
  isOut: bool,
  isLeave: bool,
  isGettingAssigned: bool,
  isUpcomingChanges: bool,
  vacationData: vacationShape,
  personId: number,
  showResourceDetail: func,
  billable: bool
};

export default ResourceState;
