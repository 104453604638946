import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  display: flex;
  height: 9.4rem;
  width: 100%;
`;

export const UserItem = styled.div`
  align-items: center;
  display: flex;
  gap: 2.4rem;
  height: 5.1rem;
  justify-content: center;
  text-align: left;
  width: 100%;

  &:not(:last-child) {
    border-right: ${({ theme }) => `1px solid ${theme.colors.athensGray}`};
  }
`;

export const UserTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-align: left;
  white-space: pre;
  color: ${({ theme }) => theme.new.colors.neutral800};
`;

export const UserName = styled.div`
  align-items: center;
  color: ${({ theme, fullName }) => theme.new.colors[fullName ? 'neutral900' : 'neutral500']};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  gap: 1.1rem;
`;
