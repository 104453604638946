import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Projects = styled.div`
  color: ${({ theme }) => theme.colors.gray80};
  font-size: 1.2rem;
`;
