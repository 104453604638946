import { number, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { toCurrency } from 'utils/number';

import { Container, Dot, Row, Title, Total } from './styles';

function GraphReference({ type, total }) {
  const intl = useIntl();

  return (
    <Container>
      <Title>{intl.formatMessage({ id: `forecasting.graph.${type}` })}:</Title>
      <Row>
        <Dot type={type} />
        <Total>{toCurrency(total)}</Total>
      </Row>
    </Container>
  );
}

GraphReference.propTypes = {
  type: string,
  total: number
};

export default GraphReference;
