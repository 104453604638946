import { arrayOf } from 'prop-types';
import { useIntl } from 'react-intl';

import EmptyState from 'components/common/EmptyState';
import ProjectOverview from 'components/Financial/ProjectOverview';
import routesPaths from 'constants/routesPaths';
import { projectOverviewFinancialShape } from 'constants/shapes';
import { routeWithProps } from 'utils/helpers';

import { Container, ContainerItem, ListItem } from './styles';

const FinancialProjects = ({ projects }) => {
  const intl = useIntl();
  if (!projects?.length) {
    return (
      <EmptyState
        title={intl.formatMessage({ id: 'emptyState.title.projects' })}
        subtitle={intl.formatMessage({ id: 'emptyState.subtitle.projects' })}
      />
    );
  }

  return (
    <Container>
      {projects.map(project => (
        <ListItem key={project.id}>
          <ContainerItem
            aria-label={project.name}
            id={`project-${project.id}`}
            key={project.id}
            to={routeWithProps(routesPaths.financialProjectsDetails, {
              id: project.id
            })}
          >
            <ProjectOverview project={project} redirectToDetail />
          </ContainerItem>
        </ListItem>
      ))}
    </Container>
  );
};

FinancialProjects.propTypes = {
  projects: arrayOf(projectOverviewFinancialShape)
};

export default FinancialProjects;
