import styled from 'styled-components';

export const LegendItemContainer = styled.span`
  color: ${({ theme }) => theme.colors.black};
  display: inline-block;
  margin: 1.6rem 4.8rem 5rem 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
