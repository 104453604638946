import { pickBy } from 'lodash';

export const isErrorEmpty = errors =>
  !Object.values(errors)
    .filter(error => !!error)
    .flat().length;

export const getErrorSection = (formSections, errors) => {
  const errorFields = pickBy(errors, Boolean);
  const firstErrorField = Object.keys(errorFields)[0];

  const errorSection =
    firstErrorField &&
    formSections.find(section => section.sectionErrors?.some(field => field === firstErrorField));

  return errorSection?.section;
};
