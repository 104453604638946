import { bool, node } from 'prop-types';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

const RouteFromPath = ({ component, privateRoute, ...route }) =>
  privateRoute ? (
    <PrivateRoute {...route}>{component}</PrivateRoute>
  ) : (
    <Route {...route}>{component}</Route>
  );

RouteFromPath.propTypes = {
  privateRoute: bool,
  component: node.isRequired
};

export default RouteFromPath;
