import { useState, useEffect } from 'react';

import peopleService from 'services/peopleService';

export default personId => {
  const [personExperiencesLoading, setPersonExperiencesLoading] = useState(true);
  const [personExperiences, setPersonExperiences] = useState([]);

  useEffect(() => {
    if (!personId) setPersonExperiences([]);
    else
      (async () => {
        const {
          data: { experiences }
        } = await peopleService.resourceExperiences({
          personId
        });
        setPersonExperiences(experiences);
        setPersonExperiencesLoading(false);
      })();
  }, [personId]);

  return { personExperiences, personExperiencesLoading };
};
