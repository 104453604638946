import dayjs from 'dayjs';
import { func } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Checkbox from 'components/common/Checkbox';
import CustomTab from 'components/common/CustomTab';
import SingleDateRange from 'components/common/SingleDateRange';
import QuickDateSelector from 'components/common/TimeNavigation/QuickDateSelector';
import { CustomSwitch } from 'components/dashboard/Filters/People/styles';
import { FULFILLED } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS } from 'constants/constants';
import { DISPLAY_MONTH_DATE_DAYJS } from 'constants/dateFormats';
import { utilizationFiltersShape } from 'constants/shapes';
import { useRoles, useSkills, useTeams } from 'hooks';
import {
  addMonths,
  dateToString,
  getFirstAndLastDayOfMonth,
  getRequestFormatDate,
  getMondayOfWeek,
  getFridayOfWeek
} from 'utils/date';
import { initializeOptionsWithFieldName } from 'utils/helpers';

import {
  Container,
  ExportButton,
  Side,
  StyledFilterDropdown,
  SwitchContainer,
  TransitionWrapper
} from './styles';

const Filters = ({ filters, setFilters, getDashboardList, exportTable }) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(intl.formatMessage({ id: 'common.monthly' }));
  const [customDateRange, setCustomDateRange] = useState({
    start: new Date(getMondayOfWeek()),
    end: new Date(getFridayOfWeek())
  });
  const { formattedTeams, teamsStatus } = useTeams(true);
  const { rolesOptions, rolesStatus } = useRoles();
  const { skillOptions, skillsStatus } = useSkills();

  const handleOptions = ({ options, key, newValue }) => {
    let params = { ...filters, [key]: options || newValue };

    if (key === 'month') {
      const startDate = getFirstAndLastDayOfMonth(dayjs(params.month))?.startDate;
      const endDate = getFirstAndLastDayOfMonth(dayjs(params.month))?.endDate;
      getDashboardList({
        startDate,
        endDate
      });
      params = { ...params, startDate, endDate };
    }
    if (key === 'dateRange') {
      const startDate = getRequestFormatDate(params.dateRange[0]);
      const endDate = getRequestFormatDate(params.dateRange[1]);
      getDashboardList({
        startDate,
        endDate
      });
      params = { ...params, startDate, endDate };
    }
    setFilters(params);
  };

  const handleActiveTab = newActiveTab => {
    if (newActiveTab === intl.formatMessage({ id: 'common.monthly' })) {
      handleOptions({ key: 'month', newValue: filters?.startDate });
    } else {
      handleOptions({
        key: 'dateRange',
        newValue: [customDateRange.start, customDateRange.end]
      });
    }
    setActiveTab(newActiveTab);
  };

  const handleDateChange = dates => {
    const [start, end] = dates;
    setCustomDateRange({ start, end });
    if (start && end) {
      handleOptions({ key: 'dateRange', newValue: dates });
    }
  };

  useEffect(() => {
    if ([teamsStatus, rolesStatus, skillsStatus].every(status => status === FULFILLED)) {
      setFilters(prevState => ({
        ...prevState,
        skills: initializeOptionsWithFieldName(skillOptions, 'skills'),
        departments: initializeOptionsWithFieldName(formattedTeams, 'departments'),
        roles: initializeOptionsWithFieldName(rolesOptions, 'roles')
      }));
    }
  }, [
    teamsStatus,
    rolesStatus,
    rolesOptions,
    setFilters,
    formattedTeams,
    skillsStatus,
    skillOptions
  ]);

  const handleQuickSelectorChange = (value, forward = true) =>
    handleOptions({ key: 'month', newValue: value || addMonths(forward ? 1 : -1, filters.month) });

  return (
    <Container>
      <Side>
        <StyledFilterDropdown
          labelId="common.departments"
          options={filters.departments}
          onFilter={handleOptions}
          queryKey="departments"
          withIntlOptions={false}
        />
        <StyledFilterDropdown
          labelId="common.cap.allRoles"
          options={filters.roles}
          onFilter={handleOptions}
          queryKey="roles"
          withIntlOptions={false}
          searchIntlId="common.cap.searchRole"
        />
        <StyledFilterDropdown
          labelId="common.availability"
          options={filters.availability}
          onFilter={handleOptions}
          queryKey="availability"
        />
        <StyledFilterDropdown
          labelId="common.cap.allSkills"
          options={filters.skills}
          onFilter={handleOptions}
          queryKey="skills"
          withIntlOptions={false}
          searchIntlId="common.cap.searchSkill"
        />
        <SwitchContainer>
          <CustomSwitch
            checked={filters.highlighted}
            onChange={() => handleOptions({ key: 'highlighted', newValue: !filters.highlighted })}
          />
          <span>{intl.formatMessage({ id: 'common.cap.mainSkill' })}</span>
        </SwitchContainer>
        <Checkbox
          label={intl.formatMessage({ id: 'insights.bench.filter.hidePending' })}
          value={filters.hidePending}
          onChange={value => handleOptions({ options: value, key: 'hidePending' })}
          variant="new"
        />
      </Side>
      <Side>
        {exportTable && (
          <ExportButton variant={BUTTON_VARIANTS.NEW_SECONDARY} onClick={exportTable}>
            {intl.formatMessage({ id: 'common.export' })}
          </ExportButton>
        )}
        <TransitionWrapper
          activeTab={activeTab === intl.formatMessage({ id: 'common.custom' }) ? 0 : 1}
        >
          <CustomTab
            activeTab={activeTab}
            setActiveTab={handleActiveTab}
            tabTextIds={['common.custom', 'common.monthly']}
          />
          {activeTab === intl.formatMessage({ id: 'common.custom' }) ? (
            <SingleDateRange
              onChange={handleDateChange}
              startDate={customDateRange.start}
              endDate={customDateRange.end}
            />
          ) : (
            <QuickDateSelector onChange={handleQuickSelectorChange}>
              <span>{dateToString(filters?.startDate, DISPLAY_MONTH_DATE_DAYJS)}</span>
            </QuickDateSelector>
          )}
        </TransitionWrapper>
      </Side>
    </Container>
  );
};

Filters.propTypes = {
  filters: utilizationFiltersShape,
  setFilters: func,
  getDashboardList: func,
  exportTable: func
};

export default Filters;
