import styled from 'styled-components';

export const Container = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.9rem;
  padding: 3rem 0 1.8rem 0;
  width: 100%;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.new.typography.h1SemiBold};
  color: ${({ theme }) => theme.new.colors.black};
  max-width: 25.1rem;
  width: 100%;
`;
