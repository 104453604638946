import styled from 'styled-components';

import EmptyState from 'components/common/EmptyState';

export const StyledEmptyState = styled(EmptyState)`
  min-height: 45rem;
`;

export const ProjectDetailsContainer = styled.div`
  height: 100%;
  padding: 1.4rem 2.4rem;
  width: 100%;

  && > header > h2 {
    max-width: 100%;
  }
`;

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 200px;
`;

export const ProjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.6rem;

  && > * {
    min-height: auto;
  }
`;

export const DetailsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 2.4rem;

  && > * {
    flex: 1;
  }

  && > div:nth-child(1) {
    height: fit-content;
    justify-content: flex-start;
    max-width: 28.8rem;
  }
`;
