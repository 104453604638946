import styled from 'styled-components';

export const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.fontColors};
  text-align: center;
`;

export const SubTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 1.9rem;
  color: ${({ theme }) => theme.fontColors};
  text-align: center;
`;
