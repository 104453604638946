import styled from 'styled-components';

import { ReactComponent as emptyStateDefault } from 'assets/icons/folder-icon.svg';

export const Container = styled.div`
  align-items: center;
  border-radius: 7px;
  display: flex;
  gap: 1.8rem;
  justify-content: center;
  height: ${({ $height }) => $height};

  background: ${({ theme }) => theme.new.colors.neutral200};
  flex-direction: ${({ $direction }) => $direction};
`;

export const Title = styled.h3`
  ${({ theme }) => theme.new.typography.p3SemiBold};
  color: ${({ theme }) => theme.new.colors.black};
`;

export const Text = styled.h4`
  ${({ theme }) => theme.new.typography.p4};
  color: ${({ theme }) => theme.new.colors.neutral600};
`;

export const StyledDefaultIcon = styled(emptyStateDefault)`
  width: 2.2rem;
`;
