import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme, border }) => (border ? `1px solid ${theme.new.colors.neutral300}` : 'none')};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  ${({ $isRemoveBottomRightRadius }) =>
    $isRemoveBottomRightRadius && 'border-bottom-right-radius: 0;'};
`;

export const Header = styled.div`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N500};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  align-items: center;
  display: flex;
  height: ${({ height }) => height || '7.3rem'};
  width: 100%;
  background: ${({ theme }) => theme.new.colors.white};
  color: ${({ theme }) => theme.new.colors.neutral700};
`;

export const Row = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  background-color: ${({ theme, gray }) => theme.new.colors[gray ? 'neutral200' : 'white']};
  color: ${({ theme }) => theme.new.colors.neutral700};
  align-items: center;
  border-bottom: 1px ${({ theme }) => theme.new.colors.neutral300} solid;
  display: flex;
  height: 6rem;
  width: 100%;
`;

export const Column = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: ${({ firstColumn }) => (firstColumn ? 'left' : 'center')};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  padding-left: ${({ firstColumn }) => firstColumn && '2.4rem'};
  flex-grow: ${({ flexGrow }) => flexGrow || '1'};
  flex-basis: 0;
  ${({ theme, strong }) => strong && theme.new.typography.p5SemiBold};
  color: ${({ theme, strong }) => strong && theme.new.colors.neutral800};
`;

export const ContainerScroll = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N500};
  max-height: ${({ maxHeight }) => maxHeight || '60rem'};
  min-height: ${({ minHeight }) => minHeight};
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    ${({ hiddenScroll }) => hiddenScroll && 'display: none'};
  }

  ::-webkit-scrollbar:vertical {
    width: 1.1rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.new.colors.white};
    height: 5rem;
    background-color: ${({ theme }) => theme.new.colors.neutral300};
  }
`;

export const Count = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  margin-left: 0.3rem;
`;

export const Label = styled.div`
  display: flex;
`;

export const TextField = styled.div`
  padding-left: ${({ padding }) => padding};
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
