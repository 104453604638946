import styled from 'styled-components';

import Button from 'components/common/Button';

export const FiltersContainer = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.fixedPositions.filters};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.stickyFilterBar};
`;

export const ArrowsContainer = styled.div`
  align-items: center;
  align-self: flex-end;
  display: flex;
  height: 100%;
  margin-right: 1rem;
`;

export const ArrowButton = styled.button`
  height: 4rem;
  width: 2rem;

  :focus {
    outline: 0;
  }

  :hover {
    cursor: pointer;
  }
`;

export const FiltersBar = styled.div`
  ${({ isSticky }) => !isSticky && 'border-radius: 5px;'}
  background-color: ${props => props.theme.colors.white};
  display: flex;
  height: ${({ theme }) => theme.fixedPositions.timeLine};
`;

export const FiltersSection = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin: 0 1.8rem;
`;

export const StyledButton = styled(Button)`
  font-size: 1.3rem;
  height: 3.9rem;
  margin: 0 2.6rem;
  width: 8.3rem;
`;

export const Title = styled.h3`
  ${({ theme }) => theme.typography.extraBold};

  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin-right: 4rem;
`;
