import styled from 'styled-components';

import { EVENT_TYPE } from 'constants/constants';
import { fragmentFlex } from 'utils/timelineUtilities';

const stripedPattern = (backgroundColor, linesColor) =>
  `repeating-linear-gradient(-60deg,${linesColor},${linesColor} 1.6px,${backgroundColor} 1.6px,${backgroundColor} 7px)`;

const getBackground = ({ theme, type }) => {
  switch (type) {
    case EVENT_TYPE.EXIT_DATE:
      return theme.colors.red;
    case EVENT_TYPE.TIME_OFF:
      return stripedPattern(theme.colors.melon, theme.colors.pastelRed);
    default:
      return theme.colors.green;
  }
};

export const EventContainer = styled.div`
  align-items: center;
  background: ${props => props?.color || getBackground(props)};
  border-radius: 5px;
  display: flex;
  flex: ${({ startDate, endDate }) => fragmentFlex(startDate, endDate)};
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 0;
  pointer-events: auto;
  }
`;

export const Pill = styled.div`
  border-radius: 13px;
  background: transparent;
  padding: 0.4rem 0.8rem;
  margin: 0 0.2rem;
  display: flex;
  justify-content: center;
`;

export const EmptyPeriodElement = styled(EventContainer)`
  background: transparent;
  pointer-events: none;
`;

export const TextContainer = styled.div`
  align-items: center;
  align-self: center;
  color: ${({ textColor }) => textColor};
  display: flex;
  font-size: 1.3rem;
  font-weight: ${props => props.theme.fontWeights.bold};
  left: 0;
  margin: auto 0 auto 2rem;
  overflow: hidden;
  padding: 0.2rem;
  right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
