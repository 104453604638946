import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Filters from 'components/dashboard/Filters/Reports';
import SectionBenchBreakdown from 'components/dashboard/SectionBenchBreakdown';
import SectionInsights from 'components/dashboard/SectionInsights';
import {
  getReportSectionsOptions,
  GROUPING_TYPES,
  REPORTS_SECTIONS_OPTIONS,
  REPORT_SECTIONS
} from 'constants/dashboardConstants';
import { useRequest } from 'hooks';
import { getAssignmentSummaries } from 'state/actions/dashboardActions';
import { getFirstAndLastDayOfCurrentMonth, getRequestFormatDate, stringToDate } from 'utils/date';

import { OverviewContainer, SectionsSelect, TopBar } from './styles';

const month = getFirstAndLastDayOfCurrentMonth();

const initialParams = {
  startDate: month.startDate,
  endDate: month.endDate,
  departmentIds: [],
  roleIds: [],
  skillIds: [],
  groupingType: GROUPING_TYPES.weekly
};

const DashboardReports = () => {
  const intl = useIntl();
  const [summaries, setSummaries] = useState([]);
  const [globalIndicators, setGlobalIndicators] = useState({});
  const [section, setSection] = useState({
    ...REPORTS_SECTIONS_OPTIONS[0],
    label: intl.formatMessage({ id: REPORTS_SECTIONS_OPTIONS[0].label })
  });
  const [, resourceRequest] = useRequest(getAssignmentSummaries, {
    fulfilledCallback: ({ weeks, ...globalIndicators }) => {
      const summaries = Object.entries(weeks).map(([key, value]) => ({
        ...value,
        name: getRequestFormatDate(stringToDate(key))
      }));
      setSummaries(summaries);
      setGlobalIndicators(globalIndicators);
    },
    params: initialParams
  });

  const handleClearFilters = () => {
    resourceRequest(initialParams);
  };

  const reportsSectionsOptions = useMemo(
    () => getReportSectionsOptions(intl.formatMessage),
    [intl.formatMessage]
  );

  return (
    <OverviewContainer>
      <TopBar>
        <SectionsSelect
          name="sections"
          onChange={newValue => setSection(newValue)}
          options={reportsSectionsOptions}
          value={section}
          withoutHighlight
          isUseFilledArrow
          isSearchable={false}
        />
        {section.value === REPORT_SECTIONS.INSIGHTS && (
          <Filters
            onRequest={resourceRequest}
            onClear={handleClearFilters}
            section={section}
            setSection={setSection}
          />
        )}
      </TopBar>
      {
        {
          [REPORT_SECTIONS.INSIGHTS]: (
            <SectionInsights summaries={summaries} globalIndicators={globalIndicators} />
          ),
          [REPORT_SECTIONS.BENCH_BREAKDOWN]: <SectionBenchBreakdown />
        }[section.value]
      }
    </OverviewContainer>
  );
};

export default DashboardReports;
