import { node, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { Container, StyledDefaultIcon, Text, Title } from './styles';

function EmptyState({
  icon = <StyledDefaultIcon />,
  textIntlId,
  titleIntlId,
  height = '11.8rem',
  text = '',
  className = '',
  title = ''
}) {
  const intl = useIntl();

  return (
    <Container
      className={className}
      $direction={titleIntlId || title ? 'column' : 'row'}
      $height={height}
    >
      {icon}
      <Title>{titleIntlId ? intl.formatMessage({ id: titleIntlId }) : title}</Title>
      <Text>{textIntlId ? intl.formatMessage({ id: textIntlId }) : text}</Text>
    </Container>
  );
}

EmptyState.propTypes = {
  title: string,
  text: string,
  icon: node,
  textIntlId: string,
  titleIntlId: string,
  className: string,
  height: string
};

export default EmptyState;
