import styled from 'styled-components';

import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import Button from 'components/common/Button';
import { Row } from 'components/common/Layout/Row';

export const StyledPlusIcon = styled(PlusIcon)`
  height: 2.2rem;
  width: 2.2rem;
  path {
    stroke: ${({ theme }) => theme.new.colors.brand};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: calc(100% - 5.5rem);
  margin-top: 1rem;
  padding-top: 2.4rem;
`;

export const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  gap: 0.3rem;
  height: 3.8rem;
  justify-content: center;
  line-height: 2.043rem;
  margin-top: 0.8rem;
`;

export const ButtonRow = styled(Row)`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;
