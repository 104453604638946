import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';

import AccessMatrixImage from 'components/AccessMatrix';
import NewModal from 'components/common/NewModal';

const AccessMatrixModal = ({ isShowing, hide }) => {
  const intl = useIntl();

  return (
    <NewModal
      isShowing={isShowing}
      hide={hide}
      contentPadding="2.4rem"
      title={intl.formatMessage({ id: 'settings.accessMatrix' })}
      maxWidth="90rem"
    >
      <AccessMatrixImage />
    </NewModal>
  );
};

AccessMatrixModal.propTypes = {
  hide: func.isRequired,
  isShowing: bool
};

export default AccessMatrixModal;
