import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';

import GrowInfo from 'components/common/GrowInfo';
import LoadingWrapper from 'components/common/LoadingWrapper';
import StatusIndicator from 'components/common/StatusIndicator';
import { DISPLAY_YEAR } from 'constants/dateFormats';
import { useRequest } from 'hooks';
import {
  getDashboardUtilization,
  getDashboardUtilizationCorner
} from 'state/actions/dashboardActions';
import { getOverviewCurrentDayData } from 'utils/dashboardUtilities';
import { dateToString } from 'utils/date';

import Filters from './Filters';
import {
  Container,
  CurrentDayData,
  DataWrapper,
  Info,
  Title,
  TopContainer,
  VerticalLine
} from './styles';
import UtilizationChart from './UtilizationChart';

const OverviewBlock = () => {
  const intl = useIntl();

  const [filters, setFilters] = useState({
    departmentId: null,
    year: dateToString(new Date(), DISPLAY_YEAR),
    group: 'month'
  });

  const [{ isPending: isLoadingGraphData, response: graphData }, getDashboardRequest] = useRequest(
    getDashboardUtilization,
    {
      params: filters
    }
  );

  const [{ isPending: isLoadingTopCorner, response: topCornerData }] = useRequest(
    getDashboardUtilizationCorner
  );

  const currentDayData = useMemo(
    () => getOverviewCurrentDayData(topCornerData.corner),
    [topCornerData]
  );

  return (
    <Container>
      <TopContainer>
        <LoadingWrapper isLoading={isLoadingTopCorner}>
          <Title>
            <h3>{intl.formatMessage({ id: 'common.cap.utilization' })}</h3>
            <VerticalLine />
            <h4>{intl.formatMessage({ id: 'common.cap.allDepartments' })}</h4>
          </Title>
        </LoadingWrapper>
        <Filters
          filters={filters}
          setFilters={setFilters}
          getDashboardRequest={getDashboardRequest}
        />
      </TopContainer>
      <LoadingWrapper isLoading={isLoadingTopCorner}>
        <Info>
          <span>{intl.formatMessage({ id: 'common.currentDay' })}</span>
          <CurrentDayData>
            <p>
              {topCornerData?.corner?.utilizationPercentage
                ? Math.round(topCornerData?.corner?.utilizationPercentage)
                : 0}
              %
            </p>
            <GrowInfo utilizationDifference={topCornerData?.corner?.utilizationDifference} />
            <DataWrapper>
              {currentDayData.map(({ label, color, value }) => (
                <StatusIndicator
                  key={label}
                  label={label}
                  color={color}
                  value={Math.round(value)}
                  unit={intl.formatMessage({ id: 'common.FTE' })}
                  unitInBold={false}
                />
              ))}
            </DataWrapper>
          </CurrentDayData>
        </Info>
      </LoadingWrapper>
      <LoadingWrapper isLoading={isLoadingGraphData}>
        <UtilizationChart data={graphData.utilizations} />
      </LoadingWrapper>
    </Container>
  );
};

export default OverviewBlock;
