import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';

class FinancialServices {
  static async getProjects() {
    return httpClient.get(ENDPOINTS.financialProjects);
  }

  static async getProject(id) {
    return httpClient.get(ENDPOINTS.getFinancialProject(id));
  }

  static async deletePeriod(periodId) {
    return httpClient.delete(ENDPOINTS.getFinancialPeriod(periodId));
  }

  static async editProject({ id, ...values }) {
    return httpClient.put(ENDPOINTS.getFinancialProject(id), values);
  }

  static async getResourceSetup(id) {
    return httpClient.get(ENDPOINTS.getFinancialResourceSetup(id));
  }

  static async getProjectClosedPeriods(id) {
    return httpClient.get(ENDPOINTS.getProjectClosedPeriods(id));
  }

  static async getClosedPeriod(id) {
    return httpClient.get(ENDPOINTS.getFinancialPeriod(id));
  }

  static async getProjectOpenedPeriods(id) {
    return httpClient.get(ENDPOINTS.getProjectOpenedPeriods(id));
  }

  static async getProjectOpenedResources(id) {
    return httpClient.get(ENDPOINTS.getProjectOpenedResources(id));
  }

  static async createOpenPeriod(data) {
    return httpClient.post(ENDPOINTS.financialPeriod, data);
  }

  static async closeOpenPeriod(id) {
    return httpClient.put(ENDPOINTS.closeOpenPeriod(id));
  }

  static async updatePeriodInfo({ id, ...values }) {
    return httpClient.put(ENDPOINTS.getFinancialPeriod(id), values);
  }

  static async updateResourceSetup(values) {
    return httpClient.put(ENDPOINTS.financialSetups, values);
  }

  static async refreshResourceSetup(id) {
    return httpClient.put(ENDPOINTS.refreshFinancialResourceSetup(id));
  }

  static async getRefreshResourceSetup(id) {
    return httpClient.get(ENDPOINTS.getRefreshedFinancialResourceSetup(id));
  }

  static async getExportData(startDate, endDate) {
    return httpClient.get(ENDPOINTS.getExportData(startDate, endDate));
  }
}

export default FinancialServices;
