import dayjs from 'dayjs';
import { number, oneOfType, shape, string } from 'prop-types';

import { DISPLAY_MONTH } from 'constants/dateFormats';

import { StyledText } from './styles';

const XAxisTick = ({ x = 0, y = 0, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <StyledText x={0} y={0} dx={0} dy={19} textAnchor="middle">
      {`${dayjs()
        .month(payload.value - 1)
        .format(DISPLAY_MONTH)}`}
    </StyledText>
  </g>
);

XAxisTick.propTypes = {
  payload: shape({ value: oneOfType([number, string]) }),
  x: number,
  y: number
};

export default XAxisTick;
