import { bool, number } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { BUTTON_VARIANTS, NOTIFICATIONS_TYPES } from 'constants/constants';
import { useDispatch } from 'hooks';
import { acknowledgeNotification } from 'state/actions/notificationActions';

import { AcknowledgeSectionContainer, StyledButton } from './styles';

const AcknowledgeSection = ({ typeId, acknowledged, notificationId }) => {
  const intl = useIntl();
  const acknowledgeNotificationRequest = useDispatch(acknowledgeNotification);

  const handleAcknowledge = async e => {
    e.stopPropagation();
    acknowledgeNotificationRequest(notificationId);
  };

  if (NOTIFICATIONS_TYPES.assignmentCreatedByProjectManager.id === typeId)
    return (
      <AcknowledgeSectionContainer>
        {acknowledged ? (
          <>
            <CheckCircle />
            <p>{intl.formatMessage({ id: 'notification.acknowledge.pm' })}</p>
          </>
        ) : (
          <>
            <p>{intl.formatMessage({ id: 'notification.notAcknowledge.pm' })}</p>
            <StyledButton
              type="button"
              variant={BUTTON_VARIANTS.APPLY}
              textIntlId="common.notify"
              onClick={handleAcknowledge}
            />
          </>
        )}
      </AcknowledgeSectionContainer>
    );

  return null;
};

AcknowledgeSection.propTypes = {
  acknowledged: bool,
  typeId: number,
  notificationId: number
};

export default AcknowledgeSection;
