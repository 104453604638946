import { bool, number, string } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as NonBillable } from 'assets/icons/nonBillable.svg';
import { ReactComponent as Support } from 'assets/icons/support.svg';
import BackfillPositionIndicator from 'components/BackfillPositionIndicator';
import IconWithTooltip from 'components/common/IconWithTooltip';
import { noteShape } from 'constants/shapes';

import {
  IconsWrapper,
  StyledMuiTooltip,
  TooltipContent,
  TooltipText,
  TooltipTitle
} from './styles';

const AssignmentTooltip = ({
  isPending,
  busyPercentage,
  workHours,
  note,
  isNonBillable,
  isSupport,
  isBackfillPosition
}) => {
  const intl = useIntl();

  return (
    <StyledMuiTooltip>
      <TooltipContent isCenter={!note?.body}>
        <TooltipTitle>{`${workHours}h ${busyPercentage}%`}</TooltipTitle>
        {isPending && (
          <TooltipText>
            <FormattedMessage id="common.pending" />
          </TooltipText>
        )}
        {note?.body && (
          <>
            <TooltipTitle marginTop="1.7rem">
              <FormattedMessage id="common.notes" />
              :&nbsp;
            </TooltipTitle>
            <TooltipText>{note.body}</TooltipText>
          </>
        )}
        <IconsWrapper>
          {isNonBillable && (
            <IconWithTooltip
              icon={<NonBillable />}
              message={intl.formatMessage({ id: 'common.nonBillable' })}
            />
          )}
          {isSupport && (
            <IconWithTooltip
              icon={<Support />}
              message={intl.formatMessage({ id: 'common.support' })}
            />
          )}
          {isBackfillPosition && <IconWithTooltip icon={<BackfillPositionIndicator />} />}
        </IconsWrapper>
      </TooltipContent>
    </StyledMuiTooltip>
  );
};

AssignmentTooltip.propTypes = {
  busyPercentage: number.isRequired,
  workHours: string,
  isPending: bool,
  note: noteShape,
  isNonBillable: bool,
  isSupport: bool,
  isBackfillPosition: bool
};

export default AssignmentTooltip;
