import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import BestMatches from 'components/common/BestMatches/index';
import BoxContainer from 'components/common/BoxContainer';
import DetailsBoxTitle from 'components/common/DetailsBoxTitle';
import LoadingWrapper from 'components/common/LoadingWrapper';
import PageHeader from 'components/common/PageHeader';
import ProjectSummary from 'components/common/ProjectSummary';
import EmployeeTable from 'components/EmployeeTable';
import NotFound from 'components/common/NotFound';
import { ALLOCATION_TIMELINE_TYPES, PROJECT_TYPES } from 'constants/constants';
import { useAssignment, useRequest, useDispatch, useStatus } from 'hooks';
import { getAssignmentById, resetResources } from 'state/actions/assignmentActions';
import { getProjectById } from 'state/actions/projectActions';

import { Container } from './styles';

const SearchPeople = () => {
  const [project, setProject] = useState(null);
  const { projectId, assignmentId } = useParams();
  const resetAssignmentResources = useDispatch(resetResources);
  const {
    peopleByAssignment: { allResources, loader },
    assignment
  } = useAssignment();
  const [resources, setResources] = useState(allResources);

  const [{ isPendingProject }] = useRequest(getProjectById, {
    params: { projectId },
    fulfilledCallback: payload => setProject(payload)
  });
  const [{ isPendingAssignment }] = useRequest(getAssignmentById, { params: { assignmentId } });

  const { isRejected: isGetProjectByIdRejected } = useStatus(getProjectById);
  const { isRejected: isGetAssignmentByIdRejected } = useStatus(getAssignmentById);

  useEffect(() => () => resetAssignmentResources(), [resetAssignmentResources]);

  const isLoading = isPendingProject || isPendingAssignment || loader;

  const isRejected = isGetProjectByIdRejected || isGetAssignmentByIdRejected;

  const handleAssignmentMargin = () =>
    assignment?.rate > 0 && assignment.assignationType === PROJECT_TYPES.BILLABLE;

  if (isRejected) {
    return <NotFound successRequest={isRejected} />;
  }

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Container id="scrollContainer">
        <PageHeader titleIntlId="common.cap.searchPeople" />
        {project && (
          <BoxContainer>
            <ProjectSummary project={project} />
          </BoxContainer>
        )}
        <BoxContainer>
          <DetailsBoxTitle
            title="searchPeople.bestMatches.title"
            indicators={Object.values(ALLOCATION_TIMELINE_TYPES)}
          />
          <BestMatches
            bestMatches={resources}
            requirement={assignment}
            displayMargin={handleAssignmentMargin()}
          />
        </BoxContainer>
        <BoxContainer>
          <DetailsBoxTitle
            title="searchPeople.exploreResources.title"
            indicators={Object.values(ALLOCATION_TIMELINE_TYPES)}
          />
          {allResources.length && (
            <EmployeeTable
              resources={resources}
              setResources={setResources}
              allResources={allResources}
              displayMargin={handleAssignmentMargin()}
            />
          )}
        </BoxContainer>
      </Container>
    </LoadingWrapper>
  );
};

export default SearchPeople;
