import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import Breadcrumb from 'components/common/BreadcrumbV2';
import Divider from 'components/common/Divider';
import LoadingWrapper from 'components/common/LoadingWrapper';
import NewModal from 'components/common/NewModal';
import ClosePeriods from 'components/Financial/ClosePeriods';
import ProjectFinancialInfoForm from 'components/Financial/ProjectFinancialInfoForm';
import OverviewHeader from 'components/Financial/ProjectOverview/OverviewHeader';
import SplitCosts from 'components/Financial/SplitCosts';
import NotFound from 'components/common/NotFound';
import { useDispatch, useFinancial, useStatus } from 'hooks';
import {
  getFinancialProjectDetails,
  getFinancialResourceSetup,
  getProjectClosedPeriods,
  getProjectOpenPeriodsData
} from 'state/actions/financialActions';

import {
  ContentContainer,
  FinancialDetailsContainer,
  FinancialDetailsNavbarContainer,
  Title
} from './styles';

const FinancialDetails = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [isOpenOverviewModal, setIsOpenOverviewModal] = useState(false);
  const financialProjectDetailsRequest = useDispatch(getFinancialProjectDetails);
  const financialResourceSetupRequest = useDispatch(getFinancialResourceSetup);
  const openedPeriodsRequest = useDispatch(getProjectOpenPeriodsData);
  const closedPeriodsRequest = useDispatch(getProjectClosedPeriods);
  const {
    isPending: isLoadingFinancialProjectDetails,
    isRejected: isFinancialProjectDetailsRejected
  } = useStatus(getFinancialProjectDetails);

  const { projectDetails, closedPeriods } = useFinancial();
  const projectId = Number(id);

  const handleOverviewModal = () => setIsOpenOverviewModal(prevState => !prevState);

  useEffect(() => {
    financialProjectDetailsRequest({ id: projectId });
  }, [financialProjectDetailsRequest, projectId]);

  useEffect(() => {
    financialResourceSetupRequest({ id: projectId });
  }, [financialResourceSetupRequest, projectId]);

  useEffect(() => {
    openedPeriodsRequest({ id: projectId });
  }, [openedPeriodsRequest, projectId]);

  useEffect(() => {
    closedPeriodsRequest({ id: projectId });
  }, [closedPeriodsRequest, projectId]);

  if (isFinancialProjectDetailsRejected) {
    return <NotFound successRequest={isFinancialProjectDetailsRejected} />;
  }

  return (
    <>
      <FinancialDetailsContainer>
        <FinancialDetailsNavbarContainer>
          <div>
            <Breadcrumb lastBread={projectDetails?.name} />
          </div>
        </FinancialDetailsNavbarContainer>
        <ContentContainer>
          {projectDetails?.name && <Title>{projectDetails?.name}</Title>}
          <Divider margin="0" />
          <LoadingWrapper isLoading={isLoadingFinancialProjectDetails}>
            {!isEmpty(projectDetails) && (
              <OverviewHeader
                project={projectDetails}
                displayClientBudget
                onBoxClick={handleOverviewModal}
                redirectToDetail
              />
            )}
          </LoadingWrapper>
          <SplitCosts projectId={projectId} />
          <ClosePeriods projectId={projectId} closedPeriods={closedPeriods} />
        </ContentContainer>
      </FinancialDetailsContainer>
      <NewModal
        isShowing={isOpenOverviewModal}
        hide={handleOverviewModal}
        title={intl.formatMessage({ id: 'projectFinancialInfo.modal.title' })}
        subTitle={intl.formatMessage({ id: 'projectFinancialInfo.modal.subTitle' })}
      >
        <ProjectFinancialInfoForm
          id={projectDetails?.id}
          hideModal={handleOverviewModal}
          initialValues={{
            billedAdjustment: String(projectDetails?.billedAdjustment),
            costsAdjustment: String(projectDetails?.costsAdjustment),
            clientBudget: String(projectDetails?.clientBudget)
          }}
          financialProjectDetailsRequest={financialProjectDetailsRequest}
          disabled={!!closedPeriods?.length}
        />
      </NewModal>
    </>
  );
};

export default FinancialDetails;
