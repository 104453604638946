import { useIntl } from 'react-intl';

import { UTILIZATION } from 'constants/dashboardConstants';

export default () => {
  const intl = useIntl();

  const indicators = [
    {
      label: intl.formatMessage({ id: UTILIZATION.billable.intl }),
      dataKey: UTILIZATION.billable.key,
      color: UTILIZATION.billable.color,
      enabled: true
    },
    {
      label: intl.formatMessage({ id: UTILIZATION.scheduled.intl }),
      dataKey: UTILIZATION.scheduled.key, // planned
      color: UTILIZATION.scheduled.color,
      enabled: true
    },
    {
      label: intl.formatMessage({ id: UTILIZATION.demand.intl }),
      dataKey: UTILIZATION.demand.key, // pending
      color: UTILIZATION.demand.color,
      enabled: true
    }
  ];

  return { indicators };
};
