import { addDays, stringToDate, minDate } from 'utils/date';

export default (allStatuses, addNewStatus, timelineStartDate, timelineEndDate) => {
  const firstStatusEndDate = minDate(
    stringToDate(addDays(allStatuses[0]?.startDate, -1)) || timelineEndDate,
    timelineEndDate
  );

  const lastStatusStartDate = minDate(
    stringToDate(addDays(allStatuses[allStatuses.length - 1]?.endDate, 1)) || timelineEndDate,
    timelineEndDate
  );

  const firstStatusProps = {
    startDate: timelineStartDate,
    endDate: firstStatusEndDate,
    onClick: () =>
      addNewStatus(timelineStartDate, firstStatusEndDate, {
        endDateDisabled: true
      })
  };

  const lastStatusProps = {
    startDate: lastStatusStartDate,
    endDate: timelineEndDate,
    onClick: () =>
      addNewStatus(lastStatusStartDate, timelineEndDate, {
        startDateDisabled: true
      })
  };

  return [firstStatusProps, lastStatusProps];
};
