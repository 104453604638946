import styled from 'styled-components';

export const ModalContainer = styled.div`
  background-blend-mode: multiply;
  background-color: ${({ theme }) => theme.colors.modalBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  padding: 2rem;
  z-index: ${({ theme, isOverlapped }) =>
    isOverlapped ? theme.zIndex.modalOverlapped : theme.zIndex.modal};
`;

export const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  max-width: ${({ maxWidth }) => maxWidth || '48.7rem'};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding || '2.6rem 2.5rem 2.4rem'};
  position: relative;
  width: 100%;
  box-shadow: ${({ theme }) => theme.new.colors.extraLarge2};
  ${({ withoutOverflow }) => {
    if (!withoutOverflow) {
      return `
        overflow-x: hidden;
        overflow-y: auto;
         `;
    }
  }}
`;

export const ModalButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 2.6rem;
  top: 2.6rem;
`;

export const ModalTitle = styled.h3`
  ${({ theme }) => theme.new.typography.h3};
  color: ${({ theme }) => theme.new.colors.neutral900};
  margin-bottom: ${({ $hasSubtitle }) => ($hasSubtitle ? '2.3rem' : '0.3rem')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;
`;

export const ModalSubTitle = styled.p`
  ${({ theme }) => theme.new.typography.p4};
  color: ${({ theme }) => theme.new.colors.neutral700};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
