import { bool, func, number, string } from 'prop-types';
import { useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import Checkbox from 'components/common/Checkbox';
import DateRange from 'components/common/DatePicker/DateRange';
import { Row, RowInputWrapper, CheckboxWrapper } from 'components/common/Layout/Row';
import { VerticalSeparator } from 'components/common/Layout/Separator';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import WarningList from 'components/common/WarningList';
import { BUTTON_VARIANTS } from 'constants/constants';
import { AssignmentShape } from 'constants/shapes';
import {
  useForm,
  useTextInputProps,
  useCheckboxProps,
  useDateRangeProps,
  useHeights,
  usePersonExperiences
} from 'hooks';
import { composeQuickAssignmentRequest } from 'utils/assignmentFormsUtilities';
import { stringToDate } from 'utils/date';

import { Form, StyledButton } from './styles';

const fields = {
  personId: 'personId',
  startDate: 'startDate',
  endDate: 'endDate',
  pending: 'pending'
};

const AssignmentQuickForm = ({
  personId,
  initialValues,
  onSubmit,
  error,
  isPending,
  hide,
  busyPercentage
}) => {
  const intl = useIntl();
  const { separatorHeight } = useHeights();
  const { personExperiences, personExperiencesLoading } = usePersonExperiences(personId);

  const onSubmitOverride = values => {
    onSubmit(composeQuickAssignmentRequest({ values, personId }));
  };

  const { values, setValues, errors, setErrors, handleValueChange, handleSubmit, handleBlur } =
    useForm(
      {
        onSubmit: onSubmitOverride,
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: {
          ...initialValues,
          startDate: stringToDate(initialValues?.starDate),
          endDate: stringToDate(initialValues?.endDate),
          pending: false
        }
      },
      [onSubmitOverride]
    );

  const inputProps = useTextInputProps({
    handleValueChange,
    handleBlur,
    values,
    errors
  });

  const dateRangeProps = useDateRangeProps(
    {
      inputProps,
      values,
      setValues,
      errors,
      setErrors
    },
    [fields.startDate, fields.endDate]
  );

  const checkboxProps = useCheckboxProps({ inputProps, values, setValues });

  const selectedSkills = initialValues?.skills?.map(({ id, name }) => ({
    value: { id },
    label: name
  }));

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <RowInputWrapper fullWidth>
          <DateRange
            names={{
              startDate: 'assignment.startDate',
              endDate: 'assignment.endDate'
            }}
            disabledKeyboardNavigation
            showWeekCounter
            labelPosition="0"
            {...dateRangeProps}
          />
        </RowInputWrapper>
      </Row>
      <Row>
        <CheckboxWrapper>
          <Checkbox
            name="assignment.pending"
            label={intl.formatMessage({ id: 'editQuickAssignment.confirmDirectly' })}
            {...checkboxProps(fields.pending)}
          />
        </CheckboxWrapper>
      </Row>
      {personExperiencesLoading ? (
        <CenteredLoading />
      ) : (
        <WarningList
          personExperiencesLoading={personExperiencesLoading}
          personExperiences={personExperiences}
          selectedSkills={selectedSkills}
          busyPercentage={busyPercentage}
          isResourceAlreadyAssigned={initialValues?.personId === personId}
        />
      )}
      {error && (
        <>
          <AlertMessage>{error}</AlertMessage>
          <VerticalSeparator height={separatorHeight} />
        </>
      )}
      <Row gap="1rem">
        <StyledButton
          type="button"
          variant={BUTTON_VARIANTS.SECONDARY}
          isLoading={isPending}
          textIntlId="common.cancel"
          onClick={hide}
          outlined
          isCancel
          fullWidth
        />
        <StyledButton
          type="submit"
          variant={BUTTON_VARIANTS.PRIMARY}
          isLoading={isPending}
          textIntlId="common.cap.confirmResource"
          fullWidth
        />
      </Row>
    </Form>
  );
};

AssignmentQuickForm.propTypes = {
  onSubmit: func.isRequired,
  busyPercentage: number,
  error: string,
  hide: func,
  initialValues: AssignmentShape,
  isPending: bool,
  personId: number
};

export default AssignmentQuickForm;
