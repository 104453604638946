import { useEffect, useCallback } from 'react';

export default keyCode => handler => {
  const escHandler = useCallback(
    event => {
      if (event.keyCode === keyCode) {
        handler(event);
      }
    },
    [handler]
  );

  useEffect(() => {
    document.addEventListener('keydown', escHandler, false);

    return () => {
      document.removeEventListener('keydown', escHandler, false);
    };
  }, []);
};
