import { useState } from 'react';
import { useIntl } from 'react-intl';

import LoadingWrapper from 'components/common/LoadingWrapper';
import SearchInput from 'components/common/SearchInput';
import GraphReference from 'components/Forecasting/GraphReference';
import ProjectList from 'components/Forecasting/ProjectsList';
import ProjectsTotals from 'components/Forecasting/ProjectsTotals';
import RevenueGraph from 'components/Forecasting/RevenueGraph';
import { FORECASTING_GRAPH_REFERENCES } from 'constants/constants';
import { useRequest } from 'hooks';
import { getGraph, getProjects } from 'state/actions/forecastingActions';
import { filterList } from 'utils/reducersUtilities';

import { Container, Header, Title, ProjectHeader, GraphReferences } from './styles';

const countMonths = 3;

const Forecasting = () => {
  const intl = useIntl();
  const [search, setSearch] = useState();
  const [projects, setProjects] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const [{ isPending: isProjectLoading }] = useRequest(getProjects, {
    fulfilledCallback: payload => {
      setProjects(payload.projects);
    },
    params: { forecastedMonthsAhead: countMonths }
  });

  const [{ isPending: isGraphLoading }] = useRequest(getGraph, {
    fulfilledCallback: payload => {
      setGraphData(payload);
    }
  });

  const filteredProjects = () => filterList(projects, search);

  return (
    <Container>
      <Header>
        <Title>{intl.formatMessage({ id: 'forecasting.mainPage.title' })}</Title>
        <ProjectsTotals />
      </Header>
      <LoadingWrapper isLoading={isGraphLoading}>
        <GraphReferences>
          <GraphReference
            type={FORECASTING_GRAPH_REFERENCES.REVENUE_TO_DATE}
            total={graphData?.revenueToDate}
          />
          <GraphReference
            type={FORECASTING_GRAPH_REFERENCES.YEAR_REVENUE_GOAL}
            total={graphData?.yearRevenueGoal}
          />
        </GraphReferences>
        <RevenueGraph data={graphData.yearMonths} />
      </LoadingWrapper>
      <ProjectHeader>
        <Title>
          {`${intl.formatMessage({ id: 'forecasting.mainPage.projectList' })} (${projects.length})`}
        </Title>
        <SearchInput
          placeholderId="filter.financial.projects"
          value={search}
          onChange={setSearch}
          variant="new"
        />
      </ProjectHeader>
      <ProjectList
        forecastingProjects={filteredProjects()}
        isLoading={isProjectLoading}
        countMonths={countMonths}
      />
    </Container>
  );
};

export default Forecasting;
