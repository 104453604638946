import { number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';

import ProgressBar from 'components/common/ProgressBar';

import { TextContainer, TextWrapper } from '../styles';

const DiffCell = ({ total, current, diff }) => {
  const theme = useTheme();

  const predictedHours = Math.max(current - diff, 0);
  const predictedUtilization = Math.round((predictedHours * 100) / total);
  const realDiff = predictedHours - current;
  const diffUtilization = Math.round((realDiff * 100) / total);

  const barContent = [
    {
      percentage: predictedUtilization,
      color: theme.colors.strongMaintenance,
      roundedLeft: true
    },
    {
      percentage: Math.abs(diffUtilization),
      color: theme.colors.paleBlue,
      roundedRight: true
    }
  ];

  return (
    <>
      <TextWrapper>
        <TextContainer bold>
          {`${predictedUtilization}% (${Math.abs(diffUtilization)}%)`}
        </TextContainer>
        <TextContainer>
          <FormattedMessage
            id="template.connectors.of"
            values={{ valA: predictedHours, valB: total }}
          />
        </TextContainer>
      </TextWrapper>
      <ProgressBar height={0.7} content={barContent} />
    </>
  );
};

DiffCell.propTypes = {
  total: number.isRequired,
  current: number.isRequired,
  diff: number.isRequired
};

export default DiffCell;
