import { useState, useEffect, useCallback } from 'react';

import LoadingWrapper from 'components/common/LoadingWrapper';
import { GlossaryItem, PeopleTabs } from 'components/dashboard';
import Filters from 'components/dashboard/Filters/People';
import { FULFILLED } from 'constants/actionStatusConstants';
import { useDispatch, useStatus } from 'hooks';
import {
  getDashboardResourcesTable,
  getDashboardAvailabilitiesTable
} from 'state/actions/dashboardActions';
import { getFirstAndLastDayOfCurrentMonth } from 'utils/date';

import { GlossaryContainer, TablesContainer, FilterContainer } from './styles';

const month = getFirstAndLastDayOfCurrentMonth();

const initialParams = {
  startDate: month.startDate,
  endDate: month.endDate,
  name: '',
  departmentIds: [],
  seniorities: [],
  skillIds: [],
  highlighted: false
};

const initialCategories = {
  training: false,
  pending: false,
  subcontractor: false,
  overassigned: false,
  lowAllocation: false
};

const DashboardPeople = () => {
  const [resources, setResources] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [glossarySummary, setGlossarySummary] = useState({});
  const [workingDays, setWorkingDays] = useState(null);
  const [categories, setCategories] = useState(initialCategories);
  const getDashboardResourcesTableRequest = useDispatch(getDashboardResourcesTable);
  const getDashboardAvailabilitiesTableRequest = useDispatch(getDashboardAvailabilitiesTable);
  const { isPending: isResourcesAllPending } = useStatus(getDashboardResourcesTable);
  const { isPending: isAvailabilitiesPending } = useStatus(getDashboardAvailabilitiesTable);

  const resourceRequest = useCallback(
    async (params, categories) => {
      const { payload, type } = await getDashboardResourcesTableRequest({
        ...params,
        ...categories
      });
      if (type.endsWith(`/${FULFILLED}`)) {
        setResources(payload.resources);
        setGlossarySummary(payload.glossarySummary);
      }
    },
    [getDashboardResourcesTableRequest]
  );

  const availabilitiesRequest = useCallback(
    async (params, categories) => {
      const { payload, type } = await getDashboardAvailabilitiesTableRequest({
        ...params,
        ...categories
      });
      if (type.endsWith(`/${FULFILLED}`)) {
        setAvailabilities(payload.resources);
        setWorkingDays(payload.workingDays);
      }
    },
    [getDashboardAvailabilitiesTableRequest]
  );

  const handleClearFilters = () => {
    setCategories(initialCategories);
    resourceRequest(initialParams);
    availabilitiesRequest(initialParams);
  };

  const handleRequest = params => {
    resourceRequest(params, categories);
    availabilitiesRequest(params, categories);
  };

  useEffect(() => {
    resourceRequest(initialParams);
    availabilitiesRequest(initialParams);
  }, [resourceRequest, availabilitiesRequest]);

  return (
    <TablesContainer>
      <LoadingWrapper isLoading={isResourcesAllPending}>
        <FilterContainer>
          <Filters onRequest={handleRequest} onClear={handleClearFilters} />
          <GlossaryContainer>
            {Object.keys(glossarySummary).map(item => (
              <GlossaryItem
                key={item}
                item={item}
                value={glossarySummary[item]}
                selected={categories[item]}
                setCategories={setCategories}
              />
            ))}
          </GlossaryContainer>
        </FilterContainer>
        <PeopleTabs
          resourcesAll={resources}
          availabilities={availabilities}
          isResourcesAllPending={isResourcesAllPending}
          isAvailabilitiesPending={isAvailabilitiesPending}
          workingDays={workingDays}
        />
      </LoadingWrapper>
    </TablesContainer>
  );
};

export default DashboardPeople;
