import Menu from '@mui/material/Menu';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 0.8rem 1.6rem;
  min-width: 20rem;
  flex-direction: column;
  justify-items: start;
  align-items: start;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.grey100};
  font-family: ${({ theme }) => theme.fontFamily};
  margin-bottom: 2rem;
`;

export const NameButton = styled.button`
  border: none;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.new.colors.black};
  margin-left: 1rem;
`;

export const Avatar = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
`;

export const Name = styled.h1`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.4rem;
  line-height: 1.9rem;
  margin: 0.3rem 0;
`;

export const Email = styled.h2`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin: 0;
`;

export const ActionItem = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.seaBlue};
  margin: 0.5rem 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ActionLabel = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.seaBlue};
  margin: 0;
`;

export const ActionIcon = styled.div`
  display: flex;
  margin-right: 1rem;
  min-width: 3rem;
`;

export const StyleMenu = styled(Menu)`
  overflow: 'visible';
  margin-top: -1.5rem;

  & .muiavatar-root {
    width: 3.2rem;
    height: 3.2rem;
    margin-left: -0.5rem;
    margin-right: 1;
  }
`;
