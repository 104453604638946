export const areasInformation = {
  design: {
    utilizationId: 'designTeam',
    nameId: 'utilization.areas.design',
    shortNameId: 'utilization.areas.shortName.design',
    color: '#E2F7D6'
  },
  engineering: {
    utilizationId: 'engineeringTeam',
    nameId: 'utilization.areas.engineering',
    shortNameId: 'utilization.areas.shortName.engineering',
    color: '#FFD0A3'
  },
  productOwner: {
    utilizationId: 'productOwnerTeam',
    nameId: 'utilization.areas.productOwner',
    shortNameId: 'utilization.areas.shortName.productOwner',
    color: '#F1B0D0'
  },
  operationTeam: {
    utilizationId: 'operations',
    nameId: 'utilization.areas.operationTeam',
    shortNameId: 'utilization.areas.shortName.operationTeam',
    color: '#D5D4FA'
  }
};
