import { createAction } from '@reduxjs/toolkit';

import PeopleService from 'services/peopleService';
import userService from 'services/userService';
import createAsyncThunk from 'utils/createAsyncThunk';
import parseError from 'utils/parseError';

export const updateSession = createAction('UPDATE_SESSION');

export const login = createAsyncThunk('LOGIN', async adminUser => {
  try {
    let resourceResponse = null;

    const {
      data: { adminUser: adminUserResponse }
    } = await userService.login({ adminUser });

    if (adminUserResponse.personId) {
      const { data } = await PeopleService.getResource(adminUserResponse.personId);
      resourceResponse = data;
    }

    return { user: adminUserResponse, resource: resourceResponse };
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const loginGoogle = createAsyncThunk('LOGIN_GOOGLE', async adminUser => {
  try {
    let resourceResponse = null;

    const {
      data: { adminUser: adminUserResponse }
    } = await userService.loginGoogle({ adminUser });

    if (adminUserResponse.personId) {
      const { data } = await PeopleService.getResource(adminUserResponse.personId);
      resourceResponse = data;
    }

    return { user: adminUserResponse, resource: resourceResponse };
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const logout = createAsyncThunk('LOGOUT', async () => {
  try {
    await userService.logout();
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const signUp = createAsyncThunk('SIGNUP', async user => {
  try {
    const { data } = await userService.signUp({ user });
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

