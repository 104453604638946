import styled, { css } from 'styled-components';

import { ReactComponent as Arrow } from 'assets/icons/arrow-up.svg';
import Button from 'components/common/Button';

export const ButtonsGroup = styled.div`
  display: flex;
`;

export const StyledArrowButton = styled(Button)`
  width: 3.6rem;
  min-width: 3.6rem;
  height: 4.8rem;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.new.colors.N400};
  svg path {
    stroke-width: 1.67px;
    stroke: ${({ theme }) => theme.new.colors.N600};
  }
  ${({ position }) =>
    position === 'left'
      ? css`
          border-radius: 4px 0 0 4px;
          border-right: none;
        `
      : css`
          border-radius: 0 4px 4px 0;
          border-left: none;
        `}
`;

export const StyledButton = styled(Button)`
  width: auto;
  min-width: 3.6rem;
  height: 4.8rem;
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.new.colors.N400};
  padding: 8px 16px;
  background: ${({ theme }) => theme.new.colors.N100};
  z-index: 2;
`;

export const ArrowRight = styled(Arrow)`
  transform: rotate(90deg);
`;

export const ArrowLeft = styled(Arrow)`
  transform: rotate(-90deg);
`;
