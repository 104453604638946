import { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { DISPLAY_DATE } from 'constants/dateFormats';
import { dateRangeShape } from 'constants/shapes';
import { maxDate, addTime, diffTime, subtractTime, clampDate, minDate } from 'utils/date';

import { HorizontalSeparator } from '../Layout/Separator';
import RangeLengthSelector from './RangeLengthSelector';
import { DatePickerContainer, Label, ErrorSpan, DateRangeInputWrapper } from './styles';

import DatePicker from '.';

const RANGE_SELECT_TYPE = {
  SELECT_START: 'SELECT_START',
  SELECT_END: 'SELECT_END'
};

const DateRange = ({
  labels,
  names,
  errors,
  selected,
  onChange,
  onSelectionEnd = () => {},
  startDateProps,
  endDateProps,
  minDate: minDateConstraint,
  maxDate: maxDateConstraint,
  separation,
  showWeekCounter = false,
  keepRangeDuration = false,
  className = '',
  gap,
  variant = 'old',
  showMonthYearPicker,
  showYearPicker,
  showQuarterYearPicker,
  labelPosition,
  ...props
}) => {
  const intl = useIntl();

  const startDateRef = useRef();
  const endDateRef = useRef();

  const [weeksDuration, setWeeksDuration] = useState(0);
  const [suggestedStartDate, setSuggestedStartDate] = useState(() =>
    props.suggestDate ? new Date() : null
  );

  const onWeekChange = value => {
    const { startDate } = selected;
    if (!startDate) return;

    const tentativeEnd = subtractTime(addTime(startDate, value, 'week'), 1, 'day');
    const endDate = clampDate(tentativeEnd, startDate, maxDateConstraint);
    onChange({ startDate, endDate });
  };

  useEffect(() => {
    const daysDiff = Math.abs(diffTime(selected.startDate, selected.endDate, 'day'));
    const weeksDiff = Math.round(daysDiff / 7);
    setWeeksDuration(weeksDiff);
  }, [selected]);

  const [selectionType, setSelectionType] = useState(RANGE_SELECT_TYPE.SELECT_START);

  const handleChange = selectedDate => {
    suggestedStartDate && setSuggestedStartDate(null);
    switch (selectionType) {
      case RANGE_SELECT_TYPE.SELECT_START: {
        let { endDate } = selected;

        if (keepRangeDuration && endDate) {
          const daysMoved = diffTime(selected.startDate, selected.endDate, 'day');
          endDate =
            selectedDate && minDate(addTime(selectedDate, daysMoved, 'day'), maxDateConstraint);
        }

        if (selectedDate > endDate) onChange({ startDate: selectedDate, endDate: selectedDate });
        else onChange({ startDate: selectedDate, endDate });

        setSelectionType(RANGE_SELECT_TYPE.SELECT_END);
        endDateRef.current.input.focus();
        onSelectionEnd({ startDate: selectedDate, endDate: selected.endDate });
        break;
      }
      case RANGE_SELECT_TYPE.SELECT_END: {
        onSelectionEnd({ startDate: selected.startDate, endDate: selectedDate });
        onChange({ startDate: selected.startDate, endDate: selectedDate });
        setSelectionType(RANGE_SELECT_TYPE.SELECT_START);
        break;
      }
      default:
    }
  };

  const { startDate, endDate } = selected;
  const selectedDate = suggestedStartDate ?? startDate;

  return (
    <DateRangeInputWrapper className={className} gap={gap}>
      <DatePickerContainer errors={errors} variant={variant}>
        {labels.startDate && (
          <Label
            errors={errors}
            selected={selected || selected === null}
            htmlFor={names.startDate}
            variant={variant}
            isDayPicker={!showMonthYearPicker && !showYearPicker && !showQuarterYearPicker}
            labelPosition={labelPosition}
          >
            {labels.startDate}
          </Label>
        )}
        <DatePicker
          dateFormat={DISPLAY_DATE}
          errors={errors}
          todayButton={intl.formatMessage({ id: 'common.today' })}
          selectsStart
          onChange={handleChange}
          name={names.startDate}
          selected={selectedDate}
          startDate={selectedDate}
          endDate={endDate}
          openToDate={startDate}
          id="startDate"
          ref={startDateRef}
          onFocus={() => setSelectionType(RANGE_SELECT_TYPE.SELECT_START)}
          popperPlacement="bottom-start"
          minDate={minDateConstraint}
          maxDate={maxDateConstraint}
          variant={variant}
          showMonthYearPicker={showMonthYearPicker}
          showYearPicker={showYearPicker}
          showQuarterYearPicker={showQuarterYearPicker}
          {...startDateProps}
          {...props}
        >
          {showWeekCounter && (
            <RangeLengthSelector amount={weeksDuration} setAmount={onWeekChange} />
          )}
        </DatePicker>
      </DatePickerContainer>
      <HorizontalSeparator width={separation || 3.4} />
      <DatePickerContainer variant={variant}>
        {labels.endDate && (
          <Label
            errors={errors}
            selected={selected || selected === null}
            htmlFor={names.endDate}
            variant={variant}
            isDayPicker={!showMonthYearPicker && !showYearPicker && !showQuarterYearPicker}
            labelPosition={labelPosition}
          >
            {labels.endDate}
          </Label>
        )}
        <DatePicker
          dateFormat={DISPLAY_DATE}
          selected={selected.endDate}
          errors={errors}
          todayButton={intl.formatMessage({ id: 'common.today' })}
          selectsEnd
          onChange={handleChange}
          name={names.endDate}
          startDate={selected.startDate}
          endDate={selected.endDate}
          openToDate={selected.endDate}
          id="endDate"
          ref={endDateRef}
          onFocus={() => setSelectionType(RANGE_SELECT_TYPE.SELECT_END)}
          popperPlacement="bottom-end"
          minDate={maxDate(minDateConstraint, selected.startDate)}
          maxDate={maxDateConstraint}
          variant={variant}
          showMonthYearPicker={showMonthYearPicker}
          showYearPicker={showYearPicker}
          showQuarterYearPicker={showQuarterYearPicker}
          {...endDateProps}
          {...props}
        >
          {showWeekCounter && (
            <RangeLengthSelector amount={weeksDuration} setAmount={onWeekChange} />
          )}
        </DatePicker>
      </DatePickerContainer>
      {errors && <ErrorSpan>{errors}</ErrorSpan>}
    </DateRangeInputWrapper>
  );
};

DateRange.propTypes = dateRangeShape;

export default DateRange;
