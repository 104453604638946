import { ResponsiveContainer } from 'recharts';

const { default: styled } = require('styled-components');

export const StyledResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-wrapper .recharts-cartesian-grid-vertical line:first-child,
  .recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
    stroke-opacity: 0;
  }
`;
