import styled from 'styled-components';

export const Selectors = styled.div`
  align-items: center;
  display: flex;

  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;
