import { useSelector } from 'react-redux';

import useDispatch from 'hooks/useDispatch';
import { toggleUnassignedSkillsShown } from 'state/actions/peopleActions';

export default () => {
  const toggleUnassignedSkillsShownRequest = useDispatch(toggleUnassignedSkillsShown);
  const { unassignedSkillsShown, expandedUnassignedSkills } = useSelector(
    ({
      people: {
        resourcesTimeline: { unassignedSkillsShown, expandedUnassignedSkills }
      }
    }) => ({
      unassignedSkillsShown,
      expandedUnassignedSkills
    })
  );

  return { unassignedSkillsShown, expandedUnassignedSkills, toggleUnassignedSkillsShownRequest };
};
