import { arrayOf, bool, shape } from 'prop-types';
import { useIntl } from 'react-intl';

import userIcon from 'assets/icons/user.svg';
import LoadingWrapper from 'components/common/LoadingWrapper';
import Details from 'components/common/ProjectDetailsData/Details';
import { userShape } from 'constants/shapes';

import { Container } from './styles';

const emptyUsers = [
  { type: 'salesRepresentative' },
  { type: 'engineerManager' },
  { type: 'deliveryOwner' }
];

const ProjectUsers = ({ users, isLoading }) => {
  const intl = useIntl();
  const assignations = users.length ? users : emptyUsers;

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Container>
        {assignations.map(({ type, fullName, avatarUrl }) => (
          <Details
            key={type}
            subTitle={`projectDetails.${type}`}
            titleText={fullName || intl.formatMessage({ id: 'common.notAssigned' })}
            avatar={avatarUrl || userIcon}
          />
        ))}
      </Container>
    </LoadingWrapper>
  );
};

ProjectUsers.propTypes = {
  users: arrayOf(shape(userShape)),
  isLoading: bool
};

export default ProjectUsers;
