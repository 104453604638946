import styled from 'styled-components';

export const Input = styled.input`
  padding: ${({ padding }) => padding || '0 2rem'};
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.new.colors.neutral200};
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.new.colors.brand};
  margin: 0 auto;
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme }) => theme.new.colors.neutral900};
`;

export const Span = styled.span`
  padding: ${({ padding }) => padding || '0 2rem'};
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => width || '100%'};
  gap: 0.8rem;
`;
