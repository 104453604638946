import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';

import { EVENT_TYPE } from 'constants/constants';

const getTitleColor = ({ theme, type }) => {
  switch (type) {
    case EVENT_TYPE.EXIT_DATE:
      return theme.colors.red;
    default:
      return theme.colors.green;
  }
};

const getContentPadding = ({ type }) => {
  switch (type) {
    case EVENT_TYPE.EXIT_DATE:
      return '0.8rem 1.4rem';
    case EVENT_TYPE.TIME_OFF:
      return '1.2rem 1.4rem';
    default:
      return '0.8rem 1.3rem';
  }
};

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className, arrow: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3.51464px 16px rgba(0, 8, 65, 0.16);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 48px;
    top: 5px;
  }
  & .MuiTooltip-arrow {
    color: ${({ theme }) => theme.colors.white};
    font-size: 25px;
    margin-left: max(-40px, -25%);
    &::before,
    ::after {
      border-bottom-right-radius: 4.39331px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 0;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.2rem;
  text-aling: left;
  padding: ${props => getContentPadding(props)};
`;

export const TooltipContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const Title = styled.span`
  color: ${props => getTitleColor(props)};
  font-weight: ${({ theme }) => theme.fontWeights.heavyBold};
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.fontColor};
`;
