import { Tabs } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.4rem;
  border-radius: 6px;
`;

export const StyledTabs = styled(Tabs)({
  '& .MuiTab-root': {
    fontFamily: 'Noto Sans',
    fontSize: '1.4rem',
    textTransform: 'capitalize',
    color: ({ theme }) => theme.colors.seaBlue,
    backgroundColor: ({ theme }) => theme.colors.gray05,
    borderBottom: ({ theme }) => `1px solid ${theme.colors.seaBlue}`,
    minHeight: '40px'
  },
  '& .Mui-selected': {
    fontFamily: 'Noto Sans',
    fontSize: '1.5rem',
    fontWeight: ({ theme }) => theme.fontWeights.semiBold,
    backgroundColor: ({ theme }) => theme.colors.softSeaBlue,
    border: ({ theme }) => `1px solid ${theme.colors.seaBlue}`,
    borderBottom: 0,
    color: ({ theme }) => `${theme.colors.seaBlue} !important`
  },
  '& .MuiTabs-indicator': {
    height: 0
  }
});

export const TabPanelContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.seaBlue};
  border-top: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;
