import { node, string } from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import AccordionBody from './AccordionBody/index';
import AccordionHeader from './AccordionHeader/index';
import { AccordionContainer } from './styles';

const Accordion = ({ children, titleId }) => {
  const intl = useIntl();
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <AccordionContainer>
      <AccordionHeader
        collapsed={isCollapsed}
        toggleCollapse={() => setIsCollapsed(!isCollapsed)}
        title={intl.formatMessage({ id: titleId })}
      />
      <AccordionBody collapsed={isCollapsed}>{children}</AccordionBody>
    </AccordionContainer>
  );
};

Accordion.propTypes = {
  children: node.isRequired,
  titleId: string.isRequired
};

export default Accordion;
