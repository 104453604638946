import { func, bool, string } from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as DownArrowSvg } from 'components/icons/arrow-down.svg';
import { ReactComponent as RightArrowSvg } from 'components/icons/arrow-right.svg';

const CollapseButtonContainer = styled.button`
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  cursor: pointer;
  height: 100%;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  :focus {
    outline: 0;
  }
`;

const CollapseButton = ({ onClick, collapsed, centered = false, id }) => (
  <CollapseButtonContainer
    id={id}
    onClick={() => onClick && onClick(!collapsed)}
    centered={centered}
  >
    {collapsed ? <RightArrowSvg /> : <DownArrowSvg />}
  </CollapseButtonContainer>
);

CollapseButton.propTypes = {
  onClick: func,
  collapsed: bool.isRequired,
  centered: bool,
  id: string
};

export default CollapseButton;
