import { func, node, number, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';

import {
  Container,
  AssignmentStatus,
  IconWrapper,
  Status,
  NumbersRow,
  Total,
  Count
} from './styles';

function Card({ color, title, Icon, total, count }) {
  const intl = useIntl();

  return (
    <Container>
      <AssignmentStatus>
        <IconWrapper color={color}>
          <Icon />
        </IconWrapper>
        <Status>{title}</Status>
      </AssignmentStatus>
      <NumbersRow>
        <Total>{total}</Total>
        <Count>{`| ${count} ${intl.formatMessage({ id: `common.projects` })}`}</Count>
      </NumbersRow>
    </Container>
  );
}

Card.propTypes = {
  color: string,
  title: string,
  Icon: oneOfType([node, func]),
  total: number,
  count: number
};

export default Card;
