import { arrayOf, bool, func } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from 'components/common/Button';
import EmptyState from 'components/common/EmptyState';
import CenteredLoading from 'components/common/Loading/CenteredLoading';
import Cell from 'components/ProjectsOverview/ProjectsTable/Cell';
import {
  BUTTON_VARIANTS,
  GROUP_ROLE_MODAL_TYPES,
  SETTINGS_FILTERS,
  SORT_DIR
} from 'constants/constants';
import { reportsResourceShape, sortableTableColumnShape } from 'constants/shapes';
import { humanize } from 'utils/helpers';
import { sortTable } from 'utils/projectsOverviewUtilities';

import {
  ActionsWrapper,
  ArrowIcon,
  Column,
  HeaderColumnContainer,
  RolePill,
  Row,
  Table,
  TableWrapper
} from './styles';

const MappingTable = ({ resources = [], columns, isLoading, setModalOpen, setRelation }) => {
  const intl = useIntl();
  const [sortConfig, setSortConfig] = useState({
    key: 'name',
    direction: SORT_DIR.desc
  });

  const onSort = ({ key: columnKey }) => {
    let direction = SORT_DIR.desc;
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === SORT_DIR.desc) {
      direction = SORT_DIR.asc;
    }
    setSortConfig({
      key: columnKey,
      direction
    });
  };

  const sortedResources = useMemo(() => {
    if (!sortConfig) return resources;
    const sortedRows = sortTable({
      columns,
      sortConfig,
      rows: resources,
      sortType: SETTINGS_FILTERS.groupRoles
    });
    return sortedRows;
  }, [columns, resources, sortConfig]);

  const handleActions = (group, modalType) => {
    setRelation({
      group: group?.id,
      role: group?.role?.id,
      groupName: humanize(group?.group),
      roleName: group?.role?.name
    });

    setModalOpen(modalType);
  };

  return (
    <>
      <TableWrapper>
        <Table>
          <thead>
            <Row height="5.7rem" isHeader>
              {columns?.map(column => {
                const isSorting = sortConfig && sortConfig.key === column.key;
                return (
                  <th key={column.key}>
                    <HeaderColumnContainer
                      onClick={() => !isLoading && onSort(column)}
                      $disabled={isLoading}
                    >
                      <span>{column.header && intl.formatMessage({ id: column.header })}</span>
                      <Column width="1.2rem">
                        <ArrowIcon
                          direction={isSorting ? sortConfig.direction : SORT_DIR.desc}
                          $isSorting={!isLoading && isSorting}
                          disabled={isLoading}
                        />
                      </Column>
                    </HeaderColumnContainer>
                  </th>
                );
              })}
              <th>
                <HeaderColumnContainer $disabled={isLoading}>
                  <span>{intl.formatMessage({ id: 'settings.table.actions' })}</span>
                </HeaderColumnContainer>
              </th>
              <td />
            </Row>
          </thead>
          <tbody>
            {!isLoading && sortedResources?.length > 0 ? (
              sortedResources?.map(groupRole => {
                const roleId = groupRole?.id;
                const { role } = groupRole;

                return (
                  <Row key={roleId}>
                    <Cell
                      value={<RolePill>{humanize(groupRole?.group) || '--'}</RolePill>}
                      columnKey="roleGroup"
                      key={`${roleId}-roleGroup`}
                    />
                    <Cell value={role.name} columnKey="roleName" key={`${roleId}-actions}`} />

                    <Cell
                      value={
                        <ActionsWrapper>
                          <Button
                            variant={BUTTON_VARIANTS.NEW_SECONDARY}
                            onClick={() => handleActions(groupRole, GROUP_ROLE_MODAL_TYPES.DELETE)}
                          >
                            {intl.formatMessage({ id: 'settings.groupTable.deleteRole' })}
                          </Button>
                        </ActionsWrapper>
                      }
                      columnKey="actions"
                      key={`${roleId}-actions}`}
                    />
                  </Row>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <EmptyState
                    className="accessRoles"
                    titleIntlId="emptyState.title.relations"
                    subtitleIntlId="emptyState.subtitle.relations"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      {isLoading && <CenteredLoading />}
    </>
  );
};

MappingTable.propTypes = {
  columns: arrayOf(sortableTableColumnShape),
  isLoading: bool,
  resources: arrayOf(reportsResourceShape),
  setModalOpen: func,
  setRelation: func
};
export default MappingTable;
