import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;
  justify-content: center;
  max-height: 11.6rem;
  path {
    stroke: ${({ theme }) => theme.new.colors.neutral600};
  }
  text-align: center;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.new.typography.h3};
  color: ${({ theme }) => theme.new.colors.neutral900};
  display: flex;
  font-size: 1.8rem;
  line-height: 2.4rem;
`;

export const Subtitle = styled.h2`
  ${({ theme }) => theme.new.typography.p6};
  color: ${({ theme }) => theme.colors.neutral600};
  display: flex;
  font-size: 1.4rem;
  line-height: 1.9rem;
  white-space: pre;
`;
