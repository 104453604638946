import { useSelector } from 'react-redux';

export default () =>
  useSelector(
    ({
      people: {
        resourcesTimeline: { filters }
      }
    }) => ({
      resourceFilters: filters
    })
  );
