import styled from 'styled-components';

export const Collapsible = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0 3rem;
  padding: 2rem;
`;
