import styled, { css } from 'styled-components';

import { ReactComponent as InfoIcon } from 'assets/icons/info-filled.svg';

export const Box = styled.button`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.6rem;
  border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  border-radius: 0.7rem;
  width: 100%;
  background: ${({ theme }) => theme.new.colors.white};
  cursor: text;
  user-select: text;
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
      &:focus {
        border: 1px solid ${({ theme }) => theme.new.colors.neutral300};
        box-shadow: 0 0 0 4px rgba(71, 111, 242, 0.12);
      }
      &:hover {
        ${({ theme }) => theme.new.shadows.large}
        border: 1px solid ${({ theme }) => theme.new.colors.neutral600};
      }
      &:active {
        ${({ theme }) => theme.new.shadows.large}
        background-color: ${({ theme }) => theme.new.colors.neutral100};
        border: 1px solid ${({ theme }) => theme.new.colors.neutral400};
      }
    `}
`;

export const Description = styled.div`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral700};
  display: flex;
  gap: 0.4rem;
  justify-content: center;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Title = styled.span`
  ${({ theme }) => theme.new.typography.p2SemiBold};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.new.colors.neutral900};
`;
