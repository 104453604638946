import styled, { css } from 'styled-components';

import Button from 'components/common/Button';

import EmptyState from '../EmptyState';

export const DynamicBlock = styled.div`
  ${({ theme, isEmpty }) =>
    isEmpty &&
    css`
      border: 1px solid ${theme.new.colors.neutral300};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}

  display: flex;
  gap: 1.2rem;
  overflow-x: auto;
  padding: 1.8rem 0 1.4rem;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 1.1rem;
  }

  ::-webkit-scrollbar:horizontal {
    width: 5rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.new.colors.white};
    background-color: ${({ theme }) => theme.new.colors.neutral300};
  }
`;

export const EmptyContainer = styled.div`
  width: 100%;
`;

export const OpenPeriodsContainer = styled.div`
  border-left: 4px solid ${({ theme }) => theme.new.colors.P700};
  height: 100%;
  margin-left: 1.4rem;
  padding: 1.4rem 0 0 1.4rem;
`;

export const StyledEmptyState = styled(EmptyState)`
  border-radius: 0;
  border-radius-bottom-right: 4px;
`;

export const AddPeriodButton = styled(Button)`
  font-weight: ${({ theme }) => theme.new.fontWeights.semiBold};
  min-width: auto;
  padding: 0.8rem 1.2rem;
`;
