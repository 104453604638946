import { arrayOf, func, instanceOf, oneOf, oneOfType, string } from 'prop-types';
import { Fragment, useMemo } from 'react';

import { TimelineRowContent } from 'components/common/Layout/Timeline';
import ProjectStatus from 'components/timeline/Fragments/ProjectStatus';
import NewProjectStatusRow from 'components/timeline/Fragments/ProjectStatus/NewProjectStatusRow';
import { PROJECT_TYPES } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { newProjectStatusShape } from 'constants/shapes';
import { useRole } from 'hooks';
import { isBefore } from 'utils/date';
import hasPermission from 'utils/hasPermission';
import { getTimelineFragments, trimDates } from 'utils/timelineUtilities';

const ProjectStatuses = ({
  editStatus,
  allStatuses,
  newProjectStatusProps,
  serviceType,
  billableType,
  startDateTimeline,
  endDateTimeline
}) => {
  const { permissions } = useRole();

  const projectStatus = useMemo(() => {
    const [firstNewStatus, lastNewStatus] = newProjectStatusProps.map(newStatusProps => ({
      ...newStatusProps,
      component: <NewProjectStatusRow {...newStatusProps} />
    }));

    const statuses = allStatuses.map((status, index) => {
      const { startDate, endDate, isStarted, isFinished } = trimDates(
        status.startDate,
        status.endDate,
        startDateTimeline,
        endDateTimeline
      );

      const handleClick = () => {
        if (hasPermission(permissions, [SCOPES.editProjectStatus])) {
          const { id, startDate, endDate, status: statusName } = status;
          return () => editStatus({ startDate, endDate, name: statusName, id });
        }

        return undefined;
      };

      return {
        ...status,
        component: (
          <ProjectStatus
            key={`project-status-${index}`}
            status={status}
            startDate={startDate}
            endDate={endDate}
            isNonBillable={billableType === PROJECT_TYPES.NON_BILLABLE}
            onClick={handleClick()}
            serviceType={serviceType}
            isStarted={isStarted}
            isFinished={isFinished}
          />
        )
      };
    });

    const projectStatusFragments = getTimelineFragments(
      startDateTimeline,
      endDateTimeline
    )([firstNewStatus, ...statuses, lastNewStatus]);

    return projectStatusFragments.map(({ startDate, endDate, ...fragment }, index) => {
      if (isBefore(endDate, startDateTimeline) || isBefore(endDateTimeline, startDate)) return null;
      if (!fragment.value) {
        return <NewProjectStatusRow key={index} startDate={startDate} endDate={endDate} />;
      }
      return <Fragment key={index}>{fragment.value.component}</Fragment>;
    });
  }, [
    newProjectStatusProps,
    allStatuses,
    startDateTimeline,
    endDateTimeline,
    billableType,
    serviceType,
    permissions,
    editStatus
  ]);

  return <TimelineRowContent height={6.6}>{projectStatus}</TimelineRowContent>;
};

ProjectStatuses.propTypes = {
  allStatuses: arrayOf(newProjectStatusShape),
  editStatus: func,
  newProjectStatusProps: arrayOf(newProjectStatusShape),
  serviceType: string,
  billableType: oneOf(Object.values(PROJECT_TYPES)),
  startDateTimeline: oneOfType([string, instanceOf(Date)]),
  endDateTimeline: oneOfType([string, instanceOf(Date)])
};

export default ProjectStatuses;
