import OverviewBlock from 'components/BenchInsight/OverviewBlock';
import BenchList from 'components/BenchInsights/BenchList';
import QuarterBlocks from 'components/BenchInsights/QuartersBlocks';
import PageHeader from 'components/common/PageHeader';

import { Container, Content } from './styles';

const BenchInsights = () => (
  <Container>
    <PageHeader titleIntlId="common.cap.insights" subtitleIntlId="common.bench" />
    <Content>
      <OverviewBlock />
      <QuarterBlocks />
      <BenchList />
    </Content>
  </Container>
);

export default BenchInsights;
