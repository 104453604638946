import { func, number, string } from 'prop-types';

import InlineTextEditor from 'components/common/InlineTextEditor';
import { OpenPeriodInput } from 'components/Financial/SplitCostsGrid/PeriodItem/styles';
import { NUMERIC_FORMAT_PROPS } from 'constants/constants';

export const AdditionalChargeInput = ({ value, onChange, field, ...props }) => {
  const handleChange = value => onChange(field, value);

  return (
    <InlineTextEditor
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={handleChange}
      showInput
      CustomInput={OpenPeriodInput}
      {...NUMERIC_FORMAT_PROPS}
      {...props}
    />
  );
};

AdditionalChargeInput.propTypes = {
  value: number,
  field: string,
  onChange: func
};

export default AdditionalChargeInput;
