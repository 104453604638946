import styled from 'styled-components';

export const TableRow = styled.div`
  background-color: ${({ theme }) => theme.new.colors.N100};
  display: grid;
  height: 6.8rem;
  gap: 3rem;
  grid-template-columns: 2.5fr 2.5fr 1.5fr 2fr 2fr 1fr 4fr 0.5fr;
  padding: 1rem;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    height: 100%;
    ${({ theme }) => theme.new.typography.p5};
  }
`;
export const Profile = styled.button`
  align-items: center;
  display: flex;
  gap: 1rem;
  border: none;

  h2 {
    margin: 0;
    ${({ theme }) => theme.new.typography.p4SemiBold};
    width: 8rem;
  }
`;

export const Skills = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MatchOverall = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const Margin = styled.div`
  height: 2.4rem;
  align-self: center;
`;
