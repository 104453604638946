import { bool } from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Row } from 'components/common/Layout/Row';
import { ReactComponent as SearchSvg } from 'components/icons/search.svg';
import { ReactComponent as UserSvg } from 'components/icons/user.svg';
import { SCOPES } from 'constants/permissions';
import { useHeights, usePeople, useRole, useStatus } from 'hooks';
import { getMinimalResources } from 'state/actions/peopleActions';
import hasPermission from 'utils/hasPermission';

import fields from '../fields.json';
import { StyledSelect } from './styles';

const ProjectLeaders = ({ isPendingProject }) => {
  const intl = useIntl();
  const { control } = useFormContext();

  const { permissions } = useRole();
  const { salesPeopleOptions, deliveryOwnerOptions, managerOptions } = usePeople();
  const { isPending: isLoadingGetMinimalResources } = useStatus(getMinimalResources);
  const { inputHeight } = useHeights();

  return (
    <>
      <Row gap="1.2rem">
        <Controller
          name={fields.salesPersonId}
          control={control}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="salesDrp"
              height={inputHeight}
              label={intl.formatMessage({ id: 'common.salesExecutive' })}
              options={salesPeopleOptions || []}
              isLoading={isLoadingGetMinimalResources}
              isSearchable
              isClearable
              enablePortal
              isDisabled={!hasPermission(permissions, [SCOPES.editProjects])}
              variant="new"
              withoutHighlight
              placeholder={intl.formatMessage({ id: 'projectForm.salesExecutive.placeholder' })}
              icon={SearchSvg}
              selectedIcon={UserSvg}
              optionIcon={UserSvg}
            />
          )}
        />
      </Row>
      <Row gap="1.2rem">
        <Controller
          name={fields.deliveryOwnerPersonId}
          control={control}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="deliveryOwnerDrp"
              height={inputHeight}
              label={intl.formatMessage({ id: 'common.deliveryOwner' })}
              options={deliveryOwnerOptions || []}
              isLoading={isLoadingGetMinimalResources || isPendingProject}
              isSearchable
              isClearable
              enablePortal
              isDisabled={!hasPermission(permissions, [SCOPES.editProjects])}
              variant="new"
              withoutHighlight
              placeholder={intl.formatMessage({ id: 'projectForm.deliveryOwner.placeholder' })}
              icon={SearchSvg}
              selectedIcon={UserSvg}
              optionIcon={UserSvg}
            />
          )}
        />
      </Row>
      <Row gap="1.2rem">
        <Controller
          name={fields.managerPersonId}
          control={control}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="managerDrp"
              height={inputHeight}
              label={intl.formatMessage({ id: 'common.manager' })}
              options={managerOptions || []}
              isLoading={isLoadingGetMinimalResources || isPendingProject}
              isSearchable
              isClearable
              enablePortal
              isDisabled={!hasPermission(permissions, [SCOPES.editProjects])}
              variant="new"
              withoutHighlight
              placeholder={intl.formatMessage({ id: 'projectForm.manager.placeholder' })}
              icon={SearchSvg}
              selectedIcon={UserSvg}
              optionIcon={UserSvg}
            />
          )}
        />
      </Row>
    </>
  );
};

ProjectLeaders.propTypes = {
  isPendingProject: bool
};

export default ProjectLeaders;
