import styled from 'styled-components';

import Button from 'components/common/Button';

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-top: 1.5rem;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 20.75rem;
`;
