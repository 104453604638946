import { ExpandMore } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import styled from 'styled-components';

export const AccordionWrapper = styled(MuiAccordion)`
  box-shadow: none !important;
  font-family: ${({ theme }) => theme.new.fontFamily};
  width: 100%;
  padding-top: 2rem;
  border-top: 1px solid ${({ theme }) => theme.new.colors.N300};

  &:before {
    display: none !important;
  }

  &.Mui-expanded {
    margin: 0 !important;
  }
`;

export const AccordionTitle = styled.h4`
  ${({ theme }) => theme.new.typography.h3};
  color: ${({ theme }) => theme.new.colors.N600};
`;

export const ExpandIcon = styled(ExpandMore)`
  &.MuiSvgIcon-root {
    height: 2.4rem;
    width: 2.4rem;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  &,
  &.Mui-expanded {
    min-height: auto !important;
    padding: 0 !important;
  }

  & .MuiAccordionSummary-content {
    align-items: center;
    display: flex;
    margin: 0.8rem 0 !important;
    min-height: 4.4rem;
    padding: 0.6rem 0.8rem 1.4rem 0.8rem;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 0.8rem !important;
  padding-right: 0 !important;
`;
