import { useSelector } from 'react-redux';

const useFinancial = () => {
  const {
    projectDetails,
    resourceSetup,
    openPeriods: { resources, periods },
    closedPeriods,
    lastPeriodEndDate
  } = useSelector(state => state.financial);

  return {
    projectDetails,
    resourceSetup,
    openPeriodResources: resources,
    openPeriodPeriods: periods,
    closedPeriods,
    lastPeriodEndDate
  };
};

export default useFinancial;
