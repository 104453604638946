import { arrayOf, bool } from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Checkbox from 'components/common/Checkbox';
import DateRange from 'components/common/DatePicker/DateRange';
import { Row, CheckboxWrapper } from 'components/common/Layout/Row';
import { ReactComponent as SearchSvg } from 'components/icons/search.svg';
import { SCOPES } from 'constants/permissions';
import { projectStatusArray, projectServiceTypeArray } from 'constants/projectConstants';
import { customerOptionShape } from 'constants/shapes';
import { newProjectFormValidations, editProjectFormValidations } from 'constants/validators';
import { useHeights, useIndustries, useRole } from 'hooks';
import hasPermission from 'utils/hasPermission';

import fields from '../fields.json';
import { StyledInput, StyledSelect } from './styles';

const BasicInfo = ({ edit, isPendingProject, customerOptions, isCustomerPending }) => {
  const intl = useIntl();
  const {
    register,
    control,
    formState: { errors },
    setValue,
    trigger
  } = useFormContext();

  const { permissions } = useRole();
  const { industriesOptions } = useIndustries();
  const { inputHeight } = useHeights();

  const validations = edit ? editProjectFormValidations : newProjectFormValidations;

  const onCreateOption = value => {
    setValue(fields.clientId, { label: value });
    setValue(fields.clientName, value);
    trigger([fields.clientId]);
  };

  const onClientChange = (value, { action }) => {
    if (action === 'clear') {
      setValue(fields.clientName, value);
    }
  };

  return (
    <>
      <Row gap="1.2rem">
        <StyledInput
          id="projectNameTxt"
          name={fields.name}
          type="text"
          label={intl.formatMessage({ id: 'common.projectName' })}
          fullWidth
          disabled={!hasPermission(permissions, [SCOPES.editProjects])}
          padding="0"
          placeholder={intl.formatMessage({ id: 'projectForm.name.placeholder' })}
          {...register(fields.name, validations[fields.name])}
          errors={errors[fields.name]?.message}
        />
        <Controller
          name={fields.clientId}
          control={control}
          rules={{
            validate: (value, formValues) =>
              !!value || !!formValues.clientName || intl.formatMessage({ id: 'customer.presence' })
          }}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="clientNameTxt"
              label={intl.formatMessage({ id: 'common.client' })}
              options={customerOptions}
              isLoading={isCustomerPending || isPendingProject}
              isSearchable
              isClearable
              enablePortal
              isDisabled={!hasPermission(permissions, [SCOPES.editProjects])}
              variant="new"
              withoutHighlight
              placeholder={intl.formatMessage({ id: 'projectForm.client.placeholder' })}
              icon={SearchSvg}
              errors={errors[fields.clientId]?.message}
              isCreateable
              onCreateOption={onCreateOption}
              onChange={(value, action) => {
                onClientChange(value, action);
                field.onChange(value);
              }}
            />
          )}
        />
      </Row>
      <Row gap="1.2rem">
        <StyledInput
          id="projectCodeTxt"
          name={fields.code}
          type="text"
          label={intl.formatMessage({ id: 'common.cap.projectCode' })}
          fullWidth
          disabled={!hasPermission(permissions, [SCOPES.editProjects])}
          padding="0"
          placeholder={intl.formatMessage({ id: 'projectForm.code.placeholder' })}
          {...register(fields.code, validations[fields.code])}
          errors={errors[fields.code]?.message}
        />
        <Controller
          name={fields.serviceType}
          control={control}
          rules={{ ...validations[fields.serviceType] }}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="serviceDrp"
              height={inputHeight}
              label={intl.formatMessage({ id: 'common.projectType' })}
              options={projectServiceTypeArray.map(project => ({
                ...project,
                label: intl.formatMessage({ id: project.label })
              }))}
              isSearchable
              isClearable
              enablePortal
              isDisabled={!hasPermission(permissions, [SCOPES.editProjects])}
              variant="new"
              withoutHighlight
              placeholder={intl.formatMessage({ id: 'projectForm.serviceType.placeholder' })}
              errors={errors[fields.serviceType]?.message}
            />
          )}
        />
      </Row>
      {!edit && (
        <Row gap="1.2rem">
          <Controller
            name={fields.dates}
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateRange
                selected={value}
                onChange={onChange}
                names={{ startDate: fields.startDate, endDate: fields.endDate }}
                disabledKeyboardNavigation
                showWeekCounter
                keepRangeDuration
                separation={0}
                gap="0.55rem"
                variant="new"
                labels={{
                  startDate: intl.formatMessage({ id: 'common.cap.startDate' }),
                  endDate: intl.formatMessage({ id: 'common.cap.endDate' })
                }}
              />
            )}
          />
        </Row>
      )}
      <Row gap="1.2rem">
        <Controller
          name={fields.industryId}
          control={control}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="industryDrp"
              height={inputHeight}
              label={intl.formatMessage({ id: 'common.industry' })}
              options={industriesOptions}
              isSearchable
              isClearable
              isLoading={isPendingProject}
              enablePortal
              isDisabled={!hasPermission(permissions, [SCOPES.editProjects])}
              variant="new"
              withoutHighlight
              placeholder={intl.formatMessage({ id: 'projectForm.industry.placeholder' })}
              optional
            />
          )}
        />

        {!edit && (
          <Controller
            name={fields.status}
            control={control}
            rules={{ ...validations[fields.status] }}
            render={({ field }) => (
              <StyledSelect
                {...field}
                id="statusDrp"
                height={inputHeight}
                label={intl.formatMessage({ id: 'common.status' })}
                isLoading={isPendingProject}
                options={projectStatusArray.map(project => ({
                  ...project,
                  label: intl.formatMessage({ id: project.label })
                }))}
                isSearchable
                isClearable
                enablePortal
                variant="new"
                withoutHighlight
                placeholder={intl.formatMessage({ id: 'projectForm.status.placeholder' })}
                errors={errors[fields.status]?.message}
              />
            )}
          />
        )}
      </Row>
      <CheckboxWrapper paddingTop="1rem">
        <Controller
          name={fields.projectType}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              id="nonBillableOpt"
              label={intl.formatMessage({ id: 'common.nonBillable' })}
              disabled={!hasPermission(permissions, [SCOPES.editProjects])}
              variant="new"
            />
          )}
        />
      </CheckboxWrapper>
    </>
  );
};

BasicInfo.propTypes = {
  edit: bool,
  isPendingProject: bool,
  customerOptions: arrayOf(customerOptionShape),
  isCustomerPending: bool
};

export default BasicInfo;
