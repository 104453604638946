import { arrayOf, bool } from 'prop-types';

import { AssignmentShape, peopleSearchShape } from 'constants/shapes';

import Card from '../Card';
import EmployeeCard from '../Card/Employee';
import Requirements from '../Card/Requirements';
import EmptyState from '../EmptyState';
import { BestMatchesContainer, Container } from './styles';

const BestMatches = ({ bestMatches, requirement, displayMargin }) => (
  <Container>
    <Card variant="secondary">
      <Requirements
        role={requirement.role?.name}
        seniority={requirement.seniority}
        allocation={requirement.percentage}
        skills={requirement.skills}
        startDate={requirement.startDate}
        endDate={requirement.endDate}
      />
    </Card>

    {bestMatches.length ? (
      <BestMatchesContainer>
        {bestMatches.slice(0, 4).map(employee => (
          <Card key={employee.id}>
            <EmployeeCard
              employee={employee}
              skillsPerAssignment={requirement.skills}
              displayMargin={displayMargin}
            />
          </Card>
        ))}
      </BestMatchesContainer>
    ) : (
      <EmptyState
        titleIntlId="emptyState.title.resources"
        subtitleIntlId="emptyState.subtitle.resources"
      />
    )}
  </Container>
);

BestMatches.propTypes = {
  bestMatches: arrayOf(peopleSearchShape),
  requirement: AssignmentShape,
  displayMargin: bool
};

export default BestMatches;
