import { string } from 'prop-types';

import { Wrapper } from './styles';

import Loading from '.';

const CenteredLoading = ({ maxHeight }) => (
  <Wrapper maxHeight={maxHeight}>
    <Loading />
  </Wrapper>
);

CenteredLoading.propTypes = {
  maxHeight: string
};

export default CenteredLoading;
