import { arrayOf, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import { useState } from 'react';

import PeriodInfoModal from 'components/Financial/PeriodInfoModal';
import { periodDetailShape, periodShape } from 'constants/shapes';
import { useDispatch, useRequest } from 'hooks';
import { updateClosedPeriodHeader } from 'state/actions/financialActions';

import PeriodNewTable from '../PeriodNewTable';
import { Container } from './styles';

const tableColumnsKeys = {
  name: 'name',
  periodLength: 'periodLength',
  // generalCosts: 'generalCosts', TO DO add general costs once it's supported by backend
  billed: 'billed',
  additional: 'additional',
  fixedPrice: 'fixedPrice',
  margin: 'margin',
  note: 'note'
};

const tableColumns = Object.values(tableColumnsKeys).map(columnKey => ({
  key: columnKey,
  header: `financial.closePeriod.table.${columnKey}`,
  greaterFunc: (a, b) => !b || a > b,
  lowerFunc: (a, b) => !a || a < b
}));

function PeriodTable({
  expandedRows,
  setExpandedRows,
  activeTab,
  periods,
  isLoading,
  projectId,
  periodDetailCopy,
  setPeriodDetailCopy,
  editId,
  setEditId
}) {
  const [editingPeriod, setEditingPeriod] = useState(null);
  const resetUpdateRequest = useDispatch(updateClosedPeriodHeader.reset);

  const isEditingPeriod = Boolean(editingPeriod);

  const [{ isPending: isLoadingUpdatePeriod, error: updateError }, updatePeriodHeaderRequest] =
    useRequest(
      updateClosedPeriodHeader,
      {
        fulfilledCallback: () => {
          setEditingPeriod(null);
        }
      },
      false
    );

  const handleEditPeriodInfo = values => {
    updatePeriodHeaderRequest({
      id: editingPeriod?.id,
      projectId,
      ...values
    });
  };

  const handleEditPeriod = periodId => {
    const editedPeriod = periods.find(({ id }) => periodId === id);
    setEditingPeriod(editedPeriod);
  };

  return (
    <>
      <Container>
        <PeriodNewTable
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          activeTab={activeTab}
          periods={periods}
          columns={tableColumns}
          isLoading={isLoading}
          handleEditPeriod={handleEditPeriod}
          projectId={projectId}
          periodDetailCopy={periodDetailCopy}
          setPeriodDetailCopy={setPeriodDetailCopy}
          editId={editId}
          setEditId={setEditId}
        />
      </Container>
      {isEditingPeriod && (
        <PeriodInfoModal
          hide={() => setEditingPeriod(null)}
          open={isEditingPeriod}
          openPeriod={editingPeriod}
          isEdit
          error={updateError}
          isLoading={isLoadingUpdatePeriod}
          onSubmit={handleEditPeriodInfo}
          resetRequest={resetUpdateRequest}
          isDatesDisabled
        />
      )}
    </>
  );
}

PeriodTable.propTypes = {
  expandedRows: object,
  setExpandedRows: func,
  activeTab: string,
  isLoading: bool,
  projectId: oneOfType([func, shape({ current: number })]),
  periods: arrayOf(periodShape),
  periodDetailCopy: arrayOf(periodDetailShape),
  setPeriodDetailCopy: func,
  editId: oneOfType([number, string]),
  setEditId: func
};

export default PeriodTable;
