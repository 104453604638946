import styled from 'styled-components';

export const BreadcrumbContainer = styled.ul`
  padding: 10px 16px;
  list-style: none;
`;

export const BreadcrumbItem = styled.li`
  display: inline;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.black};

  & + li::before {
    padding: 10px;
    content: '>';
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  &:last-child {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  & a {
    color: ${({ theme }) => theme.colors.seaBlue};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.seaBlue};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      text-decoration: underline;
    }
  }
`;
