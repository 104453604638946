import { arrayOf, bool, func, shape } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as BillableIcon } from 'assets/icons/billable-type.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-option.svg';
import { ReactComponent as FolderPlusIcon } from 'assets/icons/folder-plus.svg';
import ProjectUsers from 'components/ProjectDetails/ProjectUsers';
import { BUTTON_VARIANTS } from 'constants/constants';
import { projectSummaryShape, userShape } from 'constants/shapes';
import { useCustomer } from 'hooks';
import { formatResourceDate } from 'utils/date';
import { humanize } from 'utils/helpers';

import Button from '../Button';
import Details from './Details';
import {
  BillablePill,
  CreationDates,
  DeleteProjectButton,
  ArchiveProjectButton,
  Header,
  LeadersContainer,
  NotesContainer,
  ProjectCode,
  ProjectInformation,
  Title
} from './styles';
import { getProjectData } from './utils';

const ProjectDetailsData = ({
  project,
  users,
  isLoading,
  setEditProject,
  setIsShowEditProject,
  confirmDelete,
  confirmArchive
}) => {
  const intl = useIntl();
  const { clients } = useCustomer();
  const projectItems = getProjectData(project, clients, intl);
  const isNotBillable = project?.projectType.includes('non');

  const author = (time, author) => {
    const formattedDate = formatResourceDate(time);
    const authorText = author
      ? intl.formatMessage(
          { id: 'projectDetails.details.postAuthor', defaultMessage: 'by {author}' },
          { author }
        )
      : '';

    return `${formattedDate} ${authorText}`;
  };

  return (
    <>
      <Header>
        <Title>{intl.formatMessage({ id: 'projectDetails.details.title' })}</Title>
        <Button
          variant={BUTTON_VARIANTS.NEW_PRIMARY}
          textIntlId="common.edit"
          icon={<EditIcon />}
          padding="1.4rem"
          onClick={() => {
            setEditProject(project?.id);
            setIsShowEditProject(true);
          }}
        />
      </Header>
      <ProjectInformation>
        {projectItems.map(({ item, title, avatar }) => (
          <Details key={`${title}-item`} item={item} title={title} avatar={avatar} />
        ))}
        <ProjectCode>
          <span>{intl.formatMessage({ id: 'common.cap.projectCode' })}:</span>
          <span>{project?.code}</span>
        </ProjectCode>
        <BillablePill nonBillable={isNotBillable}>
          {!isNotBillable && <BillableIcon />}
          {humanize(project.projectType)}
        </BillablePill>
      </ProjectInformation>
      <LeadersContainer>
        <p>{intl.formatMessage({ id: 'common.projectLeaders' })}</p>
        <ProjectUsers users={users} isLoading={isLoading} />
      </LeadersContainer>
      <NotesContainer>
        <p>{intl.formatMessage({ id: 'common.notes' })}</p>
        <span>
          {project.notes || intl.formatMessage({ id: 'searchPeople.peopleSummary.noNotes' })}
        </span>
      </NotesContainer>
      <CreationDates>
        <span>
          <p>{intl.formatMessage({ id: 'common.createdOn' })}:</p>
          <p>{author(project?.createdAt, project?.createdBy)}</p>
        </span>
        <span>
          <p>{intl.formatMessage({ id: 'common.lastEdited' })}:</p>
          <p>{author(project?.updatedAt, project?.updatedBy)}</p>
        </span>
      </CreationDates>
      <ArchiveProjectButton
        variant={BUTTON_VARIANTS.CLEAR}
        icon={<FolderPlusIcon />}
        textIntlId="projects.archiveProject"
        onClick={confirmArchive}
      />
      <DeleteProjectButton
        variant={BUTTON_VARIANTS.CLEAR}
        icon={<DeleteIcon />}
        textIntlId="projects.delete"
        onClick={confirmDelete}
      />
    </>
  );
};

ProjectDetailsData.propTypes = {
  confirmDelete: func,
  confirmArchive: func,
  isLoading: bool,
  project: projectSummaryShape,
  setEditProject: func,
  setIsShowEditProject: func,
  users: arrayOf(shape(userShape))
};

export default ProjectDetailsData;
