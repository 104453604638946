import { Link } from 'react-router-dom';

import RoundAvatar from 'components/common/RoundAvatar';
import routesPaths from 'constants/routesPaths';
import { resourceShape } from 'constants/shapes';
import { routeWithProps } from 'utils/helpers';

import { Container, ResourceInfo, ResourceName, ResourceRole } from './styles';

const ResourceDetail = ({ resource }) => (
  <Container>
    <RoundAvatar src={resource.avatarUrl} />
    <ResourceInfo>
      <Link target="_blank" to={routeWithProps(routesPaths.myReportsProfile, { id: resource.id })}>
        <ResourceName>{resource.name}</ResourceName>
      </Link>
      <ResourceRole>{resource.role}</ResourceRole>
    </ResourceInfo>
  </Container>
);

ResourceDetail.propTypes = {
  resource: resourceShape.isRequired
};

export default ResourceDetail;
