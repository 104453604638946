import { isEmpty } from 'lodash';
import { func, bool, number } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import NewModal from 'components/common/NewModal';
import { FULFILLED, PENDING } from 'constants/actionStatusConstants';
import {
  useDispatch,
  useActionOnSuccess,
  useAssignmentOptions,
  useStatus,
  useConfirm,
  useRequest
} from 'hooks';
import {
  getAssignmentById,
  editAssignment,
  deleteAssignment
} from 'state/actions/assignmentActions';
import { getProjectById } from 'state/actions/projectActions';
import {
  initialAssignmentOptions,
  rolesToOption,
  seniorityToOption
} from 'utils/assignmentFormsUtilities';
import { getProjectMinMaxDates } from 'utils/date';

import AssignmentForm from '../AssignmentForm';

const EditAssignmentModal = ({
  projectId,
  assignmentId,
  isShowing,
  hide,
  editAssignmentAction = editAssignment,
  handleRefreshData,
  deleteAssignmentAction = deleteAssignment,
  overview = false
}) => {
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState(null);
  const deleteAssignmentRequest = useDispatch(deleteAssignmentAction);
  const editAssignmentRequest = useDispatch(editAssignmentAction);
  const resetDeleteStatusRequest = useDispatch(deleteAssignmentAction?.reset);
  const resetEditStatusRequest = useDispatch(editAssignmentAction?.reset);
  const resetGetAssignmentRequest = useDispatch(getAssignmentById.reset);
  const { status: deleteAssignmentStatus } = useStatus(deleteAssignmentAction);

  const { status: editAssignmentStatus, error: editAssignmentError } =
    useStatus(editAssignmentAction);

  const assignmentOptions = useAssignmentOptions();

  const [{ response: project }] = useRequest(getProjectById, {
    params: { projectId }
  });

  const [{ response: assignment }] = useRequest(getAssignmentById, {
    params: { assignmentId }
  });

  const onSubmit = async ({ assignment, note }) => {
    const payload = {
      assignment,
      note,
      assignmentId: initialValues.id
    };
    const { type } = await editAssignmentRequest(payload);

    if (type.endsWith(`/${FULFILLED}`)) {
      handleRefreshData?.();
    }
  };

  const [ConfirmationDelete, confirmDelete] = useConfirm({
    title: intl.formatMessage({ id: 'modal.confirm.title' }),
    message: intl.formatMessage({ id: 'template.deleteEntity' }, { entity: 'assignment' }),
    onConfirm: () =>
      deleteAssignmentRequest({
        assignmentId,
        projectId,
        personId: assignment.personId
      }),
    onSuccess: () => handleRefreshData?.(),
    confirmLabelIntlId: 'common.delete',
    cancelLabelIntlId: 'common.cancel'
  });

  useActionOnSuccess(
    [deleteAssignmentStatus, editAssignmentStatus],
    overview ? handleRefreshData : hide
  );

  useEffect(() => {
    if (!isEmpty(assignment) && !initialValues) {
      const { personId, skillIds, role, seniority: seniorityKey } = assignment;

      const initialAssignment = initialAssignmentOptions(personId, skillIds, assignmentOptions);

      const [startDate, endDate] = getProjectMinMaxDates(assignment);
      const assignmentInitialValues = {
        ...assignment,
        ...initialAssignment,
        startDate,
        endDate,
        role: role && rolesToOption(role),
        seniority: seniorityKey && seniorityToOption(seniorityKey, intl.formatMessage)
      };
      setInitialValues(assignmentInitialValues);
    }
  }, [assignment, assignmentOptions, initialValues, intl.formatMessage]);

  useEffect(
    () => () => {
      resetDeleteStatusRequest();
      resetEditStatusRequest();
      resetGetAssignmentRequest();
    },
    [resetDeleteStatusRequest, resetEditStatusRequest, resetGetAssignmentRequest]
  );

  return (
    <NewModal
      isShowing={isShowing}
      hide={hide}
      title={intl.formatMessage({ id: 'template.editAssignment' }, { projectName: project?.name })}
      maxWidth="64rem"
      contentPadding="2.4rem"
    >
      {project && initialValues ? (
        <AssignmentForm
          project={project}
          onSubmit={onSubmit}
          resetStatus={resetEditStatusRequest}
          status={editAssignmentStatus}
          error={editAssignmentError}
          hide={hide}
          initialValues={initialValues}
          onDelete={confirmDelete}
          isDeleteLoading={deleteAssignmentStatus === PENDING}
          showCopy={false}
        />
      ) : (
        <CenteredLoading />
      )}
      <ConfirmationDelete />
    </NewModal>
  );
};

EditAssignmentModal.propTypes = {
  projectId: number.isRequired,
  assignmentId: number.isRequired,
  isShowing: bool.isRequired,
  hide: func.isRequired,
  editAssignmentAction: func,
  handleRefreshData: func,
  deleteAssignmentAction: func,
  overview: bool
};

export default EditAssignmentModal;
