import styled from 'styled-components';

export const TimelineGridContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
`;

export const GridTrackSpace = styled.div`
  width: ${({ theme }) => theme.constantWidths.timelineHeader};
  flex-shrink: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;

  &::after {
    border-right: 1px solid ${({ theme }) => theme.new.colors.N500};
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.stickyTimeline};
  }

  &::before {
    border-left: 1px solid ${({ theme }) => theme.new.colors.N500};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.stickyTimeline};
  }
`;

export const Section = styled.div`
  border-left: ${({ theme, firstLine }) => firstLine && `1px solid ${theme.new.colors.N500}`};
  flex-basis: ${({ size, totalDays }) => (size * 100) / totalDays}%;

  display: flex;
  flex-grow: 1;
`;

export const Grid = styled.div`
  border-right: 1px solid ${({ theme }) => theme.new.colors.N300};
  flex-basis: 1rem;
  flex-grow: 1;

  background-color: ${({ theme, isToday, isWeekend }) => {
    if (isToday) return theme.new.colors.FI100;
    if (isWeekend) return theme.new.colors.N100;
  }};

  &:last-child {
    border: none;
  }
`;
