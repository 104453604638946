import includes from 'lodash/includes';
import startCase from 'lodash/startCase';
import { shape, func, string, arrayOf, object, bool } from 'prop-types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import FilterDropdown from 'components/common/FilterDropdown';

const Filter = ({ column: { isSkill, labelId, filterValue, setFilter, preFilteredRows, id } }) => {
  const intl = useIntl();
  const options = useMemo(() => {
    const optionsSet = new Set();
    if (isSkill) {
      optionsSet.add(intl.formatMessage({ id: 'filter.highlightSkill' }));
    }
    preFilteredRows.forEach(({ values }) => {
      if (isSkill) {
        values[id].forEach(({ skill }) => {
          optionsSet.add(skill);
        });
      } else {
        values[id] && optionsSet.add(values[id]);
      }
    });
    return [...optionsSet.values()].map(value => ({
      label: startCase(value),
      key: value,
      value: includes(filterValue, value)
    }));
  }, [id, isSkill, preFilteredRows, filterValue]);

  const handleFilter = ({ options }) => {
    const selectedOptions = options.filter(({ value }) => value).map(({ key }) => key);
    setFilter(selectedOptions);
  };

  return (
    <FilterDropdown
      labelId={labelId}
      options={options}
      onFilter={handleFilter}
      withIntlOptions={false}
    />
  );
};

Filter.propTypes = {
  column: shape({
    isSkill: bool,
    filterValue: arrayOf(string).isRequired,
    setFilter: func.isRequired,
    preFilteredRows: arrayOf(object).isRequired,
    id: string.isRequired
  }).isRequired
};

export default Filter;
