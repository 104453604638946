import styled from 'styled-components';

import { ReactComponent as SearchSvg } from 'components/icons/search.svg';

import { StyledSelect, Label } from '../styles';

export const StyledLabel = styled(Label)`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  padding: 0 0.5rem;
  top: 0.7rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: #84858a;
  line-height: 2.4rem;
  ${({ isAbove }) =>
    isAbove &&
    `
    opacity: 0
  `}
`;

export const Select = styled(StyledSelect)`
  padding: 0;
  width: 19.3rem;

  .react-select__control {
    height: 1rem;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1.5px solid #b3b4ba;
  }

  .react-select__value-container {
    padding: 0 8px;
  }

  .react-select__option {
    font-size: 1.3rem;
    height: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const SearchIcon = styled(SearchSvg)`
  margin: 0 0.9rem 0 0.5rem;
  height: ${({ height }) => `${height}rem` || '1.5rem'};
  width: auto;
`;
