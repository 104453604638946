import styled from 'styled-components';

import Button from 'components/common/Button';
import DeleteButton from 'components/common/DeleteButton';

export const Form = styled.form`
  width: 100%;
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => theme.typography.regular};
  border: ${({ theme }) => `1px ${theme.colors.gray30} solid`};
  color: ${({ theme }) => theme.colors.gray80};
  font-size: 1.4rem;
  height: fit-content;
  margin-top: 0;
  padding: 1rem;
`;

export const ErrorSpan = styled.span`
  ${({ theme }) => theme.typography.italic}
  color: ${props => props.theme.colors.red};
  font-size: 1.4rem;
  line-height: 3rem;
`;

export const StyledDeleteButton = styled(DeleteButton)`
  display: block;
  padding: 0;
`;
