import styled from 'styled-components';

import Button from 'components/common/Button';
import SearchInput from 'components/common/SearchInput';

export const Table = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FiltersContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0 2rem 0;
`;

export const FiltersSection = styled.div`
  align-items: center;
  display: flex;
  flex: 1;

  > div button {
    padding: 1.15rem;
  }
`;

export const ActiveFiltersContainer = styled.div`
  display: flex;
  padding: 2rem 0;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  font-size: 1.3rem;
  height: 3.9rem;
  width: 8.3rem;
`;

export const Title = styled.h3`
  ${({ theme }) => theme.typography.extraBold};

  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-right: 4rem;
  font-size: 1.5rem;
`;

export const StyledSearch = styled(SearchInput)`
  height: 4.8rem;
`;
