import styled from 'styled-components';

import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Plus } from 'assets/icons/plusIcon.svg';
import Button from 'components/common/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  min-width: ${({ $width }) => $width || '26rem'};
  width: 100%;
  flex-basis: calc(50% - 4rem);
  flex-grow: 1;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.neutral300};
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  width: 100%;

  h3 {
    ${({ theme }) => theme.new.typography.h3};
    color: ${({ theme }) => theme.new.colors.neutral900};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  min-height: 2rem;
  row-gap: 1.2rem;
`;

export const StyledButton = styled(Button)`
  width: 13rem;
  height: 100%;
  max-height: 5.1rem;

  ${({ theme }) => theme.new.typography.p6Medium};
`;

export const IconsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  height: 2rem;
`;

export const CheckCircleIcon = styled(CheckCircle)`
  &:hover {
    cursor: pointer;
  }
`;

export const CloseIcon = styled(Close)`
  &:hover {
    cursor: pointer;
  }

  & path {
    stroke: ${({ theme }) => theme.new.colors.feedbackError};
  }
`;

export const EditIcon = styled(Edit)`
  width: 2rem;
`;

export const PlusIcon = styled(Plus)`
  & path {
    stroke: ${({ theme }) => theme.new.colors.white};
  }
`;
