import { createReducer } from '@reduxjs/toolkit';

import {
  acknowledgeNotification,
  deleteNotification,
  getNotificationHeader,
  updateNotificationMarkRead,
  updateNotificationMarkSeen
} from 'state/actions/notificationActions';

const initialState = {
  notificationsSeen: false,
  notifications: []
};

const notificationReducer = createReducer(initialState, builder =>
  builder
    .addCase(getNotificationHeader.fulfilled, (state, { payload }) => {
      state.notificationsSeen = payload.notificationsSeen;
      state.notifications = payload.notifications;
    })
    .addCase(updateNotificationMarkRead, (state, { payload: { notificationId } }) => {
      const index = state.notifications.findIndex(
        notification => notification.notificationId === notificationId
      );
      state.notifications[index].read = true;
    })
    .addCase(updateNotificationMarkSeen, state => {
      state.notificationsSeen = true;
    })
    .addCase(deleteNotification.fulfilled, (state, { payload }) => {
      const pos = state.notifications.findIndex(
        notification => notification.notificationId === payload
      );
      state.notifications.splice(pos, 1);
    })
    .addCase(acknowledgeNotification.fulfilled, (state, { payload }) => {
      const pos = state.notifications.findIndex(
        notification => notification.notificationId === payload
      );
      state.notifications[pos].acknowledged = !state.notifications[pos].acknowledged;
    })
);

export default notificationReducer;
