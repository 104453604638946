import { useIntl } from 'react-intl';

import usePeople from 'hooks/people/usePeople';
import useSkills from 'hooks/useSkills';

export default () => {
  const intl = useIntl();
  const { peopleOptions } = usePeople();
  const { skillOptions } = useSkills();

  return [
    { label: intl.formatMessage({ id: 'common.people' }), options: peopleOptions },
    { label: intl.formatMessage({ id: 'common.skills' }), options: skillOptions }
  ];
};
