import styled from 'styled-components';

export const TextAreaContainer = styled.div`
  font-size: 1.6rem;
  padding: 1.1rem 0 1.3rem;
`;

export const Label = styled.label`
  ${({ theme }) => theme.new.typography.p4}
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.new.colors.neutral800};
  margin-bottom: 0.6rem;
`;

export const StyledTextArea = styled.textarea`
  ${({ theme }) => theme.new.typography.p5};
  border: 1px solid ${({ theme }) => theme.new.colors.neutral400};
  border-color: ${({ errors, theme }) => errors && theme.new.colors.feedbackError};
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: ${({ theme }) => theme.new.colors.neutral900};
  padding: 1rem 1.4rem;
  resize: none;
  &::placeholder {
    color: ${({ theme }) => theme.new.colors.neutral600};
  }
`;

export const ErrorSpan = styled.span`
  ${({ theme }) => theme.new.typography.p6}
  color: ${({ theme }) => theme.new.colors.feedbackError};
`;
