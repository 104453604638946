import { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Input from 'components/common/Input';
import { RowInputWrapper, Row, CheckboxWrapper } from 'components/common/Layout/Row';
import Select from 'components/common/Select';
import { FULFILLED, PENDING, REJECTED } from 'constants/actionStatusConstants';
import { SENIORITY_OPTIONS, BUTTON_VARIANTS } from 'constants/constants';
import { defaultFormShape } from 'constants/shapes';
import { newResourceFormValidations } from 'constants/validators';
import {
  useForm,
  useSelectorProps,
  useTextInputProps,
  useCheckboxProps,
  useHeights,
  useLocations,
  useRoles,
  useValidation,
  useTeams
} from 'hooks';
import { composeResourceRequest, seniorityToOption } from 'utils/assignmentFormsUtilities';
import { parseInputErrors } from 'utils/helpers';

import SkillsRow from './skillsRow';
import { ErrorSpan, Form, StyledButton } from './styles';

const fields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  location: 'location',
  skills: 'skills',
  department: 'department',
  seniority: 'seniority',
  fullTime: 'fullTime',
  freelancer: 'freelancer',
  hourRate: 'hourRate',
  capacityInHours: 'capacityInHours',
  experiences: 'experiences',
  experiencesSelected: 'experiencesSelected',
  role: 'role'
};

const experiencesSelectedValidator = (value, options) =>
  value.some(item => !item?.skill || !item?.weeks) ? options?.message : null;

const NewResourceForm = ({
  initialValues,
  onSubmit,
  resetStatus,
  status,
  error,
  hide,
  edit = false
}) => {
  const intl = useIntl();

  const [skillsFields, setSkillsFields] = useState([]);
  const { inputHeight } = useHeights();
  const { locationsOptions } = useLocations();
  const { rolesOptions } = useRoles();
  const { formattedTeams } = useTeams(true);

  const onSubmitOverride = values =>
    onSubmit(composeResourceRequest({ values, experiencesSelected: skillsFields }));

  const validator = useValidation(newResourceFormValidations, undefined, {
    experiencesSelectedValidator
  });

  const { values, setValues, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      initialValues: {
        ...initialValues,
        firstName: initialValues?.firstName || '',
        lastName: initialValues?.lastName || '',
        email: initialValues?.email || '',
        location: initialValues?.location || '',
        department: initialValues?.department || '',
        skills: initialValues?.skills || '',
        seniority: initialValues?.seniority || '',
        fullTime: initialValues?.fullTime || true,
        freelancer: initialValues?.freelancer || true,
        hourRate: initialValues?.hourRate || '',
        capacityInHours: initialValues?.capacityInHours || '7.5',
        experiencesSelected: initialValues?.experiences || skillsFields,
        role: initialValues?.role || ''
      },
      onSubmit: onSubmitOverride,
      validator,
      validateOnBlur: true
    },
    [onSubmitOverride]
  );

  const handlerOnClickAddSkills = () => {
    const values = [...skillsFields];
    values.push({ skill: '', skillId: '', weeks: '' });
    setSkillsFields(values);
    handleValueChange(fields.experiencesSelected, values);
  };

  const handleRemoveSkill = index => {
    const values = [...skillsFields];
    values.splice(index, 1);
    setSkillsFields(values);
    handleValueChange(fields.experiencesSelected, values);
  };

  const handleSkillSelectChange = (index, event) => {
    const values = [...skillsFields];
    values[index].skill = event || '';
    values[index].skillId = event?.value?.id || '';
    setSkillsFields(values);
    handleValueChange(fields.experiencesSelected, values);
  };

  const handleSkillInputChange = (index, event) => {
    const values = [...skillsFields];
    values[index].weeks = event.target.value;
    setSkillsFields(values);
    handleValueChange(fields.experiencesSelected, values);
  };

  useEffect(() => {
    if (status === FULFILLED) hide();
  }, [status, hide]);

  useEffect(() => resetStatus, [resetStatus]);

  const inputProps = useTextInputProps({ handleValueChange, handleBlur, values, errors });
  const selectorProps = useSelectorProps({ inputProps, values, setValues, handleBlur, errors });
  const checkboxProps = useCheckboxProps({ inputProps, values, setValues });

  const seniorityOptions = useMemo(
    () => SENIORITY_OPTIONS.map(({ key }) => seniorityToOption(key, intl.formatMessage)),
    [intl]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <RowInputWrapper>
          <Input
            id="resourceFirstName"
            name="firstName"
            type="text"
            label={intl.formatMessage({ id: 'resource.firstName' })}
            fullWidth
            required
            {...inputProps(fields.firstName)}
          />
        </RowInputWrapper>
        <RowInputWrapper>
          <Input
            id="resourceLastName"
            name="lastName"
            type="text"
            label={intl.formatMessage({ id: 'resource.lastName' })}
            fullWidth
            required
            {...inputProps(fields.lastName)}
          />
        </RowInputWrapper>
      </Row>
      <RowInputWrapper fullWidth>
        <Input
          id="resourceEmail"
          name="email"
          type="email"
          label={intl.formatMessage({ id: 'common.email' })}
          fullWidth
          required
          {...inputProps(fields.email)}
        />
      </RowInputWrapper>
      <RowInputWrapper fullWidth>
        <Select
          id="resourceLocation"
          name="location"
          label={intl.formatMessage({ id: 'resource.location' })}
          options={locationsOptions}
          isSearchable
          height={inputHeight}
          {...selectorProps(fields.location)}
          isClearable
          withoutHighlight
        />
      </RowInputWrapper>
      <RowInputWrapper fullWidth>
        <Select
          id="resourceDepartment"
          name="department"
          label={intl.formatMessage({ id: 'common.department' })}
          options={formattedTeams}
          isSearchable
          height={inputHeight}
          {...selectorProps(fields.department)}
          isClearable
          withoutHighlight
        />
      </RowInputWrapper>
      <Row>
        <RowInputWrapper>
          <Select
            id="resourceRole"
            name="roleId"
            label={intl.formatMessage({ id: 'common.role' })}
            options={rolesOptions}
            isSearchable
            height={inputHeight}
            {...selectorProps(fields.role)}
            isClearable
            withoutHighlight
          />
        </RowInputWrapper>
        <RowInputWrapper>
          <Select
            id="resourceSeniority"
            name="seniority"
            label={intl.formatMessage({ id: 'common.seniority' })}
            options={seniorityOptions}
            isSearchable
            height={inputHeight}
            {...selectorProps(fields.seniority)}
            isClearable
            withoutHighlight
          />
        </RowInputWrapper>
      </Row>
      <Row>
        <CheckboxWrapper>
          <Checkbox
            id="resourceFullTime"
            name="fields.fullTime"
            label={intl.formatMessage({ id: 'resource.fullTime' })}
            {...checkboxProps(fields.fullTime)}
          />
        </CheckboxWrapper>
        <RowInputWrapper>
          <Input
            id="resourceCapacity"
            name="capacityInHours"
            type="number"
            min="0"
            step="any"
            label={intl.formatMessage({ id: 'resource.capacityInHours' })}
            fullWidth
            required
            {...inputProps(fields.capacityInHours)}
          />
        </RowInputWrapper>
      </Row>
      <Row>
        <CheckboxWrapper>
          <Checkbox
            id="resourceFreelancer"
            name="fields.freelancer"
            label={intl.formatMessage({ id: 'resource.freelancer' })}
            {...checkboxProps(fields.freelancer)}
          />
        </CheckboxWrapper>
        {values[fields.freelancer] && (
          <RowInputWrapper>
            <Input
              id="resourceRate"
              name="hourRate"
              type="number"
              min="0"
              step="any"
              label={intl.formatMessage({ id: 'resource.hoursRate' })}
              fullWidth
              required
              {...inputProps(fields.hourRate)}
            />
          </RowInputWrapper>
        )}
      </Row>
      <SkillsRow
        skillsFields={skillsFields}
        handleSkillSelectChange={handleSkillSelectChange}
        handleSkillInputChange={handleSkillInputChange}
        handleRemoveSkill={handleRemoveSkill}
      />
      <Row>
        <StyledButton
          id="resourceAddSkill"
          variant={BUTTON_VARIANTS.SUBMIT}
          onClick={handlerOnClickAddSkills}
          textIntlId="resource.addSkills"
          outlined
        />
      </Row>
      {errors?.experiencesSelected && (
        <Row>
          <ErrorSpan>
            <FormattedMessage
              id={parseInputErrors(errors.experiencesSelected)}
              defaultMessage={parseInputErrors(errors.experiencesSelected)}
            />
          </ErrorSpan>
        </Row>
      )}

      {status === REJECTED && <AlertMessage>{error}</AlertMessage>}
      <Button
        id={edit ? 'resourceSaveEdit' : 'resourceSaveNew'}
        type="submit"
        variant={BUTTON_VARIANTS.SUBMIT}
        isLoading={status === PENDING}
        textIntlId={edit ? 'resources.editResource' : 'resources.newResource'}
        fullWidth
      />
    </Form>
  );
};

NewResourceForm.propTypes = defaultFormShape;

export default NewResourceForm;
