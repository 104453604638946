import styled from 'styled-components';

import NewModal from 'components/common/NewModal';
import { ContentWrapper } from 'components/common/NewModal/styles';

export const Horizontal = styled.hr`
  border-width: 0;
  background-color: ${({ theme, color }) => color || theme.new.colors.neutral300};
  height: ${({ height }) => height || '1px'};
  margin: ${({ margin }) => margin};
`;

export const DetailsContent = styled.div`
  display: flex;
  gap: 6rem;
`;

export const BoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  min-height: 2rem;
  row-gap: 1.2rem;
`;

export const SkillsContainer = styled.div`
  padding: 1.6rem 1.2rem;
  background-color: ${({ theme }) => theme.new.colors.neutral200};
  border-radius: 0.6rem;
`;

export const Title = styled.div`
  ${({ theme }) => theme.new.typography.p4SemiBold};
  color: ${({ theme }) => theme.new.colors.neutral900};
  margin-bottom: 1.2rem;
`;

export const StyledNewModal = styled(NewModal)`
  ${ContentWrapper} {
    display: flex;
    flex-direction: column;
    max-width: 88rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'space-between')};
  min-height: 23.7rem;
  padding: ${({ isResourceEqual }) => isResourceEqual && '3rem 0 0.7rem 0'};
  gap: 2rem;
  padding-top: 1rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
`;
