import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ActiveFilters from 'components/common/ActiveFilters';
import Filters from 'components/common/Filters';
import ProjectHeader from 'components/ProjectHeader';
import NewTimelineGrid from 'components/timeline/NewTimelineGrid';
import NewTimelineHeader from 'components/timeline/NewTimelineHeader';
import ProjectsTable from 'components/timeline/ProjectsTable';
import EditProjectStatusModal from 'components/timeline/ProjectStatus/EditProjectStatusModal';
import NewProjectStatusModal from 'components/timeline/ProjectStatus/NewProjectStatusModal';
import { useDispatch, useRequest } from 'hooks';
import { resetResources } from 'state/actions/assignmentActions';
import { getProjectsOverview, getProjectAssignments } from 'state/actions/projectActions';
import { addDays, daysCalendar, getRequestFormatDate } from 'utils/date';
import { filterItemsByProperties } from 'utils/filtersUtilities';
import { initializeOptionsWithFieldName } from 'utils/helpers';
import { convertProjectsDataToTableFormat } from 'utils/projectsOverviewUtilities';
import { filterList } from 'utils/reducersUtilities';

import { TimelineContainer, Container, Content, Horizontal, StyledTimeNavigation } from './styles';

const DAYS_AFTER_TODAY = 47;

const ProjectsTimeline = () => {
  const [projects, setProjects] = useState([]);
  const [addAssignmentTo, setAddAssignmentTo] = useState();
  const [editProjectStatus, setEditProjectStatus] = useState();
  const [newProjectStatus, setNewProjectStatus] = useState();
  const [expandedProjects, setExpandedProjects] = useState([]);
  const [date, setDate] = useState(new Date());
  const { projectStatus, assignmentStatus, serviceType } = useSelector(state => state.ui.options);
  const resetAssignmentResources = useDispatch(resetResources);
  const getAssignmentsRequest = useDispatch(getProjectAssignments);

  const initialValuesDropdowns = {
    projectStatus: initializeOptionsWithFieldName(projectStatus, 'projectStatus'),
    assignmentStatus: initializeOptionsWithFieldName(assignmentStatus, 'assignmentStatus'),
    serviceType: initializeOptionsWithFieldName(serviceType, 'serviceType')
  };

  const [filters, setFilters] = useState({
    ...initialValuesDropdowns,
    search: '',
    hideFinished: true
  });

  const startDate = getRequestFormatDate(date);
  const endDate = addDays(startDate, DAYS_AFTER_TODAY);

  const [calendar, totalDays] = daysCalendar(startDate, endDate, startDate);

  const [{ isPending: isLoading }, getProjectsOverviewRequest] = useRequest(getProjectsOverview, {
    fulfilledCallback: payload => setProjects(convertProjectsDataToTableFormat(payload?.projects)),
    params: { startDate: getRequestFormatDate(startDate), endDate: getRequestFormatDate(endDate) }
  });

  const filteredProjects = () => {
    const newProjects = filterItemsByProperties(projects, filters);
    return filterList(newProjects, filters.search, ({ name }) => name);
  };

  const handleExpandedProjects = (projectId, expanded) => {
    if (expanded) {
      const newExpandedProjects = expandedProjects.filter(project => project !== projectId);
      setExpandedProjects(newExpandedProjects);
    } else {
      setExpandedProjects([...expandedProjects, projectId]);
    }
  };

  const handleClear = () => {
    setFilters(prevState => ({ ...prevState, ...initialValuesDropdowns }));
  };

  const handleDateChange = newDate => {
    const newStartDate = getRequestFormatDate(newDate);
    const newEndDate = getRequestFormatDate(addDays(newStartDate, DAYS_AFTER_TODAY));
    getProjectsOverviewRequest({ startDate: newStartDate, endDate: newEndDate });
    expandedProjects &&
      expandedProjects.map(projectId =>
        getAssignmentsRequest({ projectId, startDate: newStartDate, endDate: newEndDate })
      );
  };

  const activeFilters = [filters?.projectStatus, filters?.assignmentStatus, filters?.serviceType]
    ?.map(filterItem => filterItem?.filter(option => option.value))
    .flat();

  useEffect(() => () => resetAssignmentResources(), [resetAssignmentResources]);

  return (
    <Container>
      <ProjectHeader
        titleId="common.timeline"
        setAddAssignmentTo={setAddAssignmentTo}
        addAssignmentTo={addAssignmentTo}
        handleRefresh={getProjectsOverviewRequest}
      />
      <Content>
        <div>
          <Filters filters={filters} setFilters={setFilters} />
          <Horizontal margin="1.6rem 0" />
          <ActiveFilters
            filters={filters}
            setFilters={setFilters}
            handleClear={handleClear}
            selectedOptions={activeFilters}
          />
        </div>
        <StyledTimeNavigation date={date} setDate={setDate} onDateChange={handleDateChange} />
        <TimelineContainer>
          <NewTimelineHeader calendar={calendar} totalDays={totalDays} />
          <NewTimelineGrid calendar={calendar} totalDays={totalDays} />
          <ProjectsTable
            filteredProjects={filteredProjects()}
            isProjectsPending={isLoading}
            setEditProjectStatus={setEditProjectStatus}
            setNewProjectStatus={setNewProjectStatus}
            startDateTimeline={startDate}
            endDateTimeline={endDate}
            getProjectsRequest={getProjectsOverviewRequest}
            handleExpandedProjects={handleExpandedProjects}
          />
        </TimelineContainer>
      </Content>
      {newProjectStatus && (
        <NewProjectStatusModal
          isShowing={!!newProjectStatus}
          hide={() => setNewProjectStatus(null)}
          handleRefreshData={getProjectsOverviewRequest}
          {...newProjectStatus}
        />
      )}
      {editProjectStatus && (
        <EditProjectStatusModal
          isShowing={!!editProjectStatus}
          hide={() => setEditProjectStatus(null)}
          handleRefreshData={getProjectsOverviewRequest}
          {...editProjectStatus}
        />
      )}
    </Container>
  );
};

export default ProjectsTimeline;
