import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 0.8rem;
`;

export const Content = styled.div`
  & > p {
    margin-bottom: 0.2rem;

    color: ${({ theme }) => theme.new.colors.neutral800};
    ${({ theme }) => theme.new.typography.p5SemiBold};
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;

    color: ${({ theme }) => theme.new.colors.neutral900};
    ${({ theme }) => theme.new.typography.p4};

    & span {
      color: ${({ theme }) => theme.new.colors.neutral600};
    }
  }
`;
