import styled from 'styled-components';

import { ReactComponent as RedirectLink } from 'assets/icons/redirectLink.svg';
import Button from 'components/common/Button';

export const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.new.colors.white};
  border-radius: 0.8rem;
  box-shadow: 0 0 2px 0 rgba(79, 94, 113, 0.12), 0 2px 4px 0 rgba(79, 94, 113, 0.11),
    0 4px 8px 0 rgba(79, 94, 113, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;
  padding: 2rem 2.8rem;
  width: 100%;

  table {
    border-radius: 8px;
    border-spacing: 0;
    border-collapse: separate;
    height: 100%;
  }
  td,
  th {
    width: 33%;
    height: 25%;
  }
`;

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding-bottom: 2rem;

  & h3 {
    ${({ theme }) => theme.new.typography.p3SemiBold};
    color: ${({ theme }) => theme.new.colors.N900};
    line-height: 2.4rem;
  }

  & h4 {
    ${({ theme }) => theme.new.typography.h4};
    color: ${({ theme }) => theme.new.colors.P800};
  }
`;

export const Column = styled.td`
  height: 100%;
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  font-size: 2rem;
  border-left: 1px solid ${({ theme }) => theme.new.colors.N300};
  border-right: ${({ theme, isAddBorderRight }) =>
    isAddBorderRight && `1px solid ${theme.new.colors.N300}`};
  padding: 2.2rem 1.2rem;
  ${({ theme }) => theme.new.typography.p6Medium};
  font-size: 2rem;
  color: ${({ theme }) => theme.new.colors.N800};
`;

export const QuarterInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.new.typography.p6};
  color: ${({ theme }) => theme.new.colors.N800};

  span:first-child {
    ${({ theme }) => theme.new.typography.h3};
    color: ${({ theme }) => theme.new.colors.P800};
  }
`;

export const HeaderColumn = styled.th`
  border-bottom: 2.6px solid ${({ theme }) => theme.new.colors.N300};
  padding: 1.6rem 0.8rem;
  ${({ theme }) => theme.new.typography.p6Medium};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.new.colors.N900};
`;

export const Row = styled.tr`
  background: ${({ theme, isGray }) => theme.new.colors[isGray ? 'N100_05' : 'white']};
`;

export const TableBody = styled.tbody`
  tr:last-child {
    td {
      border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
    }
    td:first-child {
      border-radius: 0 0 0 8px;
    }
    td:last-child {
      border-radius: 0 0 8px 0;
    }
  }
`;
export const RedirectLinkIcon = styled(RedirectLink)`
  fill: ${({ theme }) => theme.new.colors.brand};
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => theme.new.typography.p4SemiBold};
  line-height: 1.6rem;
`;
