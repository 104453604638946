import { createReducer } from '@reduxjs/toolkit';

import { showPastDates, showFutureDates, resetTimelineDates } from 'state/actions/timelineActions';
import { addWeeks, getRequestFormatDate, startOfWorkingWeek, endOfWorkingWeek } from 'utils/date';

const initialState = {
  startDate: getRequestFormatDate(startOfWorkingWeek()),
  endDate: getRequestFormatDate(endOfWorkingWeek(addWeeks(9)))
};

export default createReducer(initialState, builder =>
  builder
    .addCase(resetTimelineDates, state => {
      state.startDate = initialState.startDate;
      state.endDate = initialState.endDate;
    })
    .addCase(showPastDates, state => {
      state.startDate = addWeeks(-5, state.startDate);
      state.endDate = addWeeks(-5, state.endDate);
    })
    .addCase(showFutureDates, state => {
      state.startDate = addWeeks(5, state.startDate);
      state.endDate = addWeeks(5, state.endDate);
    })
);
