import styled from 'styled-components';

import Checkbox from 'components/common/Checkbox';

export const InputWrapper = styled.div`
  margin: 3.2rem 0 1.6rem 0;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 3.2rem;
`;

export const StyledCheckbox = styled(Checkbox)`
  & > div:nth-child(2) {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme }) => theme.new.colors.neutral900};
  }
`;
