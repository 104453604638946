import styled from 'styled-components';

export const ProfileInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  h2 {
    ${({ theme }) => theme.new.typography.p4SemiBold};
  }

  h4 {
    ${({ theme }) => theme.new.typography.p5};
  }

  p {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme }) => theme.new.colors.N600};
  }
`;
