import dayjs from 'dayjs';
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { CAPACITY_UTILIZATION } from 'constants/dashboardConstants';
import { utilizationGraphShape } from 'constants/shapes';

import {
  ContainerGraphTooltip,
  DataContainer,
  Header,
  ItemKey,
  ItemValue,
  SubHeader
} from './styles';

function GraphTooltip({ payload, label, isQuarter }) {
  const intl = useIntl();

  if (payload?.length) {
    const { demand, scheduled, billingCapacity, year, quarter } = payload[0].payload;

    return (
      <ContainerGraphTooltip>
        <DataContainer>
          <Header>
            {isQuarter
              ? `Q${quarter}`
              : `${dayjs()
                  .month(label - 1)
                  .format('MMM')} ${year}`}
          </Header>
          <SubHeader>{intl.formatMessage({ id: 'common.FTE' })}</SubHeader>
          <ItemKey color={CAPACITY_UTILIZATION.demand?.stroke}>
            {intl.formatMessage({ id: CAPACITY_UTILIZATION.demand?.intl })}:
          </ItemKey>
          <ItemValue>{Math.round(demand) || 0}</ItemValue>
          <ItemKey color={CAPACITY_UTILIZATION.scheduled?.stroke}>
            {intl.formatMessage({ id: CAPACITY_UTILIZATION.scheduled?.intl })}:
          </ItemKey>
          <ItemValue>{Math.round(scheduled) || 0}</ItemValue>
          <ItemKey color={CAPACITY_UTILIZATION.billingCapacity?.stroke}>
            {intl.formatMessage({ id: CAPACITY_UTILIZATION.billingCapacity?.intl })}:
          </ItemKey>
          <ItemValue>{Math.round(billingCapacity) || 0}</ItemValue>
          <ItemKey>{intl.formatMessage({ id: 'common.cap.utilization' })}:</ItemKey>
          <ItemValue>
            {scheduled && billingCapacity ? Math.round((scheduled * 100) / billingCapacity) : '--'}%
          </ItemValue>
        </DataContainer>
      </ContainerGraphTooltip>
    );
  }

  return null;
}

GraphTooltip.propTypes = {
  label: oneOfType([string, number]),
  payload: arrayOf(shape({ payload: utilizationGraphShape })),
  isQuarter: bool
};

export default GraphTooltip;
