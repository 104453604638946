import { string } from 'prop-types';
import { useIntl } from 'react-intl';

import { Container, Title } from './styles';

const Header = ({ headerString, headerId }) => {
  const intl = useIntl();

  return (
    <Container>
      <Title>{headerString || intl.formatMessage({ id: headerId })}</Title>
    </Container>
  );
};

Header.propTypes = {
  headerString: string,
  headerId: string
};

export default Header;
