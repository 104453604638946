import dayjs from 'dayjs';
import { arrayOf } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useTheme } from 'styled-components';

import { CAPACITY_UTILIZATION } from 'constants/dashboardConstants';
import { utilizationGraphShape } from 'constants/shapes';

import CustomizedLabelY from './CustomizedLabelY';
import GraphTooltip from './GraphTooltip';
import LegendItem from './LegendItem';
import YAxisTick from './YAxisTick';

const UtilizationChart = ({ data }) => {
  const intl = useIntl();
  const theme = useTheme();

  const graphData = [
    {
      label: intl.formatMessage({ id: CAPACITY_UTILIZATION.demand.intl }),
      dataKey: CAPACITY_UTILIZATION.demand.key,
      color: CAPACITY_UTILIZATION.demand.color,
      stroke: CAPACITY_UTILIZATION.demand.stroke
    },
    {
      label: intl.formatMessage({ id: CAPACITY_UTILIZATION.scheduled.intl }),
      dataKey: CAPACITY_UTILIZATION.scheduled.key,
      color: CAPACITY_UTILIZATION.scheduled.color,
      stroke: CAPACITY_UTILIZATION.scheduled.stroke
    },
    {
      label: intl.formatMessage({ id: CAPACITY_UTILIZATION.billingCapacity.intl }),
      dataKey: CAPACITY_UTILIZATION.billingCapacity.key,
      color: CAPACITY_UTILIZATION.billingCapacity.color,
      stroke: CAPACITY_UTILIZATION.billingCapacity.stroke
    }
  ];

  return (
    <ResponsiveContainer minHeight={400} minWidth={500}>
      <AreaChart data={data} margin={{ top: 40, bottom: 40 }}>
        <CartesianGrid vertical={false} stroke={theme.new.colors.N300} />
        <XAxis
          dataKey={data?.length > 5 ? 'month' : 'quarter'}
          tickFormatter={tickItem =>
            data?.length > 5
              ? dayjs()
                  .month(tickItem - 1)
                  .format('MMM')
                  .toUpperCase()
              : `Q${tickItem}`
          }
          tickLine={false}
          axisLine={false}
        />
        <YAxis label={CustomizedLabelY} tickLine={false} axisLine={false} tick={<YAxisTick />} />
        <defs>
          <linearGradient id="scheduledColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#6C3DF7" stopOpacity={1} />
            <stop offset="100%" stopColor="#A284F9" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="billingCapacityColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0090D7" stopOpacity={1} />
            <stop offset="100%" stopColor="#58C0F3" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Legend
          wrapperStyle={{ bottom: 16 }}
          iconType="circle"
          iconSize={8}
          chartHeight={400}
          formatter={name => (
            <LegendItem name={intl.formatMessage({ id: CAPACITY_UTILIZATION[name]?.intl })} />
          )}
        />
        <Tooltip content={content => <GraphTooltip isQuarter={data?.length < 5} {...content} />} />
        {graphData.map(({ dataKey, color, stroke, ...props }) => (
          <Area
            type="monotone"
            strokeWidth={3}
            stroke={stroke}
            key={dataKey}
            dataKey={dataKey}
            fill={color}
            {...props}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

UtilizationChart.propTypes = {
  data: arrayOf(utilizationGraphShape)
};

export default UtilizationChart;
