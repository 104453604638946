import { useCallback, useRef, useState } from 'react';

import FormAccordion from 'components/common/FormAccordion';
import { getErrorSection } from 'utils/errors';

const useFormAccordion = (formSections, errors) => {
  const [expandedSection, setExpandedSection] = useState(1);
  const accordionWrapperRef = useRef(null);

  const openErrorSection = () => {
    const errorSection = getErrorSection(formSections, errors);

    if (errorSection && accordionWrapperRef?.current) {
      setExpandedSection(errorSection);
      accordionWrapperRef.current.scrollTop = 0;
    }
  };

  const renderFormAccordion = useCallback(
    () =>
      formSections.map(section => (
        <FormAccordion
          data={section}
          expanded={expandedSection}
          setExpanded={setExpandedSection}
          key={section.section}
        >
          {section.component({ expandedSection })}
        </FormAccordion>
      )),
    [formSections, expandedSection]
  );

  return { accordionWrapperRef, openErrorSection, renderFormAccordion };
};

export default useFormAccordion;
