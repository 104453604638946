import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Text = styled.p`
  ${({ theme }) => theme.new.typography.p3SemiBold};

  color: ${({ theme }) => theme.new.colors.P800};
  left: 50%;
  position: absolute;
  text-align: center;
  top: 120px;
  transform: translate(-50%, -50%);
  white-space: pre-line;
`;

export const Total = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.new.colors.P100};
  border-radius: 4px;
  top: 240px;
  display: flex;
  left: 50%;
  padding: 4px 16px;
  position: absolute;
  transform: translateX(-50%);
  gap: 0.5rem;

  & p {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme }) => theme.new.colors.P800};
    white-space: nowrap;
  }

  & span {
    ${({ theme }) => theme.new.typography.p2SemiBold};
    color: ${({ theme }) => theme.new.colors.P800};
  }
`;
