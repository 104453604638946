import httpClient from 'httpClient';
import { ENDPOINTS } from 'httpClient/constants';

class ForecastingServices {
  static async getProjectsTotals() {
    return httpClient.get(ENDPOINTS.getProjectsTotals);
  }

  static async getProjects(forecastedMonthsAhead) {
    return httpClient.get(ENDPOINTS.getProjectsForecasting, {
      params: {
        forecastedMonthsAhead
      }
    });
  }

  static async getGraph() {
    return httpClient.get(ENDPOINTS.getForecastingGraph);
  }
}

export default ForecastingServices;
