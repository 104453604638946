import styled from 'styled-components';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { SORT_DIR } from 'constants/constants';

export const TableWrapper = styled.div`
  display: grid;
  gap: 2.8rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  min-height: 38rem;
  overflow-x: auto;
  transition: all 150ms ease-in-out;
  table {
    width: 100%;
  }
  th,
  td {
    padding: 0 1rem;
    width: fit-content;
  }
`;

export const ArrowIcon = styled(ArrowUp)`
  display: block;
  height: 1rem;
  opacity: ${({ $isSorting }) => ($isSorting ? '1' : '0.3')};
  path {
    stroke: ${({ theme, $isSorting }) =>
      $isSorting ? theme.new.colors.P500 : theme.new.colors.N900};
    stroke-width: 1.8px;
  }
  transition: all 150ms ease-in-out;
  transform: ${({ direction }) =>
    direction === SORT_DIR.desc ? 'rotate(-180deg) translate(0, 10%)' : 'none'};
  width: 1.2rem;
`;

export const Table = styled.table`
  border-collapse: collapse;
  height: fit-content;
`;

export const Column = styled.td`
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding && `${padding} !important`};
  width: ${({ width }) => width};
`;

export const HeaderColumnContainer = styled.button`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.N900};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 1.4rem;
  justify-content: start;
  width: fit-content;
`;

export const Row = styled.tr`
  border-bottom: 0px;
  ${({ theme, isHeader }) => (isHeader ? theme.new.colors.N500 : theme.new.colors.N400)};
  height: ${({ height }) => height || '7.2rem'};
  text-align: left;
  width: 100%;

  td:nth-child(1) {
    max-width: 19.6rem;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 1.2rem;
  min-width: 20rem;

  & > button {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    height: 2.9rem;
    min-width: 8.3rem;
    padding: 0.8rem 1.2rem;
  }
`;

export const BasicInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & h3 {
    ${({ theme }) => theme.new.typography.p5SemiBold};
    color: ${({ theme }) => theme.new.colors.N800};
  }

  & p {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme }) => theme.new.colors.N600};
  }
`;

export const BasicInfo = styled.section`
  align-items: center;
  display: flex;
  gap: 2.735rem;

  & img {
    margin: 0;
  }
`;

export const RolePill = styled.div`
  ${({ theme }) => theme.new.typography.p5};
  align-items: center;
  background: ${({ theme }) => theme.new.colors.N300};
  border-radius: 4px;
  color: ${({ theme }) => theme.new.colors.N800};
  display: flex;
  gap: 0.7rem;
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  width: fit-content;
  & > svg {
    stroke-width: 2px;
  }
`;

export const StyledCloseIcon = styled.img`
  cursor: pointer;
  height: 1.8rem;
  width: 1.8rem;
`;

export const TemporalRoleWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  min-width: 25rem;
`;
