import styled from 'styled-components';

export const GraphTitle = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.9rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const MoreInfo = styled.div`
  position: absolute;
  right: 3rem;
  top: 2rem;
  cursor: pointer;
`;
