import styled from 'styled-components';

import { ReactComponent as RedirectLink } from 'assets/icons/redirectLink.svg';

export const RedirectLinkIcon = styled(RedirectLink)`
  path {
    stroke: ${({ theme, disabled }) =>
      disabled ? theme.new.colors.neutral300 : theme.new.colors.brand};
  }
`;

export const Container = styled.div`
  padding: 2rem 5rem 4rem 5rem;
`;

export const Header = styled.header`
  padding: 3rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & a,
  a:link,
  a:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.new.colors.neutral800};
  }
`;

export const Title = styled.h2`
  ${({ theme }) => theme.new.typography.h2};
  color: ${({ theme }) => theme.new.colors.black};
  max-width: 25.1rem;
  width: 100%;
`;

export const BioWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  & > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    &:nth-child(1) {
      & > span {
        color: ${({ theme }) => theme.new.colors.feedbackError};
      }
    }
    & > span {
      ${({ theme }) => theme.new.typography.p6};
      color: ${({ theme }) => theme.new.colors.N500};
    }
  }
`;
