import { arrayOf, bool, func, number, string } from 'prop-types';
import { useState } from 'react';

import CenteredLoading from 'components/common/Loading/CenteredLoading';
import LoadingWrapper from 'components/common/LoadingWrapper';
import ResourceProfileModal from 'components/Reports/ResourceProfileModal';
import AssignToProjectDropdown from 'components/timeline/AssignToProjectDropdown';
import { BUTTON_VARIANTS } from 'constants/constants';
import { peopleShape } from 'constants/shapes';
import { useRequest, useTimelineDates } from 'hooks';
import { getMinimalProjects } from 'state/actions/projectActions';
import { projectToAssignmentOption } from 'utils/assignmentFormsUtilities';

import AddAssignmentModal from '../AddAssignmentModal';
import EditAssignmentModal from '../EditAssignmentModal';
import EditQuickAssignmentModal from '../EditQuickAssignmentModal';
import PeopleRow from './PeopleRow';
import { StyledButton } from './styles';

const PeopleTable = ({
  people,
  expandedPeople,
  isLoading,
  toggleExpandedRequest,
  isReadOnly = false,
  assignmentId,
  getPeopleAvailabilityRequest
}) => {
  const [addAssignment, setAddAssignment] = useState();
  const [editAssignment, setEditAssignment] = useState();
  const [editQuickAssignment, setEditQuickAssignment] = useState();
  const [isShowResourceProfileModal, setIsShowResourceProfileModal] = useState(false);
  const [personId, setPersonId] = useState(0);
  const [projectOptions, setProjectsOptions] = useState([]);

  const { startDate, endDate } = useTimelineDates();

  useRequest(getMinimalProjects, {
    fulfilledCallback: ({ projects }) => {
      setProjectsOptions(projects?.map(projectToAssignmentOption));
    },
    params: { startDate, endDate }
  });

  const showResourceDetail = personId => {
    setPersonId(personId);
    setIsShowResourceProfileModal(true);
  };

  if (isLoading && !people.length) {
    return <CenteredLoading />;
  }

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div>
        {people.map((person, index) => (
          <PeopleRow
            index={index}
            key={person.id}
            person={person}
            isExpanded={expandedPeople.includes(person.id)}
            setEditAssignment={setEditAssignment}
            setAddAssignment={setAddAssignment}
            isReadOnly={isReadOnly}
            showResourceDetail={showResourceDetail}
            quickActionComponent={
              !assignmentId ? (
                <AssignToProjectDropdown
                  id={`assignToProjectDrp-${index}`}
                  person={person}
                  setAddAssignment={setAddAssignment}
                  options={projectOptions}
                />
              ) : (
                <StyledButton
                  id={`assignResource-${index}`}
                  variant={BUTTON_VARIANTS.SECONDARY}
                  textIntlId="common.assignResource"
                  onClick={() =>
                    setEditQuickAssignment({
                      personId: person.id,
                      name: person.fullName,
                      busyPercentage: person.availability[0]?.value.busyPercentage
                    })
                  }
                  fullWidth
                />
              )
            }
            toggleExpandedRequest={toggleExpandedRequest}
          />
        ))}
        {addAssignment && (
          <AddAssignmentModal
            isShowing={!!addAssignment}
            hide={() => setAddAssignment(null)}
            projectId={addAssignment.project?.id}
            initialValues={addAssignment.initialValues}
            handleRefresh={() => {
              getPeopleAvailabilityRequest({ startDate, endDate });
            }}
          />
        )}
        {editAssignment && (
          <EditAssignmentModal
            isShowing={!!editAssignment}
            hide={() => setEditAssignment()}
            projectId={editAssignment?.projectId}
            assignmentId={editAssignment?.assignmentId}
          />
        )}
        {editQuickAssignment && (
          <EditQuickAssignmentModal
            isShowing={!!editQuickAssignment}
            hide={() => setEditQuickAssignment(null)}
            personId={editQuickAssignment?.personId}
            name={editQuickAssignment?.name}
            busyPercentage={editQuickAssignment?.busyPercentage}
            assignmentId={assignmentId}
          />
        )}
        {isShowResourceProfileModal && (
          <ResourceProfileModal
            open={isShowResourceProfileModal}
            personId={personId}
            hide={() => setIsShowResourceProfileModal(false)}
          />
        )}
      </div>
    </LoadingWrapper>
  );
};

PeopleTable.propTypes = {
  isLoading: bool.isRequired,
  people: arrayOf(peopleShape).isRequired,
  expandedPeople: arrayOf(number).isRequired,
  isReadOnly: bool,
  toggleExpandedRequest: func.isRequired,
  assignmentId: string,
  getPeopleAvailabilityRequest: func
};

export default PeopleTable;
