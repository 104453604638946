import styled from 'styled-components';

import { ReactComponent as RedirectLink } from 'assets/icons/redirectLink.svg';
import Button from 'components/common/Button';
import TabButtons from 'components/common/TabButtons';
import { TabButton } from 'components/common/TabButtons/styles';

export const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.new.colors.white};
  border-radius: 0.8rem;
  box-shadow: 0 0 2px 0 rgba(79, 94, 113, 0.12), 0 2px 4px 0 rgba(79, 94, 113, 0.11),
    0 4px 8px 0 rgba(79, 94, 113, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem;
  width: 100%;
`;

export const StyledTabButtons = styled(TabButtons)`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  width: 100%;

  ${TabButton} {
    padding: ${({ active }) => `0 0.8rem ${active ? '1.8rem' : '1.6rem'} 0.8rem`};
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  & h3 {
    ${({ theme }) => theme.new.typography.p3SemiBold};
    color: ${({ theme }) => theme.new.colors.N900};
  }

  & h4 {
    ${({ theme }) => theme.new.typography.h4};
    color: ${({ theme }) => theme.new.colors.P800};
  }

  & p {
    ${({ theme }) => theme.new.typography.p4SemiBold};
    color: ${({ theme }) => theme.new.colors.N800};
  }
`;

export const TopContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2rem;
`;

export const RedirectLinkIcon = styled(RedirectLink)`
  fill: ${({ theme }) => theme.new.colors.brand};
`;

export const ModalTitle = styled.div`
  ${({ theme }) => theme.new.typography.p2SemiBold};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.N900};
  display: flex;
  gap: 8px;

  & span {
    color: ${({ theme }) => theme.new.colors.P800};
  }
`;

export const VerticalLine = styled.div`
  background-color: ${({ theme }) => theme.new.colors.P300};
  height: ${({ height }) => height || '1.6rem'};
  width: 1px;
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => theme.new.typography.p4SemiBold};
  line-height: 1.6rem;
`;

export const SubTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;

  & > h5 {
    ${({ theme }) => theme.new.typography.p6};
  }
`;
