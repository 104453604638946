import styled from 'styled-components';

import DatePicker from 'components/common/DatePicker';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  svg path {
    stroke: ${({ theme }) => theme.new.colors.N500};
  }
  input {
    height: 4.8rem;
    border-color: ${({ theme }) => theme.new.colors.N400};
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  .react-datepicker {
    left: -14.4rem;
  }
`;
