import { GLOSSARY } from 'constants/constants';
import { glossaryShape } from 'constants/shapes';

import { Container, Dot } from './styles';

const Dots = ({ glossary }) => (
  <Container>
    {Object.keys(glossary).map(
      item => glossary[item] && <Dot key={item} color={GLOSSARY[item].color} />
    )}
  </Container>
);

Dots.propTypes = {
  glossary: glossaryShape
};

export default Dots;
