import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  span {
    ${({ theme }) => theme.new.typography.p4}
    color: ${({ theme }) => theme.new.colors.N600};
    line-height: 1.6rem;
  }

  ${({ isFuture }) =>
    !isFuture &&
    css`
      & span:first-of-type {
        line-height: 2.4rem;
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        color: ${({ theme, growthSign }) => growthSign === -1 && theme.new.colors.FE500};
        color: ${({ theme, growthSign }) => growthSign === 1 && theme.new.colors.FS500};
      }
    `}
`;
