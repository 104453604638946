import styled from 'styled-components';

import OptionsButton from 'components/common/OptionsButton';
import { Button, OptionsContainer } from 'components/common/OptionsButton/styles';

export const OptionsButtons = styled(OptionsButton)`
  ${OptionsContainer} {
    ${({ theme }) => theme.new.shadows.small}
    border-radius: 4px;
    border: ${({ theme }) => `1px solid ${theme.new.colors.neutral300}`};
  }

  ${Button} {
    ${({ theme }) => theme.new.typography.p5};
    color: ${({ theme }) => `${theme.new.colors.neutral800}`};
    height: 41px;
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:nth-child(4) {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      color: ${({ theme }) => `${theme.new.colors.neutral800}`};
    }
  }
`;
