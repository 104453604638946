import { func, bool, object, number } from 'prop-types';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import NewModal from 'components/common/NewModal';
import { FULFILLED, PENDING } from 'constants/actionStatusConstants';
import { useDispatch, useActionOnSuccess, useStatus, useConfirm } from 'hooks';
import { editProjectStatus, deleteProjectStatus } from 'state/actions/projectActions';

import ProjectStatusForm from '../ProjectStatusForm';

const EditProjectStatusModal = ({
  statusId,
  project,
  initialValues,
  isShowing,
  hide,
  handleRefreshData
}) => {
  const intl = useIntl();
  const editStatusRequest = useDispatch(editProjectStatus);
  const resetEditStatusRequest = useDispatch(editProjectStatus.reset);
  const resetDeleteStatusRequest = useDispatch(deleteProjectStatus.reset);
  const { status: editStatus, error: editError } = useStatus(editProjectStatus);
  const { status: deleteStatus, error: deleteError } = useStatus(deleteProjectStatus);
  const deleteStatusRequest = useDispatch(deleteProjectStatus);

  const [ConfirmationDelete, confirmDelete] = useConfirm({
    title: intl.formatMessage({ id: 'modal.confirm.title' }),
    message: intl.formatMessage({ id: 'projectStatus.confirmDelete' }),
    onConfirm: () => deleteStatusRequest({ statusId }),
    onSuccess: () => handleRefreshData?.(),
    confirmLabelIntlId: 'common.delete',
    cancelLabelIntlId: 'common.cancel'
  });

  useActionOnSuccess([editStatus, deleteStatus], hide);
  useEffect(() => {
    resetEditStatusRequest();
    resetDeleteStatusRequest();
  }, [resetEditStatusRequest, resetDeleteStatusRequest]);

  const onSubmit = async values => {
    const { type } = await editStatusRequest({ ...values, statusId });
    if (type.endsWith(`/${FULFILLED}`)) {
      handleRefreshData?.();
    }
  };

  return (
    <NewModal
      isShowing={isShowing}
      hide={hide}
      maxWidth="40rem"
      title={intl.formatMessage(
        { id: 'template.editProjectStatus.title' },
        { projectName: project.name }
      )}
      withoutOverflow
    >
      <ProjectStatusForm
        project={project}
        initialValues={initialValues}
        onSubmit={onSubmit}
        status={editStatus}
        error={editError || deleteError}
        onDelete={() => confirmDelete()}
        isDeleteLoading={deleteStatus === PENDING}
        submitTitleId="common.cap.updateStatus"
      />
      <ConfirmationDelete />
    </NewModal>
  );
};

EditProjectStatusModal.propTypes = {
  statusId: number.isRequired,
  project: object.isRequired,
  isShowing: bool.isRequired,
  hide: func.isRequired,
  initialValues: object,
  handleRefreshData: func
};

export default EditProjectStatusModal;
