import { arrayOf } from 'prop-types';

import { calendarShape } from 'constants/shapes';

import {
  CalendarContainer,
  MonthsContainer,
  WeeksContainer,
  MonthName,
  DatesContainer
} from './styles';

const Calendar = ({ calendar }) => (
  <CalendarContainer>
    <MonthsContainer>
      {calendar.map(({ month, year, weeks }) => (
        <MonthName weeks={weeks} key={`${month}-${year}`}>{`${month} ${year}`}</MonthName>
      ))}
    </MonthsContainer>
    <WeeksContainer>
      {calendar.map(({ month, weeks }) =>
        weeks.map((week, index) => (
          <DatesContainer key={`${month}-${index}`} week={week}>
            {week.startDate}
            <span>th</span> - {week.endDate}
            <span>th</span>
          </DatesContainer>
        ))
      )}
    </WeeksContainer>
  </CalendarContainer>
);

Calendar.propTypes = {
  calendar: arrayOf(calendarShape)
};

export default Calendar;
