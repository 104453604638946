import styled from 'styled-components';

export const TimelineHeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 7.5rem;
  position: sticky;
  top: 8.2rem;
  z-index: ${({ theme }) => theme.zIndex.stickyTimeline};
`;

export const Corner = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 12px;
  justify-content: space-between;
  display: flex;
  padding-left: 1rem;
  position: sticky;
  top: ${({ theme }) => theme.fixedPositions.timeLine};
  width: ${({ theme }) => theme.constantWidths.timelineHeader};
`;
