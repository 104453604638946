import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.div`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  width: ${({ width }) => (width ? `${width}rem` : 'auto')};
`;

const checkboxVariant = variant =>
  ({
    old: css`
      background-color: ${({ checked, theme }) => (checked ? theme.colors.seaBlue : 'transparent')};
      border: ${({ checked, theme }) => (checked ? 'none' : `0.5px solid ${theme.colors.gray60}`)};
      svg {
        stroke: ${({ theme }) => theme.colors.white};
      }
      ${({ disabled, theme, checked }) =>
        disabled &&
        css`
          background-color: ${checked ? theme.colors.disabled : 'transparent'};
          border: ${`0.5px solid ${theme.colors.gray60}`};
        `}
    `,
    new: css`
      background: ${({ theme, checked }) =>
        checked ? theme.new.colors.brandLight : theme.new.colors.white};
      border: 1px solid
        ${({ theme, checked }) => (checked ? theme.new.colors.brand : theme.new.colors.N500)};
      svg {
        stroke: ${({ theme, checked }) =>
          checked ? theme.new.colors.brand : theme.new.colors.white};
          transform: scale(${13.3 / 15});
          path {
            stroke-width: 2.5px;
          }
      }
      width: 19px;
      height: 19px;
  }
    `
  }[variant]);

export const StyledCheckbox = styled.div`
  ${({ variant }) => checkboxVariant(variant)}
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  height: ${({ size }) => size || '1.7rem'};
  justify-content: center;
  transition: all 100ms;
  width: ${({ size }) => size || '1.7rem'};
`;

export const Label = styled.div`
  ${({ variant }) =>
    ({
      old: css`
        color: ${({ theme }) => theme.colors.gray80};
        font-size: 1.4rem;
        margin-left: 0.8rem;
      `,
      new: css`
        ${({ theme }) => theme.new.typography.p4};
        color: ${({ theme }) => theme.new.colors.neutral800};
        margin-left: 0.9rem;
      `
    }[variant])};
`;
