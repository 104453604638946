import { snakeCase } from 'lodash';
import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import AlertMessage from 'components/common/AlertMessage';
import { PENDING, REJECTED } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS } from 'constants/constants';
import { useDispatch } from 'hooks';
import { addTemporalRole, editResourceRole } from 'state/actions/settingsActions';
import { getRequestFormatDate } from 'utils/date';

import FormFields from './components/FormFields';
import { ButtonRow, Form, StyledButton } from './styles';

const RoleForm = ({ status, error, hide, resource, isEdit, temporalGroupId }) => {
  const methods = useForm({
    mode: 'onChange'
  });
  const { handleSubmit, reset } = methods;
  const addRoleRequest = useDispatch(addTemporalRole);
  const editRoleRequest = useDispatch(editResourceRole);

  const setInitialValues = useCallback(() => {
    reset({
      role: ''
    });
  }, [reset]);

  useEffect(() => {
    setInitialValues({
      role: ''
    });
  }, [setInitialValues]);

  const onSubmitOverride = async values => {
    const { role } = values;
    const body = {
      temporalGroup: {
        group: snakeCase(role?.label),
        startDate: getRequestFormatDate(new Date()),
        personId: resource?.id
      }
    };
    isEdit ? await editRoleRequest({ id: temporalGroupId, body }) : await addRoleRequest({ body });
  };

  const onSave = () => {
    handleSubmit(onSubmitOverride)();
    hide();
  };

  const onSaveAndMore = async () => {
    await handleSubmit(onSubmitOverride)();
    setInitialValues({
      role: ''
    });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSave}>
        <FormFields />

        {status === REJECTED && <AlertMessage>{error || ''}</AlertMessage>}
        <ButtonRow gap="1.2rem">
          <StyledButton
            id="addAssignmentsBtn"
            type="button"
            variant={BUTTON_VARIANTS.NEW_SECONDARY}
            isLoading={status === PENDING}
            textIntlId="assignment.form.copy"
            onClick={onSaveAndMore}
            outlined
            fullWidth
          />
          <StyledButton
            id="saveCompleteBtn"
            type="submit"
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
            isLoading={status === PENDING}
            textIntlId="common.save"
            fullWidth
          />
        </ButtonRow>
      </Form>
    </FormProvider>
  );
};

RoleForm.propTypes = {
  error: string,
  hide: func,
  status: string,
  resource: shape({ name: string, id: string }),
  isEdit: bool,
  temporalGroupId: oneOfType([number, string])
};

export default RoleForm;
