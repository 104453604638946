import { number } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as Discount } from 'assets/icons/discount.svg';

import IconWithTooltip from '../IconWithTooltip';

export const DiscountIcon = ({ discount }) => {
  const intl = useIntl();

  return (
    <IconWithTooltip
      icon={<Discount />}
      message={intl.formatMessage({ id: 'common.discountOf' }, { discount })}
    />
  );
};

DiscountIcon.propTypes = {
  discount: number
};
