import { arrayOf, bool, func, object, oneOfType } from 'prop-types';
import { useIntl } from 'react-intl';

import { optionShape, projectsOverviewFilters } from 'constants/shapes';

import { Container, Item, XCircleIcon, Active, TrashIcon, ClearContainer } from './styles';

const ActiveFilters = ({
  selectedOptions,
  filters,
  setFilters,
  handleClear,
  withIntlLabel = true,
  handleOwnDelete = false
}) => {
  const intl = useIntl();

  const handleDelete = (field, value) => {
    if (handleOwnDelete) {
      setFilters(value);
      return;
    }
    const result = filters[field]?.map(item => {
      if (item.key === value) {
        return { ...item, value: false };
      }
      return item;
    });
    setFilters(prevState => ({
      ...prevState,
      [field]: result
    }));
  };

  if (!selectedOptions?.length) return null;

  return (
    <Container>
      <Active>
        {selectedOptions?.map(({ field, key, label, hasIntlOptions }) => (
          <Item key={key + field}>
            <span>
              {withIntlLabel || hasIntlOptions ? intl.formatMessage({ id: label }) : label}
            </span>
            <XCircleIcon onClick={() => handleDelete(field, key)} />
          </Item>
        ))}
      </Active>
      {selectedOptions.length >= 2 && (
        <ClearContainer onClick={handleClear}>
          <TrashIcon />
          {intl.formatMessage({ id: 'filter.dashboard.clear' })}
        </ClearContainer>
      )}
    </Container>
  );
};

ActiveFilters.propTypes = {
  setFilters: func.isRequired,
  filters: oneOfType([projectsOverviewFilters.isRequired, arrayOf(object)]),
  selectedOptions: arrayOf(optionShape).isRequired,
  handleClear: func.isRequired,
  withIntlLabel: bool,
  handleOwnDelete: bool
};

export default ActiveFilters;
