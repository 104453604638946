import styled from 'styled-components';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';

export const Horizontal = styled.hr`
  border-width: 0;
  background-color: ${({ theme, color }) => color || theme.new.colors.neutral300};
  height: ${({ height }) => height || '1px'};
  margin: ${({ margin }) => margin};
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.new.colors.white};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.new.shadows.medium.boxShadow};
  padding: 3.2rem 2.4rem;
`;

export const DetailsHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & h3 {
    color: ${({ theme }) => theme.new.colors.brand};
    ${({ theme }) => theme.new.typography.p3SemiBold};
  }
`;

export const EditIcon = styled(Edit)`
  &:hover {
    cursor: pointer;
  }
`;

export const DetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const InfoItem = styled.div`
  align-items: center;
  display: flex;
  white-space: pre-wrap;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  & p:nth-child(1) {
    color: ${({ theme }) => theme.new.colors.neutral800};
    ${({ theme }) => theme.new.typography.p4SemiBold};
  }

  & p:nth-child(2) {
    color: ${({ theme }) => theme.new.colors.neutral800};
    ${({ theme }) => theme.new.typography.p4};
  }
`;
