import { css } from 'styled-components';

import { setSvgColors } from 'utils/cssUtilities';

const fontColor = css`
  ${({ theme }) => theme.colors.white}
`;

const backgroundColor = css`
  ${({ theme }) => theme.colors.seaBlue}
`;
export const ApplyButton = css`
  ${setSvgColors({ fillColor: backgroundColor, strokeColor: fontColor })}
  color: ${fontColor};
  background-color: ${backgroundColor};
  border-radius: 23px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  height: 3rem;
  line-height: 1.9rem;
  margin-right: 0.5rem;
  padding: 0 0.9rem;
  width: 10.9rem;
`;
