import styled from 'styled-components';

import TabButtons from 'components/common/TabButtons';
import { TabButton } from 'components/common/TabButtons/styles';

export const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.new.colors.white};
  border-radius: 0.8rem;
  box-shadow: 0 0 2px 0 rgba(79, 94, 113, 0.12), 0 2px 4px 0 rgba(79, 94, 113, 0.11),
    0 4px 8px 0 rgba(79, 94, 113, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;
  padding: 1.6rem;
  width: 386.667px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
  align-self: stretch;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  padding-bottom: 2rem;
  & h3 {
    ${({ theme }) => theme.new.typography.p3SemiBold};
    color: ${({ theme }) => theme.new.colors.N900};
    line-height: 2.4rem;
  }

  & h4 {
    ${({ theme }) => theme.new.typography.h4};
    color: ${({ theme }) => theme.new.colors.P800};
  }
`;

export const StyledTabButtons = styled(TabButtons)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N300};
  ${TabButton} {
    padding: ${({ active }) => `0 0.8rem ${active ? '1.8rem' : '1.6rem'} 0.8rem`};
  }
`;
