import { tooltipClasses } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg';
import MuiTooltip from 'components/common/MuiTooltip';
import { Content } from 'components/common/MuiTooltip/styles';

export const StyledPeopleIcon = styled(PeopleIcon)`
  path {
    stroke: ${({ theme }) => theme.new.colors.N900};
    stroke-width: 2px;
  }
  transform: scale(${16 / 24});
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const PeopleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  stroke: ${({ theme }) => theme.new.colors.N700};
`;

export const StyledTooltip = styled(MuiTooltip)`
  ${Content} {
    ${({ theme }) => theme.new.typography.p6};
    padding: 0.5rem 1.2rem 0.7rem 1.2rem;
    color: ${({ theme }) => theme.new.colors.N800};
    min-width: 1px;
    width: fit-content;
  }
  & .${tooltipClasses.tooltip} {
    border-radius: 4px;
    padding: 0;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14);
  }
  & .${tooltipClasses.tooltipPlacementTop} {
    margin-bottom: 2px !important;
  }
  & .${tooltipClasses.arrow} {
    font-size: 1.7rem;
    &::before,
    &::after {
      border-bottom-right-radius: 2.5px;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
`;
