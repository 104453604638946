import { useIntl } from 'react-intl';

import useCustomer from 'hooks/useCustomer';

export default () => {
  const intl = useIntl();
  const { customerOptions, isCustomerPending } = useCustomer();
  return [
    {
      label: intl.formatMessage({ id: 'common.customer' }),
      options: customerOptions,
      isCustomerPending
    }
  ];
};
