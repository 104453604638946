import { bool, func, string } from 'prop-types';

import { Box, Header, XCircleIcon } from './styles';

const BoxItem = ({ title, info, isEditing, onDelete }) => (
  <Box>
    <Header>
      <h3>{title}</h3>
      {isEditing && <XCircleIcon onClick={onDelete} />}
    </Header>
    {info && <p>{info}</p>}
  </Box>
);

BoxItem.propTypes = {
  info: string,
  isEditing: bool,
  onDelete: func,
  title: string
};

export default BoxItem;
