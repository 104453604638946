import dayjs from 'dayjs';
import { arrayOf, bool, func, instanceOf, number, oneOfType, string } from 'prop-types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import EventTooltip from 'components/common/EventTooltip';
import { TimelineRowContent, TimelineRowContentAbsolut } from 'components/common/Layout/Timeline';
import PermissionsGate from 'components/PermissionGate';
import BasicInfoColumn from 'components/ProjectsOverview/AssignmentsTable/BasicInfoColumn';
import { NewAssignmentButton } from 'components/ProjectsOverview/AssignmentsTable/styles';
import Event from 'components/timeline/Fragments/Event';
import EmptyPeriod from 'components/timeline/Fragments/Event/EmptyPeriod';
import NewPersonProjectsAssignments from 'components/timeline/NewPersonProjectsAssignments';
import { ASSIGNMENTS_STATUS, EVENT_TYPE } from 'constants/constants';
import { SCOPES } from 'constants/permissions';
import { projectOpenAssignmentShape, projectResourceShape, projectShape } from 'constants/shapes';
import { useRole } from 'hooks';
import { getRequestFormatDate, getXAxisFormatDate } from 'utils/date';
import hasPermission from 'utils/hasPermission';
import { getResourceEventsTimeLineFragments } from 'utils/resourceEventsUtilities';

import { AssignmentHeader, AssignmentRow, NewAssignmentRow } from './styles';

const BARS_HEIGHT = 4.8;

const ProjectAssignments = ({
  setAddAssignment,
  setEditAssignment,
  resources,
  openAssignments,
  setPersonId,
  project,
  startDateTimeline,
  endDateTimeline,
  projectId
}) => {
  const intl = useIntl();
  const { permissions } = useRole();
  const getPersonEventsTimeLine = useCallback(
    events => {
      const personEventsFragments = getResourceEventsTimeLineFragments(
        events,
        startDateTimeline,
        endDateTimeline
      );

      return personEventsFragments?.map(({ startDate, endDate, value }, index) =>
        value ? (
          <Event
            key={`event-${index}`}
            eventStartDate={startDate}
            eventEndDate={endDate}
            {...value}
          />
        ) : (
          <EmptyPeriod key={`emptyPeriod-${index}`} startDate={startDate} endDate={endDate} />
        )
      );
    },
    [startDateTimeline, endDateTimeline]
  );

  const getTimeOffsEvents = useCallback(
    timeOffs =>
      timeOffs?.map(({ startDate: fromDate, endDate: toDate }) => ({
        startDate: getRequestFormatDate(fromDate),
        endDate: getRequestFormatDate(toDate),
        type: EVENT_TYPE.TIME_OFF,
        children: (
          <EventTooltip
            type={EVENT_TYPE.TIME_OFF}
            textRow1={`${intl.formatMessage({
              id: 'common.from'
            })}: ${getXAxisFormatDate(fromDate)}`}
            textRow2={`${intl.formatMessage({
              id: 'common.to'
            })}: ${getXAxisFormatDate(toDate)}`}
          />
        )
      })),
    [intl]
  );

  const getCountryHolidayEvents = useCallback(
    holidays =>
      holidays?.map(date => ({
        startDate: getRequestFormatDate(date),
        endDate: getRequestFormatDate(date),
        type: EVENT_TYPE.TIME_OFF,
        isHoliday: true,
        children: (
          <EventTooltip
            type={EVENT_TYPE.TIME_OFF}
            textRow1={`${intl.formatMessage({
              id: 'common.from'
            })}: ${getXAxisFormatDate(date)}`}
            textRow2={`${intl.formatMessage({
              id: 'common.to'
            })}: ${getXAxisFormatDate(date)}`}
          />
        )
      })),
    [intl]
  );

  const getExitDateEvents = useCallback(
    exitDate => [
      {
        startDate: getRequestFormatDate(exitDate),
        endDate: getRequestFormatDate(exitDate),
        type: EVENT_TYPE.EXIT_DATE,
        children: (
          <EventTooltip
            type={EVENT_TYPE.EXIT_DATE}
            title={`${intl.formatMessage({ id: 'common.exitDate' })}:`}
            textRow1={getXAxisFormatDate(exitDate)}
          />
        )
      }
    ],
    [intl]
  );

  const getMainSkills = assignments => {
    const assignmentSkills = assignments.reduce(
      (result, assignment) => result.concat(assignment?.mainSkills),
      []
    );
    return assignmentSkills.filter(Boolean);
  };

  const getOpenPositionMainSkills = fragments => {
    const openAssignmentData = fragments?.map(fragment => {
      if (fragment?.mainSkills) {
        return fragment;
      }
    });
    return getMainSkills(openAssignmentData);
  };

  const getDiscount = assignments => {
    const discounts = assignments?.map(({ discountPercentage }) => discountPercentage);
    return discounts[0];
  };

  const viewProfile = id =>
    hasPermission(permissions, [SCOPES.seeProfileDetail]) ? () => setPersonId(id) : null;

  return (
    <>
      {openAssignments?.map((assignment, index) => (
        <AssignmentRow key={`${projectId}-${index}`}>
          <AssignmentHeader isFirst={index === 0}>
            <BasicInfoColumn
              fullName={intl.formatMessage({ id: 'common.NA' })}
              role={assignment.role}
              mainSkills={getOpenPositionMainSkills(assignment?.fragments)}
              maxWidth="12rem"
            />
          </AssignmentHeader>
          <TimelineRowContent height={BARS_HEIGHT} padding="0">
            <NewPersonProjectsAssignments
              startDateTimeline={startDateTimeline}
              endDateTimeline={endDateTimeline}
              project={project}
              setAddAssignment={setAddAssignment}
              setEditAssignment={setEditAssignment}
              assignments={assignment?.fragments}
              status={ASSIGNMENTS_STATUS.open}
              isReadOnly={
                !hasPermission(permissions, [SCOPES.editAssignment, SCOPES.editAssignmentBasic])
              }
            />
          </TimelineRowContent>
        </AssignmentRow>
      ))}
      {resources?.map((resource, index) => {
        const { exitDate, timeOffs, fullName, avatar, assignments, id, holidays } = resource;
        const exitDateEvents = exitDate ? getExitDateEvents(exitDate) : [];
        const timeOffsEvents = timeOffs ? getTimeOffsEvents(timeOffs) : [];
        const countryHolidayEvents = holidays ? getCountryHolidayEvents(holidays) : [];
        const events = [...exitDateEvents, ...timeOffsEvents, ...countryHolidayEvents].sort(
          (a, b) => (dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1)
        );
        const personEventsTimeLine = events.length ? getPersonEventsTimeLine(events) : [];
        return (
          <AssignmentRow key={`${projectId}-${index}`}>
            <AssignmentHeader isFirst={openAssignments?.length === 0 && index === 0}>
              <BasicInfoColumn
                fullName={fullName}
                role={assignments[0]?.role?.name}
                avatarUrl={avatar}
                mainSkills={getMainSkills(assignments)}
                viewProfile={viewProfile(id)}
                discount={getDiscount(assignments)}
                maxWidth="12rem"
              />
            </AssignmentHeader>
            <TimelineRowContent height={BARS_HEIGHT} padding="0">
              {!!personEventsTimeLine.length && (
                <TimelineRowContentAbsolut height={BARS_HEIGHT + 0.5} paddingBottom="0">
                  {personEventsTimeLine}
                </TimelineRowContentAbsolut>
              )}
              <NewPersonProjectsAssignments
                person={resource}
                startDateTimeline={startDateTimeline}
                endDateTimeline={endDateTimeline}
                project={project}
                setAddAssignment={setAddAssignment}
                setEditAssignment={setEditAssignment}
                assignments={assignments}
                isReadOnly={
                  !hasPermission(permissions, [SCOPES.editAssignment, SCOPES.editAssignmentBasic])
                }
              />
            </TimelineRowContent>
          </AssignmentRow>
        );
      })}
      <NewAssignmentRow>
        <PermissionsGate scopes={[SCOPES.createAssignment]}>
          <NewAssignmentButton
            id="newAssignment"
            type="button"
            textIntlId="common.newAssignment"
            icon={<PlusIcon />}
            onClick={() => setAddAssignment({ project })}
          />
        </PermissionsGate>
      </NewAssignmentRow>
    </>
  );
};

ProjectAssignments.propTypes = {
  setAddAssignment: func,
  setEditAssignment: func,
  setPersonId: func,
  showResourceDetail: func,
  project: projectShape,
  projectId: number,
  resources: arrayOf(projectResourceShape),
  openAssignments: arrayOf(projectOpenAssignmentShape),
  startDateTimeline: oneOfType([string, instanceOf(Date)]),
  endDateTimeline: oneOfType([string, instanceOf(Date)]),
  isLoading: bool
};

export default ProjectAssignments;
