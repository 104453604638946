import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const barHeight = (value, maxValue) => `${(100 * value) / maxValue}%`;

export const DashboardAssignmentContainer = styled.div`
  margin: 2.4rem 0;
`;

export const BarsContainer = styled.div`
  border-radius: 0.5rem;
  display: flex;
  margin: 0 2rem 3rem;
  z-index: ${({ theme }) => theme.zIndex.generalAvailability};
`;

export const BarWrapper = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0rem 1rem;
  padding: 1.8rem 1.6rem;
  width: 100%;
  position: relative;

  &::after {
    background-color: ${({ theme }) => theme.colors.seaBlue};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: ${({ current }) => (current ? 'block' : 'none')};
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 0.6rem;
  }
`;

export const BarArea = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.gray05};
  border-radius: 10px;
  display: flex;
  flex: 1 1 auto;
  height: 3.2rem;
  justify-content: flex-start;
  width: 100%;
`;

export const BarLegend = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const Bar = styled.div`
  background-color: ${({ data: { info } }) => info?.color};
  border-radius: 5px;
  height: 100%;
  width: ${({ data: { utilizationPercentage }, maxUtilizationPercentage }) =>
    barHeight(utilizationPercentage, maxUtilizationPercentage)};
`;

export const AreaTitle = styled.p`
  ${({ theme }) => theme.typography.semiBold}
  font-size: 1.5rem;
`;

export const AreaUtilizationPercentage = styled.p`
  ${({ theme }) => theme.typography.extraBold}
  font-size: 1.5rem;
  line-height: 1.3;
`;

export const Tooltip = styled(ReactTooltip)`
  box-shadow: ${({ theme }) => theme.colors.boxShadowColor} 1px 1px 2px 1px;
`;

export const TableContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
`;
