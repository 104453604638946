import styled, { keyframes } from 'styled-components';
import { ReactComponent as WarningSvg } from 'assets/icons/no-network.svg';

const growPadding = keyframes`
  from {
    padding: 0 0 1rem 0;
  }
  to {
    padding: 0 5rem 1rem 5rem;
  }
`;

const opacityHandler = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  animation: ${opacityHandler} 1s ease-in-out;
`;

export const StyledWarningSvg = styled(WarningSvg)`
  width: 6rem;
  height: 6rem;

  path {
    fill: ${({ theme }) => theme.new.colors.N600};
    stroke: none;
  }
`;

export const Message = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const StyledStatusNumber = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: #9b0303;
  border-bottom: 1px solid #9b0303;
  padding: 0 5rem 1rem 5rem;
  margin-bottom: 1rem;
  display: inline-block;
  white-space: nowrap;
  animation: ${growPadding} 3s ease-out forwards, ${opacityHandler} 1s ease-in-out;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.new.colors.N600};
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const Description = styled.h1`
  color: ${({ theme }) => theme.new.colors.N500};
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0;
  text-align: center;
`;
