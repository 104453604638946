import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-top: 0.3rem;
  color: ${({ theme }) => theme.colors.gray100};
`;

export const Range = styled.div`
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  align-items: center;
`;

export const Percentage = styled.div`
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
