import { arrayOf } from 'prop-types';
import { useIntl } from 'react-intl';

import { ReactComponent as AlertTriangle } from 'assets/icons/alert-triangle.svg';
import { DISPLAY_DAY_MONTH_YEAR_NEW } from 'constants/dateFormats';
import { timeOffsShape } from 'constants/shapes';
import { dateToString } from 'utils/date';

import { Container, Content, Title, Text } from './styles';

const ScheduledVacations = ({ timeOffs }) => {
  const intl = useIntl();

  if (!timeOffs?.length) return null;

  return (
    <Container>
      {timeOffs.map(({ id, startDate, endDate, amountOfDays }) => {
        const formatStartDate = dateToString(startDate, DISPLAY_DAY_MONTH_YEAR_NEW);
        const formatEndDate = dateToString(endDate, DISPLAY_DAY_MONTH_YEAR_NEW);
        return (
          <Content key={id}>
            <Title>
              <AlertTriangle />
              {`${intl.formatMessage({
                id: 'common.cap.scheduledVacations'
              })}:`}
            </Title>
            <Text>
              {` ${amountOfDays} ${intl.formatMessage({
                id: 'common.lower.days'
              })} | ${formatStartDate} - ${formatEndDate}`}
            </Text>
          </Content>
        );
      })}
    </Container>
  );
};

ScheduledVacations.propTypes = {
  timeOffs: arrayOf(timeOffsShape)
};

export default ScheduledVacations;
