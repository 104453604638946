import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';

import SignUpForm from 'components/user/SignUpForm';
import routesPaths from 'constants/routesPaths';
import { useSession, useDispatch } from 'hooks';
import { signUp } from 'state/actions/userActions';

const SignUp = () => {
  const { authenticated } = useSession();
  const signUpRequest = useDispatch(signUp);

  if (authenticated) {
    return <Redirect to={routesPaths.index} />;
  }

  return (
    <div>
      <p>
        <FormattedMessage id="common.upper.signUp" />
      </p>
      <SignUpForm onSubmit={signUpRequest} />
      <Link to={routesPaths.login}>
        <FormattedMessage id="common.cap.signIn" />
      </Link>
    </div>
  );
};

export default memo(SignUp);
