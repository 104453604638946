import styled from 'styled-components';

import { ReactComponent as SearchSvg } from 'components/icons/search.svg';
import { ReactComponent as TickSvg } from 'components/icons/tick.svg';

import OptionsButton from '../OptionsButton';
import { OptionsContainer } from '../OptionsButton/styles';

export const SearchIcon = styled(SearchSvg)`
  height: 2rem;
  width: 2rem;
  path {
    stroke-width: 1px;
  }
`;

export const TickIcon = styled(TickSvg)`
  height: 2.4rem;
  width: 2.4rem;
  path {
    stroke-width: 0.7px;
  }
`;

export const StyledOptionsButton = styled(OptionsButton)`
  position: absolute;
  right: 1.1rem;
  top: 1.5rem;
  ${OptionsContainer} {
    position: absolute;
  }
`;
