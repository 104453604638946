import styled from 'styled-components';

export const BillableCapacityContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  min-width: 42rem;
  padding: 1.2rem 1.8rem 1.7rem 1.8rem;
  width: 100%;
`;

export const DataSection = styled.section`
  align-content: center;
  align-items: center;
  display: grid;
  flex-grow: 1;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  row-gap: 0.5rem;
`;

export const InfoLabel = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  max-width: 17rem;

  &::before {
    background-color: ${({ color, theme }) => color || theme.colors.white};
    border: ${({ color, theme }) => `2px ${color || theme.colors.gray60} solid`};
    border-radius: 7.5px;
    content: '';
    display: inline-block;
    flex-shrink: 0;
    height: 1.6rem;
    margin-right: 0.8rem;
    width: 1.6rem;
  }
`;

export const PercentageValue = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 2.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  justify-content: center;
`;
