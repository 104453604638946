import range from 'lodash/range';

import lines from 'assets/hire_exit_periods.png';
import circleFolder from 'assets/icons/circleFolder.svg';
import circleInfo from 'assets/icons/circleInfo.svg';
import circleNewProject from 'assets/icons/circleNewProject.svg';
import circleWarning from 'assets/icons/circleWarning.svg';

import routesPaths from './routesPaths';
import theme from './theme';

// App common constants

export const SUPPORTED_LANGUAGES = ['en'];
export const DEFAULT_LANGUAGE = 'en';
export const ENGINEERING_TEAM_ID = 1;
export const HIGHLIGHT_SKILL_VALUE = 'Highlight skill';

// Filters
export const STATUS_OPTIONS = [
  { key: 'active', label: 'status.active' },
  { key: 'holiday', label: 'filter.status.holiday' },
  { key: 'sick', label: 'filter.status.sick' },
  { key: 'time_off', label: 'filter.status.timeOff' }
];

export const ENGLISH_OPTIONS = [
  {
    key: 'a1_basic',
    label: 'english.a1Basic',
    value: 'a1_basic'
  },
  {
    key: 'a2_basic',
    label: 'english.a2Basic',
    value: 'a2_basic'
  },
  {
    key: 'b1_intermediate',
    label: 'english.b1Intermediate',
    value: 'b1_intermediate'
  },
  {
    key: 'b2_upper_intermediate',
    label: 'english.b2Intermediate',
    value: 'b2_upper_intermediate'
  },
  {
    key: 'c1_advance',
    label: 'english.c1Advance',
    value: 'c1_advance'
  },
  {
    key: 'c2_advance',
    label: 'english.c2Advance',
    value: 'c2_advance'
  },
  {
    key: 'native_speaker',
    label: 'english.nativeSpeaker',
    value: 'native_speaker'
  }
];

export const SENIORITY_OPTIONS = [
  { key: 'junior', label: 'filter.seniority.junior', value: 'junior' },
  { key: 'semi-senior', label: 'filter.seniority.semiSenior', value: 'semi-senior' },
  { key: 'senior', label: 'filter.seniority.senior', value: 'senior' }
];

export const SENIORITY_MAP = new Map([
  [
    'junior',
    {
      label: 'filter.seniority.junior',
      shortLabel: 'resources.seniority.junior',
      value: 'junior'
    }
  ],
  [
    'semi-senior',
    {
      label: 'filter.seniority.semiSenior',
      shortLabel: 'resources.seniority.semiSenior',
      value: 'semi-senior'
    }
  ],
  [
    'senior',
    {
      label: 'filter.seniority.senior',
      shortLabel: 'resources.seniority.senior',
      value: 'senior'
    }
  ]
]);

export const TEAMS_OPTIONS = [
  { key: 'Engineering Team', label: 'filter.team.engineering', value: 1 },
  { key: 'Product Owner Team', label: 'filter.team.productOwner', value: 2 },
  { key: 'Design Team', label: 'filter.team.design', value: 4 },
  { key: 'Operations', label: 'filter.team.operations', value: 6 },
  { key: 'Scrum Master Team', label: 'filter.team.scrumMaster', value: 7 }
];

export const AVAILABILITY_OPTIONS = [
  { key: '0-25', label: 'filter.availability.range1' },
  { key: '26-50', label: 'filter.availability.range2' },
  { key: '51-75', label: 'filter.availability.range3' },
  { key: '76-100', label: 'filter.availability.range4' }
];

export const WORK_KIND = {
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
  CONTRACTOR: 'contractor'
};

export const PROJECT_STATUS_OPTIONS = [
  { key: 'finished', label: 'status.finished' },
  { key: 'planned', label: 'status.planned' },
  { key: 'maintenance', label: 'status.maintenance' },
  { key: 'active', label: 'status.active' },
  { key: 'warranty', label: 'status.warranty' },
  { key: 'confirmed', label: 'status.confirmed' },
  { key: 'new', label: 'status.new' },
  { key: 'on_hold', label: 'status.onHold' }
];

export const SERVICE_TYPES_OPTIONS = [
  { key: 'discovery', label: 'serviceType.discovery' },
  { key: 'full_product', label: 'serviceType.fullProduct' },
  { key: 'staff_augmentation', label: 'serviceType.staffAugmentation' },
  { key: 'maintenance', label: 'status.maintenance' }
];

export const ASSIGNMENTS_STATUS = {
  open: 'open',
  pending: 'pending',
  confirmed: 'confirmed'
};

export const ASSIGNMENTS_STATUS_OPTIONS = [
  { key: ASSIGNMENTS_STATUS.open, label: 'common.open' },
  {
    key: ASSIGNMENTS_STATUS.pending,
    label: 'common.pending'
  },
  {
    key: ASSIGNMENTS_STATUS.confirmed,
    label: 'status.confirmed'
  }
];

export const ASSIGNMENTS_MAX_COUNT = 10;

export const QUERY_OPTIONS = {
  availability: 'availabilityRanges',
  seniority: 'seniorities',
  skills: 'skills',
  status: 'status',
  teams: 'departments',
  locations: 'locations',
  roles: 'roles',
  englishLevel: 'englishLevel'
};

export const PROJECT_TYPES = {
  BILLABLE: 'billable',
  NON_BILLABLE: 'non_billable'
};

export const BUSINESS_TYPES = {
  CLIENT: 'client',
  INTERNAL: 'internal',
  OPEN_SOURCE: 'open_source',
  TRAINING: 'training'
};

export const PROJECT_STATUS_TYPES = {
  FINISHED: 'finished',
  PLANNED: 'planned',
  MAINTENANCE: 'maintenance',
  ACTIVE: 'active',
  WARRANTY: 'warranty',
  CONFIRMED: 'confirmed',
  ON_HOLD: 'on_hold',
  NEW: 'new'
};

export const SORTING_PROJECT_OPTIONS = {
  status: 'orders[project_statuses.status]',
  name: 'orders[projects.name]',
  id: 'orders[projects.id]',
  startDate: 'orders[projects.start_date]',
  endDate: 'orders[projects.end_date]'
};

export const PEOPLE_TABS = {
  workTable: 0,
  benchTable: 1,
  overassignedTable: 2,
  benchWithPendingTable: 3
};

export const ITEM_TYPE = {
  text: 'text',
  skillsWithTooltip: 'skillsWithTooltip',
  textWithTooltip: 'textWithTooltip',
  utilizationBar: 'utilizationBar',
  diffBar: 'diffBar',
  percentage: 'percentage',
  resource: 'resource',
  hours: 'hours',
  seniority: 'seniority',
  label: 'label',
  resources: 'resources'
};

export const WORK_TYPE = {
  fullTime: 'full_time',
  contractor: 'contractor'
};

export const SEVERITY = {
  error: 'error',
  warn: 'warn',
  info: 'info',
  success: 'success'
};

export const TIME_INTERVALS = {
  monthWeeks: 4,
  yearWeeks: 48
};

export const HARVEST_BILLABLE_HOURS = {
  minExpectedValue: 90,
  maxExpectedValue: 110
};

const NOTIFICATION_COLORS = {
  project: '#009E3D',
  assignment: '#AF6693'
};

export const NOTIFICATIONS_TYPES = {
  newJoiner: {
    id: 0,
    color: '#778A68',
    redirectPage: routesPaths.myReportsProfile,
    getRedirectProps: id => ({ id })
  },
  corruptedData: {
    id: 1,
    color: '#BB5D10',
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  leavingCompany: {
    id: 2,
    color: '#806DAA',
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  projectUpdatedByProjectManager: {
    id: 3,
    color: NOTIFICATION_COLORS.project,
    icon: circleWarning,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  assignmentUpdatedByProjectManager: {
    id: 4,
    color: NOTIFICATION_COLORS.assignment,
    icon: circleWarning,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  newProjectAssigned: {
    id: 5,
    color: NOTIFICATION_COLORS.project,
    icon: circleNewProject,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  assignmentCreatedByOperations: {
    id: 6,
    color: NOTIFICATION_COLORS.assignment,
    icon: circleInfo,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  assignmentUpdatedByOperations: {
    id: 7,
    color: NOTIFICATION_COLORS.assignment,
    icon: circleInfo,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  resourceAssignedByOperations: {
    id: 8,
    color: NOTIFICATION_COLORS.assignment,
    icon: circleInfo,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  assignmentConfirmedByOperations: {
    id: 9,
    color: NOTIFICATION_COLORS.assignment,
    icon: circleWarning,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  projectUpdatedByOperations: {
    id: 10,
    color: NOTIFICATION_COLORS.project,
    icon: circleFolder,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  assignmentCreatedByProjectManager: {
    id: 11,
    color: NOTIFICATION_COLORS.assignment,
    icon: circleWarning,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  assignmentCreationAcknowledged: {
    id: 12,
    color: NOTIFICATION_COLORS.assignment,
    icon: circleWarning,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  assignmentDeletedByProjectManager: {
    id: 13,
    color: NOTIFICATION_COLORS.assignment,
    icon: circleWarning,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  },
  newProjectAssignedSales: {
    id: 14,
    color: NOTIFICATION_COLORS.project,
    icon: circleNewProject,
    redirectPage: routesPaths.projectDetails,
    getRedirectProps: id => ({ id })
  }
};

export const EVENT_TYPE = {
  EXIT_DATE: 'exitDate',
  TIME_OFF: 'timeOff'
};

export const BUTTON_VARIANTS = {
  PRIMARY: 'confirm',
  SECONDARY: 'cancel',
  SUBMIT: 'submit',
  APPLY: 'apply',
  CLEAR: 'clear',
  NEW_PRIMARY: 'newPrimary',
  NEW_SECONDARY: 'newSecondary'
};

export const POSITIONS_TYPES = {
  RIGHT: 'right',
  LEFT: 'left',
  TOP_LEFT: 'topLeft',
  TOP_RIGHT: 'topRight',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_RIGHT: 'bottomRight'
};

export const GLOSSARY = {
  subcontractor: {
    color: '#6932E0',
    label: 'Subcontractors',
    key: 'subcontractor'
  },
  training: { color: '#29B7CB', label: 'Training', key: 'training' },
  pending: { color: '#F1736B', label: 'Pending Status', key: 'pending' },
  overassigned: { color: '#F4A548', label: 'Overassigned', key: 'overassigned' },
  lowAllocation: { color: '#F664F1', label: 'Low Allocation', key: 'lowAllocation' }
};

export const tabPanelIndexes = { allTab: 0, availableTab: 1 };

export const PROJECT_USERS_TYPES = {
  SALES: 'salesRepresentative',
  EM: 'engineerManager',
  DELIVERY: 'deliveryOwner'
};

export const THREE_DOTS_VARIANTS = {
  SMALL: 'small',
  BIG: 'big'
};

export const BILLING_UNITS = { HOURLY: 'hourly', DAILY: 'daily' };

export const FORECASTING_GRAPH_REFERENCES = {
  REVENUE_TO_DATE: 'revenueToDate',
  YEAR_REVENUE_GOAL: 'yearRevenueGoal'
};

export const TOOLTIP_VARIANTS = {
  WHITE: 'white',
  BLACK: 'black'
};

export const PROFILE_TABS = {
  skillSet: 'skillSet',
  projects: 'projects'
};

export const PROFILE_TABS_ARRAY = Object.values(PROFILE_TABS).map(id => ({
  id,
  intlId: `reports.tab.${id}`
}));

export const BADGE_PILL_VARIANTS = {
  PRIMARY: 'primary',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  NEUTRAL: 'neutral'
};

export const SPECIAL_EVENT_TYPES = { training: 'training', conversion: 'conversion' };

export const SPECIAL_EVENTS_OPTIONS = Object.values(SPECIAL_EVENT_TYPES).map(eventType => ({
  value: eventType,
  label: `common.${eventType}`
}));

export const QUARTER_TYPES = { PAST: 'past', CURRENT: 'current', FUTURE: 'future' };

export const ALLOCATION_TIMELINE_TYPES = {
  FULL_AVAILABLE: {
    color: theme.new.colors.FS500,
    label: 'timeline.fullAvailable',
    key: 'fullAvailable'
  },
  PARTIAL_AVAILABLE: {
    color: theme.new.colors.BP500,
    label: 'timeline.partialAvailable',
    key: 'partialAvailable'
  },
  UNAVAILABLE: {
    color: theme.new.colors.FA500,
    label: 'timeline.unavailable',
    key: 'unavailable'
  },
  OVERASSIGNED: {
    color: theme.new.colors.BOA500,
    label: 'timeline.overassigned',
    key: 'overassigned'
  },
  VACATIONS: {
    color: theme.new.colors.T500,
    label: 'timeline.vacations',
    key: 'vacations'
  },
  TRAINING: {
    color: theme.new.colors.brand,
    label: 'timeline.training',
    key: 'training'
  },
  CONVERSION: {
    color: theme.new.colors.FS900,
    label: 'timeline.conversion',
    key: 'conversion'
  },
  HIRE_AND_EXIT_DATES: {
    color: `${theme.new.colors.N400} center/2.4rem url(${lines}) repeat-x `,
    label: 'timeline.hireAndExit',
    key: 'hireAndExit'
  }
};

export const EMPLOYEE_MODAL_TYPES = {
  PROFILE: 'profile',
  ASSIGNMENT: 'assignment'
};

export const ROLE_MODAL_TYPES = {
  ADD: 'Add',
  MANAGE: 'Manage',
  EDIT: 'Edit',
  MATRIX: 'Matrix',
  DELETE: 'Delete'
};

export const GROUP_ROLE_MODAL_TYPES = {
  ADD: 'Add',
  EDIT: 'Edit',
  DELETE: 'Delete'
};

export const RESOURCE_FILTERS = {
  roles: 'roles',
  skills: 'skills',
  seniority: 'seniority',
  availability: 'availability',
  englishLevel: 'englishLevel'
};

export const SETTINGS_FILTERS = {
  roles: 'roles',
  roleGroup: 'roleGroup'
};

export const SORT_DIR = { asc: 'ascendent', desc: 'descendent' };

export const NUMERIC_FORMAT_PROPS = {
  thousandSeparator: ',',
  decimalSeparator: '.',
  decimalScale: 2
};

export const EXPERIENCE_INPUTS = {
  years: { name: 'years', min: 0, max: 99 },
  months: { name: 'months', min: 0, max: 11 }
};

export const HTTP_STATUS_CODES = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404
};

export const FINANCIAL_EXPORT_FILENAME = 'financialReport.csv';

export const BENCH_INSIGHT_EXPORT_FILENAME = (startDate, endDate) =>
  `Bench Resources (${startDate} - ${endDate}).csv`;

export const FINANCIAL_EXPORT_TYPE = 'text/csv;charset=utf-8;';
