import { bool, func, number, string } from 'prop-types';
import { useEffect, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import AlertMessage from 'components/common/AlertMessage';
import Button from 'components/common/Button';
import { Row } from 'components/common/Layout/Row';
import NewInput from 'components/common/NewInput';
import NewModal from 'components/common/NewModal';
import Select from 'components/common/Select';
import ExperienceInputs from 'components/Reports/ResourceProfile/ExperienceInputs';
import { BUTTON_VARIANTS, ENGLISH_OPTIONS } from 'constants/constants';
import { editProfileDataShape } from 'constants/shapes';
import { editResourceDetailsValidations as validations } from 'constants/validators';
import { useDispatch, useSkills } from 'hooks';
import { editResourceProfile } from 'state/actions/resourceProfileActions';
import { filterSkillsByDepartment } from 'utils/helpers';
import { composeEditDetails } from 'utils/resourceProfileUtilities';

import {
  AddSkillButton,
  ExperienceContainer,
  Form,
  MainSkills,
  StyledDeleteButton,
  Title
} from './styles';

const staticFields = {
  englishLevel: 'englishLevel',
  bioLink: 'bioLink'
};

const EditDetailsModal = ({
  isOpen,
  setIsOpen,
  initialData,
  editDetailRequest,
  isLoading,
  error,
  departmentId
}) => {
  const intl = useIntl();
  const { skillOptions } = useSkills();
  const resetEditRequest = useDispatch(editResourceProfile.reset);

  const departmentSkillsOptions = filterSkillsByDepartment(skillOptions, departmentId);

  const { englishLevel, mainSkills, figmaBio } = initialData;

  const initialValues = {
    skills: [],
    bioLink: figmaBio || '',
    englishLevel: ENGLISH_OPTIONS.find(({ key }) => key === englishLevel)
  };

  const onSubmit = data => editDetailRequest(composeEditDetails(data));

  const {
    handleSubmit,
    control,
    formState: { errors },
    register
  } = useForm({
    defaultValues: {
      ...initialValues,
      ...(initialValues?.englishLevel && {
        englishLevel: {
          ...initialValues?.englishLevel,
          label: intl.formatMessage({ id: initialValues?.englishLevel?.label })
        }
      })
    }
  });

  const {
    fields: skillsFields,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'skills'
  });
  const isInitialSkillLoaded = useRef(false);

  useEffect(() => {
    if (
      !skillsFields.length &&
      !isInitialSkillLoaded.current &&
      skillOptions.length &&
      mainSkills?.length > 0
    ) {
      mainSkills?.map(({ name, experienceId, years, months }) => {
        const skill = skillOptions.find(({ key }) => key === name);
        return append({ skillValue: skill, years, months, experienceId }, { shouldFocus: false });
      });

      if (!isInitialSkillLoaded.current) {
        isInitialSkillLoaded.current = true;
      }
    }
  }, [append, mainSkills, skillOptions, skillsFields?.length]);

  useEffect(() => resetEditRequest(), [resetEditRequest]);

  return (
    <NewModal
      isShowing={isOpen}
      hide={() => setIsOpen(false)}
      title={intl.formatMessage({ id: 'reports.resource.editDetails.title' })}
      maxWidth="60.2rem"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row gap="3.2rem">
          <Controller
            name={staticFields.englishLevel}
            control={control}
            rules={{ ...validations[staticFields.englishLevel] }}
            render={({ field }) => (
              <Select
                {...field}
                ref={undefined}
                customRef={field.ref}
                options={ENGLISH_OPTIONS}
                label={intl.formatMessage({ id: 'common.cap.englishLevel' })}
                errors={errors[staticFields.englishLevel]?.message}
                isSearchable
                withoutHighlight
                withIntlOptions
                enablePortal
                fullWidth
                variant="new"
              />
            )}
          />
          <NewInput
            {...register(staticFields.bioLink)}
            label={intl.formatMessage({ id: 'common.cap.figmaBio' })}
            placeholder={intl.formatMessage({
              id: 'reports.resource.editDetails.bioLink.placeholder'
            })}
            withoutHighlight
            Icon={<LinkIcon />}
            fullWidth
          />
        </Row>
        <MainSkills>
          <Row>
            <Title>{intl.formatMessage({ id: 'common.cap.mainSkills' })}</Title>
          </Row>
          {skillsFields?.map((skill, index) => (
            <Row key={skill.id} alignItems="end" gap="1.6rem">
              <Controller
                name={`skills.${index}.skillValue`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    ref={undefined}
                    customRef={field.ref}
                    name={`skills.${index}.skillValue`}
                    isSearchable
                    options={departmentSkillsOptions}
                    label={intl.formatMessage({ id: 'common.skill' })}
                    fullWidth
                    withoutHighlight
                    enablePortal
                    variant="new"
                  />
                )}
              />
              <ExperienceContainer>
                <ExperienceInputs register={register} prefix={`skills.${index}.`} />
                <StyledDeleteButton onClick={() => remove(index)} type="button" variant="new" />
              </ExperienceContainer>
            </Row>
          ))}
          <Row>
            <AddSkillButton
              id="addSkill"
              type="button"
              variant={BUTTON_VARIANTS.NEW_SECONDARY}
              onClick={() =>
                append(
                  { skillValue: null, years: 0, months: 0 },
                  { focusName: `skills.${skillsFields.length}.skillValue`, shouldFocus: true }
                )
              }
              textIntlId="reports.resource.editDetails.addSkill"
              icon={<PlusIcon />}
            />
          </Row>
        </MainSkills>
        {error && <AlertMessage>{error}</AlertMessage>}
        <Row gap="1.2rem" content="start">
          <Button
            id="cancelBtn"
            type="button"
            textIntlId="common.cancel"
            onClick={() => setIsOpen(false)}
            variant={BUTTON_VARIANTS.NEW_SECONDARY}
            isLoading={isLoading}
          />
          <Button
            id="saveBtn"
            type="submit"
            textIntlId="common.save"
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
            isLoading={isLoading}
          />
        </Row>
      </Form>
    </NewModal>
  );
};

EditDetailsModal.propTypes = {
  isOpen: bool,
  setIsOpen: func,
  initialData: editProfileDataShape,
  editDetailRequest: func,
  isLoading: bool,
  error: string,
  departmentId: number
};
export default EditDetailsModal;
