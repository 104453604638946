import styled, { css } from 'styled-components';

import { ReactComponent as Note } from 'assets/icons/note.svg';
import { ASSIGNMENTS_STATUS } from 'constants/constants';
import { lightenColor } from 'utils/colorUtilities';
import { fragmentFlex } from 'utils/timelineUtilities';

export const getColorByAssignmentStatus = (theme, status) =>
  ({
    [ASSIGNMENTS_STATUS.open]: {
      color: theme.new.colors.BO100,
      hoverColor: theme.new.colors.BO200,
      borderColor: theme.new.colors.BO300
    },
    [ASSIGNMENTS_STATUS.pending]: {
      color: theme.new.colors.BP100,
      hoverColor: theme.new.colors.BP200,
      borderColor: theme.new.colors.BP300
    },
    [ASSIGNMENTS_STATUS.confirmed]: {
      color: theme.new.colors.BC100,
      hoverColor: theme.new.colors.BC200,
      borderColor: theme.new.colors.BC300
    }
  }[status]);

const stripedPattern = (backgroundColor, linesColor) =>
  `repeating-linear-gradient(-55deg,${linesColor},${linesColor} 1px,${backgroundColor} 1px,${backgroundColor} 10px)`;

const percentageColor = props => {
  const { theme, percentage, $isNonBillable } = props;
  if ($isNonBillable) return 'transparent';
  if (percentage > 100) return theme.colors.peach;
  if (percentage === 100) return theme.colors.green;
  if (percentage > 0) return theme.colors.yellow;
  return 'transparent';
};

const getLinesColor = props => {
  const { theme, percentage } = props;
  if (percentage > 100) return theme.colors.redAlpha;
  if (percentage === 100) return theme.colors.camouflageGreenAlpha;
  if (percentage > 0) return theme.colors.goldAlpha;
  return 'transparent';
};

const bgColorDefaultOptions = { hover: false };

const getBackgroundColor = (props, { hover } = bgColorDefaultOptions) => {
  const { percentage, isPending, $isNonBillable, theme } = props;

  let backgroundColor = percentageColor(props);
  if (hover) backgroundColor = lightenColor(backgroundColor, theme.fragmentHighlightAmount);

  if (percentage === 0) {
    return 'transparent';
  }

  if ($isNonBillable) {
    return theme.colors.gray20;
  }

  if (isPending) {
    let linesColor = getLinesColor(props);
    if (hover) linesColor = lightenColor(linesColor, theme.fragmentHighlightAmount);

    return `repeating-linear-gradient(-55deg,${linesColor},${linesColor} 1px,${backgroundColor} 1px,${backgroundColor} 10px)`;
  }

  return backgroundColor;
};

const textColor = ({ percentage, theme, $isNonBillable }) => {
  if ($isNonBillable) return theme.colors.strongNonBillable;
  if (percentage > 100) return theme.colors.red;
  if (percentage === 100) return theme.colors.camouflageGreen;
  if (percentage > 0) return theme.colors.gold;
  return 'transparent';
};

export const Pill = styled.div`
  border-radius: 13px;
  background-color: ${({ variant }) => variant === 'old' && percentageColor};
  padding: 0.4rem 0.8rem;
  margin: 0 0.2rem;
  display: flex;
  justify-content: center;
`;

export const AvailabilityContainer = styled.div`
  align-items: center;
  ${({ variant }) =>
    variant === 'new'
      ? css`
          background: ${({ theme, $isNonBillable, $isSupport, status }) =>
            // eslint-disable-next-line no-nested-ternary
            $isNonBillable
              ? theme.new.colors.N200
              : $isSupport
              ? theme.new.colors.BOA100
              : getColorByAssignmentStatus(theme, status)?.color};
          border-radius: 4px;
          border: ${({ theme, $isNonBillable, $isSupport, startDate, endDate, status }) =>
            fragmentFlex(startDate, endDate) > 0 &&
            `1px solid ${
              // eslint-disable-next-line no-nested-ternary
              $isNonBillable
                ? theme.new.colors.N400
                : $isSupport
                ? theme.new.colors.overAssigned
                : getColorByAssignmentStatus(theme, status)?.borderColor
            }`};
          ${({ isFirst }) =>
            isFirst &&
            css`
              border-left-style: none;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            `};
          ${({ isLast }) =>
            isLast &&
            css`
              border-right-style: none;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            `};

          &:hover {
            background: ${({ theme, $isNonBillable, status, onClick }) =>
              onClick && $isNonBillable
                ? theme.new.colors.N300
                : getColorByAssignmentStatus(theme, status)?.hoverColor};
          }
        `
      : css`
          background: ${({ theme, $isNonBillable }) =>
            $isNonBillable ? theme.colors.softNonBillable : getBackgroundColor};
          border-radius: 5px;
          border-bottom: ${({ theme, isBackfillPosition }) =>
            isBackfillPosition && `2px solid ${theme.colors.backfill}`};
          &:hover {
            background: ${({ onClick, ...props }) =>
              onClick && getBackgroundColor(props, { hover: true })};

            ${Pill} {
              background: ${({ onClick, ...props }) =>
                onClick && getBackgroundColor({ ...props, isPending: false }, { hover: true })};
            }
          }
        `}

  cursor: ${({ onClick }) => onClick && 'pointer'};
  display: flex;
  flex: ${({ startDate, endDate }) => fragmentFlex(startDate, endDate)};
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 0;
`;
export const EmptyAvailabilityElement = styled(AvailabilityContainer)`
  background: transparent;
  pointer-events: none;
`;

export const NewAvailabilityElement = styled(AvailabilityContainer)`
  cursor: ${({ onClick }) => onClick && 'cell'};
`;

export const NewAvailabilityInnerElement = styled(AvailabilityContainer)`
  background-color: ${({ theme }) => theme.colors.gray30};
  height: 100%;
  pointer-events: none;
`;

export const SkillAssignmentElement = styled.div`
  align-items: center;
  background: ${({ isPending, theme }) =>
    isPending ? stripedPattern(theme.colors.green, theme.colors.lightGreen) : theme.colors.green};
  border-radius: 0.5rem;
  border-top: ${({ theme, isOverassigned }) => isOverassigned && `2px solid ${theme.colors.red}`};
  cursor: ${({ onClick }) => onClick && 'pointer'};
  display: flex;
  flex: ${({ startDate, endDate }) => fragmentFlex(startDate, endDate)};
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 0;
`;

export const TextContainer = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  font-size: 1.3rem;
  gap: 0.4rem;
  left: 0;
  margin: auto 0 auto 2rem;
  overflow: hidden;
  right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ variant }) =>
    variant === 'new'
      ? css`
          ${({ theme }) => theme.new.typography.p5};
          color: ${({ theme }) => theme.new.colors.N700};
        `
      : css`
          font-weight: ${props => props.theme.fontWeights.bold};
          color: ${textColor};
          padding: 0.2rem;
        `}

  & > div > svg {
    margin-left: 0.4rem;
  }
`;

export const SkillTextContainer = styled(TextContainer)`
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.camouflageGreen};
`;

export const PercentageContainer = styled.div`
  height: 2.5rem;
`;

export const PlannedText = styled.span`
  margin-left: 1rem;
`;

export const NoteSvg = styled(Note)`
  display: flex;
  flex-shrink: 0;
  stroke: ${textColor};
  width: 20px;
`;
