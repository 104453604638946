import styled from 'styled-components';

import Button from 'components/common/Button';
import { Row } from 'pages/Utilization/styles';

export const AccordionContainer = styled.div`
  border-radius: 12px;
  height: ${({ collapsed }) => (!collapsed ? '5.2rem' : '30rem')};
  margin-bottom: 3rem;
  margin-top: 2.4rem;
  overflow: ${({ collapsed }) => (!collapsed ? 'hidden' : 'scroll')};
  position: relative;
  transition: all 0.6s ease-in-out;
`;

export const Collapsible = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.new.colors.N100};
  cursor: pointer;
  display: flex;
  height: 5.2rem;
  justify-content: space-between;
  padding: 2rem;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.gridTimeline};

  & > svg {
    stroke-width: 1.7px;
  }
`;

export const Title = styled.span`
  ${({ theme }) => theme.new.typography.p3SemiBold};
`;

export const CollapsibleBody = styled.div`
  background: ${({ theme }) => theme.new.colors.N100};
  border-radius: 0 0 12px 12px;
  padding: 2rem;
  position: relative;
  min-height: 23rem;
`;

export const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  gap: 0.3rem;
  height: 3.8rem;
  justify-content: center;
  line-height: 2.043rem;
  margin-top: 0.8rem;
`;

export const ButtonRow = styled(Row)`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;
