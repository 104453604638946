import isEmpty from 'lodash/isEmpty';
import { arrayOf, bool, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AlertMessage from 'components/common/AlertMessage';
import { optionShape, personExperiencesShape } from 'constants/shapes';

import { NumericList, List } from './styles';

const WarningList = ({
  personExperiences,
  selectedSkills,
  personExperiencesLoading = false,
  busyPercentage = 0,
  isResourceAlreadyAssigned
}) => {
  const unexperiencedSkills =
    selectedSkills && personExperiences.length
      ? selectedSkills.filter(
          ({ value: { id } }) => !personExperiences.some(({ skillId }) => skillId === id)
        )
      : [];

  if (
    (isEmpty(unexperiencedSkills) || personExperiencesLoading) &&
    busyPercentage < 100 &&
    !isResourceAlreadyAssigned
  )
    return null;
  return (
    <AlertMessage severity="warn">
      <NumericList>
        {busyPercentage >= 100 && (
          <li>
            <FormattedMessage id="assignment.capacity.max" />
          </li>
        )}
        {!isEmpty(unexperiencedSkills) && (
          <li>
            <FormattedMessage id="assignment.personHasNoExperience" />
            <List>
              {unexperiencedSkills.map(({ label, value: { id } }) => (
                <li key={id}>{label}</li>
              ))}
            </List>
          </li>
        )}
        {isResourceAlreadyAssigned && (
          <li>
            <FormattedMessage id="assignment.resourceAlreadyAssigned" />
          </li>
        )}
      </NumericList>
    </AlertMessage>
  );
};

WarningList.propTypes = {
  personExperiences: arrayOf(personExperiencesShape),
  selectedSkills: arrayOf(optionShape),
  personExperiencesLoading: bool,
  busyPercentage: number,
  isResourceAlreadyAssigned: bool
};

export default WarningList;
