import styled from 'styled-components';

import Button from 'components/common/Button';

export const Form = styled.form`
  margin-top: 3.6rem;
  width: 45rem;
`;

export const StyledButton = styled(Button)`
  font-size: 1.5rem;
  height: 3.6rem;
  margin-top: 2rem;
`;
