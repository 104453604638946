import styled from 'styled-components';

import { Column, Header, Row, Count } from '../Layout/Tables';

export const StyledColumn = styled(Column)`
  ${({ theme }) => theme.new.typography.p5};
  align-items: center;
  border-left: ${({ theme, leftSeparator }) =>
    leftSeparator && `2px ${theme.new.colors.brand} solid`};
  border-right: ${({ theme, rightSeparator }) =>
    rightSeparator && `1px ${theme.new.colors.neutral400} solid`};
  background-color: ${({ theme, isEditing }) => isEditing && theme.new.colors.white};
  color: ${({ theme }) => theme.new.colors.N700};
  justify-content: flex-start;
  padding-left: ${({ paddingLeft }) => paddingLeft || '2rem'};

  &:hover {
    background-color: ${({ theme, isEditing }) => isEditing && theme.new.colors.neutral100};
  }
  & > div:nth-child(1) {
    width: 30rem;
  }
`;

export const StyledHeader = styled(Header)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  ${({ theme }) => theme.new.typography.p5SemiBold};
  background: ${({ theme }) => theme.new.colors.N100};
  border-bottom: 1px solid ${({ theme }) => theme.new.colors.N500};
  color: ${({ theme }) => theme.new.colors.N900};
  max-height: 5.3rem;
  padding-left: 4.8rem;
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  top: 8.2rem;
  z-index: ${({ isSticky, theme }) => isSticky && theme.zIndex.stickyClosePeriodDetailHeader};
  & > div {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;

export const StyledRow = styled(Row)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  max-height: 6.6rem;
  padding-left: 4.8rem;
  :nth-child(odd),
  :nth-child(even) {
    background: ${({ theme }) => theme.new.colors.N100};
  }
`;

export const StyledCount = styled(Count)`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const ResourceData = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 0.4rem;
`;
