import dayjs from 'dayjs';
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { TOTAL_BENCH } from 'constants/dashboardConstants';
import { benchGraphShape } from 'constants/shapes';

import { ContainerGraphTooltip, DataContainer, Header, ItemKey, ItemValue } from './styles';

function GraphTooltip({ payload, label, isQuarter }) {
  const intl = useIntl();

  if (payload?.length) {
    const { totalBenchPercentage, year, quarter, totalBenchFte } = payload[0].payload;

    return (
      <ContainerGraphTooltip>
        <DataContainer>
          <Header>
            {isQuarter
              ? `Q${quarter}`
              : `${dayjs()
                  .month(label - 1)
                  .format('MMM')} ${year}`}
          </Header>
          <ItemKey color={TOTAL_BENCH?.stroke}>
            {intl.formatMessage({ id: TOTAL_BENCH?.intl })}:
          </ItemKey>
          <ItemValue>{totalBenchPercentage ? `${Math.round(totalBenchPercentage)}%` : 0}</ItemValue>
          <ItemValue>{totalBenchFte ? `${totalBenchFte} FTE` : 0}</ItemValue>
        </DataContainer>
      </ContainerGraphTooltip>
    );
  }

  return null;
}

GraphTooltip.propTypes = {
  label: oneOfType([string, number]),
  payload: arrayOf(shape({ payload: benchGraphShape })),
  isQuarter: bool
};

export default GraphTooltip;
