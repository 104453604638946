import { bool, number, object } from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Checkbox from 'components/common/Checkbox';
import DateRange from 'components/common/DatePicker/DateRange';
import { Row, RowInputWrapper, CheckboxWrapper } from 'components/common/Layout/Row';
import { PROJECT_TYPES, SENIORITY_OPTIONS } from 'constants/constants';
import { assignmentMessages } from 'constants/messages';
import { INITIAL_PERCENTAGE_ASSIGNMENT } from 'constants/projectConstants';
import { assignmentValidator } from 'constants/validators';
import { useHeights, useRoles } from 'hooks';
import { assignmentsToOption, seniorityToOption } from 'utils/assignmentFormsUtilities';
import { estimateHours, estimatePercentage } from 'utils/helpers';
import { formatAmount } from 'utils/number';

import fields from './fields.json';
import { StyledInput, StyledSelect, RateRow } from './styles';

const AssignmentsAndDates = ({
  capacityHours,
  initialValuesWithDates,
  noGeneralPermission,
  projectDates
}) => {
  const intl = useIntl();
  const { rolesOptions } = useRoles();
  const { inputHeight } = useHeights();

  const {
    control,
    formState: { errors },
    getValues,
    register,
    reset,
    setValue,
    trigger
  } = useFormContext();
  const { pending, personId, id, ongoing, sameStartDate, percentage, quantity } = useWatch();

  const { initialValues, initialEndDate, initialStartDate } = initialValuesWithDates;
  const { projectStart, projectEnd } = projectDates;

  const assignmentsOptions = assignmentsToOption();
  const isConfirmed = useMemo(() => !pending && personId?.value?.id > 0, [pending, personId]);
  const editMode = id > 0;

  const seniorityOptions = useMemo(
    () => SENIORITY_OPTIONS.map(({ key }) => seniorityToOption(key, intl.formatMessage)),
    [intl]
  );

  const handleChangePercentage = event => {
    const { value: newPercentage } = event.target;
    const wHours = estimateHours(capacityHours, newPercentage);
    setValue(fields.assignment.workHours, wHours);
    setValue(fields.assignment.percentage, newPercentage);
    trigger();
  };

  useEffect(() => {
    const initialWorkHours = formatAmount(capacityHours);
    const initialPercentage =
      percentage ||
      initialValues?.percentage ||
      INITIAL_PERCENTAGE_ASSIGNMENT ||
      estimatePercentage(initialWorkHours, initialWorkHours);

    reset(
      {
        ...getValues(),
        dates: {
          startDate: initialStartDate,
          endDate: initialEndDate
        },
        isNonBillable: initialValues?.assignationType === PROJECT_TYPES.NON_BILLABLE,
        percentage: initialPercentage,
        workHours: (initialWorkHours * initialPercentage) / 100
      },
      { keepDefaultValues: true }
    );
  }, [
    getValues,
    initialEndDate,
    initialStartDate,
    initialValues?.assignationType,
    initialValues?.percentage,
    reset
  ]);

  useEffect(() => {
    if (ongoing) {
      setValue('dates.endDate', projectEnd);
    }
    if (sameStartDate) {
      setValue('dates.startDate', projectStart);
    }
  }, [ongoing, sameStartDate, projectEnd, projectStart, setValue]);

  useEffect(() => {
    if (quantity?.value > 1) {
      setValue(fields.assignment.personId, null);
    }
  }, [quantity?.value, setValue]);

  return (
    <>
      <Row gap="1.2rem">
        {!editMode && (
          <Controller
            name={fields.assignment.quantity}
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                id="quantityDrp"
                placeholder={assignmentMessages.assignment.quantity.placeholder}
                label={intl.formatMessage(assignmentMessages.assignment.quantity.label)}
                options={assignmentsOptions}
                height={inputHeight}
                withoutHighlight
                enablePortal
                isDisabled={noGeneralPermission}
                defaultValue={1}
                variant="new"
                maxWidth="12.8rem"
              />
            )}
          />
        )}
        <Controller
          name={fields.assignment.role}
          control={control}
          rules={assignmentValidator.role}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="roleDrp"
              clearId="deleteRole"
              label={`${intl.formatMessage(assignmentMessages.assignment.role.label)} *`}
              placeholder={intl.formatMessage(assignmentMessages.assignment.role.placeholder)}
              options={rolesOptions}
              height={inputHeight}
              withoutHighlight
              isClearable
              enablePortal
              isSearchable
              isDisabled={noGeneralPermission}
              variant="new"
              errors={errors[fields.assignment.role]?.message}
            />
          )}
        />
        <Controller
          name={fields.assignment.seniority}
          control={control}
          render={({ field }) => (
            <StyledSelect
              {...field}
              id="seniorityDrp"
              clearId="deleteSen"
              label={intl.formatMessage(assignmentMessages.assignment.seniority.label)}
              placeholder={intl.formatMessage(assignmentMessages.assignment.seniority.placeholder)}
              options={seniorityOptions}
              height={inputHeight}
              isSearchable
              isClearable
              withoutHighlight
              enablePortal
              isDisabled={noGeneralPermission}
              variant="new"
              optional
            />
          )}
        />
      </Row>
      <Row gap="1.2rem" style={{ flexDirection: 'column' }}>
        <RowInputWrapper fullWidth>
          <Controller
            name={fields.assignment.dates}
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateRange
                selected={value}
                onChange={onChange}
                names={{
                  startDate: 'assignment.startDate',
                  endDate: 'assignment.endDate'
                }}
                labels={{
                  startDate: intl.formatMessage({ id: 'common.cap.startDate' }),
                  endDate: intl.formatMessage({ id: 'common.cap.endDate' })
                }}
                disabledKeyboardNavigation
                startDateProps={{
                  disabled: noGeneralPermission || sameStartDate
                }}
                endDateProps={{
                  disabled: noGeneralPermission || ongoing
                }}
                minDate={projectStart}
                maxDate={projectEnd}
                separation={0}
                variant="new"
              />
            )}
          />
        </RowInputWrapper>
        <CheckboxWrapper fullWidth paddingBottom="0" style={{ justifyContent: 'space-between' }}>
          <Controller
            name={fields.assignment.sameStartDate}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label="Same as project start date"
                disabled={noGeneralPermission}
                value={value}
                onChange={onChange}
                variant="new"
              />
            )}
          />
          <Controller
            name={fields.assignment.ongoing}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label="Same as project end date"
                disabled={noGeneralPermission}
                value={value}
                onChange={onChange}
                variant="new"
              />
            )}
          />
        </CheckboxWrapper>
      </Row>
      <Row gap="1.2rem" style={{ flexDirection: 'column' }}>
        <RateRow>
          <RowInputWrapper fullWidth>
            <StyledInput
              name={fields.assignment.rate}
              type="number"
              label={intl.formatMessage(assignmentMessages.assignment.rate.label)}
              placeholder={intl.formatMessage(assignmentMessages.assignment.rate.placeholder)}
              style={{ width: '100%' }}
              disabled={noGeneralPermission || (isConfirmed && editMode)}
              {...register(fields.assignment.rate)}
            />
          </RowInputWrapper>
          <RowInputWrapper fullWidth>
            <StyledInput
              name={fields.assignment.percentage}
              type="number"
              step=".01"
              label={intl.formatMessage(assignmentMessages.assignment.percentage.label)}
              placeholder={intl.formatMessage(assignmentMessages.assignment.percentage.placeholder)}
              style={{ width: '100%' }}
              disabled={noGeneralPermission}
              onChange={handleChangePercentage}
              {...register(fields.assignment.percentage, assignmentValidator.percentage)}
              errors={errors[fields.assignment.percentage]?.message}
            />
          </RowInputWrapper>
          <RowInputWrapper fullWidth style={{ display: 'none' }}>
            <StyledInput
              name={fields.assignment.workHours}
              type="number"
              step="any"
              label={intl.formatMessage(assignmentMessages.assignment.workHours.label)}
              placeholder={intl.formatMessage(assignmentMessages.assignment.workHours.placeholder)}
              disabled={noGeneralPermission}
              {...register(fields.assignment.workHours)}
            />
          </RowInputWrapper>
        </RateRow>
        <CheckboxWrapper fullWidth paddingBottom="0" style={{ justifyContent: 'space-between' }}>
          <Controller
            name={fields.assignment.isNonBillable}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={intl.formatMessage({ id: 'common.nonBillable' })}
                disabled={noGeneralPermission}
                value={value}
                onChange={onChange}
                variant="new"
              />
            )}
          />
          <Controller
            name={fields.assignment.support}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={intl.formatMessage({ id: 'common.support' })}
                disabled={noGeneralPermission}
                value={value}
                onChange={onChange}
                variant="new"
              />
            )}
          />
          <Controller
            name={fields.assignment.backfillPosition}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={intl.formatMessage({ id: 'common.backfillPosition' })}
                disabled={noGeneralPermission}
                value={value}
                onChange={onChange}
                variant="new"
              />
            )}
          />
        </CheckboxWrapper>
      </Row>
    </>
  );
};
AssignmentsAndDates.propTypes = {
  capacityHours: number,
  initialValuesWithDates: object,
  noGeneralPermission: bool,
  personDetail: object,
  projectDates: object
};

export default AssignmentsAndDates;
