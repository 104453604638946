import { snakeCase } from 'lodash';
import { arrayOf, bool, func } from 'prop-types';
import { useEffect, useState } from 'react';

import FilterDropdown from 'components/common/FilterDropdown';
import SearchInput from 'components/common/SearchInput';
import { SETTINGS_FILTERS } from 'constants/constants';
import { reportsResourceShape } from 'constants/shapes';
import useGroups from 'hooks/useGroups';
import { getActiveData } from 'utils/filtersUtilities';
import { initializeOptionsWithFieldName } from 'utils/helpers';
import { filterList } from 'utils/reducersUtilities';
import { isActive } from 'utils/searchPeopleUtilities';
import { getTableColumns } from 'utils/tableUtilities';

import RoleTable from './RoleTable';
import { Grid, Header } from './styles';

const tableColumnsKeys = {
  fullName: 'fullName',
  group: 'group',
  temporalGroups: 'temporalGroups'
};

const tableColumns = getTableColumns(tableColumnsKeys, 'settings.table');

const RolesTable = ({ resources, isLoading, setModalOpen, setResource }) => {
  const [search, setSearch] = useState('');
  const [tableResources, setTableResources] = useState({
    initialResources: [],
    resourcesToDisplay: []
  });

  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { groupOptions } = useGroups();
  const { initialResources, resourcesToDisplay } = tableResources;

  const filterResources = (resourceGroup, selectedRoles) => {
    const roles = selectedRoles.length ? selectedRoles.map(role => snakeCase(role)) : [];
    const roleMatches = resourceGroup?.filter(resource => isActive(roles, resource?.group));
    const temporalRoleMatches = resourceGroup?.filter(({ temporalGroups }) =>
      temporalGroups?.some(({ group }) => isActive(roles, group))
    );
    return [...new Set([...roleMatches, ...temporalRoleMatches])];
  };

  const handleRoleFilter = selection => {
    const selectedRoles = getActiveData(selection.options);
    setSelectedOptions(selectedRoles);
  };

  const filterBySearchAndRole = () => {
    const filteredBySearch = filterList(initialResources, search, ({ fullName }) => fullName);
    const filteredData = filterResources(filteredBySearch, selectedOptions);
    setTableResources({
      ...tableResources,
      resourcesToDisplay: filteredData?.sort(
        ({ fullName: a }, { fullName: b }) => a && b && a.localeCompare(b)
      )
    });
  };

  useEffect(() => {
    filterBySearchAndRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources, search, roleOptions, selectedOptions]);

  useEffect(() => {
    setRoleOptions(initializeOptionsWithFieldName(groupOptions, SETTINGS_FILTERS.roles));
  }, [groupOptions]);

  useEffect(() => {
    setTableResources({ ...tableResources, initialResources: resources });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  return (
    <>
      <Grid>
        <Header>
          <SearchInput
            className="accessRoles"
            placeholderId="settings.accessRoles.searchResource"
            value={search}
            onChange={setSearch}
            variant="new"
          />
          <FilterDropdown
            labelId="common.role"
            options={roleOptions}
            onFilter={handleRoleFilter}
            queryKey={SETTINGS_FILTERS.roles}
            withIntlOptions={false}
            searchIntlId="common.cap.searchRole"
            alignRight
          />
        </Header>
        <RoleTable
          resources={resourcesToDisplay}
          columns={tableColumns}
          isLoading={isLoading}
          setModalOpen={setModalOpen}
          setResource={setResource}
        />
      </Grid>
    </>
  );
};

RolesTable.propTypes = {
  resources: arrayOf(reportsResourceShape),
  isLoading: bool,
  setModalOpen: func,
  setResource: func
};

export default RolesTable;
