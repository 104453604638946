import styled from 'styled-components';

export const Counter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.4rem 1.1rem;
  border-radius: 0.6rem;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.seaBlue};
  margin: 0 1rem;
  svg {
    stroke: ${({ theme }) => theme.colors.white};
    fill: ${({ theme }) => theme.colors.seaBlue};
  }
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 0.6rem;
`;

export const HelpInfo = styled.p`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.grey100};
  width: 25rem;
`;
