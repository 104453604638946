import { string, number } from 'prop-types';

import avatarPlaceholder from '../images/avatar-placeholder.png';
import { RoundAvatarContainer } from './styles';

const RoundAvatar = ({ src, squareSize = 4.1, className = '' }) => {
  const onImageError = e => {
    e.target.src = avatarPlaceholder;
  };

  return (
    <RoundAvatarContainer
      src={src || avatarPlaceholder}
      squareSize={squareSize}
      alt="avatar"
      className={className}
      onError={onImageError}
    />
  );
};

RoundAvatar.propTypes = {
  src: string,
  squareSize: number,
  className: string
};

export default RoundAvatar;
