import styled from 'styled-components';

import SingleDateRange from 'components/common/SingleDateRange';

export const FinancialContainer = styled.div`
  margin: 2.2rem 5rem;
  min-height: calc(100vh - 11.2rem);
  height: 100%;
`;

export const FinancialNavbarContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.2rem;
`;

export const SearchAndExport = styled.div`
  display: flex;
  gap: 0.8rem;
  position: relative;
  && > div > button {
    display: flex;
    gap: 0.8rem;
    min-height: 4.4rem;
  }
`;

export const StyledDateRange = styled(SingleDateRange)`
  position: absolute;
  top: 5rem;
  && > svg {
    display: none;
  }
`;
