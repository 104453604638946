import styled from 'styled-components';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import BadgePill from 'components/common/BadgePill';
import { SORT_DIR } from 'constants/constants';

export const ArrowIcon = styled(ArrowUp)`
  display: block;
  transition: all 150ms ease-in-out;
  transform: ${({ direction }) =>
    direction === SORT_DIR.desc ? 'rotate(-180deg) translate(0, 10%)' : 'none'};
  width: 1.2rem;
  height: 1.2rem;
  opacity: ${({ $isSorting }) => ($isSorting ? '1' : '0.3')};
  path {
    stroke: ${({ theme, $isSorting }) =>
      $isSorting ? theme.new.colors.P500 : theme.new.colors.N900};
    stroke-width: 1.5px;
    transform: scale(0.9);
  }
`;

export const Table = styled.table`
  background: ${({ theme }) => theme.new.colors.white};
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
`;

export const Column = styled.td`
  ${({ theme }) => theme.new.typography.p5};
  color: ${({ theme, $isMonthColumn }) =>
    $isMonthColumn ? theme.new.colors.N900 : theme.new.colors.N800};
  font-weight: ${({ theme, $isMonthColumn }) => $isMonthColumn && theme.fontWeights.semiBold};
  height: ${({ height }) => height};
  min-width: 1rem;
  padding: 1.2rem 1rem 1.2rem 0;
  width: ${({ $isMonthColumn }) => ($isMonthColumn ? '19%' : `${(100 - 19) / 3}%`)};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto 0;
  max-width: ${({ isMainInfo }) => !isMainInfo && '16rem'};
  position: relative;
`;

export const HeaderColumnContainer = styled.button`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.N900};
  display: flex;
  font-size: 1.4rem;
  gap: 0.8rem;
  justify-content: start;
  width: fit-content;
  white-space: nowrap;
  cursor: ${({ onClick }) => !onClick && 'text'};
`;

export const Row = styled.tr`
  overflow: hidden;
  border-bottom: ${({ theme, isHeader }) =>
    `1px solid ${isHeader ? theme.new.colors.N500 : theme.new.colors.N300}`};
  width: 100%;
  background: ${({ theme, isGray }) => isGray && `${theme.new.colors.N100_05}`};
  th {
    padding: 1.5rem 0;
  }
  th:not(:last-child),
  td:not(:last-child) {
    padding-right: 2rem;
  }
  td:first-child,
  th:first-child {
    padding-left: 2.2rem;
    text-align: left;
  }
`;

export const Container = styled.div`
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`;

export const StyledBadgePill = styled(BadgePill)`
  margin: 0 auto;
`;

export const IconContainer = styled.div`
  width: 2.4rem;
  display: flex;
  align-items: center;
  position: relative;
  svg path {
    &:nth-child(1) {
      fill: ${({ theme }) => theme.new.colors.FW900};
    }
    &:nth-child(2) {
      stroke: ${({ theme }) => theme.new.colors.FW900};
    }
  }
`;

export const AvailabilityContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  align-items: center;
`;
