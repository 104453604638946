import isEmpty from 'lodash/isEmpty';

export const localDeleteAssignment = (
  state,
  paramPos,
  filterParamPos,
  deeperParamPos,
  assignmentId
) => {
  if ('projects' in state) {
    if (deeperParamPos >= 0) {
      const newAvailability = state.projects[paramPos].resources[
        deeperParamPos
      ].availability.filter(
        availability =>
          !(
            isEmpty(availability.assignmentIds) || availability.assignmentIds.includes(assignmentId)
          )
      );
      if (newAvailability.length) {
        state.projects[paramPos].resources[deeperParamPos].availability = [...newAvailability];
        state.filteredProjects[filterParamPos].resources[deeperParamPos].availability = [
          ...newAvailability
        ];
      } else {
        state.projects[paramPos].resources.splice(deeperParamPos, 1);
        state.filteredProjects[filterParamPos].resources.splice(deeperParamPos, 1);
      }

      return;
    }

    const newSkills = state.projects[paramPos].skills.filter(
      skill => skill.assignmentId !== assignmentId
    );
    state.projects[paramPos].skills = [...newSkills];
    state.filteredProjects[filterParamPos].skills = [...newSkills];
  } else if ('people' in state) {
    const newAvailability = state.people[paramPos].projects[deeperParamPos].availability.filter(
      availability =>
        !(isEmpty(availability.assignmentIds) || availability.assignmentIds.includes(assignmentId))
    );

    if (newAvailability.length) {
      state.people[paramPos].projects[deeperParamPos].availability = [...newAvailability];
      state.resourcesTimeline.filteredPeople[filterParamPos].projects[deeperParamPos].availability =
        [...newAvailability];
    } else {
      state.people[paramPos].projects.splice(deeperParamPos, 1);
      state.resourcesTimeline.filteredPeople[filterParamPos].projects.splice(deeperParamPos, 1);
    }
  }
};

export const localCreateAssignment = (
  state,
  paramPos,
  filterParamPos,
  deeperParamPos,
  field,
  data
) => {
  if ('projects' in state) {
    if (deeperParamPos >= 0) {
      state.projects[paramPos][field][deeperParamPos] = data;
      state.filteredProjects[filterParamPos][field][deeperParamPos] = data;
      return;
    }

    state.projects[paramPos][field].push(data);
    state.filteredProjects[filterParamPos][field].push(data);
  } else if ('people' in state) {
    if (deeperParamPos >= 0) {
      state.people[paramPos].projects[deeperParamPos] = data;
      state.resourcesTimeline.filteredPeople[filterParamPos].projects[deeperParamPos] = data;
      return;
    }
    state.people[paramPos].projects.push(data);
    state.resourcesTimeline.filteredPeople[filterParamPos].projects.push(data);
  }
};
