import { snakeCase } from 'lodash';
import { func, string } from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import AlertMessage from 'components/common/AlertMessage';
import { PENDING, REJECTED } from 'constants/actionStatusConstants';
import { BUTTON_VARIANTS, GROUP_ROLE_MODAL_TYPES } from 'constants/constants';
import { useDispatch } from 'hooks';
import { addRoleRelation } from 'state/actions/settingsActions';

import MappingFields from './Fields';
import { ButtonRow, Form, StyledButton } from './styles';

const MappingForm = ({ status, error, setModalOpen, setClosed }) => {
  const methods = useForm({
    mode: 'onChange'
  });
  const { handleSubmit } = methods;
  const addRoleRequest = useDispatch(addRoleRelation);

  const onSubmitOverride = async values => {
    const { role, group } = values;
    const body = {
      roleGroup: {
        roleId: role?.value?.id,
        group: snakeCase(group?.label)
      }
    };
    await addRoleRequest({ body });
  };

  const onSave = e => {
    e.preventDefault();
    handleSubmit(onSubmitOverride)();
    setClosed(true);
  };

  const onSaveAndMore = async () => {
    await handleSubmit(onSubmitOverride)();
    setModalOpen(GROUP_ROLE_MODAL_TYPES.ADD);
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSave}>
        <MappingFields />

        {status === REJECTED && <AlertMessage>{error || ''}</AlertMessage>}
        <ButtonRow gap="1.2rem">
          <StyledButton
            id="addAssignmentsBtn"
            type="button"
            variant={BUTTON_VARIANTS.NEW_SECONDARY}
            isLoading={status === PENDING}
            textIntlId="assignment.form.copy"
            onClick={onSaveAndMore}
            outlined
            fullWidth
          />
          <StyledButton
            id="saveCompleteBtn"
            type="submit"
            variant={BUTTON_VARIANTS.NEW_PRIMARY}
            isLoading={status === PENDING}
            textIntlId="common.save"
            fullWidth
          />
        </ButtonRow>
      </Form>
    </FormProvider>
  );
};

MappingForm.propTypes = {
  error: string,
  status: string,
  setModalOpen: func,
  setClosed: func
};

export default MappingForm;
