import { useState } from 'react';
import { useIntl } from 'react-intl';

import RoundAvatar from 'components/common/RoundAvatar';
import { ReactComponent as Logout } from 'components/icons/logout.svg';
import useDispatch from 'hooks/useDispatch';
import useSession from 'hooks/useSession';
import { logout } from 'state/actions/userActions';

import {
  UserInfo,
  NameButton,
  Avatar,
  Email,
  Name,
  Container,
  ActionItem,
  ActionLabel,
  ActionIcon,
  StyleMenu
} from './styles';

const UserNav = () => {
  const intl = useIntl();

  const { user, resource } = useSession();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const logoutRequest = useDispatch(logout);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fullName = resource
    ? `${resource.firstName} ${resource.lastName}`
    : user?.group || intl.formatMessage({ id: 'common.user' });

  return (
    <>
      <NameButton type="button" onClick={handleClick}>
        {fullName}
      </NameButton>
      <StyleMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Container>
          {resource?.avatarUrl ? <Avatar src={resource.avatarUrl} alt="avatar" /> : <RoundAvatar />}
          <UserInfo>
            <Name>{fullName}</Name>
            <Email>{resource?.email || user?.email}</Email>
          </UserInfo>
          <ActionItem onClick={logoutRequest}>
            <ActionIcon>
              <Logout fontSize="small" />
            </ActionIcon>
            <ActionLabel>{intl.formatMessage({ id: 'common.logout' })}</ActionLabel>
          </ActionItem>
        </Container>
      </StyleMenu>
    </>
  );
};

export default UserNav;
