import styled from 'styled-components';

export const BasicInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & h3 {
    ${({ titleText, theme }) =>
      titleText ? theme.new.typography.p4SemiBold : theme.new.typography.p5SemiBold};
    color: ${({ theme }) => theme.new.colors.N800};
  }

  & p {
    ${({ theme }) => theme.new.typography.p4};
    color: ${({ theme }) => theme.new.colors.N600};
  }
`;

export const BasicInfo = styled.section`
  align-items: center;
  display: flex;
  gap: 2.735rem;

  & img {
    margin: 0;
  }
`;
