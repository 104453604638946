import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.new.colors.neutral100};
  height: 8.7rem;
  width: 100%;
  gap: 0.5rem;
`;

export const Label = styled.label`
  ${({ theme }) => theme.new.typography.p6};
  color: ${({ theme }) => theme.new.colors.neutral600};
`;
