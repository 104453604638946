import { useIntl } from 'react-intl';

import { utilizationTooltipShape } from 'constants/shapes';

import { Table } from './styles';

const UtilizationTooltip = ({ utilization }) => {
  const intl = useIntl();

  return (
    <Table>
      <tbody>
        <tr>
          <td>
            {`${intl.formatMessage(
              { id: 'template.utilization.tooltip' },
              { text: intl.formatMessage({ id: utilization.info.nameId }) }
            )}`}
          </td>
          <td>
            <b>({utilization.utilizationPercentage}%)</b>
          </td>
          <td>{utilization.scheduled}hs</td>
        </tr>
        <tr>
          <td>
            {`${intl.formatMessage(
              { id: 'template.utilization.tooltip' },
              { text: intl.formatMessage({ id: 'common.billable' }) }
            )}`}
          </td>
          <td>
            <b>({utilization.billablePercentage}%)</b>
          </td>
          <td>{utilization.billable}hs</td>
        </tr>
        <tr>
          <td>
            {`${intl.formatMessage(
              { id: 'template.utilization.tooltip' },
              { text: intl.formatMessage({ id: 'common.cap.nonBillable' }) }
            )}`}
          </td>
          <td>
            <b>({utilization.nonBillablePercentage}%)</b>
          </td>
          <td>{utilization.nonBillable}hs</td>
        </tr>
      </tbody>
    </Table>
  );
};

UtilizationTooltip.propTypes = {
  utilization: utilizationTooltipShape.isRequired
};

export default UtilizationTooltip;
