import { bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { BUTTON_VARIANTS } from 'constants/constants';

import { CancelIcon, SaveIcon, StyledButton } from './styles';

function EditButtons({ saveId, cancelId, onSave, onCancel, isLoading = false, withText = false }) {
  const intl = useIntl();
  return (
    <>
      <StyledButton
        type="button"
        id={saveId}
        icon={<SaveIcon />}
        variant={BUTTON_VARIANTS.NEW_SECONDARY}
        onClick={onSave}
        disabled={isLoading}
        withText={withText}
      >
        {withText && intl.formatMessage({ id: 'common.save' })}
      </StyledButton>
      <StyledButton
        type="button"
        id={cancelId}
        icon={<CancelIcon />}
        variant={BUTTON_VARIANTS.NEW_SECONDARY}
        onClick={onCancel}
        disabled={isLoading}
        withText={withText}
      >
        {withText && intl.formatMessage({ id: 'common.close' })}
      </StyledButton>
    </>
  );
}

EditButtons.propTypes = {
  cancelId: string,
  isLoading: bool,
  onCancel: func,
  onSave: func,
  saveId: string,
  withText: bool
};

export default EditButtons;
