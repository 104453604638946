import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1.2rem;
  width: 100%;

  :nth-child(2) > div {
    min-height: 38.3rem;
  }
`;

export const BestMatchesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.2rem;
`;
